import {
  getNumberOfColors,
  ShowCarsType,
  useSuspenseGetCompanyCarsAveragesQuery,
  useSuspenseGetMeQuery
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import { useCompanyCarsStatisticsPersistedStore } from '../../../../stores/company-cars-statistics-store'
import CompanyCarsAverageCo2Component from './components/company-cars-average-co2-component/company-cars-average-co2-component'
import CompanyCarsAverageTcoComponent from './components/company-cars-average-tco-component'
import { SuspensePaper } from '../../../suspense-paper'
import CompanyCarsAverageLeaseComponent from './components/company-cars-average-lease-component/company-cars-average-lease-component'
import CompanyCarsNumberOfCarsComponent from './components/company-cars-number-of-cars-component'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface CompanyCarsStatisticsTabProps {}

export function CompanyCarsStatisticsTab(props: CompanyCarsStatisticsTabProps) {
  const { t } = useTranslation()
  const store = useCompanyCarsStatisticsPersistedStore()

  const [barChartData, setBarChartData] = useState<any>([])
  const { data: me } = useSuspenseGetMeQuery()
  const { data: companyCarsAverages } = useSuspenseGetCompanyCarsAveragesQuery({
    startDate: store.startDateFilter,
    endDate: store.endDateFilter,
    companyCarFilterModel: {
      carCategoryId: store.carCategoryFilter,
      country: store.countryFilter,
      motorTypeKey: store.motorTypeFilter,
      endsInPeriod: store.endsInPeriodFilter,
      showCars: store.showCarsFilter
    }
  })
  const cars = companyCarsAverages?.companyCarsAverages

  useEffect(() => {
    if (cars) {
      setBarChartData(
        cars.entries.map(c => ({
          TotalNumberOfCars: c.numberOfCars ? c.numberOfCars : 0,
          AverageLease: c.lease ? Math.round(c.lease) : 0,
          AverageCO2: c.co2 ? Math.round(c.co2) : 0,
          AverageTCO: c.tco ? Math.round(c.tco) : 0,
          monthAndYear: parseISO(c.date).toLocaleDateString(me?.me.locale.locale, { month: 'short', year: '2-digit' })
        }))
      )
    }
  }, [cars])

  const colors = getNumberOfColors(4)

  return (
    <Grid container spacing={2} height={'100%'}>
      <Grid item xs={12} lg={6}>
        <SuspensePaper>
          <Typography sx={{ textAlign: 'center', mb: 0, mt: 1 }} variant="h4">
            {t('companycar-statistics.averageco2')}
          </Typography>
          <CompanyCarsAverageCo2Component color={colors[0]} data={barChartData} showZeroScale={store.showZeroScale} />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper>
          <Typography sx={{ textAlign: 'center', mb: 0, mt: 1 }} variant="h4">
            {t('companycar-statistics.averagetco')}
          </Typography>
          <CompanyCarsAverageTcoComponent color={colors[1]} data={barChartData} showZeroScale={store.showZeroScale} />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper>
          <Typography sx={{ textAlign: 'center', mb: 0, mt: 1 }} variant="h4">
            {t('companycar-statistics.averagelease')}
          </Typography>
          <CompanyCarsAverageLeaseComponent color={colors[2]} data={barChartData} showZeroScale={store.showZeroScale} />
        </SuspensePaper>
      </Grid>
      <Grid item xs={12} lg={6}>
        <SuspensePaper>
          <Typography sx={{ textAlign: 'center', mb: 0, mt: 1 }} variant="h4">
            {t('companycar-statistics.numberofcars')}
          </Typography>
          <CompanyCarsNumberOfCarsComponent color={colors[3]} data={barChartData} showZeroScale={store.showZeroScale} />
        </SuspensePaper>
      </Grid>
    </Grid>
  )
}

export default CompanyCarsStatisticsTab
