import { useGetRequestPolicyContractsByContractIdQuery } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, DataTableCell } from '../../data-table'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import { EditRequestPolicyContract } from '../add-edit-request-policy-contract'
import { RemoveRequestPolicyContract } from '../remove-request-policy-contract'
import { RequestPolicyContractBalance } from '../request-policy-contract-balance'

export interface RequestPolicyContractOverviewProps {
  contractId: string
}

export function RequestPolicyContractOverview({ contractId }: RequestPolicyContractOverviewProps) {
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const { openFlyIn } = useFlyIn()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [requestPolicyContractId, setRequestPolicyContractId] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const { data, isLoading, refetch } = useGetRequestPolicyContractsByContractIdQuery({
    contractId: contractId
  })

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <CloseIcon />}</DataTableCell>
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setRequestPolicyContractId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'requestPolicy',
      headerName: t('requestpolicycontract.datatable.column.requestpolicy'),
      flex: 2,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('requestpolicycontract.datatable.column.startdate'),
      flex: 1,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('requestpolicycontract.datatable.column.enddate'),
      flex: 1,
      sortable: false
    },
    {
      field: 'balance',
      headerName: t('requestpolicycontract.datatable.column.balance'),
      flex: 2,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  useEffect(() => {
    if (data && data?.allRequestPolicyContractsByContractId) {
      const requestPolicyContracts = data.allRequestPolicyContractsByContractId
      setTableData(
        requestPolicyContracts.map(row => {
          return {
            id: row.id,
            requestPolicy: row.requestPolicyInternalName,
            startDate: parseISO(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? parseISO(row.endDate).toLocaleDateString() : null,
            balance: row.balanceShown
          }
        })
      )
      setRowCount(requestPolicyContracts.length)
    }
  }, [data])

  return (
    <>
      <DataTable hideFooter data={tableData} columns={columns} totalRowCount={rowCount} isLoading={isLoading} />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <EditRequestPolicyContract contractId={contractId} requestPolicyContractId={requestPolicyContractId} />
              ),
              callbackAfterClose: () => () => {
                refetch()
              }
            })
          }
        >
          {t('requestpolicycontractpage.list.row.menu.edit-requestpolicycontract')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveRequestPolicyContract requestPolicyContractId={requestPolicyContractId} />,
              callbackAfterClose: () => () => {
                refetch()
              }
            })
          }
        >
          {t('requestpolicycontractpage.list.row.menu.remove-requestpolicycontract')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RequestPolicyContractBalance
                  title={t('flyin.requestpolicybalance.title')}
                  requestPolicyContractId={requestPolicyContractId}
                />
              )
            })
          }
        >
          {t('requestpolicycontractpage.list.row.menu.show-balance')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RequestPolicyContractOverview
