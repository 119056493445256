import { FormSelectOption, SmartCalculationRuleOperator, SmartCalculationRulePartFunction } from "@epix-web-apps/core"

export const smartPayComponentTypeSelectOptions = {
  get calculationRuleOperators() {
    return [
        new FormSelectOption(SmartCalculationRuleOperator.Sum, '+'),
        new FormSelectOption(SmartCalculationRuleOperator.Subtraction, '-'),
        new FormSelectOption(SmartCalculationRuleOperator.Multiply, '*'),
        new FormSelectOption(SmartCalculationRuleOperator.Divide, '/')
    ]
  },
  get calculationRulePartFunctions() {
    return Object.values(SmartCalculationRulePartFunction).map(v => new FormSelectOption(v, v))
  }
}