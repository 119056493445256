import {
  GetCarCategoryByIdQuery,
  OrderDirection,
  useCreateCarCategoryMutation,
  useSuspenseGetCarCategoriesQuery,
  useSuspenseGetCarCategoryByIdQuery,
  useUpdateCarCategoryMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object, string } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface EditCarCategoryProps {
  id: string
}

export function AddCarCategory() {
  return AddEditCarCategory()
}

export function EditCarCategory({ id }: EditCarCategoryProps) {
  const getCarCategoryByIdQuery = useSuspenseGetCarCategoryByIdQuery({
    id: id
  })

  return AddEditCarCategory(id, getCarCategoryByIdQuery)
}

function AddEditCarCategory(
  id?: string,
  getCarCategoryByIdQuery?: UseSuspenseQueryResult<GetCarCategoryByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditCarCategorySchema = object({
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    code: string({
      required_error: t('form.validation.carcoderequired'),
      invalid_type_error: t('form.validation.carcoderequired')
    }).min(1, t('form.validation.carcoderequired')),
    active: boolean()
  })

  type AddEditCarCategoryForm = TypeOf<typeof addEditCarCategorySchema>

  const getCarCategoryById = getCarCategoryByIdQuery?.data

  const { refetch: refetchCarCategories } = useSuspenseGetCarCategoriesQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'Value',
    orderDirection: OrderDirection.Asc
  })

  const form = useForm<AddEditCarCategoryForm>({
    resolver: zodResolver(addEditCarCategorySchema),
    defaultValues: {
      description: getCarCategoryById?.carCategory.description,
      code: getCarCategoryById?.carCategory.code,
      active: getCarCategoryById?.carCategory.active ?? true
    }
  })

  const createMutation = useCreateCarCategoryMutation()
  const updateMutation = useUpdateCarCategoryMutation()

  const handleOnSubmit = async (newCarCategory: AddEditCarCategoryForm) => {
    if (!getCarCategoryByIdQuery || !id) {
      await createMutation
        .mutateAsync({
          createCarCategoryCommand: {
            description: newCarCategory.description,
            code: newCarCategory.code,
            active: newCarCategory.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateCarCategoryCommand: {
            id: id || '',
            description: newCarCategory.description,
            code: newCarCategory.code,
            active: newCarCategory.active
          }
        })
        .then(() => {
          refetchCarCategories()
          getCarCategoryByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditcarcategory.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.carcode')}*`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}*`} />

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCarCategory
