import { FormSelectOption, IFormSelectOption } from '@epix-web-apps/core'
import { FormControl, GridSize, InputLabel, useTheme } from '@mui/material'
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Select, {
  CSSObjectWithLabel,
  FormatOptionLabelMeta,
  GroupBase,
  InputActionMeta,
  StylesConfig,
  ThemeConfig
} from 'react-select'
import { OptionalGridWrapper } from '../form-grid-wrapper'

/* eslint-disable-next-line */
export interface FormSelectProps {
  name: string
  label: string
  options?: Array<IFormSelectOption>
  selectedAsyncOption?: IFormSelectOption | null
  isLoading?: boolean
  noOptionsMessage?: string
  formatOptionLabel?:
    | ((data: FormSelectOption, formatOptionLabelMeta: FormatOptionLabelMeta<FormSelectOption>) => ReactNode)
    | undefined
  sx?: GridSize
  onChange?: ((event: SyntheticEvent<Element | null>, child: IFormSelectOption | null) => void) | undefined
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined
  disabled?: boolean
}

export function FormSelect({
  name,
  label,
  options = undefined,
  selectedAsyncOption = undefined,
  isLoading = false,
  noOptionsMessage = undefined,
  formatOptionLabel = undefined,
  sx,
  onChange,
  onInputChange,
  disabled = false
}: FormSelectProps) {
  const theme = useTheme()
  const form = useFormContext()
  const { t } = useTranslation()
  if (!form) throw Error('Form elements can only be used inside the react hook forms provider')
  const { ref, ...register } = form.register(name)
  const defaultValue = form?.getValues(name)
  const [selectedOption, setSelectedOption] = useState<IFormSelectOption | null>(
    options?.find(c => c.id === defaultValue) || null
  )
  const [isFocused, setIsFocused] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(!!form.getFieldState(name).error)
  }, [form.getFieldState(name).error])

  const selectTheme: ThemeConfig = defaultTheme => ({
    ...defaultTheme,
    borderRadius: 2,
    colors: {
      ...defaultTheme.colors,
      primary25: theme.palette.primary.contrastText,
      primary: theme.palette.primary.main
    }
  })
  const selectStyles: StylesConfig<IFormSelectOption, false, GroupBase<IFormSelectOption>> | undefined = {
    menu: base => ({ ...base, zIndex: 1000 } as CSSObjectWithLabel),
    control: (base, state) => {
      if (error)
        base = {
          ...base,
          ...{
            borderColor: `${theme.palette.error.main}`,
            boxShadow: state.selectProps.menuIsOpen ? `0 0 0 1px ${theme.palette.error.main}` : 'none',
            '&:hover, &:active, &:focus': {
              borderColor: `${theme.palette.error.main}`
            }
          }
        } as CSSObjectWithLabel
      return base
    }
  }

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl variant="outlined" fullWidth size="small">
        <InputLabel
          sx={
            isFocused || !!selectedOption || !!defaultValue
              ? {
                  backgroundColor: disabled ? 'transparent' : theme.palette.common.white,
                  mx: -0.6,
                  px: 0.75
                }
              : {}
          }
          shrink={isFocused || !!selectedOption || !!defaultValue}
          focused={(isFocused && !!selectedOption) || isFocused}
          htmlFor={name}
        >
          {label}
        </InputLabel>
        <Controller
          {...register}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <Select
                {...field}
                ref={ref}
                inputId={register.name}
                options={options}
                isLoading={isLoading}
                value={options?.find(c => c.id === field.value) || selectedAsyncOption || new FormSelectOption('', '')}
                defaultValue={options?.find(c => c.id === defaultValue) || selectedAsyncOption}
                onChange={value => {
                  setSelectedOption((value as FormSelectOption) || null)
                  field.onChange(value?.id || null)
                  onChange && onChange({} as SyntheticEvent, value)
                }}
                onInputChange={onInputChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                menuShouldBlockScroll={true}
                loadingMessage={() => t('common.loading')}
                noOptionsMessage={() => noOptionsMessage || t('formselect.nooptions')}
                isDisabled={disabled}
                isOptionSelected={(option: IFormSelectOption, selectValue: any) => option.id === selectValue}
                isOptionDisabled={option => !option.active}
                placeholder={null}
                isSearchable
                isClearable
                styles={selectStyles}
                theme={selectTheme}
                formatOptionLabel={formatOptionLabel}
              />
            )
          }}
        />
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormSelect
