import {
  getSearchParamNumber,
  OrderDirection,
  useSuspenseGetAllContractsQuery,
  useRequiredParams,
  useSuspenseGetPersonByIdQuery
} from '@epix-web-apps/core'
import {
  a11yProps,
  DetailPageBaseQueryParams,
  DetailPageContentHeader,
  HeaderTitleNavigation,
  LoadingOverlay,
  People,
  TabPanel,
} from '@epix-web-apps/ui'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import { Box, Tabs } from '@mui/material'
import { Suspense, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import NextPreviousPerson from '../../components/people-components/next-previous-person/next-previous-person'
import { IconTab } from '../../components/tab-components/tab/icon-tab'
import IdentificationTab from './identification-tab/identification-tab'
import InformationTab from './information-tab/information-tab'
import InjuryTab from './injury-tab/injury-tab'
import SelfServiceTab from './self-service-tab/self-service-tab'

enum PersonTabs {
  IDENTIFICATION,
  INFORMATION,
  SELFSERVICE,
  INJURIES
}

/* eslint-disable-next-line */
export interface PeopleDetailPageProps {}

export function PeopleDetailPage(props: PeopleDetailPageProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { data: getPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const person = getPersonById?.personById

  const { data: getAllContracts } = useSuspenseGetAllContractsQuery({
    personId: params.id,
    orderDirection: OrderDirection.Desc
  })
  const anyContractHasSelfService = (getAllContracts?.allContracts ?? []).some(c => c.payGroup?.hasSelfService)

  const [tabIndex, setTabIndex] = useState(PersonTabs.IDENTIFICATION)

  return (
    <>
      <DetailPageContentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderTitleNavigation
            backToLink={`${People.PEOPLE()}?page=${getSearchParamNumber('page', searchParams)}`}
            title={`${person?.firstName} ${person?.preferredLastName}`}
            showDivider={false}
          />
          <NextPreviousPerson personId={params.id} />
        </Box>
      </DetailPageContentHeader>

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
          <IconTab
            label={t('peopledetailpage.identification.tab')}
            icon={<PersonOutlineOutlinedIcon fontSize="small" />}
            {...a11yProps(PersonTabs.IDENTIFICATION)}
          />
          <IconTab
            label={t('peopledetailpage.companyinfo.tab')}
            icon={<InfoOutlinedIcon fontSize="small" />}
            {...a11yProps(PersonTabs.INFORMATION)}
          />
          <IconTab
            label={t('peopledetailpage.selfserviceaccess.tab')}
            icon={<PhoneAndroidOutlinedIcon fontSize="small" />}
            {...a11yProps(PersonTabs.SELFSERVICE)}
            sx={{ display: anyContractHasSelfService ? '' : 'none' }}
          />
          <IconTab
            label={t('peopledetailpage.injury.tab')}
            icon={<LocalHospitalOutlinedIcon fontSize="small" />}
            {...a11yProps(PersonTabs.INJURIES)}
          />
        </Tabs>
      </Box>

      <TabPanel value={tabIndex} index={PersonTabs.IDENTIFICATION}>
        <Suspense fallback={<LoadingOverlay />}>
          <IdentificationTab />
        </Suspense>
      </TabPanel>

      <TabPanel value={tabIndex} index={PersonTabs.INFORMATION}>
        <Suspense fallback={<LoadingOverlay />}>
          <InformationTab />
        </Suspense>
      </TabPanel>

      {anyContractHasSelfService && (
        <TabPanel value={tabIndex} index={PersonTabs.SELFSERVICE}>
          <Suspense fallback={<LoadingOverlay />}>
            <SelfServiceTab />
          </Suspense>
        </TabPanel>
      )}

      <TabPanel value={tabIndex} index={PersonTabs.INJURIES}>
        <Suspense fallback={<LoadingOverlay />}>
          <InjuryTab />
        </Suspense>
      </TabPanel>
    </>
  )
}

export default PeopleDetailPage
