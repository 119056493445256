import { useSuspenseGetCegedimContractDetailByContractIdQuery, useSuspenseGetMeQuery } from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { EditCegedimContractType } from './edit-cegedim-contract-type'
import { EditCegedimLabourAgreement } from './edit-cegedim-labour-agreement'

/* eslint-disable-next-line */
export interface FrCegedimProps {
  contractId: string
}

export function FrCegedim({ contractId }: FrCegedimProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { data: me } = useSuspenseGetMeQuery()

  const { data: partnerContractDetailData } = useSuspenseGetCegedimContractDetailByContractIdQuery({
    contractId: contractId
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('cegedimdetailpage.labouragreement.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditCegedimLabourAgreement contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractDetailData?.cegedimContractDetailByContractId?.labourAgreementRank && (
            <DetailBoxRow>
              <Typography>
                {t('cegedimdetailpage.labouragreement.labouragreementrank')}:{' '}
                <span className="bold">
                  {' '}
                  {partnerContractDetailData?.cegedimContractDetailByContractId.labourAgreementRank}
                </span>
              </Typography>
            </DetailBoxRow>
          )}

          {partnerContractDetailData?.cegedimContractDetailByContractId?.applicableSince && (
            <DetailBoxRow>
              <Typography>
                {t('cegedimdetailpage.labouragreement.applicablesince')}:
                <span className="bold">
                  {' '}
                  {parseISO(
                    partnerContractDetailData?.cegedimContractDetailByContractId.applicableSince
                  ).toLocaleDateString(me?.me.locale.locale)}
                </span>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('cegedimdetailpage.contracttype.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditCegedimContractType contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractDetailData?.cegedimContractDetailByContractId?.contractStartReasonType && (
            <DetailBoxRow>
              <Typography>
                {t('cegedimdetailpage.contracttype.contractstartreason')}:
                <span className="bold">
                  {' '}
                  {partnerContractDetailData?.cegedimContractDetailByContractId.contractStartReasonType?.value}
                </span>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default FrCegedim
