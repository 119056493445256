import styled from '@emotion/styled'
import { OrderDirection, useGetAllIcpsQuery } from '@epix-web-apps/core'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { ToggleButton as OriginalToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'

const ToggleButton = styled(OriginalToggleButton)(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '50% !important',
    width: '2.75rem',
    margin: '0 0.5rem 0 0 !important',
    padding: '9px',
    border: '0 !important',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }
})

export function IcpFilter() {
  const { icpFilters, setIcpFilters } = useGlobalPersistedStore()
  const productivityAnalyticsStore = useProductivityAnalyticsPersistedStore()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: getAllIcps } = useGetAllIcpsQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'Code',
    orderDirection: OrderDirection.Asc
  })

  const handleIcpChange = (_: React.MouseEvent<HTMLElement>, newIcps: string[]) => {
    setIcpFilters(newIcps)

    // people page params
    searchParams.set('page', '0')
    setSearchParams(searchParams)

    // productivity analytics filters
    productivityAnalyticsStore.setEmployerFilter(null)
  }

  if (getAllIcps?.icps.totalCount === 1) {
    return <></>
  }

  return (
    <ToggleButtonGroup value={icpFilters} onChange={handleIcpChange} aria-label="icp filters">
      {getAllIcps?.icps.data.map((icp, index) => (
        <ToggleButton key={index} value={icp.id} aria-label={icp.code}>
          {icp.code}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default IcpFilter
