import { Box, Grid, IconButton, List, ListItem, Typography, useTheme } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { AbsenceRequestPersonModel, ToRouteDateFormat } from '@epix-web-apps/core'
import { useNavigate } from 'react-router-dom'
import { SelfService } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface PendingRequestsOverviewProps {
  absenceRequests: Array<AbsenceRequestPersonModel>
  dateParam?: Date
}

export function PendingRequestsOverview({ absenceRequests, dateParam }: PendingRequestsOverviewProps) {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper'
      }}
    >
      {absenceRequests?.map((absence: any) => (
        <ListItem
          sx={{
            borderRadius: '5px',
            border: `1px solid ${theme.palette.divider}`,
            mb: 0.5,
            p: 0,
            pl: 1
          }}
          key={absence.id}
          disableGutters
          onClick={() => {
            navigate(
              SelfService.SELFSERVICE_ABSENCE_REQUESTS_DATE(
                `${absence.id}`,
                `${ToRouteDateFormat(dateParam ?? new Date())}`
              )
            )
          }}
        >
          <Grid container alignItems="center">
            <Grid xs={5} md={4} item>
              <Typography>{absence.dateRange}</Typography>
            </Grid>
            <Grid xs={4} md={6} item>
              <Typography>
                {`${absence.requestedByPersonFistName} ${absence.requestedByPersonPreferredLastName.substring(0, 1)}.`}
              </Typography>
            </Grid>
            <Grid xs={3} md={2} item container justifyContent="flex-end">
              <Box display="flex">
                <IconButton color="primary">
                  <HelpOutlineIcon />
                </IconButton>
                <IconButton>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  )
}

export default PendingRequestsOverview
