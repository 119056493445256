import { OrderDirection, useGetAllSortedUsersQuery } from '@epix-web-apps/core'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, IconButton, Switch, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import FilterButton from '../../components/filter-button/filter-button'
import AddUser from '../../components/user-components/add-user'

/* eslint-disable-next-line */
export interface UsersPageProps {}

export function UsersPage(props: UsersPageProps) {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel(0))
  const [filters, setFilters] = useState({
    showInactive: false
  })
  const { openFlyIn } = useFlyIn()

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('userspage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FilterButton filters={filters}>
          <p>
            <Switch
              checked={filters.showInactive}
              onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
              aria-label={t('configurationusermanagement.filters.showinactivepeople')}
            />
            {t('configurationusermanagement.filters.showinactivepeople')}
          </p>
        </FilterButton>
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddUser />,
              callbackAfterClose: () => refetchAllUsers
            })
          }
        >
          <AddIcon />
          {t('userspage.button.adduser')}
        </Button>
      </Box>
    </Box>
  )

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton aria-label="row details" aria-controls="details-row">
      <KeyboardArrowRightIcon />
    </IconButton>
  )

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />}</DataTableCell>
  }

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('userspage.datatable.column.firstname'),
      flex: 1,
      sortable: true
    },
    {
      field: 'lastName',
      headerName: t('userspage.datatable.column.lastname'),
      flex: 1,
      sortable: true
    },
    {
      field: 'email',
      headerName: t('userspage.datatable.column.username'),
      flex: 1,
      sortable: false
    },
    {
      field: 'language',
      valueGetter: (value, row) => row.locale.language,
      headerName: t('userspage.datatable.column.language'),
      flex: 1,
      sortable: false
    },
    {
      field: 'currency',
      headerName: t('userspage.datatable.column.currency'),
      flex: 1,
      sortable: false
    },
    {
      field: 'isActive',
      headerName: t('userspage.datatable.column.active'),
      flex: 1,
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field, OrderDirection.Asc))

  useEffect(() => {
    setPaginationModel(new PaginationModel(0))
  }, [filters])

  const {
    data: users,
    isLoading: isLoadingUsers,
    refetch: refetchAllUsers
  } = useGetAllSortedUsersQuery({
    showInactive: filters.showInactive,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (users) {
      setTableData(users.allSortedUsersWithLocale.data)
      setRowCount(users.allSortedUsersWithLocale.data.length)
    }
  }, [users])

  const goToUserDetailPage = (userId: GridRowId | undefined) => {
    userId && navigate(Configuration.USERS_ID(`${userId}`))
  }

  return (
    <ScrollableDataTable
      data={tableData}
      columns={columns}
      totalRowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      isLoading={isLoadingUsers}
      sortModel={sortModel}
      onSortChange={setSortModel}
      filterBarElement={filterBar}
      onRowClick={params => goToUserDetailPage(params.id)}
    />
  )
}

export default UsersPage
