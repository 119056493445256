import { PARTNER_CONFIG_KEYS, useSuspenseGetContractByIdQuery } from '@epix-web-apps/core'
import { DeBuropartner } from './de-buropartner'
import { NlPhocus } from './nl-phocus'
import { ItLdp } from './it-ldp'
import { CzAsbgroup } from './cz-asbgroup'
import { EsAsec } from './es-asec'
import { FrCegedim } from './fr-cegedim'
import { PLASB } from './pl-asb/pl-asb'
import { LuSecurex } from './lu-securex'

/* eslint-disable-next-line */
export interface ProviderSpecificDetailsProps {
  contractId: string
}

export function ProviderSpecificDetails({ contractId }: ProviderSpecificDetailsProps) {
  const { data: getContractById } = useSuspenseGetContractByIdQuery({ contractId: contractId })

  const contract = getContractById?.contractById

  function renderProviderDetail(configKeys: string[]): boolean {
    return configKeys.some(e => e === contract?.icp.configurationKey)
  }

  return (
    contract && (
      <>
        {renderProviderDetail([PARTNER_CONFIG_KEYS.DE_BUROPARTNER, PARTNER_CONFIG_KEYS.DE_GENERIC]) && (
          <DeBuropartner contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.NL_PHOCUS, PARTNER_CONFIG_KEYS.NL_GENERIC]) && (
          <NlPhocus contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.IT_LDP, PARTNER_CONFIG_KEYS.IT_GENERIC]) && (
          <ItLdp contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.CZ_ASBGROUP, PARTNER_CONFIG_KEYS.CZ_GENERIC]) && (
          <CzAsbgroup contractId={contractId} personId={contract.personId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.ES_ASEC, PARTNER_CONFIG_KEYS.ES_GENERIC]) && (
          <EsAsec contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.FR_CEGEDIM, PARTNER_CONFIG_KEYS.FR_GENERIC]) && (
          <FrCegedim contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.PL_ASB, PARTNER_CONFIG_KEYS.PL_GENERIC]) && (
          <PLASB contractId={contractId} />
        )}
        {renderProviderDetail([PARTNER_CONFIG_KEYS.LU_SECUREX, PARTNER_CONFIG_KEYS.LU_GENERIC]) && (
          <LuSecurex contractId={contractId} />
        )}
      </>
    )
  )
}

export default ProviderSpecificDetails
