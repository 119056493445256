import { SmartInputCodeModel, useNameof } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../../data-table'
import DropdownMenu from '../../../dropdown-menu'
import { EditSmartInputCode } from '../../add-edit-smart-input-code'
import RemoveSmartInputCode from '../../remove-smart-input-code/remove-smart-input-code'

interface SmartPayInputCodeDataTableProps {
  smartPayComponentHistoryId: string
  isLoadingHistory: boolean
  inputCodes: SmartInputCodeModel[]
  employerId: string
  refetch: () => void
}

type SmartPayInputCodeRow = {
  id: string
  payrollCode: string
  userFriendlyDescription: string
  defaultValue?: number
}

export function SmartPayInputCodeDataTable({
  smartPayComponentHistoryId,
  isLoadingHistory,
  refetch,
  inputCodes,
  employerId
}: SmartPayInputCodeDataTableProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  const { nameof } = useNameof<SmartPayInputCodeRow>()
  const [inputTableData, setInputTableData] = useState<SmartPayInputCodeRow[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [smartInputCodeId, setSmartInputCodeId] = useState('')
  const [description, setDescription] = useState('')

  // TODO 3023
  useEffect(() => {
    setInputTableData(
      inputCodes.map(inputCode => {
        return {
          id: inputCode.id,
          payrollCode: inputCode.payrollCode,
          userFriendlyDescription: inputCode.userFriendlyDescription,
          defaultValue: inputCode.defaultValue ?? 0
        }
      })
    )
  }, [inputCodes])

  const rowActions = useCallback((row: SmartPayInputCodeRow) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          setAnchorEl(e.currentTarget)
          setSmartInputCodeId(row.id)
          setDescription(row.userFriendlyDescription)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }, [])

  const columns: GridColDef<SmartPayInputCodeRow>[] = [
    {
      field: nameof('payrollCode'),
      headerName: t('smartpayinputcodedatatable.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: nameof('userFriendlyDescription'),
      headerName: t('smartpayinputcodedatatable.datatable.column.description'),
      flex: 1
    },
    {
      field: nameof('defaultValue'),
      headerName: t('smartpayinputcodedatatable.datatable.column.inputcodes'),
      flex: 1
    },
    {
      field: ' ',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  return (
    <>
      <DataTable
        data={inputTableData}
        columns={columns}
        isLoading={isLoadingHistory}
        hideFooter={true}
        emptyStateElement={
          <Typography
            sx={{
              mt: 2,
              color: theme.palette.text.secondary,
              fontStyle: 'italic'
            }}
          >
            {t('smartpayinputcodedatatable.datatable.noresults')}
          </Typography>
        }
      />
      <Typography
        sx={{
          mt: 2,
          color: theme.palette.text.secondary,
          fontStyle: 'italic'
        }}
      >
        {t('smartpayinputcodedatatable.description-1')}
      </Typography>
      <Typography
        sx={{
          mt: 2,
          color: theme.palette.text.secondary,
          fontStyle: 'italic'
        }}
      >
        {t('smartpayinputcodedatatable.description-2')}
      </Typography>
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
      <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <EditSmartInputCode
                  smartComponentHistoryId={smartPayComponentHistoryId}
                  smartInputCodeId={smartInputCodeId}
                  employerId={employerId}
                  refetch={refetch}
                />
              )
            })
          }
        >
          {t('smartpayinputcodedatatable.edit-input-code')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveSmartInputCode
                  smartPayComponentHistoryId={smartPayComponentHistoryId}
                  smartInputCodeId={smartInputCodeId}
                  description={description}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default SmartPayInputCodeDataTable
