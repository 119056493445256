import {
  DEFAULT_LOCALE,
  useGetPersonCorrectionsByProcessIdQuery,
  useRequiredParams,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import {} from '@epix-web-apps/ui'
import DoneIcon from '@mui/icons-material/Done'
import { useTheme } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, PaginationModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'
import { minWidthPayrollClosure } from '../steps'

/* eslint-disable-next-line */
export interface PersonCorrectionsComponentProps {}

export function PersonCorrectionsComponent(props: PersonCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const paginationModel = new PaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: params.id
  })

  const { data: personCorrections, isLoading: isLoadingPersonCorrections } = useGetPersonCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'personNumber',
      headerName: t('personcontractdata.datatable.column.personnumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'firstName',
      headerName: t('personcontractdata.datatable.column.firstname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'otherNames',
      headerName: t('personcontractdata.datatable.column.othernames'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'preferredLastName',
      headerName: t('personcontractdata.datatable.column.preferredlastname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastNameTypeKey',
      headerName: t('personcontractdata.datatable.column.lastnametypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastNameType',
      headerName: t('personcontractdata.datatable.column.lastnametype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastNamePrefix',
      headerName: t('personcontractdata.datatable.column.lastnameprefix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastName',
      headerName: t('personcontractdata.datatable.column.lastName'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerNamePrefix',
      headerName: t('personcontractdata.datatable.column.partnernameprefix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'partnerName',
      headerName: t('personcontractdata.datatable.column.partername'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'titleKey',
      headerName: t('personcontractdata.datatable.column.titlekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'title',
      headerName: t('personcontractdata.datatable.column.title'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'language',
      headerName: t('personcontractdata.datatable.column.language'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderKey',
      headerName: t('personcontractdata.datatable.column.genderkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'gender',
      headerName: t('personcontractdata.datatable.column.gender'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderSpecificCode',
      headerName: t('personcontractdata.datatable.column.genderspecificcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderSpecific',
      headerName: t('personcontractdata.datatable.column.genderspecific'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'dateOfBirth',
      headerName: t('personcontractdata.datatable.column.dateofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'placeOfBirth',
      headerName: t('personcontractdata.datatable.column.placeofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirthKey',
      headerName: t('personcontractdata.datatable.column.countryofbirthkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirth',
      headerName: t('personcontractdata.datatable.column.countryofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalityKey',
      headerName: t('personcontractdata.datatable.column.nationalitykey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationality',
      headerName: t('personcontractdata.datatable.column.nationality'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalityIdNumber',
      headerName: t('personcontractdata.datatable.column.nationalityidnumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'disabled',
      headerName: t('personcontractdata.datatable.column.disabled'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'percentageOfDisabled',
      headerName: t('personcontractdata.datatable.column.percentageofdisabled'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'functionTitle',
      headerName: t('personcontractdata.datatable.column.functiontitle'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'functionAreaKey',
      headerName: t('personcontractdata.datatable.column.functionareakey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'functionArea',
      headerName: t('personcontractdata.datatable.column.functionarea'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'functionLevelKey',
      headerName: t('personcontractdata.datatable.column.functionlevelkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'functionLevel',
      headerName: t('personcontractdata.datatable.column.functionlevel'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'startedOn',
      headerName: t('personcontractdata.datatable.column.startedon'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'personalPhoneNumber',
      headerName: t('personcontractdata.datatable.column.personalphonenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'personalMobileNumber',
      headerName: t('personcontractdata.datatable.column.personalmobilenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'personalEmail',
      headerName: t('personcontractdata.datatable.column.personalemail'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workPhoneNumber',
      headerName: t('personcontractdata.datatable.column.workphonenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workMobileNumber',
      headerName: t('personcontractdata.datatable.column.workmobilenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'workEmail',
      headerName: t('personcontractdata.datatable.column.workemail'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankAccountHolder',
      headerName: t('personcontractdata.datatable.column.bankaccountholder'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankAccount',
      headerName: t('personcontractdata.datatable.column.bankaccount'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'internationalBankAccount',
      headerName: t('personcontractdata.datatable.column.internationalbankaccount'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankIdentierCode',
      headerName: t('personcontractdata.datatable.column.bankidentiercode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'bankSortCode',
      headerName: t('personcontractdata.datatable.column.banksortcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'educationLevelKey',
      headerName: t('personcontractdata.datatable.column.educationlevelkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'educationLevel',
      headerName: t('personcontractdata.datatable.column.educationlevel'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  useEffect(() => {
    if (personCorrections && personCorrections?.personCorrectionsByProcessId) {
      const contractCorrectionsData = personCorrections.personCorrectionsByProcessId
      setTableData(
        contractCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employerNumber: row.employerNumber,
            employeeNumber: row.employeeNumber,
            personNumber: row.personNumber,
            firstName: row.firstName,
            otherNames: row.otherNames,
            preferredLastName: row.preferredLastName,
            lastNameTypeKey: row.lastNameType?.key,
            lastNameType: row.lastNameType?.value,
            lastNamePrefix: row.lastNamePrefix,
            lastName: row.lastName,
            partnerNamePrefix: row.partnerNamePrefix,
            partnerName: row.partnerName,
            titleKey: row.title.key,
            title: row.title.value,
            language: row.language,
            genderKey: row.gender.key,
            gender: row.gender.value,
            genderSpecificCode: row.icpSpecificGender?.icpSpecificCode,
            genderSpecific: row.icpSpecificGender?.name,
            dateOfBirth: parseISO(row.dateOfBirth).toLocaleDateString(DEFAULT_LOCALE),
            placeOfBirth: row.placeOfBirth,
            countryOfBirthKey: row.countryOfBirth?.code,
            countryOfBirth: row.countryOfBirth?.name,
            nationalityKey: row.nationality?.code,
            nationality: row.nationality?.name,
            nationalityIdNumber: row.nationalSocialSecurityNumber,
            disabled: row.disabled,
            percentageOfDisabled: row.disabledPercentage,
            functionTitle: row.functionTitle,
            functionAreaKey: row.functionArea?.key,
            functionArea: row.functionArea?.value,
            functionLevelKey: row.functionLevel?.key,
            functionLevel: row.functionLevel?.value,
            startedOn: parseISO(row.startedOn).toLocaleDateString(DEFAULT_LOCALE),
            personalPhoneNumber: row.personalPhoneNumber,
            personalMobileNumber: row.personalMobileNumber,
            personalEmail: row.personalEmail,
            workPhoneNumber: row.workPhoneNumber,
            workMobileNumber: row.workMobileNumber,
            workEmail: row.workEmail,
            bankAccountHolder: row.bankInformation?.accountHolder,
            bankAccount: row.bankInformation?.bankAccount,
            internationalBankAccount: row.iBAN,
            bankIdentierCode: row.bIC,
            bankSortCode: row.bankSortCode,
            educationLevelKey: row.educationLevel?.key,
            educationLevel: row.educationLevel?.value
          }
        })
      )
      setRowCount(contractCorrectionsData.totalCount)
    }
  }, [personCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoadingPersonCorrections}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default PersonCorrectionsComponent
