import {
  FormSelectOption,
  formTypeSelectOptions,
  OrderDirection,
  useCreatePayComponentUpdateProcessMutation,
  useGetAllEmployersByIcpIdQuery,
  useGetEmployerByIdQuery
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface AddPayComponentUpdateProps {}

export function AddPayComponentUpdate(props: AddPayComponentUpdateProps) {
  const { t } = useTranslation()
  const createPayComponentUpdateSchema = object({
    payrollProviderId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    dueDate: date({
      required_error: t('form.validation.duedaterequired'),
      invalid_type_error: t('form.validation.duedaterequired')
    }),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string({
      required_error: t('form.validation.paygrouprequired'),
      invalid_type_error: t('form.validation.paygrouprequired')
    }).min(1, t('form.validation.paygrouprequired')),
    processName: string({
      required_error: t('form.validation.processnamerequired'),
      invalid_type_error: t('form.validation.processnamerequired')
    }).min(1, t('form.validation.processnamerequired')),
    notes: string().optional().nullable()
  })
  type CreatePayComponentUpdateForm = TypeOf<typeof createPayComponentUpdateSchema>

  const { closeFlyIn } = useFlyIn()
  const { icpOptions } = formTypeSelectOptions
  const [employerName, setEmployerName] = useState('')
  const [payGroup, setPayGroup] = useState('')
  const mutation = useCreatePayComponentUpdateProcessMutation()
  const form = useForm<CreatePayComponentUpdateForm>({
    resolver: zodResolver(createPayComponentUpdateSchema)
  })
  const { control } = form

  useEffect(() => {
    if (employerName || payGroup) {
      form.resetField('processName', {
        defaultValue: `${employerName} ${payGroup && ` - ${payGroup}`} `
      })
    } else {
      form.resetField('processName', { defaultValue: '' })
    }
  }, [employerName, payGroup])

  const watchedPayrollProviderId = useWatch({
    control,
    name: `payrollProviderId`
  })
  const watchedEmployerId = useWatch({ control, name: `employerId` })

  const { data: getAllEmployersByIcpId } = useGetAllEmployersByIcpIdQuery(
    {
      icpId: watchedPayrollProviderId || '',
      limit: -1,
      offset: 0,
      orderDirection: OrderDirection.Asc,
      sortByProperty: 'companyName',
      onlyCalculatedByEpix: true
    },
    {
      enabled: !!watchedPayrollProviderId
    }
  )
  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: watchedEmployerId || ''
    },
    {
      enabled: !!watchedEmployerId
    }
  )

  const handleOnSubmit = async (payComponentUpdate: CreatePayComponentUpdateForm) => {
    await mutation
      .mutateAsync({
        createPayComponentUpdateProcessCommand: {
          payrollProviderId: payComponentUpdate.payrollProviderId,
          employerId: payComponentUpdate.employerId,
          payGroupId: payComponentUpdate.payGroupId,
          dueDate: payComponentUpdate.dueDate,
          name: payComponentUpdate.processName,
          notes: payComponentUpdate.notes
        }
      })
      .then(() => {
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addpaycomponentupdateprocess.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollProviderId"
          label={`${t('form.field.payrollprovider')} *`}
          options={icpOptions}
        />

        <FormSelect
          sx={12}
          name="employerId"
          label={`${t('form.field.employer')} *`}
          options={getAllEmployersByIcpId?.employersByIcpId?.data.map(
            employer => new FormSelectOption(employer.id, `${employer.companyName} (${employer.number})`)
          )}
          onChange={(e, selectedEmployer) => {
            setEmployerName(selectedEmployer?.label || '')
            setPayGroup('')
            form.resetField('payGroupId', { defaultValue: null })
          }}
        />

        <FormSelect
          sx={12}
          name="payGroupId"
          label={`${t('form.field.paygroup')} *`}
          options={getEmployerById?.employerById?.payGroups.map(
            x => new FormSelectOption(x.id, `${x.name} (${x.code})`)
          )}
          onChange={(e, selectedPayGroup) => {
            setPayGroup(selectedPayGroup?.label || '')
          }}
        />

        <FormDatepicker sx={6} name="dueDate" label={`${t('form.field.duedate')} *`} />

        <FormInput sx={12} name="processName" label={`${t('form.field.processname')} *`} />

        <FormInput sx={12} name="notes" label={`${t('form.field.notes')}`} multiline />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddPayComponentUpdate
