import { useMsal } from '@azure/msal-react'
import { ROLE, isSelfServiceUser } from '@epix-web-apps/core'
import { CanView, Configuration, SelfService, useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import KeyIcon from '@mui/icons-material/Key'
import LanguageIcon from '@mui/icons-material/Language'
import LogoutIcon from '@mui/icons-material/Logout'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import PersonIcon from '@mui/icons-material/Person'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import { Avatar, Divider, IconButton, ListItemIcon, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { logoutRequest, resetPasswordRequest } from '../../../../msal-config'
import ChangeCurrency from '../change-currency'
import ChangeLocale from '../change-locale'
import DropdownMenu from '../dropdown-menu'

/* eslint-disable-next-line */
export interface UserMenuProps {}

export function UserMenu(props: UserMenuProps) {
  const { me } = useGlobalStore()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { openFlyIn } = useFlyIn()
  const { instance: msalInstance } = useMsal()
  const handleClose = () => setAnchorEl(null)
  const handleLogout = () => msalInstance.logout(logoutRequest)
  const handleResetPassword = () => msalInstance.loginRedirect(resetPasswordRequest)
  const showSelfServiceSwitch = isSelfServiceUser(me?.roles)

  return (
    <>
      <Tooltip title={t('usermenu.tooltip.opensettings')}>
        <IconButton
          sx={{ p: 0 }}
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={e => setAnchorEl(e.currentTarget)}
          color="inherit"
        >
          <Avatar alt="U">
            <PersonIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <DropdownMenu anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose()
            openFlyIn({
              content: <ChangeLocale />,
              callbackAfterClose: () => () => navigate(0)
            })
          }}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          {t('usermenu.changelanguage')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            openFlyIn({
              content: <ChangeCurrency />
            })
          }}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          {t('usermenu.changecurrency')}
        </MenuItem>
        <MenuItem onClick={handleResetPassword}>
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          {t('usermenu.changepassword')}
        </MenuItem>
        <CanView roles={[ROLE.ADMIN, ROLE.MANAGEUSERSANDPOLICIES]}>
          <Divider />
        </CanView>
        <CanView roles={[ROLE.ADMIN, ROLE.MANAGEUSERSANDPOLICIES]}>
          <MenuItem onClick={() => navigate(Configuration.USERS())}>
            <ListItemIcon>
              <ManageAccountsIcon />
            </ListItemIcon>
            {t('usermenu.manageusers')}
          </MenuItem>
        </CanView>
        <CanView roles={[ROLE.ADMIN, ROLE.MANAGETEAMSANDROLES]}>
          <MenuItem onClick={() => navigate(Configuration.TEAMS())}>
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            {t('usermenu.manageteams')}
          </MenuItem>
        </CanView>
        <CanView roles={[ROLE.TENANTADMIN]}>
          <MenuItem onClick={() => navigate(Configuration.TENANT_CONFIGURATION())}>
            <ListItemIcon>
              <ConstructionOutlinedIcon />
            </ListItemIcon>
            {t('usermenu.managetenant')}
          </MenuItem>
        </CanView>

        {showSelfServiceSwitch && <Divider />}
        {showSelfServiceSwitch && (
          <MenuItem
            onClick={() => {
              window.location.replace(SelfService.ROOT())
            }}
          >
            <ListItemIcon>
              <PhoneAndroidOutlinedIcon />
            </ListItemIcon>
            {t('usermenu.switchtoselfservice')}
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {t('usermenu.signout')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default UserMenu
