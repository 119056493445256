import {
    RequestPolicyAutomatedPayrollCodeModel,
    useDeleteRequestPolicyAutomatedPayrollCodeMutation
} from '@epix-web-apps/core'
import { DeleteOutline } from '@mui/icons-material'
import { IconButton, Radio, TableCell, TableRow } from '@mui/material'
import { ValidityTypes } from '../add-edit-automated-payrollcodes'

export function AutomatedCounterRow({
  automatedPayrollCode,
  selectedPayrollCode,
  requestPolicyId,
  onSelect,
  onDelete
}: {
  automatedPayrollCode: RequestPolicyAutomatedPayrollCodeModel
  selectedPayrollCode: RequestPolicyAutomatedPayrollCodeModel | null
  requestPolicyId: string
  onSelect: () => void
  onDelete: (deletedId: string) => void
}) {
  const deleteMutation = useDeleteRequestPolicyAutomatedPayrollCodeMutation()

  function handleDelete() {
    deleteMutation
      .mutateAsync({
        command: {
          requestPolicyId: requestPolicyId,
          requestPolicyPayrollCodeId: automatedPayrollCode.requestPolicyPayrollCodeId,
          requestPolicyAutomatedPayrollCodeId: automatedPayrollCode.id
        }
      })
      .then(r => {
        onDelete(r.deleteRequestPolicyAutomatedPayrollCode)
      })
  }

  return (
    <TableRow key={automatedPayrollCode.id}>
      <TableCell sx={{ p: 0 }} align="right">
        <Radio sx={{ p: 0 }} onClick={() => onSelect()} checked={automatedPayrollCode.id === selectedPayrollCode?.id} />
      </TableCell>
      <TableCell>{automatedPayrollCode.calendarPayrollCodeDescription}</TableCell>
      <TableCell>{automatedPayrollCode.validityType.value}</TableCell>
      <TableCell>
        {automatedPayrollCode.validityType.key === ValidityTypes.AMOUNT_IN_MONTHS
          ? automatedPayrollCode.validityInMonths
          : ''}
      </TableCell>
      <TableCell sx={{ p: 0 }} align="center">
        <IconButton size="small" disabled={deleteMutation.isPending} onClick={handleDelete}>
          <DeleteOutline />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
