import {
  useDeletePersonMutation,
  useSuspenseGetAllContractsWithEmployerByPersonIdQuery,
  useSuspenseGetPersonByIdQuery
} from '@epix-web-apps/core'
import { EpixAvatar, FormActionButtons, FormContainer, FormInput, People } from '@epix-web-apps/ui'
import { Box, Divider, Link, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IcpCodeBadge } from '../../icp-components'

export interface RemovePersonFlyinProps {
  personId: string
  closeFlyIn: () => void
}
export function RemovePersonFlyin({ personId, closeFlyIn }: RemovePersonFlyinProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [confirmText, setConfirmText] = useState('')

  const deleteMutation = useDeletePersonMutation()

  const { data: getPersonByIdQuery } = useSuspenseGetPersonByIdQuery({
    personId: personId
  })

  const person = getPersonByIdQuery?.personById

  const { data: getAllContracts } = useSuspenseGetAllContractsWithEmployerByPersonIdQuery({
    personId: personId
  })

  const form = useForm()

  const canDelete =
    getAllContracts?.allContractsWithEmployerByPersonId &&
    getAllContracts?.allContractsWithEmployerByPersonId.length === 0 &&
    confirmText === person?.displayName

  function goToPersonDetailPage() {
    navigate(People.PEOPLE_ID_DETAILS(`${personId}`))
    closeFlyIn()
  }

  function handleDelete() {
    deleteMutation
      .mutateAsync({
        deletePersonCommand: {
          personId: personId
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleDelete)}>
      <Typography variant="h4">Delete person</Typography>

      <Box sx={{ pb: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <EpixAvatar personId={person?.id ?? ''} />
          <Box>
            <Link onClick={goToPersonDetailPage}>
              {person?.displayName} {person?.company.personNumber && `- ${person?.company.personNumber}`}
            </Link>
            <Typography>{person?.company.functionTitle}</Typography>
          </Box>
        </Box>
        <Divider sx={{ my: '0.5rem' }} />

        {getAllContracts?.allContractsWithEmployerByPersonId &&
        getAllContracts?.allContractsWithEmployerByPersonId.length > 0 ? (
          <>
            <Typography sx={{ pb: '0.5rem' }}>
              {person?.displayName} {t('peoplepage.delete.hascontracts')}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                ml: '0.5rem'
              }}
            >
              {getAllContracts.allContractsWithEmployerByPersonId.map(c => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} key={c.id}>
                  <IcpCodeBadge icpCode={c.countryKey} />
                  <Box>
                    <Typography>
                      {c.employerName} - {c.employerNumber}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ pb: '0.5rem', color: theme.palette.text.secondary }}>
              {t('peoplepage.delete.info')}
            </Typography>

            <Typography sx={{ pb: '0.25rem' }}>Type '{person?.displayName}' to confirm</Typography>
            <FormInput onChange={e => setConfirmText(e.target.value)} name="confirm" label={'Confirm'} sx={12} />
          </>
        )}
      </Box>

      <FormActionButtons
        isMutating={deleteMutation.isPending}
        disabled={!canDelete}
        buttonText={t('common.delete')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemovePersonFlyin
