import {
  GetExternalCodeByKeyQuery,
  OrderDirection,
  useCreateExternalCodeTypeMutation,
  useSuspenseGetExternalCodeByKeyQuery,
  useSuspenseGetPagedExternalCodesQuery,
  useUpdateExternalCodeTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object, string } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface EditExternalCodeProps {
  externalCodeKey: string
}

export function AddExternalCode() {
  return AddEditExternalCode()
}

export function EditExternalCode({ externalCodeKey }: EditExternalCodeProps) {
  const getExternalCodeByKeyQuery = useSuspenseGetExternalCodeByKeyQuery({
    key: externalCodeKey
  })

  return AddEditExternalCode(getExternalCodeByKeyQuery)
}

export function AddEditExternalCode(
  getExternalCodeByKeyQuery?: UseSuspenseQueryResult<GetExternalCodeByKeyQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditExternalCodeSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    })
      .min(1, t('form.validation.keyrequired'))
      .refine(
        data => {
          if (getExternalCodeByKeyQuery) return true
          return !existingExternalCodes?.pagedExternalCodes?.data?.some(e => e.key === data)
        },
        {
          message: t('form.validation.externalcodealreadyexists')
        }
      ),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    active: boolean()
  })

  type AddEditExternalCodeForm = TypeOf<typeof addEditExternalCodeSchema>

  const { data: existingExternalCodes } = useSuspenseGetPagedExternalCodesQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'Key',
    orderDirection: OrderDirection.Desc
  })

  const getExternalCodeByKey = getExternalCodeByKeyQuery?.data

  const form = useForm<AddEditExternalCodeForm>({
    resolver: zodResolver(addEditExternalCodeSchema),
    defaultValues: {
      key: getExternalCodeByKey?.externalCodeByKey?.key,
      value: getExternalCodeByKey?.externalCodeByKey?.value,
      active: getExternalCodeByKey?.externalCodeByKey?.active ?? true
    }
  })

  const createMutation = useCreateExternalCodeTypeMutation()
  const updateMutation = useUpdateExternalCodeTypeMutation()

  const handleOnSubmit = async (newExternalCode: AddEditExternalCodeForm) => {
    if (!getExternalCodeByKeyQuery) {
      await createMutation
        .mutateAsync({
          createExternalCodeTypeCommand: {
            key: newExternalCode.key,
            value: newExternalCode.value,
            active: newExternalCode.active
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateExternalCodeTypeCommand: {
            key: newExternalCode.key,
            value: newExternalCode.value,
            active: newExternalCode.active
          }
        })
        .then(() => {
          getExternalCodeByKeyQuery.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditexternalcode.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!getExternalCodeByKeyQuery} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
      </FormGridLayout>

      <FormErrorList />

      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditExternalCode
