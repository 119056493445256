import styled from '@emotion/styled'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  CalendarDayEntryModel,
  CollectiveScheduleDayViewModel,
  DEFAULT_BASED_ON_WORKSCHEDULE,
  DayDefinitionEntryViewModel,
  OrderDirection,
  PersonalCalendarDayViewModel,
  ToBackendFormatedDate,
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetAllContractsByPersonIdAndTeamStructureQuery,
  useSuspenseGetAllValidationErrorsByPersonIdForCurrentUserQuery,
  useSuspenseGetCalendarForContractIdsAndTeamStructureQuery,
  useSuspenseGetRequestPolicyContractsByPersonIdByTeamStructureQuery
} from '@epix-web-apps/core'
import { DocumentList, SelfService, useGlobalStore, useRouteDefinitions } from '@epix-web-apps/ui'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import EventIcon from '@mui/icons-material/Event'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, Grid, IconButton, Link, Tooltip, Typography, useTheme } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { addDays, parseISO } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import TasksWarning from '../../tasks/tasks-warning'

/* eslint-disable-next-line */
export interface DayDetailCalendarProps {}

interface TooltipState {
  [key: string]: boolean
}

export type DayDetailCalendarQueryParams = {
  personId: string
  teamId: string
}

export function DayDetailCalendar(props: DayDetailCalendarProps) {
  const params = useRequiredParams<DayDetailCalendarQueryParams>()
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigateWithParams()
  const routes = useRouteDefinitions()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showTooltip, setShowToolTip] = useState<TooltipState>({})
  const { me } = useGlobalStore()

  const searchParamDate = searchParams.get('date')
  const dateFromSearchParams = searchParamDate != null ? parseISO(searchParamDate) : new Date()

  const { data: getAllContracts } = useSuspenseGetAllContractsByPersonIdAndTeamStructureQuery({
    personId: params.personId
  })

  const { data: validations } = useSuspenseGetAllValidationErrorsByPersonIdForCurrentUserQuery({
    showHasSeen: true,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const anyValidations = validations?.allValidationErrorsByPersonIdForCurrentUser.data.find(
    x => x.arguments !== '' && x.arguments && searchParamDate === x.arguments?.split(';')[0]
  )

  const { data: calendarData } = useSuspenseGetCalendarForContractIdsAndTeamStructureQuery({
    contractIds: getAllContracts?.allContractsByPersonIdAndTeamStructure.map(x => x.contractId) || '',
    startDate: dateFromSearchParams,
    endDate: dateFromSearchParams
  })

  const { data: getRequestPolicyContractsByPersonIdQuery } =
    useSuspenseGetRequestPolicyContractsByPersonIdByTeamStructureQuery()

  function hasRequestPolicies() {
    const requestPolicies = getRequestPolicyContractsByPersonIdQuery?.allRequestPolicyContractsByPersonIdByTeamStructure
    return requestPolicies ? requestPolicies.length > 0 : false
  }

  const StyledBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    padding: '0 0.5rem'
  }))

  const handleTooltipToggle = (id: string) => {
    setShowToolTip(prevShowTooltip => ({
      [id]: !prevShowTooltip[id]
    }))
  }

  function handleNextDay() {
    searchParams.set('date', ToBackendFormatedDate(addDays(dateFromSearchParams, 1)))
    setSearchParams(searchParams)
  }

  function handlePreviousDay() {
    searchParams.set('date', ToBackendFormatedDate(addDays(dateFromSearchParams, -1)))
    setSearchParams(searchParams)
  }

  return (
    <Box>
      <Box
        sx={{
          marginBottom: '1rem',
          marginLeft: '-0.5rem',
          display: 'flex'
        }}
      >
        <IconButton
          sx={{ mr: 1, color: theme.palette.primary.main }}
          onClick={() =>
            !searchParams.get('teamId')
              ? navigate(routes.SelfService.ROOT)
              : navigate(routes.SelfService.PEOPLE_ID_CALENDAR(params.personId))
          }
        >
          <ArrowBackIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 'auto'
          }}
        >
          <IconButton sx={{ color: theme.palette.primary.main }} onClick={handlePreviousDay}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography m={1} variant="h4">
            {dateFromSearchParams.toLocaleDateString()}
          </Typography>
          <IconButton onClick={handleNextDay}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
      {calendarData?.calendarDaysByContractIdsAndTeamStructure.map(
        (contract, index) =>
          contract.days[0].calculatedEntries.length !== 0 && (
            <Box mb={2} key={index}>
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <Box width={'100%'}>
                    {contract.days[0].calculatedEntries.length !== 0 && (
                      <Grid container spacing={1}>
                        {contract.days[0].calculatedEntries.map((item: CalendarDayEntryModel, subIndex: number) => {
                          return (
                            <Grid item xs={12} key={subIndex}>
                              <Grid container spacing={2} fontSize="0.8rem">
                                <Grid item xs={6}>
                                  {item.payrollCodeUserFriendlyDescription ?? item.payrollCodeDescription}{' '}
                                  {item.shiftCodeDescription ? `(${item.shiftCodeDescription})` : ''}
                                </Grid>
                                <Grid item xs={4}>
                                  {item.timeFrameType.value}
                                </Grid>
                                <Grid item xs={2}>
                                  {item.numberOfHours}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        })}
                      </Grid>
                    )}
                    {contract.days[0].calculatedEntries.some(e => e.absenceRequestCalendarModel != null) && (
                      <>
                        {contract.days[0].calculatedEntries.some(
                          e =>
                            e.absenceRequestCalendarModel?.absenceRequestType?.key ===
                            ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                        ) && (
                          <Grid container mt={1} rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                            <StyledBox key={index}>
                              <Grid item sm={12}>
                                <Box display={'flex'}>
                                  <ContactSupportOutlinedIcon
                                    sx={{ color: theme.palette.info.main, mr: 1 }}
                                    fontSize="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: '1em',
                                      mt: 0,
                                      color: theme.palette.info.main
                                    }}
                                  >
                                    {t('flyin.calendar-entry.somependingrequests')}
                                  </Typography>
                                </Box>
                              </Grid>
                            </StyledBox>
                          </Grid>
                        )}

                        {contract.days[0].calculatedEntries.every(
                          e =>
                            e.absenceRequestCalendarModel?.absenceRequestType?.key ===
                              ABSENCE_REQUEST_STATUS_TYPE.APPROVED || !e.absenceRequestCalendarModel
                        ) && (
                          <Grid container mt={1} rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                            <StyledBox key={index}>
                              <Grid item sm={12}>
                                <Box display={'flex'}>
                                  <CheckOutlinedIcon
                                    sx={{
                                      color: theme.palette.success.main,
                                      mr: 1
                                    }}
                                    fontSize="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: '1em',
                                      mt: 0,
                                      color: theme.palette.success.main
                                    }}
                                  >
                                    {t('flyin.calendar-entry.allrequestsapproved')}
                                  </Typography>
                                </Box>
                              </Grid>
                            </StyledBox>
                          </Grid>
                        )}
                        {anyValidations && (
                          <Grid container mt={1} rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                            <StyledBox key={index}>
                              <Grid item sm={12}>
                                <TasksWarning basicView={true} />
                              </Grid>
                            </StyledBox>
                          </Grid>
                        )}
                      </>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Typography variant="h5">
                      {contract.icpCode}: {parseISO(contract.contractStartDate).toLocaleDateString()} -{' '}
                      {contract.contractEndDate ? parseISO(contract.contractEndDate).toLocaleDateString() : '...'}
                    </Typography>
                    {contract.days[0].personalCalendarDays && contract.days[0].personalCalendarDays?.length !== 0 && (
                      <>
                        <Typography variant="h4">{t('flyin.calendar-entry.personal-calendar')}</Typography>
                        {contract.days[0].personalCalendarDays?.map(
                          (item: PersonalCalendarDayViewModel, index: number) => {
                            return (
                              <>
                                <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                                  <StyledBox key={index}>
                                    <Grid item xs={6}>
                                      <p>{item.payrollCodeUserFriendlyDescription ?? item.payrollCodeDescription}</p>
                                    </Grid>
                                    <Grid item xs={4}>
                                      {item.timeFrameType.value}
                                    </Grid>
                                    {item.timeOrderedType.key === DEFAULT_BASED_ON_WORKSCHEDULE &&
                                    item.numberOfHours === 0 ? (
                                      <Grid item xs={2}>
                                        <Tooltip
                                          open={showTooltip[item.personalCalendarDayId] ?? false}
                                          onOpen={() => handleTooltipToggle(item.personalCalendarDayId)}
                                          onClose={() => handleTooltipToggle(item.personalCalendarDayId)}
                                          title={t('flyin.calendar-entry.basedonworkschedule')}
                                        >
                                          <IconButton
                                            sx={{ m: 0, p: 0 }}
                                            onClick={() => handleTooltipToggle(item.personalCalendarDayId)}
                                          >
                                            <InfoOutlinedIcon fontSize="small" />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    ) : (
                                      <Grid item xs={2}>
                                        <p>{item.numberOfHours}</p>
                                      </Grid>
                                    )}
                                  </StyledBox>
                                </Grid>
                                {item.documents.length > 0 && (
                                  <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                                    <StyledBox key={index}>
                                      <Grid item xs={12} pb={1}>
                                        <DocumentList documents={item.documents} />
                                        {item.comment}
                                      </Grid>
                                    </StyledBox>
                                  </Grid>
                                )}
                                <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                                  <StyledBox key={index}>
                                    <Grid item xs={12}>
                                      {item.comment && (
                                        <Box display={'flex'}>
                                          <CommentOutlinedIcon
                                            sx={{
                                              color: theme.palette.text.secondary,
                                              mr: 1
                                            }}
                                            fontSize="small"
                                          />
                                          <Typography
                                            sx={{
                                              fontSize: '1em',
                                              mt: 0,
                                              color: theme.palette.text.secondary,
                                              fontStyle: 'italic'
                                            }}
                                          >
                                            {item.comment}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Grid>
                                  </StyledBox>
                                </Grid>

                                {item.absenceRequestCalendarModel &&
                                  item.absenceRequestCalendarModel?.absenceRequestType?.key ===
                                    ABSENCE_REQUEST_STATUS_TYPE.REQUESTED && (
                                    <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                                      <StyledBox key={index}>
                                        <Grid item sm={12}>
                                          <Link
                                            color={theme.palette.info.main}
                                            onClick={() =>
                                              navigate(
                                                SelfService.MY_REQUESTS_ID(`${item.absenceRequestCalendarModel?.id}`)
                                              )
                                            }
                                          >
                                            <Box display={'flex'}>
                                              <ContactSupportOutlinedIcon
                                                sx={{ color: theme.palette.info.main, mr: 1 }}
                                                fontSize="small"
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: '1em',
                                                  mt: 0,
                                                  color: theme.palette.info.main
                                                }}
                                              >
                                                {t('flyin.calendar-entry.pendingrequests')}
                                              </Typography>
                                            </Box>
                                          </Link>
                                        </Grid>
                                      </StyledBox>
                                    </Grid>
                                  )}
                                {item.absenceRequestCalendarModel &&
                                  item.absenceRequestCalendarModel?.absenceRequestType?.key ===
                                    ABSENCE_REQUEST_STATUS_TYPE.APPROVED && (
                                    <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                                      <StyledBox key={index}>
                                        <Grid item sm={12}>
                                          <Link
                                            color={theme.palette.success.main}
                                            onClick={() =>
                                              navigate(
                                                SelfService.MY_REQUESTS_ID(`${item.absenceRequestCalendarModel?.id}`)
                                              )
                                            }
                                          >
                                            <Box display={'flex'}>
                                              <CheckOutlinedIcon
                                                sx={{
                                                  color: theme.palette.success.main,
                                                  mr: 1
                                                }}
                                                fontSize="small"
                                              />
                                              <Typography
                                                sx={{
                                                  fontSize: '1em',
                                                  mt: 0,
                                                  color: theme.palette.success.main
                                                }}
                                              >
                                                {t('flyin.calendar-entry.approvedrequest')}{' '}
                                                {item.absenceRequestCalendarModel?.approvedByPersonFistName}{' '}
                                                {item.absenceRequestCalendarModel?.approvedByPersonPreferredLastName}{' '}
                                                {t('flyin.calendar-entry.on')}{' '}
                                                {new Date(
                                                  item.absenceRequestCalendarModel?.approvedOn
                                                ).toLocaleDateString()}
                                              </Typography>
                                            </Box>
                                          </Link>
                                        </Grid>
                                      </StyledBox>
                                    </Grid>
                                  )}
                              </>
                            )
                          }
                        )}
                      </>
                    )}

                    {contract.days[0].collectiveSchedule?.collectiveScheduleDays &&
                      contract.days[0].collectiveSchedule.collectiveScheduleDays.length !== 0 && (
                        <>
                          <Typography variant="h4">{t('flyin.calendar-entry.collective-schedule')}</Typography>
                          <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                            {contract.days[0].collectiveSchedule?.collectiveScheduleDays?.map(
                              (item: CollectiveScheduleDayViewModel, index: number) => {
                                return (
                                  <StyledBox key={index}>
                                    <Grid item xs={6}>
                                      {item.payrollCodeUserFriendlyDescription ?? item.payrollCodeDescription}
                                    </Grid>
                                    <Grid item xs={4}>
                                      {item.timeFrameType.value}
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Tooltip title={t('flyin.calendar-entry.basedonworkschedule')}>
                                        <IconButton sx={{ m: 0, p: 0 }}>
                                          <InfoOutlinedIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  </StyledBox>
                                )
                              }
                            )}
                          </Grid>
                        </>
                      )}

                    {contract.days[0].workScheduleDay?.dayDefinitionEntries &&
                      contract.days[0].workScheduleDay?.dayDefinitionEntries?.length !== 0 &&
                      !contract.days[0].workScheduleDay?.overwrittenByAlternate && (
                        <>
                          <Typography variant="h4">
                            {t('flyin.calendar-entry.workschedule') +
                              ' - ' +
                              contract.days[0].workScheduleDay?.labelOnCalendar}
                          </Typography>
                          <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                            {contract.days[0].workScheduleDay?.dayDefinitionEntries?.map(
                              (item: DayDefinitionEntryViewModel, index: number) => {
                                return (
                                  <StyledBox key={index}>
                                    <Grid item xs={6}>
                                      {item.payrollCodeUserFriendlyDescription ?? item.payrollCodeDescription}{' '}
                                      {contract.days[0].workScheduleDay?.shiftCodeDescription
                                        ? `(${contract.days[0].workScheduleDay.shiftCodeDescription})`
                                        : ''}
                                    </Grid>
                                    <Grid item xs={4}>
                                      {item.timeFrameType.value}
                                    </Grid>
                                    <Grid item xs={2}>
                                      {item.hours}
                                    </Grid>
                                  </StyledBox>
                                )
                              }
                            )}
                          </Grid>
                        </>
                      )}

                    {contract.days[0].workScheduleDay?.overwrittenByAlternate && (
                      <>
                        <Typography variant="h4">
                          {t('flyin.calendar-entry.workschedule') +
                            ' - ' +
                            contract.days[0].workScheduleDay.labelOnCalendar}
                        </Typography>
                        <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                          {contract.days[0].workScheduleDay?.dayDefinitionEntries.map((entry, index) => {
                            return (
                              <StyledBox key={index}>
                                <Grid item xs={6}>
                                  {entry.payrollCodeUserFriendlyDescription ?? entry.payrollCodeDescription}{' '}
                                  {contract.days[0].workScheduleDay?.shiftCodeDescription
                                    ? `(${contract.days[0].workScheduleDay.shiftCodeDescription})`
                                    : ''}
                                </Grid>
                                <Grid item xs={4}>
                                  {entry.timeFrameType.value ?? t('flyin.calendar-entry.notworked')}
                                </Grid>
                                <Grid item xs={2}>
                                  {entry.hours ?? 0}
                                </Grid>
                              </StyledBox>
                            )
                          })}
                        </Grid>
                      </>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )
      )}
      {me?.personId === params.personId && hasRequestPolicies() && (
        <Box>
          <Button
            variant="outlined"
            size="small"
            startIcon={<EventIcon />}
            onClick={() =>
              navigate(SelfService.REQUEST_ABSENCE_PERSONID(`${me?.personId}`), {
                date: ToBackendFormatedDate(dateFromSearchParams)
              })
            }
          >
            {t('selfservice.balances.requestabsence')}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default DayDetailCalendar
