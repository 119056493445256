import {
  PayrollClosureStep,
  PROCESS_PAYROLLCLOSURE_PARAMETER,
  PROCESS_TYPE,
  useSuspenseGetFeaturesQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetPayrollClosureProcessQuery,
  useSuspenseGetSelectedPayrollClosureProcessContractsQuery,
  useUpdatePayrollClosureProcessContractsMutation,
  useUpdatePayrollClosureProcessStepMutation,
  useRequiredParams,
  useUpdateProcessMutation
} from '@epix-web-apps/core'
import {
  EmptyState,
  FormContainer,
  GeneralRoutes,
  HeaderTitleNavigation,
  Process,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, LinearProgress, Paper, Step, StepLabel, Stepper, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import { date, object, string, TypeOf } from 'zod'
import { Finish, Start, StepParams } from '../generic-steps'
import { PayrollClosureErrorBoundaryHandler } from './payroll-closure-error-boundary-handler'
import {
  CalculatedCalendarData,
  CalendarData,
  FinalCheck,
  Parameters,
  PersonalContractData,
  Population,
  SalaryData
} from './steps'

function isCurrentStepOrLater(steps: PayrollClosureStep[], activeStep: PayrollClosureStep, step: PayrollClosureStep) {
  const activeStepIndex = steps.findIndex(s => s === activeStep)
  const stepToCheckIndex = steps.findIndex(s => s === step)

  return activeStepIndex >= stepToCheckIndex
}

/* eslint-disable-next-line */
export interface PayrollClosureProps {}

export function PayrollClosure(props: PayrollClosureProps) {
  const { t } = useTranslation()
  const { data: me } = useSuspenseGetMeQuery()
  const theme = useTheme()
  const params = useRequiredParams<StepParams>()
  const processId = params.id

  const updateProcessStepMutation = useUpdatePayrollClosureProcessStepMutation()
  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: processId
  })

  const { data: getFeatures } = useSuspenseGetFeaturesQuery()

  const showCalculatedCalendar = getFeatures?.features.calculatedCalendarView ?? true

  const allSteps = [
    { step: PayrollClosureStep.Start, label: t('wizard.step.start') },
    { step: PayrollClosureStep.Parameters, label: t('wizard.step.parameters') },
    { step: PayrollClosureStep.Population, label: t('wizard.step.population') },
    { step: PayrollClosureStep.PersonalAndContractData, label: t('wizard.step.personalandcontractdata') },
    { step: PayrollClosureStep.SalaryComponents, label: t('wizard.step.paycomponents') },
    { step: PayrollClosureStep.CalendarComponents, label: t('wizard.step.calendarcomponents') },
    { step: PayrollClosureStep.FinalCheck, label: t('wizard.step.finalcheck') },
    { step: PayrollClosureStep.Finish, label: t('wizard.step.finish') }
  ]

  const steps = allSteps.filter(s => getPayrollClosureProcess.payrollClosureProcess.steps.includes(s.step))
  const currentStepValue =
    getPayrollClosureProcess?.payrollClosureProcess.currentStepValue ?? steps?.[0]?.step ?? PayrollClosureStep.Start
  const [activeStep, setActiveStep] = useState(currentStepValue)

  function nextStep() {
    if (currentStepValue == null || processId == null) return

    const currentStepIndex = steps.findIndex(s => s.step === activeStep)
    if (currentStepIndex == null) return

    if (currentStepIndex + 1 >= steps.length) return

    const nextStep = steps[currentStepIndex + 1]
    if (nextStep == null) return

    updateProcessStepMutation
      .mutateAsync({ updatePayrollClosureProcessStep: { processId: params?.id ?? '', step: nextStep.step } })
      .then(() => {
        setActiveStep(nextStep.step)
      })
  }

  function previousStep() {
    const currentStepIndex = steps.findIndex(s => s.step === activeStep)
    if (currentStepIndex == null || currentStepIndex - 1 < 0) return
    const nextStep = steps[currentStepIndex - 1]
    setActiveStep(nextStep.step)
  }

  const payrollProcessSchema = object({
    payrollProviderId: string({
      required_error: t('form.validation.contractproviderrequired'),
      invalid_type_error: t('form.validation.contractproviderrequired')
    }).min(1, t('form.validation.contractproviderrequired')),
    dueDate: date({
      required_error: t('form.validation.duedaterequired'),
      invalid_type_error: t('form.validation.duedaterequired')
    }),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    payGroupId: string({
      required_error: t('form.validation.paygrouprequired'),
      invalid_type_error: t('form.validation.paygrouprequired')
    }).min(1, t('form.validation.paygrouprequired')),
    processName: string({
      required_error: t('form.validation.processnamerequired'),
      invalid_type_error: t('form.validation.processnamerequired')
    }).min(1, t('form.validation.processnamerequired')),
    notes: string().optional().nullable(),
    parameterTypeKey: string({
      required_error: t('form.validation.parameterrequired'),
      invalid_type_error: t('form.validation.parameterrequired')
    }).min(1, t('form.validation.parameterrequired')),
    periodStartDate: date().optional(),
    periodEndDate: date().optional(),
    contractIds: string()
      .array()
      .refine(
        data =>
          !(
            isCurrentStepOrLater(
              getPayrollClosureProcess?.payrollClosureProcess.steps ?? [],
              activeStep,
              PayrollClosureStep.Population
            ) && data.length < 1
          ),
        {
          message: t('form.validation.populationrequired')
        }
      )
  }).refine(
    data =>
      data.parameterTypeKey === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT
        ? data.periodStartDate
        : date(),
    {
      message: t('form.validation.periodrequired'),
      path: ['period']
    }
  )
  type payrollProcessForm = TypeOf<typeof payrollProcessSchema>

  const { data: getSelectedProcessContracts } = useSuspenseGetSelectedPayrollClosureProcessContractsQuery({
    id: params.id
  })
  const updateProcessMutation = useUpdateProcessMutation()
  const updateProcessContractsMutation = useUpdatePayrollClosureProcessContractsMutation()

  const form = useForm<payrollProcessForm>({
    resolver: zodResolver(payrollProcessSchema),
    defaultValues: {
      payrollProviderId: getPayrollClosureProcess?.payrollClosureProcess.payrollProviderId,
      dueDate: getPayrollClosureProcess?.payrollClosureProcess.dueDate
        ? parseISO(getPayrollClosureProcess?.payrollClosureProcess.dueDate)
        : undefined,
      employerId: getPayrollClosureProcess?.payrollClosureProcess.employerId,
      payGroupId: getPayrollClosureProcess?.payrollClosureProcess.payGroup.id,
      processName: getPayrollClosureProcess?.payrollClosureProcess.name,
      notes: getPayrollClosureProcess?.payrollClosureProcess.notes,
      periodStartDate: getPayrollClosureProcess?.payrollClosureProcess.startDate
        ? parseISO(getPayrollClosureProcess?.payrollClosureProcess.startDate)
        : undefined,
      periodEndDate: getPayrollClosureProcess?.payrollClosureProcess.endDate
        ? parseISO(getPayrollClosureProcess?.payrollClosureProcess.endDate)
        : undefined,
      parameterTypeKey: getPayrollClosureProcess?.payrollClosureProcess.parameterType?.key,
      contractIds: getSelectedProcessContracts?.selectedPayrollClosureProcessContracts || []
    }
  })
  const {
    clearErrors,
    formState: { errors },
    handleSubmit
  } = form

  const handlePrevious = () => {
    clearErrors()
    previousStep()
  }

  const handleUpdateProcessInfo = (payrollClosure: payrollProcessForm) => {
    updateProcessMutation
      .mutateAsync({
        updateProcessCommand: {
          processId: params.id || '',
          payrollProviderId: getPayrollClosureProcess?.payrollClosureProcess.payrollProviderId || '',
          dueDate: payrollClosure.dueDate,
          employerId: getPayrollClosureProcess?.payrollClosureProcess.employerId || '',
          payGroupId: getPayrollClosureProcess?.payrollClosureProcess.payGroup.id || '',
          name: payrollClosure.processName,
          notes: payrollClosure.notes,
          cancelled: getPayrollClosureProcess?.payrollClosureProcess.cancelled ?? false
        }
      })
      .then(() => {
        nextStep()
      })
  }

  const handleSelectContractsStep = (payrollClosure: payrollProcessForm) => {
    updateProcessContractsMutation
      .mutateAsync({
        updatePayrollClosureProcessContractsCommand: {
          processId: params.id || '',
          contractIds: payrollClosure.contractIds
        }
      })
      .then(() => {
        nextStep()
      })
  }

  const handleOnSubmit = async (payrollClosure: payrollProcessForm) => {
    switch (activeStep) {
      case PayrollClosureStep.Start:
        handleUpdateProcessInfo(payrollClosure)
        break
      case PayrollClosureStep.Population:
        handleSelectContractsStep(payrollClosure)
        break
      default:
        nextStep()
        break
    }
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case PayrollClosureStep.Start:
        return <Start />
      case PayrollClosureStep.Parameters:
        return <Parameters processId={params.id} />
      case PayrollClosureStep.Population:
        return <Population processId={params.id} />
      case PayrollClosureStep.PersonalAndContractData:
        return <PersonalContractData processId={params.id} />
      case PayrollClosureStep.SalaryComponents:
        return getPayrollClosureProcess?.payrollClosureProcess.payGroup.hasPayComponentManagement ? (
          <SalaryData processId={params.id || ''} />
        ) : (
          <EmptyState title={t('payrollclosure.nopaycomponentmanagement')} />
        )
      case PayrollClosureStep.CalendarComponents:
        return getPayrollClosureProcess?.payrollClosureProcess.payGroup.hasCalendarManagement ? (
          showCalculatedCalendar ? (
            <CalculatedCalendarData processId={params.id || ''} />
          ) : (
            <CalendarData processId={params.id || ''} />
          )
        ) : (
          <EmptyState title={t('payrollclosure.nocalendarmanagement')} />
        )
      case PayrollClosureStep.FinalCheck:
        return <FinalCheck />
      case PayrollClosureStep.Finish:
        return <Finish processId={params.id || ''} processType={PROCESS_TYPE.PAYROLL_CLOSURE} />
      default:
        return <Navigate to={GeneralRoutes.NOTFOUND} replace />
    }
  }

  const isMutating =
    updateProcessStepMutation.isPending || updateProcessMutation.isPending || updateProcessContractsMutation.isPending

  return (
    <Paper
      sx={{
        px: 3,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <FormContainer
        sx={{ pt: 2, display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        form={form}
        onSubmit={handleSubmit(handleOnSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: [2]
          }}
        >
          <HeaderTitleNavigation
            showDivider={false}
            backToLink={Process.PROCESSES()}
            title={t('payrollclosure.title')}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Typography variant="h4" noWrap display="inline" sx={{ mb: 0 }}>
              {getPayrollClosureProcess?.payrollClosureProcess.isTestRun && (
                <span
                  style={{
                    color: theme.palette.warning.light,
                    paddingRight: 1
                  }}
                >
                  {t('payrollclosure.istestrun')}
                  &nbsp;
                </span>
              )}
              {getPayrollClosureProcess?.payrollClosureProcess.name}
            </Typography>

            {getPayrollClosureProcess?.payrollClosureProcess.startDate &&
              getPayrollClosureProcess?.payrollClosureProcess.endDate && (
                <Typography variant="h6" color={theme.palette.text.secondary}>
                  {`${parseISO(getPayrollClosureProcess.payrollClosureProcess.startDate).toLocaleDateString(
                    me?.me.locale.locale
                  )} - ${parseISO(getPayrollClosureProcess.payrollClosureProcess.endDate).toLocaleDateString(
                    me?.me.locale.locale
                  )}`}
                </Typography>
              )}
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            {activeStep !== PayrollClosureStep.Start && !getPayrollClosureProcess?.payrollClosureProcess.completed && (
              <Button onClick={() => handlePrevious()}>{t('common.previous')}</Button>
            )}
            <Button type="submit" disabled={activeStep === PayrollClosureStep.Finish || isMutating} variant="contained">
              {t('common.next')}
              {isMutating && (
                <LinearProgress
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.6
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>

        <Stepper sx={{ mt: 1, mb: 4 }} activeStep={steps.findIndex(s => s.step === activeStep)} alternativeLabel>
          {steps.map(step => (
            <Step key={step.label}>
              <StepLabel error={activeStep === step.step && !!Object.keys(errors).length}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ px: 6, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <ErrorBoundary key={activeStep} FallbackComponent={PayrollClosureErrorBoundaryHandler}>
            {renderStepContent()}
          </ErrorBoundary>
        </Box>
      </FormContainer>
    </Paper>
  )
}

export default PayrollClosure
