import {
  FEATURE,
  Module,
  ROLE,
  useGetCanAccessModuleQuery,
  useSuspenseGetFeaturesQuery,
  useSuspenseGetMeQuery
} from '../services'
import { isAuthorized } from '../utils'

export function useCanAccess(roles: ROLE[], feature?: FEATURE, module?: Module) {
  const { data: me } = useSuspenseGetMeQuery({}, { staleTime: 1000 * 60 * 5 })
  const { data: getFeatures } = useSuspenseGetFeaturesQuery()
  const { data: canAccessModule } = useGetCanAccessModuleQuery(
    {
      module: module!
    },
    {
      enabled: !!module
    }
  )

  return (
    (roles.length === 0 || isAuthorized(me?.me.roles, roles)) &&
    (!feature || getFeatures?.features[feature]) &&
    (!module || canAccessModule?.canAccessModule)
  )
}
