import {
  OrderDirection,
  useNameof,
  useRequiredParams,
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetIcpByIdQuery,
  useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery
} from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Button, IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { AddSmartPayComponent } from '../../components/smart-pay-component-components/add-edit-smart-pay-component'
import RemoveSmartPayComponent from '../../components/smart-pay-component-components/remove-smart-pay-component/remove-smart-pay-component'

type SmartPayComponentsDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

type SmartPayComponentRow = {
  id: string
  code: string
  description: string
}

export function SmartPayComponentsPage() {
  const { nameof } = useNameof<SmartPayComponentRow>()
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<SmartPayComponentsDetailPageParams>()
  const employerId = params.employerid
  const icpId = params.id
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [tableData, setTableData] = useState<SmartPayComponentRow[]>([])
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel(0))
  const [sortModel, setSortModel] = useState(new SortModel(nameof('code'), OrderDirection.Asc))
  const [smartPayComponentId, setSmartPayComponentId] = useState('')
  const [description, setDescription] = useState('')

  const rowActions = useCallback((row: SmartPayComponentRow) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setSmartPayComponentId(row.id)
          setDescription(row.description)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }, [])

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useSuspenseGetIcpByIdQuery({
    icpId: icpId
  })

  const {
    data: getPagedSmartPayComponentsByEmployerId,
    refetch: refetchSmartPayComponents,
    isLoading: isLoadingSmartPayComponents
  } = useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery({
    employerId: employerId,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    orderDirection: OrderDirection.Asc
  })

  const { data: getCountries } = useSuspenseGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName
  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 2,
          paddingLeft: 2,
          paddingRight: 2
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('smartpaycomponentspage.title')}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddSmartPayComponent employerId={employerId} refetch={refetchSmartPayComponents} />
              })
            }
          >
            <AddIcon />
            {t('smartpaycomponentspage.button.add-smartpaycomonent')}
          </Button>
        </Box>
      </Box>
      <Typography color={theme.palette.text.secondary} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  const columns: GridColDef<SmartPayComponentRow>[] = [
    {
      field: nameof('code'),
      headerName: t('smartpaycomponentspage.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: nameof('description'),
      headerName: t('smartpaycomponentspage.datatable.column.description'),
      flex: 1
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  useEffect(() => {
    if (getPagedSmartPayComponentsByEmployerId) {
      const smartPayComponents = getPagedSmartPayComponentsByEmployerId.pagedSmartPayComponentsByEmployerId
      setRowCount(smartPayComponents.totalCount)
      setTableData(
        smartPayComponents.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            description: row.description
          }
        })
      )
    }
  }, [getPagedSmartPayComponentsByEmployerId])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingSmartPayComponents}
        sortModel={sortModel}
        onSortChange={setSortModel}
        filterBarElement={filterBar}
        onRowClick={(rowParams: GridRowParams<SmartPayComponentRow>) => {
          navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID(params.id, params.employerid, rowParams.row.id))
        }}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            navigate(
              Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID(
                params.id,
                params.employerid,
                smartPayComponentId
              )
            )
          }
        >
          {t('smartpaycomponentspage.list.row.menu.manage-smartpaycomponent')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveSmartPayComponent smartPayComponentId={smartPayComponentId} description={description} />,
              callbackAfterClose: () => refetchSmartPayComponents
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default SmartPayComponentsPage
