import { Button, Grid, LinearProgress } from '@mui/material'
import { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { useCheckChildrenType } from '../../../hooks'

/* eslint-disable-next-line */
export interface FormActionButtonsProps {
  onCancel?: MouseEventHandler<HTMLButtonElement> | undefined
  showCancel?: boolean
  onRemove?: MouseEventHandler<HTMLButtonElement> | undefined
  showRemove?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  isMutating: boolean
  disabled?: boolean
  buttonText?: string
  buttonColor?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'inherit',
  children?: React.ReactNode
}

export function FormActionButtons({
  onCancel,
  onClick,
  onRemove,
  showRemove = true,
  showCancel = true,
  isMutating,
  disabled,
  buttonText,
  buttonColor,
  children
}: FormActionButtonsProps) {
  const { t } = useTranslation()
  children = useCheckChildrenType(children, Button)

  return (
    <Grid container gap={1} sx={{ pb: 3 }}>
      <Button
        color={buttonColor ? buttonColor : 'primary'}
        type="submit"
        variant="contained"
        onClick={onClick}
        disabled={isMutating || disabled === true}
      >
        {buttonText ? buttonText : t('common.save')}
        {isMutating && (
          <LinearProgress
            color={buttonColor ? 'error' : 'primary'}
            sx={{
              backgroundColor: buttonColor,
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0.6
            }}
          />
        )}
      </Button>

      {showCancel && onCancel && (
        <Button onClick={onCancel} variant="outlined">
          {t('common.cancel')}
        </Button>
      )}
      {showRemove && onRemove && (
        <Button color="error" onClick={onRemove} variant="outlined">
          {t('common.remove')}
        </Button>
      )}
      {children && children}
    </Grid>
  )
}

export default FormActionButtons
