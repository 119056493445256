import {
  addDays,
  addMonths,
  FormSelectOption,
  formTypeSelectOptions,
  GetManualEventByIdQuery,
  GraphqlError,
  MANUAL_EVENT_TYPE,
  numericString,
  TypeModel,
  useCreateManualEventMutation,
  useGetAllManualEventsByContractIdQuery,
  useGetAllRequestPolicyPayrollCodesByContractIdInDateRangeQuery,
  useSuspenseGetManualEventByIdQuery,
  useUpdateManualEventMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, useTheme } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { isDate, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, number, object, string, TypeOf } from 'zod'

export interface AddManualEventProps {
  contractId: string
}

export interface EditManualEventProps extends AddManualEventProps {
  manualEventId: string
}

export function AddManualEvent({ contractId }: AddManualEventProps) {
  return AddEditManualEvent(contractId)
}

export function EditManualEvent({ contractId, manualEventId }: EditManualEventProps) {
  const getManualEventByIdQuery = useSuspenseGetManualEventByIdQuery({
    id: manualEventId
  })

  return AddEditManualEvent(contractId, getManualEventByIdQuery)
}

export function AddEditManualEvent(
  contractId: string,
  getManualEventByIdQuery?: UseSuspenseQueryResult<GetManualEventByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { closeFlyIn } = useFlyIn()
  const { eventTypeOptions } = formTypeSelectOptions
  const [validDate, setValidDate] = useState<Date>()
  const [showEndDateTip, setShowEndDateTip] = useState(false)
  const [absenceType, setAbsencetype] = useState<TypeModel>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { refetch: refetchManualEvents } = useGetAllManualEventsByContractIdQuery({
    contractId: contractId,
    limit: -1,
    offset: 0
  })

  const getManualEventById = getManualEventByIdQuery?.data

  useEffect(() => {
    if (getManualEventById && getManualEventById.manualEventById) {
      setValidDate(getManualEventById.manualEventById.date)
    }
  }, [getManualEventById])

  const addEditManualEventSchema = object({
    requestPolicyPayrollCode: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }),
    date: date(),
    endDate: date().optional().nullable(),
    eventTypeKey: string({
      required_error: t('form.validation.eventtyperequired'),
      invalid_type_error: t('form.validation.eventtyperequired')
    }),
    amount: numericString(number().optional()).nullable().optional(),
    comment: string().optional().nullable()
  })
    .refine(
      data =>
        data.eventTypeKey !== MANUAL_EVENT_TYPE.REMOVE_BALANCE
          ? data.amount
          : numericString(
              number({
                required_error: t('form.validation.amountrequired'),
                invalid_type_error: t('form.validation.amountrequired')
              })
            ),
      {
        message: t('form.validation.amountrequired'),
        path: ['amount']
      }
    )
    .refine(data => (data.endDate ? data.endDate >= data.date : !data.endDate), {
      message: t('form.validation.enddateafterstartdate'),
      path: ['endDate']
    })

  type AddEditManualEventForm = TypeOf<typeof addEditManualEventSchema>

  const form = useForm<AddEditManualEventForm>({
    resolver: zodResolver(addEditManualEventSchema),
    defaultValues: {
      requestPolicyPayrollCode: getManualEventById?.manualEventById.requestPolicyPayrollCodeId,
      date: parseISO(getManualEventById?.manualEventById.date),
      endDate: getManualEventById?.manualEventById.endDate
        ? parseISO(getManualEventById?.manualEventById.endDate)
        : null,
      amount: getManualEventById?.manualEventById.amount,
      eventTypeKey: getManualEventById?.manualEventById.eventType?.key || MANUAL_EVENT_TYPE.SET_BALANCE,
      comment: getManualEventById?.manualEventById.comment
    }
  })
  const { control } = form

  const watchedEventType = useWatch({ control, name: `eventTypeKey` })

  const { data: getAllRequestPolicyPayrollCodes } = useGetAllRequestPolicyPayrollCodesByContractIdInDateRangeQuery(
    {
      contractId: contractId,
      date: validDate ? validDate : new Date()
    },
    {
      enabled: !!validDate
    }
  )

  function onPayrollCodeChange(payrollCodeId: string | undefined) {
    if (getAllRequestPolicyPayrollCodes?.allRequestPolicyPayrollCodesByContractIdInDateRange && payrollCodeId) {
      const selectedPayrollCode =
        getAllRequestPolicyPayrollCodes.allRequestPolicyPayrollCodesByContractIdInDateRange.find(
          r => r.id === payrollCodeId
        )

      if (selectedPayrollCode) {
        const calculatedEndDate = validDate
          ? addDays(addMonths(validDate, selectedPayrollCode.eventValidityInMonths), -1)
          : new Date()
        form?.setValue('endDate', calculatedEndDate)
        setAbsencetype(selectedPayrollCode?.absenceType)
        setShowEndDateTip(true)
      } else {
        setAbsencetype(undefined)
      }
    }
  }

  const createMutation = useCreateManualEventMutation()
  const updateMutation = useUpdateManualEventMutation()

  const handleOnSubmit = async (newManualEvent: AddEditManualEventForm) => {
    if (!getManualEventById) {
      await createMutation
        .mutateAsync({
          createManualEventCommand: {
            contractId: contractId,
            requestPolicyPayrollCodeId: newManualEvent.requestPolicyPayrollCode,
            date: newManualEvent.date,
            amount: newManualEvent.amount,
            eventTypeKey: newManualEvent.eventTypeKey,
            comment: newManualEvent.comment,
            endDate: newManualEvent.eventTypeKey === MANUAL_EVENT_TYPE.SET_BALANCE ? newManualEvent.endDate : null
          }
        })
        .then(() => {
          closeFlyIn()
          refetchManualEvents()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateManualEventCommand: {
            id: getManualEventById.manualEventById.id,
            contractId: contractId,
            requestPolicyPayrollCodeId: newManualEvent.requestPolicyPayrollCode,
            date: newManualEvent.date,
            amount: newManualEvent.amount,
            eventTypeKey: newManualEvent.eventTypeKey,
            comment: newManualEvent.comment,
            endDate: newManualEvent.eventTypeKey === MANUAL_EVENT_TYPE.SET_BALANCE ? newManualEvent.endDate : null
          }
        })
        .then(() => {
          closeFlyIn()
          refetchManualEvents()
          getManualEventByIdQuery.refetch()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.manualevent.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={12} name="eventTypeKey" options={eventTypeOptions} label={`${t('form.field.eventtype')} *`} />

        <FormDatepicker
          sx={12}
          name="date"
          onChange={() => {
            if (isDate(form.getValues().date)) {
              setValidDate(form.getValues().date)
            } else {
              setValidDate(undefined)
            }
          }}
          label={`${t('form.field.date')} *`}
        />

        <FormSelect
          sx={12}
          name="requestPolicyPayrollCode"
          options={getAllRequestPolicyPayrollCodes?.allRequestPolicyPayrollCodesByContractIdInDateRange.map(
            x => new FormSelectOption(x.id, x.description)
          )}
          label={`${t('form.field.requestpolicypayrollcode')} *`}
          onChange={(_, absenceType) => {
            onPayrollCodeChange(absenceType?.id)
          }}
        />

        {watchedEventType === MANUAL_EVENT_TYPE.SET_BALANCE && (
          <>
            <FormDatepicker
              sx={12}
              name="endDate"
              onChange={() => setShowEndDateTip(false)}
              label={`${t('form.field.enddate')} *`}
            />
            {showEndDateTip && (
              <Typography
                sx={{
                  pl: '1.25rem',
                  pt: '0.25rem',
                  fontSize: 'small',
                  color: theme.palette.text.secondary
                }}
              >
                {t('flyin.manualevent.enddateinfo')}
              </Typography>
            )}
          </>
        )}

        <FormNumericInput
          sx={12}
          name="amount"
          label={absenceType ? `${t('form.field.amount')} (${absenceType.value})` : `${t('form.field.amount')}`}
        />

        <FormInput sx={12} name="comment" label={`${t('form.field.remarks')}`} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditManualEvent
