import {
  CIVIL_STATE,
  COMPANY_CAR,
  CONTRACT,
  EMPTY_STATE,
  FAMILY,
  FAMILY_STATE,
  OFFICIAL_ADDRESS,
  PERSON,
  RESIDENTIAL_ADDRESS,
  useGetContractCorrectionsByProcessIdQuery,
  useGetPersonCivilStateCorrectionsByProcessIdQuery,
  useGetPersonCompanyCarCorrectionsByProcessIdQuery,
  useGetPersonCorrectionsByProcessIdQuery,
  useGetPersonFamilyMemberCorrectionsByProcessIdQuery,
  useGetPersonFamilyMemberStateCorrectionsByProcessIdQuery,
  useGetPersonOfficialAddressCorrectionsByProcessIdQuery,
  useGetPersonResidentialAddressCorrectionsByProcessIdQuery,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import { EmptyState } from '@epix-web-apps/ui'
import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StepProps } from '../../generic-steps'
import { CompanyCarCorrectionsComponent } from '../company-car-corrections-component'
import { ContractCorrectionsComponent } from '../contract-corrections-component'
import { FamilyCorrectionsComponent } from '../family-corrections-component'
import { FamilyStateCorrectionsComponent } from '../family-state-corrections-component'
import { OfficialAddressCorrectionsComponent } from '../official-address-corrections-component'
import { PersonCivilStateCorrectionsComponent } from '../person-civil-state-corrections-component'
import { PersonCorrectionsComponent } from '../person-corrections-component'
import { ResidentialAddressCorrectionsComponent } from '../residential-address-corrections-component'

export const minWidthPayrollClosure = 200

export function PersonalContractData({ processId }: StepProps) {
  const { t } = useTranslation()
  const [showCorrectionTab, setShowCorrectionTab] = useState('')

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({ id: processId })

  const { data: personCorrections } = useGetPersonCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: contractCorrections } = useGetContractCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personCivilStateCorrections } = useGetPersonCivilStateCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personFamilyMemberCorrections } = useGetPersonFamilyMemberCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personFamilyMemberStateCorrections } = useGetPersonFamilyMemberStateCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personOfficialAddressCorrections } = useGetPersonOfficialAddressCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personResidentialAddressCorrections } = useGetPersonResidentialAddressCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const { data: personCompanyCarCorrections } = useGetPersonCompanyCarCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess.payrollClosureProcess.id,
    offset: 0,
    limit: 1
  })

  const tabOrder = [
    PERSON,
    CONTRACT,
    CIVIL_STATE,
    FAMILY,
    FAMILY_STATE,
    OFFICIAL_ADDRESS,
    RESIDENTIAL_ADDRESS,
    EMPTY_STATE
  ]

  const showPersonButton =
    personCorrections?.personCorrectionsByProcessId && personCorrections?.personCorrectionsByProcessId.data.length > 0
  const showContractButton =
    contractCorrections?.contractCorrectionsByProcessId &&
    contractCorrections.contractCorrectionsByProcessId.data.length > 0
  const showPersonCivilStateButton =
    personCivilStateCorrections?.personCivilStateCorrectionsByProcessId &&
    personCivilStateCorrections.personCivilStateCorrectionsByProcessId.data.length > 0
  const showFamilyMemberButton =
    personFamilyMemberCorrections?.personFamilyMemberCorrectionsByProcessId &&
    personFamilyMemberCorrections.personFamilyMemberCorrectionsByProcessId.data.length > 0
  const showFamilyMemberStateButton =
    personFamilyMemberStateCorrections?.personFamilyMemberStateCorrectionsByProcessId &&
    personFamilyMemberStateCorrections.personFamilyMemberStateCorrectionsByProcessId.data.length > 0
  const showOfficialAddressButton =
    personOfficialAddressCorrections?.personOfficialAddressCorrectionsByProcessId &&
    personOfficialAddressCorrections.personOfficialAddressCorrectionsByProcessId.data.length > 0
  const showResidentialAddressButton =
    personResidentialAddressCorrections?.personResidentialAddressCorrectionsByProcessId &&
    personResidentialAddressCorrections.personResidentialAddressCorrectionsByProcessId.data.length > 0
  const showCompanyCarButton =
    personCompanyCarCorrections?.personCompanyCarHistoryCorrectionsByProcessId &&
    personCompanyCarCorrections.personCompanyCarHistoryCorrectionsByProcessId.data.length > 0

  const defaultTab = tabOrder.find(tabName => {
    switch (tabName) {
      case PERSON:
        return PERSON
      case CONTRACT:
        return CONTRACT
      case CIVIL_STATE:
        return CIVIL_STATE
      case FAMILY:
        return FAMILY
      case FAMILY_STATE:
        return FAMILY_STATE
      case OFFICIAL_ADDRESS:
        return OFFICIAL_ADDRESS
      case RESIDENTIAL_ADDRESS:
        return RESIDENTIAL_ADDRESS
      case COMPANY_CAR:
        return COMPANY_CAR
      default:
        return EMPTY_STATE
    }
  })

  if (showCorrectionTab === '') {
    setShowCorrectionTab(defaultTab || '')
  }

  const correctionComponents = {
    CONTRACT: <ContractCorrectionsComponent />,
    PERSON: <PersonCorrectionsComponent />,
    CIVIL_STATE: <PersonCivilStateCorrectionsComponent />,
    FAMILY_MEMBER: <FamilyCorrectionsComponent />,
    FAMILY_MEMBER_STATE: <FamilyStateCorrectionsComponent />,
    OFFICIAL_ADDRESS: <OfficialAddressCorrectionsComponent />,
    RESIDENTIAL_ADDRESS: <ResidentialAddressCorrectionsComponent />,
    COMPANY_CAR: getPayrollClosureProcess?.payrollClosureProcess.payGroup.hasCarManagement ? (
      <CompanyCarCorrectionsComponent />
    ) : (
      <EmptyState title={t('personcontractdata.nocarmanagement')} />
    ),
    EMPTY_STATE: <Box>{t('emptystate.process.personcontract')}</Box>
  }

  const selectedComponent = correctionComponents[showCorrectionTab as keyof typeof correctionComponents]

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
        {showPersonButton && (
          <Button
            variant={showCorrectionTab === PERSON ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(PERSON)
            }}
          >
            {t('personcontractdata.datatable.button.person')}
          </Button>
        )}
        {showContractButton && (
          <Button
            variant={showCorrectionTab === CONTRACT ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(CONTRACT)
            }}
          >
            {t('personcontractdata.datatable.button.contract')}
          </Button>
        )}
        {showPersonCivilStateButton && (
          <Button
            variant={showCorrectionTab === CIVIL_STATE ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(CIVIL_STATE)
            }}
          >
            {t('personcontractdata.datatable.button.civilstate')}
          </Button>
        )}
        {showFamilyMemberButton && (
          <Button
            variant={showCorrectionTab === FAMILY ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(FAMILY)
            }}
          >
            {t('personcontractdata.datatable.button.familymember')}
          </Button>
        )}
        {showFamilyMemberStateButton && (
          <Button
            variant={showCorrectionTab === FAMILY_STATE ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(FAMILY_STATE)
            }}
          >
            {t('personcontractdata.datatable.button.familymemberstate')}
          </Button>
        )}
        {showOfficialAddressButton && (
          <Button
            variant={showCorrectionTab === OFFICIAL_ADDRESS ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(OFFICIAL_ADDRESS)
            }}
          >
            {t('personcontractdata.datatable.button.officialaddress')}
          </Button>
        )}
        {showResidentialAddressButton && (
          <Button
            variant={showCorrectionTab === RESIDENTIAL_ADDRESS ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(RESIDENTIAL_ADDRESS)
            }}
          >
            {t('personcontractdata.datatable.button.residentialaddress')}
          </Button>
        )}
        {showCompanyCarButton && (
          <Button
            variant={showCorrectionTab === COMPANY_CAR ? 'contained' : 'outlined'}
            onClick={e => {
              setShowCorrectionTab(COMPANY_CAR)
            }}
          >
            {t('personcontractdata.datatable.button.companycar')}
          </Button>
        )}
      </Box>
      <Box>{selectedComponent}</Box>
    </Box>
  )
}

export default PersonalContractData
