import {
  FormSelectOption,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetAsecContractDetailByContractIdQuery,
  useUpdateAsecSociaSecurityMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface EditAsecSocialSecurityProps {
  contractId: string
}

export function EditAsecSocialSecurity({ contractId }: EditAsecSocialSecurityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: asecContractDetail, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetAsecContractDetailByContractIdQuery({
      contractId: contractId
    })

  const { data: socialSecurityCategoriesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.ES_SOCIAL_SECURITY_CATEGORY.key,
    limit: -1,
    offset: 0
  })

  const updateMutation = useUpdateAsecSociaSecurityMutation()

  const socialSecurityCategories =
    socialSecurityCategoriesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
      x => new FormSelectOption(x.id, x.value)
    )

  const editAsecSocialSecuritySchema = object({
    socialSecurityNumber: string().nullable(),
    socialSecurityCategoryId: string().nullable().optional()
  })

  type EditAsecSocialSecurityForm = TypeOf<typeof editAsecSocialSecuritySchema>

  const form = useForm<EditAsecSocialSecurityForm>({
    resolver: zodResolver(editAsecSocialSecuritySchema),
    defaultValues: {
      socialSecurityNumber: asecContractDetail?.asecContractDetailByContractId.socialSecurityNumber,
      socialSecurityCategoryId: asecContractDetail?.asecContractDetailByContractId.socialSecurityCategory?.id
    }
  })

  const handleOnSubmit = async (asecContractSocialSecurity: EditAsecSocialSecurityForm) => {
    await updateMutation
      .mutateAsync({
        updateAsecSocialSecurityCommand: {
          id: asecContractDetail?.asecContractDetailByContractId.id || '',
          socialSecurityCategoryId: asecContractSocialSecurity.socialSecurityCategoryId,
          socialSecurityNumber: asecContractSocialSecurity.socialSecurityNumber
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editasecsocialsecurity.title')}</Typography>
      <FormGridLayout>
        <FormInput sx={12} name="socialSecurityNumber" label={t('form.field.essocialsecuritynumber')} />
        <FormSelect
          sx={12}
          name="socialSecurityCategoryId"
          label={t('form.field.socialsecuritycategory')}
          options={socialSecurityCategories}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isPending} />
    </FormContainer>
  )
}

export default EditAsecSocialSecurity
