import {
  isAdmin,
  ROLE,
  useSuspenseGetAllIcpsQuery,
  useSuspenseGetMeQuery,
  useSuspenseIsIcpLimitReachedQuery
} from '@epix-web-apps/core'
import { CanView, Configuration, FeatureForPurchaseIndicator, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DoneIcon from '@mui/icons-material/Done'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, IconButton, MenuItem, Switch, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import FilterButton from '../../components/filter-button/filter-button'
import { AddIcp, EditIcp, EditIcpState } from '../../components/icp-components'
import OnboardingIcpLayout from '../../components/onboarding-components/onboarding-icp-layout/onboarding-icp-layout'
import SettingsButton from '../../components/settings-button'

/* eslint-disable-next-line */
export interface IcpProvidersPageProps {}

export function IcpProvidersPage(props: IcpProvidersPageProps) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [clickedIcpId, setClickedIcpId] = useState<string>()

  const { openFlyIn } = useFlyIn()
  const [filters, setFilters] = useState({
    showInactive: false
  })
  const [defaultIcp, setDefaultIcp] = useState(false)
  const { data: me } = useSuspenseGetMeQuery()
  const { data: isLimitReachedData, refetch: refetchIsLimitReached } = useSuspenseIsIcpLimitReachedQuery()

  const handleLinkClick = () => {
    openFlyIn({
      content: <AddIcp />,
      callbackAfterClose: () => () => {
        refetchIcps()
        refetchIsLimitReached()
      }
    })
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedIcpId(rowParams.row.id)
          setAnchorEl(e.currentTarget)
          setDefaultIcp(rowParams.row.managedbyepix)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      {rowParams.row.active ? (
        <IconButton aria-label="row details" aria-controls="details-row">
          <KeyboardArrowRightIcon />
        </IconButton>
      ) : (
        ''
      )}
    </>
  )

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />}</DataTableCell>
  }

  function managedByEpixActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <ClearIcon />}</DataTableCell>
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('icpproviderspage.datatable.column.country'),
      flex: 1
    },
    {
      field: 'country',
      headerName: t('icpproviderspage.datatable.column.countrycode'),
      flex: 1
    },
    {
      field: 'currency',
      headerName: t('icpproviderspage.datatable.column.currency'),
      flex: 1
    },
    {
      field: 'code',
      headerName: t('icpproviderspage.datatable.column.icpcode'),
      flex: 1
    },
    {
      field: 'payrollprovider',
      headerName: t('icpproviderspage.datatable.column.payrollprovider'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('icpproviderspage.datatable.column.active'),
      sortable: true,
      editable: false,
      renderCell: activeActions
    },
    {
      field: 'managedbyepix',
      headerName: t('icpproviderspage.datatable.column.managedbyepix'),
      sortable: true,
      editable: false,
      renderCell: managedByEpixActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const filterBar = (
    <Box sx={{ display: 'grid', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography m={0} variant="h2">
          {t('icpproviderspage.title')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FilterButton filters={filters}>
              <p>
                <Switch
                  checked={filters.showInactive}
                  onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
                  aria-label={t('peoplepage.filters.showinactivepeople')}
                />
                {t('configurationicpproviders.filters.showinactiveicpproviders')}
              </p>
            </FilterButton>

            <CanView roles={[ROLE.ADMIN, ROLE.MANAGELISTS]}>
              <SettingsButton
                menuItems={[
                  {
                    label: t('icpproviderspage.settings.externalcodes'),
                    to: Configuration.ICP_PROVIDERS_EXTERNAL_CODES()
                  }
                ]}
              />
            </CanView>
          </Box>

          {isAdmin(me?.me.roles) && (
            <Button
              variant="contained"
              disabled={isLimitReachedData?.isIcpLimitReached}
              onClick={() =>
                openFlyIn({
                  content: <AddIcp />,
                  callbackAfterClose: () => () => {
                    refetchIcps()
                    refetchIsLimitReached()
                  }
                })
              }
            >
              <AddIcon />
              {t('icpproviderspage.button.addicp')}
            </Button>
          )}
        </Box>
      </Box>
      {isLimitReachedData?.isIcpLimitReached && (
        <Box sx={{ justifySelf: 'right', fontStyle: 'italic' }}>
          {t('limits.providerlimitreached')} <FeatureForPurchaseIndicator />
        </Box>
      )}
    </Box>
  )

  useEffect(() => {
    setPaginationModel(new PaginationModel(0))
  }, [filters])

  const {
    data: getAllIcps,
    isLoading: isLoadingIcps,
    refetch: refetchIcps
  } = useSuspenseGetAllIcpsQuery({
    activeOnly: !filters.showInactive,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  const goToEmployersPage = (clickedRow: GridRowId | undefined, active: boolean) => {
    if (clickedRow && active) {
      navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERS(clickedRow.toString()))
    }
  }

  useEffect(() => {
    if (getAllIcps && getAllIcps?.icps) {
      const icps = getAllIcps.icps
      setRowCount(icps.totalCount)
      setTableData(
        icps.data.map(row => {
          return {
            id: row.id,
            country: row.country,
            code: row.code,
            payrollprovider: row.payrollProvider,
            active: row.active,
            managedbyepix: row.managedByEpix,
            name: row.countryName,
            currency: row.currencyName + ' (' + row.currencyKey + ')'
          }
        })
      )
    }
  }, [getAllIcps])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={row => goToEmployersPage(row.row.id, row.row.active)}
        onSortChange={setSortModel}
        isLoading={isLoadingIcps}
        sortModel={sortModel}
        filterBarElement={filterBar}
        emptyStateElement={<OnboardingIcpLayout onLinkClick={handleLinkClick} />}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            clickedIcpId &&
            openFlyIn({
              content: <EditIcpState icpId={clickedIcpId} />,
              callbackAfterClose: () => () => {
                refetchIcps()
                refetchIsLimitReached()
              }
            })
          }
        >
          {t('icpproviderspage.list.row.menu.viewdetails')}
        </MenuItem>

        {!defaultIcp && clickedIcpId && (
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: <EditIcp icpId={clickedIcpId} />,
                callbackAfterClose: () => () => {
                  refetchIcps()
                  refetchIsLimitReached()
                }
              })
            }
          >
            {t('icpproviderspage.list.row.menu.editdetails')}
          </MenuItem>
        )}
        <MenuItem onClick={() => goToEmployersPage(clickedIcpId, true)}>
          {t('icpproviderspage.list.row.menu.employers')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default IcpProvidersPage
