import { useSuspenseGetLdpContractDetailsQuery, useUpdateLdpContractDetailPayScaleMutation } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

/* eslint-disable-next-line */
export interface EditLdpPayScaleProps {
  contractId: string
}

export function EditLdpPayScale({ contractId }: EditLdpPayScaleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetLdpContractDetailsQuery({
      contractId: contractId
    })

  const editLdpPayScaleSchema = object({
    payScale: string().optional()
  })

  type EditLdpPayScaleForm = TypeOf<typeof editLdpPayScaleSchema>

  const updateMutation = useUpdateLdpContractDetailPayScaleMutation()

  const form = useForm<EditLdpPayScaleForm>({
    resolver: zodResolver(editLdpPayScaleSchema),
    defaultValues: {
      payScale: partnerSpecificDetails?.ldpContractDetailByContractId.ldpContractDetailPayScaleModel?.payScale || ''
    }
  })

  const handleOnSubmit = async (newPayScale: EditLdpPayScaleForm) => {
    await updateMutation
      .mutateAsync({
        ldpContractDetailPayScaleCommand: {
          id: partnerSpecificDetails?.ldpContractDetailByContractId.id || '',
          payScale: newPayScale.payScale
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editpayscale.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="payScale" label={t('form.field.payscale')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditLdpPayScale
