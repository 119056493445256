import {
  ABSENCE_REQUEST_STATUS_TYPE,
  AbsenceRequestModel,
  ToDateRangeFormat,
  useSuspenseGetAbsenceRequestsForPersonQuery
} from '@epix-web-apps/core'
import { HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckIcon from '@mui/icons-material/Check'
import EventIcon from '@mui/icons-material/Event'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme
} from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export function MyAbsenceRequests() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const theme = useTheme()

  const { data: getAbsenceRequestsData } = useSuspenseGetAbsenceRequestsForPersonQuery()

  return (
    <>
      <HeaderTitleNavigation
        mobileSizeTitle
        title={t('selfservice.my-absence-requests.title')}
        backToLink={SelfService.ROOT()}
      />
      {getAbsenceRequestsData?.absenceRequestsForPerson.map(absenceRequestByContract => {
        const grouped = absenceRequestByContract.absenceRequests.reduce((acc, curr) => {
          const year = curr.startDate.split('-')[0]

          if (!acc[year]) {
            acc[year] = [curr]
          } else {
            acc[year].push(curr)
          }
          return acc
        }, Object.create(null)) as Record<string, AbsenceRequestModel[]>
        const sortedGroup = Object.entries(grouped).sort((a, b) => parseInt(b[0]) - parseInt(a[0]))

        return (
          <Box key={absenceRequestByContract.contractId} sx={{ px: 1 }}>
            <Typography variant="h4">
              {`${absenceRequestByContract.icpCode}: ${parseISO(absenceRequestByContract.startDate).toLocaleDateString(
                me?.locale.locale
              )} - ${
                absenceRequestByContract.endDate
                  ? parseISO(absenceRequestByContract.endDate).toLocaleDateString(me?.locale.locale)
                  : '...'
              }`}
            </Typography>
            {sortedGroup.map(([key, values], index) => {
              return (
                <Accordion key={key} defaultExpanded={index === 0}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{key}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {values.map(request => {
                      return (
                        <Grid
                          key={request.id}
                          onClick={() => {
                            navigate(SelfService.MY_REQUESTS_ID(`${request.id}`))
                          }}
                          container
                          sx={{
                            alignItems: 'center',
                            borderTop: 1,
                            borderColor: theme.palette.divider,
                            py: '0.25rem'
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography>
                              {ToDateRangeFormat(
                                {
                                  startDate: parseISO(request.startDate),
                                  endDate: parseISO(request.endDate)
                                },
                                me?.locale.locale ?? ''
                              )}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>{request.requestPolicyUserFriendlyDescription}</Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                gap: 1
                              }}
                            >
                              {request.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.APPROVED && (
                                <CheckIcon color="success" />
                              )}
                              {request.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED && (
                                <HelpOutlineIcon color="primary" />
                              )}
                              {request.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.DECLINED && (
                                <CancelIcon color="error" />
                              )}
                              {request.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.CANCELLED && (
                                <CancelIcon color="warning" />
                              )}
                              <IconButton size="small">
                                <ArrowForwardIosIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </Box>
        )
      })}

      <Button
        sx={{ m: 1 }}
        variant="outlined"
        size="small"
        startIcon={<EventIcon />}
        onClick={() => navigate(SelfService.REQUEST_ABSENCE_PERSONID(`${me?.personId}`))}
      >
        {t('selfservice.balances.requestabsence')}
      </Button>
    </>
  )
}

export default MyAbsenceRequests
