import {
  FormSelectOption,
  formTypeSelectOptions,
  numericString,
  OrderDirection,
  useGetBranchUnitsByEmployerIdQuery,
  useRequiredParams,
  useSuspenseGetContractByIdQuery,
  useUpdateContractAdditionalInformationMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, number, object, string, TypeOf } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'

/* eslint-disable-next-line */
export interface EditContractAdditionalInformationProps {}

export function EditContractAdditionalInformation(props: EditContractAdditionalInformationProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()

  const editContractAdditionalInfoSchema = object({
    department: string().nullable(),
    costCenter: string().nullable(),
    fte: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.ftegreaterthan0') })
        .lte(100, { message: t('form.validation.ftelessthan100') })
        .optional()
        .nullable()
    ),
    notes: string().nullable(),
    branchUnitId: string().optional().nullable(),
    workPermitType: string().optional().nullable(),
    workPermitStartDate: date().optional().nullable(),
    workPermitEndDate: date().optional().nullable()
  })

  type EditContractAdditionalInfoForm = TypeOf<typeof editContractAdditionalInfoSchema>

  const { data: getContractById, refetch: refetchContractById } = useSuspenseGetContractByIdQuery({
    contractId: params.contractid
  })

  const contract = getContractById.contractById

  const { workPermitOptions } = formTypeSelectOptions

  const { data: branchUnits } = useGetBranchUnitsByEmployerIdQuery({
    employerId: contract.employerId ?? '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    showInactive: false
  })
  const mutation = useUpdateContractAdditionalInformationMutation()
  const form = useForm<EditContractAdditionalInfoForm>({
    resolver: zodResolver(editContractAdditionalInfoSchema),
    defaultValues: {
      department: contract?.department,
      costCenter: contract?.costCenter,
      fte: contract?.fte,
      notes: contract?.notes,
      branchUnitId: contract?.branchUnit?.id,
      workPermitType: contract?.workPermitType?.key,
      workPermitStartDate: contract?.workPermitStartDate ? parseISO(contract.workPermitStartDate) : null,
      workPermitEndDate: contract?.workPermitEndDate ? parseISO(contract.workPermitEndDate) : null
    }
  })

  function handleOnSubmit(contractPaymentInfo: EditContractAdditionalInfoForm) {
    mutation
      .mutateAsync({
        updateContractAdditionalInformationCommand: {
          contractId: params.contractid || '',
          department: contractPaymentInfo.department,
          costCenter: contractPaymentInfo.costCenter,
          fte: contractPaymentInfo.fte,
          notes: contractPaymentInfo.notes,
          branchUnitId: contractPaymentInfo.branchUnitId,
          workPermitKey: contractPaymentInfo.workPermitType,
          workPermitStartDate: contractPaymentInfo.workPermitStartDate,
          workPermitEndDate: contractPaymentInfo.workPermitEndDate
        }
      })
      .then(() => {
        refetchContractById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontract.additionalinfo.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="branchUnitId"
          label={`${t('form.field.branchunit')}`}
          options={branchUnits?.branchUnits.data.map(x => new FormSelectOption(x.id, x.description))}
        />

        <FormInput sx={12} name="department" label={t('form.field.department')} />

        <FormInput sx={12} name="costCenter" label={t('form.field.costCenter')} />

        <FormNumericInput sx={12} name="fte" label={`${t('form.field.fte')} | ${t('form.label.ftedefaultis')}`} />
        <Typography
          variant="description"
          sx={{
            ml: 2
          }}
        >
          {t('flyin.editcontract.additionalinfo.fte')}
        </Typography>

        <FormSelect sx={12} name="workPermitType" label={`${t('form.field.workpermit')}`} options={workPermitOptions} />

        <FormDatepicker sx={6} name="workPermitStartDate" label={`${t('form.field.workpermitstartdate')}`} />

        <FormDatepicker sx={6} name="workPermitEndDate" label={`${t('form.field.workpermitenddate')}`} />

        <FormInput sx={12} multiline name="notes" label={t('form.field.notes')} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractAdditionalInformation
