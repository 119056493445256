import styled from '@emotion/styled'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  CalendarDayEntryModel,
  TIME_FRAME_TYPE_KEYS,
  ToBackendFormatedDate,
  ToRouteDateFormat,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  isInCurrentMonth,
  useGetAllContractsByPersonIdAndTeamStructureQuery,
  useGetAllTeamsWithRoleForMeQuery,
  useGetCalendarForContractIdsAndTeamStructureQuery,
  useGetTeamsForMeForTeamStructureQuery,
  useGetWorkScheduleHistoriesByPersonIdAndTeamStructureQuery,
  useNavigateWithParams
} from '@epix-web-apps/core'
import { SelfService, useGlobalStore, useRouteDefinitions } from '@epix-web-apps/ui'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useMemo } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

/* eslint-disable-next-line */
interface MonthlyCalendarProps {
  personId: string
  specificPersonPage?: boolean
  isEditorRole?: boolean
}

const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */

  .react-calendar * {
    font-family: 'Poppins', sans-serif;
  }

  .react-calendar {
    width: 100%;
    max-width: 100%;
    background-color: #ffff;
    color: #22222;
    border-color: #f5f5ff;
    min-height: 100%;
  }

  .react-calendar__navigation button {
    color: #00044e;
    min-width: 50px;
    background: none;
    font-size: 16px;
    font-weight: bold;
    margin-top: 8px;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #000;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.5;
  }

  abbr[title] {
    text-decoration: none;
  }

  .react-calendar__month-view__days .react-calendar__tile--active {
    background-color: #ffffff;
  }

  .react-calendar__month-view__days .react-calendar__tile--now {
    background-color: #f5f5ff;
    font-weight: bold;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #00044e;
  }

  .react-calendar__tile--range {
    background: #f5f5ff;
    color: #000;
    border-radius: 0;
  }

  .day-tile {
    height: 5px;
  }

  .day-tile-right {
    height: 5px;
    margin-left: auto;
  }

  .day-box {
    border-radius: 5px;
    overflow: hidden;
    display: flex;
  }

  .react-calendar__tile {
    padding-top: 5%;
    padding-bottom: 5%;
  }
`

export function MonthlyCalendar({ personId, specificPersonPage = false, isEditorRole = false }: MonthlyCalendarProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const navigate = useNavigateWithParams()
  const routes = useRouteDefinitions()

  const [searchParams, setSearchParams] = useSearchParams()
  const date = searchParams.get('date') !== null ? parseISO(searchParams.get('date')!) : new Date()
  const { data: getAllContracts } = useGetAllContractsByPersonIdAndTeamStructureQuery({ personId: personId })

  const { data: calendarData } = useGetCalendarForContractIdsAndTeamStructureQuery({
    contractIds: getAllContracts?.allContractsByPersonIdAndTeamStructure.map(c => c.contractId) ?? [],
    startDate: ToBackendFormatedDate(getFirstDayOfMonth(date)),
    endDate: ToBackendFormatedDate(getLastDayOfMonth(date))
  })

  const { data: getTeamsByPersonId } = useGetTeamsForMeForTeamStructureQuery()

  const { data: getAllTeamsWithRole } = useGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const teamId = allTeams[0]?.id

  const { data: getWorkScheduleHistoriesByPersonId } = useGetWorkScheduleHistoriesByPersonIdAndTeamStructureQuery()

  const events = useMemo(() => {
    const calendarDays = calendarData?.calendarDaysByContractIdsAndTeamStructure.flatMap(c => c.days)
    const map = new Map<string, CalendarDayEntryModel[]>()
    calendarDays?.forEach(ccm => {
      const mapEntry = map.get(ccm.date) ?? []
      map.set(ccm.date, [...mapEntry, ...ccm.calculatedEntries])
    })
    return map
  }, [personId, date])

  function navigateDayClick(date: Date) {
    const calendarDataForClickedDay = calendarData?.calendarDaysByContractIdsAndTeamStructure
      .flatMap(entry => entry.days)
      .filter(day => day.date === ToBackendFormatedDate(date))

    const hasCollectiveSchedule = calendarDataForClickedDay
      ?.flatMap(entry => entry.collectiveSchedule)
      .some(schedule => schedule?.collectiveScheduleDays?.length ?? 0 > 0)

    const hasPersonalCalendarDays = calendarDataForClickedDay
      ?.flatMap(entry => entry.personalCalendarDays)
      .some(schedule => schedule != null)

    const hasWorkSchedule = calendarDataForClickedDay
      ?.flatMap(entry => entry.workScheduleDay)
      .some(schedule => schedule?.dayDefinitionEntries?.length ?? 0 > 0)

    // no days yet so request absence
    if (
      !hasCollectiveSchedule &&
      !hasPersonalCalendarDays &&
      hasWorkSchedule &&
      (personId === me?.personId || isEditorRole)
    ) {
      navigate(SelfService.REQUEST_ABSENCE_PERSONID(personId), { date: ToBackendFormatedDate(date) })
    }
    // days so show details
    else {
      navigate(routes.SelfService.PEOPLE_ID_CALENDAR_DATE(personId, ToBackendFormatedDate(date)), {
        date: ToBackendFormatedDate(date)
      })
    }
  }

  return (
    <Box minHeight={'100%'}>
      {getWorkScheduleHistoriesByPersonId?.workScheduleHistoriesByPersonIdAndTeamStructure.some(x => x) ? (
        <CalendarContainer>
          <Calendar
            locale={me?.locale.locale}
            onActiveStartDateChange={value => {
              if (value.activeStartDate === null) return
              // drillUp = when center month on calendar navigation is clicked
              if (value.action === 'drillUp' || isInCurrentMonth(value.activeStartDate)) {
                searchParams.set('date', ToBackendFormatedDate(new Date()))
              } else {
                searchParams.set('date', ToBackendFormatedDate(getFirstDayOfMonth(value.activeStartDate)))
              }
              setSearchParams(searchParams)
            }}
            value={date}
            onClickDay={e => navigateDayClick(e)}
            tileContent={({ date: dateParam }) => (
              <TileContent events={events.get(ToBackendFormatedDate(dateParam)) ?? []} />
            )}
            view="month"
          />
        </CalendarContainer>
      ) : (
        <Typography textAlign="center" color={theme.palette.text.secondary}>
          {t('selfservice.teamcalendar.empty')}
        </Typography>
      )}

      {!specificPersonPage && teamId && (
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: 1 }}>
          <Link
            underline="always"
            onClick={() => {
              navigate(routes.SelfService.TEAMS_ID_CALENDAR(teamId), { date: ToRouteDateFormat(date) })
            }}
          >
            <Typography color={theme.palette.primary.main} fontSize="default" fontWeight="bold">
              {t('selfservice.teamcalendar.view')}
            </Typography>
          </Link>
        </Box>
      )}
    </Box>
  )
}

function TileContent({ events }: { events: CalendarDayEntryModel[] }) {
  const dayPart1Events = events.filter(event => event.timeFrameType.key === TIME_FRAME_TYPE_KEYS.DAY_PART_1)
  const dayPart2Events = events.filter(event => event.timeFrameType.key === TIME_FRAME_TYPE_KEYS.DAY_PART_2)
  const fullDayEvents = events.filter(event => event.timeFrameType.key === TIME_FRAME_TYPE_KEYS.FULL_DAY)

  return (
    <div className={'day-box'}>
      {dayPart1Events.map((x, index) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colourCodeHex ?? '',
            background:
              x.absenceRequestCalendarModel?.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colourCodeHex}, ${x.colourCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colourCodeHex ?? '',
            width: `calc(50%/${dayPart1Events.length})`
          }}
          className={'day-tile'}
        />
      ))}

      {fullDayEvents.map((x, index) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colourCodeHex ?? '',
            background:
              x.absenceRequestCalendarModel?.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colourCodeHex}, ${x.colourCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colourCodeHex ?? '',
            width: dayPart1Events.length > 0 || dayPart2Events.length > 0 ? '50%' : '100%'
          }}
          className={'day-tile'}
        />
      ))}

      {dayPart2Events.map((x, index) => (
        <div
          key={index}
          style={{
            backgroundColor: x.colourCodeHex ?? '',
            background:
              x.absenceRequestCalendarModel?.absenceRequestType.key === ABSENCE_REQUEST_STATUS_TYPE.REQUESTED
                ? `repeating-linear-gradient(45deg, ${x.colourCodeHex}, ${x.colourCodeHex} 2px, #FFFFFF 2px, #FFFFFF 3px)`
                : x.colourCodeHex ?? '',
            width: `calc(50%/${dayPart2Events.length})`
          }}
          className={dayPart1Events.length === 0 ? 'day-tile-right' : 'day-tile'}
        />
      ))}
    </div>
  )
}
