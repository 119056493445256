import { GridColDef } from '@mui/x-data-grid'
import { DataTable, DataTableCell, SortModel } from '../../../data-table'
import { Theme, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DataTableEmpty from '../../../data-table/data-table-empty'
import DoneIcon from '@mui/icons-material/Done'
import {
  CalculatedCalendarComparisonModel,
  useSuspenseGetCalculatedCalendarSummaryForClosureProcessQuery,
  useNameof
} from '@epix-web-apps/core'
import { useParams } from 'react-router-dom'
import { StepParams } from '../../generic-steps'
import { FinalCheckCalendarOverviewDownloadButton } from '../final-check-calendar-overview-download-button/final-check-calendar-overview-download-button'

function getColor(theme: Theme, value: number) {
  if (value > 0) return theme.palette.error.main
  if (value < 0) return theme.palette.success.main
  return undefined
}

export function FinalCheckCalendarOverview() {
  const { t } = useTranslation()
  const { nameof } = useNameof<CalculatedCalendarComparisonModel>()
  const theme = useTheme()
  const params = useParams<StepParams>()

  const { data: calendarData, isLoading } = useSuspenseGetCalculatedCalendarSummaryForClosureProcessQuery({
    processId: params.id ?? ''
  })

  const rowActions = (row: CalculatedCalendarComparisonModel) => {
    return (
      <FinalCheckCalendarOverviewDownloadButton
        processId={params.id ?? ''}
        payrollCodeId={row.payrollCodeId}
        payrollCode={row.payrollCode}
      />
    )
  }

  const columns: GridColDef<CalculatedCalendarComparisonModel>[] = [
    {
      field: nameof('payrollCode'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.payrollcode'),
      flex: 1.5,
      sortable: false
    },
    {
      field: nameof('payrollLabel'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.payrolllabel'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('epixDescription'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.epixdescription'),
      flex: 1,
      sortable: false
    },
    {
      field: nameof('previousPeriodAmount'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.previousperiodamount'),
      flex: 1,
      sortable: false,
      valueFormatter: (value: Date) =>
        value == null ? '' : value + ' ' + t('flyin.calendar-distribution.hours-abbreviation')
    },
    {
      field: nameof('currentPeriodAmount'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.currentperiodamount'),
      flex: 1,
      sortable: false,
      valueFormatter: (value: Date) =>
        value == null ? '' : value + ' ' + t('flyin.calendar-distribution.hours-abbreviation')
    },
    {
      field: nameof('difference'),
      headerName: t('processpage.finalcheck.calendaroverviewdatatable.column.difference'),
      flex: 1,
      sortable: false,
      renderCell: params => {
        return (
          <DataTableCell sx={{ color: getColor(theme, params.value) }}>
            {`${params.row.difference} ${t('flyin.calendar-distribution.hours-abbreviation')}`}
          </DataTableCell>
        )
      }
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  const data = (calendarData?.calculatedCalendarSummaryForClosureProcess ?? []).map(p => ({
    id: p.payrollCode,
    ...p
  }))

  return (
    <DataTable
      data={data}
      columns={columns}
      totalRowCount={calendarData?.calculatedCalendarSummaryForClosureProcess.length}
      isLoading={isLoading}
      hideFooter={true}
      emptyStateElement={
        <DataTableEmpty
          title={t('emptystate.process.finalcheck.calendaroverview')}
          icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
        />
      }
    />
  )
}
