import { Trans, useTranslation } from 'react-i18next'
import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import { IconButton } from '@mui/material'
import EditCompanyCarGeneralInformation from '../../edit-company-car-general-information'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyCarDetailModel } from '@epix-web-apps/core'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface CompanyCarGeneralInformationProps {
  car?: CompanyCarDetailModel
}

export function CompanyCarGeneralInformation({ car }: CompanyCarGeneralInformationProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  if (!car) return false

  return (
    <DetailBox
      title={t('companycardetailpage.generalinformation.title')}
      actionIcon={
        <IconButton
          size="small"
          onClick={() =>
            openFlyIn({
              content: <EditCompanyCarGeneralInformation />
            })
          }
        >
          <EditIcon />
        </IconButton>
      }
    >
      <DetailBoxRow>
        <p>
          <Trans
            i18nKey="companycardetailpage.registrationcountry"
            values={{ country: car?.country.name }}
            components={{ bold: <span className="bold" /> }}
          />
          {car?.registrationDate && (
            <Trans
              i18nKey="companycardetailpage.registrationdate"
              values={{
                registrationdate: parseISO(car.registrationDate).toLocaleDateString()
              }}
              components={{ bold: <span className="bold" /> }}
            />
          )}
        </p>
      </DetailBoxRow>
      <DetailBoxRow>
        <p>
          <Trans
            i18nKey="companycardetailpage.startdate"
            values={{
              startdate: parseISO(car.startDate).toLocaleDateString()
            }}
            components={{ bold: <span className="bold" /> }}
          />
          {car?.endDate && (
            <Trans
              i18nKey="companycardetailpage.enddate"
              values={{ enddate: parseISO(car.endDate).toLocaleDateString() }}
              components={{ bold: <span className="bold" /> }}
            />
          )}
        </p>
      </DetailBoxRow>
      <DetailBoxRow>
        <p>
          {car?.model ? (
            <Trans
              i18nKey="companycardetailpage.brandmodelplate"
              values={{
                brand: car?.carBrandType.value,
                model: car?.model,
                licenseplate: car?.licensePlate
              }}
              components={{ bold: <span className="bold" /> }}
            />
          ) : (
            <Trans
              i18nKey="companycardetailpage.brandplate"
              values={{
                brand: car?.carBrandType.value,
                licenseplate: car?.licensePlate
              }}
              components={{ bold: <span className="bold" /> }}
            />
          )}
        </p>
      </DetailBoxRow>
      {car?.carCategory?.code && (
        <DetailBoxRow>
          <p>
            {t('companycardetailpage.carcategory')}:<span className="bold"> {car?.carCategory?.description} </span>
          </p>
        </DetailBoxRow>
      )}
    </DetailBox>
  )
}

export default CompanyCarGeneralInformation
