import {
  FormSelectOption,
  GetInjuryHistoryByIdQuery,
  GraphqlError,
  numericString,
  useCreateInjuryHistoryCommandMutation,
  useRequiredParams,
  useSuspenseGetAllContractsWithEmployerByPersonIdQuery,
  useSuspenseGetInjuryHistoryByIdQuery,
  useUpdateInjuryHistoryCommandMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, number, object, string, TypeOf } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'

export interface AddInjuryHistoryProps {
  injuryId: string
}

export interface EditInjuryHistoryProps {
  injuryId: string
  injuryHistoryId: string
  contractId: string
}

export function AddInjuryHistory({ injuryId }: AddInjuryHistoryProps) {
  return AddEditInjuryHistory(injuryId)
}

export function EditInjuryHistory({ injuryId, injuryHistoryId, contractId }: EditInjuryHistoryProps) {
  const injuryHistoryQuery = useSuspenseGetInjuryHistoryByIdQuery({
    injuryId: injuryId,
    injuryHistoryId: injuryHistoryId
  })

  return AddEditInjuryHistory(injuryId, injuryHistoryQuery, contractId)
}

export function AddEditInjuryHistory(
  injuryId: string,
  injuryHistoryQuery?: UseSuspenseQueryResult<GetInjuryHistoryByIdQuery, unknown>,
  contractId?: string
) {
  const { t } = useTranslation()

  const addEditInjuryHistorySchema = object({
    contractId: string({
      required_error: t('form.validation.contractrequired'),
      invalid_type_error: t('form.validation.contractrequired')
    }).min(1, t('form.validation.contractrequired')),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date({
      required_error: t('form.validation.enddaterequired'),
      invalid_type_error: t('form.validation.enddaterequired')
    }),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.precentagelessthan100') })
    )
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  type AddEditInjuryHistoryForm = TypeOf<typeof addEditInjuryHistorySchema>
  const params = useRequiredParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const personId = params.id

  const { data: getAllContracts } = useSuspenseGetAllContractsWithEmployerByPersonIdQuery({
    personId: personId
  })
  const contracts = getAllContracts?.allContractsWithEmployerByPersonId

  const injuryHistory = injuryHistoryQuery?.data

  const form = useForm<AddEditInjuryHistoryForm>({
    resolver: zodResolver(addEditInjuryHistorySchema),
    defaultValues: {
      contractId: contracts?.length === 1 ? contracts[0].id : contractId,
      startDate: parseISO(injuryHistory?.injuryHistoryById?.startDate),
      endDate: parseISO(injuryHistory?.injuryHistoryById?.endDate),
      disabledPercentage: injuryHistory?.injuryHistoryById?.disabledPercentage
    }
  })

  const createMutation = useCreateInjuryHistoryCommandMutation()
  const updateMutation = useUpdateInjuryHistoryCommandMutation()

  const handleOnSubmit = async (newInjuryHistory: AddEditInjuryHistoryForm) => {
    if (!injuryHistory?.injuryHistoryById) {
      await createMutation
        .mutateAsync({
          createInjuryHistoryCommand: {
            injuryId: injuryId || '',
            startDate: newInjuryHistory.startDate,
            endDate: newInjuryHistory.endDate,
            contractId: newInjuryHistory.contractId,
            disabledPercentage: newInjuryHistory.disabledPercentage
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateInjuryHistoryCommand: {
            injuryId: injuryId || '',
            injuryHistoryId: injuryHistory.injuryHistoryById.id,
            startDate: newInjuryHistory.startDate,
            endDate: newInjuryHistory.endDate,
            contractId: newInjuryHistory.contractId,
            disabledPercentage: newInjuryHistory.disabledPercentage
          }
        })
        .then(() => {
          injuryHistoryQuery?.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {injuryHistory ? t('flyin.editinjuryhistory.title') : t('flyin.addinjuryhistory.title')}
      </Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="contractId"
          label={t('form.field.contract')}
          options={contracts?.map(x => new FormSelectOption(x.id, `${x.countryKey} (${x.employerName})`))}
          disabled={contracts?.length == 0}
        />
        {contracts?.length === 0 && (
          <Typography variant="description" sx={{ pl: 2 }}>
            {t('flyin.editinjuryhistory.addacontract')}
          </Typography>
        )}
        <FormDatepicker sx={12} name="startDate" label={t('form.field.injury.startdate')} />
        <FormDatepicker sx={12} name="endDate" label={t('form.field.injury.enddate')} />
        <FormNumericInput sx={12} name="disabledPercentage" label={t('form.field.disabledPercentage')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        disabled={contracts?.length === 0}
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditInjuryHistory
