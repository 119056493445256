import {
  OrderDirection,
  RequestPolicyPersonViewModel,
  ToBackendFormatedDate,
  useGetBalanceForContractQuery,
  useSuspenseGetRequestPolicyContractsQuery
} from '@epix-web-apps/core'
import { BorderedTableCell, useGlobalPersistedStore } from '@epix-web-apps/ui'
import {
  Box,
  CircularProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import { PaginationModel } from '../../data-table'
import { PagingNavigation } from '../../paging-navigation'

export function ProductivityBalancesTab() {
  const theme = useTheme()
  const { t } = useTranslation()
  const store = useProductivityAnalyticsPersistedStore()
  const { icpFilters } = useGlobalPersistedStore()
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())

  const { data, isLoading, isFetching } = useSuspenseGetRequestPolicyContractsQuery({
    requestPolicyIds: store.policiesFilter,
    icps: icpFilters,
    employerId: store.employerFilter,
    paygroupId: store.paygroupFilter,
    showMainBalance: store.mainBalanceFilter,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: 'name',
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    setPaginationModel(new PaginationModel(0))
  }, [icpFilters, store])

  return (
    <>
      <Box sx={{ overflowY: 'scroll', height: '100%', flex: 1 }}>
        <Box sx={{ height: '50vh' }}>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableHead sx={{ backgroundColor: theme.palette.grey[100], position: 'sticky', top: -1 }}>
              <TableRow>
                <BorderedTableCell>{t('productivity.balances.table.name')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.country')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.employer')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.policy')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.unit')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.total')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.taken')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.planned')}</BorderedTableCell>
                <BorderedTableCell>{t('productivity.balances.table.open')}</BorderedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!isLoading &&
                data?.requestPolicyContracts.data.map(r => (
                  <UserTableRow person={r} date={store.dateFilter} key={r.requestPolicyContractId} />
                ))}
            </TableBody>
          </Table>
          {data?.requestPolicyContracts.totalCount === 0 && (
            <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        {data && (
          <PagingNavigation
            hasNext={data.requestPolicyContracts.hasNext}
            onNextClick={() => {
              setPaginationModel(prev => new PaginationModel(prev.page + 1, prev.pageSize))
            }}
            hasPrevious={data.requestPolicyContracts.hasPrevious}
            onPreviousClick={() => {
              setPaginationModel(prev => new PaginationModel(prev.page - 1, prev.pageSize))
            }}
            isFetching={isFetching}
            paginationModel={paginationModel}
            totalCount={data.requestPolicyContracts.totalCount ?? 0}
          />
        )}
      </Box>
    </>
  )
}

export default ProductivityBalancesTab

function UserTableRow({ person, date }: { person: RequestPolicyPersonViewModel; date: Date | null }) {
  const { data, isLoading } = useGetBalanceForContractQuery({
    requestPolicyContractId: person.requestPolicyContractId,
    viewDate: ToBackendFormatedDate(date ?? new Date())
  })

  return (
    <TableRow>
      <BorderedTableCell>{person.name}</BorderedTableCell>
      <BorderedTableCell>{person.countryKey}</BorderedTableCell>
      <BorderedTableCell>{person.employerName}</BorderedTableCell>
      <BorderedTableCell>{person.policyName}</BorderedTableCell>
      <BorderedTableCell>{person.absenceTypeKey}</BorderedTableCell>
      {isLoading ? (
        <LoadingTableCell />
      ) : (
        <>
          <BorderedTableCell>{data?.balanceForContract.total}</BorderedTableCell>
          <BorderedTableCell>{data?.balanceForContract.taken}</BorderedTableCell>
          <BorderedTableCell>{data?.balanceForContract.planned}</BorderedTableCell>
          <BorderedTableCell>{data?.balanceForContract.open}</BorderedTableCell>
        </>
      )}
    </TableRow>
  )
}

function LoadingTableCell() {
  return (
    <>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
      <BorderedTableCell>
        <Skeleton />
      </BorderedTableCell>
    </>
  )
}
