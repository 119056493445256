import {
  ImportProcessStatus,
  useDeleteImportProcessMutation,
  useSuspenseGetImportProcessesQuery
} from '@epix-web-apps/core'
import { Import } from '@epix-web-apps/ui'
import { PendingActions } from '@mui/icons-material'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined'
import { Box, Button, IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'

interface IImportRow {
  id: string
  state: ImportProcessStatus
  filename: string
  success?: boolean | null
  importDataType: string
  fileType: string
  startedOn: Date
}

function StatusIcon({ state }: { state: ImportProcessStatus }) {
  const theme = useTheme()
  switch (state) {
    case ImportProcessStatus.Created:
      return <ScheduleOutlinedIcon sx={{ color: theme.palette.primary.main }} />
    case ImportProcessStatus.Imported:
      return <CheckCircleOutlineOutlinedIcon sx={{ color: theme.palette.success.main }} />
    case ImportProcessStatus.ConversionFailed:
    case ImportProcessStatus.ValidationFailed:
    case ImportProcessStatus.ImportFailed:
      return <CancelOutlinedIcon sx={{ color: theme.palette.error.main }} />
    case ImportProcessStatus.ConverterNeeded:
    case ImportProcessStatus.Validated:
      return <PendingActions sx={{ color: theme.palette.primary.main }} />
    default:
      return <ChangeCircleOutlinedIcon sx={{ color: theme.palette.primary.main }} />
  }
}

export function ImportOverviewPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [paginationModel, setPaginationModel] = useState(new PaginationModel(0))
  const [clickedJob, setClickedJob] = useState<IImportRow>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const deleteMutation = useDeleteImportProcessMutation()

  const {
    data: getCsvImportJobs,
    isLoading,
    refetch
  } = useSuspenseGetImportProcessesQuery({
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const getFileNameExtension = (fileName: string): string => {
    const splitName: string[] = fileName.split('.')

    if (splitName.length > 1) {
      return splitName[splitName.length - 1]
    } else {
      return ''
    }
  }

  const tableData: GridRowsProp = getCsvImportJobs.importProcesses.data.map<IImportRow>(row => ({
    id: row.id,
    state: row.status,
    filename: row.fileName,
    startedOn: parseISO(row.startedOn),
    fileType: getFileNameExtension(row.fileName),
    importDataType: row.type.value
  }))

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedJob(rowParams.row)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('configurationimport.status.title')}
      </Typography>

      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button variant="contained" sx={{ display: 'flex', gap: 1 }} onClick={() => navigate(Import.DATA)}>
          <CloudUploadOutlinedIcon />
          {t('configurationimport.status.newimport')}
        </Button>
        <Button
          variant="contained"
          sx={{ display: 'flex', gap: 1 }}
          onClick={() => {
            setPaginationModel(new PaginationModel(0))
            refetch()
          }}
        >
          <CachedOutlinedIcon />
          <span>{t('configurationimport.status.refresh')}</span>
        </Button>
      </Box>
    </Box>
  )

  const columns: GridColDef<IImportRow>[] = [
    {
      field: 'state',
      headerName: t('configurationimport.status.column.state'),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => (
        <DataTableCell sx={{ justifyContent: 'center' }}>
          <StatusIcon state={params.row.state} />
        </DataTableCell>
      )
    },
    {
      field: 'filename',
      headerName: t('configurationimport.status.column.filename'),
      sortable: false,
      flex: 1
    },
    {
      field: 'fileType',
      headerName: t('configurationimport.status.column.filetype'),
      sortable: false,
      flex: 1
    },
    {
      field: 'importDataType',
      headerName: t('configurationimport.status.column.datatype'),
      sortable: false,
      flex: 1
    },
    {
      field: 'startedOn',
      headerName: t('configurationimport.status.column.startedon'),
      sortable: false,
      renderCell: params => params.row.startedOn.toLocaleString(),
      flex: 1
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  async function handleDelete() {
    if (!clickedJob || !canBeDeleted(clickedJob.state)) return

    await deleteMutation
      .mutateAsync({
        deleteImportProcessJobCommand: {
          id: clickedJob.id
        }
      })
      .then(() => {
        refetch()
      })
  }

  function canBeDeleted(status: ImportProcessStatus) {
    return [ImportProcessStatus.ImportFailed, ImportProcessStatus.Imported].includes(status)
  }

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={getCsvImportJobs.importProcesses.totalCount}
        onRowClick={params => {
          navigate(Import.DATA_ID(params.row.id))
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoading}
        filterBarElement={filterBar}
      />

      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            if (!clickedJob) return
            navigate(Import.DATA_ID(clickedJob?.id))
          }}
        >
          {t('peoplepage.list.row.menu.viewdetails')}
        </MenuItem>
        {clickedJob && canBeDeleted(clickedJob.state) && <MenuItem onClick={handleDelete}>Delete</MenuItem>}
      </DropdownMenu>
    </>
  )
}

export default ImportOverviewPage
