import { useSuspenseGetEmployeePopulationDistributionQuery } from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import PiechartComponent from '../piechart-component'
import { PieData } from '../piechart-component/piechart-component'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'

/* eslint-disable-next-line */
export interface EmployeePopulationComponentProps {}

export function EmployeePopulationComponent(props: EmployeePopulationComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter } = useAnalyticsPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()

  const { data: contractPopulation } = useSuspenseGetEmployeePopulationDistributionQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })
  return (
    <PiechartComponent
      data={contractPopulation?.employeePopulationDistribution.entries.map(
        x => new PieData(x.employeePopulationValue, x.amount.toString(), x.employeePopulationValue + ': ' + x.amount)
      )}
      total={contractPopulation?.employeePopulationDistribution.total}
      name={t('analytics.pie.contractTotal')}
    />
  )
}

export default EmployeePopulationComponent
