import {
  formTypeSelectOptions,
  useSuspenseGetBuropartnerContractDetailsQuery,
  useUpdateBuropartnerContractDetailEducationLevelMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditBuropartnerEducationLevelProps {
  contractId: string
}

export function EditBuropartnerEducationLevel({ contractId }: EditBuropartnerEducationLevelProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetBuropartnerContractDetailsQuery({
      contractId: contractId || ''
    })

  const editBuropartnerEducationLevelSchema = object({
    generalEducationLevelType: string().optional().nullable(),
    professionalEducationLevelType: string().optional().nullable()
  })

  type EditBuropartnerEducationLevelForm = TypeOf<typeof editBuropartnerEducationLevelSchema>

  const { professionalEducationLevelOptions, generalEducationLevelOptions } = formTypeSelectOptions

  const form = useForm<EditBuropartnerEducationLevelForm>({
    resolver: zodResolver(editBuropartnerEducationLevelSchema),
    defaultValues: {
      generalEducationLevelType:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailEducationLevelModel
          .generalEducationLevelType?.key,
      professionalEducationLevelType:
        partnerSpecificDetails?.buropartnerContractDetailByContractId.buropartnerContractDetailEducationLevelModel
          .professionalEducationLevelType?.key
    }
  })

  const updateMutation = useUpdateBuropartnerContractDetailEducationLevelMutation()

  const handleOnSubmit = async (buroparterEducationLevel: EditBuropartnerEducationLevelForm) => {
    await updateMutation
      .mutateAsync({
        buropartnerContractDetailEducationLevelCommand: {
          id: partnerSpecificDetails?.buropartnerContractDetailByContractId.id || '',
          generalEducationLevelTypeKey: buroparterEducationLevel.generalEducationLevelType,
          professionalEducationLevelTypeKey: buroparterEducationLevel.professionalEducationLevelType
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editburoparnereducationlevel.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="generalEducationLevelType"
          label={t('form.field.generaleducationlevel')}
          options={generalEducationLevelOptions}
        />

        <FormSelect
          sx={12}
          name="professionalEducationLevelType"
          label={t('form.field.professionaleducationlevel')}
          options={professionalEducationLevelOptions}
        />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditBuropartnerEducationLevel
