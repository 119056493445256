import {
  ABSENCE_TYPE_KEYS,
  ContractByTeamStructureModel,
  FormSelectOption,
  GraphqlError,
  REQUEST_MINIMUM_TYPE_KEYS,
  REQUEST_RIGHT_EDITOR,
  TIME_FRAME_TYPE_KEYS,
  ToRouteDateFormat,
  formTypeSelectOptions,
  numericString,
  useCreateAbsenceRequestMutation,
  useGetRequestAbsenceBalancesQuery,
  useGetRequestPolicyContractsByContractIdByTeamStructureQuery,
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetAllContractsByPersonIdAndTeamStructureQuery,
  useSuspenseGetTeamMemberReqeustRightsQuery,
  useUploadPersonalCalendarDayFileForTeamStructureMutation
} from '@epix-web-apps/core'
import {
  ACCEPTED_UPLOAD_TYPES,
  DATE_INPUT_FORMAT,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormFileUpload,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  HeaderTitleNavigation,
  SelfService,
  useGlobalStore,
  useRouteDefinitions
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import EventIcon from '@mui/icons-material/Event'
import MessageIcon from '@mui/icons-material/Message'
import { Box, Button, Card, Grid, Typography, styled, useTheme } from '@mui/material'
import { isDate, isValid, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TypeOf, any, date, number, object, string } from 'zod'

export type RequestAbsenceQueryParams = {
  teamId: string
  id: string
}

export function RequestAbsence() {
  const { t } = useTranslation()
  const params = useRequiredParams<RequestAbsenceQueryParams>()
  const navigate = useNavigateWithParams()
  const theme = useTheme()
  const routes = useRouteDefinitions()
  const [comment, setComment] = useState(false)
  const personId = params.id
  const [contractId, setContractId] = useState<string>()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [requestPolicyContractId, setRequestPolicyContractId] = useState<string>()
  const [searchParams] = useSearchParams()
  const [unlimitedRequestPolicy, setUnlimitedRequestPolicy] = useState(true)
  const [absenceTypeKey, setAbsenceTypeKey] = useState('')
  const [requestMinimumTypeKey, setRequestMinimumTypeKey] = useState('')
  const { me } = useGlobalStore()
  const { timeFrameOptions } = formTypeSelectOptions
  const { mutateAsync: uploadFileAsync } = useUploadPersonalCalendarDayFileForTeamStructureMutation()

  const { data: editors } = useSuspenseGetTeamMemberReqeustRightsQuery({
    otherPersonId: params.id ?? me?.personId ?? '',
    requestPolicyRightTypeKey: REQUEST_RIGHT_EDITOR
  })

  const isEditorForPerson = editors?.allTeamMemberRequestRights.hasRight

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const requestAbsenceSchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date({
      required_error: t('form.validation.enddaterequired'),
      invalid_type_error: t('form.validation.enddaterequired')
    }),
    timeFrameType: string({
      required_error: t('form.validation.timeframetyperequired'),
      invalid_type_error: t('form.validation.timeframetyperequired')
    }),
    requestPolicyContractId: string({
      required_error: t('form.validation.absencetyperequired'),
      invalid_type_error: t('form.validation.absencetyperequired')
    }).min(1, t('form.validation.absencetyperequired')),
    comment: string().optional().nullable(),
    numberOfHours: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    documents: any().optional()
  }).refine(data => data.endDate >= data.startDate, {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type CreateAbsenceRequestForm = TypeOf<typeof requestAbsenceSchema>

  const dateSearchParamString = searchParams.get('date')
  const dateSearchParam = dateSearchParamString != null ? parseISO(dateSearchParamString) : undefined

  const form = useForm<CreateAbsenceRequestForm>({
    resolver: zodResolver(requestAbsenceSchema),
    defaultValues: {
      startDate: dateSearchParam,
      endDate: dateSearchParam
    }
  })

  const TypographyBalance = styled(Typography)(() => {
    return {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
      fontWeight: 'bold',
      margin: '1rem 0',
      padding: '0.75rem',
      textAlign: 'center'
    }
  })

  const createMutation = useCreateAbsenceRequestMutation()

  const { data: getAllContracts } = useSuspenseGetAllContractsByPersonIdAndTeamStructureQuery({
    personId: personId
  })

  const { data: getRequestPolicyContracts } = useGetRequestPolicyContractsByContractIdByTeamStructureQuery(
    {
      contractId: contractId ?? '',
      validFrom: startDate,
      validUntil: endDate,
      canBeRequested: isEditorForPerson && me?.personId !== params.id ? null : true
    },
    {
      enabled: !!contractId && !!startDate && !!endDate
    }
  )

  const { data: requestAbsenceBalances } = useGetRequestAbsenceBalancesQuery(
    {
      requestPolicyContractId: requestPolicyContractId || '',
      startDate: startDate,
      endDate: endDate
    },
    {
      enabled: !!requestPolicyContractId && !unlimitedRequestPolicy && !!startDate && !!endDate
    }
  )

  useEffect(() => {
    if (getAllContracts && getAllContracts.allContractsByPersonIdAndTeamStructure.length === 1) {
      setContractId(getAllContracts.allContractsByPersonIdAndTeamStructure[0].contractId)
    }
  }, [getAllContracts])

  useEffect(() => {
    if (requestPolicyContractId && getRequestPolicyContracts?.allRequestPolicyContractsByContractIdByTeamStructure) {
      const getRequestPolicyContract =
        getRequestPolicyContracts.allRequestPolicyContractsByContractIdByTeamStructure.find(
          p => p.id === requestPolicyContractId
        )
      if (getRequestPolicyContract) {
        setUnlimitedRequestPolicy(getRequestPolicyContract?.requestPolicyUnlimited)
        setAbsenceTypeKey(getRequestPolicyContract.requestPolicyAbsenceType.key)
        setRequestMinimumTypeKey(getRequestPolicyContract.requestPolicyMinimumType.key)
      }
    }
  }, [requestPolicyContractId, getRequestPolicyContracts])

  useEffect(() => {
    if (dateSearchParam) {
      setStartDate(dateSearchParam)
      setEndDate(dateSearchParam)
    }
  }, [dateSearchParam])

  const uploadFile = async (absenceRequestId: string, file: File) => {
    if (file) {
      await uploadFileAsync({
        file: file,
        absenceRequestId: absenceRequestId
      })
    }
  }

  const handleOnSubmit = async (newAbsenceRequest: CreateAbsenceRequestForm) => {
    await createMutation
      .mutateAsync({
        createAbsenceRequestCommand: {
          endDate: newAbsenceRequest.endDate,
          startDate: newAbsenceRequest.startDate,
          numberOfHours: newAbsenceRequest.numberOfHours ?? 0,
          comment: newAbsenceRequest.comment,
          timeFrameTypeKey: newAbsenceRequest.timeFrameType,
          requestPolicyContractId: newAbsenceRequest.requestPolicyContractId
        }
      })
      .then(response =>
        newAbsenceRequest.documents.forEach((x: any) => uploadFile(response.createAbsenceRequest, x.file))
      )
      .then(() =>
        me?.personId === personId
          ? navigate(SelfService.ROOT())
          : navigate(SelfService.PERSON_CALENDAR_TEAMID_ID(`${params.teamId}`, `${params.id}`))
      )
      .catch(e => setBackendErrors([e]))
  }

  function formatContractValue(contract: ContractByTeamStructureModel): string {
    const startDateString = parseISO(contract.startDate).toLocaleDateString()
    const endDateString = contract.endDate ? parseISO(contract.startDate).toLocaleDateString() : '...'
    return `${contract.icpCode} ${startDateString} - ${endDateString}`
  }

  const filteredRequestPolicyContractOptions =
    isEditorForPerson && me?.personId !== params.id
      ? getRequestPolicyContracts?.allRequestPolicyContractsByContractIdByTeamStructure?.filter(requestPolicyOption => {
          return editors?.allTeamMemberRequestRights?.requestPolicyIds.includes(requestPolicyOption.requestPolicyId)
        })
      : getRequestPolicyContracts?.allRequestPolicyContractsByContractIdByTeamStructure

  const requestPolicyContractOptions = filteredRequestPolicyContractOptions?.map(
    requestPolicyContract =>
      new FormSelectOption(requestPolicyContract.id, requestPolicyContract.requestPolicyUserFriendlyName)
  )

  return (
    <>
      <HeaderTitleNavigation
        onBackClick={() => {
          if (me?.personId !== params.id) {
            navigate(routes.SelfService.PEOPLE_ID_CALENDAR(`${params.id}`))
          } else {
            navigate(routes.SelfService.ROOT)
          }
        }}
        mobileSizeTitle
        title={t('selfservice.request-absence.title')}
      />
      <Box sx={{ pt: 3, px: 1 }}>
        <FormContainer sx={{ width: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
          <FormGridLayout>
            <FormDatepicker
              sx={6}
              name="startDate"
              inputFormat={DATE_INPUT_FORMAT}
              label={`${t('form.field.startdate')} *`}
              onChange={value => {
                if (value != null && isValid(value)) {
                  setStartDate(value)
                }

                if (isDate(form.getValues().startDate) && !form.getValues().endDate) {
                  form.setValue('endDate', form.getValues().startDate)
                  setEndDate(form.getValues().endDate)
                }
              }}
            />

            <FormDatepicker
              sx={6}
              name="endDate"
              inputFormat={DATE_INPUT_FORMAT}
              label={`${t('form.field.enddate')} *`}
              onChange={value => {
                if (value != null && isValid(value)) {
                  setEndDate(value)
                }
              }}
            />

            {getAllContracts &&
              getAllContracts.allContractsByPersonIdAndTeamStructure &&
              getAllContracts.allContractsByPersonIdAndTeamStructure.length > 1 && (
                <FormSelect
                  sx={12}
                  name="contract"
                  label={`${t('form.field.contract')} *`}
                  options={getAllContracts.allContractsByPersonIdAndTeamStructure.map(
                    contract => new FormSelectOption(contract.contractId, formatContractValue(contract))
                  )}
                  onChange={(_, value) => {
                    setContractId(value?.id)
                  }}
                ></FormSelect>
              )}

            <FormSelect
              sx={12}
              name="requestPolicyContractId"
              label={`${t('form.field.requestpolicycontract')} *`}
              disabled={!startDate || !endDate}
              options={requestPolicyContractOptions}
              onChange={(_, value) => {
                setRequestPolicyContractId(value?.id)
              }}
            />

            <FormSelect
              sx={12}
              name="timeFrameType"
              label={`${t('form.field.timeframetype')} *`}
              disabled={!startDate || !endDate}
              options={
                requestMinimumTypeKey === REQUEST_MINIMUM_TYPE_KEYS.FULL_DAY
                  ? timeFrameOptions.filter(f => f.id === TIME_FRAME_TYPE_KEYS.FULL_DAY)
                  : timeFrameOptions
              }
            />

            {absenceTypeKey === ABSENCE_TYPE_KEYS.HOURS &&
              requestMinimumTypeKey !== REQUEST_MINIMUM_TYPE_KEYS.FULL_DAY && (
                <Grid item xs={12}>
                  <FormNumericInput
                    sx={12}
                    name="numberOfHours"
                    placeholder={'0'}
                    label={`${t('form.field.numberofhours')}`}
                  />
                  <Typography ml={1} color={'gray'} sx={{ fontStyle: 'italic' }}>
                    {t('selfservice.request-absence.numberofhoursexplanation')}
                  </Typography>
                </Grid>
              )}

            {!unlimitedRequestPolicy && requestAbsenceBalances?.requestAbsenceBalances && (
              <>
                <Grid item xs={12}>
                  <Card>
                    <TypographyBalance>
                      {' '}
                      {requestAbsenceBalances?.requestAbsenceBalances.startBalance}{' '}
                      {requestAbsenceBalances?.requestAbsenceBalances.inHours
                        ? t('selfservice.request-absence.hours-available')
                        : t('selfservice.request-absence.days-available')}
                    </TypographyBalance>
                  </Card>
                </Grid>

                {/* TODO: workitem 1338 display none until remaining balance is correct */}
                <Grid item xs={2} sx={{ display: 'none' }}>
                  <Box sx={{ mt: 4.5, textAlign: 'center' }}>
                    <ArrowRightAltIcon />
                  </Box>
                </Grid>

                {/* TODO: workitem 1338 display none until remaining balance is correct */}
                <Grid item xs={5} sx={{ display: 'none' }}>
                  <Card>
                    <TypographyBalance>
                      {' '}
                      {requestAbsenceBalances?.requestAbsenceBalances.endBalance}{' '}
                      {requestAbsenceBalances?.requestAbsenceBalances.inHours
                        ? t('selfservice.request-absence.hours-remaining')
                        : t('selfservice.request-absence.days-remaining')}
                    </TypographyBalance>
                  </Card>
                </Grid>
              </>
            )}

            <Grid item xs={12} display="flex">
              <Grid item xs={6}>
                {!comment && (
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<MessageIcon />}
                    onClick={() => setComment(!comment)}
                  >
                    {t('selfservice.request-absence.addcomment')}
                  </Button>
                )}
              </Grid>
            </Grid>

            {comment && <FormInput sx={12} name="comment" multiline={true} label={t('form.field.comment')} />}

            <FormFileUpload
              sx={12}
              showIcon={true}
              name="documents"
              buttonText={t('documentcomponent.button.title')}
              accept={[ACCEPTED_UPLOAD_TYPES.JPEG, ACCEPTED_UPLOAD_TYPES.PNG, ACCEPTED_UPLOAD_TYPES.PDF]}
            />

            <Grid item xs={12}>
              <FormErrorList customErrors={backendErrors} />
            </Grid>
            <Grid item xs={12}>
              <FormActionButtons
                isMutating={createMutation.isPending}
                onCancel={() => navigate(SelfService.ROOT())}
                buttonText={t('common.request')}
              ></FormActionButtons>
            </Grid>
          </FormGridLayout>
        </FormContainer>
      </Box>
      {(me?.personId === personId || isEditorForPerson) && (
        <Box>
          <Button
            variant="outlined"
            size="small"
            startIcon={<EventIcon />}
            onClick={() =>
              navigate(
                routes.SelfService.PEOPLE_ID_CALENDAR_DATE(personId, ToRouteDateFormat(dateSearchParam ?? new Date()))
              )
            }
          >
            {t('selfservice.request-absence.daydetail')}
          </Button>
        </Box>
      )}
    </>
  )
}

export default RequestAbsence
