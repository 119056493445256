import { ReportDefinitionFilterModel, useDeleteReportDefinitionFilterMutation } from '@epix-web-apps/core'
import { FLYIN_WIDTH, FormActionButtons, useFlyIn } from '@epix-web-apps/ui'
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface DeleteReportFilterFlyinProps {
  filter: ReportDefinitionFilterModel
}
export function DeleteReportFilterFlyin({ filter }: DeleteReportFilterFlyinProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const mutation = useDeleteReportDefinitionFilterMutation()

  function handleDelete() {
    mutation
      .mutateAsync({
        deleteReportDefinitionFilterCommand: {
          reportDefinitionId: filter.reportDefinitionId,
          reportDefinitionFilterId: filter.id
        }
      })
      .then(_ => closeFlyIn())
  }
  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Typography variant="h4">Delete report filter</Typography>

      <Table size="small" sx={{ mb: 2 }}>
        <TableBody>
          <TableRow>
            <TableCell>{t('reportinganalyticsdetailpage.general.providers.table.provider')}</TableCell>
            <TableCell>
              <strong>
                {filter.icpCountryKey} - {filter.icpName}
              </strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('reportinganalyticsdetailpage.general.providers.table.paygroup')}</TableCell>
            <TableCell>
              <strong>{filter.employerName ?? t('reportinganalyticsdetailpage.general.providers.flyin.all')}</strong>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('reportinganalyticsdetailpage.general.providers.table.paygroup')}</TableCell>
            <TableCell>
              <strong>{filter.payGroupName ?? t('reportinganalyticsdetailpage.general.providers.flyin.all')}</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <FormActionButtons
        buttonText={t('common.delete')}
        onClick={() => handleDelete()}
        onCancel={() => closeFlyIn()}
        isMutating={mutation.isPending}
      />
    </Box>
  )
}
