import {
  useSuspenseGetAllLocalesQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetPersonForSelfServiceByIdQuery,
  useUpdateMyLocaleMutation
} from '@epix-web-apps/core'
import { EpixAvatar } from '@epix-web-apps/ui'
import { Box, MenuItem, Paper, Select, Typography, useTheme } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface ProfileSettingsPageProps {}

export function ProfileSettingsPage(props: ProfileSettingsPageProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const queryCache = useQueryClient()
  const { data: me } = useSuspenseGetMeQuery()
  const mutation = useUpdateMyLocaleMutation()
  const { i18n } = useTranslation()
  const [selectedLocale, setSelectedLocale] = useState(me?.me.locale.locale)

  const { data: getPersonForSelfServiceById } = useSuspenseGetPersonForSelfServiceByIdQuery({
    personId: me?.me.personId || ''
  })
  const person = getPersonForSelfServiceById?.personByIdForSelfservice
  const { data: getAllLocales } = useSuspenseGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true,
    showInRespectiveLanguage: true
  })

  const handleOnSubmit = async (event: any) => {
    await mutation
      .mutateAsync({ locale: event?.target.value })
      .then(updatedLocale => {
        sessionStorage.setItem('language', updatedLocale.updateMyLocale)
        i18n.changeLanguage(updatedLocale.updateMyLocale)
      })
      .finally(() => {
        queryCache.removeQueries()
        setSelectedLocale(event?.target.value)
      })
  }

  const paperStyle = {
    px: 2,
    padding: '0.5rem',
    marginTop: '1rem',
    borderColor: theme.palette.primary.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }

  return (
    <Box>
      <Paper sx={paperStyle}>
        <Typography variant="h4">{t('profilesettingspage.personalinformation.title')}</Typography>
        <EpixAvatar personId={me?.me.personId || ''} editable />
        <Typography variant="h6">
          {person?.firstName} {person?.lastName}
        </Typography>
      </Paper>

      <Paper sx={paperStyle}>
        <Typography variant="h4">{t('profilesettingspage.generalsettings.title')}</Typography>
        <Typography variant="h6">{t('profilesettingspage.generalsettings.preferredlanguage')}</Typography>
        <Select
          value={selectedLocale}
          onChange={handleOnSubmit}
          fullWidth
          labelId="language-select-label"
          id="language-select"
          style={{ borderRadius: 2, maxWidth: '20rem' }}
        >
          {getAllLocales?.locales.map(item => (
            <MenuItem key={item.locale} value={item.locale}>
              {item.language}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </Box>
  )
}

export default ProfileSettingsPage
