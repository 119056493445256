import {
  FormSelectOption,
  GetIcpByIdQuery,
  GraphqlError,
  OrderDirection,
  useCreateIcpMutation,
  useGetAllIcpsQuery,
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetIcpByIdQuery,
  useUpdateIcpMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export interface EditIcpProps {
  icpId: string
}

export function AddIcp() {
  return AddEditIcp()
}

export function EditIcp({ icpId }: EditIcpProps) {
  const getIcpByIdQuery = useSuspenseGetIcpByIdQuery({
    icpId: icpId
  })

  return AddEditIcp(getIcpByIdQuery)
}

function AddEditIcp(getIcpByIdQuery?: UseSuspenseQueryResult<GetIcpByIdQuery, unknown>) {
  const { t } = useTranslation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const getIcpById = getIcpByIdQuery?.data

  const addEditIcpSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    })
      .min(2, t('form.validation.coderequired'))
      .max(3, t('form.validation.codemaxchars')),
    country: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }).min(2, t('form.validation.countryrequired')),
    payrollProvider: string({
      required_error: t('form.validation.payrollproviderrequired'),
      invalid_type_error: t('form.validation.payrollproviderrequired')
    }).min(1, t('form.validation.payrollproviderrequired'))
  }).refine(
    data =>
      !getAllIcps?.icps.data.find(
        i => i.code.toLocaleUpperCase() === data.code.toLocaleUpperCase() && i.active && i.id !== getIcpById?.icpById.id
      )
        ? data.code
        : !data.code,
    {
      message: t('form.validation.codeunique'),
      path: ['endDate']
    }
  )

  type CreateEditIcpForm = TypeOf<typeof addEditIcpSchema>

  const { data: countries } = useSuspenseGetAllCountriesQuery({
    hasCurrency: true
  })

  const countryOptions = countries?.countries.map(x => new FormSelectOption(x.code, x.name)) || []

  const { data: getAllIcps } = useGetAllIcpsQuery({
    activeOnly: false,
    offset: 0,
    limit: -1,
    sortByProperty: null,
    orderDirection: OrderDirection.Asc
  })

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditIcpForm>({
    resolver: zodResolver(addEditIcpSchema),
    defaultValues: {
      country: getIcpById?.icpById?.country || undefined,
      code: getIcpById?.icpById?.code || undefined,
      payrollProvider: getIcpById?.icpById?.payrollProvider || undefined
    }
  })

  const createMutation = useCreateIcpMutation()
  const updateMutation = useUpdateIcpMutation()

  const handleOnSubmit = async (newIcp: CreateEditIcpForm) => {
    if (!getIcpById) {
      await createMutation
        .mutateAsync({
          createIcpCommand: {
            code: newIcp.code,
            active: true,
            country: newIcp.country,
            payrollProvider: newIcp.payrollProvider
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateIcpCommand: {
            id: getIcpById.icpById.id,
            code: newIcp.code,
            active: getIcpById?.icpById?.active ?? false,
            payrollProvider: newIcp.payrollProvider
          }
        })
        .then(() => {
          getIcpByIdQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addediticp.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.icpcode')}`} />

        <FormInput sx={12} name="payrollProvider" label={`${t('form.field.payrollprovider')}`} />

        <FormSelect sx={12} name="country" label={`${t('form.field.country')}`} options={countryOptions} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditIcp
