import { DEFAULT_UNDEFINED_KEY, useRequiredParams, useSuspenseGetPersonByIdQuery } from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import { Box, IconButton } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { EditPersonCompanyInfo, EditPersonContactInfo } from '../../../components/people-components'
import { EditPersonPersonalInformation } from '../../../components/people-components/edit-person-personal-information'

/* eslint-disable-next-line */
export interface InformationTabProps {}

export function InformationTab(props: InformationTabProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { openFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const { data: getPersonById, refetch } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const person = getPersonById.personById

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('peopledetailpage.companyinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonCompanyInfo />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {person?.company?.functionTitle && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.companyinfo.functiontitle')}:{' '}
                <span className="bold">{person?.company?.functionTitle}</span>
              </p>
            </DetailBoxRow>
          )}
          {person?.company?.functionAreaTypeKey && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.companyinfo.functionarea')}:{' '}
                <span className="bold">{person?.company?.functionAreaTypeKey.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {person?.company?.functionLevelTypeKey && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.companyinfo.functionlevel')}:{' '}
                <span className="bold">{person?.company?.functionLevelTypeKey.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {person?.company?.startedOn && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.companyinfo.startedon')}{' '}
                <span className="bold">{parseISO(person?.company?.startedOn).toLocaleDateString()}</span>
              </p>
            </DetailBoxRow>
          )}
          {person?.company?.personNumber && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.companyinfo.employeenumber')}:{' '}
                <span className="bold">{person?.company?.personNumber}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>

        <DetailBox
          title={t('peopledetailpage.personalinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonPersonalInformation />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {person?.educationLevelType?.key !== DEFAULT_UNDEFINED_KEY && (
            <DetailBoxRow>
              <p>
                {t('peopledetailpage.personalinfo.educationlevel')}:{' '}
                <span className="bold">{person?.educationLevelType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('peopledetailpage.contactinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonContactInfo />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <p>
                <HomeOutlinedIcon sx={{ color: 'inherit !important' }} />
              </p>
              <Box>
                <p>{person?.personalPhoneNumber && person.personalPhoneNumber}</p>
                <p>{person?.personalMobileNumber && person.personalMobileNumber}</p>
                <p>{person?.personalEmail && person.personalEmail}</p>
              </Box>
            </Box>
          </DetailBoxRow>

          <DetailBoxRow>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <p>
                <WorkOutlineOutlinedIcon sx={{ color: 'inherit !important' }} />
              </p>
              <Box>
                <p>{person?.workPhoneNumber && person.workPhoneNumber}</p>
                <p>{person?.workMobileNumber && person.workMobileNumber}</p>
                <p>{person?.workEmail && person.workEmail}</p>
              </Box>
            </Box>
          </DetailBoxRow>
        </DetailBox>
      </Box>
    </Box>
  )
}

export default InformationTab
