import {
  byteArrayToFile,
  ToBackendFormatedDate,
  useExportCalculatedCalendarSummaryForClosureProcessQuery
} from '@epix-web-apps/core'
import { DownloadButton } from '@epix-web-apps/ui'

interface FinalCheckCalendarOverviewDownloadButtonProps {
  processId: string
  payrollCodeId: string
  payrollCode: string
}

export function FinalCheckCalendarOverviewDownloadButton({
  processId,
  payrollCodeId,
  payrollCode
}: FinalCheckCalendarOverviewDownloadButtonProps) {
  const { isFetching, refetch: refetchExport } = useExportCalculatedCalendarSummaryForClosureProcessQuery(
    {
      payrollClosureProcessId: processId,
      payrollCodeId: payrollCodeId
    },
    {
      enabled: false
    }
  )

  async function download() {
    const result = await refetchExport()
    byteArrayToFile(
      result.data?.exportCalculatedCalendarSummaryForClosureProcess as unknown as Uint8Array,
      `final-check-calendar-code-${payrollCode}_${ToBackendFormatedDate(new Date())}.xlsx`
    )
  }

  return <DownloadButton isFetching={isFetching} onClick={download} />
}
