import {
  FormSelectOption,
  useSuspenseGetAllTeamsQuery,
  useSuspenseGetTeamByIdQuery,
  useUpdateRootTeamMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

export interface EditRootTeamProps {
  teamId: string
}

export function EditRootTeam({ teamId }: EditRootTeamProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getTeamById } = useSuspenseGetTeamByIdQuery({
    id: teamId
  })

  const { data: allTeams } = useSuspenseGetAllTeamsQuery({
    sortByProperty: 'Name'
  })

  const ownChildren = getTeamById?.teamById.children?.map(x => x.id)
  const filteredTeams = allTeams?.allTeams.filter(
    x => !ownChildren?.includes(x.id) && x.id !== getTeamById?.teamById.id
  )
  const teams = filteredTeams

  const editRootTeamSchema = object({
    rootTeamId: string()
  })

  type EditRootTeamForm = TypeOf<typeof editRootTeamSchema>

  const form = useForm<EditRootTeamForm>({
    resolver: zodResolver(editRootTeamSchema),
    defaultValues: {
      rootTeamId: getTeamById?.teamById.parentTeam?.id
    }
  })

  const mutation = useUpdateRootTeamMutation()

  const handleOnSubmit = async (newRootTeam: EditRootTeamForm) => {
    await mutation
      .mutateAsync({
        updateRootTeamCommand: {
          teamId: teamId || '',
          rootTeamId: newRootTeam.rootTeamId || ''
        }
      })
      .then(() => {
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editrootteam.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="rootTeamId"
          label={t('form.field.teams')}
          options={teams.map(x => new FormSelectOption(x.id, `${x.name} (${x.code})`))}
        />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditRootTeam
