import { SelfService } from '@epix-web-apps/ui'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { Box, Link, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface EmployerFolderComponentProps {
  employerId: string
  name: string
  documentTypeId?: string
  underline?: boolean
}

interface ContractFolderComponentProps {
  contractId: string
  name: string
  documentTypeId?: string
  underline?: boolean
}

interface FolderInfoComponentProps {
  name: string
  underline?: boolean
}

/* eslint-disable-next-line */
interface FolderComponentProps {
  contractId?: string
  employerId?: string
  name: string
  documentTypeId?: string
  underline?: boolean
}

function FolderComponent({
  contractId,
  employerId,
  name,
  documentTypeId,
  underline = true,
  ...props
}: FolderComponentProps) {
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Box
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.secondary.main
      }}
    >
      <Box mr={2}>
        <FolderOutlinedIcon fontSize="large" />
      </Box>
      {documentTypeId ? (
        <Link
          color={theme.palette.secondary.main}
          underline={underline ? 'always' : 'none'}
          onClick={() => {
            if (employerId) {
              navigate(SelfService.DOCUMENTS_EMPLOYERID_DOCUMENTTYPEID(employerId, documentTypeId))
            } else if (contractId) {
              navigate(SelfService.DOCUMENTS_CONTRACTID_DOCUMENTTYPEID(contractId, documentTypeId))
            }
          }}
        >
          <Typography>{name}</Typography>
        </Link>
      ) : (
        <Typography>{name}</Typography>
      )}
    </Box>
  )
}

export function EmployerFolderComponent(props: EmployerFolderComponentProps) {
  return <FolderComponent {...props} />
}

export function ContractFolderComponent(props: ContractFolderComponentProps) {
  return <FolderComponent {...props} />
}

export function FolderInfoComponent(props: FolderInfoComponentProps) {
  return <FolderComponent {...props} />
}
