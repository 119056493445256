import { OrderDirection, useSuspenseGetBranchUnitsByEmployerIdQuery } from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined'
import { Box, IconButton, Link } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { environment } from '../../../../../environments/environment'
import AddEditBranchUnit from '../../branch-unit-components/add-edit-branch-unit'
import { AddBranchUnit, EditBranchUnit } from '../../branch-unit-components/add-edit-branch-unit/add-edit-branch-unit'
import { ReadMoreText, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import EmployersWidgetTitle from '../employers-widget-title'

/* eslint-disable-next-line */
export interface EmployersWidgetBranchunitsProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetBranchunits({ employerId, setTabComplete }: EmployersWidgetBranchunitsProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()

  const { data: branchUnits, refetch: refetchBranchUnits } = useSuspenseGetBranchUnitsByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (branchUnits?.branchUnits) {
      setTabComplete(branchUnits.branchUnits.data.length > 0)
    }
  }, [branchUnits?.branchUnits])

  return (
    branchUnits?.branchUnits && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.branchunits.title')}
          url={`${employerId}/branch-unit`}
          icon={<MapsHomeWorkOutlinedIcon />}
          hasData={branchUnits?.branchUnits.data.length > 0}
        />
        {branchUnits?.branchUnits.data?.slice(0, 3).map(branchUnit => {
          return (
            <WidgetRowItem key={branchUnit.id}>
              <TypographySmall>
                {branchUnit.description} ({branchUnit.code})
              </TypographySmall>
              <IconButton
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  openFlyIn({
                    content: <EditBranchUnit employerId={employerId} branchUnitId={branchUnit.id} />,
                    callbackAfterClose: () => refetchBranchUnits
                  })
                }
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </WidgetRowItem>
          )
        })}

        <Box>
          {branchUnits?.branchUnits.data.length > 3 && (
            <Link
              onClick={() =>
                navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_BRANCHUNIT(`${params.id}`, employerId), {
                  state: { employerId: employerId }
                })
              }
            >
              <TypographySmall>
                {t('employerspage.widget.branchunits.and')} {branchUnits?.branchUnits.data.length - 3}{' '}
                {t('employerspage.widget.branchunits.more')}
              </TypographySmall>
            </Link>
          )}
          {branchUnits?.branchUnits.data.length < 4 && (
            <Link
              onClick={() =>
                openFlyIn({
                  content: <AddBranchUnit employerId={employerId} />,
                  callbackAfterClose: () => refetchBranchUnits
                })
              }
            >
              <TypographySmall>{t('employerspage.widget.branchunits.addbranchunit')}</TypographySmall>
            </Link>
          )}
        </Box>

        <ReadMoreText
          text={branchUnits.branchUnits.data.length === 0 ? t('employerspage.widget.branchunits.info') : ''}
          readMoreLink={environment.docs.domainName + '/Epix/epix-configuration/provider/employer/branchunits'}
          alignRight={branchUnits.branchUnits.data.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetBranchunits
