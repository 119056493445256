import EmployersWidgetTitle from '../employers-widget-title'
import { ReadMoreText, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Configuration, DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import DataObjectIcon from '@mui/icons-material/DataObject'
import {
  OrderDirection,
  useSuspenseGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery
} from '@epix-web-apps/core'
import { environment } from '../../../../../environments/environment'
import { Box, IconButton, Link } from '@mui/material'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'

/* eslint-disable-next-line */
export interface EmployersWidgetSmartPayComponentsProps {
  employerId: string
}

export function EmployersWidgetSmartPayComponents({ employerId }: EmployersWidgetSmartPayComponentsProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<DetailPageBaseQueryParams>()

  const { data: payrollCodes } = useSuspenseGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: 0,
    payrollCodeFilterModel: { showCalendarCodes: true },
    orderDirection: OrderDirection.Asc
  })

  const { data: smartPaycomponentsData } = useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: 3,
    orderDirection: OrderDirection.Asc
  })

  // TODO 2722 should this be disabled only based on payrollcodes?
  const disabled = (payrollCodes?.allPayrollCodesByEmployerId.totalCount ?? 0) === 0

  return (
    payrollCodes?.allPayrollCodesByEmployerId &&
    smartPaycomponentsData?.pagedSmartPayComponentsByEmployerId && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.smartpaycomponents.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(`${params.id}`, `${employerId}`)}
          icon={<DataObjectIcon />}
          hasData={smartPaycomponentsData.pagedSmartPayComponentsByEmployerId.totalCount > 0}
          disabled={disabled}
        />

        {smartPaycomponentsData?.pagedSmartPayComponentsByEmployerId.data.map(smartPayComponent => (
          <WidgetRowItem key={smartPayComponent.id}>
            <TypographySmall>
              {smartPayComponent.description} ({smartPayComponent.code})
            </TypographySmall>
            <Box>
              <IconButton
                sx={{ width: '1.5rem', height: '1.5rem' }}
                onClick={() =>
                  navigate(
                    Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID(
                      `${params.id}`,
                      employerId,
                      smartPayComponent.id
                    )
                  )
                }
              >
                <KeyboardArrowRightOutlinedIcon fontSize="small" />
              </IconButton>
            </Box>
          </WidgetRowItem>
        ))}

        <ReadMoreText
          text={
            smartPaycomponentsData?.pagedSmartPayComponentsByEmployerId.totalCount === 0 ? (
              <Trans
                i18nKey="employerspage.widget.smartpaycomponents.info"
                components={{
                  tt: (
                    <Link
                      onClick={() =>
                        navigate(
                          Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(`${params.id}`, `${employerId}`)
                        )
                      }
                    />
                  )
                }}
              />
            ) : (
              ''
            )
          }
          readMoreLink={environment.docs.domainName + '/Epix/epix-configuration/provider/employer/smartpaycomponents'}
          alignRight={smartPaycomponentsData?.pagedSmartPayComponentsByEmployerId.totalCount > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetSmartPayComponents
