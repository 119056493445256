import {
  useDeleteContractSmartPayComponentHistoryMutation,
  useSuspenseGetContractSmartHistoryByIdQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, useFlyIn } from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface RemoveContractSmartPayComponentHistoryProps {
  contractId: string
  contractSmartPayComponentHistoryId: string
}

export function RemoveContractSmartPayComponentHistory({
  contractId,
  contractSmartPayComponentHistoryId
}: RemoveContractSmartPayComponentHistoryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const form = useForm()

  const { data: getContractSmartPayComponentHistoryById } = useSuspenseGetContractSmartHistoryByIdQuery({
    contractId: contractId,
    contractSmartPayComponentHistoryId: contractSmartPayComponentHistoryId
  })

  const deleteMutation = useDeleteContractSmartPayComponentHistoryMutation()

  const handleOnSubmit = () => {
    deleteMutation.mutateAsync({
      command: {
        contractId: contractId,
        contractSmartPayComponentHistoryId: contractSmartPayComponentHistoryId
      }
    }).then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.remove-contractsmartpaycomponent.title')}</Typography>
      <Typography variant="h6">{`${getContractSmartPayComponentHistoryById?.contractSmartHistoryById.smartPayComponent.description} {${getContractSmartPayComponentHistoryById?.contractSmartHistoryById.smartPayComponent.code}}`}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginBottom: 2}}>
        <Typography variant="description">{t('flyin.remove-contractsmartpaycomponent.explanation1')}</Typography>
        <Typography variant="description">{t('flyin.remove-contractsmartpaycomponent.explanation2')}</Typography>
      </Box>
      <FormActionButtons isMutating={deleteMutation.isPending} buttonText={t('common.remove')} onCancel={closeFlyIn} />
    </FormContainer>
  )
}

export default RemoveContractSmartPayComponentHistory
