import {
  useGetBranchUnitsByEmployerIdQuery,
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetIcpByIdQuery
} from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, IconButton, MenuItem, Switch, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AddBranchUnit, EditBranchUnit } from '../../components/branch-unit-components/add-edit-branch-unit/add-edit-branch-unit'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import FilterButton from '../../components/filter-button/filter-button'

/* eslint-disable-next-line */
export interface BranchUnitPageProps {}

export type BranchUnitDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function BranchUnitPage(props: BranchUnitPageProps) {
  const params = useParams<BranchUnitDetailPageParams>()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const { t } = useTranslation()

  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const { openFlyIn } = useFlyIn()
  const [filters, setFilters] = useState({
    showInactive: false
  })
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [branchUnitId, setBranchUnitId] = useState('')

  const employerId = params.employerid!
  const icpId = params.id!

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useSuspenseGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCountries } = useSuspenseGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setBranchUnitId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('branchunitpage.title')}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FilterButton filters={filters}>
            <p>
              <Switch
                checked={filters.showInactive}
                onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
                aria-label={t('peoplepage.filters.showinactivepeople')}
              />
              {t('employerpage.filters.showinactiveemployers')}
            </p>
          </FilterButton>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddBranchUnit employerId={employerId} />,
                callbackAfterClose: () => refetchBranchUnit
              })
            }
          >
            <AddIcon />
            {t('branchunitpage.button.branchunit')}
          </Button>
        </Box>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {country} - {payrollProvider} - {company}
      </Typography>
    </Box>
  )

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />}</DataTableCell>
  }

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('branchunitpage.datatable.column.code'),
      flex: 1,
      sortable: false
    },
    {
      field: 'description',
      headerName: t('branchunitpage.datatable.column.description'),
      flex: 2,
      sortable: true
    },
    {
      field: 'street',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.street'),
      flex: 2
    },
    {
      field: 'number',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.number'),
      flex: 1
    },
    {
      field: 'suffix',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.suffix'),
      flex: 1
    },
    {
      field: 'zipCode',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.zipcode'),
      flex: 1
    },
    {
      field: 'city',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.city'),
      flex: 1
    },
    {
      field: 'country',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.country'),
      flex: 1
    },
    {
      field: 'branchUnitNumber',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.branch-unit-number'),
      flex: 1
    },
    {
      field: 'active',
      sortable: false,
      headerName: t('branchunitpage.datatable.column.active'),
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel('description'))

  const {
    data: branchUnits,
    isLoading: isLoadingBranchUnits,
    refetch: refetchBranchUnit
  } = useGetBranchUnitsByEmployerIdQuery({
    employerId: employerId || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    orderDirection: sortModel.orderDirection,
    showInactive: filters.showInactive
  })

  useEffect(() => {
    if (branchUnits && branchUnits.branchUnits) {
      const branchUnitsData = branchUnits.branchUnits
      setRowCount(branchUnitsData.totalCount)
      setTableData(
        branchUnitsData.data.map(row => {
          return {
            id: row.id,
            code: row.code,
            employerId: row.employerId,
            description: row.description,
            street: row.street,
            number: row.number,
            zipCode: row.zipCode,
            city: row.city,
            country: row.country.name,
            suffix: row.suffix,
            branchUnitNumber: row.branchUnitNumber,
            active: row.active
          }
        })
      )
    }
  }, [branchUnits])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoadingBranchUnits}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditBranchUnit branchUnitId={branchUnitId} employerId={employerId} />,
              callbackAfterClose: () => refetchBranchUnit
            })
          }
        >
          {t('branchunits.list.row.menu.edit-branchunit')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default BranchUnitPage
