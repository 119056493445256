import {
  GetShiftCodeByIdQuery,
  useCreateShiftCodeMutation,
  useSuspenseGetShiftCodeByIdQuery,
  useUpdateShiftCodeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

export interface AddShiftCodeProps {
  employerId: string
}

export interface EditShiftCodeProps {
  employerId: string
  shiftCodeId: string
}

export function AddShiftCode({ employerId }: AddShiftCodeProps) {
  return AddEditShiftCode(employerId)
}

export function EditShiftCode({ employerId, shiftCodeId }: EditShiftCodeProps) {
  const getShiftCodeByIdQuery = useSuspenseGetShiftCodeByIdQuery({
    shiftCodeId: shiftCodeId
  })

  return AddEditShiftCode(employerId, getShiftCodeByIdQuery)
}

function AddEditShiftCode(
  employerId: string,
  getShiftCodeByIdQuery?: UseSuspenseQueryResult<GetShiftCodeByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditShiftCodeSchema = object({
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }),
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    })
  })

  const getShiftCodeById = getShiftCodeByIdQuery?.data

  type AddEditShiftCodeForm = TypeOf<typeof addEditShiftCodeSchema>

  const defaultFormValues = useMemo(
    () =>
      ({
        code: getShiftCodeById?.shiftCodeById?.code || undefined,
        description: getShiftCodeById?.shiftCodeById?.description || undefined
      } as AddEditShiftCodeForm),
    [getShiftCodeById?.shiftCodeById]
  )

  const form = useForm<AddEditShiftCodeForm>({
    resolver: zodResolver(addEditShiftCodeSchema),
    defaultValues: defaultFormValues
  })

  const createMutation = useCreateShiftCodeMutation()
  const updateMutation = useUpdateShiftCodeMutation()

  const handleOnSubmit = async (newShiftCode: AddEditShiftCodeForm) => {
    if (!getShiftCodeById) {
      await createMutation
        .mutateAsync({
          createShiftCodeCommand: {
            code: newShiftCode.code,
            description: newShiftCode.description,
            employerId: employerId
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateShiftCodeCommand: {
            code: newShiftCode.code,
            description: newShiftCode.description,
            employerId: employerId,
            shiftCodeId: getShiftCodeById.shiftCodeById.id
          }
        })
        .then(() => {
          getShiftCodeByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.shiftcode.title')}</Typography>
      <FormGridLayout>
        <FormInput sx={12} name="code" label={t('form.field.code')} />
        <FormInput sx={12} name="description" label={t('form.field.description')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditShiftCode
