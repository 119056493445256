import {
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetSecurexContractDetailByContractIdQuery,
  useUpdateSecurexTaxesMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface EditSecurexTaxesProps {
  contractId: string
}

export function EditSecurexTaxes({ contractId }: EditSecurexTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: securexContractDetail, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetSecurexContractDetailByContractIdQuery({
      contractId: contractId
    })

  const { data: taxTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.LU_TAX.key,
    limit: -1,
    offset: 0
  })

  const { data: taxGroupTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.LU_TAX_GROUP.key,
    limit: -1,
    offset: 0
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const updateMutation = useUpdateSecurexTaxesMutation()

  const taxTypes = taxTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    t => new FormSelectOption(t.id, t.value)
  )

  const taxGroupTypes = taxGroupTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    t => new FormSelectOption(t.id, t.value)
  )

  const editSecurexTaxesSchema = object({
    isSubjectedToTaxes: boolean().nullable(),
    taxTypeId: string().nullable().optional(),
    taxGroupTypeId: string().nullable().optional(),
    taxCardValidFrom: date().optional().nullable()
  })

  type EditSecurexTaxesForm = TypeOf<typeof editSecurexTaxesSchema>

  const form = useForm<EditSecurexTaxesForm>({
    resolver: zodResolver(editSecurexTaxesSchema),
    defaultValues: {
      isSubjectedToTaxes: securexContractDetail?.securexContractDetailByContractId.isSubjectedToTaxes,
      taxTypeId: securexContractDetail?.securexContractDetailByContractId.taxType?.id,
      taxGroupTypeId: securexContractDetail?.securexContractDetailByContractId.taxGroupType?.id,
      taxCardValidFrom: securexContractDetail?.securexContractDetailByContractId.taxCardValidFrom
        ? parseISO(securexContractDetail.securexContractDetailByContractId.taxCardValidFrom)
        : null
    }
  })

  const handleOnSubmit = (securexTaxes: EditSecurexTaxesForm) => {
    updateMutation
      .mutateAsync({
        updateSecurexTaxesCommand: {
          id: securexContractDetail?.securexContractDetailByContractId.id || '',
          isSubjectedToTaxes: securexTaxes.isSubjectedToTaxes,
          taxTypeId: securexTaxes.taxTypeId,
          taxGroupTypeId: securexTaxes.taxGroupTypeId,
          taxCardValidFrom: securexTaxes.taxCardValidFrom
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editsecurextaxes.title')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="isSubjectedToTaxes" label={t('form.field.securex.issubjectedtotaxes')} />
        <FormSelect sx={12} name="taxTypeId" label={t('form.field.securex.taxtype')} options={taxTypes} />
        <FormSelect sx={12} name="taxGroupTypeId" label={t('form.field.securex.taxgroup')} options={taxGroupTypes} />
        <FormDatepicker sx={12} name="taxCardValidFrom" label={t('form.field.securex.taxcardvalidfrom')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isPending} />
    </FormContainer>
  )
}

export default EditSecurexTaxes
