import {
  GraphqlError,
  ImportConverterParametersInput,
  ImportConverterWithParametersModel,
  useAddConverterToImportProcessMutation,
  useRequiredParams,
  useSuspenseGetImportConvertersForImportProcessQuery,
  useSuspenseGetImportProcessByIdQuery
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  FormRadioOption
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesomeOutlined'
import { Box, Grid, Link, Typography, useTheme } from '@mui/material'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'
import { useImportDataStore } from '../../../../stores/import-data-store'
import { ImportMultiStepFormRef } from '../../import-multi-step-form-ref'
import { ImportSummary } from '../../import-summary'

export const SelectImportFormatStep = forwardRef<ImportMultiStepFormRef, unknown>((_, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const setIsProcessingForm = useImportDataStore(state => state.setIsProcessingForm)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: importProcessQuery, refetch: refetchImportProcess } = useSuspenseGetImportProcessByIdQuery({
    importProcessId: params.id
  })
  const { data: converters } = useSuspenseGetImportConvertersForImportProcessQuery({ importProcessId: params.id })

  const { mutateAsync } = useAddConverterToImportProcessMutation()

  const importFormatSchema = object({
    importFormat: string({
      required_error: t('form.validation.importformatrequired')
    }),
    payrollPeriodStart: date().optional(),
    year: date().optional(),
    employerNumber: string().optional()
  })
    .refine(data => {
      if (data.importFormat === '') return true
      const converter = converters.importConvertersForImportProcessQuery.find(
        converter => converter.key === data.importFormat
      )
      if (!converter) return true
      if (!converter.additionalParameters.showPayrollPeriodStart) return true
      return data.payrollPeriodStart != null
    }, t('form.validation.payrollperiodstartrequired'))
    .refine(data => {
      if (data.importFormat === '') return true
      const converter = converters.importConvertersForImportProcessQuery.find(
        converter => converter.key === data.importFormat
      )
      if (!converter) return true
      if (!converter.additionalParameters.showYear) return true
      return data.year != null
    }, t('form.validation.yearrequired'))
    .refine(data => {
      if (data.importFormat === '') return true
      const converter = converters.importConvertersForImportProcessQuery.find(
        converter => converter.key === data.importFormat
      )
      if (!converter) return true
      if (!converter.additionalParameters.showEmployerNumber) return true
      return data.employerNumber != null && data.employerNumber.trim() !== ''
    }, t('form.validation.employernumberrequired'))

  type ImportFormat = TypeOf<typeof importFormatSchema>
  const form = useForm<ImportFormat>({
    resolver: zodResolver(importFormatSchema),
    defaultValues: {
      importFormat: ''
    }
  })

  const watchedImportFormat = useWatch({
    control: form.control,
    name: 'importFormat'
  })

  useEffect(() => {
    form.clearErrors()
    setBackendErrors([])
  }, [watchedImportFormat])

  async function handleOnSubmit(data: ImportFormat) {
    setIsProcessingForm(true)
    setBackendErrors([])

    const converterParameters: ImportConverterParametersInput = {
      employerNumber: selectedConverter?.additionalParameters.showEmployerNumber ? data.employerNumber : undefined,
      payrollPeriodStart: selectedConverter?.additionalParameters.showPayrollPeriodStart
        ? data.payrollPeriodStart
        : undefined,
      year: selectedConverter?.additionalParameters.showYear ? data.year?.getFullYear() : undefined
    }

    try {
      await mutateAsync({
        addConverterToImportProcessCommand: {
          importProcessId: params.id,
          converterKey: data.importFormat,
          converterParameters: converterParameters
        }
      })
    } catch (e) {
      if (e instanceof GraphqlError) {
        setBackendErrors([e])
        return
      }
      throw e
    }

    await refetchImportProcess()
  }

  useImperativeHandle(ref, () => {
    return {
      handleNext: () =>
        form
          .handleSubmit(handleOnSubmit)()
          .finally(() => setIsProcessingForm(false))
    }
  })

  const options: FormRadioOption[] = [
    new FormRadioOption('', t('configurationimport.common.standardimportformat')),
    ...converters.importConvertersForImportProcessQuery.map(
      converter => new FormRadioOption(converter.key, converter.name)
    )
  ]

  const selectedImportFormat = useWatch({ control: form.control, name: 'importFormat' })

  const [selectedConverter, setSelectedConverter] = useState<ImportConverterWithParametersModel | undefined>(undefined)

  useEffect(() => {
    setSelectedConverter(
      converters.importConvertersForImportProcessQuery.find(converter => converter.key === selectedImportFormat)
    )
  }, [selectedImportFormat])

  return (
    <Grid container>
      <Grid item xs={6}>
        <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)} sx={{}}>
          <ImportSummary />
          <Box sx={{ mb: 2 }}>
            <Typography
              sx={{
                color: theme.palette.text.secondary
              }}
            >
              <Trans
                i18nKey="configurationimport.steps.select-converter.importtypemoreinformation"
                values={{
                  importtype: importProcessQuery.importProcessById.importJobType.value
                }}
                components={{
                  CustomLink: <Link target="_blank" rel="noreferrer" href="https://docs.myepix.io"></Link>
                }}
              />
            </Typography>
          </Box>
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AutoAwesomeIcon /> {t('configurationimport.steps.select-converter.suggestedimportformat')}:
          </Typography>
          <FormRadioGroup name="importFormat" options={options}></FormRadioGroup>

          {selectedConverter && (
            <FormGridLayout hasPaddingTop>
              {selectedConverter.additionalParameters.showPayrollPeriodStart && (
                <FormDatepicker sx={6} name="payrollPeriodStart" label={t('form.field.payrollperiodstartdate')} />
              )}
              {selectedConverter.additionalParameters.showYear && (
                <FormDatepicker sx={3} name="year" label={t('form.field.year')} views={['year']} inputFormat="yyyy" />
              )}
              {selectedConverter.additionalParameters.showEmployerNumber && (
                <FormInput sx={6} name="employerNumber" label={t('form.field.employernumber')} />
              )}
            </FormGridLayout>
          )}

          <FormErrorList customErrors={backendErrors} />
        </FormContainer>
      </Grid>
    </Grid>
  )
})
