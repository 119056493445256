import {
  FormSelectOption,
  GraphqlError,
  OrderDirection,
  PROCESSING_IMPORTJOB_TYPES,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllIcpsQuery,
  useImportCalculatedDataMutation,
  useImportCompanyCarsMutation,
  useImportContractsMutation,
  useImportManualEventsMutation,
  useImportPayComponentsMutation,
  useImportPayrollCodesDataMutation,
  useImportPeopleMutation,
  useImportPersonalCalendarDaysMutation,
  useSuspenseGetProcessingImportJobTypesQuery
} from '@epix-web-apps/core'
import {
  ACCEPTED_UPLOAD_TYPES,
  FormContainer,
  FormErrorList,
  FormFileUpload,
  FormSelect,
  Import,
  ZodFileArray
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography } from '@mui/material'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { nativeEnum, object, string, TypeOf } from 'zod'
import { useImportDataStore } from '../../../../stores/import-data-store'
import { ImportMultiStepFormRef } from '../../import-multi-step-form-ref'

export const SelectDataTypeAndFileStep = forwardRef<ImportMultiStepFormRef, unknown>((_, ref) => {
  const { t } = useTranslation()
  const setIsProcessingForm = useImportDataStore(store => store.setIsProcessingForm)
  const navigate = useNavigate()

  const importSchema = object({
    dataType: nativeEnum(PROCESSING_IMPORTJOB_TYPES, {
      required_error: t('form.validation.datatyperequired')
    }),
    employerId: string().optional(),
    files: ZodFileArray([ACCEPTED_UPLOAD_TYPES.CSV], true)
  }).refine(
    data => !(data.dataType === PROCESSING_IMPORTJOB_TYPES.PAYROLL_CODES && !data.employerId),
    t('form.validation.employerrequired')
  )

  type ImportForm = TypeOf<typeof importSchema>
  const form = useForm<ImportForm>({
    resolver: zodResolver(importSchema)
  })

  const { data: processingImportJobTypes } = useSuspenseGetProcessingImportJobTypesQuery()

  const { data: getAllIcps } = useGetAllIcpsQuery({
    activeOnly: true,
    offset: 0,
    limit: -1,
    sortByProperty: 'Code',
    orderDirection: OrderDirection.Asc
  })
  const { data: getAllEmployersByIcpIds } = useGetAllEmployersByIcpIdsQuery(
    {
      icps: getAllIcps?.icps.data.map(x => x.id),
      active: true
    },
    {
      enabled: !!getAllIcps
    }
  )

  const { mutateAsync: importCalculatedDataMutationAsync } = useImportCalculatedDataMutation()
  const { mutateAsync: importPayrollCodesDataMutationAsync } = useImportPayrollCodesDataMutation()
  const { mutateAsync: importPeopleMutationAsync } = useImportPeopleMutation()
  const { mutateAsync: importContractsMutationAsync } = useImportContractsMutation()
  const { mutateAsync: importPayComponentsMutationAsync } = useImportPayComponentsMutation()
  const { mutateAsync: importCompanyCarsMutationAsync } = useImportCompanyCarsMutation()
  const { mutateAsync: importPersonalCalendarDaysMutationAsync } = useImportPersonalCalendarDaysMutation()
  const { mutateAsync: importManualEventsAsync } = useImportManualEventsMutation()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  async function handleOnSubmit(form: ImportForm) {
    let importProcessId: string
    setIsProcessingForm(true)
    setBackendErrors([])

    if (!form.dataType) {
      return
    }

    const file = form.files[0].file

    try {
      switch (form.dataType) {
        case PROCESSING_IMPORTJOB_TYPES.CALCULATED_DATA:
          importProcessId = (
            await importCalculatedDataMutationAsync({
              file: file
            })
          ).importCalculatedData
          break
        case PROCESSING_IMPORTJOB_TYPES.PAYROLL_CODES:
          if (!form.employerId) return

          importProcessId = (
            await importPayrollCodesDataMutationAsync({
              file: file,
              employerId: form.employerId
            })
          ).importPayrollCodesData
          break
        case PROCESSING_IMPORTJOB_TYPES.PEOPLE:
          importProcessId = (
            await importPeopleMutationAsync({
              file: file
            })
          ).importPeople
          break
        case PROCESSING_IMPORTJOB_TYPES.CONTRACTS:
          importProcessId = (
            await importContractsMutationAsync({
              file: file
            })
          ).importContracts
          break
        case PROCESSING_IMPORTJOB_TYPES.PAYCOMPONENTS:
          importProcessId = (
            await importPayComponentsMutationAsync({
              file: file
            })
          ).importPayComponents
          break
        case PROCESSING_IMPORTJOB_TYPES.COMPANY_CARS:
          importProcessId = (
            await importCompanyCarsMutationAsync({
              file: file
            })
          ).importCompanyCars
          break
        case PROCESSING_IMPORTJOB_TYPES.PERSONAL_CALENDAR_DAYS:
          importProcessId = (
            await importPersonalCalendarDaysMutationAsync({
              file: file
            })
          ).importPersonalCalendarDays
          break
        case PROCESSING_IMPORTJOB_TYPES.MANUAL_EVENTS:
          importProcessId = (
            await importManualEventsAsync({
              file: file
            })
          ).importManualEvents
          break

        default:
          // if a type is not specified, we shouldn't continue
          return
      }
    } catch (e) {
      if (e instanceof GraphqlError) {
        setBackendErrors([e])
        return
      }
      throw e
    }

    navigate(Import.DATA_ID(importProcessId))
  }

  const dataTypeOptions = processingImportJobTypes.allProcessingImportJobTypes.map(
    importJobType => new FormSelectOption(importJobType.key, importJobType.value)
  )

  const employerOptions = getAllEmployersByIcpIds?.employersByIcpIds.map(
    employer => new FormSelectOption(employer.id, `${employer.companyName} (${employer.icpCode} - ${employer.number})`)
  )

  const watchedDatatype = useWatch({ control: form.control, name: 'dataType' })

  useImperativeHandle(ref, () => {
    return {
      handleNext: () =>
        form
          .handleSubmit(handleOnSubmit)()
          .finally(() => setIsProcessingForm(false))
    }
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)} sx={{}}>
      <Typography>{t('configurationimport.steps.select-data-type-step.description')}</Typography>
      <Box sx={{ mb: 2 }}>
        <Grid container paddingY={1}>
          <Grid item md={6} sm={12} lg={4}>
            <FormSelect name="dataType" label={t('form.field.datatype')} options={dataTypeOptions} />
          </Grid>
        </Grid>
        {watchedDatatype === PROCESSING_IMPORTJOB_TYPES.PAYROLL_CODES && (
          <Grid container paddingY={1}>
            <Grid item md={6} sm={12} lg={4}>
              <FormSelect name="employerId" label={t('form.field.employer')} options={employerOptions} />
            </Grid>
          </Grid>
        )}

        <Box sx={{ mt: 1 }}>
          <FormFileUpload
            name="files"
            accept={[ACCEPTED_UPLOAD_TYPES.CSV]}
            buttonText={t('configurationimport.importbtn')}
            multipleFiles={false}
          />
        </Box>
      </Box>
      <FormErrorList customErrors={backendErrors} />
    </FormContainer>
  )
})
