import {
  useRunReportDefinitionMutation,
  useSuspenseGetReportDefinitionByIdQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormGridLayout, FormInput, routes, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

interface RunReportFlyinProps {
  reportId: string
}

export function RunReportFlyin({ reportId }: RunReportFlyinProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { closeFlyIn } = useFlyIn()

  const { data: reportDefinitionData } = useSuspenseGetReportDefinitionByIdQuery({ id: reportId })
  const reportRunSchema = z.object({
    name: z.string()
  })

  type ReportRunForm = z.TypeOf<typeof reportRunSchema>

  const form = useForm<ReportRunForm>({
    resolver: zodResolver(reportRunSchema),
    defaultValues: {
      name: reportDefinitionData.reportDefinitionById.name
    }
  })

  const runreportMutation = useRunReportDefinitionMutation()

  function handleOnSubmit(form: ReportRunForm) {
    runreportMutation
      .mutateAsync({
        runReportDefinitionCommand: {
          reportDefinitionId: reportId,
          reportRunName: form.name
        }
      })
      .then(_ => {
        navigate(routes.analytics.REPORTING_RUNS)
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="h4">{t('reportinganalyticspage.flyin.runreport')}</Typography>
        <Typography variant="h4" sx={{ color: theme => theme.palette.text.secondary }}>
          {reportDefinitionData.reportDefinitionById.name}
        </Typography>
      </Box>

      <FormGridLayout>
        <FormInput name="name" label={t('reportinganalyticspage.flyin.reportrunname')} sx={12} />
      </FormGridLayout>

      <FormActionButtons
        isMutating={runreportMutation.isPending}
        buttonText={t('reportinganalyticspage.flyin.run')}
        onCancel={closeFlyIn}
      />
    </FormContainer>
  )
}
