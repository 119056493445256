
import { useSuspenseGetUserByIdQuery, useRequiredParams } from '@epix-web-apps/core'
import {
  Configuration,
  DetailPageBaseQueryParams,
  DetailPageContent,
  DetailPageContentHeader,
  DetailPageLayout,
  HeaderTitleNavigation,
} from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UserTabGeneral from '../../components/user-components/user-tab-general/user-tab-general'

/* eslint-disable-next-line */
export interface UserDetailPageProps {}

export function UserDetailPage(props: UserDetailPageProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: getUserById } = useSuspenseGetUserByIdQuery({
    userId: params.id
  })

  return (
    <DetailPageLayout>
      <DetailPageContent>
        <DetailPageContentHeader>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <HeaderTitleNavigation backToLink={Configuration.USERS()} title={`${t('userdetailpage.title')}`} />
          </Box>
          <Typography color={'gray'} variant="h4" paddingLeft={4} paddingTop={1}>
            {getUserById?.userById.email}
          </Typography>
        </DetailPageContentHeader>

        <UserTabGeneral userId={params.id} />
      </DetailPageContent>
    </DetailPageLayout>
  )
}

export default UserDetailPage
