import {
  DEFAULT_LOCALE,
  OrderDirection,
  useGetPersonResidentialAddressCorrectionsByProcessIdQuery,
  useRequiredParams,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import {} from '@epix-web-apps/ui'
import DoneIcon from '@mui/icons-material/Done'
import { useTheme } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, PaginationModel, SortModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'
import { minWidthPayrollClosure } from '../steps'

/* eslint-disable-next-line */
export interface ResidentialAddressCorrectionsComponentProps {}

export function ResidentialAddressCorrectionsComponent(props: ResidentialAddressCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const paginationModel = new PaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: params.id
  })

  const { data: personResidentialAddressCorrections, isLoading } =
    useGetPersonResidentialAddressCorrectionsByProcessIdQuery({
      processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
      offset: paginationModel.offset,
      limit: paginationModel.pageSize
    })

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeName',
      headerName: t('personcontractdata.datatable.column.employeename'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('personcontractdata.datatable.column.startdate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'endDate',
      headerName: t('personcontractdata.datatable.column.enddate'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'country',
      headerName: t('personcontractdata.datatable.column.country'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryCode',
      headerName: t('personcontractdata.datatable.column.countrycode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'street',
      headerName: t('personcontractdata.datatable.column.street'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'houseNumber',
      headerName: t('personcontractdata.datatable.column.housenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'houseNumberSuffix',
      headerName: t('personcontractdata.datatable.column.housenumbersuffix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'zipCode',
      headerName: t('personcontractdata.datatable.column.zipcode'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'city',
      headerName: t('personcontractdata.datatable.column.city'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'county',
      headerName: t('personcontractdata.datatable.column.county'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  const [sortModel, _] = useState(new SortModel(columns?.[1]?.field, OrderDirection.Asc))

  useEffect(() => {
    if (
      personResidentialAddressCorrections &&
      personResidentialAddressCorrections?.personResidentialAddressCorrectionsByProcessId
    ) {
      const personOfficialAddressCorrectionsData =
        personResidentialAddressCorrections.personResidentialAddressCorrectionsByProcessId
      setTableData(
        personOfficialAddressCorrectionsData.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employerNumber: row.employerNumber,
            employeeNumber: row.employeeNumber,
            employeeName: row.employeeName,
            startDate: parseISO(row.startDate).toLocaleDateString(DEFAULT_LOCALE),
            endDate: row.endDate ? parseISO(row.endDate).toLocaleDateString(DEFAULT_LOCALE) : null,
            country: row.country?.name,
            countryCode: row.country?.code,
            street: row.street,
            houseNumber: row.houseNumber,
            houseNumberSuffix: row.houseNumberSuffix,
            zipCode: row.zipCode,
            city: row.city,
            county: row.county
          }
        })
      )
      setRowCount(personOfficialAddressCorrectionsData.totalCount)
    }
  }, [personResidentialAddressCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoading}
        sortModel={sortModel}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default ResidentialAddressCorrectionsComponent
