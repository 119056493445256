import {
  byteArrayToFile,
  ImportProcessStatus,
  useExportImportProcessErrorsByImportProcessIdQuery,
  useRequiredParams,
  useSuspenseGetImportProcessByIdQuery
} from '@epix-web-apps/core'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import { CancelOutlined } from '@mui/icons-material'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportSummary } from '../../import-summary'

function ImportingStatus() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" sx={{ color: theme.palette.primary.main }}>
      <CircularProgress size={25} />
      <Typography sx={{ marginLeft: 1, fontSize: '1.5rem' }}>
        {t('configurationimport.steps.finish.status.importing')}
      </Typography>
    </Box>
  )
}

function ImportFailedStatus() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" sx={{ color: theme.palette.error.main }}>
      <CancelOutlined />
      <Typography sx={{ marginLeft: 1, fontSize: '1.5rem' }}>
        {t('configurationimport.steps.finish.status.importingFailed')}
      </Typography>
    </Box>
  )
}

function ImportedStatus() {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center" sx={{ color: theme.palette.secondary.main }}>
      <CheckCircleOutlineOutlinedIcon />
      <Typography sx={{ marginLeft: 1, fontSize: '1.5rem' }}>
        {t('configurationimport.steps.finish.status.imported')}
      </Typography>
    </Box>
  )
}

export function ImportFinishStep() {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const [pollForChanges, setPollForChanges] = useState(false)
  const { data: importProcessQuery } = useSuspenseGetImportProcessByIdQuery(
    {
      importProcessId: params.id
    },
    {
      refetchInterval: pollForChanges ? 2000 : false
    }
  )

  const importProcess = importProcessQuery.importProcessById

  useEffect(() => {
    setPollForChanges(importProcess.status === ImportProcessStatus.Importing)
  }, [importProcess.status])

  const { refetch: exportRefetch, isFetching: isFetchingDownload } = useExportImportProcessErrorsByImportProcessIdQuery(
    {
      importProcessId: importProcess?.id ?? ''
    },
    { enabled: false }
  )

  function handleDownloadClick() {
    exportRefetch().then(r => {
      byteArrayToFile(
        r.data?.exportImportProcessErrorsByImportProcessId as unknown as Uint8Array,
        'importjoberrors.csv'
      )
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <ImportSummary />

        <Box>
          {importProcess?.status === ImportProcessStatus.Importing && <ImportingStatus />}
          {importProcess?.status === ImportProcessStatus.ImportFailed && <ImportFailedStatus />}
          {importProcess?.status === ImportProcessStatus.Imported && <ImportedStatus />}
        </Box>
      </Grid>

      {importProcess?.status === ImportProcessStatus.ImportFailed && (
        <Grid item md={6}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" color={'gray'}>
              {t('configurationimport.common.errors.title')}
            </Typography>

            <IconButton onClick={handleDownloadClick} aria-label="row details" aria-controls="details-row">
              {isFetchingDownload ? <CircularProgress size={25} /> : <CloudDownloadOutlinedIcon />}
            </IconButton>
          </Box>
          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
              <TableRow>
                <TableCell>{t('configurationimport.common.errors.table.header')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {importProcess.errors?.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>{error.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  )
}
