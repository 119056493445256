import { useNavigateWithParams, useRequiredParams, useSuspenseGetDriversQuery } from '@epix-web-apps/core'
import { People } from '@epix-web-apps/ui'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyCarDetailPageParams } from '../../../pages/company-car-detail-page/company-car-detail-page'
import { ContractTabs } from '../../contract-details-components/contract-tab-general/contract-tab-general'
import { PaginationModel, ScrollableDataTable, SortModel } from '../../data-table'
import DataTableEmpty from '../../data-table/data-table-empty'

/* eslint-disable-next-line */
export interface CompanyCarDriversProps {}

export function CompanyCarDrivers(props: CompanyCarDriversProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<CompanyCarDetailPageParams>()
  const [rowCount, setRowCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const navigate = useNavigateWithParams()

  const columns: GridColDef[] = [
    {
      field: 'person',
      headerName: t('driverspage.datatable.column.person'),
      flex: 1
    },
    {
      field: 'employer',
      headerName: t('driverspage.datatable.column.employer'),
      flex: 1
    },
    {
      field: 'startDate',
      headerName: t('driverspage.datatable.column.from'),
      flex: 1
    },
    {
      field: 'endDate',
      headerName: t('driverspage.datatable.column.until'),
      flex: 1
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: driversData, isLoading: isLoadingDrivers } = useSuspenseGetDriversQuery({
    companyCarId: params.id,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (driversData && driversData.drivers) {
      const drivers = driversData.drivers
      setRowCount(drivers.totalCount)
      setTableData(
        drivers.data.map(row => {
          return {
            id: row.id,
            personId: row.personId,
            contractId: row.contractId,
            person: row.personPreferredName,
            employer: row.contractEmployer,
            startDate: parseISO(row.startDateCompanyCarHistory).toLocaleDateString(),
            endDate: row.endDateCompanyCarHistory ? parseISO(row.endDateCompanyCarHistory).toLocaleDateString() : null,
            userHasAccessToContract: row.userHasAccessToContract
          }
        })
      )
    }
  }, [driversData])

  const goToContractDetailsPage = (personId: string, contractId: string) => {
    navigate(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
      tabIndex: ContractTabs.OVERVIEW.toString()
    })
  }

  return (
    <ScrollableDataTable
      data={tableData}
      columns={columns}
      totalRowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      onSortChange={setSortModel}
      onRowClick={params => {
        if (params.row.userHasAccessToContract) {
          goToContractDetailsPage(params.row.personId, params.row.contractId)
        }
      }}
      sortModel={sortModel}
      isLoading={isLoadingDrivers}
      emptyStateElement={<DataTableEmpty title={t('emptystate.drivers')} />}
    />
  )
}

export default CompanyCarDrivers
