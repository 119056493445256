import {
  FormSelectOption,
  GraphqlError,
  OrderDirection,
  ToBackendFormatedDate,
  formTypeSelectOptions,
  numericString,
  useCreateCompanyCarHistoryMutation,
  useDeleteCompanyCarHistoryMutation,
  useGetCompanyCarsQuery,
  useGetContractByIdQuery,
  useRequiredParams,
  useSuspenseGetCompanyCarHistoriesByContractIdQuery,
  useUpdateCompanyCarHistoryMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, date, number, object, string } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'

/* eslint-disable-next-line */
export interface EditContractMobilityProps {}

export function EditContractMobility(props: EditContractMobilityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const params = useRequiredParams<ContractDetailPageParams>()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editContractMobilitySchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    companyCarId: string({
      required_error: t('form.validation.companycarrequired'),
      invalid_type_error: t('form.validation.companycarrequired')
    }),
    carTypeKey: string({
      required_error: t('form.validation.cartyperequired'),
      invalid_type_error: t('form.validation.cartyperequired')
    }),
    notes: string().nullable(),
    carBrandKey: string().optional(),
    model: string().optional(),
    registrationDate: string().optional(),
    companyCarStartDate: string().optional(),
    companyCarEndDate: string().optional(),
    motorType: string().optional(),
    co2: string().optional(),
    carCategory: string().optional(),
    isPersonalContribution: string().optional(),
    distanceHomeOffice: numericString(number().optional().nullable())
  })

  type EditContractMobilityForm = TypeOf<typeof editContractMobilitySchema>

  const { data: getCompanyCarHistories, refetch: refetchCompanyCarHistories } =
    useSuspenseGetCompanyCarHistoriesByContractIdQuery({
      contractId: params.contractid
    })

  const { data: getContract } = useGetContractByIdQuery({
    contractId: params.contractid
  })
  const companyCarStartDateFilter = parseISO(getContract?.contractById.startDate)

  const startDatePlusFiveYears = new Date(
    parseISO(getContract?.contractById.startDate).setFullYear(companyCarStartDateFilter.getFullYear() + 5)
  )

  const today = new Date()
  const addDateToEndDate = startDatePlusFiveYears < today ? today : startDatePlusFiveYears
  const companyCarEndDateFilter = getContract?.contractById.endDate
    ? parseISO(getContract?.contractById.endDate)
    : addDateToEndDate

  const { data: getAllCompanyCars } = useGetCompanyCarsQuery({
    startDate: ToBackendFormatedDate(companyCarStartDateFilter),
    endDate: ToBackendFormatedDate(companyCarEndDateFilter),
    offset: 0,
    limit: -1,
    sortByProperty: 'Carbrand',
    orderDirection: OrderDirection.Asc
  })
  const companyCarOptions =
    getAllCompanyCars?.companyCars.data.map(
      c => new FormSelectOption(c.id, `${c.carBrandType} ${c.model} (${c.licensePlate})`)
    ) || []

  const { carTypeOptions } = formTypeSelectOptions

  const companyCarHistories = getCompanyCarHistories?.companyCarHistories
  const [selectedCompanyCarHistory, setSelectedCompanyCarHistory] = useState(
    companyCarHistories
      ? companyCarHistories
          .filter(c => parseISO(c.endDate).toLocaleDateString() > new Date().toLocaleDateString() || c.endDate === null)
          ?.at(0)
      : null
  )
  const [addNewCompanyCar, setAddNewCompanyCar] = useState(false)
  const createMutation = useCreateCompanyCarHistoryMutation()
  const updateMutation = useUpdateCompanyCarHistoryMutation()
  const deleteMutation = useDeleteCompanyCarHistoryMutation()

  const form = useForm<EditContractMobilityForm>({
    resolver: zodResolver(editContractMobilitySchema),
    defaultValues: {
      startDate: selectedCompanyCarHistory?.startDate ? parseISO(selectedCompanyCarHistory.startDate) : undefined,
      endDate: selectedCompanyCarHistory?.endDate ? parseISO(selectedCompanyCarHistory.endDate) : null,
      companyCarId: selectedCompanyCarHistory?.companyCar.id,
      carTypeKey: selectedCompanyCarHistory?.carType.key,
      notes: selectedCompanyCarHistory?.notes,
      carBrandKey: selectedCompanyCarHistory?.companyCar.carBrandType,
      model: selectedCompanyCarHistory?.companyCar.model || '-',
      registrationDate: selectedCompanyCarHistory?.companyCar.registrationDate
        ? parseISO(selectedCompanyCarHistory.companyCar.registrationDate).toLocaleDateString()
        : '-',
      companyCarStartDate: selectedCompanyCarHistory?.companyCar.startDate
        ? parseISO(selectedCompanyCarHistory.companyCar.startDate).toLocaleDateString()
        : '-',
      companyCarEndDate: selectedCompanyCarHistory?.companyCar.endDate
        ? parseISO(selectedCompanyCarHistory.companyCar.endDate).toLocaleDateString()
        : '-',
      motorType: selectedCompanyCarHistory?.companyCar.motorType || '-',
      co2: selectedCompanyCarHistory?.companyCar.co2?.toString() || '-',
      carCategory: selectedCompanyCarHistory?.companyCar.carCategory || '-',
      isPersonalContribution: selectedCompanyCarHistory?.companyCar.isPersonalContribution
        ? `${t('common.yes')}`
        : `${t('common.no')}`,
      distanceHomeOffice: selectedCompanyCarHistory?.distanceHomeOffice
    }
  })

  const handleOnSubmit = async (contractCompanyCar: EditContractMobilityForm) => {
    if (!selectedCompanyCarHistory) {
      await createMutation
        .mutateAsync({
          createCompanyCarHistoryCommand: {
            contractId: params.contractid || '',
            startDate: contractCompanyCar.startDate,
            endDate: contractCompanyCar.endDate,
            companyCarId: contractCompanyCar.companyCarId,
            carTypeKey: contractCompanyCar.carTypeKey,
            notes: contractCompanyCar.notes,
            distanceHomeOffice: contractCompanyCar.distanceHomeOffice
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateCompanyCarHistoryCommand: {
            companyCarHistoryId: selectedCompanyCarHistory.id,
            contractId: params.contractid || '',
            startDate: contractCompanyCar.startDate,
            endDate: contractCompanyCar.endDate,
            companyCarId: contractCompanyCar.companyCarId,
            carTypeKey: contractCompanyCar.carTypeKey,
            notes: contractCompanyCar.notes,
            distanceHomeOffice: contractCompanyCar.distanceHomeOffice
          }
        })
        .then(() => {
          refetchCompanyCarHistories()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  async function handleDelete() {
    await deleteMutation
      .mutateAsync({
        deleteCompanyCarHistoryCommand: {
          companyCarHistoryId: selectedCompanyCarHistory?.id || ''
        }
      })
      .then(() => {
        refetchCompanyCarHistories()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontract.mobility.title')}</Typography>
      <Grid className="bold" container>
        <Grid xs={4} item>
          <p>{t('form.field.startdate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.enddate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.car')}</p>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {companyCarHistories && (
            <RadioGroup
              aria-labelledby="company-cars"
              value={selectedCompanyCarHistory?.id}
              onChange={(_, companyCarHistoryId) => {
                const selectedCompanyCarHistory = companyCarHistories.find(x => x.id === companyCarHistoryId)
                setSelectedCompanyCarHistory(selectedCompanyCarHistory)
                form.reset({
                  startDate: parseISO(selectedCompanyCarHistory?.startDate),
                  endDate: selectedCompanyCarHistory?.endDate ? parseISO(selectedCompanyCarHistory?.endDate) : null,
                  companyCarId: selectedCompanyCarHistory?.companyCar.id,
                  carTypeKey: selectedCompanyCarHistory?.carType.key,
                  notes: selectedCompanyCarHistory?.notes ? selectedCompanyCarHistory?.notes : null
                })
              }}
            >
              {companyCarHistories.map((companyCarHistory, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <p>
                      <Radio
                        sx={{ padding: '0 0.25rem 0 0', marginTop: '-0.2rem' }}
                        size="small"
                        value={companyCarHistory.id}
                      />
                      {parseISO(companyCarHistory.startDate).toLocaleDateString()}
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{companyCarHistory.endDate && parseISO(companyCarHistory.endDate).toLocaleDateString()}</p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>
                      {companyCarHistory.companyCar.carBrandType} {companyCarHistory.companyCar.model}
                    </p>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid container item xs={12} gap={1}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.reset({
                startDate: undefined,
                endDate: null,
                companyCarId: undefined
              })
              setAddNewCompanyCar(true)
              setSelectedCompanyCarHistory(null)
            }}
          >
            {t('flyin.editperson.addcompanycar')}
          </Button>
          {selectedCompanyCarHistory && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleDelete}
              disabled={deleteMutation.isPending}
            >
              {t('flyin.editperson.removecompanycar')}
            </Button>
          )}
        </Grid>
      </FormGridLayout>

      {(selectedCompanyCarHistory || addNewCompanyCar) && (
        <>
          <FormGridLayout hasPaddingTop hasBorderBottom>
            <FormDatepicker
              sx={6}
              name="startDate"
              label={`${t('form.field.startdate')} *`}
              disabled={!addNewCompanyCar}
            />

            <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} />

            <FormSelect
              sx={12}
              name="companyCarId"
              label={`${t('form.field.companycar')} *`}
              options={companyCarOptions}
              onChange={(_, selectedOption) => {
                const car = getAllCompanyCars?.companyCars.data?.find(c => c.id === selectedOption?.id)
                form.resetField('carBrandKey', {
                  defaultValue: car?.carBrandType || ''
                })
                form.resetField('model', {
                  defaultValue: car?.model || '-'
                })
                form.resetField('registrationDate', {
                  defaultValue: car?.registrationDate ? parseISO(car?.registrationDate).toLocaleDateString() : '-'
                })
                form.resetField('companyCarStartDate', {
                  defaultValue: car?.startDate ? parseISO(car?.startDate).toLocaleDateString() : '-'
                })
                form.resetField('companyCarEndDate', {
                  defaultValue: car?.endDate ? parseISO(car?.endDate).toLocaleDateString() : '-'
                })
                form.resetField('motorType', {
                  defaultValue: car?.motorType ? car.motorType : '-'
                })
                form.resetField('co2', {
                  defaultValue: car?.co2 ? car.co2.toString() : '-'
                })
                form.resetField('carCategory', {
                  defaultValue: car?.carCategory ? car.carCategory : '-'
                })
                form.resetField('isPersonalContribution', {
                  defaultValue: car?.isPersonalContribution ? `${t('common.yes')}` : `${t('common.no')}`
                })
              }}
            />

            <FormSelect sx={12} name="carTypeKey" label={`${t('form.field.cartype')} *`} options={carTypeOptions} />
          </FormGridLayout>

          <FormGridLayout hasPaddingTop>
            <FormInput sx={6} name="carBrandKey" label={t('form.field.carbrand')} disabled={true} />

            <FormInput sx={6} name="model" label={t('form.field.model')} disabled={true} />

            <FormInput sx={6} name="motorType" label={t('form.field.motortype')} disabled={true} />

            <FormInput sx={6} name="registrationDate" label={t('form.field.registrationdate')} disabled={true} />

            <FormInput sx={6} name="companyCarStartDate" label={t('form.field.startdate')} disabled={true} />

            <FormInput sx={6} name="companyCarEndDate" label={t('form.field.enddate')} disabled={true} />

            <FormNumericInput sx={6} name="co2" label={t('form.field.co2')} disabled={true} />

            <FormInput sx={6} name="carCategory" label={t('form.field.carcategory')} disabled={true} />

            <FormInput
              sx={6}
              name="isPersonalContribution"
              label={t('form.field.ispersonalcontribution')}
              disabled={true}
            />

            <FormNumericInput sx={6} name="distanceHomeOffice" label={t('form.field.distancehomeoffice')} />

            <FormInput sx={12} name="notes" label={t('form.field.notes')} multiline />
          </FormGridLayout>

          <FormErrorList customErrors={backendErrors} />
          <FormActionButtons
            isMutating={createMutation.isPending || updateMutation.isPending}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default EditContractMobility
