import { ImportProcessModel, ImportProcessStatus } from '@epix-web-apps/core'
import { create } from 'zustand'

export enum Steps {
  SelectDataTypeAndFile,
  SelectImportFormat,
  ConvertAndValidate,
  Finish
}

interface State {
  currentStep: Steps
  activeSteps: Steps[]
  isProcessingForm: boolean
}

interface Actions {
  reset: () => void
  setIsProcessingForm: (isProcessingForm: boolean) => void
  setImportProcess: (importProcess: ImportProcessModel) => void
}

const allSteps = [Steps.SelectDataTypeAndFile, Steps.SelectImportFormat, Steps.ConvertAndValidate, Steps.Finish]

const initialState: State = {
  currentStep: Steps.SelectDataTypeAndFile,
  activeSteps: [Steps.SelectDataTypeAndFile, Steps.ConvertAndValidate, Steps.Finish],
  isProcessingForm: false
}

export const useImportDataStore = create<State & Actions>(set => ({
  ...initialState,
  reset: () => set(initialState),
  setIsProcessingForm: (isProcessingForm: boolean) => set({ isProcessingForm }),
  setImportProcess: (importProcess: ImportProcessModel) =>
    set({
      currentStep: GetCurrentStep(importProcess.status),
      activeSteps: getActiveSteps(importProcess)
    })
}))

function getActiveSteps(importProcess: ImportProcessModel) {
  let steps = [...allSteps]

  if (
    importProcess.status === ImportProcessStatus.Created ||
    (importProcess.status !== ImportProcessStatus.ConverterNeeded && !importProcess.converterChosen)
  )
    steps = steps.filter(step => step !== Steps.SelectImportFormat)

  return steps
}

function GetCurrentStep(importProcessStatus: ImportProcessStatus) {
  switch (importProcessStatus) {
    case ImportProcessStatus.ConverterNeeded:
      return Steps.SelectImportFormat
    case ImportProcessStatus.Converting:
    case ImportProcessStatus.ConversionFailed:
    case ImportProcessStatus.Converted:
    case ImportProcessStatus.Validating:
    case ImportProcessStatus.ValidationFailed:
    case ImportProcessStatus.Validated:
      return Steps.ConvertAndValidate
    case ImportProcessStatus.Importing:
    case ImportProcessStatus.Imported:
    case ImportProcessStatus.ImportFailed:
      return Steps.Finish
    default:
      throw new Error('Import process status not supported')
  }
}
