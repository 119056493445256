import { DetailBox, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { AddManualEvent } from '../../selfservice-components/add-edit-manual-event'
import { AddRequestPolicyContract } from '../../selfservice-components/add-edit-request-policy-contract'
import { ManualEventsOverview } from '../../selfservice-components/manual-events-overview'
import { RequestPolicyContractOverview } from '../../selfservice-components/request-policy-contract-overview'
import { useRequiredParams } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface ContractsSelfServiceTabProps {}

export function ContractsSelfServiceTab(props: ContractsSelfServiceTabProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<ContractDetailPageParams>()
  const { openFlyIn } = useFlyIn()

  return (
    <>
      <Box>
        <DetailBox
          title={t('contractdetailpage.requestpolicies.title')}
          actionIcon={
            <Button
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <AddRequestPolicyContract contractId={params.contractid} />
                })
              }
            >
              <AddIcon />
              {t('contractdetailpage.button.addrequestpolicy')}
            </Button>
          }
        >
          <RequestPolicyContractOverview contractId={params.contractid || ''} />
        </DetailBox>
      </Box>

      <Box>
        <DetailBox
          title={t('contractdetailpage.manualevents.title')}
          actionIcon={
            <Button
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <AddManualEvent contractId={params.contractid} />
                })
              }
            >
              <AddIcon />
              {t('contractdetailpage.button.addmanualevent')}
            </Button>
          }
        >
          <ManualEventsOverview contractId={params.contractid || ''} />
        </DetailBox>
      </Box>
    </>
  )
}

export default ContractsSelfServiceTab
