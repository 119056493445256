import { useDeleteSmartOutputCodeMutation, useSuspenseGetSmartOutputCodesThatUseIntermediateResultQuery } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, useFlyIn } from '@epix-web-apps/ui'
import { Box, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface RemoveSmartOutputCodeProps {
  smartPayComponentHistoryId: string
  smartOutputCodeId: string
  description: string
}

function RemoveSmartOutputCode({
  smartPayComponentHistoryId,
  smartOutputCodeId,
  description
}: RemoveSmartOutputCodeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getSmartOutputCodesThatUseIntermediateResult } = useSuspenseGetSmartOutputCodesThatUseIntermediateResultQuery({
    smartPayComponentHistoryId: smartPayComponentHistoryId,
    smartOutputCodeId: smartOutputCodeId
  })

  const canDelete = getSmartOutputCodesThatUseIntermediateResult.smartOutputCodesThatUseIntermediateResult.length === 0

  const form = useForm()
  const deleteMutation = useDeleteSmartOutputCodeMutation()

  function handleDelete() {
    deleteMutation
      .mutateAsync({
        deleteSmartOutputCodeCommand: {
          smartPayComponentHistoryId: smartPayComponentHistoryId,
          smartOutputCodeId: smartOutputCodeId
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleDelete)}>
      <Typography variant="h4">{t('removesmartoutputcode.delete.title')}</Typography>

      <Box sx={{ pb: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography>{description}</Typography>
        </Box>
        <Divider sx={{ my: '0.5rem' }} />
      
        {getSmartOutputCodesThatUseIntermediateResult.smartOutputCodesThatUseIntermediateResult.length > 0 && (
          <>
            <Typography sx={{ pb: '0.5rem' }}>{t('removesmartoutputcode.delete.in-use')}</Typography>
            <List>
              {getSmartOutputCodesThatUseIntermediateResult.smartOutputCodesThatUseIntermediateResult.map(o => (
                <ListItem key={o.id}>
                  <ListItemText sx={{ flex: 1 }} primary={`${o.code} - ${o.description}`} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>

      <FormActionButtons
        isMutating={deleteMutation.isPending}
        disabled={!canDelete}
        buttonText={t('common.delete')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveSmartOutputCode
