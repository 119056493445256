import {
  ROLE,
  useGetAverageAgeQuery,
  useGetAverageSeniorityQuery,
  useGetFullTimeEquivalentQuery,
  useGetHeadCountQuery
} from '@epix-web-apps/core'
import { CanView, useGlobalPersistedStore, useRouteDefinitions } from '@epix-web-apps/ui'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import CakeIcon from '@mui/icons-material/Cake'
import SearchIcon from '@mui/icons-material/Search'
import WorkIcon from '@mui/icons-material/Work'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GeneralStatistics } from '../general-statistics'

/* eslint-disable-next-line */
export interface AnalyticsPaperProps {}

export function AnalyticsOverview(props: AnalyticsPaperProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const routeDefinitions = useRouteDefinitions()
  const { icpFilters } = useGlobalPersistedStore()

  const headcountQuery = useGetHeadCountQuery({ icps: icpFilters })
  const fteQuery = useGetFullTimeEquivalentQuery({ icps: icpFilters })
  const seniorityQuery = useGetAverageSeniorityQuery({ icps: icpFilters })
  const ageQuery = useGetAverageAgeQuery({ icps: icpFilters })

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', p: 1, mb: 2 }}>
        <Typography variant="h4" m={0}>
          {t('homepage.analystics')}
        </Typography>
        <Box sx={{ m: 1 }}>
          <CanView roles={[ROLE.ADMIN, ROLE.VIEWWORKFORCEANALYTICS]}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                navigate(routeDefinitions.Analytics.WORKFORCE)
              }}
            >
              <SearchIcon />
              {t('homepage.analytics.viewdetails')}
            </Button>
          </CanView>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Grid container spacing={2}>
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.headcount')}
            value={headcountQuery.data?.headcount.value}
            isLoading={headcountQuery.isLoading}
          />
          <GeneralStatistics
            image={<AccessibilityIcon />}
            name={t('statistics.fulltimeequivalents')}
            value={fteQuery.data?.peopleFullTimeEquivalents.value}
            isLoading={fteQuery.isLoading}
          />
          <GeneralStatistics
            image={<WorkIcon />}
            name={t('statistics.seniority')}
            value={seniorityQuery.data?.averageSeniority.value}
            isLoading={seniorityQuery.isLoading}
          />
          <GeneralStatistics
            image={<CakeIcon />}
            name={t('statistics.age')}
            value={ageQuery.data?.averageAge.value}
            isLoading={ageQuery.isLoading}
          />
        </Grid>
      </Box>
    </>
  )
}

export default AnalyticsOverview
