import {
  DEFAULT_LASTNAMETYPE_KEY,
  DEFAULT_UNDEFINED_KEY,
  FormSelectOption,
  formTypeSelectOptions,
  IcpSubGenderModel,
  numericString,
  useGetSubGendersQuery,
  useRequiredParams,
  useSuspenseGetAllContractsQuery,
  useSuspenseGetPersonByIdQuery,
  useUpdatePersonIdentificationDetailsMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useEffect } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export function EditPersonIdentification() {
  const { t } = useTranslation()

  const editPersonSubGenderSchema = object({
    id: string({
      required_error: t('form.validation.subgenderrequired'),
      invalid_type_error: t('form.validation.subgenderrequired')
    })
      .min(1, t('form.validation.subgenderrequired'))
      .optional()
  })

  const editPersonIdentificationSchema = object({
    titleType: string().nullable(),
    firstName: string({
      required_error: t('form.validation.firstnamerequired'),
      invalid_type_error: t('form.validation.firstnamerequired')
    }).min(1, t('form.validation.firstnamerequired')),
    lastName: string({
      required_error: t('form.validation.lastnamerequired'),
      invalid_type_error: t('form.validation.lastnamerequired')
    }).min(1, t('form.validation.lastnamerequired')),
    subGenders: editPersonSubGenderSchema.array(),
    lastNamePrefix: string().nullable(),
    otherNames: string().nullable(),
    partnerName: string().nullable(),
    partnerLastNamePrefix: string().nullable(),
    preferredLastNameType: string().nullable(),
    preferredLocale: string().optional().nullable(),
    genderType: string({
      required_error: t('form.validation.gendertyperequired'),
      invalid_type_error: t('form.validation.gendertyperequired')
    }),
    birthDate: date().optional().nullable(),
    birthPlace: string().nullable(),
    birthCountry: string().optional().nullable(),
    nationality: string().optional().nullable(),
    socialSecurityNumber: string().nullable(),
    disabled: boolean(),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.percentagelessthan100') })
        .optional()
    )
  })

  type EditPersonIdentificationForm = TypeOf<typeof editPersonIdentificationSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { genderOptions, countryOptions, titleOptions, lastNameOptions, nationalityOptions, localeOptions } =
    formTypeSelectOptions

  const { data: getPersonById, refetch: refetchPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })

  const { data: getAllContracts } = useSuspenseGetAllContractsQuery({
    personId: params.id
  })

  const contracts = getAllContracts.allContracts
  const mutation = useUpdatePersonIdentificationDetailsMutation()
  const form = useForm<EditPersonIdentificationForm>({
    resolver: zodResolver(editPersonIdentificationSchema),
    defaultValues: {
      titleType: getPersonById?.personById.titleType?.key || DEFAULT_UNDEFINED_KEY,
      firstName: getPersonById?.personById.firstName,
      otherNames: getPersonById?.personById.otherNames,
      lastName: getPersonById?.personById.lastName,
      lastNamePrefix: getPersonById?.personById.lastNamePrefix,
      preferredLastNameType: getPersonById?.personById.preferredLastNameType?.key || DEFAULT_LASTNAMETYPE_KEY,
      partnerName: getPersonById?.personById.partnerName,
      partnerLastNamePrefix: getPersonById?.personById.partnerLastNamePrefix,
      preferredLocale: getPersonById?.personById.preferredLanguage?.locale,
      genderType: getPersonById?.personById.genderType?.key,
      subGenders: getPersonById?.personById.icpSubGenders || [],
      birthDate: getPersonById?.personById.birthDate ? parseISO(getPersonById.personById.birthDate) : null,
      birthPlace: getPersonById?.personById.placeOfBirth,
      birthCountry: getPersonById?.personById.countryOfBirth?.code,
      nationality: getPersonById?.personById.nationality?.code,
      socialSecurityNumber: getPersonById?.personById.nationalSocialSecurityNumber,
      disabled: getPersonById?.personById.disabled,
      disabledPercentage: getPersonById?.personById.disabledPercentage || null
    }
  })

  const handleOnSubmit = async (personIdentificationDetails: EditPersonIdentificationForm) => {
    await mutation
      .mutateAsync({
        personIdentificationCommand: {
          personId: params.id || '',
          titleTypeKey: personIdentificationDetails.titleType || DEFAULT_UNDEFINED_KEY,
          firstName: personIdentificationDetails.firstName,
          otherNames: personIdentificationDetails.otherNames,
          lastName: personIdentificationDetails.lastName,
          lastNamePrefix: personIdentificationDetails.lastNamePrefix,
          partnerName: personIdentificationDetails.partnerName,
          partnerNamePrefix: personIdentificationDetails.partnerLastNamePrefix,
          preferredLastNameTypeKey: personIdentificationDetails.preferredLastNameType || DEFAULT_LASTNAMETYPE_KEY,
          preferredLocale: personIdentificationDetails.preferredLocale || '',
          genderTypeKey: personIdentificationDetails.genderType,
          icpSubGenderIds:
            personIdentificationDetails.subGenders?.filter(x => x.id !== undefined).map(x => x.id ?? '') ?? [],
          birthDate: personIdentificationDetails.birthDate,
          placeOfBirth: personIdentificationDetails.birthPlace || '',
          countryOfBirth: personIdentificationDetails.birthCountry || '',
          nationality: personIdentificationDetails.nationality || '',
          nationalSocialSecurityNumber: personIdentificationDetails.socialSecurityNumber,
          disabled: personIdentificationDetails.disabled,
          disabledPercentage: personIdentificationDetails.disabledPercentage || null
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.identification.title')}</Typography>

      <FormGridLayout>
        <FormSelect sx={6} name="titleType" label={t('form.field.title')} options={titleOptions} />

        <FormInput sx={6} name="firstName" label={`${t('form.field.firstname')} *`} />

        <FormInput sx={6} name="otherNames" label={t('form.field.othernames')} />

        <FormSelect
          sx={6}
          name="preferredLastNameType"
          label={t('form.field.lastnametype')}
          options={lastNameOptions}
        />

        <FormInput sx={6} name="lastNamePrefix" label={t('form.field.lastnameprefix')} />

        <FormInput sx={6} name="lastName" label={`${t('form.field.lastname')} *`} />

        <FormInput sx={6} name="partnerLastNamePrefix" label={t('form.field.partnernameprefix')} />

        <FormInput sx={6} name="partnerName" label={t('form.field.partnername')} />

        <FormSelect sx={6} name="preferredLocale" label={t('form.field.language')} options={localeOptions} />

        <FormSelect
          sx={6}
          name="genderType"
          label={t('form.field.gender')}
          options={genderOptions}
        />

        {[...new Map(contracts?.map(contract => [contract.icp.id, contract])).values()].map(
          (contract, index) =>
            form.watch('genderType') && (
              <IcpSubGenderDropdown
                key={contract.icp.configurationKey}
                genderTypeKey={form.watch('genderType')}
                currentIcpSubGender={getPersonById.personById.icpSubGenders?.find(
                  sg => sg.icpConfigurationKey === contract.icp.configurationKey
                )}
                icp={contract.icp}
                index={index}
              />
            )
        )}
      </FormGridLayout>

      <Box sx={{ marginTop: '2rem' }}>
        <FormGridLayout>
          <FormDatepicker sx={6} name="birthDate" label={t('form.field.birthdate')} />

          <FormInput sx={6} name="birthPlace" label={t('form.field.birthplace')} />

          <FormSelect sx={6} name="birthCountry" label={t('form.field.birthcountry')} options={countryOptions} />

          <FormSelect sx={6} name="nationality" label={t('form.field.nationality')} options={nationalityOptions} />

          <FormInput sx={6} name="socialSecurityNumber" label={t('form.field.socialsecuritynumber')} />

          <FormSwitch
            sx={12}
            name="disabled"
            onChange={() => {
              form.resetField('disabledPercentage', {
                defaultValue: undefined
              })
            }}
            label={t('form.field.disabled')}
          />

          {form.watch('disabled') && (
            <FormNumericInput sx={6} name="disabledPercentage" label={t('form.field.disabled-percentage')} />
          )}
        </FormGridLayout>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

interface SubIcpGenderDropdownProps {
  icp: {
    id: string
    code: string
    configurationKey: string
  }
  genderTypeKey: string
  currentIcpSubGender?: IcpSubGenderModel
  index: number
}

function IcpSubGenderDropdown({ icp, genderTypeKey, currentIcpSubGender, index }: SubIcpGenderDropdownProps) {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  const { data: getIcpSubGenders } = useGetSubGendersQuery({
    configurationKey: icp.configurationKey,
    genderTypeKey: genderTypeKey
  })

  useEffect(() => {
    if (getIcpSubGenders) {
      const selectedSubGender = getIcpSubGenders.subGenders.find(sg => sg.id === currentIcpSubGender?.id)
      setValue(`subGenders.${index}.id`, selectedSubGender?.id ?? getIcpSubGenders.subGenders?.[0]?.id)
    }
  }, [getIcpSubGenders])

  // Only show the sublist if it has more than one option. This will still send the one on one options to the update command.
  if (getIcpSubGenders && getIcpSubGenders.subGenders.length > 1) {
    return (
      <FormSelect
        sx={6}
        name={`subGenders.${index}.id`}
        label={`${t('form.field.subgender', { icpcode: icp?.code })} *`}
        options={getIcpSubGenders.subGenders.map(sg => new FormSelectOption(sg.id, sg.name))}
      />
    )
  }

  return null
}
