import {useRequiredParams, useSuspenseGetPersonByIdQuery, useUpdatePersonBankInformationMutation } from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  useFlyIn,
  
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditPersonBankInformationProps {}

export function EditPersonBankInformation(props: EditPersonBankInformationProps) {
  const { t } = useTranslation()
  const editPersonBankInfoSchema = object({
    bankAccount: string().nullable(),
    accountHolder: string().nullable(),
    iban: string().nullable(),
    bankIdentificationNumber: string().nullable(),
    bankSortCode: string().nullable()
  })
  type EditPersonBankInfoForm = TypeOf<typeof editPersonBankInfoSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getPersonById, refetch: refetchPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const mutation = useUpdatePersonBankInformationMutation()
  const form = useForm<EditPersonBankInfoForm>({
    resolver: zodResolver(editPersonBankInfoSchema),
    defaultValues: {
      accountHolder: getPersonById?.personById?.accountHolder,
      bankAccount: getPersonById?.personById?.bankAccount,
      iban: getPersonById?.personById?.iban,
      bankIdentificationNumber: getPersonById?.personById?.bic,
      bankSortCode: getPersonById?.personById?.bankSortCode
    }
  })

  const handleOnSubmit = async (personBankInfo: EditPersonBankInfoForm) => {
    await mutation
      .mutateAsync({
        updatePersonBankInformationCommand: {
          personId: params.id || '',
          bankAccount: personBankInfo.bankAccount,
          accountHolder: personBankInfo.accountHolder,
          iban: personBankInfo.iban,
          bic: personBankInfo.bankIdentificationNumber,
          bankSortCode: personBankInfo.bankSortCode
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.bankinfo.title')}</Typography>

      <Box sx={{ display: 'flex', gap: 8 }}>
        <p>
          <AccountBalanceOutlinedIcon />
        </p>
        <FormGridLayout>
          <FormInput sx={12} name="accountHolder" label={t('form.field.accountholder')} />

          <FormInput sx={12} name="bankAccount" label={t('form.field.bankaccount')} />

          <FormInput sx={12} name="iban" label={t('form.field.iban')} />

          <FormInput sx={12} name="bankIdentificationNumber" label={t('form.field.bankidentificationnumber')} />

          <FormInput sx={12} name="bankSortCode" label={t('form.field.banksortcode')} />
        </FormGridLayout>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonBankInformation
