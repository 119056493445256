import {
  FormSelectOption,
  GetSmartInputCodeByIdQuery,
  numericString,
  OrderDirection,
  SMART_INPUT_CODE_DEFAULTVALUE_TYPE_KEYS,
  SMART_INPUT_CODE_MANANAGEDBY_TYPE_KEYS,
  useCreateSmartInputCodeMutation,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useSuspenseGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetSmartInputCodeByIdQuery,
  useSuspenseGetSmartInputDefaultValueTypesQuery,
  useSuspenseGetSmartInputManagedByTypesQuery,
  useUpdateSmartInputCodeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormRadioGroup,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'

export interface AddSmartInputCodeProps {
  smartComponentHistoryId: string
  employerId: string
  refetch: () => void
}

export interface EditSmartInputCodeProps {
  smartComponentHistoryId: string
  employerId: string
  smartInputCodeId: string
  refetch: () => void
}

export function AddSmartInputCode({ smartComponentHistoryId, employerId, refetch }: AddSmartInputCodeProps) {
  return (
    <AddEditSmartInputCode
      smartComponentHistoryId={smartComponentHistoryId}
      employerId={employerId}
      refetch={refetch}
    />
  )
}

export function EditSmartInputCode({
  smartComponentHistoryId,
  employerId,
  smartInputCodeId,
  refetch
}: EditSmartInputCodeProps) {
  const getSmartInputCodeQuery = useSuspenseGetSmartInputCodeByIdQuery({
    smartPayComponentHistoryId: smartComponentHistoryId,
    smartInputCodeId: smartInputCodeId
  })

  return (
    <AddEditSmartInputCode
      smartComponentHistoryId={smartComponentHistoryId}
      employerId={employerId}
      refetch={refetch}
      getSmartInputCodeQuery={getSmartInputCodeQuery}
    />
  )
}

export function AddEditSmartInputCode({
  smartComponentHistoryId,
  employerId,
  refetch,
  getSmartInputCodeQuery
}: {
  smartComponentHistoryId: string
  employerId: string
  refetch: () => void
  getSmartInputCodeQuery?: UseSuspenseQueryResult<GetSmartInputCodeByIdQuery, unknown>
}) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getPayrollcodes } = useSuspenseGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: {
      showSalaryCodes: true
    }
  })

  const { data: getInputCodeDefaultValueTypes } = useSuspenseGetSmartInputDefaultValueTypesQuery()
  const { data: getInputCodeManagedByTypes } = useSuspenseGetSmartInputManagedByTypesQuery()

  const addSmartInputCodeSchema = object({
    payrollCodeId: string(),
    valueTypePayComponentKey: string(),
    managedByTypeKey: string(),
    defaultValueTypeKey: string(),
    defaultValue: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .positive({ message: t('form.validation.valuegreaterthen0') })
        .optional()
    )
  }).refine(
    data =>
      data.managedByTypeKey === SMART_INPUT_CODE_MANANAGEDBY_TYPE_KEYS.INSIDE_SET
        ? data.defaultValue
        : true,
    {
      message: t('form.validation.valuerequired'),
      path: ['defaultValue']
    }
  )

  type CreateSmartInputCode = TypeOf<typeof addSmartInputCodeSchema>

  const getSmartInputCodeById = getSmartInputCodeQuery?.data

  const form = useForm<CreateSmartInputCode>({
    resolver: zodResolver(addSmartInputCodeSchema),
    defaultValues: {
      payrollCodeId: getSmartInputCodeById?.smartInputCodeById.payrollCodeId,
      valueTypePayComponentKey: getSmartInputCodeById?.smartInputCodeById.valueTypePayComponentKey,
      managedByTypeKey:
        getSmartInputCodeById?.smartInputCodeById.managedByType.key ?? SMART_INPUT_CODE_MANANAGEDBY_TYPE_KEYS.DEFAULT,
      defaultValue: getSmartInputCodeById?.smartInputCodeById.defaultValue,
      defaultValueTypeKey:
        getSmartInputCodeById?.smartInputCodeById.defaultValueType?.key ??
        SMART_INPUT_CODE_DEFAULTVALUE_TYPE_KEYS.DEFAULT
    }
  })

  const watchedPayrollCodeId = form.watch('payrollCodeId')

  const { data: allActiveValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollCodeId || ''
    },
    {
      enabled: !!watchedPayrollCodeId
    }
  )

  useEffect(() => {
    if (allActiveValueTypePayComponents) {
      form.resetField('valueTypePayComponentKey', {
        defaultValue: allActiveValueTypePayComponents.activeValueTypePayComponentByPayrollCodeId[0]?.key
      })
    }
  }, [allActiveValueTypePayComponents])

  const createMutation = useCreateSmartInputCodeMutation()
  const updateMutation = useUpdateSmartInputCodeMutation()

  const handleOnSubmit = (newSmartInputPayComponent: CreateSmartInputCode) => {
    if (!getSmartInputCodeById) {
      createMutation
        .mutateAsync({
          createSmartInputCodeCommand: {
            smartPayComponentHistoryId: smartComponentHistoryId,
            payrollCodeId: newSmartInputPayComponent.payrollCodeId,
            valueTypePayComponentKey: newSmartInputPayComponent.valueTypePayComponentKey,
            managedByTypeKey: newSmartInputPayComponent.managedByTypeKey,
            defaultValueTypeKey: newSmartInputPayComponent.defaultValueTypeKey,
            defaultValue: newSmartInputPayComponent.defaultValue
          }
        })
        .then(() => {
          refetch()
          closeFlyIn()
        })
    } else {
      updateMutation
        .mutateAsync({
          updateSmartInputCodeCommand: {
            smartPayComponentHistoryId: smartComponentHistoryId,
            smartInputCodeId: getSmartInputCodeById.smartInputCodeById.id,
            payrollCodeId: newSmartInputPayComponent.payrollCodeId,
            valueTypePayComponentKey: newSmartInputPayComponent.valueTypePayComponentKey,
            managedByTypeKey: newSmartInputPayComponent.managedByTypeKey,
            defaultValueTypeKey: newSmartInputPayComponent.defaultValueTypeKey,
            defaultValue: newSmartInputPayComponent.defaultValue
          }
        })
        .then(() => {
          getSmartInputCodeQuery.refetch()
          refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addsmartinputpaycomponent.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCodeId"
          label={`${t('form.field.payrollcode')} *`}
          options={getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
            p => new FormSelectOption(p.id, `${p.code} -  ${p.userFriendlyDescription ?? p.description}`)
          )}
        />

        <FormSelect
          sx={12}
          name="valueTypePayComponentKey"
          label={`${t('form.field.valuetype')} *`}
          options={allActiveValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId.map(
            v => new FormSelectOption(v.key, v.value)
          )}
        />

        <FormRadioGroup
          sx={12}
          name="managedByTypeKey"
          label={`${t('flyin.addsmartinputpaycomponent.managedby')} *`}
          options={getInputCodeManagedByTypes?.allSmartInputCodeManagedByTypes.map(
            m => new FormSelectOption(m.key, m.value)
          )}
        />

        {form.watch('managedByTypeKey') === SMART_INPUT_CODE_MANANAGEDBY_TYPE_KEYS.INSIDE_SET && (
          <>
            <FormNumericInput sx={12} name="defaultValue" label={t('flyin.addsmartinputpaycomponent.defaultvalue')} />

            <FormRadioGroup
              sx={12}
              name="defaultValueTypeKey"
              label={`${t('flyin.addsmartinputpaycomponent.defaultvaluetype')} *`}
              options={getInputCodeDefaultValueTypes?.allSmartInputCodeDefaultValueTypes.map(
                m => new FormSelectOption(m.key, m.value)
              )}
            />
          </>
        )}
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isPending || updateMutation.isPending} onCancel={closeFlyIn} />
    </FormContainer>
  )
}

export default AddEditSmartInputCode
