import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import RoomServiceOutlinedIcon from '@mui/icons-material/RoomServiceOutlined'
import { isSelfServiceUser, useSuspenseGetMeQuery } from '@epix-web-apps/core'
import { SelfService } from '../../constants-routing'

export function NotMobileOptimized() {
  const { t } = useTranslation()
  const { data: me } = useSuspenseGetMeQuery()
  const hasSelfServiceAccess = isSelfServiceUser(me?.me.roles)

  return (
    <Box
      className="not-mobile-optimized"
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography variant="h1" align="center">
        {t('notoptimizedformobile.title')}
      </Typography>

      {hasSelfServiceAccess && (
        <Button
          variant="contained"
          onClick={() => {
            window.location.replace(SelfService.ROOT())
          }}
        >
          <RoomServiceOutlinedIcon />
          {t('usermenu.switchtoselfservice')}
        </Button>
      )}
    </Box>
  )
}

export default NotMobileOptimized
