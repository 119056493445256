import {
  FormSelectOption,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetPhocusContractDetailsQuery,
  useUpdatePhocusContractDetailEmployeeTemplateMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

/* eslint-disable-next-line */
export interface EditPhocusEmployeeTemplateProps {
  contractId: string
}

export function EditPhocusEmployeeTemplate({ contractId }: EditPhocusEmployeeTemplateProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetPhocusContractDetailsQuery({
      contractId: contractId
    })

  const { data: employeeTemplateTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.NL_EMPLOYEE_TEMPLATE.key,
    limit: -1,
    offset: 0
  })

  const employeeTemplateTypes = employeeTemplateTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    e => new FormSelectOption(e.id, e.value)
  )

  const editPhocusEmployeeTemplateSchema = object({
    employeeTemplateTypeId: string().nullable().optional()
  })

  type EditPhocusEmployeeTemplateForm = TypeOf<typeof editPhocusEmployeeTemplateSchema>

  const updateMutation = useUpdatePhocusContractDetailEmployeeTemplateMutation()

  const form = useForm<EditPhocusEmployeeTemplateForm>({
    resolver: zodResolver(editPhocusEmployeeTemplateSchema),
    defaultValues: {
      employeeTemplateTypeId:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailEmployeeTemplateModel
          .employeeTemplateType?.id
    }
  })

  const handleOnSubmit = (phocusEmployeeTemplate: EditPhocusEmployeeTemplateForm) => {
    updateMutation
      .mutateAsync({
        phocusContractDetailEmployeeTemplateCommand: {
          id: partnerSpecificDetails?.phocusContractDetailByContractId.id ?? '',
          employeeTemplateTypeId: phocusEmployeeTemplate.employeeTemplateTypeId
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editemployeetemplate.title')}</Typography>
      <FormGridLayout>
        <Grid xs={12} item>
          <FormSelect
            sx={12}
            name="employeeTemplateTypeId"
            label={t('form.field.phocus.employeetemplatetype')}
            options={employeeTemplateTypes}
          />
        </Grid>
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPhocusEmployeeTemplate
