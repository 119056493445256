import {
  FormSelectOption,
  IFormSelectOption,
  OrderDirection,
  useGetAllPagedPeopleQuery,
  useGetPersonByIdQuery
} from '@epix-web-apps/core'
import { EpixAvatar, FormSelect, OptionalGridWrapper } from '@epix-web-apps/ui'
import { GridSize } from '@mui/material'
import { useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormatOptionLabelMeta } from 'react-select'

/* eslint-disable-next-line */
export interface FormSearchPersonProps {
  name: string
  label: string
  disabled?: boolean
  onPersonSelected?: (person: IFormSelectOption | null) => void
  sx?: GridSize
}

export interface Person {
  id: string
  name: string
}

export function FormSearchPerson({ label, name, onPersonSelected, disabled = false, sx = 12 }: FormSearchPersonProps) {
  const { t } = useTranslation()
  const form = useFormContext()
  const [searchString, setSearchString] = useState<string>('')
  const defaultValue = form?.getValues(name)
  const { data: getPersonById } = useGetPersonByIdQuery(
    {
      personId: defaultValue
    },
    {
      enabled: !!defaultValue
    }
  )

  const { data, isLoading, isFetching } = useGetAllPagedPeopleQuery(
    {
      offset: 0,
      limit: 20,
      selectedDate: new Date().toISOString().split('T')[0],
      showInactive: false,
      showWithoutContract: false,
      showValidations: false,
      icpIds: [],
      sortByProperty: 'identification_searchname',
      orderDirection: OrderDirection.Asc,
      searchString: searchString
    },
    {
      enabled: searchString.length > 2
    }
  )

  const options = useMemo(() => {
    if (data?.pagedPeople) {
      return data.pagedPeople.data.map(x => new FormSelectOption(x.personId, `${x.firstName} ${x.lastName}`))
    } else {
      return []
    }
  }, [data?.pagedPeople])

  const FormatOptionLabel = (option: IFormSelectOption, context: FormatOptionLabelMeta<IFormSelectOption>) => {
    return context.context === 'menu' ? (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <EpixAvatar personId={option.id} size={30} sx={{ pr: 1 }} />
        <div>{option.label}</div>
      </div>
    ) : (
      <div>{option.label}</div>
    )
  }

  return (
    <OptionalGridWrapper sx={sx}>
      <FormSelect
        name={name}
        label={label}
        options={options}
        selectedAsyncOption={
          getPersonById?.personById
            ? new FormSelectOption(
                getPersonById?.personById.id,
                `${getPersonById?.personById.firstName} ${getPersonById?.personById.preferredLastName}`
              )
            : null
        }
        disabled={disabled}
        isLoading={isLoading || isFetching}
        noOptionsMessage={searchString.length > 2 ? t('formselect.nooptions') : t('formselect.typemin3chars')}
        onChange={(e, value) => {
          onPersonSelected && onPersonSelected(value)
        }}
        onInputChange={value => {
          setSearchString(value)
        }}
        formatOptionLabel={FormatOptionLabel}
      />
    </OptionalGridWrapper>
  )
}

export default FormSearchPerson
