import {
  FormSelectOption,
  GetSmartOutputCodeByIdQuery,
  numericString,
  OrderDirection,
  useCreateSmartOutputCodeMutation,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetSmartOutputCodeByIdQuery,
  useUpdateSmartIntermediateResultMutation,
  useUpdateSmartOutputCodeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

interface AddSmartOutputCodeProps {
  smartComponentHistoryId: string
  employerId: string
  refetch: () => void
}

interface EditSmartOutputCodeProps {
  smartComponentHistoryId: string
  smartOutputCodeId: string
  employerId: string
  refetch: () => void
}

export function AddSmartOutputCode({ smartComponentHistoryId, employerId, refetch }: AddSmartOutputCodeProps) {
  return (
    <AddEditSmartOutputCode
      smartComponentHistoryId={smartComponentHistoryId}
      employerId={employerId}
      refetch={refetch}
    />
  )
}

export function EditSmartOutputCode({
  smartComponentHistoryId,
  smartOutputCodeId,
  employerId,
  refetch
}: EditSmartOutputCodeProps) {
  const getSmartOutputCodeQuery = useSuspenseGetSmartOutputCodeByIdQuery({
    smartPayComponentHistoryId: smartComponentHistoryId,
    smartOutputCodeId: smartOutputCodeId
  })

  return (
    <AddEditSmartOutputCode
      smartComponentHistoryId={smartComponentHistoryId}
      employerId={employerId}
      refetch={refetch}
      getSmartOutputCodeQuery={getSmartOutputCodeQuery}
    />
  )
}

function AddEditSmartOutputCode({
  smartComponentHistoryId,
  employerId,
  refetch,
  getSmartOutputCodeQuery
}: {
  smartComponentHistoryId: string
  employerId: string
  refetch: () => void
  getSmartOutputCodeQuery?: UseSuspenseQueryResult<GetSmartOutputCodeByIdQuery, unknown>
}) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getPayrollcodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: {
      showSalaryCodes: true
    }
  })

  const addEditSmartOutputCodeSchema = object({
    isOutputCode: boolean(),
    code: string().optional().nullable(),
    description: string().optional().nullable(),
    payrollCodeId: string().optional().nullable(),
    valueTypePayComponentKey: string().optional().nullable(),
    numberOfDecimals: numericString(number().min(1).max(4))
  }).superRefine((data, ctx) => {
    if (data.isOutputCode) {
      if (!data.payrollCodeId)
        ctx.addIssue({
          code: 'custom',
          message: t('form.validation.payrollcoderequired'),
          path: ['payrollCodeId']
        })

      if (!data.valueTypePayComponentKey)
        ctx.addIssue({
          code: 'custom',
          message: t('form.validation.valuetyperequired'),
          path: ['valueTypePayComponentKey']
        })
    }

    if (!data.isOutputCode) {
      if (!data.code)
        ctx.addIssue({
          code: 'custom',
          message: t('form.validation.coderequired'),
          path: ['code']
        })

      if (!data.description)
        ctx.addIssue({
          code: 'custom',
          message: t('form.validation.descriptionrequired'),
          path: ['description']
        })
    }
  })

  const getSmartOutputCodeById = getSmartOutputCodeQuery?.data

  type CreateEditSmartOutputCodeForm = TypeOf<typeof addEditSmartOutputCodeSchema>
  const form = useForm<CreateEditSmartOutputCodeForm>({
    resolver: zodResolver(addEditSmartOutputCodeSchema),
    defaultValues: {
      isOutputCode: true,
      code: getSmartOutputCodeById?.smartOutputCodeById.code,
      description: getSmartOutputCodeById?.smartOutputCodeById.description,
      numberOfDecimals: getSmartOutputCodeById?.smartOutputCodeById.numberOfDecimals,
      payrollCodeId: getSmartOutputCodeById?.smartOutputCodeById.payrollCodeId,
      valueTypePayComponentKey: getSmartOutputCodeById?.smartOutputCodeById.valueTypePayComponent?.key
    }
  })

  const { control } = form

  const watchedPayrollCodeId = useWatch({
    control,
    name: 'payrollCodeId'
  })

  const watchedIsOutputCode = useWatch({
    control,
    name: 'isOutputCode'
  })

  const { data: allActiveValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollCodeId ?? ''
    },
    {
      enabled: !!watchedPayrollCodeId
    }
  )

  useEffect(() => {
    if (allActiveValueTypePayComponents) {
      form.resetField('valueTypePayComponentKey', {
        defaultValue: getSmartOutputCodeById?.smartOutputCodeById.valueTypePayComponent?.key ?? allActiveValueTypePayComponents.activeValueTypePayComponentByPayrollCodeId[0]?.key
      })
    }
  }, [allActiveValueTypePayComponents])

  useEffect(() => {
    if (getSmartOutputCodeById) {
      form.resetField('isOutputCode', {
        defaultValue: getSmartOutputCodeById.smartOutputCodeById.isIntermediateResult === false
      })
    }
  }, [getSmartOutputCodeById])

  const createMutation = useCreateSmartOutputCodeMutation()
  const updateOutputCodeMutation = useUpdateSmartOutputCodeMutation()
  const updateIntermediateResultMutation = useUpdateSmartIntermediateResultMutation()

  const handleOnSubmit = (newSmartOutputCode: CreateEditSmartOutputCodeForm) => {
    if (!getSmartOutputCodeById) {
      createMutation
        .mutateAsync({
          createSmartOutputCodeCommand: {
            isIntermediateResult: !newSmartOutputCode.isOutputCode,
            numberOfDecimals: newSmartOutputCode.numberOfDecimals,
            smartPayComponentHistoryId: smartComponentHistoryId,
            code: newSmartOutputCode.code,
            description: newSmartOutputCode.description,
            payrollCodeId: newSmartOutputCode.payrollCodeId,
            valueTypePayComponentKey: newSmartOutputCode.valueTypePayComponentKey
          }
        })
        .then(() => {
          closeFlyIn()
          refetch()
        })
    } else {
      if (getSmartOutputCodeById.smartOutputCodeById.isIntermediateResult) {
        updateIntermediateResultMutation
          .mutateAsync({
            updateSmartIntermediateResultCommand: {
              code: newSmartOutputCode.code ?? getSmartOutputCodeById.smartOutputCodeById.code,
              description: newSmartOutputCode.description ?? getSmartOutputCodeById.smartOutputCodeById.description,
              numberOfDecimals: newSmartOutputCode.numberOfDecimals,
              smartOutputCodeId: getSmartOutputCodeById.smartOutputCodeById.id,
              smartPayComponentHistoryId: smartComponentHistoryId
            }
          })
          .then(() => {
            getSmartOutputCodeQuery.refetch()
            closeFlyIn()
            refetch()
          })
      } else {
        updateOutputCodeMutation
          .mutateAsync({
            updateSmartOutputCodeCommand: {
              numberOfDecimals: newSmartOutputCode.numberOfDecimals,
              smartOutputCodeId: getSmartOutputCodeById.smartOutputCodeById.id,
              smartPayComponentHistoryId: smartComponentHistoryId,
              payrollCodeId: newSmartOutputCode.payrollCodeId ?? '',
              valueTypePayComponentKey: newSmartOutputCode.valueTypePayComponentKey ?? ''
            }
          })
          .then(() => {
            getSmartOutputCodeQuery.refetch()
            closeFlyIn()
            refetch()
          })
      }
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addsmartoutputcode.title')}</Typography>
      <FormGridLayout>
        <FormSwitch
          sx={12}
          name="isOutputCode"
          label={t('form.field.isoutputcode')}
          disabled={!!getSmartOutputCodeById}
        />

        {watchedIsOutputCode && (
          <>
            <FormSelect
              sx={12}
              name="payrollCodeId"
              label={`${t('form.field.payrollcode')} *`}
              options={getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
                p => new FormSelectOption(p.id, `${p.code} -  ${p.userFriendlyDescription ?? p.description}`)
              )}
            />

            <FormSelect
              sx={12}
              name="valueTypePayComponentKey"
              label={`${t('form.field.valuetype')} *`}
              options={allActiveValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId.map(
                v => new FormSelectOption(v.key, v.value)
              )}
            />
          </>
        )}

        {!watchedIsOutputCode && (
          <>
            <FormInput sx={12} name="code" label={`${t('form.field.code')} *`} />

            <FormInput sx={12} name="description" label={`${t('form.field.description')} *`} />
          </>
        )}

        <FormNumericInput
          sx={12}
          name="numberOfDecimals"
          label={t('smartoutputcodesettingspage.field.numberofdecimals')}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={
          createMutation.isPending || updateIntermediateResultMutation.isPending || updateOutputCodeMutation.isPending
        }
        onCancel={closeFlyIn}
      />
    </FormContainer>
  )
}

export default AddEditSmartOutputCode
