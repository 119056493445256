import {
  FormSelectOption,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetAsecContractDetailByContractIdQuery,
  useUpdateAsecContractTypeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface EditAsecContractTypeProps {
  contractId: string
}

export function EditAsecContractType({ contractId }: EditAsecContractTypeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: asecContractDetail, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetAsecContractDetailByContractIdQuery({
      contractId: contractId || ''
    })

  const { data: employmentStatusesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.ES_EMPLOYMENT_STATUS.key,
    limit: -1,
    offset: 0
  })

  const { data: employmentTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.ES_EMPLOYMENT_TYPE.key,
    limit: -1,
    offset: 0
  })

  const updateMutation = useUpdateAsecContractTypeMutation()

  const employmentStatuses = employmentStatusesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    x => new FormSelectOption(x.id, x.value)
  )
  const employmentTypes = employmentTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    x => new FormSelectOption(x.id, x.value)
  )

  const editAsecContractTypeSchema = object({
    employmentStatusId: string().nullable().optional(),
    employmentTypeId: string().nullable().optional()
  })

  type EditAsecContractTypeForm = TypeOf<typeof editAsecContractTypeSchema>

  const form = useForm<EditAsecContractTypeForm>({
    resolver: zodResolver(editAsecContractTypeSchema),
    defaultValues: {
      employmentStatusId: asecContractDetail?.asecContractDetailByContractId.employmentStatus?.id,
      employmentTypeId: asecContractDetail?.asecContractDetailByContractId.employmentType?.id
    }
  })

  const handleOnSubmit = async (asecContractType: EditAsecContractTypeForm) => {
    await updateMutation
      .mutateAsync({
        updateAsecContractTypeCommand: {
          id: asecContractDetail?.asecContractDetailByContractId.id || '',
          employmentStatusId: asecContractType.employmentStatusId,
          employmentTypeId: asecContractType.employmentTypeId
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editaseccontracttype.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="employmentStatusId"
          label={t('form.field.employmentstatus')}
          options={employmentStatuses}
        />
      </FormGridLayout>

      <FormGridLayout>
        <FormSelect sx={12} name="employmentTypeId" label={t('form.field.employmenttype')} options={employmentTypes} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isPending} />
    </FormContainer>
  )
}

export default EditAsecContractType
