import {
  byteArrayToFile,
  GetReportRunsForUserQuery,
  ReportRunStatus,
  useDownloadReportRunQuery,
  useGetPagedReportDefinitionsQuery,
  useGetReportRunsForUserQuery,
  useNameof
} from '@epix-web-apps/core'
import { a11yProps, DownloadButton, routes, TabPanel, useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import { Add, CancelOutlined, CheckCircleOutlined, PlayArrow, ScheduleOutlined, Sync } from '@mui/icons-material'
import { Box, Button, IconButton, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { UseQueryResult } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import { MouseEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable } from '../../components/data-table'
import {
  AddReportFlyin,
  DeleteReportFlyin
} from '../../components/reporting-assistant-components/add-edit-report-flyin'
import { RunReportFlyin } from '../../components/reporting-assistant-components/run-report-flyin'

export enum ReportingAssistantPageTabs {
  DEFINITION,
  RUNS
}

const tabOptions = [routes.analytics.REPORTING_DEFINITIONS, routes.analytics.REPORTING_RUNS]

export function ReportingAssistantPage({ tabIndex }: { tabIndex: ReportingAssistantPageTabs }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const reportRunQuery = useGetReportRunsForUserQuery()

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h2">{t('reportinganalyticspage.title')}</Typography>

        <Box sx={{ display: 'flex', gap: 1 }}>
          {tabIndex === ReportingAssistantPageTabs.RUNS && (
            <Button
              variant="outlined"
              startIcon={<Sync />}
              onClick={() => reportRunQuery.refetch()}
              disabled={reportRunQuery.isRefetching}
            >
              {t('reportinganalyticspage.runs.refresh')}
            </Button>
          )}

          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() =>
              openFlyIn({
                content: <AddReportFlyin />
              })
            }
          >
            {t('reportinganalyticspage.definitions.addreport')}
          </Button>
        </Box>
      </Box>

      <Box>
        <Tabs value={tabIndex} onChange={(_, index) => navigate(tabOptions[index])}>
          <Tab
            label={t('reportinganalyticspage.definitions.title')}
            {...a11yProps(ReportingAssistantPageTabs.DEFINITION)}
          />
          <Tab label={t('reportinganalyticspage.runs.title')} {...a11yProps(ReportingAssistantPageTabs.RUNS)} />
        </Tabs>

        <TabPanel value={tabIndex} index={ReportingAssistantPageTabs.DEFINITION} padding="0">
          <ReportDefinitionsTab />
        </TabPanel>
        <TabPanel value={tabIndex} index={ReportingAssistantPageTabs.RUNS} padding="0">
          <ReportRunsTab reportRunQuery={reportRunQuery} />
        </TabPanel>
      </Box>
    </Box>
  )
}

type ReportDefinitionTableRow = {
  id: string
  reportName: string
  rootEntity: string
  ownerId: string
  owner: string
  access: string
}

function ReportDefinitionsTab() {
  const { t } = useTranslation()
  const { nameof } = useNameof<ReportDefinitionTableRow>()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const { me } = useGlobalStore()

  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const reportDefinitionQuery = useGetPagedReportDefinitionsQuery({
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const columns: GridColDef<ReportDefinitionTableRow>[] = [
    {
      field: nameof('reportName'),
      headerName: t('reportinganalyticspage.definitions.table.reportname'),
      sortable: false,
      flex: 2
    },
    {
      field: nameof('rootEntity'),
      headerName: t('reportinganalyticspage.definitions.table.rootentity'),
      sortable: false,
      flex: 2
    },
    {
      field: nameof('owner'),
      headerName: t('reportinganalyticspage.definitions.table.owner'),
      sortable: false,
      flex: 2
    },
    // {
    //   field: nameof('access'),
    //   headerName: t('reportinganalyticspage.definitions.table.access'),
    //   sortable: false,
    //   flex: 1
    // },
    {
      field: 'runReport',
      headerName: '',
      sortable: false,
      renderCell: params => <RunReportCell row={params.row} />
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      sortable: false,
      // renderCell: params => <RowActions row={params.row} />
      getActions: params => [
        <GridActionsCellItem
          showInMenu
          label={t('reportinganalyticspage.definitions.runreport')}
          onClick={() => handleReportRun(params.row.id)}
        />,
        <GridActionsCellItem
          showInMenu
          label={t('reportinganalyticspage.definitions.manage')}
          onClick={() => handleNavigate(params.row.id)}
          disabled={me.id !== params.row.ownerId}
        />,
        <GridActionsCellItem showInMenu label={t('reportinganalyticspage.definitions.duplicate')} disabled />,
        <GridActionsCellItem showInMenu label={t('common.delete')} onClick={() => handleDelete(params.row.id)} />
      ]
    }
  ]

  function handleNavigate(id: string) {
    navigate(routes.analytics.REPORTING_RUNS_ID(id))
  }

  function handleDelete(id: string) {
    const report = reportDefinitionQuery.data?.pagedReportDefinitions.data.find(r => r.id === id)
    if (!report) {
      return
    }
    openFlyIn({
      content: <DeleteReportFlyin report={report} />,
      callbackAfterClose: () => reportDefinitionQuery.refetch
    })
  }

  function handleReportRun(id: string) {
    openFlyIn({
      content: <RunReportFlyin reportId={id} />
    })
  }

  function RunReportCell({ row }: { row: ReportDefinitionTableRow }) {
    return (
      <DataTableCell sx={{ justifyContent: 'center' }}>
        <IconButton
          onClick={e => {
            e.stopPropagation()
            handleReportRun(row.id)
          }}
        >
          <PlayArrow color="success" />
        </IconButton>
      </DataTableCell>
    )
  }

  return (
    <ScrollableDataTable
      // TODO FIX IN DATATABLE
      vHeightOffset="18rem"
      columns={columns}
      onRowClick={(params: GridRowParams<ReportDefinitionTableRow>) => {
        navigate(routes.analytics.REPORTING_DEFINITIONS_ID(params.row.id))
      }}
      totalRowCount={reportDefinitionQuery.data?.pagedReportDefinitions.totalCount}
      paginationModel={paginationModel}
      onPaginationModelChange={model => setPaginationModel(model)}
      isLoading={reportDefinitionQuery.isPending}
      data={reportDefinitionQuery.data?.pagedReportDefinitions.data.map(
        r =>
          ({
            id: r.id,
            reportName: r.name,
            rootEntity: r.reportStartPoint.value,
            ownerId: r.reportOwnerId,
            owner: `${r.reportOwnerName} (${r.reportOwnerEmail})`,
            access: r.access
          } as ReportDefinitionTableRow)
      )}
    />
  )
}

type ReportRunTableRow = {
  id: string
  dateRun: Date
  reportRunName: string
  reportDefinitionName: string
  startPoint: string
  status: ReportRunStatus
}

function ReportRunsTab({ reportRunQuery }: { reportRunQuery: UseQueryResult<GetReportRunsForUserQuery, unknown> }) {
  const { t } = useTranslation()
  const { nameof } = useNameof<ReportRunTableRow>()
  const navigate = useNavigate()

  useEffect(() => {
    reportRunQuery.refetch()
  }, [])

  const columns: GridColDef<ReportRunTableRow>[] = [
    {
      field: nameof('status'),
      headerName: '',
      sortable: false,
      renderCell: params => <ReportRunStatusCell row={params.row} />
    },
    {
      field: nameof('dateRun'),
      headerName: t('reportinganalyticspage.runs.table.daterun'),
      type: 'dateTime',
      sortable: false,
      flex: 1
    },
    {
      field: nameof('reportRunName'),
      headerName: t('reportinganalyticspage.runs.table.name'),
      sortable: false,
      flex: 1
    },
    {
      field: nameof('reportDefinitionName'),
      headerName: t('reportinganalyticspage.runs.table.reportdefinition'),
      sortable: false,
      flex: 1
    },
    {
      field: nameof('startPoint'),
      headerName: t('reportinganalyticspage.runs.table.startpoint'),
      sortable: false,
      flex: 1
    },
    {
      field: 'csv',
      headerName: '',
      sortable: false,
      renderCell: params => params.row.status === ReportRunStatus.Success && <DownloadCell reportRunRow={params.row} />
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: _ => [
        <GridActionsCellItem showInMenu label={t('reportinganalyticspage.runs.table.showdate')} disabled />,
        <GridActionsCellItem showInMenu label={t('common.delete')} disabled />
      ]
    }
  ]
  return (
    <ScrollableDataTable
      vHeightOffset="18rem"
      hideFooter
      columns={columns}
      isLoading={reportRunQuery.isPending}
      onRowClick={(params: GridRowParams<ReportRunTableRow>) =>
        navigate(routes.analytics.REPORTING_RUNS_ID(params.row.id))
      }
      data={reportRunQuery.data?.reportRunsForUser.map(rr => {
        return {
          id: rr.id,
          dateRun: parseISO(rr.runDate),
          reportDefinitionName: rr.reportDefinitionName,
          reportRunName: rr.reportRunName,
          startPoint: rr.reportStartPointName,
          status: rr.status
        } as ReportRunTableRow
      })}
    />
  )
}

function ReportRunStatusCell({ row }: { row: ReportRunTableRow }) {
  const theme = useTheme()
  return (
    <DataTableCell sx={{ justifyContent: 'center' }}>
      {row.status === ReportRunStatus.Queued && <ScheduleOutlined sx={{ color: theme.palette.primary.main }} />}
      {row.status === ReportRunStatus.Running && <Sync sx={{ color: theme.palette.primary.main }} />}
      {row.status === ReportRunStatus.Success && <CheckCircleOutlined sx={{ color: theme.palette.success.main }} />}
      {row.status === ReportRunStatus.Failed && <CancelOutlined sx={{ color: theme.palette.error.main }} />}
    </DataTableCell>
  )
}

function DownloadCell({ reportRunRow }: { reportRunRow: ReportRunTableRow }) {
  const { refetch, isFetching } = useDownloadReportRunQuery({ id: reportRunRow.id }, { enabled: false })

  function downloadFile(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    refetch().then(r =>
      byteArrayToFile(
        r.data?.downloadReportRun as unknown as Uint8Array,
        `${format(reportRunRow.dateRun, 'yyyy-MM-dd')}_${reportRunRow.reportRunName}.csv`,
        'csv'
      )
    )
  }

  return (
    <DataTableCell sx={{ justifyContent: 'center' }}>
      <DownloadButton onClick={e => downloadFile(e)} isFetching={isFetching} />
    </DataTableCell>
  )
}
