import styled from '@emotion/styled'
import { DOCUMENT_CONTENT_TYPES } from '@epix-web-apps/core'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  GridSize,
  IconButton,
  Typography,
  useTheme
} from '@mui/material'
import { useCallback, useRef, useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Document } from '../../document'
import { OptionalGridWrapper } from '../form-grid-wrapper'

export const HiddenInput = styled.input`
  display: none;
`

export enum ACCEPTED_UPLOAD_TYPES {
  CSV = DOCUMENT_CONTENT_TYPES.CSV,
  JPEG = DOCUMENT_CONTENT_TYPES.JPEG,
  GIF = DOCUMENT_CONTENT_TYPES.GIF,
  PDF = DOCUMENT_CONTENT_TYPES.PDF,
  PNG = DOCUMENT_CONTENT_TYPES.PNG,
  EXCEL = DOCUMENT_CONTENT_TYPES.EXCEL,
  XML = DOCUMENT_CONTENT_TYPES.XML
}

const MAX_FILESIZE = 31457280 //30 MiB
export const ZodFileArray = (acceptedTypes: ACCEPTED_UPLOAD_TYPES[], required = false) => {
  const { t } = useTranslation()
  return z
    .custom<{ file: File }>()
    .array()
    .refine(documents => required && documents && documents?.length !== 0, t('form.validation.documentrequired'))
    .refine(
      documents => documents && documents?.some(f => f.file.size <= MAX_FILESIZE),
      t('form.validation.maxfilesizeexceeded')
    )
    .refine(
      documents => documents && documents?.some(f => acceptedTypes.includes(f?.file.type as never)),
      t('form.validation.invalidfileformat')
    )
}

export function FormFileUpload({
  name,
  buttonText,
  accept,
  sx,
  showIcon = false,
  multipleFiles = true,
  deleteDocument,
  documentsAdded
}: {
  name: string
  buttonText: string
  accept: Array<ACCEPTED_UPLOAD_TYPES>
  sx?: GridSize
  showIcon?: boolean
  multipleFiles?: boolean
  deleteDocument?: (documentId: string) => Promise<any>
  documentsAdded?: (documents: Array<{ file: File }>) => void
}) {
  const form = useFormContext()
  const control = form.control
  const hiddenFileInputRef = useRef<HTMLInputElement>(null)

  const theme = useTheme()

  const { fields, append, remove, replace } = useFieldArray({
    keyName: 'fieldId',
    control,
    name: name
  })

  const [clickedDocumentId, setClickedDocumentId] = useState<string | null>(null)

  function handleDelete(documentId: string | null, documentListIndex: number) {
    if (documentId === null) {
      remove(documentListIndex)
      setClickedDocumentId(null)
    } else {
      deleteDocument &&
        deleteDocument(documentId)
          .then(_r => {
            setClickedDocumentId(null)
          })
          .catch(_e => {
            setClickedDocumentId(null)
          })
          .finally(() => {
            remove(documentListIndex)
          })
    }
  }

  function handleUpload(files: FileList | null) {
    if (files) {
      const filesToUpload = Array.from(files)
        .filter(file => fields.length === 0 || fields.every((f: any) => f.file?.name !== file.name))
        .map(file => ({
          file
        }))

      if (multipleFiles) {
        append(filesToUpload)
      } else {
        replace(filesToUpload)
      }
      documentsAdded && documentsAdded(filesToUpload)
    }
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.value = ''
    }
  }

  const clickHiddenInput = useCallback(() => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click()
    }
  }, [])

  return (
    <OptionalGridWrapper sx={sx}>
      <FormControl fullWidth size="small">
        <Box>
          <Button
            startIcon={showIcon ? <NoteAddOutlinedIcon /> : null}
            variant="outlined"
            size="small"
            onClick={clickHiddenInput}
          >
            {buttonText}
            <HiddenInput
              ref={hiddenFileInputRef}
              type="file"
              accept={accept.join(',')}
              multiple={multipleFiles}
              onChange={e => handleUpload(e.target.files)}
            />
          </Button>
        </Box>

        {fields.map((field: any, index: any) => (
          <Box key={field.id ?? field.fieldId} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Controller
              {...form.register}
              name={`${name}.${index}`}
              render={() => (
                <Grid container alignItems="center">
                  <Grid item xs={10}>
                    {field.name && <Document document={field} disabled={clickedDocumentId !== null} />}
                    {field.file && <Typography>{field.file?.name}</Typography>}
                  </Grid>
                  <Grid item xs={2}>
                    {clickedDocumentId && field.id === clickedDocumentId && <CircularProgress size="1.5rem" />}
                    {(!clickedDocumentId || field.id !== clickedDocumentId) && (
                      <IconButton
                        aria-label="Remove"
                        size="small"
                        onClick={() => {
                          setClickedDocumentId(field.id ?? null)
                          handleDelete(field.id ?? null, index)
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
            />
          </Box>
        ))}
      </FormControl>
    </OptionalGridWrapper>
  )
}

export default FormFileUpload
