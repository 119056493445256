import {
  FormSelectOption,
  useNavigateWithParams,
  useSuspenseGetAllTeamsWithRoleForMeQuery,
  useSuspenseGetTeamsForMeForTeamStructureQuery
} from '@epix-web-apps/core'
import { FilterSelectBox, useRouteDefinitions } from '@epix-web-apps/ui'
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { TeamCalendarPathParams } from '../../../pages/team-calendar'

/* eslint-disable-next-line */
export interface TeamCalendarFilterProps {}

export function TeamCalendarFilter(props: TeamCalendarFilterProps) {
  const { t } = useTranslation()
  const params = useParams<TeamCalendarPathParams>()
  const theme = useTheme()
  const routes = useRouteDefinitions()
  const navigate = useNavigateWithParams()

  const { data: getTeamsByPersonId } = useSuspenseGetTeamsForMeForTeamStructureQuery()

  const { data: getAllTeamsWithRole } = useSuspenseGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure || []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe || []

  const allTeams = teamsByPersonId.concat(teamWithRole).filter((team, index, self) => {
    return self.findIndex(t => t.id === team.id) === index
  })

  const [currentTeam, setCurrentTeam] = useState(allTeams.find(x => x.id === params.teamId)?.id)

  return (
    <Box sx={{ minWidth: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('flyin.team-calendar.team.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          minWidth: '100%',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <FilterSelectBox
          id="teamId"
          label={t('flyin.team-calendar.team.team')}
          keys={allTeams?.map(x => new FormSelectOption(x.id, x.name)) || []}
          value={currentTeam}
          handleChange={(e: string) => {
            setCurrentTeam(e)
            navigate(routes.SelfService.TEAMS_ID_CALENDAR(e))
          }}
        />
      </Box>

      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          py: 2,
          zIndex: 10
        }}
      >
        <Button variant="outlined" onClick={() => navigate(routes.SelfService.TEAMS_ID_CALENDAR(params.teamId!))}>
          {t('flyin.team-calendar.team.button.cancel')}
        </Button>
      </Box>
    </Box>
  )
}

export default TeamCalendarFilter
