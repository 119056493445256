import moment from 'moment'
import { PayGroupPayPeriodModel, PAYPERIODTYPES } from '../services'
import {
  DateRange,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  IsDateRecurrenceRelatedToStartDate,
  IsSameDate
} from './date-helpers'
import { parseISO } from 'date-fns'

export const nextPeriodBasedOnPayGroup = (date: DateRange, payGroup: PayGroupPayPeriodModel) => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const currentDateMonth = date.startDate.getMonth()
      const currentYear = date.startDate.getFullYear()

      let nextMonth = currentDateMonth + 1
      let nextYear = currentYear
      if (nextMonth > 11) {
        nextMonth = 0
        nextYear++
      }
      const nextMonthStart = moment([nextYear, nextMonth]).startOf('month').toDate()
      const nextMonthEnd = moment([nextYear, nextMonth]).endOf('month').toDate()
      const newDates = {
        newStartDate: nextMonthStart,
        newEndDate: nextMonthEnd
      }
      return { startDate: newDates.newStartDate, endDate: newDates.newEndDate }
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const startDate = date.startDate
      const dayOfMonth = startDate.getDate()

      if (dayOfMonth <= 15) {
        // Next period is from the 16th to the end of the next month
        const nextPeriodStart = moment(startDate).date(16).toDate()
        const nextPeriodEnd = moment(startDate).endOf('month').toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      } else {
        // Next period is from the 1st to the 15th of the next month
        const nextPeriodStart = moment(startDate).add(1, 'months').date(1).toDate()
        const nextPeriodEnd = moment(startDate).add(1, 'months').date(15).toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      }
    }
    case PAYPERIODTYPES.WEEKLY: {
      const currentEndDate = date.endDate

      const weeks = payGroup.payPeriodNumberOfWeeks!

      const nextPeriodStart = moment(currentEndDate).add(1, 'days').toDate()
      const nextPeriodEnd = moment(nextPeriodStart)
        .add(weeks * 7 - 1, 'days')
        .toDate()

      return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
    }
  }
  return { startDate: new Date(), endDate: new Date() }
}

export const isValidPayPeriodDate = (payGroup: PayGroupPayPeriodModel | null, date: Date): boolean => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const firstDayOfMonth = getFirstDayOfMonth(date)
      return !IsSameDate(date, firstDayOfMonth)
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const firstDayOfMonth = getFirstDayOfMonth(date)
      const sixteenthDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 16)
      return !(IsSameDate(date, firstDayOfMonth) || IsSameDate(date, sixteenthDayOfMonth))
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (payGroup === null) {
        return false
      }

      return !IsDateRecurrenceRelatedToStartDate(date, payGroup!.firstClosure, payGroup!.payPeriodNumberOfWeeks!)
    }
    default:
      return true
  }
}

export const isValidPayPeriodEndDate = (payGroup: PayGroupPayPeriodModel | null, date: Date): boolean => {
  const firstClosure = moment(payGroup?.firstClosure).add(-1, 'days').toDate()

  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const firstDayOfMonth = getLastDayOfMonth(date)
      return !IsSameDate(date, firstDayOfMonth)
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const firstDayOfMonth = getLastDayOfMonth(date)
      const sixteenthDayOfMonth = new Date(firstDayOfMonth.getFullYear(), firstDayOfMonth.getMonth(), 15)
      return !(IsSameDate(date, firstDayOfMonth) || IsSameDate(date, sixteenthDayOfMonth))
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (payGroup === null) {
        return false
      } else {
        return !IsDateRecurrenceRelatedToStartDate(date, firstClosure, payGroup!.payPeriodNumberOfWeeks!)
      }
    }
    default:
      return true
  }
}

export const GetFirstAndLastDayOfPeriod = (payGroup: PayGroupPayPeriodModel | null, date: Date): DateRange => {
  switch (payGroup?.payPeriodType?.key) {
    case PAYPERIODTYPES.MONTHLY: {
      const startDate = getFirstDayOfMonth(date)
      const endDate = getLastDayOfMonth(date)
      return { startDate, endDate }
    }
    case PAYPERIODTYPES.BI_MONTHLY: {
      const startDate = date
      const dayOfMonth = startDate.getDate()

      if (dayOfMonth <= 15) {
        const nextPeriodStart = moment(startDate).date(1).toDate()
        const nextPeriodEnd = moment(startDate).date(15).toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      } else {
        const nextPeriodStart = moment(startDate).date(16).toDate()
        const nextPeriodEnd = moment(startDate).endOf('month').toDate()
        return { startDate: nextPeriodStart, endDate: nextPeriodEnd }
      }
    }
    case PAYPERIODTYPES.WEEKLY: {
      if (!payGroup || payGroup.payPeriodNumberOfWeeks === undefined || !payGroup.firstClosure) {
        throw new Error('Invalid pay group configuration.')
      }

      const numberOfWeeks = payGroup.payPeriodNumberOfWeeks!
      const firstClosureDate = parseISO(payGroup.firstClosure)
      const currentDate = date

      const periodLengthInDays = numberOfWeeks * 7

      const periodsSinceClosure = Math.floor(
        (currentDate.getTime() - firstClosureDate.getTime()) / (periodLengthInDays * 24 * 60 * 60 * 1000)
      )

      const startDate = new Date(firstClosureDate)
      startDate.setDate(startDate.getDate() + periodsSinceClosure * periodLengthInDays)

      const endDate = new Date(startDate)
      endDate.setDate(startDate.getDate() + periodLengthInDays - 1)

      return { startDate, endDate }
    }
  }
  return { startDate: new Date(), endDate: new Date() }
}
