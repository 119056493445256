import {
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetAllContractDocumentTypesByEmployerIdQuery,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetContractDocumentTypeByIdQuery
} from '@epix-web-apps/core'
import { People } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { ContractFolderDocumentList, FolderColumns, FolderList } from '../../document-components'
import { ContractTabs } from '../contract-tab-general/contract-tab-general'

/* eslint-disable-next-line */
export interface ContractsDocumentTabProps {
  contractId: string
}

export interface ContractsDocumentTabTypeListProps extends ContractsDocumentTabProps {
  documentTypeId: string
}

export type ContractDocumentTypeDetailPageParams = ContractDetailPageParams & {
  documenttypeid?: string
}

export function ContractsDocumentTab({ contractId }: ContractsDocumentTabProps) {
  const params = useRequiredParams<ContractDocumentTypeDetailPageParams>()
  if (params.documenttypeid) {
    return <ContractsDocumentTabTypeList contractId={contractId} documentTypeId={params.documenttypeid} />
  }
  return <ContractsDocumentTabOverview contractId={contractId} />
}

export function ContractsDocumentTabOverview({ contractId }: ContractsDocumentTabProps) {
  const { t } = useTranslation()

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })
  const { data: getAllContractDocumentTypes } = useSuspenseGetAllContractDocumentTypesByEmployerIdQuery({
    // contract will never have a null employerId --> incorrect model in backend
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: getContractById.contractById.employerId!,
    activeOnly: true
  })

  return (
    <FolderList
      hideColumns={[FolderColumns.ISACTIVE]}
      employerId={getContractById.contractById.employerId ?? ''}
      folders={getAllContractDocumentTypes?.allContractDocumentTypesByEmployerId || []}
      title={t('contractdetailpage.documents.title')}
      goToDocumentTypeDetailPage={documentTypeId =>
        People.PEOPLE_ID_CONTRACTS_ID_DOCUMENTTYPE_ID(
          getContractById.contractById.personId,
          contractId || '',
          documentTypeId
        )
      }
    />
  )
}

export function ContractsDocumentTabTypeList({ contractId, documentTypeId }: ContractsDocumentTabTypeListProps) {
  const { t } = useTranslation()
  const navigate = useNavigateWithParams()
  const params = useRequiredParams<ContractDocumentTypeDetailPageParams>()

  const handleTitleClick = (personId: string, contractId: string) => {
    navigate(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
      tabIndex: ContractTabs.DOCUMENTS.toString()
    })
  }

  const { data: getDocumentTypeById } = useSuspenseGetContractDocumentTypeByIdQuery({
    id: documentTypeId
  })

  return (
    <ContractFolderDocumentList
      documentTypeId={documentTypeId}
      onTitleClick={() => handleTitleClick(params?.id || '', params?.contractid || '')}
      subTitle={`> ${getDocumentTypeById?.contractDocumentTypeById.name}`}
      title={`${t('contractdetailpage.documents.title')}`}
      contractId={contractId}
    />
  )
}

export default ContractsDocumentTab
