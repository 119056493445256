import {
  FormSelectOption,
  GraphqlError,
  ReportDefinitionAccess,
  ReportDefinitionDateSelection,
  ReportDefinitionDetailModel,
  ReportDefinitionModel,
  ReportDefinitionPeriodSelection,
  ToBackendFormatedDate,
  useCreateReportDefinitionMutation,
  useDeleteReportDefinitionMutation,
  useSuspenseGetAllReportStartPointsQuery,
  useUpdateReportDefinitionMutation
} from '@epix-web-apps/core'
import {
  FLYIN_WIDTH,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  FormRadioOption,
  FormSelect,
  routes,
  useFlyIn,
  useGlobalStore
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export function AddReportFlyin() {
  return <AddEditReportFlyin />
}

export function EditReportFlyin({ report }: { report: ReportDefinitionDetailModel }) {
  return <AddEditReportFlyin report={report} />
}

function AddEditReportFlyin({ report }: { report?: ReportDefinitionDetailModel }) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const { data } = useSuspenseGetAllReportStartPointsQuery()

  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const create = !report
  const reportStartPoint = data.allReportStartPoints.find(r => r.key === report?.reportStartPoint.key)

  const reportSchema = z
    .object({
      name: z.string(),
      access: z.nativeEnum(ReportDefinitionAccess),
      reportStartPoint: z.string(),
      populationDateSelection: z.nativeEnum(ReportDefinitionDateSelection).optional(),
      populationDate: z.date().optional().nullable(),
      historyDateSelection: z.nativeEnum(ReportDefinitionDateSelection).optional(),
      historyDate: z.date().optional().nullable(),
      periodDateSelection: z.nativeEnum(ReportDefinitionPeriodSelection).optional(),
      periodStartDate: z.date().optional().nullable(),
      periodEndDate: z.date().optional().nullable(),
      allProviders: z.boolean().optional().nullable()
    })
    .refine(
      schema => {
        if (schema.populationDateSelection === ReportDefinitionDateSelection.Date && schema.populationDate == null) {
          return false
        }
        return true
      },
      { message: t('reportinganalyticsdetailpage.general.flyin.populationdaterequired'), path: ['populationDate'] }
    )
    .refine(
      schema => {
        if (schema.historyDateSelection === ReportDefinitionDateSelection.Date && schema.historyDate == null) {
          return false
        }
        return true
      },
      { message: t('reportinganalyticsdetailpage.general.flyin.historydaterequired'), path: ['historyDate'] }
    )
    .refine(
      schema => {
        if (
          schema.periodDateSelection === ReportDefinitionPeriodSelection.Period &&
          (schema.periodStartDate == null || schema.periodEndDate == null)
        ) {
          return false
        }
        return true
      },
      { message: t('reportinganalyticsdetailpage.general.flyin.perioddatesrequired'), path: ['periodDateSelection'] }
    )

  type ReportForm = z.TypeOf<typeof reportSchema>

  const form = useForm<ReportForm>({
    resolver: zodResolver(reportSchema),
    defaultValues: {
      name: report?.name,
      access: ReportDefinitionAccess.Owner,
      reportStartPoint: report?.reportStartPoint.key,
      populationDateSelection: report?.selectedPopulation,
      populationDate: report?.populationViewDate ? parseISO(report?.populationViewDate) : null,
      historyDateSelection: report?.selectedHistory,
      historyDate: report?.historyViewDate ? parseISO(report?.historyViewDate) : null,
      periodDateSelection: report?.selectedPeriod,
      periodStartDate: report?.periodStartDate ? parseISO(report?.periodStartDate) : null,
      periodEndDate: report?.periodEndDate ? parseISO(report?.periodEndDate) : null,
      allProviders: report?.selectAllProviders
    }
  })

  const createMutation = useCreateReportDefinitionMutation()
  const updateMutation = useUpdateReportDefinitionMutation()
  function handleOnSubmit(form: ReportForm) {
    if (create) {
      createMutation
        .mutateAsync({
          createReportDefinitionCommand: {
            name: form.name,
            reportAccess: ReportDefinitionAccess.Owner,
            reportStartingPointKey: form.reportStartPoint
          }
        })
        .then(r => {
          closeFlyIn()
          navigate(routes.analytics.REPORTING_DEFINITIONS_ID(r.createReportDefinition))
        })
        .catch(e => {
          setBackendErrors([e])
        })
    } else {
      updateMutation
        .mutateAsync({
          updateReportDefinitionCommand: {
            id: report.id,
            name: form.name,
            access: ReportDefinitionAccess.Owner,
            populationDateSelection: form.populationDateSelection ?? ReportDefinitionDateSelection.None,
            populationDate: form.populationDate ? ToBackendFormatedDate(form.populationDate) : null,
            historyDateSelection: form.historyDateSelection ?? ReportDefinitionDateSelection.None,
            historyDate: form.historyDate ? ToBackendFormatedDate(form.historyDate) : null,
            periodSelection: form.periodDateSelection ?? ReportDefinitionPeriodSelection.CurrentMonth,
            periodStartDate: form.periodStartDate,
            periodEndDate: form.periodEndDate,
            selectAllProviders: form.allProviders ?? true
          }
        })
        .then(_ => {
          closeFlyIn()
        })
        .catch(e => {
          setBackendErrors([e])
        })
    }
  }

  const dateSelectionOrder = {
    [ReportDefinitionDateSelection.None]: 0,
    [ReportDefinitionDateSelection.Date]: 1,
    [ReportDefinitionDateSelection.All]: 2
  }

  const populationOptions = [
    {
      key: ReportDefinitionDateSelection.None,
      value: t('reportinganalyticsdetailpage.general.flyin.populationselection.today'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.None] <=
        dateSelectionOrder[reportStartPoint?.contractSelection ?? ReportDefinitionDateSelection.None]
    },
    {
      key: ReportDefinitionDateSelection.Date,
      value: t('reportinganalyticsdetailpage.general.flyin.populationselection.date'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.Date] <=
        dateSelectionOrder[reportStartPoint?.contractSelection ?? ReportDefinitionDateSelection.None]
    },
    {
      key: ReportDefinitionDateSelection.All,
      value: t('reportinganalyticsdetailpage.general.flyin.populationselection.all'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.All] <=
        dateSelectionOrder[reportStartPoint?.contractSelection ?? ReportDefinitionDateSelection.None]
    }
  ].map(option => new FormRadioOption(option.key, option.value, !option.selectable))

  const historyOptions = [
    {
      key: ReportDefinitionDateSelection.None,
      value: t('reportinganalyticsdetailpage.general.flyin.historydateselection.today'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.None] <=
        dateSelectionOrder[reportStartPoint?.historySelection ?? ReportDefinitionDateSelection.None]
    },
    {
      key: ReportDefinitionDateSelection.Date,
      value: t('reportinganalyticsdetailpage.general.flyin.historydateselection.date'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.Date] <=
        dateSelectionOrder[reportStartPoint?.historySelection ?? ReportDefinitionDateSelection.None]
    },
    {
      key: ReportDefinitionDateSelection.All,
      value: t('reportinganalyticsdetailpage.general.flyin.historydateselection.all'),
      selectable:
        dateSelectionOrder[ReportDefinitionDateSelection.All] <=
        dateSelectionOrder[reportStartPoint?.historySelection ?? ReportDefinitionDateSelection.None]
    }
  ].map(option => new FormRadioOption(option.key, option.value, !option.selectable))

  const periodOptions = [
    {
      key: ReportDefinitionPeriodSelection.CurrentMonth,
      value: t('reportinganalyticsdetailpage.general.flyin.periodselection.currentmonth')
    },
    {
      key: ReportDefinitionPeriodSelection.Period,
      value: t('reportinganalyticsdetailpage.general.flyin.periodselection.period')
    }
  ].map(option => new FormRadioOption(option.key, option.value))

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {!report && <Typography variant="h4">{t('reportinganalyticspage.definitions.flyin.title')}</Typography>}
      {report && <Typography variant="h4">{t('reportinganalyticsdetailpage.general.flyin.title')}</Typography>}

      <FormGridLayout>
        <FormInput name="name" label={t('reportinganalyticspage.definitions.flyin.reportname')} sx={12} />

        {/* <FormRadioGroup
          sx={12}
          options={[
            new FormRadioOption(ReportDefinitionAccess.Owner, t('reportinganalyticspage.definitions.flyin.access.me')),
            new FormRadioOption(
              ReportDefinitionAccess.All,
              t('reportinganalyticspage.definitions.flyin.access.allepixusers')
            )
          ]}
          name={'access'}
          label={t('reportinganalyticspage.definitions.flyin.access.label')}
        /> */}

        <FormSelect
          disabled={!!report}
          options={data.allReportStartPoints.map(r => new FormSelectOption(r.key, r.value))}
          name={'reportStartPoint'}
          label={t('reportinganalyticspage.flyin.entityroot')}
          sx={12}
        />

        {!create && (
          <>
            <FormRadioGroup
              name="populationDateSelection"
              label={t('reportinganalyticsdetailpage.general.flyin.populationselection.label')}
              options={populationOptions}
              sx={12}
            />

            {form.watch('populationDateSelection') === ReportDefinitionDateSelection.Date && (
              <FormDatepicker
                name="populationDate"
                label={t('reportinganalyticsdetailpage.general.flyin.date')}
                sx={12}
              />
            )}

            <FormRadioGroup
              name="historyDateSelection"
              label={t('reportinganalyticsdetailpage.general.flyin.historydateselection.label')}
              options={historyOptions}
              sx={12}
            />

            {form.watch('historyDateSelection') === ReportDefinitionDateSelection.Date && (
              <FormDatepicker name="historyDate" label={t('reportinganalyticsdetailpage.general.flyin.date')} sx={12} />
            )}

            {reportStartPoint?.periodSelection === ReportDefinitionPeriodSelection.Period && (
              <>
                <FormRadioGroup
                  name="periodDateSelection"
                  label={t('reportinganalyticsdetailpage.general.flyin.periodselection.label')}
                  options={periodOptions}
                  sx={12}
                />

                {form.watch('periodDateSelection') === ReportDefinitionPeriodSelection.Period && (
                  <>
                    <FormDatepicker
                      name="periodStartDate"
                      label={t('reportinganalyticsdetailpage.general.flyin.startdate')}
                      sx={6}
                    />
                    <FormDatepicker
                      name="periodEndDate"
                      label={t('reportinganalyticsdetailpage.general.flyin.enddate')}
                      sx={6}
                    />
                  </>
                )}
              </>
            )}

            <FormSwitch
              name="allProviders"
              label={t('reportinganalyticsdetailpage.general.flyin.allproviders')}
              sx={12}
            />
          </>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isPending || updateMutation.isPending} onCancel={closeFlyIn} />
    </FormContainer>
  )
}

export function DeleteReportFlyin({ report }: { report: ReportDefinitionModel }) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { me } = useGlobalStore()

  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const isReportOwner = me.id === report.reportOwnerId

  const mutation = useDeleteReportDefinitionMutation()

  function handleDelete() {
    if (me.id !== report.reportOwnerId) {
      return
    }

    mutation
      .mutateAsync({ deleteReportDefinitionCommand: { id: report.id } })
      .then(_ => closeFlyIn())
      .catch(e => setBackendErrors([e]))
  }
  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      <Typography variant="h4">{t('reportinganalyticspage.definitions.flyin.delete.title')}</Typography>
      {!isReportOwner && <Typography>{t('reportinganalyticspage.definitions.flyin.delete.notallowed')}</Typography>}
      {isReportOwner && (
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>{t('reportinganalyticspage.definitions.flyin.delete.reportname')}</TableCell>
              <TableCell>
                <strong>{report.name}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('reportinganalyticspage.definitions.flyin.delete.rootentityname')}</TableCell>
              <TableCell>
                <strong>{report.reportStartPoint.value}</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('reportinganalyticspage.definitions.flyin.delete.reportaccess')}</TableCell>
              <TableCell>
                <strong>{report.access}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        buttonText={t('common.delete')}
        onClick={handleDelete}
        isMutating={mutation.isPending}
        disabled={!isReportOwner}
      />
    </Box>
  )
}
