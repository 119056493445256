import {
  GetEmployerDocumentTypeByIdQuery,
  useCreateEmployerDocumentTypeMutation,
  useSuspenseGetEmployerDocumentTypeByIdQuery,
  useRequiredParams,
  useUpdateEmployerDocumentTypeMutation
} from '@epix-web-apps/core'
import {
  EditTranslationZodArray,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormTranslations,
  useFlyIn,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { CompanyDocumentTypesDetailPageParams } from '../../../pages/company-documenttypes-page/company-documenttypes-page'
import { FormSwitch } from '../../form-components/form-switch'

export interface EditCompanyDocumenttypeProps {
  documentTypeId: string
}

export function AddCompanyDocumentType() {
  return AddEditCompanyDocumentType()
}

export function EditCompanyDocumentType({ documentTypeId }: EditCompanyDocumenttypeProps) {
  const getDocumentTypeQuery = useSuspenseGetEmployerDocumentTypeByIdQuery({
    id: documentTypeId
  })

  return AddEditCompanyDocumentType(getDocumentTypeQuery)
}

function AddEditCompanyDocumentType(
  getDocumentTypeQuery?: UseSuspenseQueryResult<GetEmployerDocumentTypeByIdQuery, unknown>
) {
  const { t } = useTranslation()

  const params = useRequiredParams<CompanyDocumentTypesDetailPageParams>()

  const addEditCompanyDocumentTypeSchema = object({
    active: boolean(),
    name: string({
      required_error: t('form.validation.namerequired'),
      invalid_type_error: t('form.validation.namerequired')
    }).min(1, t('form.validation.namerequired')),
    translations: EditTranslationZodArray()
  })

  const updateMutation = useUpdateEmployerDocumentTypeMutation()
  const createMutation = useCreateEmployerDocumentTypeMutation()

  const getDocumentType = getDocumentTypeQuery?.data

  const { closeFlyIn } = useFlyIn()

  type AddEditCompanyDocumentTypeForm = TypeOf<typeof addEditCompanyDocumentTypeSchema>

  const form = useForm<AddEditCompanyDocumentTypeForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      name: getDocumentType?.employerDocumentTypeById.nameDefaultValue,
      active: getDocumentType?.employerDocumentTypeById?.active,
      translations: getDocumentType?.employerDocumentTypeById?.nameTranslations
    },
    resolver: zodResolver(addEditCompanyDocumentTypeSchema)
  })

  const handleOnSubmit = async (addedEditedCompanyDocumentType: AddEditCompanyDocumentTypeForm) => {
    if (getDocumentType) {
      await updateMutation
        .mutateAsync({
          updateEmployerDocumentTypeCommand: {
            id: getDocumentType.employerDocumentTypeById.id,
            active: addedEditedCompanyDocumentType.active,
            name: addedEditedCompanyDocumentType.name,
            employeeAccessLevelTypeKey: getDocumentType?.employerDocumentTypeById?.employeeAccessLevel.key || '',
            translations: addedEditedCompanyDocumentType.translations
          }
        })
        .finally(() => {
          getDocumentTypeQuery.refetch()
          closeFlyIn()
        })
    } else {
      await createMutation
        .mutateAsync({
          createEmployerDocumentTypeCommand: {
            employerId: params.employerid,
            name: addedEditedCompanyDocumentType.name,
            active: addedEditedCompanyDocumentType.active,
            translations: addedEditedCompanyDocumentType.translations
          }
        })
        .finally(() => {
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {getDocumentType ? (
        <Typography variant="h4">{t('flyin.editcompanydocumenttype.edittitle')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.editcompanydocumenttype.addtitle')}</Typography>
      )}
      <FormGridLayout>
        <FormSwitch sx={12} name="active" label={t('form.field.active')} />
        <FormInput sx={12} name="name" label={`${t('form.field.name')} *`} />
      </FormGridLayout>
      <FormTranslations label={t('flyin.editcompanydocumenttype.foldername')} name={'translations'} />
      <FormErrorList />
      <FormActionButtons
        isMutating={updateMutation.isPending || createMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCompanyDocumentType
