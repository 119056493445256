import {
  DEFAULT_PAYPERIOD,
  FormSelectOption,
  formTypeSelectOptions,
  GetContractsByPayGroupIdAndEmployerIdQuery,
  GetPayGroupByIdQuery,
  GraphqlError,
  NUMBEROFWEEKS_PAYPERIOD,
  numericString,
  OrderDirection,
  PAYPERIODTYPES,
  typeToFormSelectOption,
  useCreatePayGroupMutation,
  useSuspenseGetCollectiveSchedulesByEmployerIdQuery,
  useSuspenseGetContractsByPayGroupIdAndEmployerIdQuery,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetPayGroupByIdQuery,
  useUpdatePayGroupMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddPaygroupProps {
  employerId: string
}

export interface EditPaygroupProps {
  employerId: string
  payGroupId: string
}

export function AddPayGroup({ employerId }: AddPaygroupProps) {
  return AddEditPaygroup(employerId)
}

export function EditPayGroup({ employerId, payGroupId }: EditPaygroupProps) {
  const getContractIdsQuery = useSuspenseGetContractsByPayGroupIdAndEmployerIdQuery({
    payGroupId: payGroupId,
    employerId: employerId,
    offset: 0,
    limit: 0
  })

  const getPayGroupByIdQuery = useSuspenseGetPayGroupByIdQuery({
    employerId: employerId,
    payGroupId: payGroupId
  })

  return AddEditPaygroup(employerId, payGroupId, getContractIdsQuery, getPayGroupByIdQuery)
}

export function AddEditPaygroup(
  employerId: string,
  payGroupId?: string,
  getContractIdsQuery?: UseSuspenseQueryResult<GetContractsByPayGroupIdAndEmployerIdQuery, unknown>,
  getPayGroupByIdQuery?: UseSuspenseQueryResult<GetPayGroupByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })

  const getContractIds = getContractIdsQuery?.data

  const hasContractIds = getContractIds && getContractIds.allContractsByPayGroupIdAndEmployerId.totalCount > 0

  const { data: collectiveSchedule } = useSuspenseGetCollectiveSchedulesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const addEditPayGroupSchema = object({
    name: string({
      required_error: t('form.validation.paygroupnamerequired'),
      invalid_type_error: t('form.validation.paygroupnamerequired')
    }).min(1, t('form.validation.paygroupnamerequired')),
    code: string({
      required_error: t('form.validation.paygroupcoderequired'),
      invalid_type_error: t('form.validation.paygroupcoderequired')
    }).min(1, t('form.validation.paygroupcoderequired')),
    firstClosure: date().optional().nullable(),
    collectiveSchedule: string().nullable().optional(),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCarManagement: boolean(),
    payPeriod: string({
      required_error: t('form.validation.payperiodtyperequired'),
      invalid_type_error: t('form.validation.payperiodtyperequired')
    }).min(1, t('form.validation.payperiodtyperequired')),
    payPeriodNumberOfWeeks: numericString(
      number()
        .gt(0, { message: t('form.validation.numberofweeksgreaterthan0') })
        .lt(5, { message: t('form.validation.numberofweekslessthan5') })
        .optional()
        .nullable()
    )
  })
    .refine(
      data =>
        getEmployerById?.employerById.calculatedByEpix
          ? data.firstClosure
          : date({
              required_error: t('form.validation.firstclosurerequired'),
              invalid_type_error: t('form.validation.firstclosurerequired')
            }),
      {
        message: t('form.validation.firstclosurerequired'),
        path: ['firstClosure']
      }
    )
    .refine(
      data =>
        data.payPeriod === PAYPERIODTYPES.WEEKLY
          ? data.payPeriodNumberOfWeeks
          : numericString(
              number({
                required_error: t('form.validation.numberofweeksrequired'),
                invalid_type_error: t('form.validation.numberofweeksrequired')
              })
                .gt(0, { message: t('form.validation.numberofweeksgreaterthan0') })
                .lt(5, { message: t('form.validation.numberofweekslessthan5') })
            ).optional(),
      {
        message: t('form.validation.numberofweeksrequired'),
        path: ['payPeriodNumberOfWeeks']
      }
    )
    .refine(
      data =>
        data.payPeriod === PAYPERIODTYPES.WEEKLY
          ? Number.isInteger(data.payPeriodNumberOfWeeks) && data.payPeriodNumberOfWeeks
          : true,
      {
        message: t('form.validation.numberofweeksmustbeaninteger'),
        path: ['payPeriodNumberOfWeeks']
      }
    )

  type CreateEditPayGroupForm = TypeOf<typeof addEditPayGroupSchema>

  const getPayGroupById = getPayGroupByIdQuery?.data

  const defaultFormValues = useMemo(
    () =>
      ({
        code: getPayGroupById?.payGroupById?.code || undefined,
        name: getPayGroupById?.payGroupById?.name || undefined,
        calculatedByEpix: getPayGroupById?.payGroupById?.calculatedByEpix ?? false,
        hasPayComponentManagement: getPayGroupById?.payGroupById?.hasPayComponentManagement ?? false,
        hasCalendarManagement: getPayGroupById?.payGroupById?.hasCalendarManagement ?? false,
        hasDocumentManagement: getPayGroupById?.payGroupById?.hasDocumentManagement ?? false,
        hasCarManagement: getPayGroupById?.payGroupById?.hasCarManagement ?? false,
        hasSelfService: getPayGroupById?.payGroupById?.hasSelfService ?? false,
        firstClosure: getPayGroupById?.payGroupById?.firstClosure
          ? parseISO(getPayGroupById.payGroupById.firstClosure)
          : null,
        employerId: employerId,
        collectiveSchedule: getPayGroupById?.payGroupById?.collectiveScheduleId || '',
        payPeriod: getPayGroupById?.payGroupById?.payPeriodType.key ?? DEFAULT_PAYPERIOD,
        payPeriodNumberOfWeeks: getPayGroupById?.payGroupById?.payPeriodNumberOfWeeks?.toString() || null
      } as CreateEditPayGroupForm),
    [employerId, getPayGroupById?.payGroupById]
  )

  const form = useForm<CreateEditPayGroupForm>({
    resolver: zodResolver(addEditPayGroupSchema),
    defaultValues: defaultFormValues
  })
  const { control, reset } = form
  useEffect(() => {
    reset(defaultFormValues)
  }, [defaultFormValues])

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { payPeriodOptions } = formTypeSelectOptions
  const createMutation = useCreatePayGroupMutation()
  const updateMutation = useUpdatePayGroupMutation()

  const handleOnSubmit = async (newPayGroup: CreateEditPayGroupForm) => {
    if (!getPayGroupByIdQuery || !payGroupId) {
      await createMutation
        .mutateAsync({
          createPayGroupCommand: {
            code: newPayGroup.code,
            name: newPayGroup.name,
            firstClosure: newPayGroup.firstClosure && newPayGroup.firstClosure,
            calculatedByEpix: newPayGroup.calculatedByEpix,
            hasPayComponentManagement: newPayGroup.hasPayComponentManagement,
            hasCalendarManagement: newPayGroup.hasCalendarManagement,
            hasDocumentManagement: newPayGroup.hasCalendarManagement,
            hasSelfService: newPayGroup.hasSelfService,
            hasCarManagement: newPayGroup.hasCarManagement,
            employerId: employerId,
            collectiveScheduleId: newPayGroup.collectiveSchedule || null,
            payPeriodTypeKey: newPayGroup.payPeriod,
            payPeriodNumberOfWeeks: newPayGroup.payPeriodNumberOfWeeks || null
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updatePayGroupCommand: {
            payGroupId: payGroupId,
            code: newPayGroup.code,
            name: newPayGroup.name,
            firstClosure: newPayGroup.firstClosure && newPayGroup.firstClosure,
            calculatedByEpix: newPayGroup.calculatedByEpix,
            hasPayComponentManagement: newPayGroup.hasPayComponentManagement,
            hasCalendarManagement: newPayGroup.hasCalendarManagement,
            hasDocumentManagement: newPayGroup.hasDocumentManagement,
            hasSelfService: newPayGroup.hasSelfService,
            hasCarManagement: newPayGroup.hasCarManagement,
            employerId: employerId || '',
            collectiveScheduleId: newPayGroup.collectiveSchedule || null,
            payPeriodTypeKey: newPayGroup.payPeriod,
            payPeriodNumberOfWeeks: newPayGroup.payPeriodNumberOfWeeks || null
          }
        })
        .then(() => {
          getPayGroupByIdQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const watchCalendarManagement = useWatch({
    control,
    name: 'hasCalendarManagement'
  })

  const watchPayPeriod = useWatch({
    control,
    name: 'payPeriod'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.paygroup.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.paygroupcode')}`} />

        <FormInput sx={12} name="name" label={`${t('form.field.description')}`} />

        <FormSelect
          sx={12}
          name="collectiveSchedule"
          label={`${t('form.field.collectiveschedule')}`}
          options={collectiveSchedule?.collectiveSchedules.data.map(x => new FormSelectOption(x.id, x.description))}
        />

        <FormSelect
          sx={12}
          disabled={hasContractIds}
          onChange={e => {
            form.resetField('payPeriodNumberOfWeeks', { defaultValue: null })
          }}
          name="payPeriod"
          label={`${t('form.field.payperiod')}`}
          options={payPeriodOptions}
        />
        {watchPayPeriod === PAYPERIODTYPES.WEEKLY && (
          <FormSelect
            sx={12}
            disabled={hasContractIds}
            name="payPeriodNumberOfWeeks"
            label={t('form.field.numberofweeks')}
            options={typeToFormSelectOption(NUMBEROFWEEKS_PAYPERIOD)}
          />
        )}

        <FormDatepicker
          disabled={hasContractIds}
          sx={6}
          name="firstClosure"
          label={`${t('form.field.firstclosure')}`}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.calculatedByEpix}
          name="calculatedByEpix"
          label={t('form.field.defaultpayrollviaepix')}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasPayComponentManagement}
          name="hasPayComponentManagement"
          label={t('form.field.paycomponentmanagement')}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasCalendarManagement}
          name="hasCalendarManagement"
          onChange={e => {
            if (!e) {
              form.resetField('hasSelfService', { defaultValue: false })
            }
          }}
          label={t('form.field.calendarmanagement')}
        />

        <Box ml={8}>
          <FormSwitch
            sx={12}
            disabled={
              !getEmployerById?.employerById.hasSelfService ||
              (!watchCalendarManagement && getEmployerById?.employerById.hasSelfService)
            }
            name="hasSelfService"
            label={t('form.field.selfservice.hasselfservice')}
          />
        </Box>

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasCompanyCars}
          name="hasCarManagement"
          label={t('form.field.hascompanycars')}
        />

        <FormSwitch
          sx={12}
          disabled={!getEmployerById?.employerById.hasDocumentManagement}
          name="hasDocumentManagement"
          label={t('form.field.hasdocumentmanagement')}
        />

        <Grid xs={12} item>
          <Typography sx={{ fontStyle: 'italic' }}>{t('form.field.flagsdescription')}</Typography>
        </Grid>
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditPaygroup
