import { DateRange, isValidPayPeriodDate, PayGroupPayPeriodModel, PAYPERIODTYPES } from '@epix-web-apps/core'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, SxProps, useTheme } from '@mui/material'
import { EpixDatePicker } from '../../epix-date-picker'

const sxPopper: SxProps = {
  '& .MuiInputBase-input': {
    padding: '0',
    margin: '0'
  }
}

/* eslint-disable-next-line */
export interface DateComponentProps {
  label?: string
  period: DateRange
  payGroup: PayGroupPayPeriodModel | null
  onChange: (date: Date) => void
  onNext: () => void
  onPrevious: () => void
}

export function PeriodNavigationDatePicker({ payGroup, period, onChange, onNext, onPrevious }: DateComponentProps) {
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 0.5,
        color: theme.palette.text.secondary,
        maxWidth: '13rem'
      }}
    >
      <IconButton size="small" onClick={onPrevious}>
        <KeyboardArrowLeftIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
      <EpixDatePicker
        openTo={payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? 'month' : 'day'}
        value={period.startDate}
        onAccept={newStartDate => {
          onChange(newStartDate ?? new Date())
        }}
        shouldDisableDate={e => isValidPayPeriodDate(payGroup ?? null, e)}
        views={payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? ['year', 'month'] : ['year', 'month', 'day']}
        textFieldInputProps={{
          disableUnderline: true,
          sx: sxPopper
        }}
        variant="standard"
      />
      <IconButton size="small" onClick={onNext}>
        <KeyboardArrowRightIcon fontSize="medium" sx={{ verticalAlign: 'bottom' }} />
      </IconButton>
    </Box>
  )
}

export default PeriodNavigationDatePicker
