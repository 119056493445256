import {
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetSecurexContractDetailByContractIdQuery,
  useUpdateSecurexSocialSecurityMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditSecurexSocialSecurityProps {
  contractId: string
}

export function EditSecurexSocialSecurity({ contractId }: EditSecurexSocialSecurityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: securexContractDetail, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetSecurexContractDetailByContractIdQuery({
      contractId: contractId
    })

  const { data: contributionsCodeTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.LU_CONTRIBUTIONS_CODE.key,
    limit: -1,
    offset: 0
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const updateMutation = useUpdateSecurexSocialSecurityMutation()

  const contributionsCodeTypes = contributionsCodeTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    c => new FormSelectOption(c.id, c.value)
  )

  const editSecurexSocialSecuritySchema = object({
    isSubjectedToSocialSecurity: boolean().nullable(),
    contributionsCodeTypeId: string().nullable().optional()
  })

  type EditSecurexSocialSecurityForm = TypeOf<typeof editSecurexSocialSecuritySchema>

  const form = useForm<EditSecurexSocialSecurityForm>({
    resolver: zodResolver(editSecurexSocialSecuritySchema),
    defaultValues: {
      isSubjectedToSocialSecurity: securexContractDetail?.securexContractDetailByContractId.isSubjectedToSocialSecurity,
      contributionsCodeTypeId: securexContractDetail?.securexContractDetailByContractId.contributionsCodeType?.id
    }
  })

  const handleOnSubmit = async (securexSocialSecurity: EditSecurexSocialSecurityForm) => {
    await updateMutation
      .mutateAsync({
        updateSecurexSocialSecurityCommand: {
          id: securexContractDetail?.securexContractDetailByContractId.id || '',
          isSubjectedToSocialSecurity: securexSocialSecurity.isSubjectedToSocialSecurity,
          contributionsCodeTypeId: securexSocialSecurity.contributionsCodeTypeId
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editsecurexsocialsecurity.title')}</Typography>
      <FormGridLayout>
        <FormSwitch
          sx={12}
          name="isSubjectedToSocialSecurity"
          label={t('form.field.securex.issubjectedtosocialsecurity')}
        />
        <FormSelect
          sx={12}
          name="contributionsCodeTypeId"
          label={t('form.field.securex.contributionscode')}
          options={contributionsCodeTypes}
        />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isPending} />
    </FormContainer>
  )
}

export default EditSecurexSocialSecurity
