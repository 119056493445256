import styled from '@emotion/styled'
import {
  IsDateWithinRange,
  useRequiredParams,
  useSuspenseGetAllInjuriesByPersonIdQuery,
  useSuspenseGetPersonByIdQuery
} from '@epix-web-apps/core'
import { DetailBox, useFlyIn, useGlobalStore } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Divider, IconButton, Link, MenuItem, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DropdownMenu from '../../../components/dropdown-menu'
import {
  AddInjuryHistory,
  EditInjuryHistory
} from '../../../components/injury-components/add-edit-injury-history/add-edit-injury-history'
import { AddInjury, EditInjury } from '../../../components/injury-components/add-edit-injury/add-edit-injury'
import RemoveInjury from '../../../components/injury-components/remove-injury'
import RemoveInjuryHistory from '../../../components/injury-components/remove-injury-history'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'

/* eslint-disable-next-line */
export interface InjuryTabProps {}

export const InjuryDetailBox = styled(Box)(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.MuiSvgIcon-root': {
      color: theme.palette.primary.main,
      width: '1.25rem',
      height: '1.25rem'
    }
  }
})

export const BoldBox = styled(Box)(() => {
  return {
    fontWeight: 'bold'
  }
})

export function InjuryTab(props: InjuryTabProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { me } = useGlobalStore()
  const params = useRequiredParams<ContractDetailPageParams>()
  const [anchorElInjury, setAnchorElInjury] = useState<null | HTMLElement>(null)
  const [anchorElInjuryHistory, setAnchorElInjuryHistory] = useState<null | HTMLElement>(null)
  const [injuryId, setInjuryId] = useState('')
  const [injuryHistoryId, setInjuryHistoryId] = useState('')

  const { data: registeredInjuries, refetch } = useSuspenseGetAllInjuriesByPersonIdQuery({
    personId: params.id
  })

  const { data: getPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })

  const hasMatch =
    registeredInjuries?.allInjuriesByPersonId
      .flatMap(x =>
        x.contractHistoryModels.flatMap(y =>
          y.injuryHistoryModels.some(z => IsDateWithinRange(new Date(), parseISO(z.startDate), parseISO(z.endDate)))
        )
      )
      ?.some(match => match === true) ?? false

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox title={t('contractdetailpage.injury.title')}>
          <Box>
            <Trans
              i18nKey="contractdetailpage.injury.injury"
              components={{ bold: <span className="bold" /> }}
              values={{
                personName: getPersonById?.personById.firstName,
                injured: hasMatch ? t('contractdetailpage.injury.injured') : t('contractdetailpage.injury.notinjured')
              }}
            />
          </Box>
        </DetailBox>

        <DetailBox
          title={t('contractdetailpage.registeredinjuries.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <AddInjury />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <AddIcon />
            </IconButton>
          }
        >
          <Box>
            {registeredInjuries?.allInjuriesByPersonId.map(injury => {
              return (
                <Box key={injury.id} mb={2}>
                  <Box>
                    <InjuryDetailBox>
                      <BoldBox>
                        <span>
                          {t('contractdetailpage.injury.incidentdate')}:{' '}
                          {parseISO(injury.injuryDate).toLocaleDateString(me?.locale.locale)}
                        </span>
                      </BoldBox>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={e => {
                            setInjuryId(injury.id)
                            setAnchorElInjury(e.currentTarget)
                          }}
                        >
                          <EditIcon />
                        </IconButton>

                        {!injury.fatal && (
                          <Link
                            mr="0.5rem"
                            onClick={() =>
                              openFlyIn({
                                content: <AddInjuryHistory injuryId={injury.id} />,
                                callbackAfterClose: () => refetch
                              })
                            }
                          >
                            <Typography>{t('contractdetailpage.injury.addinjuryhistory')}</Typography>
                          </Link>
                        )}
                      </Box>
                    </InjuryDetailBox>
                    <Divider />
                  </Box>

                  {injury.fatal && <BoldBox mt={0.5}>{t('contractdetailpage.injury.fatal')}</BoldBox>}
                  {injury.contractHistoryModels.map(contractHistory => (
                    <Box key={contractHistory.contractBasicModel.id} mb={2}>
                      <p className="bold">
                        {contractHistory.contractBasicModel.icp.code}
                        <span>
                          {' '}
                          (
                          {parseISO(contractHistory.contractBasicModel.startDate).toLocaleDateString(
                            me?.locale.locale
                          )}{' '}
                          -{' '}
                          {contractHistory.contractBasicModel.endDate
                            ? parseISO(contractHistory.contractBasicModel.endDate).toLocaleDateString(me?.locale.locale)
                            : '...'}
                          )
                        </span>
                      </p>

                      {contractHistory.injuryHistoryModels.map((injuryHistory, index) => (
                        <Box key={injuryHistory.id} ml={1}>
                          <InjuryDetailBox>
                            <Box>
                              <Trans
                                i18nKey="contractdetailpage.injury.injuryhistory"
                                components={{
                                  bold: <span className="bold" />
                                }}
                                values={{
                                  startDate: parseISO(injuryHistory.startDate).toLocaleDateString(me?.locale.locale),
                                  endDate: parseISO(injuryHistory.endDate).toLocaleDateString(me?.locale.locale),
                                  disabledPercentage: injuryHistory.disabledPercentage
                                }}
                              />
                            </Box>
                            <IconButton
                              size="small"
                              onClick={e => {
                                setAnchorElInjuryHistory(e.currentTarget)
                                setInjuryHistoryId(injuryHistory.id)
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </InjuryDetailBox>

                          <DropdownMenu
                            key={injuryHistory.id}
                            open={injuryHistoryId === injuryHistory.id}
                            anchorEl={anchorElInjuryHistory}
                            onClose={() => {
                              setAnchorElInjuryHistory(null)
                              setInjuryHistoryId('')
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                openFlyIn({
                                  content: (
                                    <EditInjuryHistory
                                      injuryId={injury.id}
                                      injuryHistoryId={injuryHistory.id}
                                      contractId={contractHistory.contractBasicModel.contractId}
                                    />
                                  ),
                                  callbackAfterClose: () => refetch
                                })
                              }
                            >
                              {t('contractdetailpage.injury.editinjuryhistory')}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                openFlyIn({
                                  content: (
                                    <RemoveInjuryHistory injuryId={injury.id} injuryHistoryId={injuryHistory.id} />
                                  ),
                                  callbackAfterClose: () => refetch
                                })
                              }
                            >
                              {t('contractdetailpage.injury.removeinjuryhistory')}
                            </MenuItem>
                          </DropdownMenu>
                        </Box>
                      ))}
                    </Box>
                  ))}
                  <DropdownMenu
                    anchorEl={anchorElInjury}
                    open={injuryId === injury.id}
                    onClose={() => {
                      setInjuryId('')
                      setAnchorElInjury(null)
                    }}
                  >
                    <MenuItem
                      onClick={() =>
                        openFlyIn({
                          content: <EditInjury injuryId={injury.id} />,
                          callbackAfterClose: () => refetch
                        })
                      }
                    >
                      {t('contractdetailpage.injury.editinjury')}
                    </MenuItem>
                    {injuryId && (
                      <MenuItem
                        onClick={() =>
                          openFlyIn({
                            content: <RemoveInjury injuryId={injury.id} />,
                            callbackAfterClose: () => refetch
                          })
                        }
                      >
                        {t('contractdetailpage.injury.removeinjury')}
                      </MenuItem>
                    )}
                  </DropdownMenu>
                </Box>
              )
            })}
          </Box>
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}></Box>
    </Box>
  )
}

export default InjuryTab
