import {
  formTypeSelectOptions,
  GetPayrollCodeByIdQuery,
  ROLE,
  useCanAccess,
  useCreatePayrollCodeMutation,
  useSuspenseGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeMutation
} from '@epix-web-apps/core'
import {
  CanView,
  DEFAULT_PAYROLLCODE_COLOR,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddPayrollCodeProps {
  employerId: string
}
export interface EditPayrollCodeProps extends AddPayrollCodeProps {
  payrollCodeId: string
}

export function AddPayrollCode({ employerId }: AddPayrollCodeProps) {
  return AddEditPayrollCode(employerId)
}

export function EditPayrollCode({ employerId, payrollCodeId }: EditPayrollCodeProps) {
  const getPayrollCodeByIdQuery = useSuspenseGetPayrollCodeByIdQuery({
    payrollCodeId: payrollCodeId
  })

  return AddEditPayrollCode(employerId, getPayrollCodeByIdQuery)
}

function AddEditPayrollCode(
  employerId: string,
  getPayrollCodeByIdQuery?: UseSuspenseQueryResult<GetPayrollCodeByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const addEditPayrollCodeSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    }).min(1, t('form.validation.coderequired')),
    providerCode: string().optional().nullable(),
    epixcode: string().optional().nullable(),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    externalCodeType: string().optional().nullable(),
    groupType: string({
      required_error: t('form.validation.grouptyperequired'),
      invalid_type_error: t('form.validation.grouptyperequired')
    }).min(1, t('form.validation.grouptyperequired')),
    subGroupType: string({
      required_error: t('form.validation.subgrouptyperequired'),
      invalid_type_error: t('form.validation.subgrouptyperequired')
    }).min(1, t('form.validation.subgrouptyperequired')),
    userFriendlyDescription: string(),
    exportToProvider: boolean()
  })

  type CreateEditPayrollCodeForm = TypeOf<typeof addEditPayrollCodeSchema>
  const { groupOptions, subGroupOptions, externalCodeTypeOptions } = formTypeSelectOptions

  const getPayrollCodeById = getPayrollCodeByIdQuery?.data

  const canAccessPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditPayrollCodeForm>({
    resolver: zodResolver(addEditPayrollCodeSchema),
    defaultValues: {
      code: getPayrollCodeById?.payrollCodeById.code,
      providerCode: getPayrollCodeById?.payrollCodeById.providerCode,
      epixcode: getPayrollCodeById?.payrollCodeById?.subGroup?.key || '',
      description: getPayrollCodeById?.payrollCodeById.description,
      userFriendlyDescription: getPayrollCodeById?.payrollCodeById.userFriendlyDescription || '',
      groupType: getPayrollCodeById?.payrollCodeById.group.key,
      subGroupType: getPayrollCodeById?.payrollCodeById.subGroup?.key,
      externalCodeType: getPayrollCodeById?.payrollCodeById.externalCodeType?.key,
      exportToProvider: getPayrollCodeById?.payrollCodeById.exportToProvider ?? true
    }
  })
  const { control } = form
  const watchedGroupTypeKey = useWatch({ control, name: `groupType` })

  const createMutation = useCreatePayrollCodeMutation()
  const updateMutation = useUpdatePayrollCodeMutation()

  const handleOnSubmit = async (newPayrollCode: CreateEditPayrollCodeForm) => {
    if (!canAccessPayrollCodes) return
    if (!getPayrollCodeById) {
      await createMutation
        .mutateAsync({
          createPayrollCodeCommand: {
            code: newPayrollCode.code,
            providerCode: newPayrollCode.providerCode || null,
            description: newPayrollCode.description,
            groupTypeKey: newPayrollCode.groupType,
            subGroupTypeKey: newPayrollCode.subGroupType,
            externalCodeTypeKey: newPayrollCode.externalCodeType,
            employerId: employerId,
            userFriendlyDescription: newPayrollCode.userFriendlyDescription,
            exportToProvider: newPayrollCode.exportToProvider
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updatePayrollCodeCommand: {
            id: getPayrollCodeById.payrollCodeById.id,
            code: newPayrollCode.code,
            providerCode: newPayrollCode.providerCode || null,
            colourHexCode: getPayrollCodeById?.payrollCodeById.colourCodeHex || DEFAULT_PAYROLLCODE_COLOR,
            description: newPayrollCode.description,
            groupTypeKey: newPayrollCode.groupType,
            subGroupTypeKey: newPayrollCode.subGroupType,
            externalCodeTypeKey: newPayrollCode.externalCodeType,
            employerId: employerId || '',
            reverseSignImport: getPayrollCodeById?.payrollCodeById.reverseSignImport ?? false,
            reverseSignExport: getPayrollCodeById?.payrollCodeById.reverseSignExport ?? false,
            isCalendarEntry: getPayrollCodeById?.payrollCodeById.isCalendarEntry ?? false,
            isSalaryEntry: getPayrollCodeById?.payrollCodeById.isSalaryEntry ?? false,
            isUsableInSelfService: getPayrollCodeById?.payrollCodeById.isUsableInSelfService ?? false,
            needsDocument: getPayrollCodeById?.payrollCodeById.needsDocument ?? false,
            useForBradfordCalculation: getPayrollCodeById?.payrollCodeById.useForBradfordCalculation ?? false,
            userFriendlyDescription: newPayrollCode.userFriendlyDescription,
            valueTypePayComponents: (getPayrollCodeById?.payrollCodeById.valueTypePayComponents || []).map(
              item => item.key || item.value
            ),
            exportToProvider: newPayrollCode.exportToProvider,
            salaryEntryTypeKey: getPayrollCodeById?.payrollCodeById.salaryEntryType?.key
          }
        })
        .then(() => {
          getPayrollCodeByIdQuery?.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditpayrollcode.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <FormInput disabled={!canAccessPayrollCodes} sx={6} name="code" label={`${t('form.field.payrollcode')}`} />

        <FormInput
          disabled={!canAccessPayrollCodes}
          sx={6}
          name="providerCode"
          label={`${t('form.field.providercode')}`}
        />

        <FormInput
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="description"
          label={`${t('form.field.providerdescription')}`}
        />

        <FormInput
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="userFriendlyDescription"
          label={`${t('form.field.userfriendlydescription')}`}
        />

        <FormSelect
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="externalCodeType"
          label={`${t('form.field.externalcode')}`}
          options={externalCodeTypeOptions}
        />

        <FormSelect
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="groupType"
          label={`${t('form.field.grouptype')}`}
          options={groupOptions}
        />

        <FormSelect
          sx={12}
          disabled={!canAccessPayrollCodes}
          name="subGroupType"
          label={`${t('form.field.subgrouptype')}`}
          options={subGroupOptions.filter(s => s.id.includes(watchedGroupTypeKey))}
          onChange={(e, subGroupType) => {
            form.resetField('epixcode', {
              defaultValue: subGroupType?.id || ''
            })
          }}
        />

        <FormInput sx={6} name="epixcode" label={t('form.field.epixcode')} disabled={true} />

        <FormSwitch
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="exportToProvider"
          label={t('form.field.exporttoprovider')}
        />
      </FormGridLayout>
      <FormErrorList />
      <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES]}>
        <FormActionButtons
          isMutating={createMutation.isPending || updateMutation.isPending}
          onCancel={() => closeFlyIn()}
        />
      </CanView>
    </FormContainer>
  )
}

export default AddEditPayrollCode
