import { DriversEndingSoonModel, FormatDateToDDMM, useGetDriversEndingSoonQuery } from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingPaper } from '../../suspense-paper'
import HomepageEventWidget from '../homepage-event-widget'
import { OverviewDriversEndingSoon } from './overview-drivers-ending-soon'

/* eslint-disable-next-line */
export interface DriversEndingSoonComponentProps {}

export function DriversEndingSoonComponent(props: DriversEndingSoonComponentProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: driversEndingSoon, isLoading } = useGetDriversEndingSoonQuery({
    days: 90,
    icps: icpFilters
  })

  if (driversEndingSoon?.driversEndingSoon && driversEndingSoon.driversEndingSoon.length === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <LoadingPaper isLoading={isLoading} title={t('homepage.event.driversendingsoon')}>
        <HomepageEventWidget
          data={driversEndingSoon?.driversEndingSoon ?? []}
          length={driversEndingSoon?.driversEndingSoon.length || 0}
          subtext={(item: DriversEndingSoonModel) => FormatDateToDDMM(parseISO(item.upcomingDate))}
          onItemClick={item => navigate(People.PEOPLE_ID(item.personId))}
          onMoreClick={() => {
            openFlyIn({
              content: <OverviewDriversEndingSoon data={driversEndingSoon?.driversEndingSoon} />
            })
          }}
        />
      </LoadingPaper>
    </Grid>
  )
}

export default DriversEndingSoonComponent
