import {
  PROCESS_PAYROLLCLOSURE_PARAMETER,
  useGetAllValidPayComponentCorrectionsByProcessIdQuery,
  useGetPagedOriginalPayComponentsByProcessIdQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Button, useTheme } from '@mui/material'
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, PaginationModel, ScrollableDataTable } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty/data-table-empty'
import { StepProps } from '../../generic-steps'

export function SalaryData({ processId }: StepProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [rowCountCorrections, setRowCountCorrections] = useState(0)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [currencyRateHeader, setCurrencyRateHeader] = useState<string>()
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    valueRate: true,
    validFrom: false
  })

  const { data: me } = useSuspenseGetMeQuery()

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: processId
  })

  const {
    data: originalPayComponents,
    isLoading,
    refetch
  } = useGetPagedOriginalPayComponentsByProcessIdQuery({
    processId: processId,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const {
    data: changedPayComponentsCorrections,
    isLoading: isLoadingCorrections,
    refetch: refetchCorrections
  } = useGetAllValidPayComponentCorrectionsByProcessIdQuery({
    offset: paginationModel.offset,
    limit: paginationModel.pageSize + 1,
    processId: getPayrollClosureProcess.payrollClosureProcess.id
  })

  const parameter = getPayrollClosureProcess?.payrollClosureProcess.parameterType?.key

  const [changeToCorrections, setChangeToCorrections] = useState(false)

  const valueActions = (rowParams: GridRenderCellParams) => (
    <>
      {rowParams.row.value} {rowParams.row.sign}
    </>
  )

  const valueRateActions = (rowParams: GridRenderCellParams) => (
    <>
      {rowParams.row.valueRate} {me?.me.currencySymbol}
    </>
  )

  const periodActions = (rowParams: GridRenderCellParams) => (
    <>
      {rowParams.row.startDate} - {rowParams.row.endDate ? rowParams.row.endDate : '...'}
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'employee',
      headerName: t('salarydata.datatable.column.employee'),
      flex: 1.5,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('salarydata.datatable.column.employeenumber'),
      flex: 1,
      sortable: false
    },
    {
      field: 'paycode',
      headerName: t('salarydata.datatable.column.paycode'),
      flex: 1,
      sortable: false
    },
    {
      field: 'payrollcodeName',
      headerName: t('salarydata.datatable.column.payrollcodename'),
      flex: 1,
      sortable: false
    },
    {
      field: 'payrollcodeUserFriendlyName',
      headerName: t('salarydata.datatable.column.payrollcodeuserfriendlyname'),
      flex: 1.5,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('salarydata.datatable.column.value'),
      flex: 1,
      renderCell: valueActions,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueRate',
      headerName: currencyRateHeader,
      flex: 1,
      renderCell: valueRateActions,
      hideable: true,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'valueType',
      headerName: t('salarydata.datatable.column.valuetype'),
      flex: 1,
      sortable: false
    },
    {
      field: 'startDate',
      headerName: t('salarydata.datatable.column.period'),
      flex: 1.5,
      renderCell: periodActions,
      sortable: false
    }
  ]

  const correctionColumns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('salarydata.datatable.column.version'),
      flex: 1,
      sortable: false
    },
    ...columns
  ]

  const [tableData, setTableData] = useState<GridRowsProp>()
  const [tableDataCorrections, setTableDataCorrections] = useState<GridRowsProp>()

  useEffect(() => {
    if (changedPayComponentsCorrections && changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId) {
      const payComponents = changedPayComponentsCorrections.pagedPayComponentCorrectionsByProcessId
      setTableDataCorrections(
        payComponents.data.map(row => {
          return {
            id: `${row.version}-${row.id}`,
            version: row.version,
            employee: row.employeeName,
            employeeNumber: row.employeeNumber,
            paycode: row.providerCode,
            payrollcodeName: row.payrollLabel,
            payrollcodeUserFriendlyName: row.payrollFriendlyDescription,
            value: row.value,
            sign: row.sign,
            valueRate: row.valueRate,
            valueType: row.valueTypeValue,
            startDate: parseISO(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? parseISO(row.endDate).toLocaleDateString() : null
          }
        })
      )
      setRowCountCorrections(payComponents.totalCount)
    }
  }, [changedPayComponentsCorrections])

  useEffect(() => {
    if (originalPayComponents && originalPayComponents?.pagedOriginalPayComponentsByProcessId) {
      setTableData(
        originalPayComponents?.pagedOriginalPayComponentsByProcessId.data.map(row => {
          return {
            id: row.id,
            employee: row.employeeName,
            employeeNumber: row.employeeNumber,
            paycode: row.providerCode,
            payrollcodeName: row.payrollLabel,
            payrollcodeUserFriendlyName: row.payrollFriendlyDescription,
            value: row.value,
            sign: row.sign,
            valueRate: row.valueRate,
            valueType: row.valueType,
            startDate: parseISO(row.startDate).toLocaleDateString(),
            endDate: row.endDate ? parseISO(row.endDate).toLocaleDateString() : null
          }
        })
      )
      setRowCount(originalPayComponents?.pagedOriginalPayComponentsByProcessId.totalCount)
    }
  }, [originalPayComponents])

  const showOriginalButton =
    parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT &&
    originalPayComponents?.pagedOriginalPayComponentsByProcessId.data &&
    originalPayComponents?.pagedOriginalPayComponentsByProcessId.data.length > 0
  const showCorrectionButton =
    (parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.ORIGINAL_CORRECTIONS_PERSONCONTRACT ||
      parameter === PROCESS_PAYROLLCLOSURE_PARAMETER.CORRECTIONS_PERSONCONTRACT) &&
    changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId.data &&
    changedPayComponentsCorrections?.pagedPayComponentCorrectionsByProcessId.data.length > 0

  useEffect(() => {
    if (originalPayComponents?.pagedOriginalPayComponentsByProcessId) {
      if (originalPayComponents?.pagedOriginalPayComponentsByProcessId.data[0]?.icpCurrency === me?.me.currency) {
        setColumnVisibilityModel({ valueRate: false, validFrom: false })
        setCurrencyRateHeader('')
      } else {
        setColumnVisibilityModel({ valueRate: true, validFrom: false })
        setCurrencyRateHeader(me?.me.currencyName)
      }
    }
  }, [originalPayComponents, changedPayComponentsCorrections])

  useEffect(() => {
    refetch()
    refetchCorrections()
  }, [me?.me.currency])

  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
        {showOriginalButton && (
          <Button
            variant={!changeToCorrections ? 'contained' : 'outlined'}
            onClick={e => {
              setPaginationModel(new PaginationModel(0))
              setChangeToCorrections(false)
            }}
          >
            {t('salarydata.datatable.button.original')}
          </Button>
        )}
        {showCorrectionButton && (
          <Button
            variant={changeToCorrections ? 'contained' : 'outlined'}
            onClick={e => {
              setPaginationModel(new PaginationModel(0))
              setChangeToCorrections(true)
            }}
          >
            {t('salarydata.datatable.button.corrections')}
          </Button>
        )}
      </Box>

      {changeToCorrections ? (
        <ScrollableDataTable
          data={tableDataCorrections}
          columns={correctionColumns}
          columnsToHide={columnVisibilityModel}
          totalRowCount={rowCountCorrections}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          isLoading={isLoadingCorrections}
          vHeightOffset="25rem"
          emptyStateElement={
            <DataTableEmpty
              title={t('emptystate.process.salary')}
              icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
            />
          }
        />
      ) : (
        <DataTable
          data={tableData}
          columns={columns}
          columnsToHide={columnVisibilityModel}
          totalRowCount={rowCount}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          hideFooter
          isLoading={isLoading}
          emptyStateElement={
            <DataTableEmpty
              title={t('emptystate.process.salary')}
              icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
            />
          }
        />
      )}
    </Box>
  )
}

export default SalaryData
