import {
  FormSelectOption,
  GraphqlError,
  OrderDirection,
  useCreateReportDefinitionFilterMutation,
  useGetAllEmployersByIcpIdQuery,
  useGetAllPayGroupsByEmployerIdQuery,
  useSuspenseGetAllIcpsQuery
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

interface AddReportFilterFlyinProps {
  reportDefinitionId: string
}
export function AddReportFilterFlyin({ reportDefinitionId }: AddReportFilterFlyinProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const addReportFilterSchema = z.object({
    icpId: z.string(),
    allEmployers: z.boolean(),
    employerId: z.string().optional(),
    allPaygroups: z.boolean(),
    paygroupId: z.string().optional()
  })

  type AddReportFilterForm = z.TypeOf<typeof addReportFilterSchema>

  const form = useForm<AddReportFilterForm>({
    resolver: zodResolver(addReportFilterSchema),
    defaultValues: {
      allEmployers: true,
      allPaygroups: true
    }
  })

  const icpId = form.watch('icpId')
  const allEmployers = form.watch('allEmployers')
  const employerId = form.watch('employerId')
  const allPaygroups = form.watch('allPaygroups')

  const { data: icpQuery } = useSuspenseGetAllIcpsQuery({
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const { data: employerQuery, isLoading: isLoadingEmployer } = useGetAllEmployersByIcpIdQuery(
    {
      icpId: icpId!,
      offset: 0,
      limit: -1,
      orderDirection: OrderDirection.Asc
    },
    { enabled: !!icpId }
  )

  const { data: paygroupQuery, isLoading: isLoadingPaygroups } = useGetAllPayGroupsByEmployerIdQuery(
    {
      employerId: employerId!,
      orderDirection: OrderDirection.Asc
    },
    { enabled: !!employerId }
  )

  const mutation = useCreateReportDefinitionFilterMutation()

  function handleOnSubmit(form: AddReportFilterForm) {
    mutation
      .mutateAsync({
        createReportDefinitionFilterCommand: {
          reportDefinitionId: reportDefinitionId,
          icpId: form.icpId,
          employerId: form.employerId,
          payGroupId: form.paygroupId
        }
      })
      .then(_ => closeFlyIn())
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('reportinganalyticsdetailpage.general.providers.flyin.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          label={t('reportinganalyticsdetailpage.general.providers.flyin.provider')}
          name="icpId"
          options={icpQuery.icps.data.map(i => new FormSelectOption(i.id, `${i.payrollProvider} (${i.code})`))}
          onChange={() => {
            form.resetField('employerId')
            form.resetField('paygroupId')
          }}
          sx={12}
        />

        {!!icpId && (
          <Grid xs={12} item>
            <FormControl fullWidth size="small">
              <FormLabel>{t('reportinganalyticsdetailpage.general.providers.flyin.employer')}</FormLabel>
              <RadioGroup value={allEmployers} onChange={e => form.setValue('allEmployers', e.target.value == 'true')}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('reportinganalyticsdetailpage.general.providers.flyin.all')}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('reportinganalyticsdetailpage.general.providers.flyin.selected')}
                />
              </RadioGroup>
            </FormControl>

            {!allEmployers && !isLoadingEmployer && (
              <FormSelect
                name={'employerId'}
                label={t('reportinganalyticsdetailpage.general.providers.flyin.employer')}
                options={employerQuery?.employersByIcpId.data.map(e => new FormSelectOption(e.id, e.companyName))}
                onChange={() => {
                  form.resetField('paygroupId')
                }}
              />
            )}
          </Grid>
        )}

        {!!icpId && !!employerId && !allEmployers && (
          <Grid xs={12} item>
            <FormControl fullWidth size="small">
              <FormLabel>{t('reportinganalyticsdetailpage.general.providers.flyin.paygroup')}</FormLabel>
              <RadioGroup value={allPaygroups} onChange={e => form.setValue('allPaygroups', e.target.value == 'true')}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={t('reportinganalyticsdetailpage.general.providers.flyin.all')}
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={t('reportinganalyticsdetailpage.general.providers.flyin.selected')}
                />
              </RadioGroup>
            </FormControl>

            {!allPaygroups && !isLoadingPaygroups && (
              <FormSelect
                name={'paygroupId'}
                label={t('reportinganalyticsdetailpage.general.providers.flyin.paygroup')}
                options={paygroupQuery?.allPayGroupsByEmployerId.map(p => new FormSelectOption(p.id, p.name))}
              />
            )}
          </Grid>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={mutation.isPending} onCancel={closeFlyIn} />
    </FormContainer>
  )
}
