import { ExternalCodeTypeModel, useGetPagedExternalCodesQuery } from '@epix-web-apps/core'
import { Configuration, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import {
  AddExternalCode,
  EditExternalCode
} from '../../components/external-code-components/add-edit-external-code/add-edit-external-code'
import ViewDetailsExternalCode from '../../components/external-code-components/view-details-external-code/view-details-external-code'
import { IcpCodeBadge } from '../../components/icp-components'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExternalCodesPageProps {}

export function ExternalCodesPage(props: ExternalCodesPageProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [rowCount, setRowCount] = useState(0)
  const [clickedExternalCodeKey, setClickedExternalCodeKey] = useState<string>('')
  const { openFlyIn } = useFlyIn()

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <VisibilityOffIcon />}</DataTableCell>
  }

  const rowActions = (rowParams: GridRenderCellParams) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedExternalCodeKey(rowParams.row.key)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }

  const providerRowData = (rowParams: GridRenderCellParams) => {
    const countryKeys = rowParams.value
    return (
      <DataTableCell sx={{ gap: 1 }}>
        {countryKeys.map((countryKey: string, index: number) => (
          <IcpCodeBadge key={index} icpCode={countryKey} />
        ))}
      </DataTableCell>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'value',
      headerName: t('externalcodespage.datatable.column.value'),
      flex: 1
    },
    {
      field: 'key',
      headerName: t('externalcodespage.datatable.column.key'),
      flex: 1
    },
    {
      field: 'active',
      headerName: t('externalcodespage.datatable.column.active'),
      flex: 1,
      editable: false,
      renderCell: activeActions
    },
    {
      field: 'countryKeys',
      headerName: t('externalcodespage.datatable.column.provider'),
      sortable: false,
      renderCell: providerRowData
    },
    {
      field: ' ',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field))
  const [tableData, setTableData] = useState<GridRowsProp>()

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <HeaderTitleNavigation backToLink={Configuration.ICP_PROVIDERS()} title={t('externalcodespage.title')} />
      <Button
        variant="contained"
        onClick={() =>
          openFlyIn({
            content: <AddExternalCode />,
            callbackAfterClose: () => refetchAllExternalCodes
          })
        }
      >
        <AddIcon />
        {t('externalcodespage.button.addexternalcode')}
      </Button>
    </Box>
  )

  const {
    data: externalCodesData,
    isLoading: isLoadingExternalCodes,
    refetch: refetchAllExternalCodes
  } = useGetPagedExternalCodesQuery({
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    orderDirection: sortModel.orderDirection,
    sortByProperty: sortModel.field
  })

  useEffect(() => {
    if (externalCodesData && externalCodesData.pagedExternalCodes) {
      const pagedExternalCodes = externalCodesData.pagedExternalCodes
      setRowCount(pagedExternalCodes.totalCount)
      setTableData(
        pagedExternalCodes.data.map((row: ExternalCodeTypeModel) => {
          return {
            id: row.key,
            key: row.key,
            value: row.value,
            active: row.active,
            countryKeys: row.countryKeys
          }
        })
      )
    }
  }, [externalCodesData])

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onSortChange={setSortModel}
        isLoading={isLoadingExternalCodes}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditExternalCode externalCodeKey={clickedExternalCodeKey} />,
              callbackAfterClose: () => refetchAllExternalCodes
            })
          }
        >
          {t('externalcodespage.list.row.menu.edit-externalcodetype')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            openFlyIn({
              content: <ViewDetailsExternalCode externalCodeKey={clickedExternalCodeKey} />
            })
          }}
        >
          {t('externalcodespage.list.row.menu.view-details')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ExternalCodesPage
