import { ToLongDateTime, useRequiredParams, useSuspenseGetImportProcessByIdQuery } from '@epix-web-apps/core'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

export function ImportSummary() {
  const { t } = useTranslation()
  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: importProcessQuery } = useSuspenseGetImportProcessByIdQuery({
    importProcessId: params.id
  })

  const importProcess = importProcessQuery.importProcessById

  const anyConverterParameters =
    importProcess.converterParameters?.employerNumber &&
    importProcess.converterParameters?.payrollPeriodStart &&
    importProcess.converterParameters?.year

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'max-content auto', columnGap: 5, mb: 3 }}>
      <Typography>{t('configurationimport.common.filetoimport')}</Typography>
      <Typography>{importProcess.originalFileName}</Typography>

      <Typography>{t('configurationimport.common.importtype')}</Typography>
      <Typography>{importProcess.importJobType.value}</Typography>

      {importProcess.converter && (
        <>
          <Typography>{t('configurationimport.common.chosenformat')}</Typography>
          <Typography>{importProcess.converter.name}</Typography>

          {anyConverterParameters && (
            <>
              <Typography>{t('configurationimport.common.converterparameters')}</Typography>
              <Typography></Typography>

              {importProcess.converterParameters?.employerNumber && (
                <>
                  <Typography sx={{ marginLeft: 2 }}>{t('configurationimport.common.employernumber')}</Typography>
                  <Typography>{importProcess.converterParameters.employerNumber}</Typography>
                </>
              )}

              {importProcess.converterParameters?.payrollPeriodStart && (
                <>
                  <Typography sx={{ marginLeft: 2 }}>{t('configurationimport.common.payrollperiodstart')}</Typography>
                  <Typography>
                    {parseISO(importProcess.converterParameters.payrollPeriodStart).toLocaleDateString()}
                  </Typography>
                </>
              )}

              {importProcess.converterParameters?.year && (
                <>
                  <Typography sx={{ marginLeft: 2 }}>{t('configurationimport.common.year')}</Typography>
                  <Typography>{importProcess.converterParameters.year}</Typography>
                </>
              )}
            </>
          )}
        </>
      )}

      <Typography>{t('configurationimport.common.startedby')}</Typography>
      <Typography>{importProcess.createdBy}</Typography>

      <Typography>{t('configurationimport.common.startedon')}</Typography>
      <Typography>{ToLongDateTime(importProcess.startedOn)}</Typography>

      {importProcess?.endedOn && (
        <>
          <Typography>{t('configurationimport.common.endedon')}</Typography>
          <Typography>{ToLongDateTime(importProcess.endedOn)}</Typography>
        </>
      )}
    </Box>
  )
}
