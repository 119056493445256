import {
  GraphqlError,
  useDeletePayComponentHistoryMutation,
  useSuspenseGetPayComponentHistoryByIdQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface RemovePaycomponentProps {
  payComponentId: string
  payComponentHistoryId: string
}

export function RemovePaycomponent({ payComponentId, payComponentHistoryId }: RemovePaycomponentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: getPayComponentHistoryById } = useSuspenseGetPayComponentHistoryByIdQuery({
    id: payComponentHistoryId
  })
  const deleteMutation = useDeletePayComponentHistoryMutation()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const form = useForm()

  const recordToDelete = getPayComponentHistoryById?.payComponentHistoryById

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deletePayComponentHistoryCommand: {
          id: payComponentId,
          payComponentHistoryId: payComponentHistoryId
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.earning.deleteitem')}</Typography>
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>
        {recordToDelete?.startDate} {<ChevronRightOutlinedIcon fontSize="small" />} {recordToDelete?.endDate || '...'}
      </Typography>
      <Typography>
        {recordToDelete?.payrollCode} - {recordToDelete?.description} : {recordToDelete?.value}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemovePaycomponent
