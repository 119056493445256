import { FEATURE, Module, ROLE, useCanAccess, useSuspenseGetMeQuery } from '@epix-web-apps/core'
import { ReactNode } from 'react'
import { NotAuthorizedPage } from '../../pages/not-authorized-page'

export interface IsAuthorizedProps {
  children: ReactNode
  roles?: ROLE[]
  feature?: FEATURE
  module?: Module
  hideOnOwnUser?: string
}

export function IsAuthorized({ children, roles, feature, module }: IsAuthorizedProps) {
  const canAccess = useCanAccess(roles ?? [], feature, module)

  return canAccess ? <>{children}</> : <NotAuthorizedPage />
}

export function CanView({ children, roles, feature, module, hideOnOwnUser }: IsAuthorizedProps) {
  const { data: me } = useSuspenseGetMeQuery()
  const canAccess = useCanAccess(roles ?? [], feature, module)
  const hideForOwnUser = hideOnOwnUser !== undefined && me.me.id === hideOnOwnUser

  return canAccess && !hideForOwnUser ? <>{children}</> : null
}

export default IsAuthorized
