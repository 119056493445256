import {
  useSuspenseGetAverageAgeQuery,
  useSuspenseGetAverageSeniorityQuery,
  useSuspenseGetSeniorityByAgeQuery
} from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import HeatmapComponent from '../heatmap-component'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'

/* eslint-disable-next-line */
export interface SeniorityByAgeComponentProps {}

export function SeniorityByAgeComponent(props: SeniorityByAgeComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter } = useAnalyticsPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()

  const { data: seniority } = useSuspenseGetSeniorityByAgeQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })
  const { data: age } = useSuspenseGetAverageAgeQuery({
    icps: icpFilters
  })
  const { data: avseniority } = useSuspenseGetAverageSeniorityQuery({
    icps: icpFilters
  })

  return (
    <Box>
      <Typography sx={{ textAlign: 'right', mt: 1, fontSize: '0.8em', mr: 2 }}>
        {t('analytics.heatmap.averageage') + age?.averageAge.value}
      </Typography>
      <Typography sx={{ textAlign: 'right', mt: 1, fontSize: '0.8em', mr: 2 }}>
        {t('analytics.heatmap.averageseniority') + avseniority?.averageSeniority.value}{' '}
      </Typography>
      <HeatmapComponent
        data={seniority?.seniorityByAge.entries.map(x => ({
          id: t('heatmap.age.' + x.id),
          data: x.entries.map(item => ({
            x: t('heatmap.seniority.' + item.name),
            y: item.value
          }))
        }))}
      />
    </Box>
  )
}

export default SeniorityByAgeComponent
