import {
  GetDayDefinitionByIdQuery,
  useCreateDayDefinitionMutation,
  useSuspenseGetDayDefinitionByIdQuery,
  useSuspenseGetEmployerByIdQuery,
  useUpdateDayDefinitionMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export interface AddDayDefinitionProps {
  employerId: string
}

export interface EditDayDefinitionProps extends AddDayDefinitionProps {
  dayDefinitionId: string
}

export function AddDayDefinition({ employerId }: AddDayDefinitionProps) {
  return AddEditDayDefinition(employerId)
}

export function EditDayDefinition({ employerId, dayDefinitionId }: EditDayDefinitionProps) {
  const dayDefinitionQuery = useSuspenseGetDayDefinitionByIdQuery({
    dayDefinitionId: dayDefinitionId
  })

  return AddEditDayDefinition(employerId, dayDefinitionQuery)
}

function AddEditDayDefinition(
  employerId: string,
  getDayDefinitionByIdQuery?: UseSuspenseQueryResult<GetDayDefinitionByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditDayDefinitionSchema = object({
    code: string({
      required_error: t('form.validation.daydefinitioncoderequired'),
      invalid_type_error: t('form.validation.daydefinitioncoderequired')
    }).min(1, t('form.validation.daydefinitioncoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired'))
  })

  type CreateEditDayDefinitionForm = TypeOf<typeof AddEditDayDefinitionSchema>
  const getDayDefinitionById = getDayDefinitionByIdQuery?.data

  const form = useForm<CreateEditDayDefinitionForm>({
    resolver: zodResolver(AddEditDayDefinitionSchema),
    defaultValues: {
      code: getDayDefinitionById?.dayDefinitionById.code,
      description: getDayDefinitionById?.dayDefinitionById.description,
      employerId: employerId
    }
  })

  const createMutation = useCreateDayDefinitionMutation()
  const updateMutation = useUpdateDayDefinitionMutation()

  const handleOnSubmit = async (newDayDefinition: CreateEditDayDefinitionForm) => {
    if (!getDayDefinitionById) {
      await createMutation
        .mutateAsync({
          createDayDefinitionCommand: {
            code: newDayDefinition.code,
            description: newDayDefinition.description,
            employerId: employerId || ''
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateDayDefinitionCommand: {
            id: getDayDefinitionById.dayDefinitionById.id,
            code: newDayDefinition.code,
            description: newDayDefinition.description
          }
        })
        .then(() => {
          getDayDefinitionByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId || ''
  })
  const company = getEmployerById?.employerById.companyName

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditdaydefinitions.title')}</Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.addeditdaydefinitions.subtitle')}: {company}
      </Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.daydefinitioncode')}`} />
        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditDayDefinition
