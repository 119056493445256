import {
  getSearchParamNumber,
  useRequiredParams,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetPersonByIdQuery
} from '@epix-web-apps/core'
import { DetailPageBaseQueryParams, DetailPageContentHeader, HeaderTitleNavigation, People } from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import ContractTabGeneral from '../../components/contract-details-components/contract-tab-general'
import { IcpCodeBadge, IcpCodeBadgeSize } from '../../components/icp-components/icp-code-badge'
import NextPreviousPerson from '../../components/people-components/next-previous-person/next-previous-person'

/* eslint-disable-next-line */
export interface ContractsDetailPageProps {}

export type ContractDetailPageParams = DetailPageBaseQueryParams & {
  contractid: string
}

export function ContractsDetailPage(props: ContractsDetailPageProps) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const params = useRequiredParams<ContractDetailPageParams>()

  const { data: getPersonById } = useSuspenseGetPersonByIdQuery({ personId: params.id })
  const person = getPersonById?.personById

  const { data: getContractById } = useSuspenseGetContractByIdQuery({ contractId: params?.contractid })
  const contract = getContractById?.contractById

  return (
    <>
      <DetailPageContentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            <HeaderTitleNavigation
              backToLink={`${People.PEOPLE()}?page=${getSearchParamNumber('page', searchParams)}`}
              title={`${person?.firstName} ${person?.preferredLastName}`}
            />
            <Box sx={{ display: 'flex', marginLeft: '2rem' }}>
              <IcpCodeBadge sx={{ marginRight: '1rem' }} icpCode={contract?.icp?.code} size={IcpCodeBadgeSize.large} />
              <Box>
                <Typography>
                  {contract && parseISO(contract.startDate).toLocaleDateString()} -{' '}
                  {contract?.endDate ? parseISO(contract.endDate).toLocaleDateString() : '...'}
                </Typography>
                <Typography>
                  {contract?.employeeNumber &&
                    `${t('contractdetailpage.label.employeenumber')}: ${contract?.employeeNumber} |`}{' '}
                  {contract?.employer?.companyName} ({contract?.icp.code})
                </Typography>
              </Box>
            </Box>
          </Box>
          <NextPreviousPerson personId={params.id} />
        </Box>
      </DetailPageContentHeader>

      {person && contract && (
        <>
          <ContractTabGeneral personId={person.id} contractId={contract.id} />
        </>
      )}
    </>
  )
}

export default ContractsDetailPage
