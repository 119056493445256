import {
  DEFAULT_UNDEFINED_KEY,
  formTypeSelectOptions,
  GetPersonByIdQuery,
  GetPersonFamilyMemberStateHistoryQuery,
  isLastEntryById,
  numericString,
  useCreateFamilyMemberMutation,
  useCreateFamilyMemberStateMutation,
  useDeleteFamilyMemberMutation,
  useDeleteFamilyMemberStateMutation,
  useRequiredParams,
  useSuspenseGetPersonByIdQuery,
  useSuspenseGetPersonFamilyMemberStateHistoryQuery,
  useUpdateFamilyMemberMutation,
  useUpdateFamilyMemberStateMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditPersonFamilyRelationsProps {
  familyMemberId: string
}

export function AddPersonFamilyRelations() {
  return AddEditPersonFamilyRelations()
}

export function EditPersonFamilyRelations({ familyMemberId }: EditPersonFamilyRelationsProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const getPersonByIdQuery = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })

  const getPersonFamilyMemberStateHistory = useSuspenseGetPersonFamilyMemberStateHistoryQuery({
    personId: params.id,
    familyMemberId: familyMemberId
  })

  return AddEditPersonFamilyRelations(getPersonByIdQuery, getPersonFamilyMemberStateHistory, familyMemberId)
}

function AddEditPersonFamilyRelations(
  getPersonByIdQuery?: UseSuspenseQueryResult<GetPersonByIdQuery, unknown>,
  getPersonFamilyMemberStateHistoryQuery?: UseSuspenseQueryResult<GetPersonFamilyMemberStateHistoryQuery, unknown>,
  familyMemberId?: string
) {
  const { t } = useTranslation()
  const editPersonFamilyRelationsSchema = object({
    firstName: string().nullable(),
    otherNames: string().nullable(),
    lastNamePrefix: string().nullable(),
    lastName: string().nullable(),
    relation: string({
      required_error: t('form.validation.relationrequired'),
      invalid_type_error: t('form.validation.relationrequired')
    }).min(1, t('form.validation.relationrequired')),
    birthDate: date().optional().nullable(),
    genderType: string().nullable(),
    birthplace: string().nullable(),
    countryOfBirthKey: string().optional().nullable(),
    nationalityKey: string().optional().nullable(),
    socialSecurityNumber: string().nullable(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }).optional(),
    endDate: date().optional().nullable(),
    dependent: boolean().optional(),
    disabled: boolean().optional(),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.percentagelessthan100') })
        .optional()
    )
  })

  type EditPersonFamilyRelationsForm = TypeOf<typeof editPersonFamilyRelationsSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { genderOptions, countryOptions, familyRelationOptions, nationalityOptions } = formTypeSelectOptions

  const getPersonFamilyMemberStateHistoryResult = getPersonFamilyMemberStateHistoryQuery?.data
  const personByIdResult = getPersonByIdQuery?.data

  const familyMember = familyMemberId
    ? personByIdResult?.personById.familyMembers?.find(x => x.id === familyMemberId)
    : undefined

  const [selectedState, setSelectedState] = useState(
    getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory?.current
  )
  const [addNewState, setAddNewState] = useState(false)
  const createMutation = useCreateFamilyMemberMutation()
  const createStateMutation = useCreateFamilyMemberStateMutation()
  const updateMutation = useUpdateFamilyMemberMutation()
  const updateStateMutation = useUpdateFamilyMemberStateMutation()
  const deleteFamilyMemberMutation = useDeleteFamilyMemberMutation()
  const deleteFamilyMemberStateMutation = useDeleteFamilyMemberStateMutation()

  useEffect(() => {
    if (getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory?.current) {
      setSelectedState(getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory?.current)
    }
  }, [getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory])

  const form = useForm<EditPersonFamilyRelationsForm>({
    resolver: zodResolver(editPersonFamilyRelationsSchema),
    defaultValues: {
      firstName: familyMember?.firstName,
      lastName: familyMember?.lastName,
      lastNamePrefix: familyMember?.lastNamePrefix,
      otherNames: familyMember?.otherNames,
      relation: familyMember?.relationType.key,
      genderType: familyMember?.genderType?.key || DEFAULT_UNDEFINED_KEY,
      countryOfBirthKey: familyMember?.familyMemberCountry?.code,
      birthplace: familyMember?.cityOfBirth,
      nationalityKey: familyMember?.familyMemberNationality?.code,
      socialSecurityNumber: familyMember?.nationalIdNumber,
      birthDate: familyMember?.dateOfBirth ? parseISO(familyMember.dateOfBirth) : null,
      startDate: selectedState?.startDate ? parseISO(selectedState.startDate) : undefined,
      endDate: selectedState?.endDate ? parseISO(selectedState.endDate) : null,
      disabled: selectedState?.disabled ?? false,
      disabledPercentage: selectedState?.disabledPercentage || null,
      dependent: selectedState?.dependent ?? false
    }
  })
  const { control } = form
  const watchDisabled = useWatch({ control, name: `disabled` })

  const handleOnSubmit = async (personFamilyRelation: EditPersonFamilyRelationsForm) => {
    const familyRelationModel = {
      personId: params.id || '',
      firstName: personFamilyRelation.firstName,
      otherNames: personFamilyRelation.otherNames,
      lastName: personFamilyRelation.lastName,
      lastNamePrefix: personFamilyRelation.lastNamePrefix,
      familyRelationTypeKey: personFamilyRelation.relation,
      dateOfBirth: personFamilyRelation.birthDate && personFamilyRelation.birthDate,
      genderTypeKey: personFamilyRelation.genderType || DEFAULT_UNDEFINED_KEY,
      cityOfBirth: personFamilyRelation.birthplace,
      countryOfBirthKey: personFamilyRelation.countryOfBirthKey,
      nationalityKey: personFamilyRelation.nationalityKey,
      nationalIdNumber: personFamilyRelation.socialSecurityNumber
    }
    if (!familyMemberId) {
      await createMutation
        .mutateAsync({
          createFamilyMemberCommand: familyRelationModel
        })
        .then(async response => {
          if (addNewState) {
            await createStateMutation.mutateAsync({
              createFamilyMemberStateCommand: {
                familyMemberId: response.createFamilyMember,
                personId: params.id,
                startDate: personFamilyRelation.startDate && personFamilyRelation.startDate,
                dependent: personFamilyRelation.dependent ?? false,
                disabled: personFamilyRelation.disabled ?? false,
                disabledPercentage: personFamilyRelation.disabledPercentage || null
              }
            })
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateFamilyMemberCommand: {
            familyMemberId: familyMemberId,
            ...familyRelationModel
          }
        })
        .then(async () => {
          if (addNewState) {
            await createStateMutation.mutateAsync({
              createFamilyMemberStateCommand: {
                familyMemberId: familyMemberId,
                personId: params.id,
                startDate: personFamilyRelation.startDate && personFamilyRelation.startDate,
                dependent: personFamilyRelation.dependent ?? false,
                disabled: personFamilyRelation.disabled ?? false,
                disabledPercentage: personFamilyRelation.disabledPercentage || null
              }
            })
          } else {
            if (selectedState) {
              await updateStateMutation.mutateAsync({
                updateFamilyMemberStateCommand: {
                  familyMemberId: familyMemberId,
                  personId: params.id,
                  stateId: selectedState.id,
                  dependent: personFamilyRelation.dependent ?? false,
                  disabled: personFamilyRelation.disabled ?? false,
                  disabledPercentage: personFamilyRelation.disabledPercentage || null
                }
              })
            }
          }
        })
        .then(() => {
          getPersonByIdQuery?.refetch()
          getPersonFamilyMemberStateHistoryQuery?.refetch()
          closeFlyIn()
        })
    }
  }

  async function handleFamilyMemberDelete() {
    if (!familyMemberId) return
    await deleteFamilyMemberMutation
      .mutateAsync({
        deleteFamilyMemberCommand: {
          personId: params.id,
          familyMemberId: familyMemberId
        }
      })
      .then(() => {
        getPersonByIdQuery?.refetch()
        closeFlyIn()
      })
  }

  async function handleFamilyMemberStateDelete() {
    if (!familyMemberId || !selectedState) return
    await deleteFamilyMemberStateMutation
      .mutateAsync({
        deleteFamilyMemberStateCommand: {
          personId: params.id,
          familyMemberId: familyMemberId,
          stateId: selectedState.id
        }
      })
      .then(() => {
        getPersonByIdQuery?.refetch()
        getPersonFamilyMemberStateHistoryQuery?.refetch()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {familyMember?.firstName} {familyMember?.lastName} {(familyMember?.firstName || familyMember?.lastName) && '- '}
        {t('flyin.editperson.familyrelations.title')}
      </Typography>
      <FormGridLayout>
        <FormInput sx={6} name="firstName" label={t('form.field.firstname')} />

        <FormInput sx={6} name="otherNames" label={t('form.field.othernames')} />

        <FormInput sx={6} name="lastNamePrefix" label={t('form.field.lastnameprefix')} />

        <FormInput sx={6} name="lastName" label={t('form.field.lastname')} />

        <FormDatepicker sx={6} name="birthDate" label={t('form.field.birthdate')} />

        <FormSelect sx={6} name="genderType" label={t('form.field.gender')} options={genderOptions} />

        <FormSelect sx={12} name="relation" label={`${t('form.field.relation')} *`} options={familyRelationOptions} />

        <FormInput sx={6} name="birthplace" label={t('form.field.birthplace')} />

        <FormSelect sx={6} name="countryOfBirthKey" label={t('form.field.birthcountry')} options={countryOptions} />

        <FormSelect sx={6} name="nationalityKey" label={t('form.field.nationality')} options={nationalityOptions} />

        <FormInput sx={6} name="socialSecurityNumber" label={t('form.field.socialsecuritynumber')} />
      </FormGridLayout>
      {familyMemberId && (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          <Button
            variant="outlined"
            size="small"
            color="error"
            onClick={handleFamilyMemberDelete}
            disabled={
              deleteFamilyMemberStateMutation.isPending ||
              getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory.history.some(f => f)
            }
          >
            {t('flyin.editperson.familyrelations.removefamilymember')}
          </Button>
        </Box>
      )}

      <Typography variant="h4" mt={0}>
        {t('flyin.editperson.familyrelations.statetitle')}
      </Typography>
      <Grid className="bold" container>
        <Grid xs={4} item>
          <p>{t('form.field.startdate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.enddate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.state')}</p>
        </Grid>
      </Grid>
      <Divider />
      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory.history && (
            <RadioGroup
              aria-labelledby="state-history"
              value={selectedState?.id}
              onChange={(e, stateId) => {
                const selectedFamilyRelationState =
                  getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory.history.find(x => x.id === stateId)
                setSelectedState(selectedFamilyRelationState)
                form.reset({
                  firstName: familyMember?.firstName || null,
                  otherNames: familyMember?.otherNames || null,
                  lastNamePrefix: familyMember?.lastNamePrefix || null,
                  lastName: familyMember?.lastName || null,
                  birthDate: familyMember?.dateOfBirth ? parseISO(familyMember?.dateOfBirth) : null,
                  genderType: familyMember?.genderType?.key || DEFAULT_UNDEFINED_KEY,
                  relation: familyMember?.relationType.key,
                  birthplace: familyMember?.cityOfBirth || null,
                  countryOfBirthKey: familyMember?.familyMemberCountry?.code || null,
                  nationalityKey: familyMember?.familyMemberNationality?.code || null,
                  socialSecurityNumber: familyMember?.nationalIdNumber || null,
                  startDate: parseISO(selectedFamilyRelationState?.startDate),
                  endDate: selectedFamilyRelationState?.endDate ? parseISO(selectedFamilyRelationState.endDate) : null,
                  disabled: selectedFamilyRelationState?.disabled ?? false,
                  disabledPercentage: selectedFamilyRelationState?.disabledPercentage || null,
                  dependent: selectedFamilyRelationState?.dependent ?? false
                })
              }}
            >
              {getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory.history.map((state, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <p>
                      <Radio
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        value={state.id}
                      />
                      <span>{parseISO(state.startDate).toLocaleDateString()}</span>
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{state.endDate && parseISO(state.endDate).toLocaleDateString()}</p>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography fontSize={12}>
                      {state.dependent
                        ? t('flyin.editperson.familyrelations.label.dependent')
                        : t('flyin.editperson.familyrelations.label.notdependant')}
                      <span>, </span>
                      {state.disabled
                        ? t('flyin.editperson.familyrelations.label.disabled')
                        : t('flyin.editperson.familyrelations.label.notdisabled')}
                    </Typography>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} container item gap={'0.5rem'}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.resetField('startDate', { defaultValue: null })
              form.resetField('endDate', { defaultValue: null })
              form.resetField('disabled', { defaultValue: false })
              form.resetField('disabledPercentage', { defaultValue: null })
              form.resetField('dependent', { defaultValue: false })
              setAddNewState(true)
              setSelectedState(null)
            }}
          >
            {t('flyin.editperson.familyrelations.addnewstate')}
          </Button>
          {isLastEntryById(
            getPersonFamilyMemberStateHistoryResult?.familyMemberStateHistory?.history,
            selectedState?.id
          ) && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleFamilyMemberStateDelete}
              disabled={deleteFamilyMemberStateMutation.isPending}
            >
              {t('flyin.editperson.familyrelations.removestate')}
            </Button>
          )}
        </Grid>
      </FormGridLayout>

      {(selectedState || addNewState) && (
        <FormGridLayout hasPaddingTop hasBorderBottom>
          <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')} *`} disabled={!addNewState} />

          <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} disabled={true} />

          <FormSwitch
            sx={12}
            name="disabled"
            onChange={() => {
              form.resetField('disabledPercentage', { defaultValue: null })
            }}
            label={t('form.field.disabled')}
          />

          {watchDisabled && (
            <FormNumericInput sx={12} name="disabledPercentage" label={t('form.field.disabledPercentage')} />
          )}

          <FormSwitch sx={12} name="dependent" label={t('form.field.dependent')} />
        </FormGridLayout>
      )}

      <FormErrorList />
      <FormActionButtons
        isMutating={
          createMutation.isPending ||
          createStateMutation.isPending ||
          updateMutation.isPending ||
          updateStateMutation.isPending
        }
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditPersonFamilyRelations
