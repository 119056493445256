import Download from '@mui/icons-material/Download'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { MouseEvent } from 'react'

/* eslint-disable-next-line */
export interface DownloadButtonProps {
  disabled?: boolean
  isFetching: boolean
  onClick: (e: MouseEvent<HTMLButtonElement>) => void
}

export function DownloadButton({ disabled = false, isFetching, onClick }: DownloadButtonProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isFetching && <CircularProgress color="inherit" size={30} />}
      {!isFetching && (
        <IconButton disabled={disabled} onClick={e => onClick(e)}>
          <Download />
        </IconButton>
      )}
    </Box>
  )
}

export default DownloadButton
