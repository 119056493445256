import {
  Countries,
  formTypeSelectOptions,
  useCreatePersonOfficialAddressMutation,
  useSuspenseGetPersonByIdQuery,
  useSuspenseGetPersonOfficialAddressHistoryQuery,
  useRequiredParams,
  useUpdatePersonOfficialAddressMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditPersonOfficialAddressProps {}

export function EditPersonOfficialAddress(props: EditPersonOfficialAddressProps) {
  const { t } = useTranslation()
  const editPersonOfficialAddressSchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    country: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }).min(1, t('form.validation.countryrequired')),
    street: string().nullable(),
    houseNumber: string().nullable(),
    houseNumberSuffix: string().nullable(),
    zipCode: string().nullable(),
    city: string().nullable(),
    postOffice: string().nullable().optional(),
    county: string().nullable()
  })
  type EditPersonOfficialAddressForm = TypeOf<typeof editPersonOfficialAddressSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { countryOptions } = formTypeSelectOptions
  const { refetch: refetchPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })

  const { data: getPersonOfficialAddresses, refetch: refetchAddress } = useSuspenseGetPersonOfficialAddressHistoryQuery(
    {
      personId: params.id
    }
  )

  const officialAddresses = getPersonOfficialAddresses.personOfficialAddressHistory.current
  const [selectedAddress, setSelectedAddress] = useState(officialAddresses)
  const [addNewAddress, setAddNewAddress] = useState(false)
  const createMutation = useCreatePersonOfficialAddressMutation()
  const updateMutation = useUpdatePersonOfficialAddressMutation()

  const form = useForm<EditPersonOfficialAddressForm>({
    resolver: zodResolver(editPersonOfficialAddressSchema),
    defaultValues: {
      startDate: selectedAddress?.startDate ? parseISO(selectedAddress.startDate) : undefined,
      endDate: selectedAddress?.endDate ? parseISO(selectedAddress.endDate) : null,
      country: selectedAddress?.country?.code,
      street: selectedAddress?.street,
      houseNumber: selectedAddress?.houseNumber,
      houseNumberSuffix: selectedAddress?.houseNumberSuffix,
      zipCode: selectedAddress?.zipCode,
      city: selectedAddress?.city,
      postOffice: selectedAddress?.postOffice,
      county: selectedAddress?.county
    }
  })

  const handleOnSubmit = async (personOfficialAddress: EditPersonOfficialAddressForm) => {
    const addressModel = {
      personId: params.id,
      street: personOfficialAddress.street,
      houseNumber: personOfficialAddress.houseNumber,
      houseNumberSuffix: personOfficialAddress.houseNumberSuffix,
      zipCode: personOfficialAddress.zipCode,
      city: personOfficialAddress.city,
      postOffice: personOfficialAddress.postOffice,
      county: personOfficialAddress.county,
      country: personOfficialAddress.country
    }
    if (!selectedAddress) {
      await createMutation
        .mutateAsync({
          creatPersonAddressCommand: {
            startDate: personOfficialAddress.startDate && personOfficialAddress.startDate,
            ...addressModel
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updatePersonAddressCommand: {
            personAddressId: selectedAddress?.id || '',
            ...addressModel
          }
        })
        .then(() => {
          refetchPersonById()
          refetchAddress()
          closeFlyIn()
        })
    }
  }

  const countryWatch = useWatch({ control: form.control, name: 'country' })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.officialaddress.title')}</Typography>
      <Grid className="bold" container>
        <Grid xs={4} item>
          <Typography>{t('form.field.startdate')}</Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography>{t('form.field.enddate')}</Typography>
        </Grid>
        <Grid xs={4} item>
          <Typography>{t('form.field.street')}</Typography>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {getPersonOfficialAddresses?.personOfficialAddressHistory?.history && (
            <RadioGroup
              aria-labelledby="address-history"
              value={selectedAddress?.id}
              onChange={(_e, addressId) => {
                const selectedAddress = getPersonOfficialAddresses?.personOfficialAddressHistory?.history?.find(
                  x => x.id === addressId
                )
                setSelectedAddress(selectedAddress)
                form.reset({
                  startDate: parseISO(selectedAddress?.startDate),
                  endDate: selectedAddress?.endDate ? parseISO(selectedAddress?.endDate) : null,
                  country: selectedAddress?.country?.code,
                  county: selectedAddress?.county ?? '',
                  city: selectedAddress?.city,
                  street: selectedAddress?.street,
                  zipCode: selectedAddress?.zipCode,
                  houseNumber: selectedAddress?.houseNumber,
                  houseNumberSuffix: selectedAddress?.houseNumberSuffix
                })
              }}
            >
              {getPersonOfficialAddresses?.personOfficialAddressHistory?.history?.map((address, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <Typography>
                      <Radio
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        value={address.id}
                      />
                      {parseISO(address.startDate).toLocaleDateString()}
                    </Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography>{address.endDate && parseISO(address.endDate).toLocaleDateString()}</Typography>
                  </Grid>
                  <Grid xs={4} item>
                    <Typography>{address.street}</Typography>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.reset({
                startDate: undefined,
                endDate: null,
                country: undefined
              })
              setAddNewAddress(true)
              setSelectedAddress(null)
            }}
          >
            {t('flyin.editperson.addaddress')}
          </Button>
        </Grid>
      </FormGridLayout>

      {(selectedAddress || addNewAddress) && (
        <>
          <FormGridLayout hasPaddingTop hasBorderBottom>
            <FormDatepicker
              sx={6}
              name="startDate"
              label={`${t('form.field.startdate')} *`}
              disabled={!addNewAddress}
            />

            <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} disabled={true} />

            <FormSelect sx={12} name="country" label={`${t('form.field.country')} *`} options={countryOptions} />
          </FormGridLayout>

          <FormGridLayout hasPaddingTop>
            <FormInput sx={12} name="street" label={t('form.field.street')} />

            <FormInput sx={6} name="houseNumber" label={t('form.field.housenumber')} />

            <FormInput sx={6} name="houseNumberSuffix" label={t('form.field.housenumbersuffix')} />

            <FormInput sx={4} name="zipCode" label={t('form.field.zipcode')} />

            <FormInput sx={8} name="city" label={t('form.field.city')} />

            {countryWatch === Countries.PL && (
              <FormInput sx={12} name="postOffice" label={t('form.field.postoffice')} />
            )}

            <FormInput sx={12} name="county" label={t('form.field.county')} />
          </FormGridLayout>

          <FormErrorList />
          <FormActionButtons
            isMutating={createMutation.isPending || updateMutation.isPending}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default EditPersonOfficialAddress
