import { groupBy } from '@epix-web-apps/core'
import { List } from '@mui/material'
import { ExportJobModelWithoutProcessingJobType } from '../../generic-steps'
import ExportJobListItem from '../export-job-download-link/export-job-download-link'

interface ExportJobListProps {
  exportJobs: Array<ExportJobModelWithoutProcessingJobType>
  payrollProviderCode: string | undefined
  payrollProviderName: string | undefined
}

export function ExportJobList({ exportJobs, payrollProviderCode, payrollProviderName }: ExportJobListProps) {
  const groupedExportJobs = groupBy(exportJobs, (item) => item.exportType);
 
  return (
    <List>
      {exportJobs.map((exportJob: ExportJobModelWithoutProcessingJobType) => (
        <ExportJobListItem
          key={exportJob.id}
          exportJob={exportJob}
          payrollProviderCode={payrollProviderCode}
          payrollProviderName={payrollProviderName}
          showExportJobByDocumentName={groupedExportJobs[exportJob.exportType].length > 1}
        />
      ))}
    </List>
  )
}

export default ExportJobList
