import {
  GraphqlError,
  ROLE,
  useRequiredParams,
  useSuspenseGetUserByIdQuery,
  useUpdateUserRolesMutation
} from '@epix-web-apps/core'
import { DetailPageBaseQueryParams, FormActionButtons, FormContainer, FormErrorList } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { InfoOutlined } from '@mui/icons-material'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface UserCustomConfigurationProps {}

export function UserCustomConfiguration(props: UserCustomConfigurationProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError | Error>>([])

  const updateUserRolesMutation = useUpdateUserRolesMutation()

  const editUserCustomSchema = object({
    createPerson: boolean(),
    managePeopleContracts: boolean(),
    managePersonalCalendar: boolean(),
    manageSalary: boolean(),
    manageSelfService: boolean(),
    viewCalculatedData: boolean(),
    viewValidationErrors: boolean(),
    manageTasksAndProcesses: boolean(),
    finishPayrollClosure: boolean(),
    viewAnalytics: boolean(),
    viewProductivityAnalytics: boolean(),
    viewWorkforceAnalytics: boolean(),
    viewLabourCostAnalytics: boolean(),
    viewReportingAssistant: boolean(),
    manageConfiguration: boolean(),
    managePayrollCodes: boolean(),
    manageContractDocumentTypes: boolean(),
    manageEmployerDocumentTypes: boolean(),
    managePayrollClosureDocuments: boolean(),
    managePayrollOutputReports: boolean(),
    managePayComponentUpdateDocuments: boolean(),
    managePayslipDocuments: boolean(),
    manageFinancialDocuments: boolean(),
    manageCustomDocuments: boolean()
  })

  const { data: getUserById, refetch: refetchUserById } = useSuspenseGetUserByIdQuery({
    userId: params.id
  })

  const currentUserRoles = getUserById.userById.roles.map(x => x.toUpperCase()) || []

  type EditCustomUserForm = TypeOf<typeof editUserCustomSchema>
  const form = useForm<EditCustomUserForm>({
    resolver: zodResolver(editUserCustomSchema),
    defaultValues: {
      createPerson: currentUserRoles.includes(ROLE.CREATEPERSON),
      managePeopleContracts: currentUserRoles.includes(ROLE.MANAGEPEOPLECONTRACTS),
      managePersonalCalendar: currentUserRoles.includes(ROLE.MANAGEPERSONALCALENDAR),
      manageSalary: currentUserRoles.includes(ROLE.MANAGESALARY),
      manageSelfService: currentUserRoles.includes(ROLE.MANAGESELFSERVICE),
      viewCalculatedData: currentUserRoles.includes(ROLE.VIEWCALCULATEDDATA),
      viewValidationErrors: currentUserRoles.includes(ROLE.VIEWVALIDATIONERRORS),
      manageTasksAndProcesses: currentUserRoles.includes(ROLE.MANAGETASKSANDPROCESSES),
      finishPayrollClosure: currentUserRoles.includes(ROLE.FINISHPAYROLLCLOSURE),
      viewAnalytics: currentUserRoles.includes(ROLE.VIEWANALYTICS),
      viewLabourCostAnalytics: currentUserRoles.includes(ROLE.VIEWLABOURCOSTANALYTICS),
      viewReportingAssistant: currentUserRoles.includes(ROLE.MANAGEREPORTINGASSISTANT),
      viewProductivityAnalytics: currentUserRoles.includes(ROLE.VIEWPRODUCTIVITYANALYTICS),
      viewWorkforceAnalytics: currentUserRoles.includes(ROLE.VIEWWORKFORCEANALYTICS),
      manageConfiguration: currentUserRoles.includes(ROLE.MANAGECONFIGURATION),
      managePayrollCodes: currentUserRoles.includes(ROLE.MANAGEPAYROLLCODES),
      manageContractDocumentTypes: currentUserRoles.includes(ROLE.MANAGECONTRACTDOCUMENTTYPES),
      manageEmployerDocumentTypes: currentUserRoles.includes(ROLE.MANAGEEMPLOYERDOCUMENTTYPES),
      managePayrollClosureDocuments: currentUserRoles.includes(ROLE.MANAGEPAYROLLCLOSUREDOCUMENTS),
      managePayrollOutputReports: currentUserRoles.includes(ROLE.MANAGEPAYROLLOUTPUTREPORTS),
      managePayComponentUpdateDocuments: currentUserRoles.includes(ROLE.MANAGEPAYCOMPONENTUPDATEDOCUMENTS),
      managePayslipDocuments: currentUserRoles.includes(ROLE.MANAGEPAYSLIPDOCUMENTS),
      manageFinancialDocuments: currentUserRoles.includes(ROLE.MANAGEFINANCIALDOCUMENTS),
      manageCustomDocuments: currentUserRoles.includes(ROLE.MANAGECUSTOMDOCUMENTS)
    }
  })
  const { control } = form

  const handleOnSubmit = async (user: EditCustomUserForm) => {
    const selectedUserRoles = Object.entries({
      [ROLE.CREATEPERSON]: user.createPerson,
      [ROLE.MANAGEPEOPLECONTRACTS]: user.managePeopleContracts,
      [ROLE.MANAGESALARY]: user.manageSalary,
      [ROLE.MANAGEPERSONALCALENDAR]: user.managePersonalCalendar,
      [ROLE.MANAGESELFSERVICE]: user.manageSelfService,
      [ROLE.VIEWCALCULATEDDATA]: user.viewCalculatedData,
      [ROLE.VIEWVALIDATIONERRORS]: user.viewValidationErrors,
      [ROLE.MANAGETASKSANDPROCESSES]: user.manageTasksAndProcesses,
      [ROLE.FINISHPAYROLLCLOSURE]: user.finishPayrollClosure,
      [ROLE.VIEWANALYTICS]: user.viewAnalytics,
      [ROLE.VIEWPRODUCTIVITYANALYTICS]: user.viewProductivityAnalytics,
      [ROLE.VIEWWORKFORCEANALYTICS]: user.viewWorkforceAnalytics,
      [ROLE.VIEWLABOURCOSTANALYTICS]: user.viewLabourCostAnalytics,
      [ROLE.MANAGEREPORTINGASSISTANT]: user.viewReportingAssistant,
      [ROLE.MANAGECONFIGURATION]: user.manageConfiguration,
      [ROLE.MANAGEPAYROLLCODES]: user.managePayrollCodes,
      [ROLE.MANAGEEMPLOYERDOCUMENTTYPES]: user.manageEmployerDocumentTypes,
      [ROLE.MANAGECONTRACTDOCUMENTTYPES]: user.manageContractDocumentTypes,
      [ROLE.MANAGEPAYROLLCLOSUREDOCUMENTS]: user.managePayrollClosureDocuments,
      [ROLE.MANAGEPAYROLLOUTPUTREPORTS]: user.managePayrollOutputReports,
      [ROLE.MANAGEPAYCOMPONENTUPDATEDOCUMENTS]: user.managePayComponentUpdateDocuments,
      [ROLE.MANAGEPAYSLIPDOCUMENTS]: user.managePayslipDocuments,
      [ROLE.MANAGEFINANCIALDOCUMENTS]: user.manageFinancialDocuments,
      [ROLE.MANAGECUSTOMDOCUMENTS]: user.manageCustomDocuments
    }).map(([key, value]) => ({ key, value }))
    const userRolesToAdd = selectedUserRoles.filter(x => x.value).map(x => x.key.toString())
    const userRolesToDelete = selectedUserRoles.filter(x => !x.value).map(x => x.key.toString())
    const newUserRoles = [
      ...new Set(
        currentUserRoles
          .map(x => x.toUpperCase())
          .filter(x => !userRolesToDelete.includes(x))
          .concat(userRolesToAdd.filter(x => !currentUserRoles.includes(x)))
      )
    ]

    await updateUserRolesMutation
      .mutateAsync({
        updateUserRolesCommand: {
          id: params.id || '',
          roles: newUserRoles
        }
      })
      .then(() => {
        refetchUserById()
        setBackendErrors([])
      })
      .catch(e => setBackendErrors([e]))
  }

  const canManagePeopleContracts = useWatch({ control, name: `managePeopleContracts` })
  const canManageTasksAndProcesses = useWatch({ control, name: `manageTasksAndProcesses` })
  const canViewAnalytics = useWatch({ control, name: `viewAnalytics` })
  const canManageConfiguration = useWatch({ control, name: `manageConfiguration` })
  const canManageEmployerDocumentTypes = useWatch({ control, name: `manageEmployerDocumentTypes` })

  useEffect(() => {
    form.setValue('viewWorkforceAnalytics', form.getValues('viewAnalytics'))
  }, [])

  return (
    <FormContainer sx={{ width: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Box
        sx={{
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          gap: 1
        }}
      >
        <Typography variant="h5">{t('usercustomconfiguration.title.generalsettings')}</Typography>
        <Tooltip title={t('usercustomconfiguration.tooltip.allowedproviders')}>
          <IconButton size="small">
            <InfoOutlined />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 5 }}>
        <Box>
          <FormSwitch sx={12} name="createPerson" label={t('usercustomconfiguration.form.field.createperson')} />
          <FormSwitch
            sx={12}
            name="managePeopleContracts"
            label={t('usercustomconfiguration.form.field.managepeoplecontracts')}
            onChange={e => {
              form.resetField('manageSalary', { defaultValue: e })
              form.resetField('managePersonalCalendar', { defaultValue: e })
              form.resetField('manageContractDocumentTypes', { defaultValue: e })
              form.resetField('manageSelfService', { defaultValue: e })
              form.resetField('viewCalculatedData', { defaultValue: e })
            }}
          />
          <Box
            sx={{
              ml: 6
            }}
          >
            <FormSwitch
              sx={12}
              name="manageSalary"
              label={t('usercustomconfiguration.form.field.managesalary')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="managePersonalCalendar"
              label={t('usercustomconfiguration.form.field.managepersonalcalendar')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="manageContractDocumentTypes"
              label={t('usercustomconfiguration.form.field.managecontractdocumenttypes')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="manageSelfService"
              label={t('usercustomconfiguration.form.field.manageselfservice')}
              disabled={!canManagePeopleContracts}
            />
            <FormSwitch
              sx={12}
              name="viewCalculatedData"
              label={t('usercustomconfiguration.form.field.viewcalculateddata')}
              disabled={!canManagePeopleContracts}
            />
          </Box>
        </Box>
        <Box>
          <FormSwitch
            sx={12}
            name="viewValidationErrors"
            label={t('usercustomconfiguration.form.field.viewvalidationerrors')}
          />
          <FormSwitch
            sx={12}
            name="manageTasksAndProcesses"
            label={t('usercustomconfiguration.form.field.managetasksandprocesses')}
            onChange={e => {
              if (!e) {
                form.resetField('finishPayrollClosure', { defaultValue: e })
              }
            }}
          />
          <Box
            sx={{
              ml: 6,
              mb: 2
            }}
          >
            <FormSwitch
              sx={12}
              name="finishPayrollClosure"
              disabled={!canManageTasksAndProcesses}
              label={t('usercustomconfiguration.form.field.finishpayrollclosure')}
            />
          </Box>
          <FormSwitch
            sx={12}
            name="viewAnalytics"
            label={t('usercustomconfiguration.form.field.viewanalytics')}
            onChange={e => {
              form.resetField('viewWorkforceAnalytics', { defaultValue: e })
              if (!e) {
                form.resetField('viewProductivityAnalytics', { defaultValue: e })
                form.resetField('viewLabourCostAnalytics', { defaultValue: e })
                form.resetField('viewReportingAssistant', { defaultValue: e })
              }
            }}
          />
          <Box
            sx={{
              ml: 6
            }}
          >
            <FormSwitch
              sx={12}
              disabled
              name="viewWorkforceAnalytics"
              label={t('usercustomconfiguration.form.field.viewworkforceanalytics')}
            />
            <FormSwitch
              sx={12}
              name="viewProductivityAnalytics"
              disabled={!canViewAnalytics}
              label={t('usercustomconfiguration.form.field.viewproductivityanalytics')}
            />
            <FormSwitch
              sx={12}
              name="viewLabourCostAnalytics"
              disabled={!canViewAnalytics}
              label={t('usercustomconfiguration.form.field.viewlabourcostanalytics')}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <FormSwitch
                sx={12}
                name="viewReportingAssistant"
                disabled={!canViewAnalytics}
                label={t('usercustomconfiguration.form.field.viewreportingassistant')}
              />
              <Tooltip title={t('usercustomconfiguration.tooltip.viewreportingassistant')}>
                <InfoOutlined color="error" />
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ml: 6
          }}
        >
          <FormSwitch
            sx={12}
            name="manageEmployerDocumentTypes"
            label={t('usercustomconfiguration.form.field.manageemployerdocumenttypes')}
            onChange={e => {
              form.resetField('managePayrollClosureDocuments', { defaultValue: e })
              form.resetField('managePayrollOutputReports', { defaultValue: e })
              form.resetField('managePayComponentUpdateDocuments', { defaultValue: e })
              form.resetField('managePayslipDocuments', { defaultValue: e })
              form.resetField('manageFinancialDocuments', { defaultValue: e })
              form.resetField('manageCustomDocuments', { defaultValue: e })
            }}
          />
          <Box
            sx={{
              ml: 6,
              mb: 2
            }}
          >
            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="managePayrollClosureDocuments"
              label={t('usercustomconfiguration.form.field.managepayrollclosuredocuments')}
            />
            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="managePayrollOutputReports"
              label={t('usercustomconfiguration.form.field.managePayrollOutputReports')}
            />
            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="managePayComponentUpdateDocuments"
              label={t('usercustomconfiguration.form.field.managepaycomponentupdatedocuments')}
            />

            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="managePayslipDocuments"
              label={t('usercustomconfiguration.form.field.managepayslipdocuments')}
            />
            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="manageFinancialDocuments"
              label={t('usercustomconfiguration.form.field.managefinancialdocuments')}
            />
            <FormSwitch
              sx={12}
              disabled={!canManageEmployerDocumentTypes}
              name="manageCustomDocuments"
              label={t('usercustomconfiguration.form.field.managecustomdocuments')}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FormSwitch
              sx={12}
              name="manageConfiguration"
              label={t('usercustomconfiguration.form.field.manageconfiguration')}
              onChange={e => {
                form.resetField('managePayrollCodes', { defaultValue: e })
              }}
            />
            <Tooltip title={t('usercustomconfiguration.tooltip.manageproviders')}>
              <InfoOutlined />
            </Tooltip>
          </Box>

          <Box
            sx={{
              ml: 6,
              mb: 2
            }}
          >
            <FormSwitch
              sx={12}
              disabled={!canManageConfiguration}
              name="managePayrollCodes"
              label={t('usercustomconfiguration.form.field.managepayrollcodes')}
            />
          </Box>
        </Box>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateUserRolesMutation.isPending} />
    </FormContainer>
  )
}

export default UserCustomConfiguration
