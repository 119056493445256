import { useDeleteSmartCalculationRuleMutation, useSuspenseGetSmartCalculationRuleByIdQuery } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, useFlyIn } from '@epix-web-apps/ui'
import { Box, Divider, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface RemoveCalculationRuleProps {
  smartPayComponentHistoryId: string
  smartOutputCodeId: string
  smartCalculationRuleId: string
  refetch: () => void
}

function RemoveCalculationRule({
  smartPayComponentHistoryId,
  smartOutputCodeId,
  smartCalculationRuleId,
  refetch
}: RemoveCalculationRuleProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const form = useForm()
  const deleteMutation = useDeleteSmartCalculationRuleMutation()
  const { data: getRuleById } = useSuspenseGetSmartCalculationRuleByIdQuery({
    smartPayComponentHistoryId: smartPayComponentHistoryId,
    smartOutputCodeId: smartOutputCodeId,
    smartRuleId: smartCalculationRuleId
  })

  function handleDelete() {
    deleteMutation
      .mutateAsync({
        deleteSmartCalculationRuleCommand: {
          smartPayComponentHistoryId: smartPayComponentHistoryId,
          smartOutputCodeId: smartOutputCodeId,
          smartRuleId: smartCalculationRuleId
        }
      })
      .then(() => {
        refetch()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleDelete)}>
      <Typography variant="h4">{t('removecalculationrule.delete.title')}</Typography>

      <Box sx={{ pb: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography>{`${getRuleById.smartCalculationRuleById.ruleOperator} - ${getRuleById.smartCalculationRuleById.fixedValue}`}</Typography>
        </Box>
        <Divider sx={{ my: '0.5rem' }} />
      </Box>

      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.delete')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveCalculationRule
