import {
  GetPartnerContractDetailTypeByIdQuery,
  useCreatePartnerContractDetailTypeMutation,
  useGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetPartnerContractDetailTypeByIdQuery,
  useUpdatePartnerContractDetailTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

export interface AddPartnerContractDetailTypeProps {
  listDefinitionKey: string
}

export interface EditPartnerContractDetailTypeProps extends AddPartnerContractDetailTypeProps {
  id: string
}

export function AddPartnerContractDetailType({ listDefinitionKey }: AddPartnerContractDetailTypeProps) {
  return AddEditPartnerContractDetailType(listDefinitionKey)
}

export function EditPartnerContractDetailType({ listDefinitionKey, id }: EditPartnerContractDetailTypeProps) {
  const getPartnerContractDetailTypeByIdQuery = useSuspenseGetPartnerContractDetailTypeByIdQuery({
    id: id
  })

  return AddEditPartnerContractDetailType(listDefinitionKey, getPartnerContractDetailTypeByIdQuery)
}

function AddEditPartnerContractDetailType(
  listDefinitionKey: string,
  getPartnerContractDetailTypeByIdQuery?: UseSuspenseQueryResult<GetPartnerContractDetailTypeByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditPartnerContractDetailTypeSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    }).min(1, t('form.validation.coderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired'))
  })

  type AddEditPartnerContractDetailTypeForm = TypeOf<typeof addEditPartnerContractDetailTypeSchema>

  const getPartnerContractDetailTypeById = getPartnerContractDetailTypeByIdQuery?.data

  const { refetch: refetchPartnerContractDetailTypes } = useGetAllPartnerContractDetailTypesByListDefinitionKeyQuery(
    {
      listDefinitionKey: listDefinitionKey,
      limit: -1,
      offset: 0
    },
    { enabled: false }
  )

  const form = useForm<AddEditPartnerContractDetailTypeForm>({
    resolver: zodResolver(addEditPartnerContractDetailTypeSchema),
    defaultValues: {
      code: getPartnerContractDetailTypeById?.partnerContractDetailTypeById.key,
      description: getPartnerContractDetailTypeById?.partnerContractDetailTypeById.value
    }
  })

  const createMutation = useCreatePartnerContractDetailTypeMutation()
  const updateMutation = useUpdatePartnerContractDetailTypeMutation()

  const handleOnSubmit = (newPartnerContractDetailType: AddEditPartnerContractDetailTypeForm) => {
    if (!getPartnerContractDetailTypeById) {
      createMutation
        .mutateAsync({
          createPartnerContractDetailTypeCommand: {
            key: newPartnerContractDetailType.code,
            value: newPartnerContractDetailType.description,
            listDefinitionKey: listDefinitionKey
          }
        })
        .then(closeFlyIn)
    } else {
      updateMutation
        .mutateAsync({
          updatePartnerContractDetailTypeCommand: {
            id: getPartnerContractDetailTypeById.partnerContractDetailTypeById.id,
            key: newPartnerContractDetailType.code,
            value: newPartnerContractDetailType.description
          }
        })
        .then(() => {
          refetchPartnerContractDetailTypes()
          getPartnerContractDetailTypeByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditpartnercontractdetailtype.title')} </Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.code')}*`} />
        <FormInput sx={12} name="description" label={`${t('form.field.description')}*`} />
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditPartnerContractDetailType
