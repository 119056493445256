import styled from '@emotion/styled'
import {
  InjuryDetailModel,
  IsDateWithinRange,
  OrderDirection,
  ROLE,
  useNavigateWithParams,
  useSuspenseGetAllContractsQuery,
  useSuspenseGetAllInjuriesByPersonIdQuery,
  useSuspenseGetMeQuery,
  useRequiredParams,
  useSuspenseGetPersonByIdQuery
} from '@epix-web-apps/core'
import {
  CanView,
  DetailPageBaseQueryParams,
  DetailPageContent,
  DetailPageLayout,
  DetailPageSidebar,
  EpixAvatar,
  LoadingOverlay,
  People,
} from '@epix-web-apps/ui'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import EventIcon from '@mui/icons-material/Event'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Button, Divider, Link, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContractTabs } from '../contract-details-components/contract-tab-general/contract-tab-general'
import { IcpCodeBadge } from '../icp-components/icp-code-badge'

const ContractInfo = styled(Box)(() => ({
  display: 'flex',
  marginBottom: '1rem',
  p: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

/* eslint-disable-next-line */
export interface PeopleDetailSidebarProps {}

export function PeopleDetailSidebar(props: PeopleDetailSidebarProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateWithParams = useNavigateWithParams()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { data: me } = useSuspenseGetMeQuery()

  const { data: getPersonSidebarInfoById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const person = getPersonSidebarInfoById?.personById
  const { data: getAllContracts } = useSuspenseGetAllContractsQuery({
    personId: params.id,
    orderDirection: OrderDirection.Desc
  })
  const contracts = getAllContracts?.allContracts ?? []
  const anyValidations = contracts?.find(c => c.validationWarningCount > 0 || c.validationErrorCount > 0)

  const { data: registeredInjuries } = useSuspenseGetAllInjuriesByPersonIdQuery({
    personId: person?.id
  })

  const getInjuriesWithOldestDate = (injuries: any): InjuryDetailModel | null => {
    const matchingInjuries: InjuryDetailModel[] = []

    injuries?.map((injury: any) => {
      injury.contractHistoryModels.map((contractHistory: any) => {
        contractHistory.injuryHistoryModels.map((injuryHistory: any) => {
          if (IsDateWithinRange(new Date(), parseISO(injuryHistory.startDate), parseISO(injuryHistory.endDate))) {
            matchingInjuries.push(injury)
          }
          return null
        })
      })
    })

    if (matchingInjuries.length === 0) {
      return null
    }

    return matchingInjuries.reduce((oldestInjury: InjuryDetailModel, currentInjury: InjuryDetailModel) => {
      return oldestInjury.injuryDate < currentInjury.injuryDate ? oldestInjury : currentInjury
    })
  }

  const oldestInjury = getInjuriesWithOldestDate(registeredInjuries?.allInjuriesByPersonId)

  return (
    <DetailPageLayout>
      <DetailPageSidebar>
        <EpixAvatar
          sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}
          personId={params?.id || ''}
          editable
        />
        <Box sx={{ marginBottom: '.5rem' }}>
          {person?.company?.functionTitle && <Typography variant="h6">{person?.company?.functionTitle}</Typography>}
          {person?.company?.startedOn && (
            <p>
              {t('peopledetailpage.companyinfo.startedon')} {parseISO(person?.company?.startedOn).toLocaleDateString()}
            </p>
          )}
          {person?.company?.personNumber && (
            <p>
              {t('peopledetailpage.companyinfo.personnumber')} : {person?.company?.personNumber}
            </p>
          )}
        </Box>
        <Box sx={{ marginBottom: '.5rem' }}>
          {(person?.validationErrorCount !== 0 || person?.validationWarningCount !== 0) && (
            <Link
              sx={{ mt: 0.75 }}
              color={theme.palette.warning.main}
              underline="always"
              onClick={() => {
                navigateWithParams(People.PEOPLE_ID_VALIDATION_ERRORS(`${person?.id}`))
              }}
            >
              {person?.validationErrorCount !== 0 && (
                <Box sx={{ display: 'flex', color: theme.palette.error.light }}>
                  <ErrorOutlineOutlinedIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                  <span>
                    {person?.validationErrorCount} {t('peopledetailpage.sidebar.button.validationerrors')}
                  </span>
                </Box>
              )}
              {person?.validationWarningCount !== 0 && (
                <Box sx={{ display: 'flex', color: theme.palette.warning.light }}>
                  <WarningAmberOutlinedIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                  <span>
                    {person?.validationWarningCount} {t('peopledetailpage.sidebar.button.validationwarnings')}
                  </span>
                </Box>
              )}
            </Link>
          )}
        </Box>
        {oldestInjury && (
          <Box sx={{ marginBottom: '.5rem', display: 'flex' }}>
            <LocalHospitalOutlinedIcon
              sx={{
                marginRight: '0.5rem',
                fontSize: '1rem',
                color: theme.palette.error.light
              }}
            />
            <span>
              {t('persondetailpage.injury.injured')}{' '}
              {parseISO(oldestInjury?.injuryDate).toLocaleDateString(me?.me.locale.locale)}
            </span>
          </Box>
        )}
        <Button
          variant="outlined"
          color="primary"
          size="small"
          fullWidth
          onClick={() => navigateWithParams(People.PEOPLE_ID_DETAILS(`${person?.id}`))}
        >
          {t('peopledetailpage.button.gotopersoninfo')}
        </Button>
        <Divider sx={{ borderColor: theme.palette.primary.main }} />
        <Typography variant="h6">{t('peopledetailpage.sidebar.currentcontract.title')}</Typography>
        {contracts.map(contract => (
          <ContractInfo key={contract.id}>
            <IcpCodeBadge sx={{ marginRight: '1rem' }} icpCode={contract.icp.code} />
            <Box display={'grid'}>
              <Link
                color="primary"
                underline="always"
                onClick={() => {
                  navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(`${person?.id}`, contract.id), {
                    tabIndex: ContractTabs.OVERVIEW.toString()
                  })
                }}
              >
                <span>
                  {parseISO(contract.startDate).toLocaleDateString()} -{' '}
                  {contract.endDate ? parseISO(contract.endDate).toLocaleDateString() : '...'}
                </span>
              </Link>
              {contract.employeeNumber && (
                <span>
                  {t('peopledetailpage.sidebar.currentcontract.employeenumber')} : {contract.employeeNumber}
                </span>
              )}
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGESALARY]}>
                {contract?.payGroup?.hasPayComponentManagement && (
                  <Link
                    color="primary"
                    underline="always"
                    onClick={() => {
                      navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(`${person?.id}`, contract.id), {
                        tabIndex: ContractTabs.SALARY.toString()
                      })
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <SavingsOutlinedIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                      <span>{t('peopledetailpage.sidebar.button.paycomponent')}</span>
                    </Box>
                  </Link>
                )}
              </CanView>
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPERSONALCALENDAR]}>
                {contract?.payGroup?.hasCalendarManagement && (
                  <Link
                    color="primary"
                    underline="always"
                    onClick={() => {
                      navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(`${person?.id}`, contract.id), {
                        tabIndex: ContractTabs.CALENDAR.toString()
                      })
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <EventIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                      <span>{t('peopledetailpage.sidebar.button.calendar')}</span>
                    </Box>
                  </Link>
                )}
              </CanView>
              {(contract.validationErrorCount !== 0 || contract.validationWarningCount !== 0) && (
                <Link
                  color={theme.palette.warning.main}
                  underline="always"
                  onClick={() => {
                    navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(`${person?.id}`, contract.id), {
                      tabIndex: ContractTabs.VALIDATION_ERRORS.toString()
                    })
                  }}
                >
                  {contract.validationErrorCount !== 0 && (
                    <Box sx={{ display: 'flex', color: theme.palette.error.light }}>
                      <ErrorOutlineOutlinedIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                      <span>
                        {contract.validationErrorCount} {t('peopledetailpage.sidebar.button.validationerrors')}
                      </span>
                    </Box>
                  )}
                  {contract.validationWarningCount !== 0 && (
                    <Box
                      sx={{
                        display: 'flex',
                        color: theme.palette.warning.light
                      }}
                    >
                      <WarningAmberOutlinedIcon sx={{ marginRight: '0.5rem', fontSize: '1rem' }} />
                      <span>
                        {contract.validationWarningCount} {t('peopledetailpage.sidebar.button.validationwarnings')}
                      </span>
                    </Box>
                  )}
                </Link>
              )}
            </Box>
          </ContractInfo>
        ))}

        <Link
          color="primary"
          underline="always"
          onClick={() => navigateWithParams(People.PEOPLE_ID_CONTRACTS(`${person?.id}`))}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>{t('peopledetailpage.sidebar.button.showallcontracts')}</span>
            {anyValidations && (
              <WarningAmberOutlinedIcon
                sx={{
                  color: theme.palette.error.light,
                  marginRight: '0.5rem',
                  fontSize: '1rem',
                  ml: 1
                }}
              />
            )}
          </Box>
        </Link>
        <CanView roles={[ROLE.ADMIN, ROLE.MANAGETEAMSANDROLES]}>
          <Divider sx={{ borderColor: theme.palette.primary.main }} />
          <Button
            variant="outlined"
            color="primary"
            size="small"
            fullWidth
            onClick={() => navigateWithParams(People.PEOPLE_ID_ORGANISATION(`${person?.id}`))}
          >
            {t('peopledetailpage.button.gotoorganisation')}
          </Button>
        </CanView>
      </DetailPageSidebar>
      <DetailPageContent>
        <Suspense fallback={<LoadingOverlay />}>
          <Outlet />
        </Suspense>
      </DetailPageContent>
    </DetailPageLayout>
  )
}

export default PeopleDetailSidebar
