import {
  FormSelectOption,
  formTypeSelectOptions,
  GetUserByEmailQuery,
  GraphqlError,
  isAuthorized,
  ROLE,
  useCreateUserMutation,
  useGetUserByEmailQuery,
  useGetUserByPersonIdQuery,
  useSuspenseGetAllLocalesQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetPersonByIdQuery,
  useUpdateUserMutation,
  useRequiredParams,
  useUpdateUserRolesMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn,
  useGlobalStore,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, literal, object, string, TypeOf } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditSelfServiceAccessProps {}

export function EditSelfServiceAccess(props: EditSelfServiceAccessProps) {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const editSelfServiceSchema = object({
    hasAccess: boolean(),
    selfServiceEmail: string().email(t('form.validation.invalidemail')).or(literal('')),
    localeCode: string({
      required_error: t('form.validation.languagerequired'),
      invalid_type_error: t('form.validation.languagerequired')
    }).min(1, t('form.validation.languagerequired')),
    currency: string({
      required_error: t('form.validation.currencyrequired'),
      invalid_type_error: t('form.validation.currencyrequired')
    }).min(1, t('form.validation.currencyrequired'))
  })

  type EditSelfServiceForm = TypeOf<typeof editSelfServiceSchema>
  const params = useRequiredParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const { me } = useGlobalStore()
  const { currencyOptions } = formTypeSelectOptions
  const { refetch: refetchMe } = useSuspenseGetMeQuery()
  const { data: getAllLocales } = useSuspenseGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true
  })
  const localeOptions = getAllLocales?.locales?.map(x => new FormSelectOption(x.locale, x.language))
  const personId = params.id
  const { data: getPersonById } = useSuspenseGetPersonByIdQuery({
    personId: personId
  })
  const { data: getUserByPersonId, refetch: refetchUserByPersonId } = useGetUserByPersonIdQuery(
    {
      personId: personId
    },
    {
      throwOnError: false
    }
  )

  const upsertSelfServiceUser = async (editSelfServiceForm: EditSelfServiceForm) => {
    let getUserByEmail: GetUserByEmailQuery | undefined

    try {
      getUserByEmail = await queryClient.fetchQuery<GetUserByEmailQuery>({
        queryKey: useGetUserByEmailQuery.getKey({ userEmail: editSelfServiceForm.selfServiceEmail ?? '' }),
        queryFn: useGetUserByEmailQuery.fetcher({ userEmail: editSelfServiceForm.selfServiceEmail ?? '' })
      })
    } catch {
      // just catch
    }

    const coupledUser = getUserByPersonId?.userByPersonId
    const userToCouple = getUserByEmail?.userByEmail
    const userToCoupleAlreadyInSelfServiceRole = isAuthorized(userToCouple?.roles, [ROLE.SELFSERVICEUSER])
    const userToUpdateIsNotTheSameUser = userToCouple?.email !== coupledUser?.email

    if (editSelfServiceForm?.hasAccess) {
      if (coupledUser && isSelfServiceUser) {
        await updateUserMutation.mutateAsync({
          updateUserCommand: {
            id: coupledUser.id,
            firstName: coupledUser.firstName || '',
            lastName: coupledUser.lastName || '',
            roleNames: coupledUser.roles.filter(x => x.toUpperCase() !== ROLE.SELFSERVICEUSER.toString()),
            currency: coupledUser.currency,
            locale: coupledUser.locale.locale,
            isActive: coupledUser.isActive,
            personId: null
          }
        })
      }
      if (userToCouple) {
        if (userToCoupleAlreadyInSelfServiceRole && userToUpdateIsNotTheSameUser) {
          setBackendErrors([
            {
              message: t('form.validation.alreadyinselfservicerole'),
              name: 'alreadyinselfservicerole'
            } as GraphqlError
          ])
        } else {
          const userRoles = userToCouple.roles
          userRoles.push(ROLE.SELFSERVICEUSER)
          await updateUserMutation
            .mutateAsync({
              updateUserCommand: {
                id: userToCouple.id,
                firstName: userToCouple.firstName || '',
                lastName: userToCouple.lastName || '',
                roleNames: userRoles,
                currency: userToUpdateIsNotTheSameUser ? userToCouple.currency : editSelfServiceForm.currency,
                locale: userToUpdateIsNotTheSameUser ? userToCouple.locale.locale : editSelfServiceForm.localeCode,
                isActive: userToCouple.isActive,
                personId: personId
              }
            })
            .then(() => {
              refetchUserByPersonId()
              refetchMe()
              closeFlyIn()
            })
            .catch(e => setBackendErrors([e]))
        }
      } else {
        await createUserMutation
          .mutateAsync({
            createUserCommand: {
              personId: personId,
              firstName: getPersonById?.personById.firstName || '',
              lastName: getPersonById?.personById.lastName || '',
              roleNames: [ROLE.SELFSERVICEUSER],
              email: editSelfServiceForm.selfServiceEmail,
              currencyCode: editSelfServiceForm.currency,
              localeCode: editSelfServiceForm.localeCode,
              isActive: true
            }
          })
          .then(() => {
            refetchUserByPersonId()
            refetchMe()
            closeFlyIn()
          })
          .catch(e => setBackendErrors([e]))
      }
    } else {
      await updateUserRoleMutation
        .mutateAsync({
          updateUserRolesCommand: {
            id: coupledUser?.id || '',
            roles: coupledUser?.roles.filter(x => x.toUpperCase() !== ROLE.SELFSERVICEUSER.toString()) || []
          }
        })
        .then(() => {
          refetchUserByPersonId()
          refetchMe()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const isSelfServiceUser = isAuthorized(getUserByPersonId?.userByPersonId?.roles, [ROLE.SELFSERVICEUSER])
  const [hasAccess, setHasAccess] = useState(isSelfServiceUser)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const createUserMutation = useCreateUserMutation()
  const updateUserMutation = useUpdateUserMutation()
  const updateUserRoleMutation = useUpdateUserRolesMutation()

  const form = useForm<EditSelfServiceForm>({
    resolver: zodResolver(editSelfServiceSchema),
    defaultValues: {
      hasAccess: isSelfServiceUser,
      selfServiceEmail: getUserByPersonId?.userByPersonId?.email || getPersonById?.personById.workEmail || '',
      localeCode: getUserByPersonId?.userByPersonId?.locale.locale || me?.locale?.locale || '',
      currency: getUserByPersonId?.userByPersonId?.currency || me?.currency || ''
    }
  })

  const handleOnSubmit = async (editSelfService: EditSelfServiceForm) => {
    await upsertSelfServiceUser(editSelfService)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editselfserviceaccess.title')}</Typography>

      <FormGridLayout>
        <FormSwitch
          sx={12}
          name="hasAccess"
          label={t('form.field.selfservice.hasaccess')}
          onChange={hasAccess => setHasAccess(hasAccess)}
        />

        <FormInput sx={12} name="selfServiceEmail" label={t('form.field.selfservice.email')} disabled={!hasAccess} />

        <FormSelect
          sx={6}
          name="localeCode"
          label={`${t('form.field.defaultlanguage')} *`}
          options={localeOptions}
          disabled={!hasAccess}
        />

        <FormSelect
          sx={6}
          name="currency"
          label={`${t('form.field.defaultcurrency')} *`}
          options={currencyOptions}
          disabled={!hasAccess}
        />
      </FormGridLayout>

      <Typography color={'gray'} sx={{ fontStyle: 'italic' }}>
        {t('contractdetailpage.selfserviceaccess.note')}
      </Typography>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createUserMutation.isPending || updateUserMutation.isPending || updateUserRoleMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default EditSelfServiceAccess
