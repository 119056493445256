import styled from '@emotion/styled'
import {
  ABSENCE_REQUEST_STATUS_TYPE,
  DEFAULT_BASED_ON_WORKSCHEDULE,
  useSuspenseGetCalendarForDateRangeQuery
} from '@epix-web-apps/core'
import { Typography, Grid, Divider, Box, Tooltip, IconButton, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { FLYIN_WIDTH, DocumentList } from '@epix-web-apps/ui'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface CalendarTypeListProps {
  start: Date
  contractId: string | undefined
}

export function CalendarTypeList({ start, contractId }: CalendarTypeListProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const { data: calendarData } = useSuspenseGetCalendarForDateRangeQuery({
    contractId: contractId || '',
    startDate: start,
    endDate: start
  })

  const Title = styled(Typography)(() => ({
    fontSize: '1rem'
  }))

  const StyledBox = styled(Box)(() => ({
    display: 'flex',
    width: '100%',
    padding: '0 0.5rem'
  }))

  const personalCalendarEntries = calendarData?.calendarDaysByContractId[0].personalCalendarDays
  const resultEntries = calendarData?.calendarDaysByContractId[0].calculatedEntries
  const workscheduleEntries = calendarData?.calendarDaysByContractId[0].workScheduleDay?.dayDefinitionEntries
  const collectiveEntries = calendarData?.calendarDaysByContractId[0].collectiveSchedule?.collectiveScheduleDays
  const workScheduleDay = calendarData?.calendarDaysByContractId[0].workScheduleDay

  return (
    <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
      {resultEntries && resultEntries?.length !== 0 && (
        <Box>
          <Title variant="h4">{t('flyin.calendar-entry.result')}</Title>
          <Grid container rowSpacing={0.5} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
            {resultEntries?.map((resultEntry, index) => {
              return (
                <StyledBox key={index}>
                  <Grid item sm={6}>
                    {resultEntry.payrollCodeUserFriendlyDescription ?? resultEntry.payrollCodeDescription}{' '}
                    {resultEntry.shiftCodeDescription ? `(${resultEntry.shiftCodeDescription})` : ''}
                  </Grid>
                  <Grid item sm={4}>
                    {resultEntry.timeFrameType.value}
                  </Grid>
                  <Grid item sm={2}>
                    {resultEntry.numberOfHours}
                  </Grid>
                </StyledBox>
              )
            })}
          </Grid>
          <Divider sx={{ marginTop: 3 }} />
        </Box>
      )}

      {personalCalendarEntries && personalCalendarEntries?.length !== 0 && (
        <>
          <Title variant="h4">{t('flyin.calendar-entry.personal-calendar')}</Title>
          {personalCalendarEntries?.map((personalCalendarEntry: any, index: number) => {
            return (
              <>
                <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                  <StyledBox key={index}>
                    <Grid item sm={6}>
                      <p>
                        {personalCalendarEntry.payrollCodeUserFriendlyDescription ??
                          personalCalendarEntry.payrollCodeDescription}
                      </p>
                    </Grid>
                    <Grid item sm={4}>
                      {personalCalendarEntry.timeFrameType.value}
                    </Grid>
                    {personalCalendarEntry.timeOrderedType.key === DEFAULT_BASED_ON_WORKSCHEDULE &&
                    personalCalendarEntry.numberOfHours === 0 ? (
                      <Grid item sm={2}>
                        <Tooltip title={t('flyin.calendar-entry.basedonworkschedule')}>
                          <IconButton sx={{ m: 0, p: 0 }}>
                            <InfoOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Grid item sm={2}>
                        <p>{personalCalendarEntry.numberOfHours}</p>
                      </Grid>
                    )}
                  </StyledBox>
                </Grid>
                {personalCalendarEntry.documents.length > 0 && (
                  <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                    <StyledBox key={index}>
                      <Grid item xs={12} pb={1}>
                        <DocumentList documents={personalCalendarEntry.documents} />
                      </Grid>
                    </StyledBox>
                  </Grid>
                )}
                <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                  <StyledBox key={index}>
                    <Grid item sm={12}>
                      {personalCalendarEntry.comment && (
                        <Box display={'flex'}>
                          <CommentOutlinedIcon
                            sx={{
                              color: theme.palette.text.secondary,
                              mr: 1
                            }}
                            fontSize="small"
                          />
                          <Typography
                            sx={{
                              fontSize: '1em',
                              mt: 0,
                              color: theme.palette.text.secondary,
                              fontStyle: 'italic'
                            }}
                          >
                            {personalCalendarEntry.comment}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                  </StyledBox>
                </Grid>
                {personalCalendarEntry.absenceRequestCalendarModel &&
                  personalCalendarEntry.absenceRequestCalendarModel.absenceRequestType.key ===
                    ABSENCE_REQUEST_STATUS_TYPE.REQUESTED && (
                    <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                      <StyledBox key={index}>
                        <Grid item sm={12}>
                          <Box display={'flex'}>
                            <ContactSupportOutlinedIcon
                              sx={{ color: theme.palette.info.main, mr: 1 }}
                              fontSize="small"
                            />
                            <Typography
                              sx={{
                                fontSize: '1em',
                                mt: 0,
                                color: theme.palette.info.main
                              }}
                            >
                              {t('flyin.calendar-entry.pendingrequests')}
                            </Typography>
                          </Box>
                        </Grid>
                      </StyledBox>
                    </Grid>
                  )}
                
                {personalCalendarEntry.absenceRequestCalendarModel &&
                  personalCalendarEntry.absenceRequestCalendarModel.absenceRequestType.key ===
                    ABSENCE_REQUEST_STATUS_TYPE.APPROVED && (
                    <Grid container rowSpacing={0} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
                      <StyledBox key={index}>
                        <Grid item sm={12}>
                          <Box display={'flex'}>
                            <CheckOutlinedIcon
                              sx={{
                                color: theme.palette.success.main,
                                mr: 1
                              }}
                              fontSize="small"
                            />
                            <Typography
                              sx={{
                                fontSize: '1em',
                                mt: 0,
                                color: theme.palette.success.main
                              }}
                            >
                              {t('flyin.calendar-entry.approvedrequest')}{' '}
                              {personalCalendarEntry.absenceRequestCalendarModel?.approvedByPersonFistName}{' '}
                              {personalCalendarEntry.absenceRequestCalendarModel?.approvedByPersonPreferredLastName}{' '}
                              {t('flyin.calendar-entry.on')}{' '}
                              {parseISO(
                                personalCalendarEntry.absenceRequestCalendarModel?.approvedOn
                              ).toLocaleDateString()}
                            </Typography>
                          </Box>
                        </Grid>
                      </StyledBox>
                    </Grid>
                  )}
              </>
            )
          })}
          <Divider sx={{ marginTop: 3 }} />
        </>
      )}

      {collectiveEntries && collectiveEntries?.length !== 0 && (
        <>
          <Title variant="h4">{t('flyin.calendar-entry.collective-schedule')}</Title>
          <Grid container rowSpacing={1} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
            {collectiveEntries?.map((collectiveEntry, index) => {
              return (
                <StyledBox key={index}>
                  <Grid item sm={6}>
                    {collectiveEntry.payrollCodeUserFriendlyDescription ?? collectiveEntry.payrollCodeDescription}
                  </Grid>
                  <Grid item sm={4}>
                    {collectiveEntry.timeFrameType.value}
                  </Grid>
                  <Grid item sm={2}>
                    <Tooltip title={t('flyin.calendar-entry.basedonworkschedule')}>
                      <IconButton sx={{ m: 0, p: 0 }}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </StyledBox>
              )
            })}
          </Grid>
          <Divider sx={{ marginTop: 3 }} />
        </>
      )}

      {workscheduleEntries && workscheduleEntries?.length !== 0 && !workScheduleDay?.overwrittenByAlternate && (
        <>
          <Title variant="h4">
            {t('flyin.calendar-entry.workschedule') +
              ' - ' +
              calendarData?.calendarDaysByContractId[0].workScheduleDay?.labelOnCalendar}
          </Title>
          <Grid container rowSpacing={1} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
            {workscheduleEntries?.map(workScheduleEntry => {
              return (
                <StyledBox key={workScheduleEntry.dayDefinitionEntryId}>
                  <Grid item sm={6}>
                    {workScheduleEntry.payrollCodeUserFriendlyDescription ?? workScheduleEntry.payrollCodeDescription}{' '}
                    {workScheduleDay?.shiftCodeDescription ? `(${workScheduleDay.shiftCodeDescription})` : ''}
                  </Grid>
                  <Grid item sm={4}>
                    {workScheduleEntry.timeFrameType.value}
                  </Grid>
                  <Grid item sm={2}>
                    {workScheduleEntry.hours}
                  </Grid>
                </StyledBox>
              )
            })}
          </Grid>
        </>
      )}

      {workScheduleDay?.overwrittenByAlternate && (
        <>
          <Title variant="h4">{t('flyin.calendar-entry.workschedule') + ' - ' + workScheduleDay.labelOnCalendar}</Title>
          <Grid container rowSpacing={1} fontSize="0.8rem" columnSpacing={{ xs: 1 }}>
            {workScheduleDay?.dayDefinitionEntries.map((entry, index) => {
              return (
                <StyledBox key={entry.dayDefinitionEntryId}>
                  <Grid item sm={6}>
                    {entry.payrollCodeUserFriendlyDescription ?? entry.payrollCodeDescription}{' '}
                    {workScheduleDay.shiftCodeDescription ? `(${workScheduleDay.shiftCodeDescription})` : ''}
                  </Grid>
                  <Grid item sm={4}>
                    {entry.timeFrameType.value ?? t('flyin.calendar-entry.notworked')}
                  </Grid>
                  <Grid item sm={2}>
                    {entry.hours ?? 0}
                  </Grid>
                </StyledBox>
              )
            })}
          </Grid>
        </>
      )}
    </Box>
  )
}

export default CalendarTypeList
