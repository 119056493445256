import { Box, Link, Typography, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { OnboardingLayout } from '../../../layouts'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined'
import { environment } from '../../../../../../src/environments/environment'
import { Configuration, Import } from '@epix-web-apps/ui'
import { ROLE, isAuthorized, useSuspenseGetMeQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface OnboardingPersonLayoutProps {
  onClick: () => void
  icpsPresent: boolean
  employersPresent: boolean
}

export function OnboardingPersonLayout({ onClick, icpsPresent, employersPresent }: OnboardingPersonLayoutProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { data: me } = useSuspenseGetMeQuery()

  return (
    <>
      <OnboardingLayout title={t('peoplepage.onboarding.title')} icon={<PersonOutlinedIcon />} />
      <Box
        sx={{
          marginLeft: '20%',
          marginRight: '20%'
        }}
      >
        {!icpsPresent && (
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            <Trans
              i18nKey={t('peoplepage.onboarding.description')}
              values={{ linktoicp: t('peoplepage.onboarding.here') }}
              components={{
                tt: <Link underline="always" onClick={() => navigate(Configuration.ICP_PROVIDERS())} />
              }}
            />
          </Typography>
        )}
        {!employersPresent && icpsPresent && (
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            <Trans
              i18nKey={t('peoplepage.onboarding.description2')}
              values={{ linktoicp: t('peoplepage.onboarding.here') }}
              components={{
                tt: <Link underline="always" onClick={() => navigate(Configuration.ICP_PROVIDERS())} />
              }}
            />
          </Typography>
        )}
        {icpsPresent && employersPresent && (
          <>
            <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
              {t('peoplepage.onboarding.description3')}
            </Typography>
            {isAuthorized(me?.me.roles, [ROLE.IMPORTDATA, ROLE.ADMIN]) ? (
              <>
                <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
                  {t('peoplepage.onboarding.description4')}
                </Typography>
                <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
                  <Trans
                    i18nKey={t('peoplepage.onboarding.description5')}
                    values={{
                      linktoimport: t('peoplepage.onboarding.import'),
                      linktoperson: t('peoplepage.onboarding.here')
                    }}
                    components={{
                      ttt: <Link underline="always" onClick={() => navigate(Import.IMPORT)} />,
                      tt: <Link underline="always" onClick={onClick} />
                    }}
                  />
                </Typography>
                <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
                  <Trans
                    i18nKey={t('peoplepage.onboarding.description6')}
                    values={{
                      linktodocusaurus: t('peoplepage.onboarding.here')
                    }}
                    components={{
                      tt: (
                        <Link
                          underline="always"
                          target="_blank"
                          href={environment.docs.domainName + '/Import/import-csv/import-csv-people'}
                        />
                      )
                    }}
                  />
                </Typography>
              </>
            ) : (
              <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
                <Trans
                  i18nKey={t('peoplepage.onboarding.description7')}
                  values={{
                    linktoperson: t('peoplepage.onboarding.here')
                  }}
                  components={{
                    tt: <Link underline="always" onClick={onClick} />
                  }}
                />
              </Typography>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default OnboardingPersonLayout
