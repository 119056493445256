import { GraphqlError, useDeleteInjuryHistoryCommandMutation, useGetInjuryHistoryByIdQuery } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface RemoveInjuryHistoryProps {
  injuryId: string
  injuryHistoryId: string
}

export function RemoveInjuryHistory({ injuryId, injuryHistoryId }: RemoveInjuryHistoryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const deleteMutation = useDeleteInjuryHistoryCommandMutation()
  const form = useForm()

  const { data: injuryHistoryById } = useGetInjuryHistoryByIdQuery({
    injuryId: injuryId || '',
    injuryHistoryId: injuryHistoryId || ''
  })

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteInjuryHistoryCommand: {
          injuryId: injuryId || '',
          injuryHistoryId: injuryHistoryId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleteinjuryhistory.title')}</Typography>
      <Typography>
        <Trans
          i18nKey="contractdetailpage.injury.injuryhistory"
          components={{ bold: <span className="bold" /> }}
          values={{
            startDate: parseISO(injuryHistoryById?.injuryHistoryById?.startDate).toLocaleDateString(),
            endDate: parseISO(injuryHistoryById?.injuryHistoryById?.endDate).toLocaleDateString(),
            disabledPercentage: injuryHistoryById?.injuryHistoryById?.disabledPercentage
          }}
        />
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveInjuryHistory
