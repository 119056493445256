import {
  useRequiredParams,
  useSuspenseGetPayComponentUpdateProcessByIdQuery,
  useSuspenseGetSelectedPayComponentUpdateProcessContractsQuery
} from '@epix-web-apps/core'
import {} from '@epix-web-apps/ui'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StepParams } from '../../generic-steps'

/* eslint-disable-next-line */
export interface FinishPaycomponentUpdateOverviewProps {}

export function FinishPaycomponentUpdateOverview(props: FinishPaycomponentUpdateOverviewProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<StepParams>()

  const { data: payComponentUpdateProcess } = useSuspenseGetPayComponentUpdateProcessByIdQuery({
    id: params.id
  })

  const { data: getSelectedPayComponentUpdateProcess } = useSuspenseGetSelectedPayComponentUpdateProcessContractsQuery({
    processId: params.id
  })

  return (
    <>
      <Typography variant="h3">{t('processpage.finishoverview.title')}</Typography>
      <Box sx={{ mb: 3 }}>
        <Typography>
          {t('form.field.payrollprovider')}:{' '}
          {payComponentUpdateProcess?.payComponentUpdateProcessById.payrollProviderName} (
          {payComponentUpdateProcess?.payComponentUpdateProcessById.payrollProviderCode})
        </Typography>
        <Typography>
          {t('form.field.employer')}: {payComponentUpdateProcess?.payComponentUpdateProcessById.employerName} (
          {payComponentUpdateProcess?.payComponentUpdateProcessById.employerNumber})
        </Typography>
        <Typography>
          {t('form.field.paygroup')}: {payComponentUpdateProcess?.payComponentUpdateProcessById.payGroup.name} (
          {payComponentUpdateProcess?.payComponentUpdateProcessById.payGroup.code})
        </Typography>
        <Typography>
          {t('form.field.payrollcode')}:{' '}
          {`${payComponentUpdateProcess?.payComponentUpdateProcessById.payrollCode} - ${payComponentUpdateProcess?.payComponentUpdateProcessById.payrollCodeDescription}`}
        </Typography>
        {payComponentUpdateProcess?.payComponentUpdateProcessById.endDate ? (
          <Typography>
            {t('form.field.payrollperiod')}:{' '}
            {`${payComponentUpdateProcess?.payComponentUpdateProcessById.startDate} - ${payComponentUpdateProcess?.payComponentUpdateProcessById.endDate}`}
          </Typography>
        ) : (
          <Typography>
            {t('form.field.payrollperiod')}: {payComponentUpdateProcess?.payComponentUpdateProcessById.startDate}
          </Typography>
        )}
      </Box>
      <Typography sx={{ mb: 1 }}>
        {t('processpage.finishoverview.numberofpaycomponents')}:{' '}
        {getSelectedPayComponentUpdateProcess?.selectedPayComponentUpdateProcessContracts.length}
      </Typography>
    </>
  )
}

export default FinishPaycomponentUpdateOverview
