import { useCalculateCalculateSmartPayComponentHistoryCommandInputMutation } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import { Box, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface CalculateContractSmartPayComponentHistoryProps {
    contractId: string
    contractSmartPayComponentHistoryId: string
    startDate: Date
    endDate: Date
  }

function CalculateContractSmartPayComponentHistory({ contractId, contractSmartPayComponentHistoryId, startDate, endDate }: CalculateContractSmartPayComponentHistoryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const calculateMutation = useCalculateCalculateSmartPayComponentHistoryCommandInputMutation()

  const handleCalculation = () => {
    calculateMutation
      .mutateAsync({
        command: {
          contractId: contractId,
          contractSmartPayComponentHistoryId: contractSmartPayComponentHistoryId,
          startDate: startDate,
          endDate: endDate
        }
      })
      .then(closeFlyIn)
  }

  return (
    <Box>
      <Typography variant="h4">{t('flyin.calculatecontractsmartpaycomponenthistory.title')}</Typography>
      <Typography>{t('flyin.calculatecontractsmartpaycomponenthistory.description')}</Typography>
      <Button sx={{mt: 1}} color={'primary'} variant="contained" onClick={handleCalculation} disabled={calculateMutation.isPending}>
        {t('flyin.calculatecontractsmartpaycomponenthistory.start-calculation')}
        {calculateMutation.isPending && (
          <LinearProgress
            color={'primary'}
            sx={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              opacity: 0.6
            }}
          />
        )}
      </Button>
    </Box>
  )
}

export default CalculateContractSmartPayComponentHistory;
