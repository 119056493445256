import { OrderDirection } from '@epix-web-apps/core'
import { Box, CircularProgress, SxProps, useTheme } from '@mui/material'
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridColumnVisibilityModel,
  GridDensity,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortDirection,
  GridSortItem
} from '@mui/x-data-grid'
import React, { useEffect, useRef, useState } from 'react'
import DataTableEmpty from './data-table-empty/data-table-empty'

export function DataTableCell({ sx, children }: { sx?: SxProps; children: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        '& .MuiTypography-root': { fontSize: '0.875rem' },
        ...sx
      }}
    >
      {children}
    </Box>
  )
}

export class PaginationModel implements GridPaginationModel {
  page: number
  pageSize: number
  offset: number

  constructor(page = 0, pageSize = 25) {
    this.page = page
    this.pageSize = pageSize
    this.offset = page * pageSize
  }
}

export class SortModel implements GridSortItem {
  field: string
  orderDirection: OrderDirection
  sort: GridSortDirection

  constructor(field: string, orderDirection = OrderDirection.Asc) {
    this.field = field
    this.orderDirection = orderDirection
    this.sort = orderDirection === OrderDirection.Asc ? 'asc' : 'desc'
  }
}

/* eslint-disable-next-line */
export interface DataTableProps {
  data?: GridRowsProp
  totalRowCount?: number
  columns: GridColDef[]
  density?: GridDensity
  sortModel?: SortModel
  onSortChange?: (model: SortModel) => void
  paginationModel?: GridPaginationModel
  onPaginationModelChange?: (model: PaginationModel) => void
  onRowClick?: (params: GridRowParams) => void
  isLoading: boolean
  checkboxSelection?: boolean
  onSelectionModelChange?: (selectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => void
  selectionModel?: GridRowSelectionModel
  filterBarElement?: React.ReactNode | null
  hideFooter?: boolean
  columnsToHide?: GridColumnVisibilityModel
  emptyStateElement?: React.ReactNode
  isRowSelectable?: (params: GridRowParams) => boolean
  disableRowSelectionOnClick?: boolean
}

export function DataTable({ filterBarElement, ...props }: DataTableProps) {
  return (
    <Box sx={{ height: '100%' }}>
      {filterBarElement}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <BaseDataTable {...props} />
      </div>
    </Box>
  )
}

export function ScrollableDataTable({
  vHeightOffset = '8rem',
  filterBarElement,
  ...props
}: DataTableProps & {
  vHeightOffset?: number | string
}) {
  const theme = useTheme()
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [containerHeight, setContainerHeight] = useState<string | number>('100vh')

  useEffect(() => {
    const target = containerRef.current
    const observer = new ResizeObserver(entries => {
      setContainerHeight(entries[0].contentRect.height)
    })
    if (target) {
      observer.observe(target)
    }
    return () => {
      observer.disconnect()
    }
  }, [containerRef])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${vHeightOffset})`,
        p: 1
      }}
    >
      {filterBarElement}
      <div
        style={{
          color: theme.palette.primary.main,
          flex: 1,
          position: 'relative',
          minHeight: 0
        }}
        ref={containerRef}
      >
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, maxHeight: containerHeight }}>
          <BaseDataTable {...props} />
        </div>
      </div>
    </Box>
  )
}

export function BaseDataTable({
  data,
  columns,
  totalRowCount,
  sortModel,
  density = 'standard',
  onSortChange,
  onPaginationModelChange,
  paginationModel,
  onRowClick,
  isLoading,
  checkboxSelection = false,
  onSelectionModelChange,
  selectionModel,
  hideFooter = false,
  columnsToHide,
  emptyStateElement,
  isRowSelectable,
  disableRowSelectionOnClick = false
}: DataTableProps) {
  if (data && data.length === 0) {
    return (
      <>
        {emptyStateElement && emptyStateElement}
        {!emptyStateElement && <DataTableEmpty />}
      </>
    )
  }

  // TODO: 2930 - datatable should have its own loading state
  if (isLoading) {
    return (
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <DataGrid
      density={density}
      isRowSelectable={params => (isRowSelectable ? isRowSelectable(params) : true)}
      disableRowSelectionOnClick={disableRowSelectionOnClick}
      rows={data}
      columns={columns}
      sortingOrder={['desc', 'asc']}
      sortingMode="server"
      sortModel={sortModel && [sortModel]}
      disableColumnSorting={!sortModel}
      onSortModelChange={sortModel => {
        if (onSortChange && !!sortModel) {
          onSortChange(
            new SortModel(sortModel[0]?.field, sortModel[0]?.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc)
          )
        }
      }}
      paginationModel={paginationModel}
      paginationMode={totalRowCount ? 'server' : 'client'}
      rowCount={totalRowCount}
      onPaginationModelChange={paginationModel => {
        onPaginationModelChange &&
          onPaginationModelChange(new PaginationModel(paginationModel.page, paginationModel.pageSize))
      }}
      onRowClick={(params, event) => {
        event.defaultMuiPrevented = true
        onRowClick && onRowClick(params)
      }}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={onSelectionModelChange}
      rowSelectionModel={selectionModel}
      disableColumnMenu={true}
      loading={isLoading}
      sx={{
        '& .MuiDataGrid-columnHeadersInner': {
          color: 'primary.main'
        },
        border: 'none'
      }}
      hideFooter={hideFooter}
      columnVisibilityModel={columnsToHide}
    />
  )
}
