import {
  useCalculateCalendarForContractsCalculatedByEpixMutation,
  useTriggerRolloutCalendarForPeopleNotManagedByEpixMutation
} from '@epix-web-apps/core'
import { Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export function BartsToolbox() {
  const { t } = useTranslation()

  const triggerRollOutCalendarMutationNotManagedByEpix = useTriggerRolloutCalendarForPeopleNotManagedByEpixMutation()
  const rolloutCalendarNotCalculatedByEpixClick = () => triggerRollOutCalendarMutationNotManagedByEpix.mutateAsync({})

  const triggerRollOutCalendarMutation = useCalculateCalendarForContractsCalculatedByEpixMutation()
  const rolloutCalendarCalculatedByEpixClick = () => triggerRollOutCalendarMutation.mutateAsync({})

  return (
    <>
      <Typography variant="h3" color="textSecondary">
        {t('tenantconfigurationpage.bartstoolbox.title')}
      </Typography>
      <Button
        variant="contained"
        onClick={rolloutCalendarNotCalculatedByEpixClick}
        disabled={triggerRollOutCalendarMutationNotManagedByEpix.isPending}
        sx={{ mb: 2 }}
      >
        {t('tenantconfigurationpage.bartstoolbox.triggerrolloutcalendarfornotcalculatedbyepix')}
      </Button>
      <Button
        variant="contained"
        onClick={rolloutCalendarCalculatedByEpixClick}
        disabled={triggerRollOutCalendarMutation.isPending}
        sx={{ mb: 2 }}
      >
        {t('tenantconfigurationpage.bartstoolbox.triggerrolloutcalendarforcalculatedbyepix')}
      </Button>
    </>
  )
}
