import {
  GraphqlError,
  useDeleteAlternateWorkScheduleDayMutation,
  useSuspenseGetAlternateWorkScheduleDayQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { Divider, Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PersonIdentificationComponent from '../../person-identification-component'

/* eslint-disable-next-line */
export interface ResetWorkScheduleProps {
  start: Date
  alternateWorkScheduleDayId: string
  alternateWorkScheduleDayDescription: string
}

export function ResetWorkSchedule({
  start,
  alternateWorkScheduleDayId,
  alternateWorkScheduleDayDescription: title
}: ResetWorkScheduleProps) {
  const { t } = useTranslation()
  const form = useForm()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const deleteMutation = useDeleteAlternateWorkScheduleDayMutation()

  const { data: getAlternateWorkScheduleById } = useSuspenseGetAlternateWorkScheduleDayQuery({
    id: alternateWorkScheduleDayId || ''
  })

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteAlternateWorkScheduleDayCommand: {
          alternateWorkScheduleDayId: alternateWorkScheduleDayId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <PersonIdentificationComponent title={t('flyin.overviewcalendar.title')} />
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" color={'gray'}>
        {t('flyin.collectiveschedule-entry.date')}: {start?.toLocaleDateString()}
      </Typography>
      <Typography>
        {getAlternateWorkScheduleById?.alternateWorkScheduleDay.dayDefinition.description} - {title}
      </Typography>
      <Typography variant="h6" sx={{ color: 'orange' }}>
        {t('flyin.warning.reset')}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default ResetWorkSchedule
