import {
  ROLE,
  useCanAccess,
  useSuspenseGetAllEmployerDocumentTypesByEmployerIdQuery,
  useSuspenseGetEmployerByIdQuery
} from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, Documents, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FolderList } from '../../components/document-components'
import {
  AddCompanyDocumentType,
  EditCompanyDocumentType
} from '../../components/document-components/add-edit-company-documenttype'
import { EditAccessCompanyDocumenttype } from '../../components/document-components/edit-access-company-documenttype'

/* eslint-disable-next-line */
export interface CompanyDocumentTypesPageProps {}

export type CompanyDocumentTypesDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
}

export function CompanyDocumentTypesPage(props: CompanyDocumentTypesPageProps) {
  const params = useParams<CompanyDocumentTypesDetailPageParams>()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: params.employerid || ''
  })
  const { data: getAllEmployerDocumentTypes, refetch } = useSuspenseGetAllEmployerDocumentTypesByEmployerIdQuery({
    id: params.employerid || ''
  })

  const canManageEmployerDocumentTypes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEEMPLOYERDOCUMENTTYPES])

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={`${t('companydocumenttypespage.title')} - ${getEmployerById?.employerById.companyName}`}
        />
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddCompanyDocumentType />,
              callbackAfterClose: () => refetch
            })
          }
        >
          <AddIcon />
          {t('companydocumenttypespage.button.newdocumenttype')}
        </Button>
      </Box>
      <FolderList
        folders={getAllEmployerDocumentTypes?.allEmployerDocumentTypesByEmployerId || []}
        employerId={params.employerid || ''}
        editFlyin={(documentTypeId: string) => <EditCompanyDocumentType documentTypeId={documentTypeId} />}
        editAccessFlyin={(documentTypeId?: string) => <EditAccessCompanyDocumenttype documentTypeId={documentTypeId} />}
        onChangesSaved={refetch}
        {...(canManageEmployerDocumentTypes
          ? {
              goToDocumentTypeDetailPage: documentId =>
                Documents.DOCUMENTS_ICP_ID_EMPLOYER_ID_DOCUMENTTYPE_ID(
                  params.id || '',
                  params.employerid || '',
                  documentId || ''
                )
            }
          : {})}
      />
    </>
  )
}

export default CompanyDocumentTypesPage
