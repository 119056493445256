import { useSuspenseGetAllLocalesQuery, useSuspenseGetMeQuery, useUpdateMyLocaleMutation } from '@epix-web-apps/core'
import { FLYIN_WIDTH } from '@epix-web-apps/ui'
import { Box, Divider, List, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface ChangeLocaleProps {}

export function ChangeLocale(props: ChangeLocaleProps) {
  const { t } = useTranslation()
  const { data: getAllLocales } = useSuspenseGetAllLocalesQuery({
    ignoreTranslations: true,
    onlySupported: true,
    showInRespectiveLanguage: true
  })
  const mutation = useUpdateMyLocaleMutation()
  const { data: me } = useSuspenseGetMeQuery()
  const [isPageRefreshing, setIsPageRefreshing] = useState(false)

  const handleClick = (locale: string) => {
    setIsPageRefreshing(true)
    mutation.mutateAsync({ locale: locale }).then(() => {
      window.location.reload()
    })
  }

  if (isPageRefreshing) {
    return (
      <Box sx={{ width: FLYIN_WIDTH.SMALL }}>
        <Typography variant="h4">{t('flyin.changelanguage.title')}</Typography>
        <Box className="spinner-container">
          <Box className="spinner"></Box>
          <p>{t('common.pagerefresh')}</p>
        </Box>
      </Box>
    )
  }

  return (
    <Box sx={{ width: FLYIN_WIDTH.SMALL }}>
      <Typography variant="h4">{t('flyin.changelanguage.title')}</Typography>
      <List>
        {getAllLocales?.locales.map(item => (
          <Fragment key={item.locale}>
            <ListItemButton selected={me?.me.locale.locale === item.locale} onClick={() => handleClick(item.locale)}>
              <ListItemText id={item.locale} primary={item.language} />
            </ListItemButton>
            <Divider />
          </Fragment>
        ))}
      </List>
    </Box>
  )
}

export default ChangeLocale
