import { GraphqlError, useDeleteManualEventMutation, useSuspenseGetManualEventByIdQuery } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface RemoveManualEventProps {
  manualEventId: string
}

export function RemoveManualEvent({ manualEventId }: RemoveManualEventProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const deleteMutation = useDeleteManualEventMutation()

  const { data: getManualEventById } = useSuspenseGetManualEventByIdQuery({
    id: manualEventId || ''
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const form = useForm()

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteManualEventCommand: {
          id: manualEventId
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deletemanualevent.title')}</Typography>
      <Typography variant="h5" color={'gray'}>
        {getManualEventById?.manualEventById.payrollCodeDescription} -{' '}
        {parseISO(getManualEventById?.manualEventById.date).toLocaleDateString()}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveManualEvent
