import {
  GetEmployerByIdQuery,
  GraphqlError,
  useCreateEmployerMutation,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetFeaturesQuery,
  useSuspenseGetTenantFeaturesQuery,
  useUpdateEmployerMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FeatureForPurchaseIndicator,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export function AddEmployer() {
  return <AddEditEmployer />
}

interface EditEmployerProps {
  employerId: string
}

export function EditEmployer({ employerId }: EditEmployerProps) {
  const query = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })

  return <AddEditEmployer employerQuery={query} />
}

function AddEditEmployer({ employerQuery }: { employerQuery?: UseSuspenseQueryResult<GetEmployerByIdQuery, unknown> }) {
  const employer = employerQuery?.data.employerById

  const { t } = useTranslation()
  const theme = useTheme()
  const { data: tenantFeaturesData } = useSuspenseGetTenantFeaturesQuery()
  const { data: getFeatureFlags } = useSuspenseGetFeaturesQuery()
  const {
    calculatedByEpix,
    hasCalendarManagement,
    hasCompanyCars,
    hasDocumentManagement,
    hasPayComponentManagement,
    hasSelfService,
    hasSmartPayComponents
  } = tenantFeaturesData?.tenantFeatures ?? {}

  const addEditEmployerSchema = object({
    companyName: string({
      required_error: t('form.validation.companynamerequired'),
      invalid_type_error: t('form.validation.companynamerequired')
    }).min(1, t('form.validation.companynamerequired')),
    number: string({
      required_error: t('form.validation.numberrequired'),
      invalid_type_error: t('form.validation.numberrequired')
    }).min(1, t('form.validation.numberrequired')),
    companyRegistrationNumber: string().nullable(),
    nationalSocialSecurityNumber: string().nullable(),
    providerPayrollContactEmail: string().nullable(),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean(),
    hasSmartPayComponents: boolean(),
    active: boolean(),
    useContactEmailForPayrollClosure: boolean(),
    useContactEmailForPayrollCodeChange: boolean()
  })
  type CreateEditEmployerForm = TypeOf<typeof addEditEmployerSchema>
  const { closeFlyIn } = useFlyIn()
  const createMutation = useCreateEmployerMutation()
  const updateMutation = useUpdateEmployerMutation()
  const params = useParams<DetailPageBaseQueryParams>()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const form = useForm<CreateEditEmployerForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      companyName: employer?.companyName,
      number: employer?.number,
      companyRegistrationNumber: employer?.companyRegistrationNumber,
      nationalSocialSecurityNumber: employer?.nationalSocialSecurityNumber,
      providerPayrollContactEmail: employer?.providerPayrollContactEmail,
      useContactEmailForPayrollClosure: employer?.useContactEmailForPayrollClosure ?? true,
      useContactEmailForPayrollCodeChange: employer?.useContactEmailForPayrollCodeChange ?? true,
      calculatedByEpix: employer?.calculatedByEpix ?? false,
      hasCalendarManagement: employer?.hasCalendarManagement ?? false,
      hasDocumentManagement: employer?.hasDocumentManagement ?? false,
      hasPayComponentManagement: employer?.hasPayComponentManagement ?? false,
      active: employer?.active ?? true,
      hasSelfService: employer?.hasSelfService ?? false,
      hasCompanyCars: employer?.hasCompanyCars ?? false,
      hasSmartPayComponents: employer?.hasSmartPayComponents ?? false
    },
    resolver: zodResolver(addEditEmployerSchema)
  })

  // to keep a checkbox selected when disabled, we need to set the values ourselves. If we don't
  // do this, the values stay undefined as this is the default behavior of a form.
  useEffect(() => {
    form.setValue('calculatedByEpix', employer?.calculatedByEpix ?? false)
    form.setValue('hasCalendarManagement', employer?.hasCalendarManagement ?? false)
    form.setValue('hasDocumentManagement', employer?.hasDocumentManagement ?? false)
    form.setValue('hasPayComponentManagement', employer?.hasPayComponentManagement ?? false)
    form.setValue('hasSelfService', employer?.hasSelfService ?? false)
    form.setValue('hasCompanyCars', employer?.hasCompanyCars ?? false)
    form.setValue('hasSmartPayComponents', employer?.hasSmartPayComponents ?? false)

    // default to true if no contact email is filled in
    if (!employer?.providerPayrollContactEmail) {
      form.setValue('useContactEmailForPayrollClosure', true)
      form.setValue('useContactEmailForPayrollCodeChange', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnSubmit = async (newEmployer: CreateEditEmployerForm) => {
    if (!employer) {
      await createMutation
        .mutateAsync({
          createEmployerCommand: {
            icpId: params.id || '',
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            useContactEmailForPayrollClosure: newEmployer.useContactEmailForPayrollClosure,
            useContactEmailForPayrollCodeChange: newEmployer.useContactEmailForPayrollCodeChange,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            hasSmartPayComponents: newEmployer.hasSmartPayComponents,
            active: newEmployer.active
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateEmployerCommand: {
            id: employer.id,
            companyName: newEmployer.companyName,
            number: newEmployer.number,
            companyRegistrationNumber: newEmployer.companyRegistrationNumber,
            nationalSocialSecurityNumber: newEmployer.nationalSocialSecurityNumber,
            providerPayrollContactEmail: newEmployer.providerPayrollContactEmail,
            useContactEmailForPayrollClosure: newEmployer.useContactEmailForPayrollClosure,
            useContactEmailForPayrollCodeChange: newEmployer.useContactEmailForPayrollCodeChange,
            calculatedByEpix: newEmployer.calculatedByEpix,
            hasPayComponentManagement: newEmployer.hasPayComponentManagement,
            hasCalendarManagement: newEmployer.hasCalendarManagement,
            hasDocumentManagement: newEmployer.hasDocumentManagement,
            hasSelfService: newEmployer.hasSelfService,
            hasCompanyCars: newEmployer.hasCompanyCars,
            hasSmartPayComponents: newEmployer.hasSmartPayComponents,
            active: newEmployer.active
          }
        })
        .then(() => {
          employerQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  const watchCalendarManagement = useWatch({
    control: form.control,
    name: 'hasCalendarManagement'
  })

  const watchPaycomponentManagement = useWatch({
    control: form.control,
    name: 'hasPayComponentManagement'
  })

  const contactEmailWatch = useWatch({
    control: form.control,
    name: 'providerPayrollContactEmail'
  })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addemployer.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="companyName" label={`${t('form.field.employer')} *`} />

        <FormInput sx={12} name="number" label={`${t('form.field.employernumber')} *`} />

        <FormInput sx={12} name="companyRegistrationNumber" label={`${t('form.field.companyregistrationnumber')}`} />

        <FormInput sx={12} name="nationalSocialSecurityNumber" label={`${t('form.field.socialsecuritynumber')}`} />

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="calculatedByEpix"
                disabled={!calculatedByEpix}
                onChange={e => {
                  if (e) {
                    form.resetField('hasPayComponentManagement', {
                      defaultValue: true
                    })
                    form.resetField('hasCalendarManagement', { defaultValue: true })
                    form.resetField('hasSelfService', { defaultValue: true })
                  }
                }}
                label={t('form.field.payrollviaepix')}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!calculatedByEpix && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="hasPayComponentManagement"
                label={t('form.field.paycomponentmanagement')}
                disabled={!hasPayComponentManagement}
                onChange={e => {
                  if (!e) {
                    form.resetField('hasSmartPayComponents', { defaultValue: false })
                  }
                }}
              />{' '}
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasPayComponentManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        {getFeatureFlags?.features.smartPayComponents && (
          <Box ml={8}>
            <Grid item xs={12}>
              <Grid container sx={{ alignItems: 'center' }}>
                <Grid item>
                  <FormSwitch
                    name="hasSmartPayComponents"
                    label={t('form.field.hassmartpaycomponents')}
                    disabled={!hasSmartPayComponents || !watchPaycomponentManagement}
                  />
                </Grid>
                <Grid item sx={{ paddingLeft: '5px' }}>
                  {!hasSmartPayComponents && <FeatureForPurchaseIndicator />}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                sx={12}
                name="hasCalendarManagement"
                disabled={!hasCalendarManagement}
                onChange={e => {
                  if (!e) {
                    form.resetField('hasSelfService', { defaultValue: false })
                  }
                }}
                label={t('form.field.calendarmanagement')}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasCalendarManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Box ml={8}>
          <Grid item xs={12}>
            <Grid container sx={{ alignItems: 'center' }}>
              <Grid item>
                <FormSwitch
                  disabled={!hasSelfService || !watchCalendarManagement}
                  name="hasSelfService"
                  label={t('form.field.selfservice.hasselfservice')}
                />
              </Grid>
              <Grid item sx={{ paddingLeft: '5px' }}>
                {!hasSelfService && <FeatureForPurchaseIndicator />}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch name="hasCompanyCars" label={t('form.field.hascompanycars')} disabled={!hasCompanyCars} />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasCompanyCars && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'center' }}>
            <Grid item>
              <FormSwitch
                name="hasDocumentManagement"
                label={t('form.field.hasdocumentmanagement')}
                disabled={!hasDocumentManagement}
              />
            </Grid>
            <Grid item sx={{ paddingLeft: '5px' }}>
              {!hasDocumentManagement && <FeatureForPurchaseIndicator />}
            </Grid>
          </Grid>
        </Grid>

        <FormSwitch sx={12} name="active" label={t('form.field.active')} />

        <FormInput
          sx={12}
          name="providerPayrollContactEmail"
          label={`${t('form.field.providerpayrollcontactemail')}`}
        />

        <Grid item xs={12}>
          <Grid container sx={{ alignItems: 'baseline' }}>
            <Grid item xs={4}>
              {t('flyin.addemployer.useincaseof')}:
            </Grid>
            <Grid item xs={8}>
              <FormSwitch
                sx={12}
                disabled={!contactEmailWatch}
                name="useContactEmailForPayrollClosure"
                label={t('form.field.payrollclosure')}
              />
              <FormSwitch
                sx={12}
                disabled={!contactEmailWatch}
                name="useContactEmailForPayrollCodeChange"
                label={t('form.field.payrollcodechange')}
              />
            </Grid>
          </Grid>
        </Grid>

        {!!employer && (
          <Grid xs={12} item>
            <Typography sx={{ fontStyle: 'italic', color: theme.palette.text.secondary }}>
              <Trans
                i18nKey="form.field.activeemployerdescription"
                values={{
                  settingscode: employer.settingsCode
                }}
                components={{ bold: <span className="bold" /> }}
              />
            </Typography>
          </Grid>
        )}
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
