import { useSuspenseGetValidationErrorByIdQuery, useUpdateValidationErrorMutation } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface UpdateViewedValidationErrorProps {
  validationErrorId: string | undefined
}

export function UpdateViewedValidationError({ validationErrorId }: UpdateViewedValidationErrorProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: validationError, refetch: refetchValidationError } = useSuspenseGetValidationErrorByIdQuery({
    validationErrorId: validationErrorId || ''
  })

  const editValidationErrorSchema = object({
    hasSeen: boolean()
  })

  type EditValidationErrorForm = TypeOf<typeof editValidationErrorSchema>
  const updateMutation = useUpdateValidationErrorMutation()

  const form = useForm<EditValidationErrorForm>({
    resolver: zodResolver(editValidationErrorSchema),
    defaultValues: {
      hasSeen: validationError?.validationErrorById.hasSeen
    }
  })

  const handleOnSubmit = async (newValidationError: EditValidationErrorForm) => {
    await updateMutation
      .mutateAsync({
        updateValidationErrorCommand: {
          id: validationError?.validationErrorById.id || '',
          hasSeen: newValidationError.hasSeen
        }
      })
      .then(() => {
        refetchValidationError()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editvalidationerror.viewed')}</Typography>
      <Box sx={{ px: 1, pt: 1 }}>
        <FormSwitch name="hasSeen" label={t('form.field.hasseen')} />
      </Box>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default UpdateViewedValidationError
