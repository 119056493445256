import {
  useSuspenseGetPhocusContractDetailsQuery,
  useUpdatePhocusContractDetailEmploymentMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditPhocusEmploymentProps {
  contractId: string
}

export function EditPhocusEmployment({ contractId }: EditPhocusEmploymentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetPhocusContractDetailsQuery({
      contractId: contractId
    })

  const editPhocusEmploymentSchema = object({
    isEmploymentTerminated: boolean()
  })

  type EditPhocusEmploymentForm = TypeOf<typeof editPhocusEmploymentSchema>

  const updateMutation = useUpdatePhocusContractDetailEmploymentMutation()

  const form = useForm<EditPhocusEmploymentForm>({
    resolver: zodResolver(editPhocusEmploymentSchema),
    defaultValues: {
      isEmploymentTerminated:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailEmploymentModel
          .isEmploymentTerminated
    }
  })

  const handleOnSumbit = async (phocusEmployment: EditPhocusEmploymentForm) => {
    await updateMutation
      .mutateAsync({
        phocusContractDetailEmploymentCommand: {
          id: partnerSpecificDetails?.phocusContractDetailByContractId.id || '',
          isEmploymentTerminated: phocusEmployment.isEmploymentTerminated
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSumbit)}>
      <Typography variant="h4">{t('flyin.editemployment.title')}</Typography>
      <FormGridLayout>
        <FormSwitch sx={12} name="isEmploymentTerminated" label={t('form.field.isemploymentterminated')} />
      </FormGridLayout>

      <FormErrorList />

      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPhocusEmployment
