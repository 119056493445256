import { useRequiredParams, useSuspenseGetPersonByIdQuery } from '@epix-web-apps/core'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import { Avatar, Badge, Box, Typography } from '@mui/material'

/* eslint-disable-next-line */
export interface PersonIdentificationComponentProps {
  title: string
}

export function PersonIdentificationComponent({ title }: PersonIdentificationComponentProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { data: getPersonSidebarInfoById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const person = getPersonSidebarInfoById?.personById

  return (
    <Box sx={{ display: 'flex', margin: [1, 1, 1, 1] }}>
      <Box>
        <Badge sx={{ display: 'block' }} overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <Avatar
            sx={{
              margin: '0 auto',
              width: '5rem',
              height: '5rem',
              marginBottom: 1,
              marginRight: 2,
              marginTop: 1
            }}
            alt={`${person?.firstName} ${person?.preferredLastName}`}
            //src="example.jpg"
          >{`${person?.firstName.charAt(0)}${person?.lastName.charAt(0)}`}</Avatar>
        </Badge>
      </Box>
      <Box>
        <Typography variant="h5">{`${person?.firstName} ${person?.preferredLastName}`}</Typography>
        <Typography variant="h4">{title}</Typography>
      </Box>
    </Box>
  )
}

export default PersonIdentificationComponent
