import {
  FormSelectOption,
  GetRequestPolicyPayrollCodeByIdQuery,
  numericString,
  useCreateRequestPolicyPayrollCodeMutation,
  useSuspenseGetAllPayrollCodesForSelfServiceQuery,
  useSuspenseGetRequestPolicyPayrollCodeByIdQuery,
  useUpdateRequestPolicyPayrollCodeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, number, object, string } from 'zod'

export interface AddRequestPolicyPayrollcodeProps {
  requestPolicyId: string
  employerId: string
}

export interface EditRequestPolicyPayrollcodeProps extends AddRequestPolicyPayrollcodeProps {
  requestPolicyId: string
  employerId: string
  requestPolicyPayrollCodeId: string
}

export function AddRequestPolicyPayrollcode({ employerId, requestPolicyId }: AddRequestPolicyPayrollcodeProps) {
  return AddEditRequestPolicyPayrollcode(employerId, requestPolicyId)
}

export function EditRequestPolicyPayrollcode({
  employerId,
  requestPolicyId,
  requestPolicyPayrollCodeId
}: EditRequestPolicyPayrollcodeProps) {
  const getRequestPolicyPayrollCodeByIdQuery = useSuspenseGetRequestPolicyPayrollCodeByIdQuery({
    requestPolicyId: requestPolicyId,
    id: requestPolicyPayrollCodeId
  })

  return AddEditRequestPolicyPayrollcode(employerId, requestPolicyId, getRequestPolicyPayrollCodeByIdQuery)
}

function AddEditRequestPolicyPayrollcode(
  employerId: string,
  requestPolicyId: string,
  getRequestPolicyPayrollCodeByIdQuery?: UseSuspenseQueryResult<GetRequestPolicyPayrollCodeByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const getRequestPolicyPayrollCodeById = getRequestPolicyPayrollCodeByIdQuery?.data

  const { data: getPayrollcodes } = useSuspenseGetAllPayrollCodesForSelfServiceQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1
  })

  const editRequestPolicyRequestRightsSchema = object({
    payrollCode: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    eventValidityInMonths: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    )
  })

  type AddRequestPolicyPayrollCodeGeneralForm = TypeOf<typeof editRequestPolicyRequestRightsSchema>

  const form = useForm<AddRequestPolicyPayrollCodeGeneralForm>({
    resolver: zodResolver(editRequestPolicyRequestRightsSchema),
    defaultValues: {
      payrollCode: getRequestPolicyPayrollCodeById?.requestPolicyPayrollCodeById?.payrollCodeId,
      eventValidityInMonths: getRequestPolicyPayrollCodeById?.requestPolicyPayrollCodeById?.eventValidityInMonths ?? 12
    }
  })

  const createMutation = useCreateRequestPolicyPayrollCodeMutation()
  const updateMutation = useUpdateRequestPolicyPayrollCodeMutation()

  const handleOnSubmit = async (newRequestPolicyPayrollCode: AddRequestPolicyPayrollCodeGeneralForm) => {
    if (!getRequestPolicyPayrollCodeById) {
      await createMutation
        .mutateAsync({
          createRequestPolicyPayrollCodeCommand: {
            payrollCodeId: newRequestPolicyPayrollCode.payrollCode,
            requestPolicyId: requestPolicyId,
            eventValidityInMonths: newRequestPolicyPayrollCode.eventValidityInMonths
          }
        })
        .then(() => {
          closeFlyIn()
        })
    } else {
      await updateMutation
        .mutateAsync({
          updateRequestPolicyPayrollCodeCommand: {
            requestPolicyId: requestPolicyId,
            requestPolicyPayrollCodeId: getRequestPolicyPayrollCodeById.requestPolicyPayrollCodeById.id,
            eventValidityInMonths: newRequestPolicyPayrollCode.eventValidityInMonths
          }
        })
        .then(() => {
          getRequestPolicyPayrollCodeByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicy.payrollcodes.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCode"
          label={`${t('form.field.payrollcode')} *`}
          options={getPayrollcodes?.allPayrollCodesForSelfService.data.map(
            x => new FormSelectOption(x.id, `${x.userFriendlyDescription ?? x.description}`)
          )}
          disabled={!!getRequestPolicyPayrollCodeById}
        />

        <FormNumericInput
          sx={12}
          name="eventValidityInMonths"
          placeholder={'0'}
          label={`${t('flyin.requestpolicy.payrollcodes.form.event-validity')} *`}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddRequestPolicyPayrollcode
