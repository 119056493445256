import styled from '@emotion/styled'
import {
  GetFirstDayOfNextYear,
  GetFirstDayOfPreviousYear,
  GetFirstDayOfYear,
  GetYearOfDate,
  ToBackendFormatedDate,
  isDateStringBeforeDate,
  useSuspenseGetBalanceForRequestPolicyContractByTeamStructureQuery,
  useSuspenseGetRequestPolicyContractByIdByTeamStructureQuery
} from '@epix-web-apps/core'
import { PeriodNavigation, useGlobalStore } from '@epix-web-apps/ui'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { Box, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'

/* eslint-disable-next-line */
export interface BalanceCalculationListProps {
  requestPolicyContractId: string
}

export function BalanceCalculationList({ requestPolicyContractId }: BalanceCalculationListProps) {
  const theme = useTheme()
  const { me } = useGlobalStore()
  const [currentYear, setCurrentYear] = useState(GetFirstDayOfYear(new Date()))

  const SmallSecondaryTypography = styled(Typography)(() => ({
    color: theme.palette.text.primary,
    fontSize: '0.7em'
  }))

  const { data: balanceData } = useSuspenseGetBalanceForRequestPolicyContractByTeamStructureQuery({
    requestPolicyContractId: requestPolicyContractId || '',
    viewDate: ToBackendFormatedDate(currentYear)
  })

  const { data: requestPolicyContract } = useSuspenseGetRequestPolicyContractByIdByTeamStructureQuery({
    id: requestPolicyContractId
  })

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 1
        }
      }}
      position="right"
    >
      <Box>
        <PeriodNavigation
          disabledLeftButton={
            currentYear.getFullYear() <=
            parseISO(requestPolicyContract?.requestPolicyContractByIdByTeamStructure.startDate).getFullYear()
          }
          disabledRightButton={isDateStringBeforeDate(
            currentYear,
            requestPolicyContract?.requestPolicyContractByIdByTeamStructure.endDate
          )}
          next={() => setCurrentYear(GetFirstDayOfNextYear(currentYear))}
          prev={() => setCurrentYear(GetFirstDayOfPreviousYear(currentYear))}
          currentMonth={GetYearOfDate(currentYear, me?.locale.locale || 'en-GB')}
        />
      </Box>
      {balanceData?.balanceForRequestPolicyContractByTeamStructure.map((item, index) => {
        const bold =
          index === 0 ||
          index === balanceData.balanceForRequestPolicyContractByTeamStructure.length - 1 ||
          (parseISO(item.date).toLocaleDateString() === new Date().toLocaleDateString() && item.calculation === 0)
        return (
          <TimelineItem key={index}>
            <TimelineOppositeContent
              sx={{
                minWidth: '7rem',
                pl: 0,
                textAlign: 'right',
                m: 'auto 0'
              }}
            >
              <Typography
                component="span"
                sx={{
                  textAlign: 'left',
                  fontWeight: bold ? 'bold' : 'normal'
                }}
              >
                {parseISO(item.date).toLocaleDateString(me?.locale.locale)}
              </Typography>
              {item.manualEventTypeValue && (
                <SmallSecondaryTypography>{item.manualEventTypeValue}</SmallSecondaryTypography>
              )}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot
                variant="outlined"
                sx={{
                  borderColor:
                    item.calculation < 0
                      ? theme.palette.error.dark
                      : item.calculation > 0
                      ? theme.palette.success.dark
                      : theme.palette.info.dark,
                  width: '2.5em',
                  height: '2.5em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '0.8em',
                    fontWeight: bold ? 'bold' : 'normal'
                  }}
                >
                  {item.balance}
                </Typography>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                m: 'auto 0',
                fontWeight: bold ? 'bold' : 'normal'
              }}
            >
              {item.description}
              <SmallSecondaryTypography>
                {item.calculation > 0 ? '+' + item.calculation : item.calculation < 0 ? item.calculation : null}
              </SmallSecondaryTypography>
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

export default BalanceCalculationList
