import {
  ToBackendFormatedDate,
  useNavigateWithParams,
  useSuspenseGetRequestPolicyContractsByPersonIdByTeamStructureQuery
} from '@epix-web-apps/core'
import { SelfService, useGlobalStore } from '@epix-web-apps/ui'
import EventIcon from '@mui/icons-material/Event'
import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { SuspensePaper } from '../../../myepix/components/suspense-paper'
import PendingRequestComponent from '../../components/absence-components/pending-request-component'
import BalanceChart from '../../components/balance-components/balance-chart'
import { MonthlyCalendar } from '../../components/calendar-components/monthly-calendar'
import TasksWarning from '../../components/tasks/tasks-warning'

/* eslint-disable-next-line */
export interface HomePageProps {}

export function HomePage(props: HomePageProps) {
  const { me } = useGlobalStore()
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigateWithParams()

  const [searchParams, _] = useSearchParams()
  const date = searchParams.get('date') !== null ? parseISO(searchParams.get('date')!) : new Date()

  const { data: requestPolicyContracts } = useSuspenseGetRequestPolicyContractsByPersonIdByTeamStructureQuery()

  const hasBalanceShownTrue = requestPolicyContracts?.allRequestPolicyContractsByPersonIdByTeamStructure
    .flatMap(item => item.requestPolicyContractModels)
    .some(item => item.balanceShown)

  function hasRequestPolicies() {
    const requestPolicies = requestPolicyContracts?.allRequestPolicyContractsByPersonIdByTeamStructure
    return requestPolicies ? requestPolicies.length > 0 : false
  }

  return (
    <Grid container spacing={2} px={1}>
      <Grid container item xs={12} md={6}>
        <Grid item xs={12}>
          <Typography variant="h4" mb={1}>
            {t('selfservice.homepage.calendar')}
          </Typography>
          {me?.personId && <MonthlyCalendar personId={me.personId} />}
        </Grid>
        <Grid item mt={2} xs={12}>
          <TasksWarning />
        </Grid>
      </Grid>

      <Grid item container xs={12} md={6} height={'100%'}>
        <Grid item xs={12} height={'50%'}>
          {me?.personId && hasRequestPolicies() && (
            <>
              {hasBalanceShownTrue && (
                <Typography variant="h4">
                  {t('selfservice.homepage.balances')} {date.toLocaleDateString(me?.locale.locale)}
                </Typography>
              )}
              <Box sx={{ marginTop: 2 }}>
                {hasBalanceShownTrue && (
                  <SuspensePaper sx={{ border: 0, p: 0 }}>
                    <BalanceChart dateOfCalendar={date} personId={me.personId} />
                  </SuspensePaper>
                )}
                <Box display="flex" marginTop={2} justifyContent="space-between">
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<EventIcon />}
                    onClick={() =>
                      navigate(SelfService.SELFSERVICE_REQUEST_ABSENCE_DATE(`${me.personId}`), {
                        date: ToBackendFormatedDate(date)
                      })
                    }
                  >
                    {t('selfservice.balances.requestabsence')}
                  </Button>
                  <Link underline="always" align="right" onClick={() => navigate(SelfService.BALANCE_OVERVIEW())}>
                    <Typography
                      color={theme.palette.primary.main}
                      fontSize="default"
                      fontWeight="bold"
                      sx={{ whiteSpace: 'nowrap', marginBottom: 0.5 }}
                    >
                      {t('selfservice.balances.viewallbalances')}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={12} height={'50%'}>
          <PendingRequestComponent />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default HomePage
