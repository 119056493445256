import {
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetSmartPayComponentByIdQuery,
  useSuspenseGetSmartPayComponentHistoryByIdQuery
} from '@epix-web-apps/core'
import {
  Configuration,
  DetailBox,
  DetailBoxRow,
  DetailPageBaseQueryParams,
  HeaderTitleNavigation,
  useFlyIn,
} from '@epix-web-apps/ui'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme
} from '@mui/material'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddSmartInputCode } from '../add-edit-smart-input-code'
import { AddSmartOutputCode } from '../add-edit-smart-output-code'
import { EditSmartPayComponent } from '../add-edit-smart-pay-component'
import { SmartPayInputCodeDataTable } from './smart-pay-input-code-datatable'
import { SmartPayOutputCodeDataTable } from './smart-pay-output-code-datatable'

type SmartPayComponentSettingsPageParams = DetailPageBaseQueryParams & {
  employerid: string
  smartpaycomponentid: string
}

export function SmartPayComponentSettings() {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigateWithParams()
  const { openFlyIn } = useFlyIn()
  const params = useRequiredParams<SmartPayComponentSettingsPageParams>()
  const employerId = params.employerid

  const { data: me } = useSuspenseGetMeQuery()
  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })

  const { data: getSmartPayComponentById, refetch: refetchSmartPayComponentById } =
    useSuspenseGetSmartPayComponentByIdQuery({
      id: params.smartpaycomponentid
    })

  const [selectedHistory, setSelectedHistory] = useState(getSmartPayComponentById.smartPayComponentById.histories[0].id)

  useEffect(() => {
    if (getSmartPayComponentById) {
      setSelectedHistory(getSmartPayComponentById.smartPayComponentById?.histories[0].id)
    }
  }, [getSmartPayComponentById])

  const {
    data: getSmartPayComponentHistoryById,
    refetch: refetchSmartPayComponentHistoryById,
    isLoading: isLoadingHistory
  } = useSuspenseGetSmartPayComponentHistoryByIdQuery({
    id: selectedHistory
  })

  const goToOutputCode = (outputCodeId: string) => {
    const icpId = params.id
    const employerId = params.employerid
    const smartpaycomponentid = params.smartpaycomponentid
    const historyId = selectedHistory

    if (icpId && employerId && smartpaycomponentid && historyId) {
      navigate(
        Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID_SMARTPAYCOMPONENTHISTORY_ID_OUTPUTCODE_ID(
          icpId,
          employerId,
          smartpaycomponentid,
          historyId,
          outputCodeId
        )
      )
    }
  }

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 3,
          paddingLeft: 3,
          paddingRight: 2
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS(
            `${params.id}`,
            `${params.employerid}`
          )}
          title={t('smartpaycomponentsettingspage.title')}
        />

        <Grid container>
          <Grid item md={9}>
            <Typography color={theme.palette.text.secondary} variant="h3" paddingBottom={2}>
              {getEmployerById?.employerById.companyName} -{' '}
              {getSmartPayComponentById?.smartPayComponentById.description}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <FormControl fullWidth size="small" sx={{ mt: 1, pt: 1 }}>
              <InputLabel id="history-select-label" sx={{ left: '-1rem', fontWeight: 'bold' }}>
                {t('smartpaycomponentsettingspage.history-selection')}
              </InputLabel>
              <Select
                value={selectedHistory}
                fullWidth
                labelId="history-select-label"
                id="history-select"
                style={{ borderRadius: 2, maxWidth: '20rem' }}
              >
                {getSmartPayComponentById?.smartPayComponentById?.histories?.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {`${parseISO(item.validFrom).toLocaleDateString(me?.me.locale.locale)} - ${
                      item.validTo ? parseISO(item.validTo).toLocaleDateString(me?.me.locale.locale) : '...'
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid columnSpacing={'2rem'} container>
          <Grid item md={6} lg={4}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.generalsettings.title')}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <EditSmartPayComponent
                          employerId={params.employerid}
                          smartPayComponentId={params.smartpaycomponentid}
                          refetch={refetchSmartPayComponentById}
                        />
                      )
                    })
                  }
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <DetailBoxRow>
                <Typography>
                  <span className="bold">
                    {' '}
                    {getSmartPayComponentById?.smartPayComponentById.description} (
                    {getSmartPayComponentById?.smartPayComponentById.code})
                  </span>
                </Typography>
              </DetailBoxRow>
            </DetailBox>
          </Grid>
        </Grid>
        <Grid container columnSpacing={'4rem'}>
          <Grid item md={6}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.inputcodes.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddSmartInputCode
                          smartComponentHistoryId={selectedHistory}
                          employerId={params.employerid}
                          refetch={refetchSmartPayComponentHistoryById}
                        />
                      )
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  {t('smartpaycomponentsettingspage.inputcodes.add')}
                </Button>
              }
            >
              <SmartPayInputCodeDataTable
                isLoadingHistory={isLoadingHistory}
                smartPayComponentHistoryId={selectedHistory}
                inputCodes={getSmartPayComponentHistoryById?.smartPayComponentHistoryById.inputCodes ?? []}
                refetch={refetchSmartPayComponentHistoryById}
                employerId={params.employerid}
              />
            </DetailBox>
          </Grid>
          <Grid item md={6}>
            <DetailBox
              title={t('smartpaycomponentsettingspage.outputcodes.title')}
              actionIcon={
                <Button
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: (
                        <AddSmartOutputCode
                          smartComponentHistoryId={selectedHistory}
                          employerId={params.employerid}
                          refetch={refetchSmartPayComponentHistoryById}
                        />
                      )
                    })
                  }
                >
                  <AddCircleOutlineIcon />
                  {t('smartpaycomponentsettingspage.outputcodes.add')}
                </Button>
              }
            >
              <SmartPayOutputCodeDataTable
                isLoadingHistory={isLoadingHistory}
                smartPayComponentHistoryId={selectedHistory}
                outputCodes={getSmartPayComponentHistoryById?.smartPayComponentHistoryById.outputCodes ?? []}
                refetch={refetchSmartPayComponentHistoryById}
                goToOutputCode={goToOutputCode}
                employerId={params.employerid}
              />
            </DetailBox>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default SmartPayComponentSettings
