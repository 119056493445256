import { useSuspenseGetContractByIdQuery, useUpdateContractClosureInformationMutation } from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, object, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'
import { IcpCodeBadge, IcpCodeBadgeSize } from '../../icp-components'

/* eslint-disable-next-line */
export interface EditContractClosureParameters {
  contractId: string
}

export function EditContractClosureParameters({ contractId }: EditContractClosureParameters) {
  const { t } = useTranslation()
  const EditContractClosureParameters = object({
    calculatedByEpix: boolean(),
    currentPayPeriod: date().optional().nullable(),
    correctionsSent: date().optional().nullable(),
    fixedDataSent: date().optional().nullable()
  })
  type EditContractClosureParameters = TypeOf<typeof EditContractClosureParameters>
  const { closeFlyIn } = useFlyIn()
  const { data: getContractById, refetch: refetchContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  const contract = getContractById?.contractById
  const mutation = useUpdateContractClosureInformationMutation()
  const form = useForm<EditContractClosureParameters>({
    resolver: zodResolver(EditContractClosureParameters),
    defaultValues: {
      calculatedByEpix: contract?.calculatedByEpix ?? false,
      currentPayPeriod: contract?.currentPayPeriod ? parseISO(contract.currentPayPeriod) : null,
      correctionsSent: contract?.correctionsSent ? parseISO(contract.correctionsSent) : null,
      fixedDataSent: contract?.fixedDataSent ? parseISO(contract.fixedDataSent) : null
    }
  })

  const handleOnSubmit = async (contractClosureInfo: EditContractClosureParameters) => {
    await mutation
      .mutateAsync({
        updateContractClosureInformationCommand: {
          contractId: contractId,
          calculatedByEpix: contractClosureInfo.calculatedByEpix,
          currentPayPeriod: contractClosureInfo.currentPayPeriod,
          correctionsSent: contractClosureInfo.correctionsSent,
          fixedDataSent: contractClosureInfo.fixedDataSent
        }
      })
      .then(() => {
        closeFlyIn()
        refetchContractById()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontract.closureinformation.title')}</Typography>
      <Box sx={{ display: 'flex', marginBottom: '1rem' }}>
        <IcpCodeBadge sx={{ marginRight: '1rem' }} icpCode={contract?.icp?.code} size={IcpCodeBadgeSize.large} />
        <Box>
          <Typography>
            {contract && parseISO(contract.startDate).toLocaleDateString()} -{' '}
            {contract?.endDate ? parseISO(contract.endDate).toLocaleDateString() : '...'}
          </Typography>
          <Typography>
            {contract?.employeeNumber &&
              `${t('contractdetailpage.label.employeenumber')}: ${contract?.employeeNumber} |`}{' '}
            {contract?.employer?.companyName} ({contract?.icp.code})
          </Typography>
        </Box>
      </Box>
      <FormGridLayout>
        <FormDatepicker sx={12} name="currentPayPeriod" label={t('form.field.currentpayperiod')} />

        <FormDatepicker sx={12} name="correctionsSent" label={t('form.field.correctionssent')} />

        <FormDatepicker sx={12} name="fixedDataSent" label={t('form.field.fixeddatasent')} />

        <FormSwitch sx={12} name="calculatedByEpix" label={t('form.field.payrollviaepix')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractClosureParameters
