import { MANUAL_EVENT_TYPE, useGetAllManualEventsByContractIdQuery } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, MenuItem } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../data-table'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import { AddEditManualEvent, EditManualEvent } from '../add-edit-manual-event'
import { RemoveManualEvent } from '../remove-manual-event'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface ManualEventsOverviewProps {
  contractId: string
}

export function ManualEventsOverview({ contractId }: ManualEventsOverviewProps) {
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [rowCount, setRowCount] = useState(0)
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [manualEventId, setManualEventId] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const {
    data: getAllManualEvents,
    isLoading,
    refetch
  } = useGetAllManualEventsByContractIdQuery({
    contractId: contractId,
    limit: -1,
    offset: 0
  })

  const activeActions = (rowParams: GridRenderCellParams) => {
    const { row } = rowParams
    const isRemoveBalance = row.eventType === MANUAL_EVENT_TYPE.REMOVE_BALANCE
    const absenceType = row.absenceType.toLowerCase()

    if (isRemoveBalance) {
      return '- ' + row.amount + ' ' + absenceType
    } else {
      return row.amount + ' ' + absenceType
    }
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setManualEventId(rowParams.row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'payrollCode',
      headerName: t('manualevent.datatable.column.payrollcode'),
      flex: 2,
      sortable: false
    },
    {
      field: 'date',
      headerName: t('manualevent.datatable.column.date'),
      flex: 1,
      sortable: false
    },
    {
      field: 'amount',
      headerName: t('manualevent.datatable.column.amount'),
      flex: 1,
      renderCell: activeActions,
      sortable: false
    },
    {
      field: 'comment',
      headerName: t('manualevent.datatable.column.comment'),
      flex: 2,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  useEffect(() => {
    if (getAllManualEvents && getAllManualEvents?.allManualEventsByContractId) {
      const manualEvents = getAllManualEvents.allManualEventsByContractId
      setTableData(
        manualEvents.data.map(row => {
          return {
            id: row.id,
            payrollCode: row.payrollCodeDescription,
            date: `${parseISO(row.date).toLocaleDateString()}${
              row.endDate ? ' - ' + parseISO(row.endDate).toLocaleDateString() : ''
            }`,
            amount: row.amount,
            eventType: row.eventType.key,
            absenceType: row.absenceType.value,
            comment: row.comment
          }
        })
      )
      setRowCount(manualEvents.data.length)
    }
  }, [getAllManualEvents])

  return (
    <>
      <DataTable hideFooter data={tableData} columns={columns} totalRowCount={rowCount} isLoading={isLoading} />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <EditManualEvent contractId={contractId} manualEventId={manualEventId} />,
              callbackAfterClose: () => () => {
                refetch()
              }
            })
          }
        >
          {t('requestpolicycontractpage.list.row.menu.edit-manualevent')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemoveManualEvent manualEventId={manualEventId} />,
              callbackAfterClose: () => () => {
                refetch()
              }
            })
          }
        >
          {t('requestpolicycontractpage.list.row.menu.delete-manualevent')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ManualEventsOverview
