import {
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetCollectiveScheduleByIdQuery,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetIcpByIdQuery
} from '@epix-web-apps/core'
import { Box, Typography, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation } from '@epix-web-apps/ui'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import CalendarYear from '../../components/schedule-components/calendar-year/calendar-year'

/* eslint-disable-next-line */
export interface CollectiveCalendarDaysPageProps {}

export type CollectiveCalendarDaysDetailPageParams = DetailPageBaseQueryParams & {
  employerid: string
  collectivecalendarid: string
}

export function CollectiveCalendarDaysPage(props: CollectiveCalendarDaysPageProps) {
  const params = useParams<CollectiveCalendarDaysDetailPageParams>()
  const { t } = useTranslation()

  const employerId = params.employerid!
  const icpId = params.id!

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })
  const { data: getIcpById } = useSuspenseGetIcpByIdQuery({
    icpId: icpId
  })
  const { data: getCollectiveScheduleById } = useSuspenseGetCollectiveScheduleByIdQuery({
    collectiveScheduleId: params.collectivecalendarid || ''
  })

  const { data: getCountries } = useSuspenseGetAllCountriesQuery()
  const code = getIcpById?.icpById.code
  const country = getCountries?.countries.find(c => c.code.includes(code ? code : ''))?.name
  const payrollProvider = getIcpById?.icpById.payrollProvider
  const company = getEmployerById?.employerById.companyName

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%'
      }}
    >
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '700px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: [1],
            paddingTop: [1],
            paddingBottom: [0]
          }}
        >
          <HeaderTitleNavigation
            backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_COLLECTIVE_CALENDAR(
              `${params.id}`,
              `${params.employerid}`
            )}
            title={
              t('collectivescheduledayspage.title') +
              ': ' +
              getCollectiveScheduleById?.collectiveSchedule.description +
              ' (' +
              getCollectiveScheduleById?.collectiveSchedule.code +
              ')'
            }
          />
        </Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          <Typography color={'gray'} variant="h3" paddingTop={0} pl={1}>
            {country} - {payrollProvider} - {company}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexGrow: 1,
            minHeight: '100%',
            paddingBottom: 2
          }}
        >
          <Paper sx={{ flexGrow: 1, minHeight: '700px', border: 'none' }}>
            <CalendarYear employerId={employerId} collectiveScheduleId={params.collectivecalendarid} />
          </Paper>
        </Box>
      </Box>
    </Paper>
  )
}

export default CollectiveCalendarDaysPage
