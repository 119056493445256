import {
  DEFAULT_LOCALE,
  useGetPersonFamilyMemberCorrectionsByProcessIdQuery,
  useRequiredParams,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import {} from '@epix-web-apps/ui'
import DoneIcon from '@mui/icons-material/Done'
import { useTheme } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, PaginationModel } from '../../../data-table'
import DataTableEmpty from '../../../data-table/data-table-empty'
import { StepParams } from '../../generic-steps'
import DataExceedsPagesize from '../data-exceeds-pagesize'
import { minWidthPayrollClosure } from '../steps'

/* eslint-disable-next-line */
export interface FamilyCorrectionsComponentProps {}

export function FamilyCorrectionsComponent(props: FamilyCorrectionsComponentProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<StepParams>()
  const [rowCount, setRowCount] = useState(0)
  const paginationModel = new PaginationModel(0, 101)
  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: params.id
  })

  const { data: personFamilyMemberCorrections, isLoading } = useGetPersonFamilyMemberCorrectionsByProcessIdQuery({
    processId: getPayrollClosureProcess?.payrollClosureProcess.id || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const columns: GridColDef[] = [
    {
      field: 'version',
      headerName: t('personcontractdata.datatable.column.version'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employerNumber',
      headerName: t('personcontractdata.datatable.column.employernumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'employeeNumber',
      headerName: t('personcontractdata.datatable.column.employeenumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'firstName',
      headerName: t('personcontractdata.datatable.column.firstname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'otherNames',
      headerName: t('personcontractdata.datatable.column.othernames'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastNamePrefix',
      headerName: t('personcontractdata.datatable.column.lastnameprefix'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'lastName',
      headerName: t('personcontractdata.datatable.column.lastname'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderTypeKey',
      headerName: t('personcontractdata.datatable.column.gendertypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'genderType',
      headerName: t('personcontractdata.datatable.column.gendertype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'dateOfBirth',
      headerName: t('personcontractdata.datatable.column.dateofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'placeOfBirth',
      headerName: t('personcontractdata.datatable.column.placeofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirthKey',
      headerName: t('personcontractdata.datatable.column.countryofbirthkey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'countryOfBirth',
      headerName: t('personcontractdata.datatable.column.countryofbirth'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalityKey',
      headerName: t('personcontractdata.datatable.column.nationalitykey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationality',
      headerName: t('personcontractdata.datatable.column.nationality'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'nationalitySecurityNumber',
      headerName: t('personcontractdata.datatable.column.nationalitysecuritynumber'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationTypeKey',
      headerName: t('personcontractdata.datatable.column.relationtypekey'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    },
    {
      field: 'relationType',
      headerName: t('personcontractdata.datatable.column.relationtype'),
      minWidth: minWidthPayrollClosure,
      sortable: false
    }
  ]

  useEffect(() => {
    if (personFamilyMemberCorrections && personFamilyMemberCorrections?.personFamilyMemberCorrectionsByProcessId) {
      const personFamilyCorrections = personFamilyMemberCorrections.personFamilyMemberCorrectionsByProcessId
      setTableData(
        personFamilyCorrections.data.map((row: any, index: number) => {
          return {
            id: index,
            version: row.version,
            employerNumber: row.employerNumber,
            employeeNumber: row.employeeNumber,
            firstName: row.firstName,
            otherNames: row.otherNames,
            lastNamePrefix: row.lastNamePrefix,
            lastName: row.lastName,
            genderTypeKey: row.genderType?.key,
            genderType: row.genderType?.value,
            dateOfBirth: row.dateOfBirth ? parseISO(row.dateOfBirth).toLocaleDateString(DEFAULT_LOCALE) : null,
            placeOfBirth: row.placeOfBirth,
            countryOfBirthKey: row.countryOfBirth,
            countryOfBirth: row.countryOfBirth,
            nationalityKey: row.nationality,
            nationality: row.nationality,
            nationalitySecurityNumber: row.nationalSecurityNumber,
            relationTypeKey: row.relationType?.key,
            relationType: row.relationType?.value
          }
        })
      )
      setRowCount(personFamilyCorrections.totalCount)
    }
  }, [personFamilyMemberCorrections])

  return (
    <>
      {rowCount === paginationModel.pageSize && <DataExceedsPagesize />}
      <DataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        hideFooter={true}
        isLoading={isLoading}
        emptyStateElement={
          <DataTableEmpty
            title={t('emptystate.process.contract')}
            icon={<DoneIcon sx={{ fontSize: '4.5rem', fill: theme.palette.primary.light }} />}
          />
        }
      />
    </>
  )
}

export default FamilyCorrectionsComponent
