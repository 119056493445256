import { useSuspenseGetAsbplContractDetailByContractIdQuery } from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import { Edit } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditASBPLSocialSecurity } from './edit-asb-pl-social-security'
import { EditASBPLGeneral } from './edit-asb-pl-general'
import { EditASBPLTaxes } from './edit-asb-pl-taxes'

interface PLASBProps {
  contractId: string
}

export function PLASB({ contractId }: PLASBProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data, refetch } = useSuspenseGetAsbplContractDetailByContractIdQuery({ contractId: contractId })

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbpldetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                data &&
                openFlyIn({
                  content: <EditASBPLSocialSecurity contractDetail={data.asbplContractDetailByContractId} />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <Edit />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {t('asbpldetailpage.socialsecurity.nationalhealthfund')}:{' '}
              <strong>{data?.asbplContractDetailByContractId.socialSecurityNationalHealthFundType?.value}</strong>
            </Typography>
          </DetailBoxRow>
        </DetailBox>
        <DetailBox
          title={t('asbpldetailpage.general.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                data &&
                openFlyIn({
                  content: <EditASBPLGeneral contractDetail={data.asbplContractDetailByContractId} />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <Edit />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {t('asbpldetailpage.general.disabilitystage')}:{' '}
              <strong>{data?.asbplContractDetailByContractId.generalDisabilityStageType?.value}</strong>
            </Typography>
          </DetailBoxRow>
        </DetailBox>
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbpldetailpage.taxes.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                data &&
                openFlyIn({
                  content: <EditASBPLTaxes contractDetail={data.asbplContractDetailByContractId} />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <Edit />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {t('asbpldetailpage.taxes.taxidnumber')}:{' '}
              <strong>{data?.asbplContractDetailByContractId.taxesTaxIdentificationNumber}</strong>
            </Typography>
          </DetailBoxRow>

          <DetailBoxRow>
            <Typography>
              {t('asbpldetailpage.taxes.taxlevel')}:{' '}
              <strong>{data?.asbplContractDetailByContractId.taxesTaxLevelType?.value}</strong>
            </Typography>
          </DetailBoxRow>
        </DetailBox>
      </Box>
    </Box>
  )
}
