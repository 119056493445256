import { addDays, ToBackendFormatedDate, useGetPagedBirthdaysQuery, useNameof } from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Box, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRowId } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTable, SortModel } from '../../data-table'

type TableRow = {
  id: string
  name: string
  date: string
  yearsOfService: string
}

/* eslint-disable-next-line */
export interface OverviewEventBirthdaysProps {}

export function OverviewEventBirthdays(props: OverviewEventBirthdaysProps) {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { nameof } = useNameof<TableRow>()
  const { icpFilters } = useGlobalPersistedStore()

  const [tableData, setTableData] = useState<TableRow[]>()

  const { data: dataUpcomingBirthdays, isLoading: isLoadingBirthdays } = useGetPagedBirthdaysQuery({
    icps: icpFilters,
    startDate: ToBackendFormatedDate(new Date()),
    endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
    offset: 0,
    limit: -1
  })

  const columns: GridColDef<TableRow>[] = [
    {
      field: nameof('name'),
      headerName: t('analytics.homepage.events.name'),
      flex: 3,
      sortable: false
    },
    {
      field: nameof('date'),
      headerName: t('analytics.homepage.events.date'),
      flex: 2,
      sortable: false
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns[0]?.field))

  const goToPersonDetailPage = (personId: GridRowId | undefined) => {
    personId && navigate(People.PEOPLE_ID_DETAILS(`${personId}`))
  }

  useEffect(() => {
    if (dataUpcomingBirthdays) {
      setTableData(
        dataUpcomingBirthdays.pagedBirthdays.data.map(row => {
          return {
            id: row.personId,
            name: row.firstName + ' ' + row.lastName,
            date: parseISO(row.upcomingDate).toLocaleDateString(),
            yearsOfService: row.years.toString()
          }
        })
      )
    }
  }, [dataUpcomingBirthdays])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '30rem'
      }}
    >
      <Typography variant="h4">{t('analytics.homepage.events.title.birthdays')}</Typography>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <DataTable
          totalRowCount={dataUpcomingBirthdays?.pagedBirthdays.totalCount}
          data={tableData}
          columns={columns}
          onSortChange={setSortModel}
          isLoading={isLoadingBirthdays}
          sortModel={sortModel}
          hideFooter={true}
          onRowClick={params => {
            goToPersonDetailPage(params.id)
            closeFlyIn()
          }}
          emptyStateElement={
            <Typography
              sx={{
                mt: 2,
                color: theme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              {t('analytics.homepage.events.noresults')}
            </Typography>
          }
        />
      </Box>
    </Box>
  )
}

export default OverviewEventBirthdays
