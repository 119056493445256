import { AiCompanionSearchResponseModel } from '@epix-web-apps/core'
import { Box, Divider, Link, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'

export interface AiCompanionResultsProps {
  response: AiCompanionSearchResponseModel | null
}

export function AiCompanionResults({ response }: AiCompanionResultsProps) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <Box>
      {response && (
        <>
          <Typography variant="h6">{t('aicompanionpage.result.info')}</Typography>
          <Box sx={{ color: theme.palette.primary.main }}>
            <Markdown>{response.answer}</Markdown>
          </Box>

          {response.results.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">{t('aicompanionpage.result.sites')}</Typography>

              {response.results.map((result, index) => (
                <Box key={'link-' + index} display={'flex'}>
                  <Link color={theme.palette.secondary.main} href={result.url} target="_blank" rel="noopener">
                    [{index + 1}] {result.url}{' '}
                  </Link>
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  )
}

export default AiCompanionResults
