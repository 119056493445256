import {
  GraphqlError,
  useUploadDocumentForContractDocumentTypeMutation,
  useUploadDocumentForEmployerDocumentTypeMutation
} from '@epix-web-apps/core'
import {
  ACCEPTED_UPLOAD_TYPES,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormFileUpload,
  FormGridLayout,
  ZodFileArray,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object } from 'zod'

/* eslint-disable-next-line */
export interface AddDocumentProps {
  documentTypeId: string
  contractId?: string
}

export function AddDocument({ documentTypeId, contractId }: AddDocumentProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const acceptedFileTypes = [
    ACCEPTED_UPLOAD_TYPES.JPEG,
    ACCEPTED_UPLOAD_TYPES.PNG,
    ACCEPTED_UPLOAD_TYPES.PDF,
    ACCEPTED_UPLOAD_TYPES.EXCEL,
    ACCEPTED_UPLOAD_TYPES.XML
  ]
  const addDocumentSchema = object({
    documents: ZodFileArray(acceptedFileTypes, true)
  })

  type AddDocumentForm = TypeOf<typeof addDocumentSchema>

  const form = useForm<AddDocumentForm>({
    resolver: zodResolver(addDocumentSchema),
    defaultValues: {
      documents: []
    }
  })

  const uploadDocumentForEmnployerDocumentTypeMutation = useUploadDocumentForEmployerDocumentTypeMutation()
  const uploadDocumentForContractDocumentTypeMutation = useUploadDocumentForContractDocumentTypeMutation()

  const uploadDocument = async (
    document: AddDocumentForm,
    uploadFn: (payload: any) => Promise<any>,
    extraPayload: Record<string, any> = {}
  ) => {
    await Promise.all(document.documents.map(({ file }) => uploadFn({ file, documentTypeId, ...extraPayload })))
  }

  const handleOnSubmit = async (document: AddDocumentForm) => {
    try {
      if (contractId !== undefined) {
        await uploadDocument(document, uploadDocumentForContractDocumentTypeMutation.mutateAsync, { contractId })
      } else {
        await uploadDocument(document, uploadDocumentForEmnployerDocumentTypeMutation.mutateAsync)
      }
      closeFlyIn()
    } catch (error: any) {
      setBackendErrors([error])
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.adddocument.title')}</Typography>

      <FormGridLayout>
        <FormFileUpload
          sx={12}
          name="documents"
          buttonText={t('documentcomponent.button.title')}
          accept={acceptedFileTypes}
        />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={
          uploadDocumentForEmnployerDocumentTypeMutation.isPending ||
          uploadDocumentForContractDocumentTypeMutation.isPending
        }
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddDocument
