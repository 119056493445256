import {
  GetSmartPayComponentByIdQuery,
  useCreateSmartPayComponentMutation,
  useSuspenseGetSmartPayComponentByIdQuery,
  useUpdateSmartPayComponentMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface AddSmartPayComponentProps {
  employerId: string
  refetch: () => void
}

export interface EditSmartPayComponentProps extends AddSmartPayComponentProps {
  smartPayComponentId: string
}

export function AddSmartPayComponent({ employerId, refetch }: AddSmartPayComponentProps) {
  return AddEditSmartPayComponent(employerId, refetch)
}

export function EditSmartPayComponent({ employerId, smartPayComponentId, refetch }: EditSmartPayComponentProps) {
  const query = useSuspenseGetSmartPayComponentByIdQuery({
    id: smartPayComponentId
  })

  return AddEditSmartPayComponent(employerId, refetch, query)
}

function AddEditSmartPayComponent(
  employerId: string,
  refetch: () => void,
  getSmartPaycomponentByIdQuery?: UseSuspenseQueryResult<GetSmartPayComponentByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const smartPayComponentByIdData = getSmartPaycomponentByIdQuery?.data.smartPayComponentById

  const addEditSmartPayComponentSchema = object({
    code: string({
      required_error: t('form.validation.coderequired'),
      invalid_type_error: t('form.validation.coderequired')
    }).min(1, t('form.validation.coderequired')).max(10, t('form.validation.maxlength', {length: 10})),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.coderequired')).max(100, t('form.validation.maxlength', {length: 100}))
  })

  type CreateEditSmartPayComponentForm = TypeOf<typeof addEditSmartPayComponentSchema>
  const form = useForm<CreateEditSmartPayComponentForm>({
    resolver: zodResolver(addEditSmartPayComponentSchema),
    defaultValues: {
      code: smartPayComponentByIdData?.code,
      description: smartPayComponentByIdData?.description
    }
  })

  const createMutation = useCreateSmartPayComponentMutation()
  const updateMutation = useUpdateSmartPayComponentMutation()

  const handleOnSubmit = (newSmartPayComponent: CreateEditSmartPayComponentForm) => {
    if (!smartPayComponentByIdData) {
      createMutation
        .mutateAsync({
          createSmartPayComponentCommand: {
            code: newSmartPayComponent.code,
            description: newSmartPayComponent.description,
            employerId: employerId
          }
        })
        .then(() => {
          refetch()
          closeFlyIn()
        })
    } else {
      updateMutation
        .mutateAsync({
          updateSmartPayComponentCommand: {
            id: smartPayComponentByIdData.id,
            code: newSmartPayComponent.code,
            description: newSmartPayComponent.description
          }
        })
        .then(() => {
          refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditsmartpaycomponent.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.code')}`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditSmartPayComponent
