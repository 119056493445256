import { Box } from '@mui/material'
import { HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ToRouteDateFormat, useSuspenseGetAllPendingAbsenceRequestByPersonIdQuery } from '@epix-web-apps/core'
import { DateRange, ToDateRangeFormat } from '@epix-web-apps/core'
import { PendingRequestsOverview } from '../../components/absence-components/pending-requests-overview'
import { useSearchParams } from 'react-router-dom'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface PendingRequestsProps {}

export function PendingRequests(props: PendingRequestsProps) {
  const { t } = useTranslation()
  const { me } = useGlobalStore()
  const [absenceRequests, setAbsenceRequests] = useState<any[]>([])
  const [searchParams] = useSearchParams()
  const dateSearchParam = searchParams.get('date')

  const { data: getPendingAbsenceRequests } = useSuspenseGetAllPendingAbsenceRequestByPersonIdQuery()

  useEffect(() => {
    if (getPendingAbsenceRequests && getPendingAbsenceRequests.pendingAbsenceRequestsByPersonId) {
      setAbsenceRequests(
        getPendingAbsenceRequests.pendingAbsenceRequestsByPersonId.map(a => {
          return {
            id: a.id,
            dateRange: ToDateRangeFormat(
              {
                startDate: parseISO(a.startDate),
                endDate: parseISO(a.endDate)
              } as DateRange,
              me?.locale.locale ?? ''
            ),
            absenceRequestType: a.absenceRequestType.key,
            requestPolicyUserFriendlyDescription: a.requestPolicyUserFriendlyDescription,
            requestedByPersonFistName: a.requestedByPersonFistName,
            requestedByPersonPreferredLastName: a.requestedByPersonPreferredLastName
          }
        })
      )
    }
  }, [getPendingAbsenceRequests])

  return (
    <Box>
      <HeaderTitleNavigation
        backToLink={SelfService.SELFSERVICE_DATE(
          `${ToRouteDateFormat(dateSearchParam ? parseISO(dateSearchParam) : new Date())}`
        )}
        mobileSizeTitle
        title={t('selfservice.pendingrequests.title')}
      />
      <Box>
        <PendingRequestsOverview absenceRequests={absenceRequests} />
      </Box>
    </Box>
  )
}

export default PendingRequests
