import { CSSObject } from '@emotion/styled'
import {
  Module,
  OrderDirection,
  ROLE,
  useCanAccess,
  useSuspenseGetAllPayGroupsQuery,
  useSuspenseGetMeQuery,
  useSuspenseGetTenantFeaturesQuery
} from '@epix-web-apps/core'
import {
  CanView,
  CompanyCar,
  Configuration,
  Documents,
  FlyIn,
  FlyInProvider,
  GeneralRoutes,
  Import,
  LoadingOverlay,
  People,
  Process,
  useRouteDefinitions,
  ValidationErrors
} from '@epix-web-apps/ui'
import { AssessmentOutlined, FolderOpenOutlined, Redeem } from '@mui/icons-material'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import PeopleIcon from '@mui/icons-material/PeopleOutlined'
import SettingsIcon from '@mui/icons-material/SettingsOutlined'
import SpeedIcon from '@mui/icons-material/Speed'
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { AppBar, Box, Drawer, IconButton, Link, List, styled, Theme, Toolbar, Tooltip, useTheme } from '@mui/material'
import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { matchRoutes, Outlet, useLocation } from 'react-router-dom'
import { environment } from '../../../../src/environments/environment'
import IcpFilter from '../components/icp-components/icp-filter'
import ListItemTooltipComponent from '../components/list-item-tooltip-component'
import { SearchPerson } from '../components/people-components'
import UserMenu from '../components/user-menu'

const DRAWER_WIDTH = 270

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 4px)`
})

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'open'
})<any>(({ open, theme }) => {
  return {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }
})

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'open'
})<any>(({ open, theme }) => {
  return {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    }),
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.background.default
      },
      '& .MuiListItemButton-root:hover': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.primary.main
        }
      },
      '& .MuiListItemButton-root.Mui-selected': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        '& .MuiSvgIcon-root, & .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.primary.main
        }
      }
    }
  }
})

const Main = styled('main', {
  shouldForwardProp: prop => prop !== 'open'
})<any>(({ open, theme }) => {
  return {
    overflow: 'hidden',
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + 1rem)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  }
})

/* eslint-disable-next-line */
export interface DrawerFlyinLayoutProps {}

export function DrawerFlyinLayout(props: DrawerFlyinLayoutProps) {
  const routeDefinitions = useRouteDefinitions()
  const routesWithoutIcpFilter = [
    { path: '/tenant-configuration' },
    { path: '/configuration/*' },
    { path: '/import' },
    { path: '/company-cars/*' },
    { path: '/ai-companion' }
  ]
  const location = useLocation()
  const matches = matchRoutes(routesWithoutIcpFilter, location)
  const { data: getMeQuery } = useSuspenseGetMeQuery()
  const me = getMeQuery?.me
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const toggleDrawer = () => setOpen(!open)

  const { data: tenantFeaturesData } = useSuspenseGetTenantFeaturesQuery()
  const tenantFeatures = tenantFeaturesData?.tenantFeatures

  useEffect(() => {
    if (me?.locale.locale) {
      sessionStorage.setItem('language', me.locale.locale)
      i18n.changeLanguage(me.locale.locale)
    }
  }, [me?.locale.locale])

  const canViewLabourCostNavItem = useCanAccess([ROLE.ADMIN, ROLE.VIEWLABOURCOSTANALYTICS])
  const canViewProductivityNavItem = useCanAccess([ROLE.ADMIN, ROLE.VIEWPRODUCTIVITYANALYTICS])
  const canViewReportingAssistant = useCanAccess([ROLE.ADMIN, ROLE.MANAGEREPORTINGASSISTANT])

  const { data: payGroups } = useSuspenseGetAllPayGroupsQuery({
    icps: [],
    hasCalendarManagement: true,
    offset: 0,
    limit: 0,
    sortByProperty: null,
    orderDirection: OrderDirection.Asc
  })

  return (
    <FlyInProvider>
      <Box
        className="desktop-layout"
        sx={{
          display: 'flex',
          minHeight: '100vh'
        }}
      >
        <StyledAppBar color="transparent" elevation={0} position="fixed" open={open}>
          <Toolbar>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                color: theme.palette.primary.light,
                marginRight: '36px',
                marginLeft: '-8px',
                [theme.breakpoints.up('sm')]: {
                  marginRight: '36px',
                  marginLeft: '-16px'
                },
                ...(open && { display: 'none' })
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ overflowX: 'auto', mr: 1 }}> {!matches && <IcpFilter />}</Box>
            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'end', alignItems: 'center', gap: 1 }}>
              <SearchPerson />
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <Tooltip title={t('header.tochangelog')}>
                  <Link href={environment.docs.domainName + '/changelog'} target="_blank" rel="noopener">
                    <IconButton size="small">
                      <Redeem />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title={t('header.todocumentation')}>
                  <Link href={environment.docs.domainName} target="_blank" rel="noopener">
                    <IconButton size="small">
                      <MenuBookOutlinedIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              </Box>
              <UserMenu />
            </Box>
          </Toolbar>
        </StyledAppBar>
        <StyledDrawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1]
            }}
          >
            {open && (
              <IconButton onClick={toggleDrawer}>
                <ChevronLeftIcon />
              </IconButton>
            )}
          </Toolbar>
          <List
            component="nav"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1
            }}
          >
            <Box>
              <ListItemTooltipComponent
                open={open}
                to={GeneralRoutes.ROOT}
                icon={<HomeIcon />}
                text={t('side.menu.home')}
              />
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPEOPLECONTRACTS]}>
                <ListItemTooltipComponent
                  open={open}
                  to={`${People.PEOPLE()}`}
                  icon={<PeopleIcon />}
                  text={t('side.menu.people')}
                />
              </CanView>
              {tenantFeatures.calculatedByEpix && (
                <CanView roles={[ROLE.ADMIN, ROLE.VIEWVALIDATIONERRORS]}>
                  <ListItemTooltipComponent
                    open={open}
                    to={ValidationErrors.VALIDATION_ERROR()}
                    icon={<WarningAmberOutlinedIcon />}
                    text={t('side.menu.validations')}
                  />
                </CanView>
              )}
              {tenantFeatures.calculatedByEpix && tenantFeatures.hasPayComponentManagement && (
                <CanView roles={[ROLE.ADMIN, ROLE.MANAGETASKSANDPROCESSES]}>
                  <ListItemTooltipComponent
                    open={open}
                    to={Process.PROCESSES()}
                    icon={<TaskOutlinedIcon />}
                    text={t('side.menu.processes')}
                  />
                </CanView>
              )}
              <CanView roles={[ROLE.ADMIN, ROLE.VIEWANALYTICS]}>
                <ListItemTooltipComponent
                  open={open}
                  to={routeDefinitions.Analytics.ROOT}
                  icon={<AssessmentOutlined />}
                  text={t('side.menu.analytics')}
                  subItems={[
                    ...(canViewReportingAssistant && tenantFeatures.hasReporting
                      ? [
                          {
                            to: routeDefinitions.Analytics.REPORTING_DEFINITIONS,
                            text: t('side.menu.reporting-analytics'),
                            icon: <InsightsOutlinedIcon />
                          }
                        ]
                      : []),
                    {
                      to: routeDefinitions.Analytics.WORKFORCE,
                      text: t('side.menu.workforceanalytics'),
                      icon: <StackedBarChartIcon />
                    },
                    ...(canViewProductivityNavItem && payGroups?.allPayGroups.totalCount > 0
                      ? [
                          {
                            to: routeDefinitions.Analytics.PRODUCTIVITY,
                            text: t('side.menu.productivity-analytics'),
                            icon: <SpeedIcon />
                          }
                        ]
                      : []),
                    ...(canViewLabourCostNavItem
                      ? [
                          {
                            to: routeDefinitions.Analytics.LABOUR_COST,
                            text: t('side.menu.labourcost-analytics'),
                            icon: <DashboardOutlinedIcon />
                          }
                        ]
                      : [])
                  ]}
                />
              </CanView>
            </Box>
            <Box>
              {tenantFeatures?.hasEpixCopilot && (
                <CanView roles={[ROLE.CUSTOM, ROLE.ADMIN]}>
                  <ListItemTooltipComponent
                    open={open}
                    to="/ai-companion"
                    icon={<AutoAwesomeIcon />}
                    text={t('side.menu.ai-companion')}
                  />
                </CanView>
              )}
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGEEMPLOYERDOCUMENTTYPES]} module={Module.Myepixdocuments}>
                <ListItemTooltipComponent
                  open={open}
                  to={Documents.DOCUMENTS}
                  icon={<FolderOpenOutlined />}
                  text={t('side.menu.documentmanagement')}
                />
              </CanView>
              <CanView roles={[ROLE.ADMIN, ROLE.IMPORTDATA]}>
                <ListItemTooltipComponent
                  open={open}
                  to={Import.IMPORT}
                  icon={<CloudUploadOutlinedIcon />}
                  text={t('side.menu.import')}
                />
              </CanView>
              {tenantFeatures?.hasCompanyCars && (
                <CanView roles={[ROLE.MANAGECOMPANYCARS, ROLE.ADMIN]}>
                  <ListItemTooltipComponent
                    open={open}
                    to={CompanyCar.COMPANY_CAR}
                    icon={<DirectionsCarIcon />}
                    text={t('side.menu.companycars')}
                  />
                </CanView>
              )}
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGECONFIGURATION]}>
                <ListItemTooltipComponent
                  open={open}
                  to={Configuration.ICP_PROVIDERS()}
                  icon={<SettingsIcon />}
                  text={t('side.menu.configuration')}
                />
              </CanView>
            </Box>
          </List>
        </StyledDrawer>
        <Main open={open}>
          <Suspense fallback={<LoadingOverlay />}>
            <Outlet />
          </Suspense>
        </Main>
      </Box>
      <FlyIn />
    </FlyInProvider>
  )
}

export default DrawerFlyinLayout
