import { getNumberOfColors, useSuspenseGetContractsHistoryQuery, useSuspenseGetMeQuery } from '@epix-web-apps/core'
import { useAnalyticsPersistedStore } from '../../../../stores/analytics-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BarchartComponent from '../../barchart-component'
import { parseISO } from 'date-fns'

function EvolutionTab() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, genderFilter, functionAreaFilter, functionLevelFilter, employeeTypeFilter } =
    useAnalyticsPersistedStore()
  const { data: me } = useSuspenseGetMeQuery()

  const { data: contractHistory } = useSuspenseGetContractsHistoryQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  if (contractHistory?.contractsHistory.entries.some(e => e.headcount === 0 && e.fteCount === 0))
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>

  const data = contractHistory?.contractsHistory.entries.map(x => ({
    [t('analytics.headcount')]: x.headcount,
    [t('analytics.fte')]: (x.fteCount / 100).toFixed(1),
    date: parseISO(x.date).toLocaleDateString(me?.me.locale.locale, {
      month: 'short'
    })
  }))

  return (
    <>
      <BarchartComponent
        data={data}
        keys={[t('analytics.headcount'), t('analytics.fte')]}
        layoutMode="vertical"
        indexBy={'date'}
        groupMode={'grouped'}
        colors={getNumberOfColors(2)}
        innerPadding={4}
        marginRight={50}
        marginLeft={50}
      />

      <Typography mt={1} sx={{ color: theme.palette.text.secondary }}>
        * {t('analytics.headcountexplenation')}
      </Typography>
    </>
  )
}

export default EvolutionTab
