import {
  DateRange,
  GetFirstAndLastDayOfPeriod,
  useGetAllPayComponentsQuery,
  useGetContractSmartHistoriesByContractIdQuery,
  useGetMeQuery,
  useNameof,
  usePreCalculateSmartPayComponentQuery,
  useSuspenseGetContractByIdQuery
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../../data-table'
import DropdownMenu from '../../../dropdown-menu'
import CalculateContractSmartPayComponentHistory from '../../calculate-contract-smartpaycomponent-history/calculate-contract-smartpaycomponent-history'
import EditContractSmartPayComponentHistory from '../../edit-contract-smartpaycomponent-history/edit-contract-smartpaycomponent-history'
import { RemoveContractSmartPayComponentHistory } from '../../remove-smartpaycomponent-history'
import { OutputCodePreCalculationRow } from '../contracts-salary-tab'

type ContractSmartPayComponentsRow = {
  id: string
  code: string
  description: string
  period: string
}

interface ContractSmartPayComponentsDatatableProps {
  contractId: string
  period: DateRange
  setTableDataPreCalculation: React.Dispatch<React.SetStateAction<OutputCodePreCalculationRow[]>>
}

function ContractSmartPayComponentsDatatable({
  contractId,
  period,
  setTableDataPreCalculation
}: ContractSmartPayComponentsDatatableProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { data: me } = useGetMeQuery()
  const { nameof } = useNameof<ContractSmartPayComponentsRow>()
  const [tableData, setTableData] = useState<ContractSmartPayComponentsRow[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [contractSmartHistoryId, setContractSmartHistoryId] = useState('')

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  const today = new Date()
  let endDateOfCalculation = today

  if (getContractById.contractById.payGroup) {
    const currentPayPeriod = parseISO(getContractById.contractById.currentPayPeriod)
    const dateToCalculate = currentPayPeriod < today ? today : currentPayPeriod
    const period = GetFirstAndLastDayOfPeriod(getContractById.contractById.payGroup, dateToCalculate)
    endDateOfCalculation = period.endDate
  }

  const { data: getContractSmartHistories, refetch: refetchContractSmartHistories } =
    useGetContractSmartHistoriesByContractIdQuery({
      contractId: contractId,
      startDate: period.startDate,
      endDate: period.endDate
    })

  useEffect(() => {
    if (getContractSmartHistories) {
      setTableData(
        getContractSmartHistories?.contractSmartHistoriesByContractId.map(s => {
          return {
            id: s.id,
            code: s.smartPayComponent.code,
            description: s.smartPayComponent.description,
            period: `${new Date(s.startDate).toLocaleDateString(me?.me?.locale?.locale)} - ${
              s.endDate ? new Date(s.endDate).toLocaleDateString(me?.me?.locale?.locale) : '...'
            }`
          }
        })
      )
    }
  }, [getContractSmartHistories])

  const rowActions = useCallback((row: ContractSmartPayComponentsRow) => {
    return (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          setAnchorEl(e.currentTarget)
          setContractSmartHistoryId(row.id)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )
  }, [])

  const { data: preCalculateSmartPayComponentData, refetch: preCalculateSmartPayComponent } =
    usePreCalculateSmartPayComponentQuery(
      {
        contractId: contractId,
        startDate: period.startDate,
        endDate: period.endDate,
        contractSmartPayComponentHistoryId: contractSmartHistoryId
      },
      { enabled: false }
    )

  const { refetch: refetchEarnings } = useGetAllPayComponentsQuery({
    contractId: contractId,
    startDate: period.startDate,
    endDate: period.endDate,
    isDeduction: false
  })
  const { refetch: refetchDeductions } = useGetAllPayComponentsQuery({
    contractId: contractId,
    startDate: period.startDate,
    endDate: period.endDate,
    isDeduction: true
  })

  useEffect(() => {
    if (preCalculateSmartPayComponentData) {
      setTableDataPreCalculation(
        preCalculateSmartPayComponentData.preCalculateSmartPayComponent.map(s => {
          return {
            code: s.payrollCode,
            description: s.description,
            endDate: period.endDate,
            startDate: period.startDate,
            value: s.value,
            id: s.outputCodeId,
            isDeduction: s.isDeduction,
            smartPayComponentCode: s.smartPayComponentCode,
            icpCurrencySymbol: s.icpCurrencySymbol,
            valueTypeKey: s.valueType.key
          }
        })
      )
    }
  }, [preCalculateSmartPayComponentData])

  const columns: GridColDef<ContractSmartPayComponentsRow>[] = [
    {
      field: nameof('code'),
      headerName: t('contractsalarypage.datatable.column.code'),
      flex: 0,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: nameof('description'),
      headerName: t('contractsalarypage.datatable.column.description'),
      flex: 3
    },
    {
      field: nameof('period'),
      headerName: t('contractsalarypage.datatable.column.period'),
      flex: 2
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: params => rowActions(params.row)
    }
  ]

  return (
    <>
      {tableData.length > 0 && (
        <DataTable
          data={tableData}
          columns={columns}
          isLoading={false}
          filterBarElement={
            <Typography
              sx={{
                textAlign: 'left',
                color: theme.palette.primary.main,
                my: 0
              }}
              variant="h4"
            >
              {t('contractsalarypage.smartpaycomponents')}
            </Typography>
          }
          hideFooter={true}
        />
      )}
      {contractSmartHistoryId && (
        <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <EditContractSmartPayComponentHistory
                    contractId={contractId}
                    contractSmartPayComponentHistoryId={contractSmartHistoryId}
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchEarnings()
                  refetchDeductions()
                  refetchContractSmartHistories()
                }
              })
            }
          >
            {t('contractsalarypage.list.row.menu.edit')}
          </MenuItem>
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <RemoveContractSmartPayComponentHistory
                    contractId={contractId}
                    contractSmartPayComponentHistoryId={contractSmartHistoryId}
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchEarnings()
                  refetchDeductions()
                  refetchContractSmartHistories()
                }
              })
            }
          >
            {t('contractsalarypage.list.row.menu.remove')}
          </MenuItem>
          {period.startDate < endDateOfCalculation && (
            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <CalculateContractSmartPayComponentHistory
                      contractId={contractId}
                      contractSmartPayComponentHistoryId={contractSmartHistoryId}
                      startDate={period.startDate}
                      endDate={period.endDate}
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchEarnings()
                    refetchDeductions()
                    refetchContractSmartHistories()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.recalculate')}
            </MenuItem>
          )}
          {period.startDate > endDateOfCalculation && (
            <MenuItem onClick={() => preCalculateSmartPayComponent()}>
              {t('contractsalarypage.list.row.menu.precalculate')}
            </MenuItem>
          )}
        </DropdownMenu>
      )}
    </>
  )
}

export default ContractSmartPayComponentsDatatable
