import {
  FormSelectOption,
  GetTeamAuthorizationByIdQuery,
  GraphqlError,
  OrderDirection,
  useCreateTeamAuthorizationMutation,
  useSuspenseGetAllFunctionsQuery,
  useSuspenseGetAllTeamsQuery,
  useSuspenseGetTeamAuthorizationByIdQuery,
  useUpdateTeamAuthorizationMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object, string } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddTeamAuthorizationForPersonProps {
  personId: string
}

export interface EditTeamAuthorizationForPersonProps extends AddTeamAuthorizationForPersonProps {
  teamAuthorizationId: string
}

export function AddTeamAuthorizationForPerson({ personId }: AddTeamAuthorizationForPersonProps) {
  return AddEditTeamAuthorizationForPerson(personId)
}

export function EditTeamAuthorizationForPerson({ personId, teamAuthorizationId }: EditTeamAuthorizationForPersonProps) {
  const getTeamAuthorizationByIdQuery = useSuspenseGetTeamAuthorizationByIdQuery({
    id: teamAuthorizationId
  })

  return AddEditTeamAuthorizationForPerson(personId, getTeamAuthorizationByIdQuery)
}

function AddEditTeamAuthorizationForPerson(
  personId: string,
  getTeamAuthorizationByIdQuery?: UseSuspenseQueryResult<GetTeamAuthorizationByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const getTeamAuthorizationById = getTeamAuthorizationByIdQuery?.data

  const { data: allFunctions } = useSuspenseGetAllFunctionsQuery({
    limit: -1,
    offset: 0,
    sortByProperty: 'Name',
    orderDirection: OrderDirection.Asc
  })

  const { data: allTeams } = useSuspenseGetAllTeamsQuery({})

  const addEditTeamAuthorizationForPersonSchema = object({
    roleId: string({
      required_error: t('form.validation.rolerequired'),
      invalid_type_error: t('form.validation.rolerequired')
    }),
    teamId: string({
      required_error: t('form.validation.teamrequired'),
      invalid_type_error: t('form.validation.teamrequired')
    }),
    validForChildTeams: boolean()
  })

  type AddTeamAuthorizationForPersonForm = TypeOf<typeof addEditTeamAuthorizationForPersonSchema>

  const form = useForm<AddTeamAuthorizationForPersonForm>({
    resolver: zodResolver(addEditTeamAuthorizationForPersonSchema),
    defaultValues: {
      roleId: getTeamAuthorizationById?.teamAuthorizationById.functionId,
      teamId: getTeamAuthorizationById?.teamAuthorizationById.teamId,
      validForChildTeams: getTeamAuthorizationById?.teamAuthorizationById.validForChildTeams
    }
  })

  const createMutation = useCreateTeamAuthorizationMutation()
  const updateMutation = useUpdateTeamAuthorizationMutation()

  const handleOnSubmit = async (newTeamAuthorization: AddTeamAuthorizationForPersonForm) => {
    if (!getTeamAuthorizationById) {
      await createMutation
        .mutateAsync({
          createTeamAuthorizationCommand: {
            personId: personId || '',
            teamId: newTeamAuthorization.teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateTeamAuthorizationCommand: {
            id: getTeamAuthorizationById.teamAuthorizationById.id,
            teamId: newTeamAuthorization.teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(() => {
          getTeamAuthorizationByIdQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addteamauthorization.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'roleId'}
          label={`${t('form.field.function')} *`}
          options={allFunctions?.allFunctions.data.map(x => new FormSelectOption(x.id, `${x.description} (${x.code})`))}
        />

        <FormSelect
          sx={12}
          name={'teamId'}
          label={`${t('form.field.teams')} *`}
          options={allTeams?.allTeams.map(x => new FormSelectOption(x.id, `${x.name}`))}
        />

        <FormSwitch sx={12} name="validForChildTeams" label={t('form.field.validforchildteams')} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
