import {
  FormSelectOption,
  GetAlternateWorkScheduleDayQuery,
  getReferenceFullDay,
  GraphqlError,
  OrderDirection,
  useCreateAlternateWorkScheduleDayMutation,
  useSuspenseGetAllDayDefinitionsByEmployerIdQuery,
  useSuspenseGetAllShiftCodesByEmployerIdQuery,
  useSuspenseGetAlternateWorkScheduleDayQuery,
  useSuspenseGetCalendarForDateRangeQuery,
  useSuspenseGetContractByIdQuery,
  useRequiredParams,
  useSuspenseGetMeQuery,
  useUpdateAlternateWorkScheduleDayMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Divider, Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import PersonIdentificationComponent from '../../person-identification-component'

export interface SetAlternateWorkScheduleProps {
  start: Date
  contractId: string
}

export interface EditAlternateWorkScheduleProps extends SetAlternateWorkScheduleProps {
  alternateWorkScheduleDayId: string
}

export function SetAlternateWorkSchedule({ start, contractId }: SetAlternateWorkScheduleProps) {
  return ChangeWorkSchedule(start, contractId)
}

export function EditAlternatWorkSchedule({
  start,
  contractId,
  alternateWorkScheduleDayId
}: EditAlternateWorkScheduleProps) {
  const getAlternateWorkScheduleByIdQuery = useSuspenseGetAlternateWorkScheduleDayQuery({
    id: alternateWorkScheduleDayId
  })

  return ChangeWorkSchedule(start, contractId, getAlternateWorkScheduleByIdQuery)
}

function ChangeWorkSchedule(
  start: Date,
  contractId: string,
  alternateWorkScheduleDayQuery?: UseSuspenseQueryResult<GetAlternateWorkScheduleDayQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { data: me } = useSuspenseGetMeQuery()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const params = useRequiredParams<ContractDetailPageParams>()

  const getAlternateWorkScheduleById = alternateWorkScheduleDayQuery?.data

  const { data: calendarData, refetch: refetchCalendarData } = useSuspenseGetCalendarForDateRangeQuery({
    contractId: contractId,
    startDate: start,
    endDate: start
  })

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: params.contractid
  })
  const contract = getContractById?.contractById

  const { data: dayDefinitionsData } = useSuspenseGetAllDayDefinitionsByEmployerIdQuery({
    employerId: contract.employerId ?? '',
    offset: 0,
    limit: -1,
    sortByProperty: null,
    orderDirection: OrderDirection.Asc,
    includeDefault: true
  })

  const { data: shiftCodesForEmployerData } = useSuspenseGetAllShiftCodesByEmployerIdQuery({
    employerId: contract.employerId ?? '',
    offset: 0,
    limit: -1,
    sortByProperty: null,
    orderDirection: OrderDirection.Asc
  })

  const ChangeWorkScheduleSchema = object({
    dayDefinitionId: string({
      required_error: t('form.validation.daydefinitionrequired'),
      invalid_type_error: t('form.validation.daydefinitionrequired')
    }).min(1, t('form.validation.daydefinitionrequired')),
    labelOnCalendar: string({
      required_error: t('form.validation.labeloncalendarrequired'),
      invalid_type_error: t('form.validation.labeloncalendarrequired')
    }).min(1, t('form.validation.labeloncalendarrequired')),
    shiftCodeId: string().optional().nullable()
  })

  const createMutation = useCreateAlternateWorkScheduleDayMutation()
  const updateMutation = useUpdateAlternateWorkScheduleDayMutation()

  type ChangeWorkScheduleForm = TypeOf<typeof ChangeWorkScheduleSchema>

  const form = useForm<ChangeWorkScheduleForm>({
    resolver: zodResolver(ChangeWorkScheduleSchema),
    defaultValues: {
      dayDefinitionId: getAlternateWorkScheduleById?.alternateWorkScheduleDay.dayDefinition?.id || '',
      labelOnCalendar: alternateWorkScheduleDayQuery
        ? calendarData?.calendarDaysByContractId[0].workScheduleDay?.labelOnCalendar
        : '',
      shiftCodeId: calendarData?.calendarDaysByContractId[0]?.workScheduleDay?.shiftCodeId
    }
  })

  const handleOnSubmit = async (newWorkSchedule: ChangeWorkScheduleForm) => {
    if (!getAlternateWorkScheduleById) {
      return createMutation
        .mutateAsync({
          createAlternateWorkScheduleDayCommand: {
            contractId: contractId || '',
            dayDefinitionId: newWorkSchedule.dayDefinitionId || '',
            date: start,
            labelOnCalendar: newWorkSchedule.labelOnCalendar || '',
            shiftCodeId: newWorkSchedule.shiftCodeId
          }
        })
        .then(() => {
          refetchCalendarData()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      return updateMutation
        .mutateAsync({
          updateAlternateWorkScheduleDayCommand: {
            alternateWorkScheduleDayId: getAlternateWorkScheduleById.alternateWorkScheduleDay.id,
            dayDefinitionId: newWorkSchedule.dayDefinitionId || '',
            labelOnCalendar: newWorkSchedule.labelOnCalendar,
            shiftCodeId: newWorkSchedule.shiftCodeId
          }
        })
        .then(() => {
          refetchCalendarData()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <PersonIdentificationComponent title={t('flyin.change-workschedule.title')} />
      <Divider sx={{ marginBottom: 3 }} />
      <Typography variant="h6" color={'gray'}>{`${t(
        'flyin.collectiveschedule-entry.date'
      )}: ${start?.toLocaleDateString()} (${getReferenceFullDay(start, me?.me.locale.locale, 0)})`}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={7}
          name={'dayDefinitionId'}
          label={`${t('form.field.daydefinition')}`}
          options={dayDefinitionsData.dayDefinitions.data.map(x => new FormSelectOption(x.id, x.description))}
          onChange={(e, value) => {
            form.resetField('labelOnCalendar', { defaultValue: value?.label })
          }}
        />

        <FormSelect
          sx={5}
          name={'shiftCodeId'}
          label={`${t('form.field.shiftcode')}`}
          options={shiftCodesForEmployerData?.allShiftCodesByEmployerId.data.map(
            x => new FormSelectOption(x.id, x.description)
          )}
        />

        <FormInput sx={12} name="labelOnCalendar" label={`${t('form.field.labeloncalendar')}`} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default ChangeWorkSchedule
