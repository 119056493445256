import {
  CALCULATED_DATA_VALUE_TYPE_KEYS,
  ContractModel,
  DateRange,
  GetFirstAndLastDayOfPeriod,
  VALUE_TYPE_KEYS,
  nextPeriodBasedOnPayGroup,
  prevPeriodBasedOnPayGroup,
  useGetAllPayComponentsQuery,
  useGetContractSmartHistoriesByContractIdQuery,
  useGetEmployerByIdQuery,
  useSuspenseGetMeQuery
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import OutputIcon from '@mui/icons-material/Output'
import { Box, Button, CircularProgress, IconButton, MenuItem, TableRow, Typography, useTheme } from '@mui/material'
import { GridColDef, GridColumnVisibilityModel, GridRowsProp } from '@mui/x-data-grid'
import { lastDayOfMonth, parseISO } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, DataTableCell } from '../../data-table'
import DropdownMenu from '../../dropdown-menu'
import { OnboardingPaycomponentLayout } from '../../onboarding-components/onboarding-paycomponent-layout'
import AddContractSmartPayComponentHistory from '../add-contract-smartpaycomponent-history/add-contract-smartpaycomponent-history'
import { AddPayComponent, EditPayComponent } from '../add-edit-paycomponent'
import { PeriodNavigationDatePicker } from '../period-navigation-date-picker'
import { RemovePaycomponent } from '../remove-paycomponent'
import { ViewPaycomponentHistory } from '../view-paycomponent-history'
import ContractSmartPayComponentsDatatable from './contract-smart-pay-components-datatable/contract-smart-pay-components-datatable'

/* eslint-disable-next-line */
interface ContractsSalaryTabProps {
  contract: ContractModel
}

type TableRow = {
  id: string
  payComponentId: string
  payrollCode: string
  description: string
  comment: string | null | undefined
  onlyShowCommentInFirstPeriod: boolean
  value: string
  valueType: string
  convertedValue: string
  startDate: Date
  endDate: Date | null
  isInputCode: boolean
  isOutputCode: boolean
  smartPayComponentCode?: string
  icpCurrencySymbol: string
  showActionButton: boolean
}

export type OutputCodePreCalculationRow = {
  id: string
  code: string
  description: string
  startDate: Date
  endDate: Date
  smartPayComponentCode: string
  value: string
  valueTypeKey: string
  isDeduction: boolean
  icpCurrencySymbol: string
}

export function ContractsSalaryTab({ contract }: ContractsSalaryTabProps) {
  const [period, setPeriod] = useState<DateRange>(contractInitialPeriod(contract))
  const { t } = useTranslation()
  const theme = useTheme()
  const { data: me } = useSuspenseGetMeQuery()
  const { openFlyIn } = useFlyIn()

  const [tableDataEarnings, setTableDataEarnings] = useState<TableRow[]>([])
  const [tableDataDeductions, setTableDataDeductions] = useState<GridRowsProp<TableRow>>([])
  const [tableDataPreCalculation, setTableDataPreCalculation] = useState<OutputCodePreCalculationRow[]>([])

  const {
    data: earnings,
    isLoading: isLoadingEarnings,
    refetch: refetchEarnings
  } = useGetAllPayComponentsQuery({
    contractId: contract.id,
    startDate: period.startDate,
    endDate: period.endDate,
    isDeduction: false
  })
  const {
    data: deductions,
    isLoading: isLoadingDeductions,
    refetch: refetchDeductions
  } = useGetAllPayComponentsQuery({
    contractId: contract.id,
    startDate: period.startDate,
    endDate: period.endDate,
    isDeduction: true
  })

  const { data: getContractSmartHistories, isLoading: isLoadingSmartHistories } =
  useGetContractSmartHistoriesByContractIdQuery({
    contractId: contract.id,
    startDate: period.startDate,
    endDate: period.endDate
  })

  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: contract.employerId ?? ''
    },
    {
      enabled: !!contract.employerId
    }
  )

  const { refetch: refetchContractSmartHistories } = useGetContractSmartHistoriesByContractIdQuery({
    contractId: contract.id,
    startDate: period.startDate,
    endDate: period.endDate
  })

  useEffect(() => {
    const period = contractInitialPeriod(contract)
    setPeriod(period)
  }, [contract])

  useEffect(() => {
    refetchEarnings()
    refetchDeductions()
  }, [me?.me.currency])

  useEffect(() => {
    if (earnings?.payComponents || tableDataPreCalculation) {
      const payComponentsData = earnings?.payComponents ?? []
      const earningsTableData: TableRow[] = payComponentsData.map(row => {
        return {
          id: row.payComponentHistory.current?.id ?? '',
          payComponentId: row.payComponentId,
          payrollCode: row.payComponentHistory.current?.payrollCode ?? '',
          description: row.payComponentHistory.current?.description ?? '',
          comment: row.payComponentHistory.current?.comment,
          onlyShowCommentInFirstPeriod: row.payComponentHistory.current?.onlyShowCommentInFirstPeriod ?? true,
          value: row.payComponentHistory.current?.value,
          valueType: row.payComponentHistory.current?.valueType.key ?? '',
          convertedValue: row.payComponentHistory.current?.valueRate,
          startDate: row.payComponentHistory.current?.startDate
            ? parseISO(row.payComponentHistory.current?.startDate)
            : new Date(),
          endDate: row.payComponentHistory.current?.endDate ? parseISO(row.payComponentHistory.current?.endDate) : null,
          isInputCode: row.payComponentHistory.current?.smartInputCode !== null,
          isOutputCode: row.payComponentHistory.current?.smartOutputCode !== null,
          smartPayComponentCode: row.payComponentHistory.current?.smartPayComponent?.code,
          icpCurrencySymbol: row.icpCurrencySymbol,
          showActionButton: true
        }
      })
      const smartPayComponentCalculationData: TableRow[] = tableDataPreCalculation
        .filter(c => c.isDeduction === false)
        .map(row => {
          return {
            id: row.id,
            payComponentId: row.id,
            isOutputCode: true,
            isInputCode: false,
            smartPayComponentCode: row.smartPayComponentCode,
            value: row.value,
            description: row.description,
            startDate: row.startDate,
            endDate: row.endDate,
            payrollCode: row.code,
            comment: null,
            onlyShowCommentInFirstPeriod: false,
            convertedValue: '',
            valueType: row.valueTypeKey,
            icpCurrencySymbol: row.icpCurrencySymbol,
            showActionButton: false
          }
        })
      setTableDataEarnings(earningsTableData.concat(smartPayComponentCalculationData))
      if (earnings?.payComponents && earnings?.payComponents.length > 0) {
        if (earnings?.payComponents[0].icpCurrency === me?.me.currency) {
          setColumnVisibilityModel({ convertedValue: false })
        } else {
          setColumnVisibilityModel({ convertedValue: true })
        }
      }
    }
    if (deductions?.payComponents || tableDataPreCalculation) {
      const payComponentsData = deductions?.payComponents ?? []

      const deductionsTableData: TableRow[] = payComponentsData.map(row => {
        return {
          id: row.payComponentHistory.current?.id ?? '',
          payComponentId: row.payComponentId,
          payrollCode: row.payComponentHistory.current?.payrollCode ?? '',
          description: row.payComponentHistory.current?.description ?? '',
          comment: row.payComponentHistory.current?.comment,
          onlyShowCommentInFirstPeriod: row.payComponentHistory.current?.onlyShowCommentInFirstPeriod ?? true,
          value: row.payComponentHistory.current?.value,
          valueType: row.payComponentHistory.current?.valueType.key ?? '',
          convertedValue: row.payComponentHistory.current?.valueRate,
          startDate: row.payComponentHistory.current
            ? parseISO(row.payComponentHistory.current?.startDate)
            : new Date(),
          endDate: row.payComponentHistory.current?.endDate ? parseISO(row.payComponentHistory.current?.endDate) : null,
          isInputCode: row.payComponentHistory.current?.smartInputCode !== null,
          isOutputCode: row.payComponentHistory.current?.smartOutputCode !== null,
          smartPayComponentCode: row.payComponentHistory.current?.smartPayComponent?.code,
          icpCurrencySymbol: row.icpCurrencySymbol,
          showActionButton: true
        }
      })

      const smartPayComponentCalculationData: TableRow[] = tableDataPreCalculation
        .filter(c => c.isDeduction === true)
        .map(row => {
          return {
            id: row.id,
            payComponentId: row.id,
            isOutputCode: true,
            isInputCode: false,
            smartPayComponentCode: row.smartPayComponentCode,
            value: row.value,
            description: row.description,
            startDate: row.startDate,
            endDate: row.endDate,
            payrollCode: row.code,
            comment: null,
            onlyShowCommentInFirstPeriod: false,
            convertedValue: '',
            valueType: row.valueTypeKey,
            icpCurrencySymbol: row.icpCurrencySymbol,
            showActionButton: false
          }
        })
      setTableDataDeductions(deductionsTableData.concat(smartPayComponentCalculationData))
    }
  }, [deductions, earnings, tableDataPreCalculation, me?.me])

  const [clickedPayComponentRow, setClickedPayComponentRow] = useState<TableRow | null>(null)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const RowActions = ({ row }: { row: TableRow }) =>
    row.showActionButton && (
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          setClickedPayComponentRow(row)
          setAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
    )

  const columns: GridColDef[] = [
    {
      field: 'payrollCode',
      headerName: t('contractsalarypage.datatable.column.payrollcode'),
      flex: 0,
      sortable: false,
      align: 'right',
      headerAlign: 'right'
    },
    {
      field: 'description',
      headerName: t('contractsalarypage.datatable.column.description'),
      renderCell: params => <DescriptionColumn row={params.row} period={period} />,
      flex: 2,
      sortable: false
    },
    {
      field: 'value',
      headerName: t('contractsalarypage.datatable.column.value'),
      flex: 1,
      renderCell: params => <ValueColumn row={params.row} />,
      sortable: false,
      headerAlign: 'right'
    },
    {
      field: 'convertedValue',
      headerName: me?.me.currencyName,
      flex: 1,
      sortable: false,
      renderCell: params => <ConvertedValueColumn row={params.row} currencySymbol={me?.me.currencySymbol} />,
      hideable: true,
      headerAlign: 'right'
    },
    {
      field: 'startDate',
      headerName: t('contractsalarypage.datatable.column.period'),
      flex: 2,
      renderCell: params => <PeriodColumn row={params.row} />,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: params => <RowActions row={params.row} />
    }
  ]

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    convertedValue: true,
    validFrom: true
  })

  const hasDeductions = tableDataDeductions.length > 0
  const hasEarnings = tableDataEarnings.length > 0
  const hasSmartHistories = getContractSmartHistories ? getContractSmartHistories.contractSmartHistoriesByContractId.length > 0 : false

  function NewFilterBar() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        {/* empty element for flexbox to calculate spacing correctly */}
        <Box></Box>
        <PeriodNavigationDatePicker
          period={period}
          payGroup={contract.payGroup!}
          onChange={date => {
            setPeriod({ startDate: date, endDate: lastDayOfMonth(date) })
            setTableDataPreCalculation([])
          }}
          onPrevious={() => {
            setPeriod(prevPeriodBasedOnPayGroup(period, contract.payGroup!))
            setTableDataPreCalculation([])
          }}
          onNext={() => {
            setPeriod(nextPeriodBasedOnPayGroup(period, contract.payGroup!))
            setTableDataPreCalculation([])
          }}
        />

        <Box>
          {contract.employerId && getEmployerById?.employerById.hasSmartPayComponents && (
            <Button
              variant="contained"
              onClick={() =>
                openFlyIn({
                  content: (
                    <AddContractSmartPayComponentHistory
                      contractId={contract.id}
                      employerId={contract.employerId}
                      period={period}
                    />
                  ),
                  callbackAfterClose: () => () => refetchContractSmartHistories()
                })
              }
              sx={{ marginRight: 1 }}
            >
              <AutoFixHighIcon />
              {t('salarypage.button.addsmartpaycomponent')}
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddPayComponent contractId={contract.id} employerId={contract.employerId} period={period} />,
                callbackAfterClose: () => () => {
                  refetchDeductions()
                  refetchEarnings()
                }
              })
            }
          >
            <AddIcon />
            {t('salarypage.button.additem')}
          </Button>
        </Box>
      </Box>
    )
  }

  const isInputOrOutputCode =
    clickedPayComponentRow &&
    clickedPayComponentRow.isInputCode === false &&
    clickedPayComponentRow.isOutputCode === false
  return (
    <>
      <NewFilterBar />

      {isLoadingEarnings && isLoadingDeductions && isLoadingSmartHistories && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!hasEarnings && !hasDeductions && !hasSmartHistories && !isLoadingEarnings && !isLoadingDeductions && !isLoadingSmartHistories && <OnboardingPaycomponentLayout />}

      {hasEarnings && (
        <DataTable
          data={tableDataEarnings}
          columns={columns}
          isLoading={isLoadingEarnings}
          filterBarElement={
            <Typography
              sx={{
                textAlign: 'left',
                color: theme.palette.success.dark,
                my: 0
              }}
              variant="h4"
            >
              {t('contractsalarypage.earnings')}
            </Typography>
          }
          hideFooter={true}
          columnsToHide={columnVisibilityModel}
        />
      )}
      {hasDeductions && (
        <DataTable
          data={tableDataDeductions}
          columns={columns}
          isLoading={isLoadingDeductions}
          filterBarElement={
            <Typography
              sx={{
                textAlign: 'left',
                color: theme.palette.error.dark,
                my: 0
              }}
              variant="h4"
            >
              {t('contractsalarypage.deductions')}
            </Typography>
          }
          hideFooter={true}
          columnsToHide={columnVisibilityModel}
        />
      )}
      {getEmployerById?.employerById.hasSmartPayComponents && (
        <ContractSmartPayComponentsDatatable
          contractId={contract.id}
          period={period}
          setTableDataPreCalculation={setTableDataPreCalculation}
        />
      )}

      {clickedPayComponentRow && (
        <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          {isInputOrOutputCode && (
            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <EditPayComponent
                      contractId={contract.id}
                      employerId={contract.employerId}
                      period={period}
                      payComponentId={clickedPayComponentRow.payComponentId}
                      payComponentHistoryId={clickedPayComponentRow.id}
                      editRecord={true}
                      lastHistoryEndDate={
                        earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentRow.payComponentId)
                          ?.lastHistoryEndDate ||
                        deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentRow.payComponentId)
                          ?.lastHistoryEndDate
                      }
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchDeductions()
                    refetchEarnings()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.editpaycomponent')}
            </MenuItem>
          )}
          {isInputOrOutputCode && (
            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <EditPayComponent
                      contractId={contract.id}
                      employerId={contract.employerId}
                      period={period}
                      payComponentId={clickedPayComponentRow.payComponentId}
                      payComponentHistoryId={clickedPayComponentRow.id}
                      addRecord={true}
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchDeductions()
                    refetchEarnings()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.addpaycomponent')}
            </MenuItem>
          )}
          {isInputOrOutputCode &&
            (earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentRow.payComponentId)
              ?.lastHistoryEndDate ||
              deductions?.payComponents.find(p => p.payComponentId === clickedPayComponentRow.payComponentId)
                ?.lastHistoryEndDate) && (
              <MenuItem
                onClick={() =>
                  openFlyIn({
                    content: (
                      <EditPayComponent
                        contractId={contract.id}
                        employerId={contract.employerId}
                        period={period}
                        payComponentId={clickedPayComponentRow.payComponentId}
                        payComponentHistoryId={clickedPayComponentRow.id}
                        addRecord={true}
                        addRecordAfterClosedPayComponent={true}
                        lastHistoryEndDate={
                          earnings?.payComponents.find(p => p.payComponentId === clickedPayComponentRow.payComponentId)
                            ?.lastHistoryEndDate ||
                          deductions?.payComponents.find(
                            p => p.payComponentId === clickedPayComponentRow.payComponentId
                          )?.lastHistoryEndDate
                        }
                      />
                    ),
                    callbackAfterClose: () => () => {
                      refetchDeductions()
                      refetchEarnings()
                    }
                  })
                }
              >
                {t('contractsalarypage.list.row.menu.addpaycomponentafterclosedpaycomponent')}
              </MenuItem>
            )}
          {isInputOrOutputCode && (
            <MenuItem
              onClick={() =>
                openFlyIn({
                  content: (
                    <RemovePaycomponent
                      payComponentId={clickedPayComponentRow.payComponentId}
                      payComponentHistoryId={clickedPayComponentRow.id}
                    />
                  ),
                  callbackAfterClose: () => () => {
                    refetchDeductions()
                    refetchEarnings()
                  }
                })
              }
            >
              {t('contractsalarypage.list.row.menu.removepaycomponent')}
            </MenuItem>
          )}
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <ViewPaycomponentHistory
                    payComponentId={clickedPayComponentRow.payComponentId}
                    contractId={contract.id}
                    endDate={period.endDate}
                    icpCurrencySymbol={
                      earnings?.payComponents[0].icpCurrencySymbol ||
                      deductions?.payComponents[0].icpCurrencySymbol ||
                      ''
                    }
                  />
                ),
                callbackAfterClose: () => () => {
                  refetchDeductions()
                  refetchEarnings()
                }
              })
            }
          >
            {t('contractsalarypage.list.row.menu.viewhistory')}
          </MenuItem>
        </DropdownMenu>
      )}
    </>
  )
}

export default ContractsSalaryTab

function DescriptionColumn({ row, period }: { row: TableRow; period: DateRange }) {
  const shouldShowDescription = row.onlyShowCommentInFirstPeriod ? row.startDate === period.startDate : true
  return (
    <DataTableCell sx={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
      <Typography>{row.description}</Typography>
      {shouldShowDescription && row.comment && <Typography variant="description">{row.comment}</Typography>}
    </DataTableCell>
  )
}

function ValueColumn({ row }: { row: TableRow }) {
  const { t } = useTranslation()
  return (
    <DataTableCell sx={{ justifyContent: 'end' }}>
      {row.valueType === CALCULATED_DATA_VALUE_TYPE_KEYS.PERCENTAGE ? (
        <Typography>
          {row.value} {'%'}
        </Typography>
      ) : row.valueType === CALCULATED_DATA_VALUE_TYPE_KEYS.HOURS ? (
        <Typography>
          {row.value} {t('common.hours')}
        </Typography>
      ) : row.valueType === CALCULATED_DATA_VALUE_TYPE_KEYS.AMOUNT ? (
        <Typography>
          {row.value} {row.icpCurrencySymbol}
        </Typography>
      ) : (
        <Typography>{row.value}</Typography>
      )}
    </DataTableCell>
  )
}

function ConvertedValueColumn({ row, currencySymbol }: { row: TableRow; currencySymbol?: string }) {
  return (
    <DataTableCell sx={{ justifyContent: 'end' }}>
      <Typography>{row.valueType === VALUE_TYPE_KEYS.AMOUNT && `${row.convertedValue} ${currencySymbol}`}</Typography>
    </DataTableCell>
  )
}

function PeriodColumn({ row }: { row: TableRow }) {
  return (
    <DataTableCell>
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography>
          {row.startDate.toLocaleDateString()} - {row.endDate ? row.endDate.toLocaleDateString() : '...'}
        </Typography>
        <Box display="flex" flexDirection="row" gap="5px">
          {row.smartPayComponentCode && <Typography>{`{${row.smartPayComponentCode}}`}</Typography>}
          {row.isInputCode && <ExitToAppIcon />}
          {row.isOutputCode && <OutputIcon />}
        </Box>
      </Box>
    </DataTableCell>
  )
}

function contractInitialPeriod(contract: ContractModel) {
  const today = new Date()
  const numberOfDaysInContractMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()

  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), numberOfDaysInContractMonth)

  if (contract.currentPayPeriod) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, parseISO(contract.currentPayPeriod)).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, parseISO(contract.currentPayPeriod)).endDate
    }
  }

  if (
    (contract.endDate === null && parseISO(contract.startDate) <= today) ||
    (parseISO(contract.endDate) <= lastDayOfMonth && contract.endDate >= firstDayOfMonth)
  ) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, firstDayOfMonth).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, firstDayOfMonth).endDate
    }
  }

  if (parseISO(contract.startDate) >= today) {
    return {
      startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.startDate).startDate,
      endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.startDate).endDate
    }
  }

  return {
    startDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.endDate).startDate,
    endDate: GetFirstAndLastDayOfPeriod(contract.payGroup!, contract.endDate).endDate
  }
}
