import styled from '@emotion/styled'
import { GraphqlError, useCreateTeamMembersMutation } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { Grid, IconButton, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'
import { FormSearchPerson } from '../../form-components'

/* eslint-disable-next-line */
export interface AddTeamMembersProps {
  teamId: string | undefined
}

export interface Person {
  id: string
  name: string
}

export function AddTeamMembers({ teamId }: AddTeamMembersProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const [peopleToAdd, setPeopleToAdd] = useState<Array<Person>>([])
  const theme = useTheme()

  const StyledGrid = styled(Grid)(() => ({
    width: '100%',
    margin: '0rem 0rem 0rem 1rem',
    border: '1px solid',
    padding: '0.5rem',
    borderColor: theme.palette.primary.light
  }))

  const StyledHeadGrid = styled(Grid)(() => ({
    width: '100%',
    margin: '1rem 0rem 0rem 1rem',
    color: theme.palette.primary.dark,
    border: '1px solid',
    padding: '0.5rem',
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light
  }))

  const addTeamMembersSchema = object({
    personId: string({
      required_error: t('form.validation.personrequired'),
      invalid_type_error: t('form.validation.personrequired')
    })
  })

  type AddTeamMembersForm = TypeOf<typeof addTeamMembersSchema>

  const form = useForm<AddTeamMembersForm>({
    resolver: zodResolver(addTeamMembersSchema),
    defaultValues: {
      personId: ''
    }
  })

  const createMutation = useCreateTeamMembersMutation()

  const handleOnSubmit = async () => {
    await createMutation
      .mutateAsync({
        createTeamMembersCommand: {
          personIds: peopleToAdd.map((person: Person) => person.id),
          teamId: teamId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  const removePeopleToAdd = (id: string) => {
    setPeopleToAdd(items => items.filter(item => item.id !== id))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addteammember.title')}</Typography>

      <FormGridLayout>
        <FormSearchPerson
          onPersonSelected={option => {
            option && setPeopleToAdd(item => [...item, { id: option.id, name: option.label } as Person])
            form.setValue('personId', '')
          }}
          name={'personId'}
          label={`${t('form.searchperson.label')} *`}
        />

        {peopleToAdd.length !== 0 && (
          <>
            <StyledHeadGrid container>
              <Grid item xs={8}>
                {t('flyin.addteammember.selectedpeople')}
              </Grid>
              <Grid item xs={4}>
                {t('flyin.addteammember.removeperson')}
              </Grid>
            </StyledHeadGrid>
            {peopleToAdd.map((person: Person) => (
              <StyledGrid container key={person.id}>
                <Grid item xs={8}>
                  {person.name}
                </Grid>
                <Grid item xs={4}>
                  <IconButton sx={{ m: 0, p: 0.1 }} onClick={() => removePeopleToAdd(person.id)}>
                    <DeleteOutlineOutlinedIcon fontSize="medium" />
                  </IconButton>
                </Grid>
              </StyledGrid>
            ))}
          </>
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddTeamMembers
