import {
  addDaysToDate,
  DateRange,
  FormSelectOption,
  formTypeSelectOptions,
  GetFirstAndLastDayOfPeriod,
  GetPayComponentHistoryByIdQuery,
  GraphqlError,
  isValidPayPeriodDate,
  isValidPayPeriodEndDate,
  numericString,
  OrderDirection,
  PAYPERIODTYPES,
  useCreatePayComponentHistoryMutation,
  useCreatePayComponentMutation,
  useGetActiveValueTypesPayComponentByPayrollCodeIdQuery,
  useSuspenseGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetPayComponentHistoryByIdQuery,
  useUpdatePayComponentHistoryMutation,
  WithoutTime
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Grid, Link, Typography, useTheme } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { endOfMonth, parseISO, startOfMonth } from 'date-fns'
import { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export const DATE_INPUT_FORMAT_MONTLY = 'MMMM yyyy'
export const DATE_INPUT_FORMAT = 'dd/MM/yyyy'

interface AddPaycomponentProps {
  contractId: string
  employerId: string
  period: DateRange
}

interface EditPaycomponentProps extends AddPaycomponentProps {
  payComponentHistoryId: string
  payComponentId: string
  addRecord?: boolean
  editRecord?: boolean
  addRecordAfterClosedPayComponent?: boolean
  lastHistoryEndDate?: Date
}

export function AddPayComponent({ contractId, employerId, period }: AddPaycomponentProps) {
  return <AddEditPaycomponent contractId={contractId} employerId={employerId} period={period} />
}

export function EditPayComponent({
  contractId,
  employerId,
  period,
  payComponentHistoryId,
  payComponentId,
  addRecord,
  editRecord,
  addRecordAfterClosedPayComponent,
  lastHistoryEndDate
}: EditPaycomponentProps) {
  const payComponentHistoryIdQuery = useSuspenseGetPayComponentHistoryByIdQuery({
    id: payComponentHistoryId
  })

  return (
    <AddEditPaycomponent
      contractId={contractId}
      employerId={employerId}
      period={period}
      payComponentId={payComponentId}
      addRecord={addRecord}
      editRecord={editRecord}
      addRecordAfterClosedPayComponent={addRecordAfterClosedPayComponent}
      lastHistoryEndDate={lastHistoryEndDate}
      payComponentHistoryIdQuery={payComponentHistoryIdQuery}
    />
  )
}

interface AddEditPaycomponentProps {
  contractId: string
  employerId: string
  period: DateRange
  payComponentId?: string
  addRecord?: boolean
  editRecord?: boolean
  addRecordAfterClosedPayComponent?: boolean
  lastHistoryEndDate?: Date
  payComponentHistoryIdQuery?: UseSuspenseQueryResult<GetPayComponentHistoryByIdQuery, unknown>
}

function AddEditPaycomponent({
  contractId,
  employerId,
  period,
  payComponentId,
  addRecord,
  editRecord,
  addRecordAfterClosedPayComponent,
  lastHistoryEndDate,
  payComponentHistoryIdQuery
}: AddEditPaycomponentProps) {
  const { t } = useTranslation()

  const addPaycomponentSchema = object({
    groupType: string({
      required_error: t('form.validation.grouptyperequired'),
      invalid_type_error: t('form.validation.grouptyperequired')
    }).min(1, t('form.validation.grouptyperequired')),
    subGroupType: string({
      required_error: t('form.validation.subgrouptyperequired'),
      invalid_type_error: t('form.validation.subgrouptyperequired')
    }).min(1, t('form.validation.subgrouptyperequired')),
    payrollCode: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    value: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    valueType: string({
      required_error: t('form.validation.valuetyperequired'),
      invalid_type_error: t('form.validation.valuetyperequired')
    }).min(1, t('form.validation.valuetyperequired')),
    reasonSalaryChange: string().nullable().optional(),
    comment: string().nullable().optional(),
    onlyShowCommentInFirstPeriod: boolean().optional(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? WithoutTime(data.endDate) >= WithoutTime(data.startDate) : true), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type CreatePaycomponentForm = TypeOf<typeof addPaycomponentSchema>

  const theme = useTheme()

  const { data: getPayrollcodes } = useSuspenseGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: {
      showSalaryCodes: true
    }
  })

  const getPayComponentById = payComponentHistoryIdQuery?.data

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  const currentHistory = getPayComponentById?.payComponentHistoryById

  const createMutation = useCreatePayComponentMutation()
  const createHistoryMutation = useCreatePayComponentHistoryMutation()
  const updateMutation = useUpdatePayComponentHistoryMutation()

  const addNewPayComponent = !payComponentHistoryIdQuery
  const [isPaycomponentInContractPeriod, setIsPaycomponentInContractPeriod] = useState<boolean>(true)

  function calculateStartDate(): Date {
    if (addNewPayComponent) {
      const periodAddNewPayComponent = period
      return periodAddNewPayComponent?.startDate
    } else if (editRecord) {
      return parseISO(currentHistory?.startDate)
    } else if (addRecordAfterClosedPayComponent) {
      return addDaysToDate(new Date(lastHistoryEndDate!), 1)
    } else {
      return period.startDate
    }
  }

  function calculateEndDate(): Date | null {
    if (addNewPayComponent) {
      const periodAddNewPayComponent = period
      return periodAddNewPayComponent?.endDate
    } else if (editRecord) {
      if (currentHistory?.endDate) {
        return parseISO(currentHistory.endDate)
      } else return null
    } else if (addRecordAfterClosedPayComponent && getContractById?.contractById.payGroup && lastHistoryEndDate) {
      return GetFirstAndLastDayOfPeriod(
        getContractById.contractById.payGroup,
        addDaysToDate(new Date(lastHistoryEndDate), 1)
      ).endDate
    } else return null
  }

  function checkInBoundsForContractPeriod(startDate: Date | null, endDate?: Date | null) {
    if (startDate == null) {
      setIsPaycomponentInContractPeriod(true)
      return
    }

    let isValid = true
    const startDateBoundary = startOfMonth(parseISO(getContractById?.contractById.startDate))

    if (startDate < startDateBoundary) {
      isValid = false
    }

    if (getContractById?.contractById.endDate) {
      const endDateBoundary = endOfMonth(getContractById?.contractById.endDate)
      if (startDate > endDateBoundary) isValid = false

      if (endDate) {
        if (endDate > endDateBoundary) isValid = false
      }
    }

    setIsPaycomponentInContractPeriod(isValid)
  }

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreatePaycomponentForm>({
    resolver: zodResolver(addPaycomponentSchema),
    defaultValues: {
      groupType: getPayComponentById?.payComponentHistoryById?.groupType.key,
      subGroupType: getPayComponentById?.payComponentHistoryById?.subGroupType.key,
      payrollCode: getPayComponentById?.payComponentHistoryById?.payrollCodeId,
      value: getPayComponentById?.payComponentHistoryById?.value,
      valueType: getPayComponentById?.payComponentHistoryById?.valueType.key,
      comment: getPayComponentById?.payComponentHistoryById?.comment || undefined,
      onlyShowCommentInFirstPeriod: getPayComponentById?.payComponentHistoryById.onlyShowCommentInFirstPeriod ?? true,
      reasonSalaryChange: getPayComponentById?.payComponentHistoryById?.reasonSalaryChangeType?.key || undefined,
      startDate: calculateStartDate(),
      endDate: calculateEndDate()
    }
  })

  const [showReason, setShowReason] = useState(false)
  const [showComment, setShowComment] = useState(false)
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const watchedGroupTypeKey = useWatch({
    control: form.control,
    disabled: !!payComponentHistoryIdQuery,
    name: 'groupType'
  })
  const watchedSubGroupTypeKey = useWatch({
    control: form.control,
    disabled: !!payComponentHistoryIdQuery,
    name: 'subGroupType'
  })
  const watchedPayrollcodeId = useWatch({
    control: form.control,
    disabled: !!payComponentHistoryIdQuery,
    name: 'payrollCode'
  })

  const { data: allValueTypePayComponents } = useGetActiveValueTypesPayComponentByPayrollCodeIdQuery(
    {
      payrollCodeId: watchedPayrollcodeId || ''
    },
    {
      enabled: !!watchedPayrollcodeId
    }
  )

  useEffect(() => {
    if (allValueTypePayComponents) {
      form.resetField('valueType', {
        defaultValue: allValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId[0]?.key
      })
    }
  }, [allValueTypePayComponents])

  const { groupOptions, subGroupOptions, salaryChangedReasonOptions } = formTypeSelectOptions
  const allValueOptions =
    allValueTypePayComponents?.activeValueTypePayComponentByPayrollCodeId?.map(option => ({
      id: option.key,
      label: option.value,
      active: true
    })) ?? []

  useEffect(() => {
    setShowReason(!!getPayComponentById?.payComponentHistoryById.reasonSalaryChangeType?.key)
    setShowComment(!!getPayComponentById?.payComponentHistoryById.comment)
  }, [getPayComponentById])

  const handleOnSubmit = async (newPayComponent: CreatePaycomponentForm) => {
    // we set the enddate of a monthly payperiod here because datepicker month selection will always return the first of the month
    let endDate = newPayComponent.endDate
    if (getContractById?.contractById?.payGroup?.payPeriodType.key === PAYPERIODTYPES.MONTHLY) {
      endDate = endDate ? endOfMonth(endDate) : null
    }

    if (payComponentHistoryIdQuery == null) {
      await createMutation
        .mutateAsync({
          createPayComponentCommand: {
            payrollCodeId: newPayComponent.payrollCode || '',
            contractId: contractId || '',
            startDate: newPayComponent.startDate,
            endDate: endDate,
            value: newPayComponent.value,
            comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
            onlyShowCommentInFirstPeriod: newPayComponent.onlyShowCommentInFirstPeriod ?? true,
            reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange || null,
            valueTypeKey: newPayComponent.valueType
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      if (addRecord) {
        await createHistoryMutation
          .mutateAsync({
            createPayComponentHistoryCommand: {
              id: payComponentId ?? '',
              value: newPayComponent.value,
              comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
              onlyShowCommentInFirstPeriod: newPayComponent.onlyShowCommentInFirstPeriod ?? true,
              reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange ?? null,
              endDate: endDate,
              startDate: newPayComponent.startDate
            }
          })
          .then(() => {
            payComponentHistoryIdQuery.refetch()
            closeFlyIn()
          })
          .catch(e => setBackendErrors([e]))
      } else {
        if (editRecord) {
          await updateMutation
            .mutateAsync({
              updatePayComponentHistoryCommand: {
                id: payComponentId ?? '',
                payComponentHistoryId: payComponentHistoryIdQuery.data?.payComponentHistoryById.id ?? '',
                value: newPayComponent.value,
                comment: newPayComponent.comment !== '' ? newPayComponent.comment : null,
                onlyShowCommentInFirstPeriod: newPayComponent.onlyShowCommentInFirstPeriod ?? true,
                reasonSalaryChangeTypeKey: newPayComponent.reasonSalaryChange ?? null,
                endDate: endDate,
              }
            })
            .then(() => {
              payComponentHistoryIdQuery.refetch()
              closeFlyIn()
            })
            .catch(e => setBackendErrors([e]))
        }
      }
    }
  }

  const disableEndDate =
    (addRecord && !addRecordAfterClosedPayComponent) ||
    (editRecord && (lastHistoryEndDate ?? null) !== getPayComponentById?.payComponentHistoryById.endDate)

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {addRecord ? (
        <Typography variant="h4">{t('flyin.earning.addnewhistory')}</Typography>
      ) : editRecord ? (
        <Typography variant="h4">{t('flyin.earning.editcurrenthistory')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.earning.additem')}</Typography>
      )}

      <FormGridLayout>
        <Grid item xs={12}>
          <Typography
            color={theme.palette.text.secondary}
            sx={{
              marginTop: 0.75
            }}
          >
            <Trans
              i18nKey="flyin.addpaycomponent.period"
              values={{
                numberofweeks: getContractById?.contractById.payGroup?.payPeriodNumberOfWeeks,
                payperiod: getContractById?.contractById.payGroup?.payPeriodType?.value
              }}
            />
          </Typography>
        </Grid>

        <FormDatepicker
          sx={12}
          name="startDate"
          inputFormat={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY
              ? DATE_INPUT_FORMAT_MONTLY
              : DATE_INPUT_FORMAT
          }
          label={`${t('form.field.startdate')}`}
          disabled={editRecord || addRecordAfterClosedPayComponent}
          openTo={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? 'month' : 'day'
          }
          views={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY
              ? ['year', 'month']
              : ['year', 'month', 'day']
          }
          shouldDisableDate={e => isValidPayPeriodDate(getContractById?.contractById.payGroup ?? null, e)}
          onChange={e => {
            checkInBoundsForContractPeriod(e, form.getValues().endDate)
          }}
        />

        <FormDatepicker
          sx={12}
          name="endDate"
          label={`${t('form.field.enddate')}`}
          inputFormat={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY
              ? DATE_INPUT_FORMAT_MONTLY
              : DATE_INPUT_FORMAT
          }
          disabled={disableEndDate}
          openTo={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY ? 'month' : 'day'
          }
          views={
            getContractById?.contractById.payGroup?.payPeriodType?.key === PAYPERIODTYPES.MONTHLY
              ? ['year', 'month']
              : ['year', 'month', 'day']
          }
          shouldDisableDate={e => {
            if (getContractById?.contractById.payGroup?.payPeriodType.key !== PAYPERIODTYPES.MONTHLY) {
              return isValidPayPeriodEndDate(getContractById?.contractById.payGroup ?? null, e)
            }
            return false
          }}
          onChange={e => {
            checkInBoundsForContractPeriod(form.getValues().startDate, e)
          }}
        />

        {!disableEndDate && (
          <Box ml={2}>
            <Typography color={theme.palette.text.secondary} fontStyle={'underline'}>
              <Link
                component="button"
                type="button"
                disabled={disableEndDate}
                underline="always"
                onClick={() => form.resetField('endDate', { defaultValue: null })}
              >
                {t('flyin.addpaycomponent.clearenddate')}
              </Link>
            </Typography>
          </Box>
        )}

        <FormSelect
          sx={12}
          name="groupType"
          disabled={!!payComponentHistoryIdQuery}
          label={`${t('form.field.grouptype')} *`}
          options={groupOptions}
        />

        <FormSelect
          sx={12}
          name="subGroupType"
          disabled={!!payComponentHistoryIdQuery}
          label={`${t('form.field.subgrouptype')} *`}
          options={subGroupOptions.filter(s => s.id.includes(watchedGroupTypeKey))}
        />

        <FormSelect
          sx={12}
          name="payrollCode"
          disabled={!!payComponentHistoryIdQuery}
          onChange={(e, payRollCode) => {
            const groupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(x => x.id === payRollCode?.id)
              ?.group.key
            const subGroupTypeKey = getPayrollcodes?.allPayrollCodesByEmployerId.data.find(
              x => x.id === payRollCode?.id
            )?.subGroup?.key
            form.resetField('groupType', {
              defaultValue: groupTypeKey
            })
            form.resetField('subGroupType', {
              defaultValue: subGroupTypeKey
            })
          }}
          label={`${t('form.field.payrollcode')} *`}
          options={
            watchedGroupTypeKey || watchedSubGroupTypeKey
              ? getPayrollcodes?.allPayrollCodesByEmployerId.data
                  .filter(c => c.group.key.includes(watchedGroupTypeKey))
                  .filter(c => c.subGroup?.key.includes(watchedSubGroupTypeKey))
                  .map(x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`))
              : getPayrollcodes?.allPayrollCodesByEmployerId.data.map(
                  x => new FormSelectOption(x.id, `${x.code} -  ${x.userFriendlyDescription ?? x.description}`)
                )
          }
        />

        <FormNumericInput sx={6} name="value" placeholder={'0'} label={`${t('form.field.value')} *`} />

        <FormSelect
          sx={6}
          name="valueType"
          label={`${t('form.field.valuetype')} *`}
          options={allValueOptions}
          disabled={!!payComponentHistoryIdQuery}
        />

        <Grid xs={6} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShowReason(prev => !prev)
            }}
          >
            {t('flyin.addpaycomponent.addreason')}
          </Button>
        </Grid>
        <Grid xs={6} item>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShowComment(prev => !prev)
            }}
          >
            {t('flyin.addpaycomponent.addcomment')}
          </Button>
        </Grid>

        {showReason && (
          <FormSelect
            sx={12}
            name="reasonSalaryChange"
            label={`${t('form.field.reasonsalarychange')}`}
            options={salaryChangedReasonOptions}
          />
        )}

        {showComment && (
          <>
            <FormInput sx={12} name="comment" label={t('form.field.comment')} multiline={true} />
            <FormSwitch
              sx={12}
              name="onlyShowCommentInFirstPeriod"
              label={t('flyin.addpaycomponent.onlyshowfirstperiod')}
            />
          </>
        )}
      </FormGridLayout>

      {!isPaycomponentInContractPeriod && (
        <Typography variant="h6" sx={{ color: theme.palette.warning.light }}>
          {t('flyin.addpaycomponent.paycomponentdoesnotmatchperiod')}
        </Typography>
      )}

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditPaycomponent
