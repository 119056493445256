import { Box, Divider, Typography, useTheme } from '@mui/material'
import { TypographyBold, useGlobalStore } from '@epix-web-apps/ui'
import { Trans } from 'react-i18next'
import { DocumentModel } from '@epix-web-apps/core'
import { DownloadDocument } from '../download-document'

/* eslint-disable-next-line */
export interface DocumentComponentProps {
  document: DocumentModel
  documentName: string
  addedOnDate: Date
}

export function DocumentComponent({ document, documentName, addedOnDate }: DocumentComponentProps) {
  const { me } = useGlobalStore()

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TypographyBold>{documentName}</TypographyBold>
          <Typography>
            <Trans
              i18nKey="documentcomponent.details"
              values={{ addeddate: addedOnDate?.toLocaleDateString(me?.locale.locale) }}
              components={{ tt: <span /> }}
            />
          </Typography>
        </Box>

        <Box>
          <DownloadDocument documentData={document} />
        </Box>
      </Box>
      <Divider />
    </>
  )
}

export default DocumentComponent
