import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    description: React.CSSProperties
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    description?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    description: true
  }
}

const palette = {
  primary: {
    main: '#00044E',
    light: '#F5F5FF',
    contrastText: '#dee1e6'
  },
  secondary: {
    main: '#00C291',
    light: '#7fe1c8',
    contrastText: '#FFF'
  },
  background: {
    default: '#FFFFFF'
  },
  text: {
    primary: '#171A1F',
    secondary: '#878787'
  },
  success: {
    main: '#306D32',
    light: '#96be25'
  },
  error: {
    main: '#D32F2F',
    light: '#db5858',
    dark: '#8B0000',
    contrastText: '#FFF'
  }
}

export const epixTheme = createTheme({
  palette: palette,
  shape: {
    borderRadius: 6
  },
  typography: {
    h1: {
      fontSize: '2.5rem',
      color: palette.primary.main,
      margin: '1rem 0'
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
      color: palette.primary.main,
      margin: '1rem 0'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
      color: palette.primary.main,
      margin: '1rem 0'
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 700,
      color: palette.primary.main,
      margin: '1rem 0'
    },
    h5: {
      fontSize: '1.2rem',
      color: palette.primary.main,
      margin: '0.75rem 0'
    },
    h6: {
      fontSize: '0.95rem',
      fontWeight: 700,
      color: palette.primary.main,
      margin: '0.5rem 0'
    },
    description: {
      fontSize: 'small',
      color: palette.text.secondary,
      lineHeight: 'normal'
    }
  }
})

epixTheme.components = {
  MuiLink: {
    styleOverrides: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        borderRadius: 4,
        '&:hover': {
          boxShadow: 'none'
        },
        gap: 8
      }
    }
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        border: 'none'
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        elevation: 0,
        boxShadow: 'none',
        border: `1px solid ${epixTheme.palette.divider}`,
        borderRadius: 2
      }
    }
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        elevation: 0,
        boxShadow: 'none',
        border: 'none'
      }
    }
  },
  MuiCssBaseline: {
    styleOverrides: `
      .bold {
        font-weight: bold;
      },
      p {
        margin: 0.25rem 0;
      },
    `
  }
}
