import { useCreateRequestPolicyMutation } from '@epix-web-apps/core'
import {
  Configuration,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { TypeOf, object, string } from 'zod'
import { RequestPolicyDetailPageParams } from '../../../pages/request-policy-page/request-policy-page'

/* eslint-disable-next-line */
export interface AddRequestPolicyProps {
  employerId: string
}

export function AddRequestPolicy({ employerId }: AddRequestPolicyProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const navigate = useNavigate()
  const params = useParams<RequestPolicyDetailPageParams>()

  const addRequestPolicySchema = object({
    internalName: string({
      required_error: t('form.validation.internalnamerequired'),
      invalid_type_error: t('form.validation.internalnamerequired')
    }).min(1, t('form.validation.internalnamerequired')),
    userFriendlyName: string({
      required_error: t('form.validation.userfriendlynamerequired'),
      invalid_type_error: t('form.validation.userfriendlynamerequired')
    }).min(1, t('form.validation.userfriendlynamerequired'))
  })

  type CreateRequestPolicyForm = TypeOf<typeof addRequestPolicySchema>

  const form = useForm<CreateRequestPolicyForm>({
    resolver: zodResolver(addRequestPolicySchema)
  })

  const goToRequestPolicyPage = (requestPolicyId: string | undefined) => {
    requestPolicyId &&
      navigate(
        Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES_ID(`${params.id}`, employerId, requestPolicyId)
      )
  }

  const createMutation = useCreateRequestPolicyMutation()

  const handleOnSubmit = async (newRequestPolicy: CreateRequestPolicyForm) => {
    await createMutation
      .mutateAsync({
        createRequestPolicyCommand: {
          employerId: employerId,
          internalName: newRequestPolicy.internalName,
          userFriendlyName: newRequestPolicy.userFriendlyName
        }
      })
      .then(data => {
        closeFlyIn()
        goToRequestPolicyPage(data.createRequestPolicy)
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addrequestpolicy.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="internalName" label={`${t('form.field.internalname')}*`} />

        <FormInput sx={12} name="userFriendlyName" label={`${t('form.field.userfriendlyname')}*`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddRequestPolicy
