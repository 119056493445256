import {
  useDeleteRequestPolicyMutation,
  useNavigateWithParams,
  useSuspenseGetAllRequestPolicyContractsByRequestPolicyIdQuery
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, People } from '@epix-web-apps/ui'
import { Box, Divider, Link, Typography, useTheme } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ContractTabs } from '../../contract-details-components/contract-tab-general/contract-tab-general'

interface RemoveRequestPolicyProps {
  requestPolicyId: string
  internalName: string
  closeFlyIn: () => void
}

function RemoveRequestPolicy({ requestPolicyId, internalName, closeFlyIn }: RemoveRequestPolicyProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigateWithParams = useNavigateWithParams()

  const { data: requestPolicyContracts } = useSuspenseGetAllRequestPolicyContractsByRequestPolicyIdQuery({
    requestPolicyId: requestPolicyId,
    offset: 0,
    limit: 10
  })

  const form = useForm()

  const canDelete = requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId.totalCount === 0

  function goToContractDetailPage(personId: string, contractId: string) {
    navigateWithParams(People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
      tabIndex: ContractTabs.SELFSERVICE.toString()
    })
    closeFlyIn()
  }

  const deleteMutation = useDeleteRequestPolicyMutation()

  function handleDelete() {
    deleteMutation
      .mutateAsync({
        deleteRequestPolicyCommand: {
          id: requestPolicyId
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleDelete)}>
      <Typography variant="h4">{t('requestpolicypage.delete.title')}</Typography>

      <Box sx={{ pb: '1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <Typography>{internalName}</Typography>
        </Box>
        <Divider sx={{ my: '0.5rem' }} />

        {requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId &&
          requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId.totalCount > 0 && (
            <>
              <Typography sx={{ pb: '0.5rem', color: theme.palette.text.secondary }}>
                {t('requestpolicypage.delete.hasrequestpolicycontracts')}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                  ml: '0.5rem'
                }}
              >
                {requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId.data.map(r => (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }} key={r.contractId}>
                    <Link onClick={() => goToContractDetailPage(r.personId!, r.contractId!)}>
                      {r.personFirstName} {r.personPreferredLastName}
                    </Link>
                  </Box>
                ))}
                {requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId &&
                  requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId.totalCount > 10 && (
                    <Typography sx={{ pb: '0.5rem', color: theme.palette.text.secondary }}>
                      +{requestPolicyContracts?.allRequestPolicyContractsByRequestPolicyId.totalCount - 10}{' '}
                      {t('requestpolicypage.delete.info')}
                    </Typography>
                  )}
              </Box>
            </>
          )}
      </Box>

      <FormActionButtons
        isMutating={deleteMutation.isPending}
        disabled={!canDelete}
        buttonText={t('common.delete')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
export default RemoveRequestPolicy
