import EditIcon from '@mui/icons-material/Edit'
import {
  AsbGroupPaymentModel,
  useSuspenseGetAsbGroupContractDetailsQuery,
  useSuspenseGetAsbGroupPaymentDetailsByContractIdQuery
} from '@epix-web-apps/core'
import { BorderedTableCell, DetailBox, DetailBoxRow, useFlyIn, useRouteDefinitions } from '@epix-web-apps/ui'
import { Box, IconButton, Link, Table, TableBody, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditAsbgroupSocialSecurity } from './edit-asbgroup-social-security'
import { EditAsbgroupTaxes } from './edit-asbgroup-taxes'
import { EditAsbgroupContractInfo } from './edit-asbgroup-contract-info'
import { EditAsbgroupTrexima } from './edit-asbgroup-trexima'
import { EditAsbGroupPayments } from './edit-asbgroup-payments'
import { Check, Close } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { ContractOverviewPageContent } from '../../contracts-overview-tab'

/* eslint-disable-next-line */
export interface CzAsbgroupProps {
  contractId: string
  personId: string
}

export function CzAsbgroup({ contractId, personId }: CzAsbgroupProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const routeDefinitions = useRouteDefinitions()
  const navigate = useNavigate()

  const { data: partnerSpecificDetails } = useSuspenseGetAsbGroupContractDetailsQuery({ contractId: contractId })

  const { data: getPaymentInfo, refetch: refetchPaymentInfo } = useSuspenseGetAsbGroupPaymentDetailsByContractIdQuery({
    contractId: contractId
  })

  const socialSecurity = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupSocialSecurityModel
  const contractInfo = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupContractInfoModel
  const taxes = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTaxesModel
  const trexima = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupTreximaModel
  const payments = getPaymentInfo?.asbGroupPaymentDetailsByContractId ?? []

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbgroupdetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupSocialSecurity contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {socialSecurity?.healthInsuranceCompanyType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.socialsecurity.healthinsurancecompany')}:
                <span className="bold"> {socialSecurity.healthInsuranceCompanyType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('asbgroupdetailpage.contractinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupContractInfo contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {contractInfo?.ppContractType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.ppcontracttype')}:
                <span className="bold"> {contractInfo?.ppContractType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.socialSecurityContractType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.socialsecuritycontracttype')}:
                <span className="bold"> {contractInfo.socialSecurityContractType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.alteredWorkingAbilityType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.alteredworkingability')}:
                <span className="bold"> {contractInfo.alteredWorkingAbilityType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
          {contractInfo?.disabilityType?.value && (
            <DetailBoxRow>
              <p>
                {t('asbgroupdetailpage.contractinfo.disability')}:
                <span className="bold"> {contractInfo?.disabilityType?.value}</span>
              </p>
            </DetailBoxRow>
          )}
        </DetailBox>

        <DetailBox title={t('asbgroupdetailpage.payments.title')}>
          {payments.length === 0 && (
            <>
              <DetailBoxRow sx={{ '.MuiTypography-root': { my: 0, mt: '0.25rem' } }}>
                <Typography>{t('contractdetailpage.bankmanagement.empty')}</Typography>
              </DetailBoxRow>
              <DetailBoxRow sx={{ justifyContent: 'end' }}>
                <Link
                  onClick={() =>
                    navigate(routeDefinitions.People.PEOPLE_ID_CONTRACTS_ID(personId, contractId), {
                      state: { pageContent: ContractOverviewPageContent.BankDetails }
                    })
                  }
                >
                  {t('contractdetailpage.bankmanagement.info')}
                </Link>
              </DetailBoxRow>
            </>
          )}
          {payments.length !== 0 && (
            <DetailBoxRow sx={{ pt: 2 }}>
              <Table size="small">
                <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                  <TableRow>
                    <BorderedTableCell fontWeight="bold">
                      {t('asbgroupdetailpage.payments.payrollcode')}
                    </BorderedTableCell>
                    <BorderedTableCell fontWeight="bold">
                      {t('asbgroupdetailpage.payments.description')}
                    </BorderedTableCell>
                    <BorderedTableCell fontWeight="bold" align="center">
                      {t('asbgroupdetailpage.payments.vcs')}
                    </BorderedTableCell>
                    <BorderedTableCell></BorderedTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payments.map(p => {
                    return (
                      <TableRow key={p.bankdetails.id}>
                        <BorderedTableCell>{p.bankdetails.payrollCodeCode}</BorderedTableCell>
                        <BorderedTableCell>{p.bankdetails.payrollCodeDescription}</BorderedTableCell>
                        <BorderedTableCell align="center">
                          <PaymentsCheck asbGroupPayment={p} />
                        </BorderedTableCell>
                        <BorderedTableCell align="center" padding="none">
                          <IconButton
                            size="small"
                            onClick={() =>
                              openFlyIn({
                                content: <EditAsbGroupPayments asbGroupPayment={p} />,
                                callbackAfterClose: () => refetchPaymentInfo
                              })
                            }
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </BorderedTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asbgroupdetailpage.taxes.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupTaxes contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {taxes?.hasSignedTaxReliefForm ? (
            <>
              <DetailBoxRow>
                <p>{t('asbgroupdetailpage.taxes.signedtaxreliefformactive')}</p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.hasEmployeeTaxReduction
                    ? t('asbgroupdetailpage.taxes.employeetaxreductionactive')
                    : t('asbgroupdetailpage.taxes.employeetaxreductioninactive')}
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.hasDisabilityReduction
                    ? t('asbgroupdetailpage.taxes.disabilityreductionactive')
                    : t('asbgroupdetailpage.taxes.disabilityreductioninactive')}
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {taxes?.willApplyTaxRelief
                    ? t('asbgroupdetailpage.taxes.taxreliefactive')
                    : t('asbgroupdetailpage.taxes.taxreliefinactive')}
                </p>
              </DetailBoxRow>
            </>
          ) : (
            <DetailBoxRow>
              <p>{t('asbgroupdetailpage.taxes.signedtaxreliefforminactive')}</p>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('asbgroupdetailpage.trexima.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsbgroupTrexima contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {trexima?.isSubjectToTreximaReporting ? (
            <>
              <DetailBoxRow>
                <p>{t('asbgroupdetailpage.trexima.subjectedtotreximareporting')}</p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.highesteducation')}:
                  <span className="bold"> {trexima?.educationType?.value}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.highesteducationcode')}:
                  <span className="bold"> {trexima?.educationCode}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.workpositioncode')}:
                  <span className="bold"> {trexima?.workPositionCode}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {t('asbgroupdetailpage.trexima.contractclassification')}:
                  <span className="bold"> {trexima?.contractClassificationType?.value}</span>
                </p>
              </DetailBoxRow>
              <DetailBoxRow>
                <p>
                  {trexima?.isManagementLevel
                    ? t('asbgroupdetailpage.trexima.onmanagementlevel')
                    : t('asbgroupdetailpage.trexima.notonmanagementlevel')}
                </p>
              </DetailBoxRow>
            </>
          ) : (
            <DetailBoxRow>
              <p>{t('asbgroupdetailpage.trexima.notsubjectedtotreximareporting')}</p>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default CzAsbgroup

function PaymentsCheck({ asbGroupPayment }: { asbGroupPayment: AsbGroupPaymentModel }) {
  function renderSign(field?: string) {
    if (field) {
      return <Check fontSize="small" color="success" />
    } else {
      return <Close fontSize="small" />
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {renderSign(asbGroupPayment.metaData?.variableSymbol)}
      {renderSign(asbGroupPayment.metaData?.constantSymbol)}
      {renderSign(asbGroupPayment.metaData?.specificSymbol)}
    </Box>
  )
}
