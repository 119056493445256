import {
  ToBackendFormatedDate,
  getNumberOfColors,
  useSuspenseGetMeQuery,
  useSuspenseGetProductivityEvolutionQuery
} from '@epix-web-apps/core'
import BarchartComponent from '../../analytics-components/barchart-component/barchart-component'
import { useProductivityAnalyticsPersistedStore } from '../../../stores/productivity-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

function ProductivityEvolutionTab() {
  const { data: me } = useSuspenseGetMeQuery()
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter, employerFilter, paygroupFilter, subgroupFilter } = useProductivityAnalyticsPersistedStore()
  const { data: getProductivityEvolutionQuery } = useSuspenseGetProductivityEvolutionQuery({
    viewDate: dateFilter ? ToBackendFormatedDate(dateFilter) : null,
    employerId: employerFilter,
    paygroupId: paygroupFilter,
    subgroupKeys: subgroupFilter,
    icps: icpFilters
  })

  const keys = new Set(
    getProductivityEvolutionQuery?.productivityEvolution.flatMap(p =>
      p.payrollSubGroupHours.flatMap(psgh => psgh.subGroupName)
    )
  )
  const indexByKey = 'date'

  const data = getProductivityEvolutionQuery?.productivityEvolution.map(pe => {
    const record: Record<string, any> = {}
    const dateArray = pe.date.split('-')
    record[indexByKey] = `${new Date(dateArray[0], dateArray[1] - 1).toLocaleDateString(me?.me.locale.locale, {
      year: 'numeric',
      month: 'short'
    })}`
    pe.payrollSubGroupHours.forEach(pc => {
      record[pc.subGroupName] = pc.amount
    })
    return record
  })

  if (getProductivityEvolutionQuery?.productivityEvolution.flatMap(pe => pe.payrollSubGroupHours).length === 0) {
    return <Typography sx={{ p: 2, textAlign: 'center' }}>{t('analytics.nodata')}</Typography>
  }

  return (
    <BarchartComponent
      keys={Array.from(keys)}
      indexBy={indexByKey}
      data={data}
      layoutMode="vertical"
      groupMode="stacked"
      showLegend={true}
      legendAnchor="right"
      legendDirection="column"
      legendPosition={[175, 0]}
      legendCharLength={30}
      axisLeft={t('barchartcomponent.component.axe.hours')}
      axisLeftPadding={-65}
      colors={getNumberOfColors(keys.size)}
      innerPadding={0}
      marginRight={250}
      marginLeft={80}
    />
  )
}

export default ProductivityEvolutionTab
