import {
  useRequiredParams,
  useSuspenseGetPersonByIdQuery,
  useUpdatePersonContactInformationMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { literal, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditPersonContactInfoProps {}

export function EditPersonContactInfo(props: EditPersonContactInfoProps) {
  const { t } = useTranslation()
  const editPersonContactInfoSchema = object({
    personalPhone: string().nullable(),
    personalMobile: string().nullable(),
    personalEmail: string().email(t('form.validation.invalidemail')).nullable().or(literal('')),
    workPhone: string().nullable(),
    workMobile: string().nullable(),
    workEmail: string().email(t('form.validation.invalidemail')).nullable().or(literal(''))
  })
  type EditPersonContactInfoForm = TypeOf<typeof editPersonContactInfoSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()
  const { data: getPersonById, refetch: refetchPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const mutation = useUpdatePersonContactInformationMutation()
  const form = useForm<EditPersonContactInfoForm>({
    resolver: zodResolver(editPersonContactInfoSchema),
    defaultValues: {
      personalPhone: getPersonById?.personById?.personalPhoneNumber,
      personalMobile: getPersonById?.personById?.personalMobileNumber,
      personalEmail: getPersonById?.personById?.personalEmail,
      workEmail: getPersonById?.personById?.workEmail,
      workMobile: getPersonById?.personById?.workMobileNumber,
      workPhone: getPersonById?.personById?.workPhoneNumber
    }
  })

  const handleOnSubmit = async (personContactInfo: EditPersonContactInfoForm) => {
    await mutation
      .mutateAsync({
        updatePersonContactInformationCommand: {
          personId: params.id || '',
          personalPhoneNumber: personContactInfo.personalPhone,
          personalMobileNumber: personContactInfo.personalMobile,
          personalEmail: personContactInfo.personalEmail,
          workPhoneNumber: personContactInfo.workPhone,
          workMobileNumber: personContactInfo.workMobile,
          workEmail: personContactInfo.workEmail
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.contactinfo.title')}</Typography>

      <Box sx={{ display: 'flex', gap: 8 }}>
        <p>
          <HomeOutlinedIcon />
        </p>
        <FormGridLayout>
          <FormInput sx={12} name={'personalPhone'} label={t('form.field.personalphone')} />

          <FormInput sx={12} name="personalMobile" label={t('form.field.personalmobilephone')} />

          <FormInput sx={12} name="personalEmail" label={t('form.field.personalemail')} />
        </FormGridLayout>
      </Box>
      <Box sx={{ display: 'flex', gap: 8 }}>
        <p>
          <WorkOutlineOutlinedIcon />
        </p>
        <FormGridLayout>
          <FormInput sx={12} name="workPhone" label={t('form.field.workphone')} />

          <FormInput sx={12} name="workMobile" label={t('form.field.workmobilephone')} />

          <FormInput sx={12} name="workEmail" label={t('form.field.workemail')} />
        </FormGridLayout>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonContactInfo
