import styled from '@emotion/styled'
import {
  AiCompanionSearchResponseStatus,
  GetAiCompanionSearchResponseByIdQuery,
  GraphqlError,
  useGetAiCompanionSearchResponseByIdQuery,
  useSearchCompanionAiMutation,
  useSuspenseGetAiCompanionCreditsUsedQuery,
  useSuspenseGetSearchModesQuery
} from '@epix-web-apps/core'
import { DetailPageLayout } from '@epix-web-apps/ui'
import { StarOutline } from '@mui/icons-material'
import SendIcon from '@mui/icons-material/Send'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputBase,
  ToggleButton as OriginalToggleButton,
  Paper,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material'
import { Query, QueryKey } from '@tanstack/react-query'
import { grid, quantum } from 'ldrs'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import AiCompanionResults from '../../components/ai-companion-components/ai-companion-results'

const ToggleButton = styled(OriginalToggleButton)<any>(() => {
  const theme = useTheme()
  return {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.light} !important`,
    borderRadius: '50% !important',
    width: '2.75rem',
    margin: '0 0.5rem 0 0 !important',
    padding: '9px',
    border: '0 !important',
    '&.Mui-selected, &.Mui-selected:hover': {
      color: theme.palette.common.white,
      backgroundColor: `${theme.palette.primary.main} !important`
    }
  }
})

export function AiCompanionPage() {
  const theme = useTheme()
  const { t } = useTranslation()
  registerLoaders([quantum, grid])

  const [countryDefinition, setCountryDefinition] = useState<string | null>(null)
  const [question, setQuestion] = useState('')
  const [responseId, setResponseId] = useState<string | null>(null)
  const [disableButton, setDisableButton] = useState(false)
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { data: searchModes } = useSuspenseGetSearchModesQuery({}, { refetchOnWindowFocus: false })

  useEffect(() => {
    if (searchModes) {
      setCountryDefinition(searchModes.searchModes[0]?.code)
    }
  }, [searchModes])

  const { data: getAiCredits, refetch: refetchAiCredits } = useSuspenseGetAiCompanionCreditsUsedQuery()

  const { data: responseQuery } = useGetAiCompanionSearchResponseByIdQuery(
    { id: responseId! },
    { enabled: responseId !== null, refetchInterval: responsePolling }
  )

  function responsePolling(
    query: Query<GetAiCompanionSearchResponseByIdQuery, Query, GetAiCompanionSearchResponseByIdQuery, QueryKey>
  ) {
    const data = query.state.data
    if (data) {
      if (data.aiCompanionSearchResponseById.status === AiCompanionSearchResponseStatus.Finished) {
        setDisableButton(false)
        return false
      }
      return 1000
    }
    return 1000
  }

  const mutation = useSearchCompanionAiMutation()

  async function handleSearchClick() {
    setResponseId(null)
    if (countryDefinition !== null && question !== null) {
      await mutation
        .mutateAsync({
          searchAiCompanionCommand: { mode: countryDefinition, question: question }
        })
        .then(r => {
          setResponseId(r.searchCompanion)
          setDisableButton(true)
          refetchAiCredits()
        })
        .catch(e => {
          setBackendErrors([e])
        })
    }
  }

  return (
    <DetailPageLayout>
      {/* <DetailPageSidebar>
        <Box>
          <Typography variant="h6">{t('aicompanionpage.previous-searches.title')}</Typography>
          <Typography sx={{ fontSize: '0.9rem' }}>{t('aicompanionpage.previous-searches.today')}</Typography>
          <Box sx={{ marginBottom: 1, marginTop: 0.5 }} display={'flex'}>
            <>BE</>
            <Link sx={{ pl: 0.5 }}>How many vacation days has an employee?</Link>
            <ContentCopyIcon />
            <DeleteOutlineIcon />
          </Box>

          <Typography sx={{ fontSize: '0.9rem' }}>{t('aicompanionpage.previous-searches.yesterday')}</Typography>
        </Box>
      </DetailPageSidebar> */}
      <Box sx={{ pl: 1.5, pr: 1.5, width: '100%' }}>
        <Typography variant="h2">{t('aicompanionpage.title')}</Typography>
        {searchModes && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ToggleButtonGroup
              value={countryDefinition}
              onChange={(_e, value) => setCountryDefinition(value)}
              aria-label="Country mode selection"
              exclusive={true}
              sx={{ mb: 1.5, backgroundColor: 'transparent' }}
            >
              {searchModes.searchModes.map(searchMode => (
                <ToggleButton
                  key={searchMode.code}
                  value={searchMode.code}
                  aria-label={searchMode.code}
                  disabled={mutation.isPending}
                >
                  {searchMode.code}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Box sx={{ display: 'flex', gap: 1, color: theme.palette.secondary.main }}>
              <StarOutline fontSize="small" fontWeight="bold" />
              <Typography>
                <Trans
                  i18nKey="aicompanionpage.creditsremaining"
                  values={{
                    credits: getAiCredits?.aiCompanionCreditsUsed.creditsRemaining
                  }}
                />
              </Typography>
            </Box>
          </Box>
        )}

        <Paper
          component="form"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            borderRadius: 1
          }}
        >
          <InputBase
            id="companion-ai-search"
            placeholder={t('aicompanionpage.search.label')}
            multiline
            minRows={2}
            value={question}
            onChange={event => setQuestion(event.target.value)}
            fullWidth
            aria-label={'search companion ai'}
            sx={{ padding: 2 }}
            disabled={mutation.isPending}
          />
          <Divider sx={{ height: 50, m: 0.5 }} orientation="vertical" />
          <IconButton
            color="primary"
            sx={{ p: '20px' }}
            onClick={handleSearchClick}
            disabled={
              mutation.isPending ||
              countryDefinition === null ||
              question === '' ||
              disableButton ||
              getAiCredits?.aiCompanionCreditsUsed.creditsRemaining === 0
            }
          >
            <SendIcon />
          </IconButton>
        </Paper>

        <Typography variant="description" sx={{ mt: 0.75 }}>
          {t('aicompanionpage.search.disclaimer')}
        </Typography>

        {backendErrors.length > 0 && (
          <>
            {backendErrors.map(be => (
              <Typography>{be.message}</Typography>
            ))}
          </>
        )}

        {mutation.isPending && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, py: 2 }}>
            <CircularProgress />
            <Typography className="loadingtext" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {t('aicompanionpage.loadingstate.asking')}
            </Typography>
          </Box>
        )}

        {responseQuery && (
          <>
            {responseQuery.aiCompanionSearchResponseById.status === AiCompanionSearchResponseStatus.Queued && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, py: 2 }}>
                <l-quantum size="50" speed="3" color={theme.palette.secondary.main} />
                <Typography className="loadingtext" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  {t('aicompanionpage.loadingstate.collectinginfo')}
                </Typography>
              </Box>
            )}

            {responseQuery.aiCompanionSearchResponseById.status === AiCompanionSearchResponseStatus.Processing && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, py: 2 }}>
                <l-grid size="60" speed="3" color={theme.palette.secondary.main} />
                <Typography className="loadingtext" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                  {t('aicompanionpage.loadingstate.generatingresponse')}
                </Typography>
              </Box>
            )}

            {responseQuery.aiCompanionSearchResponseById.status === AiCompanionSearchResponseStatus.Finished && (
              <AiCompanionResults response={responseQuery.aiCompanionSearchResponseById} />
            )}
          </>
        )}
      </Box>
    </DetailPageLayout>
  )
}

function registerLoaders(loaders: { register: (name?: string) => void }[]) {
  loaders.forEach(l => {
    l.register()
  })
}
