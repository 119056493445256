import {
  colors,
  getLastDayOfMonth,
  useGetMeQuery,
  useSuspenseGetCalculatedCalendarByProcessIdQuery,
  useSuspenseGetPayrollClosureProcessQuery
} from '@epix-web-apps/core'
import { TypographyBold } from '@epix-web-apps/ui'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Avatar, Box, Button, Grid, IconButton, Typography, useTheme } from '@mui/material'
import { addMonths, eachDayOfInterval, lastDayOfMonth, parseISO, startOfMonth } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginationModel } from '../../../../data-table'
import { PagingNavigation } from '../../../../paging-navigation'
import { StepProps } from '../../../generic-steps'

export function CalculatedCalendarData({ processId }: StepProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())

  const { data: getCalculatedCalendarData, isFetching } = useSuspenseGetCalculatedCalendarByProcessIdQuery({
    processId: processId,
    limit: paginationModel.pageSize,
    offset: paginationModel.offset
  })

  const { data: getPayrollClosureProcess } = useSuspenseGetPayrollClosureProcessQuery({
    id: processId
  })

  const { data: me } = useGetMeQuery()

  const processStartDate = getPayrollClosureProcess?.payrollClosureProcess.startDate
  const processEndDate = getPayrollClosureProcess?.payrollClosureProcess.endDate
  const [startDate, setStartDate] = useState<Date>(
    processStartDate ? parseISO(processStartDate) : startOfMonth(new Date())
  )
  const [endDate, setEndDate] = useState<Date>(
    processEndDate ? parseISO(processEndDate) : getLastDayOfMonth(new Date())
  )

  const months =
    getCalculatedCalendarData?.calculatedCalendarByProcessId.data
      .map(c => c.days)
      .flatMap(d => d.map(x => startOfMonth(parseISO(x.date)).getTime()))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a - b)
      .map(m => new Date(m)) || []

  const onHandlePrevMonth = () => {
    setStartDate(addMonths(startDate, -1))
    setEndDate(lastDayOfMonth(addMonths(endDate, -1)))
  }
  const onHandleNextMonth = () => {
    setStartDate(addMonths(startDate, 1))
    setEndDate(lastDayOfMonth(addMonths(endDate, 1)))
  }
  const onHandleMonth = (date: Date) => {
    setStartDate(date)
    setEndDate(lastDayOfMonth(date))
  }

  if (getCalculatedCalendarData?.calculatedCalendarByProcessId.totalCount === 0) {
    return <Typography sx={{ textAlign: 'center' }}>{t('processpage.calendarstep.noitemstoshow')}</Typography>
  }
  const interval: Interval = {
    start: startDate,
    end: endDate
  }
  const days = eachDayOfInterval(interval)
  return (
    <Box>
      {months.length > 0 && (
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 1, overflowX: 'auto' }}>
          {months.map((m, index) => {
            return (
              <Button
                key={index}
                startIcon={<FilterAltIcon />}
                variant={startDate.valueOf() === m.valueOf() ? 'contained' : 'outlined'}
                onClick={_ => onHandleMonth(m)}
                sx={{ minWidth: 230 }}
              >
                <Typography noWrap>
                  {m.toLocaleDateString(me?.me.locale.locale, { month: 'long', year: 'numeric' })}
                </Typography>
              </Button>
            )
          })}
        </Box>
      )}
      <Box sx={{ position: 'relative' }}>
        <Typography sx={{ position: 'absolute', top: '3rem', left: '-2rem' }}>
          <IconButton onClick={_ => onHandlePrevMonth()}>
            <ArrowBackIosIcon />
          </IconButton>
        </Typography>
        <Box>
          <Grid
            container
            sx={{
              borderTop: '1px solid',
              borderLeft: '1px solid',
              borderColor: 'divider',
              '& > div': {
                borderRight: '1px solid',
                borderBottom: '1px solid',
                borderColor: 'divider'
              }
            }}
          >
            {/* Header */}
            <Grid container>
              <Grid sx={{ width: 250 }}></Grid>
              <Grid item xs sx={{ fontSize: 14 }}>
                <Grid container sx={{ backgroundColor: '#f7f7fc' }} justifyContent="center" rowGap={1}>
                  <TypographyBold sx={{ fontSize: '14px', color: '#00044E' }}>
                    {startDate.toLocaleDateString(me?.me.locale.locale, { month: 'long', year: 'numeric' })}
                  </TypographyBold>
                  <Grid
                    container
                    columns={days.length}
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    sx={{ marginTop: '8px' }}
                  >
                    {days.map((day, index) => (
                      <Grid item textAlign="center" xs key={index}>
                        {day.getDate().toString().padStart(2, '0')}
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Items */}
            {getCalculatedCalendarData?.calculatedCalendarByProcessId.data.map((contractData, index) => (
              <Grid container key={index}>
                <Grid
                  item
                  sx={{
                    width: 250,
                    color: theme.palette.primary.main,
                    display: 'flex',
                    alignItems: 'center',
                    height: '50px',
                    padding: '0 8px'
                  }}
                >
                  <Avatar alt={contractData?.employeeName} sx={{ width: 30, height: 30, marginRight: 1 }} />
                  <TypographyBold
                    sx={{
                      fontSize: '0.85rem',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      flexGrow: 1
                    }}
                  >
                    <span style={{ display: 'flex', flexDirection: 'column' }}>
                      {contractData?.employeeName}
                      <sub
                        style={{
                          marginTop: '-3px',
                          color: theme.palette.grey[500]
                        }}
                      >
                        {contractData?.employeeNumber}
                      </sub>
                    </span>
                  </TypographyBold>
                  {/* REVIEW FOR FUNCTIONAL VALUES */}
                  {/* {getPayrollClosureProcess?.payrollClosureProcess.hasOriginalData && (
                    <Chip
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: 'bold',
                        borderRadius: 2
                      }}
                      variant="outlined"
                      label={`${contractData?.totalNumberOfHours}h`}
                    />
                  )} */}
                </Grid>
                <Grid item xs>
                  <Grid container columns={days.length} justifyContent="center">
                    {days.map((day, index) => {
                      const contractDay = contractData?.days.find(d => {
                        const contractDayDate = new Date(d.date)
                        return (
                          contractDayDate.getFullYear() === day.getFullYear() &&
                          contractDayDate.getMonth() === day.getMonth() &&
                          contractDayDate.getDate() === day.getDate() &&
                          d.calculatedEntries.length > 0
                        )
                      })

                      return (
                        <Grid
                          key={index}
                          item
                          xs
                          sx={{
                            borderLeft: '1px solid',
                            borderColor: 'divider',
                            height: '50px',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: 1,
                            paddingBottom: 1,
                            textAlign: 'center',
                            backgroundColor:
                              day.getDay() === 0 /* sunday */ || day.getDay() === 6 /* or saturday */
                                ? '#f7f7fc'
                                : 'inherit'
                          }}
                        >
                          {contractDay && (
                            <>
                              <TypographyBold
                                sx={{
                                  color: theme.palette.primary.main,
                                  fontSize: '0.8rem',
                                  cursor: 'default'
                                }}
                              >
                                {contractDay.calculatedEntries
                                  .map(cc => cc.numberOfHours)
                                  .reduce((a, b) => a + b, 0)
                                  .toFixed(2)}
                              </TypographyBold>
                              <Typography
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  fontSize: '0.6rem',
                                  cursor: 'default'
                                }}
                              >
                                {contractDay.calculatedEntries
                                  .map(cc => cc.payrollCode.colourCodeHex ?? colors[0])
                                  .map((dayPartColor: string, index: number) => (
                                    <span
                                      key={index}
                                      style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        width: `${100 / contractDay.calculatedEntries.length}%`,
                                        height: 10,
                                        background: dayPartColor,
                                        padding: 1,
                                        borderRadius: '3px'
                                      }}
                                    />
                                  ))}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Typography sx={{ position: 'absolute', right: '-2rem', top: '3rem' }}>
          <IconButton onClick={_ => onHandleNextMonth()}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', mt: 1 }}>
        {getCalculatedCalendarData && (
          <PagingNavigation
            hasNext={getCalculatedCalendarData.calculatedCalendarByProcessId.hasNext}
            onNextClick={() => {
              setPaginationModel(prev => new PaginationModel(prev.page + 1, prev.pageSize))
            }}
            hasPrevious={getCalculatedCalendarData.calculatedCalendarByProcessId.hasPrevious}
            onPreviousClick={() => {
              setPaginationModel(prev => new PaginationModel(prev.page - 1, prev.pageSize))
            }}
            isFetching={isFetching}
            paginationModel={paginationModel}
            totalCount={getCalculatedCalendarData?.calculatedCalendarByProcessId.totalCount}
          />
        )}
      </Box>
    </Box>
  )
}

export default CalculatedCalendarData
