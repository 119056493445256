import { OrderDirection, PayGroupBaseModel, useSuspenseGetAllPayGroupsByEmployerIdQuery } from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined'
import EventOutlinedIcon from '@mui/icons-material/EventOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import { Box, IconButton, Link } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { environment } from '../../../../../environments/environment'
import { AddPayGroup, EditPayGroup } from '../add-edit-paygroup/add-edit-paygroup'
import { ReadMoreText, TypographyIcons, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import EmployersWidgetTitle from '../employers-widget-title'

/* eslint-disable-next-line */
export interface EmployersWidgetPaygroupsProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetPaygroups({ employerId, setTabComplete }: EmployersWidgetPaygroupsProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()

  const { data: payGroups, refetch: refetchPayGroups } = useSuspenseGetAllPayGroupsByEmployerIdQuery({
    employerId: employerId || '',
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (payGroups?.allPayGroupsByEmployerId) {
      setTabComplete(payGroups?.allPayGroupsByEmployerId.length > 0)
    }
  }, [payGroups?.allPayGroupsByEmployerId])

  return (
    payGroups?.allPayGroupsByEmployerId && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.paygroups.title')}
          hasData={payGroups?.allPayGroupsByEmployerId?.length > 0}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYGROUPS(`${params.id}`, `${employerId}`)}
        />

        {payGroups?.allPayGroupsByEmployerId.slice(0, 3).map((payGroup: PayGroupBaseModel) => {
          return (
            <WidgetRowItem key={payGroup.id}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TypographySmall>{`${payGroup.name} (${payGroup.code})`}</TypographySmall>
                {(payGroup.hasCalendarManagement ||
                  payGroup.hasPayComponentManagement ||
                  payGroup.hasSelfService ||
                  payGroup.hasCarManagement ||
                  payGroup.hasDocumentManagement) && (
                  <TypographyIcons sx={{ px: '5px' }}>
                    {payGroup?.hasPayComponentManagement && <EuroOutlinedIcon />}
                    {payGroup?.hasCalendarManagement && <EventOutlinedIcon />}
                    {payGroup?.hasSelfService && <PhoneAndroidOutlinedIcon />}
                    {payGroup?.hasCarManagement && <DirectionsCarFilledOutlinedIcon />}
                    {payGroup?.hasDocumentManagement && <AttachFileOutlinedIcon />}
                  </TypographyIcons>
                )}
              </Box>
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditPayGroup employerId={employerId} payGroupId={payGroup.id} />,
                    callbackAfterClose: () => refetchPayGroups
                  })
                }
                sx={{ width: '1.5rem', height: '1.5rem' }}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
            </WidgetRowItem>
          )
        })}

        <Box>
          {payGroups?.allPayGroupsByEmployerId?.length > 3 && (
            <Link
              onClick={() =>
                navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_PAYGROUPS(`${params.id}`, employerId), {
                  state: { employerId: employerId }
                })
              }
            >
              <TypographySmall>
                {t('employerspage.widget.paygroups.and')} {payGroups?.allPayGroupsByEmployerId?.length - 3}{' '}
                {t('employerspage.widget.paygroups.more')}
              </TypographySmall>
            </Link>
          )}
          {payGroups.allPayGroupsByEmployerId.length < 4 && (
            <TypographySmall sx={{ pb: '0.25rem' }}>
              <Link
                onClick={() =>
                  openFlyIn({
                    content: <AddPayGroup employerId={employerId} />,
                    callbackAfterClose: () => refetchPayGroups
                  })
                }
              >
                {t('employerspage.widget.paygroups.addpaygroup')}
              </Link>
            </TypographySmall>
          )}
        </Box>

        <ReadMoreText
          text={payGroups?.allPayGroupsByEmployerId.length === 0 ? t('employerspage.widget.paygroups.info') : ''}
          readMoreLink={environment.docs.domainName + '/Epix/epix-configuration/provider/employer/paygroup'}
          alignRight={payGroups?.allPayGroupsByEmployerId.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetPaygroups
