import { SmartOutputCodeModel, useNameof, useUpdateSmartOutputCodeOrderMutation } from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import FunctionsIcon from '@mui/icons-material/Functions'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import OutputIcon from '@mui/icons-material/Output'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, MenuItem, Tooltip, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, DataTableCell } from '../../../data-table'
import DropdownMenu from '../../../dropdown-menu'
import { EditSmartOutputCode } from '../../add-edit-smart-output-code'
import RemoveSmartOutputCode from '../../remove-smart-output-code/remove-smart-output-code'

interface SmartPayOutputCodeDataTableProps {
  smartPayComponentHistoryId: string
  isLoadingHistory: boolean
  outputCodes: SmartOutputCodeModel[]
  refetch: () => void
  goToOutputCode: (outputCodeId: string) => void
  employerId: string
}

enum SmartPayOutputStatus {
  WARNING,
  FUNCTION,
  OUTPUT
}

type SmartPayOutputCodeRow = {
  id: string
  code: string
  description: string
  isIntermediateResult: boolean
  hasCalculationRule: boolean
  statusIcon: SmartPayOutputStatus
}

export function SmartPayOutputCodeDataTable({
  smartPayComponentHistoryId,
  isLoadingHistory,
  refetch,
  goToOutputCode,
  outputCodes,
  employerId
}: SmartPayOutputCodeDataTableProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const theme = useTheme()
  const { nameof } = useNameof<SmartPayOutputCodeRow>()
  const [outputTableData, setOutputTableData] = useState<SmartPayOutputCodeRow[]>([])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [smartOutputCodeId, setSmartOutputCodeId] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    setOutputTableData(
      outputCodes.map(outputCode => {
        let statusIcon = SmartPayOutputStatus.WARNING
        if (outputCode.hasCalculationRule) {
          statusIcon = outputCode.isIntermediateResult ? SmartPayOutputStatus.FUNCTION : SmartPayOutputStatus.OUTPUT
        }
        return {
          id: outputCode.id,
          code: outputCode.code,
          description: outputCode.description,
          hasCalculationRule: outputCode.hasCalculationRule,
          isIntermediateResult: outputCode.isIntermediateResult,
          statusIcon: statusIcon
        }
      })
    )
  }, [outputCodes])

  const rowActions = useCallback((row: SmartPayOutputCodeRow) => {
    return (
      <DataTableCell>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            setAnchorEl(e.currentTarget)
            setSmartOutputCodeId(row.id)
            setDescription(row.description)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton
          aria-label="row details"
          aria-controls="details-row"
          size="small"
          onClick={_ => {
            goToOutputCode(row.id)
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </DataTableCell>
    )
  }, [])

  const outputCodeState = useCallback((row: SmartPayOutputCodeRow) => {
    switch (row.statusIcon) {
      case SmartPayOutputStatus.WARNING:
        return (
          <DataTableCell>
            <Tooltip title={t('smartpayoutputcodedatatable.datatable.statusicon.warning-tooltip')}>
              <WarningIcon color="error" />
            </Tooltip>
          </DataTableCell>
        )
      case SmartPayOutputStatus.FUNCTION:
        return (
          <DataTableCell>
            <Tooltip title={t('smartpayoutputcodedatatable.datatable.statusicon.intermediateresult-tooltip')}>
              <FunctionsIcon />
            </Tooltip>
          </DataTableCell>
        )
      default:
        return (
          <DataTableCell>
            <Tooltip title={t('smartpayoutputcodedatatable.datatable.statusicon.output-tooltip')}>
              <OutputIcon />
            </Tooltip>
          </DataTableCell>
        )
    }
  }, [])

  const columns: GridColDef<SmartPayOutputCodeRow>[] = [
    {
      field: nameof('code'),
      headerName: t('smartpayoutputcodedatatable.datatable.column.code'),
      flex: 1,
      sortable: true
    },
    {
      field: nameof('description'),
      headerName: t('smartpayoutputcodedatatable.datatable.column.description'),
      flex: 1
    },
    {
      field: nameof('statusIcon'),
      headerName: t('smartpayoutputcodedatatable.datatable.column.status'),
      renderCell: rowParams => outputCodeState(rowParams.row)
    },
    {
      field: ' ',
      sortable: false,
      editable: false,
      align: 'center',
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  const updateSmartOutputCodeOrder = useUpdateSmartOutputCodeOrderMutation()

  const handleOrderUp = () => {
    updateSmartOutputCodeOrder
      .mutateAsync({
        updateSmartOutputCodeCommand: {
          smartOutputCodeId: smartOutputCodeId,
          smartPayComponentHistoryId: smartPayComponentHistoryId,
          increment: true
        }
      })
      .then(() => refetch())
  }

  const handleOrderDown = async () => {
    updateSmartOutputCodeOrder
      .mutateAsync({
        updateSmartOutputCodeCommand: {
          smartOutputCodeId: smartOutputCodeId,
          smartPayComponentHistoryId: smartPayComponentHistoryId,
          increment: false
        }
      })
      .then(() => refetch())
  }

  return (
    <>
      <DataTable
        data={outputTableData}
        columns={columns}
        isLoading={isLoadingHistory}
        hideFooter={true}
        emptyStateElement={
          <Typography
            sx={{
              mt: 2,
              color: theme.palette.text.secondary,
              fontStyle: 'italic'
            }}
          >
            {t('smartpayoutputcodedatatable.datatable.noresults')}
          </Typography>
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <EditSmartOutputCode
                  smartComponentHistoryId={smartPayComponentHistoryId}
                  smartOutputCodeId={smartOutputCodeId}
                  refetch={refetch}
                  employerId={employerId}
                />
              )
            })
          }
        >
          {t('smartpayoutputcodedatatable.edit-output-code')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveSmartOutputCode
                  smartPayComponentHistoryId={smartPayComponentHistoryId}
                  smartOutputCodeId={smartOutputCodeId}
                  description={description}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
        <MenuItem onClick={() => handleOrderDown()}>{t('smartpaycomponentspage.list.row.menu.move-code-up')}</MenuItem>
        <MenuItem onClick={() => handleOrderUp()}>{t('smartpaycomponentspage.list.row.menu.move-code-down')}</MenuItem>
      </DropdownMenu>
    </>
  )
}

export default SmartPayOutputCodeDataTable
