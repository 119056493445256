import {
  useRequiredParams,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetSmartOutputCodeByIdQuery,
  useSuspenseGetSmartPayComponentByIdQuery,
  useUpdateSmartCalculationRuleOrderMutation
} from '@epix-web-apps/core'
import {
  BorderedTableCell,
  Configuration,
  DetailPageBaseQueryParams,
  HeaderTitleNavigation,
  useFlyIn
} from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableRow,
  Typography,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DropdownMenu from '../../dropdown-menu'
import { AddCalculationRule, EditCalculationRule } from '../add-edit-calculation-rule/add-edit-calculation-rule'
import { smartPayComponentTypeSelectOptions } from '../form-select-options'
import RemoveCalculationRule from '../remove-calculation-rule/remove-calculation-rule'

type SmartOutputCodeSettingsPageParams = DetailPageBaseQueryParams & {
  employerid: string
  smartpaycomponentid: string
  smartpaycomponenthistoryid: string
  outputcodeid: string
}

export function SmartOutputCodeSettings() {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { calculationRuleOperators, calculationRulePartFunctions } = smartPayComponentTypeSelectOptions
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedRule, setClickedRule] = useState<string>()
  const theme = useTheme()
  const params = useRequiredParams<SmartOutputCodeSettingsPageParams>()
  const employerId = params.employerid

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId
  })

  const { data: getSmartPayComponentById } = useSuspenseGetSmartPayComponentByIdQuery({
    id: params.smartpaycomponentid
  })

  const { data: getSmartOutputCodeById, refetch: refetchSmartOutputCodeById } = useSuspenseGetSmartOutputCodeByIdQuery({
    smartOutputCodeId: params.outputcodeid,
    smartPayComponentHistoryId: params.smartpaycomponenthistoryid
  })

  const updateSmartCalculationRuleOrder = useUpdateSmartCalculationRuleOrderMutation()

  const handleOrderUp = () => {
    updateSmartCalculationRuleOrder
      .mutateAsync({
        updateSmartCalculationRuleOrderCommand: {
          smartOutputCodeId: params.outputcodeid ?? '',
          smartPayComponentHistoryId: params.smartpaycomponenthistoryid ?? '',
          smartRuleId: clickedRule ?? '',
          increment: true
        }
      })
      .then(() => refetchSmartOutputCodeById())
  }

  const handleOrderDown = () => {
    updateSmartCalculationRuleOrder
      .mutateAsync({
        updateSmartCalculationRuleOrderCommand: {
          smartOutputCodeId: params.outputcodeid ?? '',
          smartPayComponentHistoryId: params.smartpaycomponenthistoryid ?? '',
          smartRuleId: clickedRule ?? '',
          increment: false
        }
      })
      .then(() => refetchSmartOutputCodeById())
  }

  return (
    <Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: 3,
          paddingLeft: 3,
          paddingRight: 2
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_SMART_PAYCOMPONENTS_ID(
            params.id ?? '',
            params.employerid ?? '',
            params.smartpaycomponentid ?? ''
          )}
          title={t('smartpaycomponentsettingspage.title')}
        />

        <Grid container>
          <Grid item md={12}>
            <Typography color={theme.palette.text.secondary} variant="h3" paddingBottom={2}>
              {getEmployerById?.employerById.companyName} -{' '}
              {getSmartPayComponentById?.smartPayComponentById.description}
            </Typography>
          </Grid>

          {getSmartOutputCodeById && (
            <Grid item md={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h4">
                  <Trans
                    i18nKey="smartpaycomponentsettingspage.outputcode-title"
                    values={{
                      code: getSmartOutputCodeById.smartOutputCodeById.code,
                      description: getSmartOutputCodeById.smartOutputCodeById.description,
                      numberOfDecimals: getSmartOutputCodeById.smartOutputCodeById.numberOfDecimals,
                      valueDescription: getSmartOutputCodeById.smartOutputCodeById.valueTypePayComponent
                        ? `${getSmartOutputCodeById.smartOutputCodeById.valueTypePayComponent.value}: `
                        : ''
                    }}
                  />
                </Typography>
                <Box>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() =>
                      openFlyIn({
                        content: (
                          <AddCalculationRule
                            smartOutputCodeId={params.outputcodeid ?? ''}
                            smartComponentHistoryId={params.smartpaycomponenthistoryid ?? ''}
                            employerId={employerId}
                          />
                        ),
                        callbackAfterClose: () => refetchSmartOutputCodeById
                      })
                    }
                  >
                    <AddIcon />
                    {t('smartoutputcodesettingspage.button.add-rule')}
                  </Button>
                </Box>
              </Box>
              <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
                <TableBody>
                  {getSmartOutputCodeById.smartOutputCodeById.rules.map(r => (
                    <TableRow key={r.id}>
                      <BorderedTableCell>
                        <Select
                          value={r.ruleOperator}
                          disabled
                          fullWidth
                          labelId="operator-select-label"
                          id="operator-select"
                          style={{ borderRadius: 2, maxWidth: '10rem' }}
                        >
                          {calculationRuleOperators?.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </BorderedTableCell>
                      <BorderedTableCell>
                        <Select
                          value={r.function}
                          disabled
                          fullWidth
                          labelId="function-select-label"
                          id="function-select"
                          style={{ borderRadius: 2, maxWidth: '20rem' }}
                        >
                          {calculationRulePartFunctions?.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </BorderedTableCell>
                      <BorderedTableCell>
                        <InputBase
                          id="rule-desciption"
                          minRows={2}
                          disabled
                          value={`${r.description}`}
                          fullWidth
                          aria-label={'rule description'}
                          sx={{ padding: 2 }}
                        />
                      </BorderedTableCell>
                      <BorderedTableCell>
                        <IconButton
                          aria-label="row actions"
                          aria-controls="menu-row"
                          aria-haspopup="true"
                          onClick={e => {
                            e.preventDefault()
                            e.stopPropagation()
                            setClickedRule(r.id)
                            setAnchorEl(e.currentTarget)
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </BorderedTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {clickedRule && (
                <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                  <MenuItem
                    onClick={() =>
                      openFlyIn({
                        content: (
                          <EditCalculationRule
                            smartOutputCodeId={params.outputcodeid}
                            smartComponentHistoryId={params.smartpaycomponenthistoryid}
                            smartCalculationRuleId={clickedRule}
                            employerId={employerId}
                          />
                        ),
                        callbackAfterClose: () => refetchSmartOutputCodeById
                      })
                    }
                  >
                    {t('smartoutputcodesettingspage.list.row.menu.edit-rule')}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      openFlyIn({
                        content: (
                          <RemoveCalculationRule
                            smartOutputCodeId={params.outputcodeid}
                            smartPayComponentHistoryId={params.smartpaycomponenthistoryid}
                            smartCalculationRuleId={clickedRule}
                            refetch={refetchSmartOutputCodeById}
                          />
                        )
                      })
                    }}
                  >
                    {t('smartoutputcodesettingspage.list.row.menu.delete-rule')}
                  </MenuItem>
                  <MenuItem onClick={() => handleOrderDown()}>
                    {t('smartoutputcodesettingspage.list.row.menu.move-rule-up')}
                  </MenuItem>
                  <MenuItem onClick={() => handleOrderUp()}>
                    {t('smartoutputcodesettingspage.list.row.menu.move-rule-down')}
                  </MenuItem>
                </DropdownMenu>
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default SmartOutputCodeSettings
