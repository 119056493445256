import {
  FormSelectOption,
  GetTeamAuthorizationByIdQuery,
  GraphqlError,
  OrderDirection,
  useCreateTeamAuthorizationMutation,
  useSuspenseGetAllFunctionsQuery,
  useSuspenseGetTeamAuthorizationByIdQuery,
  useUpdateTeamAuthorizationMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object, string } from 'zod'
import { FormSearchPerson } from '../../form-components'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddTeamAuthorizationProps {
  teamId: string
}

export interface EditTeamAuthorizationProps extends AddTeamAuthorizationProps {
  teamAuthorizationId: string
}

export function AddTeamAuthorization({ teamId }: AddTeamAuthorizationProps) {
  return AddEditTeamAuthorization(teamId)
}
export function EditTeamAuthorization({ teamId, teamAuthorizationId }: EditTeamAuthorizationProps) {
  const getTeamAuthorizationByIdQuery = useSuspenseGetTeamAuthorizationByIdQuery({
    id: teamAuthorizationId
  })

  return AddEditTeamAuthorization(teamId, getTeamAuthorizationByIdQuery)
}

function AddEditTeamAuthorization(
  teamId: string,
  getTeamAuthorizationByIdQuery?: UseSuspenseQueryResult<GetTeamAuthorizationByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { data: getFunctions } = useSuspenseGetAllFunctionsQuery({
    limit: -1,
    offset: 0,
    sortByProperty: 'Name',
    orderDirection: OrderDirection.Asc
  })

  const getTeamAuthorizationById = getTeamAuthorizationByIdQuery?.data

  const addEditTeamAuthorizationSchema = object({
    roleId: string({
      required_error: t('form.validation.rolerequired'),
      invalid_type_error: t('form.validation.rolerequired')
    }),
    personId: string({
      required_error: t('form.validation.personrequired'),
      invalid_type_error: t('form.validation.personrequired')
    }),
    validForChildTeams: boolean()
  })

  type AddTeamAuthorizationForm = TypeOf<typeof addEditTeamAuthorizationSchema>

  const form = useForm<AddTeamAuthorizationForm>({
    resolver: zodResolver(addEditTeamAuthorizationSchema),
    defaultValues: {
      roleId: getTeamAuthorizationById?.teamAuthorizationById.functionId,
      personId: getTeamAuthorizationById?.teamAuthorizationById.personId,
      validForChildTeams: getTeamAuthorizationById?.teamAuthorizationById.validForChildTeams
    }
  })

  const createMutation = useCreateTeamAuthorizationMutation()
  const updateMutation = useUpdateTeamAuthorizationMutation()

  const handleOnSubmit = async (newTeamAuthorization: AddTeamAuthorizationForm) => {
    if (!getTeamAuthorizationById) {
      await createMutation
        .mutateAsync({
          createTeamAuthorizationCommand: {
            personId: newTeamAuthorization.personId,
            teamId: teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateTeamAuthorizationCommand: {
            id: getTeamAuthorizationById.teamAuthorizationById.id,
            teamId: teamId || '',
            functionId: newTeamAuthorization.roleId,
            validForChildTeams: newTeamAuthorization.validForChildTeams
          }
        })
        .then(() => {
          getTeamAuthorizationByIdQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addteamauthorization.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'roleId'}
          label={`${t('form.field.function')} *`}
          options={getFunctions?.allFunctions.data.map(x => new FormSelectOption(x.id, `${x.description} (${x.code})`))}
        />

        {!getTeamAuthorizationById && (
          <FormSearchPerson name={'personId'} label={`${t('form.searchperson.label')} *`} />
        )}
        <FormSwitch sx={12} name="validForChildTeams" label={t('form.field.validforchildteams')} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
