import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Box, IconButton, Typography } from '@mui/material'
import { PaginationModel } from '../data-table/data-table'

interface PagingNavigationProps {
  hasNext: boolean
  onNextClick: () => void
  hasPrevious: boolean
  onPreviousClick: () => void
  isFetching: boolean
  paginationModel: PaginationModel
  totalCount: number
}

export function PagingNavigation({
  hasNext,
  onNextClick,
  hasPrevious,
  onPreviousClick,
  isFetching,
  paginationModel,
  totalCount
}: PagingNavigationProps) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography fontSize="small">
        {paginationModel.offset + 1} -{' '}
        {Math.min(totalCount,paginationModel.offset + paginationModel.pageSize)}
        {' of '}
        {totalCount}
      </Typography>
      <Box>
        <IconButton
          size="small"
          disabled={isFetching || !hasPrevious}
          onClick={() => !isFetching && hasPrevious && onPreviousClick()}
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          size="small"
          disabled={isFetching || !hasNext}
          onClick={() => !isFetching && hasNext && onNextClick()}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    </Box>
  )
}
