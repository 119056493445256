import {
  formTypeSelectOptions,
  getSearchParamNumber,
  OrderDirection,
  PeopleFilterSearchProperty,
  ROLE,
  useGetPagedPeopleQuery,
  useNavigateWithParams,
  useSuspenseGetAllIcpsQuery,
  useSuspenseIsActivePeopleLimitReachedQuery
} from '@epix-web-apps/core'
import {
  CanView,
  EpixAvatar,
  FeatureForPurchaseIndicator,
  People,
  useFlyIn,
  useGlobalPersistedStore
} from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Button, IconButton, MenuItem, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRowParams } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import { FilterButtonFlyIn } from '../../components/filter-button'
import { IcpCodeBadge } from '../../components/icp-components'
import OnboardingPersonLayout from '../../components/onboarding-components/onboarding-person-layout'
import { AddPerson } from '../../components/people-components'
import { FilterPeopleFlyIn } from '../../components/people-components/filter-people-flyin'
import RemovePersonFlyin from '../../components/people-components/remove-person/remove-person-flyin'
import SettingsButton from '../../components/settings-button'
import { usePeopleFilterStore } from '../../stores/people-page-filter-store'

export interface PeopleFilters {
  searchProperty: PeopleFilterSearchProperty
  searchString: string | null
  employer: string | null
  paygroup: string | null
  showInactive: boolean
  showWithoutContract: boolean
  showValidations: boolean
}

type TableRow = {
  id: string
  firstName: string
  lastName: string
  personNumber: string
  startedOn: string
  countryKeys: string[]
  validations: { errorCount: number; warningCount: number }
}

/* eslint-disable-next-line */
export interface PeoplePageProps {}

export function PeoplePage(props: PeoplePageProps) {
  const navigateWithParams = useNavigateWithParams()
  const { t } = useTranslation()
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const [searchParams, setSearchParams] = useSearchParams()
  const page = getSearchParamNumber('page', searchParams)

  const [paginationModel, setPaginationModel] = useState(new PaginationModel(page))

  useEffect(() => {
    setPaginationModel(prev => new PaginationModel(page, prev.pageSize))
  }, [page])

  const [clickedPersonId, setClickedPersonId] = useState<string | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [tableData, setTableData] = useState<TableRow[]>()
  const { data: isActivePeopleLimitReached } = useSuspenseIsActivePeopleLimitReachedQuery()

  const store = usePeopleFilterStore()

  const filters: PeopleFilters = {
    searchProperty: store.searchProperty,
    searchString: store.searchString,
    employer: store.employer,
    paygroup: store.paygroup,
    showInactive: store.showInactive,
    showWithoutContract: store.showWithoutContract,
    showValidations: store.showValidations
  }

  function updateStore(values: PeopleFilters) {
    store.setSearchProperty(values.searchProperty)
    store.setSearchString(values.searchString)
    store.setEmloyer(values.employer)
    store.setPaygroup(values.paygroup)
    store.setShowInactive(values.showInactive)
    store.setShowWithoutContract(values.showWithoutContract)
    store.setShowValidations(values.showValidations)
    searchParams.set('page', '0')
    setSearchParams(searchParams)
  }

  const isAnyFilterActive =
    (filters.searchString !== null && filters.searchString !== '') ||
    filters.employer !== null ||
    filters.paygroup !== null ||
    filters.showInactive ||
    filters.showWithoutContract ||
    filters.showValidations

  const { icpFilters } = useGlobalPersistedStore()
  const { data, isLoading, refetch } = useGetPagedPeopleQuery({
    icpIds: icpFilters,
    searchProperty: store.searchProperty,
    searchString: store.searchString,
    employerId: store.employer,
    paygroupId: store.paygroup,
    showInactive: store.showInactive,
    showWithoutContract: store.showWithoutContract,
    showValidations: store.showValidations,
    sortByProperty: store.sortByProperty,
    orderDirection: store.sortDirection,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const { employerOptions } = formTypeSelectOptions
  const { data: icps } = useSuspenseGetAllIcpsQuery({
    offset: 0,
    limit: 0,
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (data) {
      const tableData = data.pagedPeople.data.map(row => ({
        id: row.personId,
        firstName: row.firstName,
        lastName: row.lastName,
        personNumber: row.personNumber ?? '',
        startedOn: row.startedOn ? parseISO(row.startedOn).toLocaleDateString() : '',
        countryKeys: row.countryKeys,
        validations: {
          errorCount: row.validationErrorCount,
          warningCount: row.validationWarningCount
        }
      }))
      setTableData(tableData)
    }
  }, [data])

  useEffect(() => {
    store.setSortByProperty(columns.find(c => c.field === 'lastName')?.field ?? '')
    store.setSortDirection(OrderDirection.Asc)
  }, [])

  const rowActions = useCallback((row: TableRow) => {
    return (
      <DataTableCell>
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          size="small"
          onClick={e => {
            e.stopPropagation()
            setAnchorEl(e.currentTarget)
            setClickedPersonId(row.id)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
        <IconButton aria-label="row details" aria-controls="details-row" size="small">
          <KeyboardArrowRightIcon />
        </IconButton>
      </DataTableCell>
    )
  }, [])

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'avatar',
        headerName: '',
        sortable: false,
        flex: 1,
        renderCell: rowParams => <RenderAvatar row={rowParams.row} />
      },
      {
        field: 'firstName',
        headerName: t('peoplepage.datatable.column.firstname'),
        flex: 4
      },
      {
        field: 'lastName',
        headerName: t('peoplepage.datatable.column.lastname'),
        flex: 4
      },
      {
        field: 'personNumber',
        headerName: t('peoplepage.datatable.column.personnumber'),
        flex: 4
      },
      {
        field: 'startedOn',
        headerName: t('peoplepage.datatable.column.startedon'),
        flex: 4
      },
      {
        field: 'contract',
        headerName: t('peoplepage.datatable.column.contracts'),
        sortable: false,
        flex: 2,
        renderCell: rowParams => <ContractRowData row={rowParams.row} />
      },
      {
        field: 'validations',
        sortable: false,
        headerName: t('peoplepage.datatable.column.validations'),
        renderCell: rowParams => <ValidationRowData row={rowParams.row} />
      },
      {
        field: 'rowactions',
        headerName: '',
        sortable: false,
        editable: false,
        renderCell: rowParams => rowActions(rowParams.row)
      }
    ],
    []
  )

  function goToPersonDetailPage(id: string) {
    navigateWithParams(People.PEOPLE_ID_DETAILS(id))
  }

  const filterBar = (
    <Box sx={{ display: 'grid', p: 2 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography m={0} variant="h2">
          {t('peoplepage.title')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <FilterButtonFlyIn
              filled={isAnyFilterActive}
              flyinContent={<FilterPeopleFlyIn filters={store} updateFilters={updateStore} />}
            />
            <CanView roles={[ROLE.ADMIN, ROLE.MANAGELISTS]}>
              <SettingsButton
                menuItems={[
                  {
                    label: t('peoplepage.settings.functionareas'),
                    to: '/configuration/function-areas'
                  },
                  {
                    label: t('peoplepage.settings.functionlevels'),
                    to: '/configuration/function-levels'
                  }
                ]}
              />
            </CanView>
          </Box>

          <CanView roles={[ROLE.ADMIN, ROLE.CREATEPERSON]}>
            <Button
              variant="contained"
              disabled={
                isActivePeopleLimitReached?.isActivePeopleLimitReached ||
                (icps?.icps && icps?.icps.totalCount === 0) ||
                (employerOptions && employerOptions.length === 0)
              }
              onClick={() =>
                openFlyIn({
                  content: <AddPerson />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <AddIcon />
              {t('peoplepage.button.addperson')}
            </Button>
          </CanView>
        </Box>
      </Box>
      {isActivePeopleLimitReached?.isActivePeopleLimitReached && (
        <Box sx={{ justifySelf: 'right', fontStyle: 'italic' }}>
          {t('limits.peoplelimitreached')} <FeatureForPurchaseIndicator />
        </Box>
      )}
    </Box>
  )

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={data?.pagedPeople.totalCount ?? 0}
        sortModel={new SortModel(store.sortByProperty, store.sortDirection)}
        onSortChange={sortModel => {
          store.setSortByProperty(sortModel.field)
          store.setSortDirection(sortModel.orderDirection)
        }}
        paginationModel={paginationModel}
        onPaginationModelChange={v => {
          searchParams.set('page', `${v.page}`)
          setSearchParams(searchParams)
          setPaginationModel(v)
        }}
        onRowClick={(params: GridRowParams<TableRow>) => params.row.id && goToPersonDetailPage(params.row.id)}
        isLoading={isLoading}
        filterBarElement={filterBar}
        emptyStateElement={
          <OnboardingPersonLayout
            onClick={() =>
              openFlyIn({
                content: <AddPerson />,
                callbackAfterClose: () => refetch
              })
            }
            icpsPresent={(icps?.icps && icps.icps.totalCount > 0) ?? false}
            employersPresent={(employerOptions && employerOptions.length > 0) ?? false}
          />
        }
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => clickedPersonId && goToPersonDetailPage(clickedPersonId)}>
          {t('peoplepage.list.row.menu.viewdetails')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <RemovePersonFlyin personId={clickedPersonId ?? ''} closeFlyIn={closeFlyIn} />
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default PeoplePage

function RenderAvatar({ row }: { row: TableRow }) {
  return (
    <DataTableCell sx={{ justifyContent: 'center' }}>
      <EpixAvatar personId={row.id} />
    </DataTableCell>
  )
}

function ContractRowData({ row }: { row: TableRow }) {
  return (
    <DataTableCell sx={{ gap: 1 }}>
      {row.countryKeys.map((icpCode: string, index: number) => (
        <IcpCodeBadge key={index} icpCode={icpCode} />
      ))}
    </DataTableCell>
  )
}

function ValidationRowData({ row }: { row: TableRow }) {
  const theme = useTheme()
  const errors = row.validations.errorCount
  const warnings = row.validations.warningCount

  if (errors === 0 && warnings === 0) {
    return (
      <DataTableCell>
        <Typography color={theme.palette.success.main}>
          <CheckIcon />
        </Typography>
      </DataTableCell>
    )
  } else {
    return (
      <DataTableCell sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
        {warnings > 0 && (
          <Typography color={theme.palette.warning.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WarningAmberOutlinedIcon sx={{ fontSize: 18 }} />
            {warnings}
          </Typography>
        )}
        {errors > 0 && (
          <Typography color={theme.palette.error.light} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ErrorOutlineOutlinedIcon sx={{ fontSize: 18 }} />
            {errors}
          </Typography>
        )}
      </DataTableCell>
    )
  }
}
