import {
  FormSelectOption,
  formTypeSelectOptions,
  OrderDirection,
  useGetAllEmployersByIcpIdQuery,
  useGetEmployerByIdQuery
} from '@epix-web-apps/core'
import { FormDatepicker, FormGridLayout, FormInput, FormSelect } from '@epix-web-apps/ui'
import { Box } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StartStepProps {}

export function Start(props: StartStepProps) {
  const { t } = useTranslation()
  const { icpOptions } = formTypeSelectOptions
  const { resetField, control } = useFormContext()

  const watchedPayrollProviderId = useWatch({
    control,
    name: `payrollProviderId`
  })
  const watchedEmployerId = useWatch({ control, name: `employerId` })

  const { data: getAllEmployersByIcpId } = useGetAllEmployersByIcpIdQuery(
    {
      icpId: watchedPayrollProviderId || '',
      limit: -1,
      offset: 0,
      orderDirection: OrderDirection.Asc,
      sortByProperty: 'companyName'
    },
    {
      enabled: !!watchedPayrollProviderId
    }
  )
  const { data: getEmployerById } = useGetEmployerByIdQuery(
    {
      employerId: watchedEmployerId || ''
    },
    {
      enabled: !!watchedEmployerId
    }
  )

  return (
    <>
      <Box>
        <FormGridLayout>
          <FormSelect
            sx={4}
            name="payrollProviderId"
            label={`${t('form.field.payrollprovider')} *`}
            options={icpOptions}
            disabled={true}
          />
          <FormDatepicker sx={2} name="dueDate" label={`${t('form.field.duedate')} *`} />
        </FormGridLayout>

        <FormGridLayout>
          <FormSelect
            sx={4}
            name="employerId"
            label={`${t('form.field.employer')} *`}
            options={getAllEmployersByIcpId?.employersByIcpId?.data.map(
              x => new FormSelectOption(x.id, `${x.companyName} (${x.number})`)
            )}
            onChange={() => resetField('payGroupId', { defaultValue: null })}
            disabled={true}
          />
          <FormSelect
            sx={4}
            name="payGroupId"
            label={`${t('form.field.paygroup')} *`}
            options={getEmployerById?.employerById?.payGroups.map(
              x => new FormSelectOption(x.id, `${x.name} (${x.code})`)
            )}
            disabled={true}
          />
        </FormGridLayout>

        <FormGridLayout>
          <FormInput sx={4} name="processName" label={`${t('form.field.processname')} *`} />
        </FormGridLayout>
      </Box>

      <FormInput sx={12} name="notes" label={`${t('form.field.notes')}`} multiline />
    </>
  )
}

export default Start
