import {
  DEFAULT_DATE,
  DEFAULT_UNDEFINED_KEY,
  useRequiredParams,
  useSuspenseGetPersonByIdQuery,
  useSuspenseGetPersonCivilStateHistoryQuery
} from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, DetailPageBaseQueryParams, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Box, IconButton, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  AddPersonFamilyRelations,
  EditPersonBankInformation,
  EditPersonCivilState,
  EditPersonFamilyRelations,
  EditPersonIdentification,
  EditPersonOfficialAddress,
  EditPersonResidentialAddress
} from '../../../components/people-components'

/* eslint-disable-next-line */
export interface IndentificationTabProps {}

export function IdentificationTab(props: IndentificationTabProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { openFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const { data: getPersonById, refetch } = useSuspenseGetPersonByIdQuery({ personId: params.id })
  const person = getPersonById?.personById

  const { data: getPersonCivilStates, refetch: refetchCivilStates } = useSuspenseGetPersonCivilStateHistoryQuery({
    personId: params.id || ''
  })

  const currentCivilState = getPersonCivilStates?.personCivilStateHistory.current

  function getPartnerDisabledText() {
    if (!currentCivilState?.disabled) return ''

    if (currentCivilState.disabledPercentage)
      return `${currentCivilState?.disabledPercentage}% ${t('flyin.editperson.familyrelations.label.disabled')}`
    return t('flyin.editperson.familyrelations.label.disabled')
  }

  const genderDependentDisabledParagraph = useMemo(
    () =>
      [
        currentCivilState?.genderType?.value,
        currentCivilState?.dependent && t('flyin.editperson.familyrelations.label.dependent'),
        getPartnerDisabledText()
      ]
        .filter(Boolean)
        .join(', '),
    [getPersonCivilStates]
  )

  const civilStateBirthPlaceDetails = useMemo(
    () => [currentCivilState?.cityOfBirth, currentCivilState?.countryOfBirth?.name].filter(Boolean).join(', '),
    [getPersonCivilStates]
  )

  const civilStateContactDetails = useMemo(
    () =>
      [currentCivilState?.phoneNumber, currentCivilState?.mobilePhoneNumber, currentCivilState?.emailAddress]
        .filter(Boolean)
        .join(' | '),
    [getPersonCivilStates]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('peopledetailpage.identification.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonIdentification />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {person?.titleType?.key !== DEFAULT_UNDEFINED_KEY && person?.titleType?.value}{' '}
              <span className="bold">{person?.firstName}</span>
              <span> {person?.otherNames} </span>
              <span className="bold">{person?.preferredLastName}</span>
            </Typography>
            <Typography className="bold">{person?.genderType?.value}</Typography>
          </DetailBoxRow>
          {(person?.birthDate || person?.placeOfBirth || person?.countryOfBirth || person?.preferredLanguage) && (
            <DetailBoxRow>
              <Typography>
                <span className="bold">
                  {person?.birthDate && person?.birthDate !== DEFAULT_DATE
                    ? `°${parseISO(person.birthDate).toLocaleDateString()}`
                    : ''}
                </span>
                {person?.birthDate && person?.placeOfBirth && ','} {person?.placeOfBirth}
                {person?.countryOfBirth?.name && ','} {person?.countryOfBirth?.name}
              </Typography>
              <Typography className="bold">{person?.preferredLanguage?.language}</Typography>
            </DetailBoxRow>
          )}
          {(person?.nationality || person?.nationalSocialSecurityNumber) && (
            <DetailBoxRow>
              <Typography>
                {person?.nationality && (
                  <Trans
                    i18nKey="peopledetailpage.nationality"
                    values={{ nationality: person?.nationality.name }}
                    components={{ bold: <span className="bold" /> }}
                  />
                )}{' '}
                {person?.nationalSocialSecurityNumber && (
                  <Trans
                    i18nKey="peopledetailpage.nationalid"
                    values={{
                      nationalSocialSecurityNumber: person?.nationalSocialSecurityNumber
                    }}
                    components={{ bold: <span className="bold" /> }}
                  />
                )}
              </Typography>
            </DetailBoxRow>
          )}
          {person?.disabled && (
            <DetailBoxRow>
              <Typography className="bold">
                <span>{person.disabledPercentage && person.disabledPercentage + '% '}</span>
                <span>{t('form.field.disabled')}</span>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>

        <DetailBox
          title={t('peopledetailpage.civilstate.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonCivilState />,
                  callbackAfterClose: () => () => {
                    refetch()
                    refetchCivilStates()
                  }
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {getPersonCivilStates?.personCivilStateHistory?.current && (
            <>
              <DetailBoxRow>
                <Typography>
                  {person?.firstName && currentCivilState?.civilState && (
                    <Trans
                      i18nKey="peopledetailpage.civilstate.is"
                      values={{
                        firstName: person?.firstName,
                        civilState: currentCivilState?.civilState?.value
                      }}
                      components={{ bold: <span className="bold" /> }}
                    />
                  )}

                  {currentCivilState?.icpSubCivilStates && currentCivilState?.icpSubCivilStates?.length > 0 && (
                    <span>
                      (
                      {currentCivilState.icpSubCivilStates.map((x, index) => (
                        <span key={index}>
                          {x.name}
                          {index < currentCivilState.icpSubCivilStates.length - 1 && ', '}
                        </span>
                      ))}
                      ){' '}
                    </span>
                  )}

                  {(currentCivilState?.firstName || currentCivilState?.lastName) && (
                    <>
                      <Trans i18nKey="peopledetailpage.civilstate.with" />
                      <span className="bold"> {currentCivilState?.firstName} </span> {currentCivilState?.otherNames}{' '}
                      <span className="bold">{currentCivilState?.lastNamePrefix} </span>{' '}
                      <span className="bold"> {currentCivilState?.lastName} </span>
                      {(currentCivilState?.dependent || currentCivilState?.disabled) && (
                        <span>{`(${genderDependentDisabledParagraph}) `}</span>
                      )}
                    </>
                  )}
                  {currentCivilState?.startDate && (
                    <Trans
                      i18nKey="peopledetailpage.civilstate.since"
                      values={{
                        startDate:
                          currentCivilState?.startDate && parseISO(currentCivilState?.startDate).toLocaleDateString()
                      }}
                      components={{ bold: <span className="bold" /> }}
                    />
                  )}
                </Typography>
              </DetailBoxRow>

              {(currentCivilState?.cityOfBirth ||
                currentCivilState?.countryOfBirth ||
                currentCivilState?.dateOfBirth) && (
                <DetailBoxRow>
                  <Typography>
                    {currentCivilState?.dateOfBirth && (
                      <strong>{`°${parseISO(currentCivilState?.dateOfBirth).toLocaleDateString()}`}</strong>
                    )}
                    {currentCivilState?.dateOfBirth &&
                      (currentCivilState?.cityOfBirth || currentCivilState?.countryOfBirth) && <>, </>}
                    {civilStateBirthPlaceDetails}
                  </Typography>
                </DetailBoxRow>
              )}

              {(currentCivilState?.nationality || currentCivilState?.nationalIdNumber) && (
                <DetailBoxRow>
                  <Typography>
                    {currentCivilState.nationality && (
                      <>
                        <strong>{currentCivilState?.nationality?.name}</strong>
                        {` ${t('peopledetailpage.civilstate.nationality')}`}
                      </>
                    )}
                    {currentCivilState?.nationality &&
                      currentCivilState?.nationalIdNumber &&
                      ` ${t('peopledetailpage.civilstate.with')} `}

                    {currentCivilState?.nationalIdNumber && (
                      <>
                        {`${t('peopledetailpage.civilstate.nationalidnumber')}: `}
                        <strong>{` ${currentCivilState?.nationalIdNumber} `}</strong>
                      </>
                    )}
                  </Typography>
                </DetailBoxRow>
              )}

              {(currentCivilState?.phoneNumber ||
                currentCivilState?.mobilePhoneNumber ||
                currentCivilState?.emailAddress) && (
                <DetailBoxRow>
                  <Typography>
                    {`${t('peopledetailpage.civilstate.contactdetails')}: `}
                    {civilStateContactDetails}
                  </Typography>
                </DetailBoxRow>
              )}
            </>
          )}
        </DetailBox>

        <DetailBox
          title={t('peopledetailpage.familyrelations.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <AddPersonFamilyRelations />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <PersonAddIcon />
            </IconButton>
          }
        >
          {person?.familyMembers.map((familyMember, index) => (
            <DetailBoxRow key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography>
                  <span className="bold">{familyMember?.firstName}</span> {familyMember?.otherNames}{' '}
                  <span className="bold">
                    {familyMember?.lastNamePrefix}
                    {familyMember?.lastNamePrefix && ' '}
                    {familyMember?.lastName}{' '}
                  </span>
                  ({familyMember?.dateOfBirth && `°${parseISO(familyMember?.dateOfBirth).toLocaleDateString()}`}
                  {familyMember?.dateOfBirth &&
                    (familyMember.relationType ||
                      familyMember.currentState?.dependent ||
                      familyMember.currentState?.disabled) &&
                    ', '}
                  {familyMember.relationType && familyMember.relationType.value}
                  {familyMember.relationType &&
                    (familyMember.currentState?.dependent || familyMember.currentState?.disabled) &&
                    ', '}
                  {familyMember?.currentState?.dependent && t('flyin.editperson.familyrelations.label.dependent')}
                  {familyMember.currentState?.dependent && familyMember.currentState?.disabled && ', '}
                  {familyMember.currentState?.disabledPercentage &&
                    familyMember.currentState?.disabledPercentage + '% '}
                  {familyMember.currentState?.disabled && t('flyin.editperson.familyrelations.label.disabled')})
                </Typography>
              </Box>
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditPersonFamilyRelations familyMemberId={familyMember.id} />,
                    callbackAfterClose: () => refetch
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </DetailBoxRow>
          ))}
        </DetailBox>
      </Box>

      <Box sx={{ flex: 1 }}>
        <DetailBox title={t('peopledetailpage.address.title')}>
          <DetailBoxRow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <HomeOutlinedIcon sx={{ color: 'inherit !important' }} />
              <Typography>
                <span>
                  {person?.currentOfficialAddress?.street} {person?.currentOfficialAddress?.houseNumber}
                  {person?.currentOfficialAddress?.houseNumberSuffix}
                </span>
                {person?.currentOfficialAddress?.street &&
                  (person?.currentOfficialAddress?.city ||
                    person?.currentOfficialAddress?.county ||
                    person?.currentOfficialAddress?.country) &&
                  ', '}
                {person?.currentOfficialAddress?.city}
                {person?.currentOfficialAddress?.zipCode && ' (' + person?.currentOfficialAddress?.zipCode + ')'}
                {(person?.currentOfficialAddress?.city || person?.currentOfficialAddress?.zipCode) &&
                  (person?.currentOfficialAddress?.county || person?.currentOfficialAddress?.country) &&
                  ', '}
                {person?.currentOfficialAddress?.county}
                {person?.currentOfficialAddress?.county && person?.currentOfficialAddress?.country && ', '}
                {person?.currentOfficialAddress?.country?.name}
              </Typography>
            </Box>

            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonOfficialAddress />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </DetailBoxRow>

          <DetailBoxRow>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationOnOutlinedIcon sx={{ color: 'inherit !important' }} />
              <Typography>
                <span>
                  {person?.currentResidentialAddress?.street} {person?.currentResidentialAddress?.houseNumber}
                  {person?.currentResidentialAddress?.houseNumberSuffix}
                </span>
                {person?.currentResidentialAddress?.street &&
                  (person?.currentResidentialAddress?.city ||
                    person?.currentResidentialAddress?.county ||
                    person?.currentResidentialAddress?.country) &&
                  ', '}
                {person?.currentResidentialAddress?.city}
                {person?.currentResidentialAddress?.zipCode && ' (' + person?.currentResidentialAddress?.zipCode + ')'}
                {(person?.currentResidentialAddress?.city || person?.currentResidentialAddress?.zipCode) &&
                  (person?.currentResidentialAddress?.county || person?.currentResidentialAddress?.country) &&
                  ', '}
                {person?.currentResidentialAddress?.county}
                {person?.currentResidentialAddress?.county && person?.currentResidentialAddress?.country && ', '}
                {person?.currentResidentialAddress?.country?.name}
              </Typography>
            </Box>
            <IconButton
              size="small"
              color="primary"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonResidentialAddress />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </DetailBoxRow>
        </DetailBox>

        <DetailBox
          title={t('peopledetailpage.bankinfo.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPersonBankInformation />,
                  callbackAfterClose: () => refetch
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {person?.accountHolder && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.accountholder')}: {person.accountHolder}
              </Typography>
            </DetailBoxRow>
          )}
          {person?.bankAccount && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.bankaccount')}: {person.bankAccount}
              </Typography>
            </DetailBoxRow>
          )}
          {person?.iban && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.iban')}: {person.iban}{' '}
              </Typography>
            </DetailBoxRow>
          )}
          {person?.bic && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.bic')}: {person.bic}
              </Typography>
            </DetailBoxRow>
          )}
          {person?.bankSortCode && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.sortcode')}: {person.bankSortCode}
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default IdentificationTab
