import { DATE_INPUT_FORMAT } from '@epix-web-apps/ui'
import { InputProps, TextFieldVariants } from '@mui/material'
import { DatePicker, DatePickerProps, PickersActionBarAction } from '@mui/x-date-pickers'
import { parseISO } from 'date-fns'

/* eslint-disable-next-line */
export interface EpixDatePickerProps<TDate extends Date> extends Omit<DatePickerProps<TDate>, 'onChange'> {
  onChange?: (value: TDate | null) => void
  format?: string
  actionBarActions?: PickersActionBarAction[]
  variant?: TextFieldVariants
  fullWidth?: boolean
  disableUnderline?: boolean
  textFieldInputProps?: Partial<InputProps>
}

export function EpixDatePicker(props: EpixDatePickerProps<Date>) {
  const { onChange, format } = props
  return (
    <DatePicker
      {...props}
      onChange={(value: Date | null) => {
        if (value !== null && !isNaN(value?.getTime()) && value?.getFullYear() < 1900) {
          return
        }
        onChange && onChange(value)
      }}
      minDate={parseISO('1900-01-01')}
      format={format ? format : DATE_INPUT_FORMAT}
      slotProps={{
        textField: {
          size: 'small',
          variant: props.variant,
          InputProps: props.textFieldInputProps,
          fullWidth: props.fullWidth
        },
        actionBar: {
          actions: props.actionBarActions
        }
      }}
    />
  )
}

export default EpixDatePicker
