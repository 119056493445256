import {
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useSuspenseGetCegedimContractDetailByContractIdQuery,
  useUpdateCegedimContractTypeMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface EditCegedimContractTypeProps {
  contractId: string
}

export function EditCegedimContractType({ contractId }: EditCegedimContractTypeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: cegedimContractDetail, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetCegedimContractDetailByContractIdQuery({
      contractId: contractId
    })

  const { data: contractStartReasonTypesData } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.FR_CONTRACT_START_REASON.key,
    limit: -1,
    offset: 0
  })
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const updateMutation = useUpdateCegedimContractTypeMutation()

  const contractStartReasonTypes =
    contractStartReasonTypesData?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
      x => new FormSelectOption(x.id, x.value)
    )

  const editCegedimContractTypeSchema = object({
    contractStartReasonTypeId: string().nullable().optional()
  })

  type EditCegedimContractTypeForm = TypeOf<typeof editCegedimContractTypeSchema>

  const form = useForm<EditCegedimContractTypeForm>({
    resolver: zodResolver(editCegedimContractTypeSchema),
    defaultValues: {
      contractStartReasonTypeId: cegedimContractDetail?.cegedimContractDetailByContractId.contractStartReasonType?.id
    }
  })

  const handleOnSubmit = async (cegedimContractType: EditCegedimContractTypeForm) => {
    await updateMutation
      .mutateAsync({
        updateCegedimContractTypeCommand: {
          id: cegedimContractDetail?.cegedimContractDetailByContractId.id || '',
          contractStartReasonTypeId: cegedimContractType.contractStartReasonTypeId
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcegedimcontracttype.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="contractStartReasonTypeId"
          label={t('form.field.contractstartreason')}
          options={contractStartReasonTypes}
        />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={updateMutation.isPending} />
    </FormContainer>
  )
}

export default EditCegedimContractType
