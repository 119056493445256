import {
  GetFunctionAreaTypeByKeyQuery,
  OrderDirection,
  useCreateFunctionAreaTypeMutation,
  useGetAllFunctionAreaTypesQuery,
  useSuspenseGetFunctionAreaTypeByKeyQuery,
  useUpdateFunctionAreaTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface EditFunctionAreaProps {
  functionAreaKey: string
}

export function AddFunctionArea() {
  return AddEditFunctionArea()
}

export function EditFunctionArea({ functionAreaKey }: EditFunctionAreaProps) {
  const getFunctionAreasByKeyQuery = useSuspenseGetFunctionAreaTypeByKeyQuery({
    key: functionAreaKey
  })

  return AddEditFunctionArea(getFunctionAreasByKeyQuery)
}

function AddEditFunctionArea(
  getFunctionAreasByKeyQuery?: UseSuspenseQueryResult<GetFunctionAreaTypeByKeyQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditFunctionAreaSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    }).min(1, t('form.validation.keyrequired')),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    active: boolean()
  })

  type CreateEditFunctionAreaForm = TypeOf<typeof addEditFunctionAreaSchema>

  const { data: functionAreasData } = useGetAllFunctionAreaTypesQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'key',
    orderDirection: OrderDirection.Asc
  })

  const getFunctionAreasByKey = getFunctionAreasByKeyQuery?.data

  const form = useForm<CreateEditFunctionAreaForm>({
    resolver: zodResolver(addEditFunctionAreaSchema),
    defaultValues: {
      key: getFunctionAreasByKey?.functionAreaTypeByKey?.key,
      value: getFunctionAreasByKey?.functionAreaTypeByKey?.value,
      active: getFunctionAreasByKey?.functionAreaTypeByKey?.active ?? true
    }
  })

  const createMutation = useCreateFunctionAreaTypeMutation()
  const updateMutation = useUpdateFunctionAreaTypeMutation()

  const handleOnSubmit = async (newFunctionArea: CreateEditFunctionAreaForm) => {
    if (
      !getFunctionAreasByKeyQuery &&
      newFunctionArea.key !==
        functionAreasData?.functionAreaTypes.data.find(f => f.key.includes(newFunctionArea.key))?.key
    ) {
      await createMutation
        .mutateAsync({
          createFunctionAreaTypeCommand: {
            key: newFunctionArea.key,
            value: newFunctionArea.value
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateFunctionAreaTypeCommand: {
            key: newFunctionArea.key,
            value: newFunctionArea.value,
            active: newFunctionArea.active
          }
        })
        .then(() => {
          getFunctionAreasByKeyQuery?.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditfunctionarea.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!getFunctionAreasByKeyQuery} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        {getFunctionAreasByKeyQuery && <FormSwitch sx={12} name="active" label={t('form.field.active')} />}
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditFunctionArea
