import { DocumentModel } from '@epix-web-apps/core'
import { Box, Grid } from '@mui/material'
import Document from '../document/document'

/* eslint-disable-next-line */
export interface DocumentListProps {
  documents: DocumentModel[]
}

export function DocumentList({ documents }: DocumentListProps) {
  return (
    <Box>
      {documents.map(document => (
        <Box key={document?.id}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              {<Document document={document} />}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  )
}

export default DocumentList
