import { OrderDirection, useGetPagedPeopleQuery } from '@epix-web-apps/core'
import { EpixAvatar, People } from '@epix-web-apps/ui'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Person {
  id: string
  name: string
}
/* eslint-disable-next-line */
export interface SearchPersonProps {}

export function SearchPerson(props: SearchPersonProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [inputValue, setInputValue] = useState<Person | null>(null)
  const [searchString, setSearchString] = useState<string>('')
  const [options, setOptions] = React.useState<readonly Person[]>([])

  const { data, isLoading, isFetching } = useGetPagedPeopleQuery(
    {
      searchString: searchString,
      showInactive: false,
      showWithoutContract: false,
      showValidations: false,
      orderDirection: OrderDirection.Asc,
      offset: 0,
      limit: 20
    },
    {
      enabled: searchString.length > 2
    }
  )

  useEffect(() => {
    if (data?.pagedPeople) {
      setOptions(
        data.pagedPeople.data.map(x => ({
          id: x.personId,
          name: x.firstName + ' ' + x.lastName
        }))
      )
    } else {
      setOptions([])
    }
  }, [data?.pagedPeople])

  return (
    <Autocomplete
      id="search-person"
      sx={{ width: 300, maxBlockSize: '2', background: 'white' }}
      getOptionLabel={option => option.name}
      filterOptions={x => x}
      options={options}
      value={inputValue}
      loading={isLoading || isFetching}
      noOptionsText={t('usermenu.nosearchresults')}
      onInputChange={(e, newInputValue) => setSearchString(newInputValue)}
      onChange={(e, value) => {
        setInputValue(null)
        setSearchString('')
        setOptions([])
        value?.id && navigate(People.PEOPLE_ID_DETAILS(`${value?.id}`))
      }}
      popupIcon={false}
      renderInput={params => (
        <TextField
          {...params}
          label={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <SearchIcon />
              <p style={{ padding: 0, margin: 0 }}>{t('usermenu.searchperson')} ...</p>
            </Box>
          }
          fullWidth
          size="small"
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <EpixAvatar personId={option.id} size={30} />
              <Typography color={theme.palette.text.secondary} fontWeight={'medium'}>
                {option.name}
              </Typography>
            </Box>
          </li>
        )
      }}
    />
  )
}

export default SearchPerson
