import { PARTNER_LIST_IDENTIFIERS, ROLE, useSuspenseGetPhocusContractDetailsQuery } from '@epix-web-apps/core'
import { CanView, Configuration, DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, IconButton, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EditPhocusContractType } from './edit-phocus-contract-type'
import { EditPhocusEmployeeTemplate } from './edit-phocus-employee-template'
import { EditPhocusEmployment } from './edit-phocus-employment'
import { EditPhocusTaxes } from './edit-phocus-taxes'

/* eslint-disable-next-line */
export interface NlPhocusProps {
  contractId: string
}

export function NlPhocus({ contractId }: NlPhocusProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()

  const { data: partnerSpecificDetails } = useSuspenseGetPhocusContractDetailsQuery({
    contractId: contractId
  })

  const partnerTaxes = partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel
  const partnerContractType =
    partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailContractTypeModel
  const partnerEmployment = partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailEmploymentModel
  const partnerEmployeeTemplate =
    partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailEmployeeTemplateModel

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('phocusdetailpage.tax.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPhocusTaxes contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {partnerTaxes?.wageTaxCredit ? t('form.field.wagetaxcreditapply') : t('form.field.wagetaxcreditnotapply')}
            </Typography>
          </DetailBoxRow>

          {partnerTaxes?.annualWageSpecialRate != null && (
            <DetailBoxRow>
              <Typography>
                {t('form.field.annualwagespecialrate')}: <strong>{partnerTaxes.annualWageSpecialRate}</strong>
              </Typography>
            </DetailBoxRow>
          )}

          {partnerTaxes?.annualWage != null && (
            <DetailBoxRow>
              <Typography>
                {t('phocusdetailpage.tax.annualwage')}: <strong>{partnerTaxes.annualWage}</strong>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>

        <DetailBox
          title={t('phocusdetailpage.contracttype.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPhocusContractType contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {partnerContractType?.writtenContract
                ? t('form.field.writtencontract')
                : t('form.field.nowrittencontract')}
            </Typography>
          </DetailBoxRow>
        </DetailBox>
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('phocusdetailpage.employment.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditPhocusEmployment contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {partnerEmployment?.isEmploymentTerminated
                ? t('form.field.employmentterminated')
                : t('form.field.employmentnotterminated')}
            </Typography>
          </DetailBoxRow>
        </DetailBox>
        <DetailBox
          title={t('phocusdetailpage.employeetemplate.title')}
          actionIcon={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <IconButton
                size="small"
                onClick={() =>
                  openFlyIn({
                    content: <EditPhocusEmployeeTemplate contractId={contractId} />
                  })
                }
              >
                <EditIcon />
              </IconButton>
              <CanView roles={[ROLE.ADMIN, ROLE.MANAGELISTS]}>
                <IconButton
                  onClick={() =>
                    navigate(
                      Configuration.PARTNER_CONTRACT_DETAIL_TYPES(PARTNER_LIST_IDENTIFIERS.NL_EMPLOYEE_TEMPLATE.key)
                    )
                  }
                >
                  <SettingsIcon />
                </IconButton>
              </CanView>
            </Box>
          }
        >
          <DetailBoxRow>
            <Typography>
              {t('phocusdetailpage.employeetemplate.employeetemplatetype')}:
              <strong> {partnerEmployeeTemplate?.employeeTemplateType?.value}</strong>
            </Typography>
          </DetailBoxRow>
        </DetailBox>
      </Box>
    </Box>
  )
}

export default NlPhocus
