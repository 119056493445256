import {
  IsDateBeforeToday,
  OrderDirection,
  PROCESS_TYPE,
  ToBackendFormatedDate,
  useGetPagedProcessesQuery
} from '@epix-web-apps/core'
import { Process, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import ClearIcon from '@mui/icons-material/Clear'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import WarningIcon from '@mui/icons-material/Warning'
import { Box, Button, Grid, IconButton, LinearProgress, MenuItem, Switch, Typography, useTheme } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'
import FilterButton from '../../components/filter-button/filter-button'
import { IcpCodeBadge } from '../../components/icp-components'
import CancelProcessFlyin from '../../components/process-components/cancel-process-flyin/cancel-process-flyin'
import { AddPayComponentUpdate } from '../../components/process-components/paycomponent-update/add-paycomponent-update'
import { AddPayrollClosure } from '../../components/process-components/payroll-closure/add-payroll-closure'

type TableData = {
  id: string
  processTypeKey: string
  country: string
  processType: string
  processName: string
  lastmodified: string
  duedate: Date
  progress: number
  completed: boolean
  cancelled: boolean
  hasErrors: boolean
}

/* eslint-disable-next-line */
export interface ProcessPageProps {}

export function ProcessPage(props: ProcessPageProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const [filters, setFilters] = useState({
    showDueDateClose: false,
    showCompletedProcesses: false,
    showCancelledProcesses: false
  })
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [rowCount, setRowCount] = useState(0)
  const [rowAnchorEl, setRowAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedProcessId, setClickedProcessId] = useState<GridRowId>()

  const { icpFilters } = useGlobalPersistedStore()

  const rowActions = (rowParams: GridRenderCellParams) => (
    <DataTableCell>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setClickedProcessId(rowParams.id)
          setRowAnchorEl(e.currentTarget)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row">
        <KeyboardArrowRightIcon />
      </IconButton>
    </DataTableCell>
  )

  function DueDateTableCell({ row }: { row: TableData }) {
    const formatedDueDate = row.duedate.toLocaleDateString()
    if (!row.completed && !row.cancelled && IsDateBeforeToday(row.duedate)) {
      return (
        <DataTableCell>
          <Typography sx={{ color: theme.palette.error.light }}>
            <WarningIcon fontSize="small" sx={{ marginLeft: -2.75, position: 'absolute' }} />
            {formatedDueDate}
          </Typography>
        </DataTableCell>
      )
    }
    return (
      <DataTableCell>
        <Typography>{formatedDueDate}</Typography>
      </DataTableCell>
    )
  }

  const columns: GridColDef[] = [
    {
      field: 'country',
      sortable: false,
      editable: false,
      headerName: t('processpage.datatable.column.country'),
      renderCell: params => <CountryTableCell row={params.row} />
    },
    {
      field: 'processType',
      sortable: false,
      headerName: t('processpage.datatable.column.processtype'),
      width: 200
    },
    {
      field: 'processName',
      sortable: false,
      headerName: t('processpage.datatable.column.processname'),
      flex: 1
    },
    {
      field: 'lastmodified',
      sortable: false,
      headerName: t('processpage.datatable.column.lastmodified'),
      width: 200
    },
    {
      field: 'duedate',
      sortable: true,
      headerName: t('processpage.datatable.column.duedate'),
      renderCell: DueDateTableCell,
      width: 200
    },
    {
      field: 'progress',
      sortable: false,
      headerName: t('processpage.datatable.column.progress'),
      renderCell: params => <ProgressBarTableCell row={params.row} />,
      width: 300
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [sortModel, setSortModel] = useState(new SortModel(columns?.[5]?.field, OrderDirection.Desc))
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [tableData, setTableData] = useState<GridRowsProp>()

  useEffect(() => {
    setPaginationModel(new PaginationModel(0))
  }, [icpFilters, filters])

  const today = new Date()
  const { data, isLoading, refetch } = useGetPagedProcessesQuery({
    icps: icpFilters,
    includeCompleted: filters.showCompletedProcesses,
    includeCancelled: filters.showCancelledProcesses,
    startDate: filters.showDueDateClose ? ToBackendFormatedDate(today) : null,
    endDate: filters.showDueDateClose
      ? ToBackendFormatedDate(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()))
      : null,
    offset: paginationModel.offset,
    limit: paginationModel.pageSize,
    sortByProperty: sortModel.field,
    orderDirection: sortModel.orderDirection
  })

  useEffect(() => {
    if (data && data?.pagedProcesses) {
      const processesData = data.pagedProcesses
      setTableData(
        processesData.data.map(row => {
          return {
            id: row.id,
            processTypeKey: row.type.key,
            country: row.countryKey,
            processType: row.type.value,
            processName: row.name,
            lastmodified: parseISO(row.lastChangedOn).toLocaleDateString(),
            duedate: parseISO(row.dueDate),
            progress: (row.currentStep / row.totalSteps) * 100,
            completed: row.completed,
            cancelled: row.cancelled,
            hasErrors: row.hasErrors
          }
        })
      )
      setRowCount(processesData.totalCount)
    }
  }, [data])

  const goToProcessDetailPage = (processType: PROCESS_TYPE, processId?: GridRowId) => {
    if (processId) {
      navigate(Process.PROCESSES_TYPE_ID(processType, `${[processId]}`))
    } else {
      navigate(Process.PROCESSES_TYPE(processType))
    }
  }

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Typography m={0} variant="h2">
        {t('processpage.title')}
      </Typography>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FilterButton filters={filters}>
          <Typography>
            <Switch
              checked={filters.showDueDateClose}
              onChange={e => setFilters({ ...filters, showDueDateClose: e.target.checked })}
              aria-label={t('processpage.filters.showduedatewithinonemonth')}
            />
            {t('processpage.filters.showduedatewithinonemonth')}
          </Typography>
          <Typography>
            <Switch
              checked={filters.showCompletedProcesses}
              onChange={e =>
                setFilters({
                  ...filters,
                  showCompletedProcesses: e.target.checked
                })
              }
              aria-label={t('processpage.filters.showcompleteprocesses')}
            />
            {t('processpage.filters.showcompleteprocesses')}
          </Typography>
          <Typography>
            <Switch
              checked={filters.showCancelledProcesses}
              onChange={e =>
                setFilters({
                  ...filters,
                  showCancelledProcesses: e.target.checked
                })
              }
              aria-label={t('processpage.filters.showcancelledprocesses')}
            />
            {t('processpage.filters.showcancelledprocesses')}
          </Typography>
        </FilterButton>
        <Button variant="contained" onClick={e => setAnchorEl(e.currentTarget)}>
          <AddIcon />
          {t('processpage.button.newprocess')}
        </Button>
      </Box>
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddPayrollClosure />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.button.newpayrollclosure')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <AddPayComponentUpdate />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.button.newpaycomponentupdate')}
        </MenuItem>
      </DropdownMenu>
    </Box>
  )

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        onSortChange={setSortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onRowClick={params =>
          goToProcessDetailPage(PROCESS_TYPE[params.row.processTypeKey as keyof typeof PROCESS_TYPE], params.id)
        }
        isLoading={isLoading}
        sortModel={sortModel}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={rowAnchorEl} onClose={() => setRowAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: <CancelProcessFlyin processId={clickedProcessId} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('processpage.datatable.row.editprocess')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default ProcessPage

function CountryTableCell({ row }: { row: TableData }) {
  return (
    <DataTableCell>
      <IcpCodeBadge icpCode={row.country} />
    </DataTableCell>
  )
}

function ProgressBarTableCell({ row }: { row: TableData }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (row.cancelled) {
    return (
      <DataTableCell sx={{ gap: 1, color: theme.palette.error.light }}>
        <CancelOutlinedIcon />
        <Typography>{t('processpage.progress.cancelled')}</Typography>
      </DataTableCell>
    )
  }
  if (row.hasErrors) {
    return (
      <DataTableCell sx={{ gap: 1, color: theme.palette.error.light }}>
        <ClearIcon />
        <Typography>{t('processpage.progress.failed')}</Typography>
      </DataTableCell>
    )
  }
  if (row.completed) {
    return (
      <DataTableCell sx={{ gap: 1, color: theme.palette.success.light }}>
        <CheckOutlinedIcon />
        <Typography>{t('processpage.progress.completed')}</Typography>
      </DataTableCell>
    )
  }
  return (
    <DataTableCell>
      <Grid xs item sx={{ display: 'block' }}>
        <LinearProgress variant="determinate" value={row.progress} />
      </Grid>
    </DataTableCell>
  )
}
