import {
  DateRange,
  FormSelectOption,
  isValidPayPeriodDate,
  isValidPayPeriodEndDate,
  OrderDirection,
  useCreateContractSmartPaycomponentHistoryMutation,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery,
  WithoutTime
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, string, TypeOf } from 'zod'

interface AddContractSmartPayComponentHistoryProps {
  contractId: string
  employerId: string
  period: DateRange
}

function AddContractSmartPayComponentHistory({
  contractId,
  employerId,
  period,
}: AddContractSmartPayComponentHistoryProps) {
  const { t } = useTranslation()
  const [smartPayComponentOptions, setSmartPayComponentOptions] = useState<FormSelectOption[]>([])

  const addContractSmartPayComponentHistory = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    smartPayComponent: string()
  }).refine(data => (data.endDate ? WithoutTime(data.endDate) >= WithoutTime(data.startDate) : true), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type CreateContractSmartPayComponentHistoryForm = TypeOf<typeof addContractSmartPayComponentHistory>

  const { data: getPagedSmartPayComponentsByEmployerId } = useSuspenseGetPagedSmartPayComponentsByEmployerIdQuery({
    employerId: employerId,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  useEffect(() => {
    if (getPagedSmartPayComponentsByEmployerId) {
      setSmartPayComponentOptions(
        getPagedSmartPayComponentsByEmployerId.pagedSmartPayComponentsByEmployerId.data.map(
          s => new FormSelectOption(s.id, `${s.description} (${s.code})`)
        )
      )
    }
  }, [getPagedSmartPayComponentsByEmployerId])

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateContractSmartPayComponentHistoryForm>({
    resolver: zodResolver(addContractSmartPayComponentHistory),
    defaultValues: {
      startDate: period.startDate,
      endDate: period.endDate
    }
  })

  const createMutation = useCreateContractSmartPaycomponentHistoryMutation()

  const handleOnSubmit = (newContractSmartPayComponentHistoryForm: CreateContractSmartPayComponentHistoryForm) => {
    createMutation
      .mutateAsync({
        command: {
          contractId: contractId,
          smartPayComponentId: newContractSmartPayComponentHistoryForm.smartPayComponent,
          startDate: newContractSmartPayComponentHistoryForm.startDate,
          endDate: newContractSmartPayComponentHistoryForm.endDate
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addcontractsmartpaycomponenthistory.title')}</Typography>
      <FormGridLayout>
        <FormDatepicker
          sx={12}
          name="startDate"
          label={`${t('form.field.startdate')} *`}
          openTo={'day'}
          views={['year', 'month', 'day']}
          shouldDisableDate={e => isValidPayPeriodDate(getContractById.contractById.payGroup ?? null, e)}
        />
        <FormDatepicker
          sx={12}
          name="endDate"
          label={`${t('form.field.enddate')}`}
          openTo={'day'}
          views={['year', 'month', 'day']}
          shouldDisableDate={e => isValidPayPeriodEndDate(getContractById.contractById.payGroup ?? null, e)}
        />
        <FormSelect
          sx={12}
          name="smartPayComponent"
          label={`${t('form.field.smartpaycomponent')} *`}
          options={smartPayComponentOptions}
        />
      </FormGridLayout>
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddContractSmartPayComponentHistory
