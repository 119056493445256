import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Button, Drawer, DrawerProps, IconButton, useTheme } from '@mui/material'
import { memo, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingOverlay from '../loading-overlay/loading-overlay'
import { useFlyIn } from './fly-in-context'

/* eslint-disable-next-line */
export interface FlyInProps extends DrawerProps {}

const StyledContentWrapper = styled.div`
  padding: 0 1rem;
  height: 100%;

  @media (max-width: 1024px) {
    min-width: 100vw;
    .closeButton {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    .closeButtonMobile {
      display: none;
    }
  }
`

function FlyIn({ anchor = 'right' }: FlyInProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { closeFlyIn, isOpen, content } = useFlyIn()

  const closeDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }
    closeFlyIn()
  }

  return (
    <Drawer anchor={anchor} open={isOpen} onClose={closeDrawer()} sx={{ zIndex: theme.zIndex.drawer + 100 }}>
      <StyledContentWrapper className="styledContentWrapper">
        <Suspense fallback={<LoadingOverlay />}>
          <Button
            className="closeButton"
            size="small"
            endIcon={<CloseIcon />}
            sx={{ position: 'absolute', right: 0, top: 0 }}
            onClick={closeFlyIn}
          >
            {t('common.close')}
          </Button>
          <IconButton
            className="closeButtonMobile"
            sx={{ position: 'absolute', right: 0, top: 0 }}
            size="large"
            onClick={closeFlyIn}
          >
            <CloseOutlinedIcon />
          </IconButton>
          {content}
        </Suspense>
      </StyledContentWrapper>
    </Drawer>
  )
}

export default memo(FlyIn)
