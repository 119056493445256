import { useSuspenseGetFeaturesQuery, useSuspenseGetMeQuery } from '@epix-web-apps/core'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

interface IGlobalPersistedStore {
  icpFilters: Array<string>
  setIcpFilters: (icps: Array<string>) => void
  selfServiceMode: boolean
  setSelfServiceMode: (selfServiceMode: boolean) => void
}

export const useGlobalPersistedStore = create<IGlobalPersistedStore>()(
  persist(
    set => ({
      icpFilters: [],
      setIcpFilters: (icps: string[]) => {
        set({ icpFilters: icps })
      },
      selfServiceMode: false,
      setSelfServiceMode: (selfServiceMode: boolean) => {
        set({ selfServiceMode: selfServiceMode })
      }
    }),
    {
      name: 'globalPersistedStore',
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export const useGlobalStore = () => {
  const meQuery = useSuspenseGetMeQuery({}, { gcTime: Infinity, staleTime: 1000 * 60 * 5 })
  const featuresQuery = useSuspenseGetFeaturesQuery(
    {},
    {
      gcTime: Infinity,
      staleTime: Infinity
    }
  )

  return create(() => ({
    me: meQuery.data.me,
    features: featuresQuery.data.features
  })).getState()
}
