import React, { createContext, useCallback, useContext, useState } from 'react'

interface OpenFlyOutProps {
  content: React.ReactNode
  callbackAfterClose?: () => void
}

interface IFlyInContext {
  openFlyIn({ content, callbackAfterClose }: OpenFlyOutProps): void
  closeFlyIn(): void
  content: React.ReactNode
  isOpen: boolean
}

export const FlyInContext = createContext<IFlyInContext | undefined>(undefined)

export const FlyInProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<React.ReactNode>(null)
  // TODO BUG: because state can be initialized and updated with a function that returns the initial state or the updated state,
  // u need to supply a function that in turn returns the function you want to put in state instead
  // Fixes annoying 'callbackafterclose: () => () => callbackFunc()
  const [callbackAfterClose, setCallbackAfterClose] = useState<() => void>()

  const openFlyIn = useCallback(({ content, callbackAfterClose }: OpenFlyOutProps) => {
    setIsOpen(true)
    setContent(content)
    setCallbackAfterClose(callbackAfterClose)
  }, [])

  const closeFlyIn = useCallback(async () => {
    setIsOpen(false)
    setContent(null)
    callbackAfterClose && (await callbackAfterClose())
  }, [callbackAfterClose])

  return (
    <FlyInContext.Provider
      value={{
        openFlyIn,
        closeFlyIn,
        content,
        isOpen
      }}
    >
      {children}
    </FlyInContext.Provider>
  )
}

export const useFlyIn = () => {
  const context = useContext(FlyInContext)

  if (context === undefined) {
    throw new Error('useFlyIn must be used within a <FlyInProvider></FlyInProvider>')
  }

  return context
}
