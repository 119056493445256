import { downloadFile, useGetExportJobErrorsCsvByJobIdQuery } from '@epix-web-apps/core'
import { Link, ListItemText, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { Trans, useTranslation } from 'react-i18next'
import { ExportJobModelWithoutProcessingJobType } from '../../generic-steps'

interface ExportJobApiListItemProps {
  exportJob: ExportJobModelWithoutProcessingJobType
}

function ExportJobApiListItem({ exportJob }: ExportJobApiListItemProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const { refetch: refetchExportJobErrorsCsv } = useGetExportJobErrorsCsvByJobIdQuery(
    {
      jobId: exportJob?.id || ''
    },
    { enabled: false }
  )

  return (
    <ListItemText
      primary={t('processpage.finishoverview.jobstates.succeeded')}
      secondary={
        <ListItemText
          primary={
            <Trans
              i18nKey="processpage.finishoverview.jobstates.syncedon"
              values={{
                date: `${parseISO(exportJob.exportDate).toLocaleDateString()} ${parseISO(
                  exportJob.exportDate
                ).toLocaleTimeString()}`
              }}
            />
          }
          secondary={
            exportJob.warningMessages.length > 0 && (
              <Link
                color={theme.palette.warning.light}
                underline="always"
                onClick={() => {
                  refetchExportJobErrorsCsv().then(r => {
                    if (r.data) {
                      const errorWarningLabel = exportJob?.errorMessages.length > 0 ? 'error' : 'warning'
                      const exportJobDate = parseISO(exportJob?.exportDate).toISOString()
                      downloadFile(
                        r.data.exportJobErrorsCsvByJobId,
                        `${exportJob.exportType}-exportjob-${errorWarningLabel}_${exportJobDate}.csv`,
                        'text/csv'
                      )
                    }
                  })
                }}
              >
                {`${t('processpage.finishoverview.jobstates.downloadallwarnings')} (${
                  exportJob.warningMessages.length
                })`}
              </Link>
            )
          }
        />
      }
    />
  )
}

export default ExportJobApiListItem
