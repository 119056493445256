import { GraphqlError, TenantAdminModel, useRemoveTenantAdminRoleFromUserMutation } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface DeleteTenantAdminProps {
  tenantAdmin: TenantAdminModel
}

export function RemoveTenantAdmin({ tenantAdmin }: Readonly<DeleteTenantAdminProps>) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const deleteMutation = useRemoveTenantAdminRoleFromUserMutation()
  const form = useForm()

  async function handleOnSubmit() {
    await deleteMutation
      .mutateAsync({
        removeTenantAdminRoleFromUser: {
          userId: tenantAdmin.id
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deletetenantadmin.title')}</Typography>
      <Typography>
        {tenantAdmin.name} {tenantAdmin.username != null && `(${tenantAdmin.username})`}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}
