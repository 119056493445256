import {
  AsbplContractDetailModel,
  FormSelectOption,
  GraphqlError,
  PARTNER_LIST_IDENTIFIERS,
  useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useUpdateAsbplSocialSecurityMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

interface EditASBPLSocialSecurityProps {
  contractDetail: AsbplContractDetailModel
}
export function EditASBPLSocialSecurity({ contractDetail }: EditASBPLSocialSecurityProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { data: plHealthFund } = useSuspenseGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: PARTNER_LIST_IDENTIFIERS.PL_SOCIAL_SECURITY_HEALTH_FUND.key,
    limit: -1,
    offset: 0
  })

  const healthFundOptions = plHealthFund?.allPartnerContractDetailTypesByListDefinitionKey.data.map(
    p => new FormSelectOption(p.id, `${p.key} - ${p.value}`)
  )

  const editASBPLSocialSecuritySchema = object({
    nationalHealthFundId: string().nullable().optional()
  })

  type EditASBPLSocialSecurityForm = TypeOf<typeof editASBPLSocialSecuritySchema>

  const form = useForm<EditASBPLSocialSecurityForm>({
    resolver: zodResolver(editASBPLSocialSecuritySchema),
    defaultValues: {
      nationalHealthFundId: contractDetail.socialSecurityNationalHealthFundType?.id
    }
  })

  const mutation = useUpdateAsbplSocialSecurityMutation()

  function handleOnSubmit(form: EditASBPLSocialSecurityForm) {
    mutation
      .mutateAsync({
        updateASBPLSocialSecurityCommand: {
          id: contractDetail.id,
          nationalHealthFundTypeId: form.nationalHealthFundId
        }
      })
      .then(() => closeFlyIn())
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('asbpldetailpage.socialsecurity.flyin.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name={'nationalHealthFundId'}
          label={t('asbpldetailpage.socialsecurity.flyin.nationalhealthfund')}
          options={healthFundOptions}
        />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons onCancel={() => closeFlyIn()} isMutating={mutation.isPending} />
    </FormContainer>
  )
}
