import {
  byteArrayToFile,
  GraphqlError,
  ImportProcessModel,
  ImportProcessStatus,
  useConfirmImportProcessValidatedMutation,
  useDownloadConvertedFileForImportProcessQuery,
  useExportImportProcessErrorsByImportProcessIdQuery,
  useRequiredParams,
  useSuspenseGetImportProcessByIdQuery,
  useUploadNewImportProcessFileToConvertMutation,
  useUploadNewImportProcessFileToValidateMutation
} from '@epix-web-apps/core'
import {
  ACCEPTED_UPLOAD_TYPES,
  DetailPageBaseQueryParams,
  FormContainer,
  FormErrorList,
  FormFileUpload,
  ZodFileArray
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { CloudUploadOutlined } from '@mui/icons-material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { object, TypeOf } from 'zod'
import { ImportMultiStepFormRef } from '../../import-multi-step-form-ref'
import { ImportSummary } from '../../import-summary'

function ConvertInformation({ importProcess }: { importProcess: ImportProcessModel }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const downloadQuery = useDownloadConvertedFileForImportProcessQuery(
    {
      importProcessId: importProcess.id
    },
    { enabled: false }
  )

  const hasBeenConverted =
    importProcess.status === ImportProcessStatus.Converted ||
    (importProcess.converterChosen &&
      importProcess.status !== ImportProcessStatus.Converting &&
      importProcess.status !== ImportProcessStatus.ConversionFailed)

  function handleDownloadClick() {
    downloadQuery.refetch().then(r => {
      byteArrayToFile(
        r.data?.downloadConvertedFileForImportProcess as unknown as Uint8Array,
        `${importProcess.originalFileName}_converted.csv`
      )
    })
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {importProcess.status === ImportProcessStatus.Converting && <CircularProgress size={25} />}
      {importProcess.status === ImportProcessStatus.ConversionFailed && (
        <CancelOutlinedIcon sx={{ color: theme.palette.error.main }} />
      )}
      {hasBeenConverted && <CheckCircleOutlineOutlinedIcon sx={{ color: theme.palette.success.main }} />}

      {importProcess.status === ImportProcessStatus.Converting && (
        <Typography sx={{ fontSize: '1.5rem' }}>
          {t('configurationimport.steps.convert-and-validate.converting')}
        </Typography>
      )}
      {importProcess.status === ImportProcessStatus.ConversionFailed && (
        <Typography sx={{ fontSize: '1.5rem', color: theme.palette.error.main }}>
          {t('configurationimport.steps.convert-and-validate.conversionfailed')}
        </Typography>
      )}
      {hasBeenConverted && (
        <>
          <Typography sx={{ fontSize: '1.5rem', color: theme.palette.success.main }}>
            {t('configurationimport.steps.convert-and-validate.converted')}
          </Typography>
          <Tooltip title={t('configurationimport.steps.convert-and-validate.downloadconvertedfile')}>
            <IconButton
              disabled={downloadQuery.isFetching}
              onClick={handleDownloadClick}
              aria-label="row details"
              aria-controls="details-row"
            >
              {downloadQuery.isFetching ? <CircularProgress size={25} /> : <CloudDownloadOutlinedIcon />}
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
}

function ValidateInformation({ importProcess }: { importProcess: ImportProcessModel }) {
  const { t } = useTranslation()
  const theme = useTheme()

  if (
    importProcess.status !== ImportProcessStatus.Validated &&
    importProcess.status !== ImportProcessStatus.ValidationFailed &&
    importProcess.status !== ImportProcessStatus.Validating
  )
    return null

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {importProcess.status === ImportProcessStatus.Validating && <CircularProgress size={25} />}
        {importProcess.status === ImportProcessStatus.ValidationFailed && (
          <CancelOutlinedIcon sx={{ color: theme.palette.error.main }} />
        )}
        {importProcess.status === ImportProcessStatus.Validated && (
          <CheckCircleOutlineOutlinedIcon sx={{ color: theme.palette.success.main }} />
        )}

        {importProcess.status === ImportProcessStatus.Validating && (
          <Typography sx={{ fontSize: '1.5rem' }}>
            {t('configurationimport.steps.convert-and-validate.validating')}
          </Typography>
        )}
        {importProcess.status === ImportProcessStatus.ValidationFailed && (
          <Typography sx={{ fontSize: '1.5rem', color: theme.palette.error.main }}>
            {t('configurationimport.steps.convert-and-validate.validationfailed')}
          </Typography>
        )}
        {importProcess.status === ImportProcessStatus.Validated && (
          <Typography sx={{ fontSize: '1.5rem', color: theme.palette.success.main }}>
            {t('configurationimport.steps.convert-and-validate.validated')}
          </Typography>
        )}
      </Box>
      <Typography variant="description" component="p" sx={{ marginTop: 1 }}>
        <Trans i18nKey="configurationimport.steps.convert-and-validate.validatingdescription" />
      </Typography>
    </>
  )
}

function NewFileUpload() {
  const { t } = useTranslation()
  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: importProcessQuery, refetch: refetchImportProcess } = useSuspenseGetImportProcessByIdQuery({
    importProcessId: params.id
  })

  const importProcess = importProcessQuery.importProcessById

  const retryConversionMutation = useUploadNewImportProcessFileToConvertMutation()
  const retryValidationMutation = useUploadNewImportProcessFileToValidateMutation()

  const uploadNewFileSchema = object({
    files: ZodFileArray([ACCEPTED_UPLOAD_TYPES.CSV], true)
  })

  type ImportForm = TypeOf<typeof uploadNewFileSchema>
  const form = useForm<ImportForm>({
    resolver: zodResolver(uploadNewFileSchema)
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])
  const [isRetryingConversion, setIsRetryingConversion] = useState(false)
  const [isRetryingValidation, setIsRetryingValidation] = useState(false)

  function handleRetryConversionClick() {
    form.handleSubmit(handleRetryConversion)()
  }

  function handleRetryValidationClick() {
    form.handleSubmit(handleRetryValidation)()
  }

  async function handleRetryConversion(form: ImportForm) {
    const callbackFunction = (file: any) =>
      retryConversionMutation.mutateAsync({ importProcessId: importProcess.id, file: file })
    await handleRetry(form, setIsRetryingConversion, callbackFunction)
  }

  async function handleRetryValidation(form: ImportForm) {
    const callbackFunction = (file: any) =>
      retryValidationMutation.mutateAsync({ importProcessId: importProcess.id, file: file })
    await handleRetry(form, setIsRetryingValidation, callbackFunction)
  }

  async function handleRetry(
    form: ImportForm,
    setRetryingState: (value: boolean) => void,
    mutationToCall: (file: any) => Promise<any>
  ) {
    setRetryingState(true)
    try {
      setBackendErrors([])

      const file = form.files[0].file

      await mutationToCall(file)

      await refetchImportProcess()
    } catch (e) {
      if (e instanceof GraphqlError) {
        setBackendErrors([e])
        return
      }
      throw e
    } finally {
      setRetryingState(false)
    }
  }

  return (
    <FormContainer form={form} onSubmit={() => undefined} sx={{}}>
      <Typography variant="h4" color={'gray'}>
        {t('configurationimport.steps.convert-and-validate-step.retry.title')}
      </Typography>
      <Box sx={{ mb: 2, mt: 1 }}>
        <FormFileUpload
          name="files"
          accept={[ACCEPTED_UPLOAD_TYPES.CSV]}
          buttonText={t('configurationimport.importbtn')}
          multipleFiles={false}
        />
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {importProcess.converter != null && (
          <Button
            type="button"
            variant="contained"
            onClick={handleRetryConversionClick}
            disabled={isRetryingConversion || isRetryingValidation}
          >
            {t('configurationimport.steps.convert-and-validate-step.retry-conversion.button')}
            {isRetryingConversion && (
              <LinearProgress
                sx={{
                  backgroundColor: undefined,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.6
                }}
              />
            )}
          </Button>
        )}

        {importProcess.status === ImportProcessStatus.ValidationFailed && (
          <Button
            type="button"
            variant="contained"
            onClick={handleRetryValidationClick}
            disabled={isRetryingConversion || isRetryingValidation}
          >
            {t('configurationimport.steps.convert-and-validate-step.retry-validation.button')}
            {isRetryingValidation && (
              <LinearProgress
                sx={{
                  backgroundColor: undefined,
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.6
                }}
              />
            )}
          </Button>
        )}
      </Box>

      <FormErrorList customErrors={backendErrors} />
    </FormContainer>
  )
}

export const ConvertAndValidateStep = forwardRef<ImportMultiStepFormRef, unknown>((_, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const [pollForChanges, setPollForChanges] = useState(false)
  const { data: importProcessQuery, refetch: refetchImportProcess } = useSuspenseGetImportProcessByIdQuery(
    {
      importProcessId: params.id
    },
    {
      refetchInterval: pollForChanges ? 2000 : false
    }
  )

  const importProcess = importProcessQuery.importProcessById

  useEffect(() => {
    const currentStatus = importProcess.status
    setPollForChanges(
      currentStatus !== ImportProcessStatus.ConversionFailed &&
        currentStatus !== ImportProcessStatus.ValidationFailed &&
        currentStatus !== ImportProcessStatus.Validated
    )
  }, [importProcess.status])

  const confirmValidatedCommand = useConfirmImportProcessValidatedMutation()

  const anythingFailed =
    importProcess.status === ImportProcessStatus.ConversionFailed ||
    importProcess.status === ImportProcessStatus.ValidationFailed

  const { refetch: exportRefetch, isFetching: isFetchingDownload } = useExportImportProcessErrorsByImportProcessIdQuery(
    {
      importProcessId: importProcess.id
    },
    { enabled: false }
  )

  function handleDownloadClick() {
    exportRefetch().then(r => {
      byteArrayToFile(
        r.data?.exportImportProcessErrorsByImportProcessId as unknown as Uint8Array,
        'importjoberrors.csv'
      )
    })
  }

  function handleSubmitClick() {
    confirmValidatedCommand
      .mutateAsync({ confirmValidatedCommand: { importProcessId: importProcess.id } })
      .then(() => refetchImportProcess())
  }

  return (
    <Grid container spacing={1}>
      <Grid item md={6}>
        <Box sx={{ mb: 2 }}>
          <ImportSummary />

          {importProcess.converter && (
            <Box sx={{ mb: 2 }}>
              <ConvertInformation importProcess={importProcess} />
            </Box>
          )}
          <Box sx={{ mb: 2 }}>
            <ValidateInformation importProcess={importProcess} />
          </Box>
        </Box>

        {anythingFailed && (
          <Box sx={{ mb: 2, mt: 6 }}>
            <NewFileUpload />
          </Box>
        )}

        {importProcess.status === ImportProcessStatus.Validated && (
          <Box>
            <Typography sx={{ mb: 1 }}>
              {t('configurationimport.steps.convert-and-validate.startimportdescription')}
            </Typography>
            <Button
              type="button"
              variant="contained"
              onClick={handleSubmitClick}
              disabled={confirmValidatedCommand.isPending}
              startIcon={<CloudUploadOutlined />}
            >
              {t('configurationimport.steps.convert-and-validate.startimport')}
              {confirmValidatedCommand.isPending && (
                <LinearProgress
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0.6
                  }}
                />
              )}
            </Button>
          </Box>
        )}
      </Grid>

      {anythingFailed && (
        <Grid item md={6} gap={1}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h3" color={'gray'}>
              {t('configurationimport.common.errors.title')}
            </Typography>

            <IconButton onClick={handleDownloadClick} aria-label="row details" aria-controls="details-row">
              {isFetchingDownload ? <CircularProgress size={25} /> : <CloudDownloadOutlinedIcon />}
            </IconButton>
          </Box>

          <Table sx={{ border: `1px solid ${theme.palette.divider}` }}>
            <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
              <TableRow>
                <TableCell>{t('configurationimport.common.errors.table.header')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {importProcess.errors?.map((error, index) => (
                <TableRow key={index}>
                  <TableCell>{error.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  )
})
