import {
  useSuspenseGetPhocusContractDetailsQuery,
  useUpdatePhocusContractDetailContractTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, object } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditPhocusContractTypeProps {
  contractId: string
}

export function EditPhocusContractType({ contractId }: EditPhocusContractTypeProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetPhocusContractDetailsQuery({
      contractId: contractId
    })

  const editPhocusContractTypeSchema = object({
    writtenContract: boolean()
  })

  type EditPhocusContractTypeForm = TypeOf<typeof editPhocusContractTypeSchema>

  const updateMutation = useUpdatePhocusContractDetailContractTypeMutation()

  const form = useForm<EditPhocusContractTypeForm>({
    resolver: zodResolver(editPhocusContractTypeSchema),
    defaultValues: {
      writtenContract:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailContractTypeModel.writtenContract
    }
  })

  const handleOnSubmit = async (phocusContractType: EditPhocusContractTypeForm) => {
    await updateMutation
      .mutateAsync({
        phocusContractDetailContractTypeCommand: {
          id: partnerSpecificDetails?.phocusContractDetailByContractId.id || '',
          writtenContract: phocusContractType.writtenContract
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontracttype.title')}</Typography>

      <FormGridLayout>
        <FormSwitch sx={12} name="writtenContract" label={t('form.field.writtencontract')} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPhocusContractType
