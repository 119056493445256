import {
  REQUEST_RIGHT_EDITOR,
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetPersonForSelfServiceByIdQuery,
  useSuspenseGetTeamMemberReqeustRightsQuery
} from '@epix-web-apps/core'
import { HeaderTitleNavigation, useRouteDefinitions } from '@epix-web-apps/ui'
import { Box, Grid, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import BalanceChart from '../../components/balance-components/balance-chart'
import { MonthlyCalendar } from '../../components/calendar-components/monthly-calendar'

/* eslint-disable-next-line */
export interface SpecificPersonCalendarProps {}

export type SpecificPersonCalendarQueryParams = {
  personId: string
}

export function SpecificPersonCalendar(props: SpecificPersonCalendarProps) {
  const params = useRequiredParams<SpecificPersonCalendarQueryParams>()
  const { t } = useTranslation()
  const navigate = useNavigateWithParams()
  const routes = useRouteDefinitions()

  const [searchParams, _] = useSearchParams()
  const date = searchParams.get('date') !== null ? parseISO(searchParams.get('date')!) : new Date()
  const { data: personForSelfServiceById } = useSuspenseGetPersonForSelfServiceByIdQuery({
    personId: params.personId
  })

  const { data: editors } = useSuspenseGetTeamMemberReqeustRightsQuery({
    otherPersonId: params.personId,
    requestPolicyRightTypeKey: REQUEST_RIGHT_EDITOR
  })

  const isEditorForPerson = editors?.allTeamMemberRequestRights.hasRight

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <HeaderTitleNavigation
          onBackClick={() => {
            const teamId = searchParams.get('teamId')
            !teamId ? navigate(routes.SelfService.ROOT) : navigate(routes.SelfService.TEAMS_ID_CALENDAR(teamId))
          }}
          showDivider={false}
          mobileSizeTitle
          title={
            personForSelfServiceById?.personByIdForSelfservice.firstName +
            ' ' +
            personForSelfServiceById?.personByIdForSelfservice.preferredLastName
          }
        />

        <MonthlyCalendar isEditorRole={isEditorForPerson} specificPersonPage personId={params.personId} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography variant="h4">
          {t('selfservice.homepage.balances')} {date.toLocaleDateString()}
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <BalanceChart dateOfCalendar={date} personId={params.personId} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default SpecificPersonCalendar
