import { useTranslation } from 'react-i18next'
import { useSuspenseGetDisabledDistributionQuery } from '@epix-web-apps/core'
import { PieData } from '../piechart-component/piechart-component'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import PiechartComponent from '../piechart-component'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface DisabledComponentProps {}

export function DisabledComponent(props: DisabledComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const store = useAnalyticsPersistedStore()

  const { data: disabledDistribution } = useSuspenseGetDisabledDistributionQuery({
    icpIds: icpFilters,
    selectedDate: store.dateFilter,
    filterModel: {
      genderType: store.genderFilter,
      functionAreaType: store.functionAreaFilter,
      functionLevelType: store.functionLevelFilter,
      employeeType: store.employeeTypeFilter
    }
  })

  return (
    <PiechartComponent
      data={disabledDistribution?.disabledDistribution.entries.map(
        x => new PieData(x.disabledRange, x.amount.toString(), `${x.disabledRange}: ${x.amount}`)
      )}
      total={disabledDistribution?.disabledDistribution.total}
      name={t('analytics.pie.genderTotal')}
    />
  )
}

export default DisabledComponent
