import {
    FormatDateToDDMM,
    ToBackendFormatedDate,
    UpcomingStartersLeaversModel,
    useGetPagedStartersQuery
} from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { addDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingPaper } from '../../suspense-paper'
import HomepageEventWidget from '../homepage-event-widget'
import { OverviewEventStarters } from '../overview-event-starters'

/* eslint-disable-next-line */
export interface UpcomingStartersComponentProps {}

export function UpcomingStartersComponent(props: UpcomingStartersComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const navigate = useNavigate()
  const { openFlyIn } = useFlyIn()

  const { data: getPagedStarters, isLoading } = useGetPagedStartersQuery({
    icps: icpFilters,
    startDate: ToBackendFormatedDate(new Date()),
    endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
    offset: 0,
    limit: 4
  })

  if (getPagedStarters && getPagedStarters.pagedStarters.totalCount === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <LoadingPaper isLoading={isLoading} title={t('homepage.event.starters')}>
        <HomepageEventWidget
          data={getPagedStarters?.pagedStarters.data ?? []}
          length={getPagedStarters?.pagedStarters.totalCount ?? 0}
          subtext={(item: UpcomingStartersLeaversModel) =>
            `${item.countryKey} (${FormatDateToDDMM(item.upcomingDate)})`
          }
          onItemClick={item => navigate(People.PEOPLE_ID_DETAILS(`${item.personId}`))}
          onMoreClick={() => openFlyIn({ content: <OverviewEventStarters /> })}
        />
      </LoadingPaper>
    </Grid>
  )
}

export default UpcomingStartersComponent
