import {
  regex,
  ROLE,
  useCanAccess,
  useSuspenseGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeMutation
} from '@epix-web-apps/core'
import { CanView, FormActionButtons, FormContainer, FormErrorList, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormLabel, Grid, Typography, useTheme } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormColorPicker } from '../../form-components/form-color-picker'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditCalendarSettingsProps {
  employerId: string
  payrollCodeId: string
}

export function AddEditCalendarSettings({ employerId, payrollCodeId }: AddEditCalendarSettingsProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const addEditCalendarSettingsSchema = object({
    isCalendarEntry: boolean(),
    colourHexCode: regex(
      string({
        invalid_type_error: t('form.validation.colourhexcode')
      })
    ),
    isUsableInSelfService: boolean(),
    needsDocument: boolean(),
    useForBradfordCalculation: boolean()
  })
  type CreateEditCalendarSettingsForm = TypeOf<typeof addEditCalendarSettingsSchema>

  const { data: getPayrollCodeById, refetch: refetchPayrollCodeById } = useSuspenseGetPayrollCodeByIdQuery({
    payrollCodeId: payrollCodeId
  })

  const canAccessPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditCalendarSettingsForm>({
    resolver: zodResolver(addEditCalendarSettingsSchema),
    defaultValues: {
      isCalendarEntry: getPayrollCodeById?.payrollCodeById.isCalendarEntry ?? false,
      colourHexCode: getPayrollCodeById?.payrollCodeById.colourCodeHex || theme.palette.primary.main,
      needsDocument: getPayrollCodeById?.payrollCodeById.needsDocument ?? false,
      useForBradfordCalculation: getPayrollCodeById?.payrollCodeById.useForBradfordCalculation ?? false,
      isUsableInSelfService: getPayrollCodeById?.payrollCodeById.isUsableInSelfService ?? false
    }
  })
  const { control } = form
  const watchIsCalendarEntry = useWatch({ control, name: `isCalendarEntry` })

  const updateMutation = useUpdatePayrollCodeMutation()

  const handleOnSubmit = async (newPayrollCode: CreateEditCalendarSettingsForm) => {
    if (!canAccessPayrollCodes) return
    await updateMutation
      .mutateAsync({
        updatePayrollCodeCommand: {
          id: payrollCodeId || '',
          code: getPayrollCodeById?.payrollCodeById.code || '',
          providerCode: getPayrollCodeById?.payrollCodeById.providerCode,
          colourHexCode: newPayrollCode.colourHexCode,
          description: getPayrollCodeById?.payrollCodeById.description || '',
          groupTypeKey: getPayrollCodeById?.payrollCodeById.group.key || '',
          subGroupTypeKey: getPayrollCodeById?.payrollCodeById.subGroup?.key || '',
          employerId: employerId || '',
          reverseSignImport: getPayrollCodeById?.payrollCodeById.reverseSignImport ?? false,
          reverseSignExport: getPayrollCodeById?.payrollCodeById.reverseSignExport ?? false,
          isCalendarEntry: newPayrollCode.isCalendarEntry,
          isSalaryEntry: getPayrollCodeById?.payrollCodeById.isSalaryEntry ?? false,
          isUsableInSelfService: newPayrollCode.isUsableInSelfService ?? false,
          needsDocument: newPayrollCode.needsDocument,
          useForBradfordCalculation: newPayrollCode.useForBradfordCalculation,
          userFriendlyDescription: getPayrollCodeById?.payrollCodeById.userFriendlyDescription,
          valueTypePayComponents: (getPayrollCodeById?.payrollCodeById.valueTypePayComponents || []).map(
            item => item.key || item.value
          ),
          exportToProvider: getPayrollCodeById?.payrollCodeById.exportToProvider ?? true,
          salaryEntryTypeKey: getPayrollCodeById?.payrollCodeById.salaryEntryType?.key
        }
      })
      .then(() => {
        refetchPayrollCodeById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditcalendarsettings.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <Grid xs={12} item>
          <FormLabel>{t('form.field.calendarsubtitle')}</FormLabel>
        </Grid>
        <FormSwitch
          disabled={!canAccessPayrollCodes}
          sx={6}
          name="isCalendarEntry"
          label={t('form.field.iscalendarentry')}
        />

        <FormColorPicker
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="colourHexCode"
          label={t('form.field.colourhexcode')}
        />

        {watchIsCalendarEntry && (
          <>
            <Grid xs={12} item>
              <FormLabel>{t('form.field.selfservice.title')}</FormLabel>
            </Grid>
            <FormSwitch
              disabled={!canAccessPayrollCodes}
              sx={12}
              name="isUsableInSelfService"
              label={t('form.field.isusableforselfservice')}
            />
          </>
        )}

        {watchIsCalendarEntry && (
          <>
            <Grid xs={12} item>
              <FormLabel>{t('form.field.documents.title')}</FormLabel>
            </Grid>
            <FormSwitch
              disabled={!canAccessPayrollCodes}
              sx={12}
              name="needsDocument"
              label={t('form.field.needsdocument')}
            />

            <Grid xs={12} item>
              <FormLabel>{t('form.field.bradfordcalculation.title')}</FormLabel>
            </Grid>
            <FormSwitch
              disabled={!canAccessPayrollCodes}
              sx={12}
              name="useForBradfordCalculation"
              label={t('form.field.addtobradforcalculation')}
            />
          </>
        )}
      </FormGridLayout>
      <FormErrorList />
      <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES]}>
        <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
      </CanView>
    </FormContainer>
  )
}

export default AddEditCalendarSettings
