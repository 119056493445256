import {
  GetFunctionLevelTypeByKeyQuery,
  OrderDirection,
  useCreateFunctionLevelTypeMutation,
  useGetAllFunctionLevelTypesQuery,
  useSuspenseGetFunctionLevelTypeByKeyQuery,
  useUpdateFunctionLevelTypeMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Grid, Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditFunctionLevelProps {
  functionLevelKey: string
}

export function AddFunctionLevel() {
  return AddEditFunctionLevel()
}

export function EditFunctionLevel({ functionLevelKey }: AddEditFunctionLevelProps) {
  const getFunctionLevelByKeyQuery = useSuspenseGetFunctionLevelTypeByKeyQuery({
    key: functionLevelKey
  })

  return AddEditFunctionLevel(getFunctionLevelByKeyQuery)
}

export function AddEditFunctionLevel(
  getFunctionLevelByKeyQuery?: UseSuspenseQueryResult<GetFunctionLevelTypeByKeyQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditFunctionLevelSchema = object({
    key: string({
      required_error: t('form.validation.keyrequired'),
      invalid_type_error: t('form.validation.keyrequired')
    }).min(1, t('form.validation.keyrequired')),
    value: string({
      required_error: t('form.validation.valuerequired'),
      invalid_type_error: t('form.validation.valuerequired')
    }).min(1, t('form.validation.valuerequired')),
    isActive: boolean()
  })

  type CreateEditFunctionLevelForm = TypeOf<typeof addEditFunctionLevelSchema>

  const { data: functionLevelsData } = useGetAllFunctionLevelTypesQuery({
    offset: 0,
    limit: -1,
    sortByProperty: 'key',
    orderDirection: OrderDirection.Asc
  })

  const getFunctionLevelByKey = getFunctionLevelByKeyQuery?.data

  const form = useForm<CreateEditFunctionLevelForm>({
    resolver: zodResolver(addEditFunctionLevelSchema),
    defaultValues: {
      key: getFunctionLevelByKey?.functionLevelTypeByKey?.key,
      value: getFunctionLevelByKey?.functionLevelTypeByKey?.value,
      isActive: getFunctionLevelByKey?.functionLevelTypeByKey?.active ?? false
    }
  })

  useEffect(() => {
    form.setValue('isActive', getFunctionLevelByKey?.functionLevelTypeByKey?.inUseCount !== 0)
  }, [])

  const createMutation = useCreateFunctionLevelTypeMutation()
  const updateMutation = useUpdateFunctionLevelTypeMutation()

  const handleOnSubmit = async (newFunctionLevel: CreateEditFunctionLevelForm) => {
    if (
      !getFunctionLevelByKeyQuery &&
      newFunctionLevel.key !==
        functionLevelsData?.functionLevelTypes.data.find(f => f.key.includes(newFunctionLevel.key))?.key
    ) {
      await createMutation
        .mutateAsync({
          createFunctionLevelTypeCommand: {
            key: newFunctionLevel.key,
            value: newFunctionLevel.value
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateFunctionLevelTypeCommand: {
            key: newFunctionLevel.key,
            value: newFunctionLevel.value,
            active: newFunctionLevel.isActive
          }
        })
        .then(() => {
          getFunctionLevelByKeyQuery?.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditfunctionlevel.title')}</Typography>

      <FormGridLayout>
        <FormInput sx={12} name="key" disabled={!!getFunctionLevelByKeyQuery} label={`${t('form.field.key')}`} />

        <FormInput sx={12} name="value" label={`${t('form.field.value')}`} />

        {getFunctionLevelByKeyQuery && (
          <FormSwitch
            sx={12}
            name="isActive"
            label={t('form.field.active')}
            disabled={getFunctionLevelByKey?.functionLevelTypeByKey?.inUseCount !== 0}
          />
        )}
      </FormGridLayout>

      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />

      <Grid item xs={12}>
        <Typography color="gray">
          {t('functionlevelspage.flyin.amountofpeople')} {getFunctionLevelByKey?.functionLevelTypeByKey.inUseCount}
        </Typography>
      </Grid>
    </FormContainer>
  )
}

export default AddEditFunctionLevel
