import {
  FormSelectOption,
  numericString,
  TenantOwner,
  useSuspenseGetFeaturesQuery,
  useSuspenseGetTenantConfigurationQuery,
  useSuspenseGetTenantFeaturesQuery,
  useUpdateTenantConfigurationMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormSelect
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, nativeEnum, number, object, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

const tenantOwnerOptions = Object.values(TenantOwner).map(v => new FormSelectOption(v, v))

export function TenantFeaturesForm() {
  const { t } = useTranslation()
  const { refetch: refetchTenantFeatures } = useSuspenseGetTenantFeaturesQuery()
  const { data: tenantConfigurationData, refetch: refetchTenantConfigurationData } =
    useSuspenseGetTenantConfigurationQuery()
  const { data: getFeatureFlags } = useSuspenseGetFeaturesQuery()

  const updateMutation = useUpdateTenantConfigurationMutation()

  const editConfigurationForm = object({
    tenantOwner: nativeEnum(TenantOwner),
    calculatedByEpix: boolean(),
    hasPayComponentManagement: boolean(),
    hasReporting: boolean(),
    hasCalendarManagement: boolean(),
    hasDocumentManagement: boolean(),
    hasSelfService: boolean(),
    hasCompanyCars: boolean(),
    hasSmartPayComponents: boolean(),
    hasEpixCopilot: boolean(),
    allowActivatingDefaultProviders: boolean(),
    activePeopleLimit: numericString(number().nonnegative()),
    providerLimit: numericString(number().nonnegative()),
    adminLimit: numericString(number().nonnegative()),
    aiCreditLimit: numericString(number().nonnegative())
  })
  type EditConfigurationForm = TypeOf<typeof editConfigurationForm>

  const form = useForm<EditConfigurationForm>({
    reValidateMode: 'onSubmit',
    defaultValues: {
      tenantOwner: tenantConfigurationData?.tenantConfiguration.tenantOwner,
      calculatedByEpix: tenantConfigurationData?.tenantConfiguration.calculatedByEpix,
      hasCalendarManagement: tenantConfigurationData?.tenantConfiguration.hasCalendarManagement,
      hasReporting: tenantConfigurationData.tenantConfiguration.hasReporting,
      hasDocumentManagement: tenantConfigurationData?.tenantConfiguration.hasDocumentManagement,
      hasPayComponentManagement: tenantConfigurationData?.tenantConfiguration.hasPayComponentManagement,
      hasSelfService: tenantConfigurationData?.tenantConfiguration.hasSelfService,
      hasCompanyCars: tenantConfigurationData?.tenantConfiguration.hasCompanyCars,
      hasSmartPayComponents: tenantConfigurationData?.tenantConfiguration.hasSmartPayComponents,
      hasEpixCopilot: tenantConfigurationData?.tenantConfiguration.hasEpixCopilot,
      allowActivatingDefaultProviders: tenantConfigurationData?.tenantConfiguration.allowActivatingDefaultProviders,
      activePeopleLimit: tenantConfigurationData?.tenantConfiguration.activePeopleLimit,
      providerLimit: tenantConfigurationData?.tenantConfiguration.providerLimit,
      adminLimit: tenantConfigurationData?.tenantConfiguration.adminLimit,
      aiCreditLimit: tenantConfigurationData.tenantConfiguration.aiCreditLimit
    },
    resolver: zodResolver(editConfigurationForm)
  })

  function handleOnSubmit(formData: EditConfigurationForm) {
    updateMutation
      .mutateAsync({
        updateTenantConfigurationCommand: {
          tenantOwner: formData.tenantOwner,
          calculatedByEpix: formData.calculatedByEpix,
          hasCalendarManagement: formData.hasCalendarManagement,
          hasReporting: formData.hasReporting,
          hasCompanyCars: formData.hasCompanyCars,
          hasDocumentManagement: formData.hasDocumentManagement,
          hasPayComponentManagement: formData.hasPayComponentManagement,
          hasSelfService: formData.hasSelfService,
          hasSmartPayComponents: formData.hasSmartPayComponents,
          hasEpixCopilot: formData.hasEpixCopilot,
          allowActivatingDefaultProviders: formData.allowActivatingDefaultProviders,
          activePeopleLimit: formData.activePeopleLimit,
          providerLimit: formData.providerLimit,
          adminLimit: formData.adminLimit,
          aiCreditLimit: formData.aiCreditLimit
        }
      })
      .then(() => {
        refetchTenantFeatures()
        refetchTenantConfigurationData()
      })
  }

  return (
    <>
      <Typography variant="h3" color="textSecondary">
        {t('tenantconfigurationpage.tenantfeatures.title')}
      </Typography>
      <FormContainer sx={{ width: '100%' }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
        <Grid container>
          <FormGridLayout item xs={6} paddingRight={2}>
            <FormSwitch
              sx={12}
              name="calculatedByEpix"
              onChange={e => {
                if (e) {
                  form.resetField('hasPayComponentManagement', {
                    defaultValue: true
                  })
                  form.resetField('hasCalendarManagement', { defaultValue: true })
                  form.resetField('hasSelfService', { defaultValue: true })
                }
              }}
              label={t('form.field.payrollviaepix')}
            />

            <FormSwitch
              sx={12}
              name="hasPayComponentManagement"
              onChange={e => {
                if (!e) {
                  form.resetField('hasSmartPayComponents', { defaultValue: false })
                }
              }}
              label={t('form.field.paycomponentmanagement')}
            />

            {getFeatureFlags?.features.smartPayComponents && (
              <Box ml={8}>
                <FormSwitch sx={12} name="hasSmartPayComponents" label={t('form.field.hassmartpaycomponents')} />
              </Box>
            )}

            <FormSwitch
              sx={12}
              name="hasCalendarManagement"
              onChange={e => {
                if (!e) {
                  form.resetField('hasSelfService', { defaultValue: false })
                }
              }}
              label={t('form.field.calendarmanagement')}
            />

            <Box ml={8}>
              <FormSwitch
                sx={12}
                disabled={!form.watch('hasCalendarManagement')}
                name="hasSelfService"
                label={t('form.field.selfservice.hasselfservice')}
              />
            </Box>

            <FormSwitch sx={12} name="hasReporting" label={t('form.field.reporting')} />

            <FormSwitch sx={12} name="hasCompanyCars" label={t('form.field.hascompanycars')} />

            <FormSwitch sx={12} name="hasDocumentManagement" label={t('form.field.hasdocumentmanagement')} />

            <FormSwitch sx={12} name="hasEpixCopilot" label={'Epix Copilot'} />

            <FormSwitch
              sx={12}
              name="allowActivatingDefaultProviders"
              label={t('form.field.allowactivatingdefaultproviders')}
            />

            <FormSelect sx={12} name="tenantOwner" label={t('form.field.tenantowner')} options={tenantOwnerOptions} />
          </FormGridLayout>

          <Grid item xs={6}>
            <FormGridLayout item>
              <FormNumericInput sx={12} name="activePeopleLimit" label={t('form.field.activepeoplelimit')} />
              <FormNumericInput sx={12} name="providerLimit" label={t('form.field.providerlimit')} />
              <FormNumericInput sx={12} name="adminLimit" label={t('form.field.adminlimit')} />
              <FormNumericInput sx={12} name="aiCreditLimit" label={t('form.field.aicreditlimit')} />
            </FormGridLayout>
          </Grid>
        </Grid>
        <FormErrorList />
        <FormActionButtons isMutating={updateMutation.isPending} />
      </FormContainer>
    </>
  )
}
