import {
  numericString,
  useSuspenseGetPhocusContractDetailsQuery,
  useUpdatePhocusContractDetailTaxesMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, number, object } from 'zod'
import { FormSwitch } from '../../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditPhocusTaxesProps {
  contractId: string
}

export function EditPhocusTaxes({ contractId }: EditPhocusTaxesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetPhocusContractDetailsQuery({
      contractId: contractId
    })

  const editPhocusTaxesSchema = object({
    wageTaxCredit: boolean(),
    annualWageSpecialRate: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    ),
    annualWage: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).optional()
    )
  })

  type EditPhocusTaxesForm = TypeOf<typeof editPhocusTaxesSchema>

  const updateMutation = useUpdatePhocusContractDetailTaxesMutation()

  const form = useForm<EditPhocusTaxesForm>({
    resolver: zodResolver(editPhocusTaxesSchema),
    defaultValues: {
      wageTaxCredit:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.wageTaxCredit,
      annualWageSpecialRate:
        partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.annualWageSpecialRate,
      annualWage: partnerSpecificDetails?.phocusContractDetailByContractId.phocusContractDetailTaxesModel.annualWage
    }
  })

  const handleOnSubmit = async (phocusTaxes: EditPhocusTaxesForm) => {
    await updateMutation
      .mutateAsync({
        phocusContractDetailTaxesCommand: {
          id: partnerSpecificDetails?.phocusContractDetailByContractId.id || '',
          wageTaxCredit: phocusTaxes.wageTaxCredit,
          annualWageSpecialRate: phocusTaxes.annualWageSpecialRate,
          annualWage: phocusTaxes.annualWage
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editphocustaxes.title')}</Typography>

      <FormGridLayout>
        <FormSwitch sx={12} name="wageTaxCredit" label={t('form.field.wagetaxcredit')} />
        <FormNumericInput sx={12} name="annualWageSpecialRate" label={t('form.field.annualwagespecialrate')} />
        <FormNumericInput sx={12} name="annualWage" label={t('form.field.annualwage')} />
      </FormGridLayout>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="description">{t('flyin.editphocustaxes.info1')}</Typography>
        <Typography variant="description">{t('flyin.editphocustaxes.info2')}</Typography>
        <Typography variant="description">{t('flyin.editphocustaxes.info3')}</Typography>
        <Typography variant="description">{t('flyin.editphocustaxes.info4')}</Typography>
        <Typography variant="description">{t('flyin.editphocustaxes.info5')}</Typography>
      </Box>

      <FormErrorList />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPhocusTaxes
