import {
  getSearchParamNumber,
  TeamAuthorizationModel,
  useRequiredParams,
  useSuspenseGetPersonByIdQuery,
  useSuspenseGetSuperiorsByPersonIdQuery,
  useSuspenseGetTeamAuthorizationsByPersonIdQuery,
  useSuspenseGetTeamMembersByPersonIdQuery
} from '@epix-web-apps/core'
import {
  Configuration,
  DetailBox,
  DetailBoxRow,
  DetailPageBaseQueryParams,
  DetailPageContentHeader,
  HeaderTitleNavigation,
  People,
  useFlyIn
} from '@epix-web-apps/ui'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import EnhancedEncryptionOutlinedIcon from '@mui/icons-material/EnhancedEncryptionOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import {
  Box,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useTheme
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DropdownMenu from '../../components/dropdown-menu'
import NextPreviousPerson from '../../components/people-components/next-previous-person'
import {
  AddTeamAuthorizationForPerson,
  EditTeamAuthorizationForPerson
} from '../../components/team-components/add-edit-team-authorization-for-person/add-edit-team-authorization-for-person'
import RemoveTeamAuthorization from '../../components/team-components/remove-team-authorization'

/* eslint-disable-next-line */
export interface OrganisationPageProps {}

export function OrganisationPage(props: OrganisationPageProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [clickedTeamAuthorization, setClickedTeamAuthorization] = useState<string>()
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { data: getPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })
  const person = getPersonById?.personById

  const { data: getTeams } = useSuspenseGetTeamMembersByPersonIdQuery({
    id: params.id
  })

  const { data: getSuperiorRoles } = useSuspenseGetSuperiorsByPersonIdQuery({
    id: params.id
  })

  const { data: getTeamAuthorizationsByPersonId, refetch: refetchTeamAuthorizationById } =
    useSuspenseGetTeamAuthorizationsByPersonIdQuery({
      id: params.id
    })

  return (
    <>
      <DetailPageContentHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <HeaderTitleNavigation
            backToLink={`${People.PEOPLE()}?page=${getSearchParamNumber('page', searchParams)}`}
            title={`${person?.firstName} ${person?.preferredLastName}`}
            showDivider={false}
          />
          <NextPreviousPerson personId={params.id} />
        </Box>
      </DetailPageContentHeader>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          flexWrap: 'wrap',
          padding: '1.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2rem'
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DetailBox
              title={t('personorganisationpage.teammembership.title')}
              actionIcon={
                <IconButton size="small" onClick={() => navigate(Configuration.TEAMS())}>
                  <AccountTreeIcon />
                </IconButton>
              }
            >
              <DetailBoxRow sx={{ mb: 1 }}>
                <p>
                  <span>
                    {person?.firstName} {t('personorganisationpage.teammembership.ismemberofteams')}
                  </span>
                </p>
              </DetailBoxRow>
              {getTeams?.allTeamMembersByPersonId.map((item: any, index: number) => (
                <DetailBoxRow key={index}>
                  <Link
                    color="primary"
                    underline="always"
                    sx={{ color: theme.palette.common.black }}
                    onClick={() => {
                      navigate(Configuration.TEAMS_ID(`${item?.teamId}`))
                    }}
                  >
                    <span>{item.teamName}</span>
                  </Link>
                </DetailBoxRow>
              ))}
            </DetailBox>
          </Box>

          <Box sx={{ flex: 1 }}>
            <DetailBox
              title={`${t('personorganisationpage.rolestowards.title')} ${getPersonById?.personById.firstName}`}
            >
              <DetailBoxRow sx={{ mb: 1 }}>
                <p>
                  <span>
                    {t('personorganisationpage.rolestowards.subtitle')} {getPersonById?.personById.firstName}
                  </span>
                </p>
              </DetailBoxRow>

              <Divider />
              <Table size="small">
                <TableBody>
                  {getSuperiorRoles?.superiorsByPersonId.map((role: TeamAuthorizationModel, index: number) => (
                    <TableRow key={index}>
                      <TableCell sx={{ fontSize: '1rem', paddingLeft: 0 }}>{role.personName}</TableCell>
                      <TableCell sx={{ fontSize: '1rem' }}>{role.functionName}</TableCell>
                      <TableCell sx={{ fontSize: '1rem' }}>
                        <Link
                          sx={{ color: theme.palette.common.black }}
                          onClick={() => {
                            navigate(Configuration.TEAMS_ID(`${role?.teamId}`))
                          }}
                        >
                          {role.teamName}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </DetailBox>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2rem'
          }}
        >
          <Box sx={{ flex: 1 }}>
            <DetailBox
              title={`${t('personorganisationpage.rolesof.title')} ${getPersonById?.personById.firstName}`}
              actionIcon={
                <IconButton
                  size="small"
                  onClick={() =>
                    openFlyIn({
                      content: <AddTeamAuthorizationForPerson personId={person?.id} />,
                      callbackAfterClose: () => refetchTeamAuthorizationById
                    })
                  }
                >
                  <EnhancedEncryptionOutlinedIcon />
                </IconButton>
              }
            >
              <DetailBoxRow sx={{ mb: 1 }}>
                <p>
                  <span>
                    {getPersonById?.personById.firstName} {t('personorganisationpage.rolesof.subtitle')}
                  </span>
                </p>
              </DetailBoxRow>

              <Divider />
              <Table size="small">
                <TableBody>
                  {getTeamAuthorizationsByPersonId?.teamAuthorizationsByPersonId.map(
                    (teamAuthorization: TeamAuthorizationModel) => (
                      <TableRow key={teamAuthorization.functionId}>
                        <TableCell sx={{ fontSize: '1rem', paddingLeft: 0 }}>
                          {teamAuthorization.functionName}
                        </TableCell>
                        <TableCell sx={{ fontSize: '1rem' }}>
                          <Link
                            sx={{ color: theme.palette.common.black }}
                            onClick={() => {
                              navigate(Configuration.TEAMS_ID(`${teamAuthorization.teamId}`))
                            }}
                          >
                            {teamAuthorization.teamName}
                          </Link>
                        </TableCell>
                        <TableCell sx={{ fontSize: '1rem', textAlign: 'right' }}>
                          <IconButton
                            aria-label="row actions"
                            aria-controls="menu-row"
                            aria-haspopup="true"
                            onClick={e => {
                              e.preventDefault()
                              e.stopPropagation()
                              setAnchorEl(e.currentTarget)
                              setClickedTeamAuthorization(teamAuthorization.id)
                            }}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                          <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                            {clickedTeamAuthorization && (
                              <MenuItem
                                onClick={() =>
                                  openFlyIn({
                                    content: (
                                      <EditTeamAuthorizationForPerson
                                        personId={person?.id}
                                        teamAuthorizationId={clickedTeamAuthorization}
                                      />
                                    ),
                                    callbackAfterClose: () => refetchTeamAuthorizationById
                                  })
                                }
                              >
                                {t('personorganisationpage.rolesof.edit')}
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                openFlyIn({
                                  content: <RemoveTeamAuthorization teamAuthorizationId={clickedTeamAuthorization} />,
                                  callbackAfterClose: () => refetchTeamAuthorizationById
                                })
                              }
                            >
                              {t('personorganisationpage.rolesof.delete')}
                            </MenuItem>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </DetailBox>
          </Box>

          <Box sx={{ flex: 1 }} />
        </Box>
      </Box>
    </>
  )
}

export default OrganisationPage
