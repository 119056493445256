import { Box, IconButton, Link, useTheme } from '@mui/material'
import EmployersWidgetTitle from '../employers-widget-title'
import { useTranslation } from 'react-i18next'
import { ReadMoreText, TypographySmall, WidgetRowItem } from '../employer-overview/employer-overview'
import { useNavigate, useParams } from 'react-router-dom'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined'
import {
  OrderDirection,
  useSuspenseGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetAllRequestPoliciesByEmployerIdQuery
} from '@epix-web-apps/core'
import { AddRequestPolicy } from '../../request-policy-components'
import { Configuration, useFlyIn } from '@epix-web-apps/ui'
import { environment } from '../../../../../environments/environment'
import { useEffect } from 'react'
import { CompanyCarDetailPageParams } from '../../../pages/company-car-detail-page/company-car-detail-page'

/* eslint-disable-next-line */
export interface EmployersWidgetRequestpoliciesProps {
  employerId: string
  setTabComplete: (newValue: boolean) => void
}

export function EmployersWidgetRequestpolicies({ employerId, setTabComplete }: EmployersWidgetRequestpoliciesProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const params = useParams<CompanyCarDetailPageParams>()
  const { openFlyIn } = useFlyIn()

  const { data: requestPolicies, refetch: refetchRequestPolicies } = useSuspenseGetAllRequestPoliciesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1
  })

  const { data: payrollCodes } = useSuspenseGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: 0,
    payrollCodeFilterModel: {
      usableInSelfService: true
    },
    orderDirection: OrderDirection.Asc
  })

  useEffect(() => {
    if (requestPolicies?.allRequestPoliciesByEmployer) {
      setTabComplete(requestPolicies?.allRequestPoliciesByEmployer.totalCount > 0)
    }
  }, [requestPolicies?.allRequestPoliciesByEmployer])

  const disabled = (payrollCodes?.allPayrollCodesByEmployerId.totalCount ?? 0) === 0

  return (
    requestPolicies?.allRequestPoliciesByEmployer &&
    payrollCodes?.allPayrollCodesByEmployerId.data && (
      <>
        <EmployersWidgetTitle
          title={t('employerspage.widget.requestpolicies.title')}
          url={Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES(`${params.id}`, `${employerId}`)}
          icon={<ContactSupportOutlinedIcon />}
          hasData={requestPolicies.allRequestPoliciesByEmployer.totalCount > 0}
          disabled={disabled}
        />

        {disabled && (
          <TypographySmall
            sx={{
              pb: '0.25rem',
              color: disabled ? theme.palette.text.secondary : ''
            }}
          >
            {t('employerspage.widget.requestpolicies.norequestpolicies')}
          </TypographySmall>
        )}

        {requestPolicies.allRequestPoliciesByEmployer.data.slice(0, 3).map(requestPolicy => (
          <WidgetRowItem key={requestPolicy.id}>
            <TypographySmall>
              {requestPolicy.userFriendlyName} ({requestPolicy.internalName})
            </TypographySmall>
            <IconButton
              sx={{ width: '1.5rem', height: '1.5rem' }}
              onClick={() =>
                navigate(
                  Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES_ID(
                    `${params.id}`,
                    employerId,
                    requestPolicy.id
                  )
                )
              }
            >
              <KeyboardArrowRightOutlinedIcon />
            </IconButton>
          </WidgetRowItem>
        ))}

        <Box>
          {requestPolicies.allRequestPoliciesByEmployer.data.length > 3 && (
            <Link
              onClick={() =>
                navigate(Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES(`${params.id}`, employerId))
              }
            >
              <TypographySmall>
                {t('employerspage.widget.requestpolicies.and')}{' '}
                {requestPolicies.allRequestPoliciesByEmployer.data.length - 3}{' '}
                {t('employerspage.widget.requestpolicies.more')}
              </TypographySmall>
            </Link>
          )}

          {requestPolicies.allRequestPoliciesByEmployer.data.length < 4 && !disabled && (
            <Link
              onClick={() =>
                openFlyIn({
                  content: <AddRequestPolicy employerId={employerId} />,
                  callbackAfterClose: () => refetchRequestPolicies
                })
              }
            >
              <TypographySmall>{t('employerspage.widget.requestpolicies.addrequestpolicy')}</TypographySmall>
            </Link>
          )}
        </Box>

        <ReadMoreText
          text={
            requestPolicies.allRequestPoliciesByEmployer.data.length === 0
              ? t('employerspage.widget.requestpolicies.info')
              : ''
          }
          readMoreLink={environment.docs.domainName + '/Epix/epix-configuration/provider/employer/requestpolicy'}
          alignRight={requestPolicies.allRequestPoliciesByEmployer.data.length > 0}
        />
      </>
    )
  )
}

export default EmployersWidgetRequestpolicies
