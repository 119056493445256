import {
  byteArrayToFile,
  ToBackendFormatedDate,
  useExportPayComponentSummaryForClosureProcessQuery
} from '@epix-web-apps/core'
import { DownloadButton } from '@epix-web-apps/ui'

export interface FinalCheckSalaryDownloadButtonProps {
  processId: string
  payrollCodeId: string
  payrollCode: string
  valueType: string
}

export function FinalCheckSalaryDownloadButton({
  processId,
  payrollCodeId,
  payrollCode,
  valueType
}: FinalCheckSalaryDownloadButtonProps) {
  const { isFetching, refetch: refetchExport } = useExportPayComponentSummaryForClosureProcessQuery(
    {
      payrollClosureProcessId: processId,
      payrollCodeId: payrollCodeId,
      valueTypePayComponent: valueType
    },
    {
      enabled: false
    }
  )

  async function download() {
    const result = await refetchExport()
    byteArrayToFile(
      result.data?.exportPayComponentSummaryForClosureProcess as unknown as Uint8Array,
      `final-check-salary-component-code-${payrollCode}-valuetype-${valueType}_${ToBackendFormatedDate(
        new Date()
      )}.xlsx`
    )
  }

  return <DownloadButton isFetching={isFetching} onClick={download} />
}
