import { DataTable, SortModel } from '../../data-table'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import { useTranslation } from 'react-i18next'
import { IconButton, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import {
  OrderDirection,
  useDeleteRequestPolicyFunctionMutation,
  useSuspenseGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery
} from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface ApproverRolesProps {
  requestPolicyId: string
  requestPolicyRight: string
}

export function RequestPolicyRoles({ requestPolicyId, requestPolicyRight }: ApproverRolesProps) {
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [clickedRequestPolicyFunction, setClickedRequestPolicyFunction] = useState<GridRowId>()
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const {
    data: approverRolesData,
    isLoading: isLoadingApproverRoles,
    refetch: refetchApproverRoles
  } = useSuspenseGetRequestPolicyFunctionByRequestPolicyIdAndRequestPolicyRightQuery({
    requestPolicyId: requestPolicyId || '',
    requestPolicyRightKey: requestPolicyRight
  })

  useEffect(() => {
    if (approverRolesData && approverRolesData?.requestPolicyFunctionByRequestPolicyId) {
      const approverRoles = approverRolesData.requestPolicyFunctionByRequestPolicyId
      setTableData(
        approverRoles.map(row => {
          return {
            id: row.id,
            role: row.functionDescription
          }
        })
      )
      setRowCount(approverRoles.length)
    }
  }, [approverRolesData])

  const rowActions = (rowParams: GridRenderCellParams) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedRequestPolicyFunction(rowParams.id)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const columns: GridColDef[] = [
    {
      field: 'role',
      headerName: t('requestpolicyroles.datatable.column.role'),
      flex: 1,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const deleteRequestPolicyPayrollCode = useDeleteRequestPolicyFunctionMutation()

  const handleDelete = async () => {
    await deleteRequestPolicyPayrollCode
      .mutateAsync({
        deleteRequestPolicyFunctionCommand: {
          requestPolicyFunctionId: clickedRequestPolicyFunction?.toString() || ''
        }
      })
      .then(() => refetchApproverRoles())
  }

  return (
    <>
      <DataTable
        hideFooter
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        isLoading={isLoadingApproverRoles}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => handleDelete()}>{t('requestpolicyroles.list.row.menu.remove')}</MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RequestPolicyRoles
