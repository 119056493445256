import { GraphqlError, useDeleteInjuryCommandMutation, useGetInjuryByIdQuery } from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, useFlyIn } from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

/* eslint-disable-next-line */
export interface RemoveInjuryProps {
  injuryId: string
}

export function RemoveInjury({ injuryId }: RemoveInjuryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const deleteMutation = useDeleteInjuryCommandMutation()
  const form = useForm()

  const { data: injuryById } = useGetInjuryByIdQuery({
    injuryId: injuryId || ''
  })

  const handleOnSubmit = async () => {
    await deleteMutation
      .mutateAsync({
        deleteInjuryCommand: {
          id: injuryId || ''
        }
      })
      .then(closeFlyIn)
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.deleteinjury.title')}</Typography>
      <Typography>
        {t('flyin.deleteinjury.subtitle')} {parseISO(injuryById?.injuryById?.injuryDate).toLocaleDateString()}
      </Typography>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={deleteMutation.isPending}
        buttonText={t('common.remove')}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default RemoveInjury
