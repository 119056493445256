import {
  GraphqlError,
  formTypeSelectOptions,
  useSuspenseGetAsbGroupContractDetailsQuery,
  useUpdateAsbGroupContractDetailContractInfoMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, object, string } from 'zod'

/* eslint-disable-next-line */
export interface EditAsbgroupContractInfoProps {
  contractId: string
}

export function EditAsbgroupContractInfo({ contractId }: EditAsbgroupContractInfoProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const { data: partnerSpecificDetails, refetch: refetchPartnerSpecificDetails } =
    useSuspenseGetAsbGroupContractDetailsQuery({
      contractId: contractId || ''
    })

  const {
    asbDisabilityTypeOptions,
    asbSocialSecurityContractTypeOptions,
    asbPpContractTypeOptions,
    asbGroupAlteredWorkingAbilityTypeOptions
  } = formTypeSelectOptions

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editASBGroupContractInfoSchema = object({
    ppContractTypeKey: string().optional().nullable(),
    socialSecurityContractTypeKey: string().optional().nullable(),
    alteredWorkingAbliityTypeKey: string().optional().nullable(),
    disablityTypeKey: string().optional().nullable()
  })

  type EditASBGroupContractInfoForm = TypeOf<typeof editASBGroupContractInfoSchema>

  const updateMutation = useUpdateAsbGroupContractDetailContractInfoMutation()

  const contractInfo = partnerSpecificDetails?.asbGroupContractDetailByContractId.asbGroupContractInfoModel

  const form = useForm<EditASBGroupContractInfoForm>({
    resolver: zodResolver(editASBGroupContractInfoSchema),
    defaultValues: {
      ppContractTypeKey: contractInfo?.ppContractType?.key,
      socialSecurityContractTypeKey: contractInfo?.socialSecurityContractType?.key,
      alteredWorkingAbliityTypeKey: contractInfo?.alteredWorkingAbilityType?.key,
      disablityTypeKey: contractInfo?.disabilityType?.key
    }
  })

  const handleOnSubmit = async (contractInfo: EditASBGroupContractInfoForm) => {
    await updateMutation
      .mutateAsync({
        asbGroupContractDetailContractInfoCommand: {
          id: partnerSpecificDetails?.asbGroupContractDetailByContractId.id || '',
          asbGroupPPContractTypeKey: contractInfo?.ppContractTypeKey,
          asbGroupSocialSecurityContractTypeKey: contractInfo?.socialSecurityContractTypeKey,
          asbGroupAlteredWorkingAbilityTypeKey: contractInfo?.alteredWorkingAbliityTypeKey,
          asbGroupDisabilityTypeKey: contractInfo?.disablityTypeKey
        }
      })
      .then(() => {
        refetchPartnerSpecificDetails()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }
  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editasbgroupsecurity.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="ppContractTypeKey"
          label={t('form.field.ppcontract')}
          options={asbPpContractTypeOptions}
        />
        <FormSelect
          sx={12}
          name="socialSecurityContractTypeKey"
          label={t('form.field.socialsecritycontract')}
          options={asbSocialSecurityContractTypeOptions}
        />
        <FormSelect
          sx={12}
          name="alteredWorkingAbliityTypeKey"
          label={t('form.field.alteredworkingability')}
          options={asbGroupAlteredWorkingAbilityTypeOptions}
        />
        <FormSelect
          sx={12}
          name="disablityTypeKey"
          label={t('form.field.disability')}
          options={asbDisabilityTypeOptions}
        />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}
export default EditAsbgroupContractInfo
