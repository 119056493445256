import {
  byteArrayToFile,
  DEFAULT_CURRENCY,
  formTypeSelectOptions,
  getPropertyName,
  useExportLabourCostCountryQuery,
  useExportLabourCostPeriodQuery,
  useExportLabourCostPersonQuery,
  useSuspenseGetLabourCostCountryQuery,
  useSuspenseGetMeQuery
} from '@epix-web-apps/core'
import {
  a11yProps,
  DetailPageLayout,
  DownloadButton,
  TabPanel,
  useFlyIn,
  useGlobalPersistedStore
} from '@epix-web-apps/ui'
import { Box, Button, Chip, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FilterLabourCostFlyin from '../../components/labour-cost-analytics-components/filter-labour-cost-flyin'
import LabourCostCountryChartComponent from '../../components/labour-cost-analytics-components/labour-cost-country-chart-component'
import LabourCostPeriodChartComponent from '../../components/labour-cost-analytics-components/labour-cost-period-chart-component'
import LabourCostPersonChartComponent from '../../components/labour-cost-analytics-components/labour-cost-person-chart-component'
import OnboardingLabourCosts from '../../components/onboarding-components/onboarding-labour-costs/onboarding-labour-costs'
import { SuspensePaper } from '../../components/suspense-paper'
import { useLabourCostAnalyticsPersistedStore } from '../../stores/labour-cost-analytics-store'

export interface LabourCostFilters {
  dateFrom: Date
  dateTo: Date
  currency: string | null
  gender: string | null
  functionArea: string | null
  functionLevel: string | null
  employer: string | null
  employeeType: string | null
  subGroupTypesFilter: string[]
}

enum LabourCostAnalyticsTabs {
  COUNTRY,
  PERIOD,
  PERSON
}

const exportFileNames = {
  country: 'labourcostcountry.csv',
  period: 'labourcostperiod.csv',
  person: 'labourcostperson.csv'
}

/* eslint-disable-next-line */
export interface LabourCostAnalyticsPageProps {}

export function LabourCostAnalyticsPage(props: LabourCostAnalyticsPageProps) {
  const [tabIndex, setTabIndex] = useState(LabourCostAnalyticsTabs.COUNTRY)
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()

  const store = useLabourCostAnalyticsPersistedStore()
  const { data: me } = useSuspenseGetMeQuery()

  const filters: LabourCostFilters = {
    dateFrom: store.dateFromFilter,
    dateTo: store.dateToFilter,
    currency: store.currencyToFilter,
    gender: store.genderFilter,
    functionArea: store.functionAreaFilter,
    functionLevel: store.functionLevelFilter,
    employer: store.employerFilter,
    employeeType: store.employeeTypeFilter,
    subGroupTypesFilter: store.subGroupTypesFilter
  }

  const {
    genderOptions,
    employeeOptions,
    functionAreaOptions,
    functionLevelOptions,
    currencyOptions,
    employerOptions
  } = formTypeSelectOptions
  const filterLabels = [
    genderOptions,
    functionAreaOptions,
    functionLevelOptions,
    employeeOptions,
    currencyOptions,
    employerOptions
  ].flat()

  function updateStore(filters: LabourCostFilters) {
    store.setDateFromFilter(filters.dateFrom)
    store.setDateToFilter(filters.dateTo)
    store.setCurrencyToFilter(filters.currency)
    store.setGenderFilter(filters.gender)
    store.setFunctionAreaFilter(filters.functionArea)
    store.setFunctionLevelFilter(filters.functionLevel)
    store.setEmployerFilter(filters.employer)
    store.setEmployeeTypeFilter(filters.employeeType)
    store.setSubGroupTypesFilter(filters.subGroupTypesFilter)
  }

  function handleFilterDelete(filterName: [string, string | null]) {
    updateStore({ ...filters, [filterName[0]]: null })
  }

  const calculatedDataFilterModel = {
    startDate: store.dateFromFilter,
    endDate: store.dateToFilter,
    currency: store.currencyToFilter ?? me?.me.currency ?? DEFAULT_CURRENCY,
    genderType: store.genderFilter,
    functionAreaType: store.functionAreaFilter,
    functionLevelType: store.functionLevelFilter,
    employerId: store.employerFilter,
    employeeType: store.employeeTypeFilter,
    subGroupTypes: store.subGroupTypesFilter
  }

  const { refetch: refetchExportLabourCostCountry, isFetching: isFetchingExportLabourCostCountry } =
    useExportLabourCostCountryQuery(
      { icps: icpFilters, calculatedDataFilterModel: calculatedDataFilterModel },
      { enabled: false }
    )

  const { refetch: refetchExportLabourCostPeriod, isFetching: isFetchingExportLabourCostPeriod } =
    useExportLabourCostPeriodQuery(
      { icps: icpFilters, calculatedDataFilterModel: calculatedDataFilterModel },
      { enabled: false }
    )

  const { refetch: refetchExportLabourCostPerson, isFetching: isFetchingExportLabourCostPerson } =
    useExportLabourCostPersonQuery(
      { icps: icpFilters, calculatedDataFilterModel: calculatedDataFilterModel },
      { enabled: false }
    )

  const isDownloading =
    isFetchingExportLabourCostCountry || isFetchingExportLabourCostPeriod || isFetchingExportLabourCostPerson

  function handleDownloadClick() {
    switch (tabIndex) {
      case LabourCostAnalyticsTabs.COUNTRY:
        refetchExportLabourCostCountry().then(r => {
          byteArrayToFile(r.data?.exportLabourCostPerCountry as unknown as Uint8Array, exportFileNames.country)
        })
        break
      case LabourCostAnalyticsTabs.PERIOD:
        refetchExportLabourCostPeriod().then(r => {
          byteArrayToFile(r.data?.exportLabourCostPerPeriod as unknown as Uint8Array, exportFileNames.period)
        })
        break
      case LabourCostAnalyticsTabs.PERSON:
        refetchExportLabourCostPerson().then(r => {
          byteArrayToFile(r.data?.exportLabourCostPerPerson as unknown as Uint8Array, exportFileNames.person)
        })
        break
    }
  }

  const { data: labourCost } = useSuspenseGetLabourCostCountryQuery({
    icps: icpFilters,
    calculatedDataFilterModel: {
      startDate: store.dateFromFilter,
      endDate: store.dateToFilter,
      currency: store.currencyToFilter ?? me?.me.currency ?? DEFAULT_CURRENCY,
      genderType: store.genderFilter,
      functionAreaType: store.functionAreaFilter,
      functionLevelType: store.functionLevelFilter,
      employerId: store.employerFilter,
      employeeType: store.employeeTypeFilter,
      subGroupTypes: store.subGroupTypesFilter
    }
  })

  const hasData = labourCost?.labourCostPerCountry.hasCalculatedData

  const hiddenChips = [
    getPropertyName(filters, f => f.dateFrom),
    getPropertyName(filters, f => f.dateTo),
    getPropertyName(filters, f => f.currency),
    getPropertyName(filters, f => f.subGroupTypesFilter)
  ]

  return (
    <DetailPageLayout>
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography ml={2} variant="h2">
            {t('homepage.labourcostanalystics')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography>
                {t('analytics.header.period')}:{' '}
                <strong>
                  {new Date(filters.dateFrom).toLocaleDateString()} - {new Date(filters.dateTo).toLocaleDateString()}
                </strong>
              </Typography>
              <Typography>
                {t('analytics.header.currency')}:{' '}
                <strong>
                  {filterLabels.find(f => f.id === filters.currency)?.label} ({filters.currency})
                </strong>
              </Typography>
              <Typography variant="description">{t('analytics.header.perioddisclaimer')}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                gap: 1,
                maxWidth: 400,
                flexWrap: 'wrap'
              }}
            >
              {Object.entries(filters).map(
                (entry: [string, string | null]) =>
                  entry[1] &&
                  !hiddenChips.includes(entry[0]) && (
                    <Chip
                      key={entry[0]}
                      size="small"
                      variant="outlined"
                      label={filterLabels.find(f => f.id === entry[1]!.toString())?.label}
                      onDelete={() => handleFilterDelete(entry)}
                    />
                  )
              )}
            </Box>
            <Button
              variant="outlined"
              onClick={() =>
                openFlyIn({
                  content: <FilterLabourCostFlyin filters={filters} updateStore={updateStore} />
                })
              }
            >
              {t('analytics.filters.title')}
            </Button>
          </Box>
        </Box>

        {!hasData ? (
          <OnboardingLabourCosts />
        ) : (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <Tabs value={tabIndex} onChange={(e, tabIndex) => setTabIndex(tabIndex)}>
                <Tab label={t('analytics.tab.country')} {...a11yProps(LabourCostAnalyticsTabs.COUNTRY)} />
                <Tab label={t('analytics.tab.period')} {...a11yProps(LabourCostAnalyticsTabs.PERIOD)} />
                <Tab label={t('analytics.tab.person')} {...a11yProps(LabourCostAnalyticsTabs.PERSON)} />
              </Tabs>

              <DownloadButton isFetching={isDownloading} onClick={handleDownloadClick} />
            </Box>
            <TabPanel value={tabIndex} index={LabourCostAnalyticsTabs.COUNTRY}>
              <SuspensePaper>
                <LabourCostCountryChartComponent />
              </SuspensePaper>
            </TabPanel>
            <TabPanel value={tabIndex} index={LabourCostAnalyticsTabs.PERIOD}>
              <SuspensePaper>
                <LabourCostPeriodChartComponent />
              </SuspensePaper>
            </TabPanel>
            <TabPanel value={tabIndex} index={LabourCostAnalyticsTabs.PERSON}>
              <SuspensePaper>
                <LabourCostPersonChartComponent />
              </SuspensePaper>
            </TabPanel>
          </>
        )}
      </Box>
    </DetailPageLayout>
  )
}

export default LabourCostAnalyticsPage
