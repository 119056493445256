import {
  CalendarDayEntryModel,
  DateRange,
  DEFAULT_LOCALE,
  TeamCalendarModel,
  TIME_FRAME_TYPE_KEYS,
  ToBackendFormatedDate,
  useGetTeamByIdForTeamStructureQuery,
  useGetTeamCalendarForMeForTeamStructureQuery,
  useNavigateWithParams,
  useRequiredParams,
  useSuspenseGetAllTeamsWithRoleForMeQuery,
  useSuspenseGetTeamsForMeForTeamStructureQuery
} from '@epix-web-apps/core'
import { EmptyState, HeaderTitleNavigation, useGlobalStore, useRouteDefinitions } from '@epix-web-apps/ui'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Box, Divider, IconButton, Link, Typography, useTheme } from '@mui/material'
import { eachDayOfInterval, endOfWeek, isWeekend, nextMonday, parseISO, previousMonday, startOfWeek } from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TypographySmall } from '../../../myepix/components/employer-components/employer-overview/employer-overview'

/* eslint-disable-next-line */
export interface TeamCalendarProps {}

export type TeamCalendarPathParams = {
  teamId: string
}

export function TeamCalendar(props: TeamCalendarProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { me } = useGlobalStore()

  const params = useRequiredParams<TeamCalendarPathParams>()
  const routes = useRouteDefinitions()
  const navigate = useNavigateWithParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const dateRange = getWeekForDate(searchParams.get('date'))

  const { data: getTeamsByPersonId } = useSuspenseGetTeamsForMeForTeamStructureQuery()
  const { data: getAllTeamsWithRole } = useSuspenseGetAllTeamsWithRoleForMeQuery()

  const teamsByPersonId = getTeamsByPersonId?.teamsForMeForTeamStructure ?? []
  const teamWithRole = getAllTeamsWithRole?.allTeamsWithRoleForMe ?? []
  const allTeams = [...new Set([...teamsByPersonId, ...teamWithRole])]

  const personHasRoleTowardsSelectedTeam = teamWithRole.find(x => x.id === params.teamId) !== undefined

  const { data: getTeamById } = useGetTeamByIdForTeamStructureQuery({ id: params.teamId })
  const { data: getTeamCalendarData } = useGetTeamCalendarForMeForTeamStructureQuery({
    teamId: params.teamId,
    startDate: ToBackendFormatedDate(dateRange.startDate),
    endDate: ToBackendFormatedDate(dateRange.endDate)
  })

  function handleNextPeriod() {
    searchParams.set('date', ToBackendFormatedDate(nextMonday(dateRange.startDate)))
    setSearchParams(searchParams)
  }

  function handlePreviousPeriod() {
    searchParams.set('date', ToBackendFormatedDate(previousMonday(dateRange.startDate)))
    setSearchParams(searchParams)
  }

  const toMonthString = useCallback(
    (date: Date, format: 'long' | 'short') => {
      return date.toLocaleString(me?.locale.locale ?? DEFAULT_LOCALE, { month: format })
    },
    [me]
  )

  if (allTeams.length === 0) {
    return (
      <Box>
        <HeaderTitleNavigation
          onBackClick={() => {
            searchParams.delete('teamId')
            navigate(routes.SelfService.ROOT)
          }}
          mobileSizeTitle
          title={t('selfservice.team-calendar.title')}
          showDivider={false}
        />
        <EmptyState title={t('selfservice.team-calendar.no-team')} />
      </Box>
    )
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <HeaderTitleNavigation
            onBackClick={() => {
              searchParams.delete('teamId')
              navigate(routes.SelfService.ROOT)
            }}
            mobileSizeTitle
            title={getTeamById?.teamByIdForTeamStructure.name}
            showDivider={false}
          />
        </Box>

        <IconButton
          size="small"
          onClick={_e => {
            navigate(routes.SelfService.TEAMS_ID_CALENDAR_FILTER(params.teamId))
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </Box>

      <Divider />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton size="small" sx={{ color: theme.palette.primary.main }} onClick={handlePreviousPeriod}>
          <KeyboardArrowLeft />
        </IconButton>

        <Typography fontSize={'1rem'} m={1} variant="h4">
          {dateRange.startDate.getMonth() !== dateRange.endDate.getMonth()
            ? `${toMonthString(dateRange.startDate, 'short')} - ${toMonthString(dateRange.endDate, 'short')}`
            : `${toMonthString(dateRange.startDate, 'long')}`}
        </Typography>

        <IconButton size="small" sx={{ color: theme.palette.primary.main }} onClick={handleNextPeriod}>
          <KeyboardArrowRight />
        </IconButton>
      </Box>

      <Divider />

      {getTeamCalendarData?.teamCalendarForMeForTeamStructure.length === 0 && (
        <EmptyState title={t('selfservice.team-calendar.noteammembers')} />
      )}

      {getTeamCalendarData?.teamCalendarForMeForTeamStructure.length !== 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, py: 1 }}>
          <HeaderRow dateRange={dateRange} />

          {getTeamCalendarData?.teamCalendarForMeForTeamStructure.map(person => (
            <PersonRow
              key={person.personId}
              teamId={params.teamId}
              person={person}
              dateRange={dateRange}
              hasRights={personHasRoleTowardsSelectedTeam}
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

function HeaderRow({ dateRange }: { dateRange: DateRange }) {
  const theme = useTheme()
  const { me } = useGlobalStore()

  return (
    <>
      <Box sx={{ display: 'flex', gap: 0.5 }}>
        <Box sx={{ flexBasis: '25%', textAlign: 'right', paddingRight: 2 }} />
        {eachDayOfInterval({ start: dateRange.startDate, end: dateRange.endDate }).map(date => (
          <Box
            key={ToBackendFormatedDate(date)}
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexBasis: 0,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TypographySmall sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
              {date.toLocaleString(me?.locale.locale ?? DEFAULT_LOCALE, { weekday: 'short' })}
            </TypographySmall>

            <Typography>{date.getDate()}</Typography>
          </Box>
        ))}
      </Box>
    </>
  )
}

function PersonRow({
  teamId,
  person,
  dateRange,
  hasRights
}: {
  teamId: string
  person: TeamCalendarModel
  dateRange: DateRange
  hasRights: boolean
}) {
  const navigate = useNavigateWithParams()
  const routes = useRouteDefinitions()
  const theme = useTheme()

  const days = useMemo(() => {
    const calendarDaysForDateMap = new Map<string, CalendarDayEntryModel[]>()

    const allCalendarDays = person.days.flatMap(day => day)
    allCalendarDays.forEach(cdm => {
      const calendarDaysForDate = calendarDaysForDateMap.get(cdm.date) ?? []
      calendarDaysForDateMap.set(cdm.date, [...calendarDaysForDate, ...cdm.calculatedEntries])
    })
    return calendarDaysForDateMap
  }, [dateRange, person])

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box sx={{ flexBasis: '25%', textAlign: 'right', paddingRight: 2 }}>
          {hasRights && (
            <Link
              color={theme.palette.text.primary}
              underline="always"
              onClick={() => navigate(routes.SelfService.PEOPLE_ID_CALENDAR(person.personId), { teamId: teamId })}
            >
              <TypographySmall>
                {person.personFirstName} {shortenName(person.personLastName)}
              </TypographySmall>
            </Link>
          )}
          {!hasRights && (
            <TypographySmall>
              {person.personFirstName} {person.personLastName[0]}.
            </TypographySmall>
          )}
        </Box>

        {eachDayOfInterval({ start: dateRange.startDate, end: dateRange.endDate }).map(date => {
          const entriesForDay = days.get(ToBackendFormatedDate(date)) ?? []
          const part1Entries = entriesForDay.filter(e => e.timeFrameType.key === TIME_FRAME_TYPE_KEYS.DAY_PART_1)
          const part2Entries = entriesForDay.filter(e => e.timeFrameType.key === TIME_FRAME_TYPE_KEYS.DAY_PART_2)
          const fullDayEntries = entriesForDay.filter(e => e.timeFrameType.key === TIME_FRAME_TYPE_KEYS.FULL_DAY)

          if (part1Entries.length !== 0 || part2Entries.length !== 0) {
            return (
              <Box
                key={date.toString()}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                  border: `1px solid ${theme.palette.divider}`,
                  height: 6
                }}
              >
                <Box sx={{ flexGrow: 1, backgroundColor: part1Entries[0]?.colourCodeHex }} />
                <Box sx={{ flexGrow: 1, backgroundColor: part2Entries[0]?.colourCodeHex }} />
              </Box>
            )
          } else {
            return (
              <Box
                key={date.toString()}
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexBasis: 0,
                  border: `1px solid ${fullDayEntries[0]?.colourCodeHex ?? theme.palette.divider}`,
                  height: 6
                }}
              >
                <Box
                  key={fullDayEntries[0]?.payrollCode}
                  sx={{
                    flexGrow: 1,
                    backgroundColor:
                      fullDayEntries[0]?.colourCodeHex ?? (isWeekend(date) ? theme.palette.grey[100] : 'inherit')
                  }}
                />
              </Box>
            )
          }
        })}
      </Box>
    </>
  )
}

function getWeekForDate(dateSearchParam: string | null): DateRange {
  const date = dateSearchParam !== null ? parseISO(dateSearchParam) : new Date()
  return { startDate: startOfWeek(date, { weekStartsOn: 1 }), endDate: endOfWeek(date, { weekStartsOn: 1 }) }
}

function shortenName(name: string) {
  const shortenedName = name.split(' ').map(n => n[0] + '.')
  return shortenedName
}
