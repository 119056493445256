import { DocumentModel, downloadFile, useGetDocumentBlobByIdForPersonQuery } from '@epix-web-apps/core'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { Box, CircularProgress, IconButton } from '@mui/material'

export interface DownloadDocumentProps {
  documentData: DocumentModel
}

export function DownloadDocument({ documentData }: DownloadDocumentProps) {
  const { isFetching, refetch } = useGetDocumentBlobByIdForPersonQuery({ id: documentData.id }, { enabled: false })

  function downloadDocument() {
    refetch().then(r => {
      if (r.data) {
        downloadFile(
          r.data.documentBlobByIdForPerson.blob,
          r.data.documentBlobByIdForPerson.name,
          r.data.documentBlobByIdForPerson.contentType
        )
      }
    })
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isFetching && <CircularProgress size="1.75rem" />}
      {!isFetching && (
        <IconButton size="small" onClick={() => downloadDocument()}>
          <DownloadOutlinedIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default DownloadDocument
