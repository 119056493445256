import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import { Box, IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { Trans, useTranslation } from 'react-i18next'
import { useSuspenseGetAsecContractDetailByContractIdQuery } from '@epix-web-apps/core'
import { EditAsecContractType } from './edit-asec-contract-type'
import { EditAsecSocialSecurity } from './edit-asec-social-security'

export interface EsAsecProps {
  contractId: string
}

export function EsAsec({ contractId }: EsAsecProps) {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()

  const { data: partnerContractDetailData } = useSuspenseGetAsecContractDetailByContractIdQuery({
    contractId: contractId
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('asecdetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsecSocialSecurity contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractDetailData?.asecContractDetailByContractId?.socialSecurityNumber && (
            <DetailBoxRow>
              <Typography>
                {t('asecdetailpage.socialsecurity.socialsecuritynumber')}:{' '}
                <span className="bold">
                  {' '}
                  {partnerContractDetailData?.asecContractDetailByContractId.socialSecurityNumber}
                </span>
              </Typography>
            </DetailBoxRow>
          )}

          {partnerContractDetailData?.asecContractDetailByContractId?.socialSecurityCategory && (
            <DetailBoxRow>
              <Typography>
                {t('asecdetailpage.socialsecurity.socialsecuritycategory')}:
                <span className="bold">
                  {' '}
                  {partnerContractDetailData?.asecContractDetailByContractId.socialSecurityCategory?.value}
                </span>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('asecdetailpage.contracttype.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditAsecContractType contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractDetailData?.asecContractDetailByContractId?.employmentStatus && (
            <DetailBoxRow>
              <Typography>
                {t('asecdetailpage.contracttype.employmentstatus')}:
                <span className="bold">
                  {' '}
                  {partnerContractDetailData?.asecContractDetailByContractId.employmentStatus?.value}
                </span>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.asecContractDetailByContractId?.employmentType && (
            <DetailBoxRow>
              <Typography>
                <Trans
                  i18nKey="asecdetailpage.contracttype.employmenttype"
                  values={{
                    employmenttype: partnerContractDetailData?.asecContractDetailByContractId.employmentType?.value
                  }}
                  components={{ bold: <span className="bold" style={{ textTransform: 'lowercase' }} /> }}
                />
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default EsAsec
