import {
  formTypeSelectOptions,
  ROLE,
  useCanAccess,
  useSuspenseGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeCalculatedDataMutation,
  VALUE_TYPE_KEYS
} from '@epix-web-apps/core'
import {
  CanView,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface AddEditCalculatedDataSettingsProps {
  employerId: string
  payrollCodeId: string
}

export function AddEditCalculatedDataSettings({ employerId, payrollCodeId }: AddEditCalculatedDataSettingsProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const addEditPayrollCodeSchema = object({
    valueType: string({
      required_error: t('form.validation.valuetyperequired'),
      invalid_type_error: t('form.validation.valuetyperequired')
    }).min(1, t('form.validation.valuetyperequired')),
    includeInImport: boolean(),
    calculatedDataConverterMapping: string().optional().nullable()
  })

  type CreateEditPayrollCodeForm = TypeOf<typeof addEditPayrollCodeSchema>
  const { allValueOptions } = formTypeSelectOptions

  const canAccessPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  const { data: getPayrollCodeById, refetch: refetchPayrollCodeById } = useSuspenseGetPayrollCodeByIdQuery({
    payrollCodeId: payrollCodeId
  })

  const form = useForm<CreateEditPayrollCodeForm>({
    resolver: zodResolver(addEditPayrollCodeSchema),
    defaultValues: {
      valueType: getPayrollCodeById?.payrollCodeById.valueType.key || VALUE_TYPE_KEYS.DEFAULT,
      includeInImport: getPayrollCodeById?.payrollCodeById.includeInImport,
      calculatedDataConverterMapping: getPayrollCodeById.payrollCodeById.calculatedDataConverterMapping
    }
  })

  const mutation = useUpdatePayrollCodeCalculatedDataMutation()

  function handleOnSubmit(form: CreateEditPayrollCodeForm) {
    if (!canAccessPayrollCodes) return
    mutation
      .mutateAsync({
        updatePayrollCodeCalculatedDataCommand: {
          id: payrollCodeId,
          employerId: employerId,
          valueTypeKey: form.valueType,
          includeInImport: form.includeInImport,
          calculatedDataConverterMapping: form.calculatedDataConverterMapping
        }
      })
      .then(() => {
        refetchPayrollCodeById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditcalculateddata.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <FormRadioGroup
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="valueType"
          label={`${t('form.field.valuetypecalculateddata')}`}
          options={allValueOptions}
        />

        <FormControlLabel
          name="importInCalculatedData"
          sx={{ pl: 2 }}
          checked={form.watch('includeInImport')}
          onChange={(_e, v) => {
            form.setValue('includeInImport', v)
          }}
          control={<Checkbox />}
          label={t('flyin.addeditcalculateddata.includeinimport')}
        />

        <FormInput
          sx={12}
          name="calculatedDataConverterMapping"
          label={t('flyin.addeditcalculateddata.calculateddataconvertermapping')}
        />
      </FormGridLayout>
      <FormErrorList />
      <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES]}>
        <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
      </CanView>
    </FormContainer>
  )
}

export default AddEditCalculatedDataSettings
