import { downloadFile, ExportType, ProcessingStatusType, useGetDocumentBlobByIdQuery } from '@epix-web-apps/core'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { CircularProgress, Link, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { ExportJobModelWithoutProcessingJobType } from '../../generic-steps'
import ExportJobApiListItem from '../export-job-api-list-item'
import ExportJobErrorListItem from '../export-job-error-list-item'

interface ExportJobListItemProps {
  exportJob: ExportJobModelWithoutProcessingJobType
  payrollProviderCode: string | undefined
  payrollProviderName: string | undefined
  showExportJobByDocumentName: boolean
}

function ExportJobListItem({
  exportJob,
  payrollProviderCode,
  payrollProviderName,
  showExportJobByDocumentName
}: ExportJobListItemProps) {
  const { t } = useTranslation()
  const theme = useTheme()

  const { refetch: refetchDocumentBlobById, isFetching } = useGetDocumentBlobByIdQuery(
    { id: exportJob.document?.id || '' },
    { enabled: false }
  )

  let downloadLinkText
  if (showExportJobByDocumentName) {
    downloadLinkText = (
      <Trans
        i18nKey="processpage.finishoverview.downloadlink.asicpspecificexport"
        values={{
          provider: payrollProviderCode,
          filename: exportJob.document?.name
        }}
      />
    )
  } else {
    switch (exportJob.exportType) {
      case ExportType.IcpSpecific:
        downloadLinkText = (
          <>
            <Trans
              i18nKey="processpage.finishoverview.downloadlink.asspecificexport"
              values={{ provider: payrollProviderCode }}
            />{' '}
            ({exportJob.document?.extension})
          </>
        )
        break
      case ExportType.IcpSpecificPaycomponent:
        downloadLinkText = (
          <>
            <Trans
              i18nKey="processpage.finishoverview.downloadlink.asspecificpaycomponentexport"
              values={{ provider: payrollProviderCode }}
            />{' '}
            ({exportJob.document?.extension})
          </>
        )
        break
      case ExportType.IcpSpecificCalendar:
        downloadLinkText = (
          <>
            <Trans
              i18nKey="processpage.finishoverview.downloadlink.asspecificcalendarexport"
              values={{ provider: payrollProviderCode }}
            />{' '}
            ({exportJob.document?.extension})
          </>
        )
        break
      case ExportType.General:
        downloadLinkText = `${t('processpage.finishoverview.downloadlink.asgenericexport')} (${
          exportJob.document?.extension
        })`
        break
      default:
        downloadLinkText = (
          <Trans
            i18nKey="processpage.finishoverview.downloadlink.asicpspecificexport"
            values={{
              provider: payrollProviderCode,
              filename: exportJob.document?.name
            }}
          />
        )
    }
  }

  return (
    <ListItem key={exportJob.id}>
      {exportJob.statusType !== ProcessingStatusType.Finished && (
        <>
          <ListItemIcon>
            <CircularProgress size="1.3rem" />
          </ListItemIcon>
          <ListItemText
            primary={
              <LoadingStateText exportJobType={exportJob.exportType} payrollProviderName={payrollProviderName} />
            }
            secondary={t('processpage.finishoverview.jobstates.keepwindowopen')}
          />
        </>
      )}
      {exportJob.statusType === ProcessingStatusType.Finished && (
        <>
          <ListItemIcon>
            {exportJob.success && exportJob.document?.name && <CloudDownloadOutlinedIcon />}
            {exportJob.success && !exportJob.document?.name && <CloudSyncOutlinedIcon />}
            {!exportJob.success && <WarningAmberOutlinedIcon color="error" />}
          </ListItemIcon>

          {exportJob.success && exportJob.document?.name && (
            <Link
              color={isFetching ? theme.palette.text.secondary : theme.palette.primary.main}
              onClick={() => {
                if (!isFetching) {
                  refetchDocumentBlobById().then(r => {
                    if (r.data) {
                      downloadFile(
                        r.data.documentBlobById.blob,
                        r.data.documentBlobById.name,
                        r.data.documentBlobById.contentType
                      )
                    }
                  })
                }
              }}
            >
              {downloadLinkText}
            </Link>
          )}
          {exportJob.success && !exportJob.document?.name && <ExportJobApiListItem exportJob={exportJob} />}
          {!exportJob.success && <ExportJobErrorListItem exportJob={exportJob} />}
        </>
      )}
    </ListItem>
  )
}

export default ExportJobListItem

function LoadingStateText({
  exportJobType,
  payrollProviderName
}: {
  exportJobType: ExportType
  payrollProviderName: string | undefined
}) {
  const { t } = useTranslation()
  switch (exportJobType) {
    case ExportType.IcpSpecific:
      return (
        <Trans i18nKey="processpage.finishoverview.loading.specific" values={{ providerName: payrollProviderName }} />
      )
    case ExportType.General:
      return t('processpage.finishoverview.loading.general')
    default:
      return t('processpage.finishoverview.jobstates.exporting')
  }
}
