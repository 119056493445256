import { HeaderTitleWithIcon, TabPanel, a11yProps } from '@epix-web-apps/ui'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import { Grid, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BartsToolbox } from '../../components/tenant-configuration-components/barts-toolbox.tsx/barts-toolbox'
import { TenantAdminsTable } from '../../components/tenant-configuration-components/tenant-admins-table/tenant-admins-table'
import { TenantFeaturesForm } from '../../components/tenant-configuration-components/tenant-features/tenant-features-form'
import { TenantImportSettings } from '../../components/tenant-configuration-components/tenant-import-settings'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface TenantConfigurationPageProps {}

enum TenantConfigurationTabs {
  Features,
  Admins,
  Import,
  BartsToolbox
}

export function TenantConfigurationPage(props: TenantConfigurationPageProps) {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState(TenantConfigurationTabs.Features)

  return (
    <>
      <HeaderTitleWithIcon icon={<ConstructionOutlinedIcon />} title={t('tenantconfigurationpage.title')} />

      <Tabs value={tabIndex} onChange={(e, tabIndex) => setTabIndex(tabIndex)}>
        <Tab
          label={t('tenantconfigurationpage.tenantfeatures.title')}
          {...a11yProps(TenantConfigurationTabs.Features)}
        />
        <Tab label={t('tenantconfigurationpage.tenantadmins.title')} {...a11yProps(TenantConfigurationTabs.Admins)} />
        <Tab label={t('tenantconfigurationpage.importsettings.title')} {...a11yProps(TenantConfigurationTabs.Import)} />
        <Tab
          label={t('tenantconfigurationpage.bartstoolbox.title')}
          {...a11yProps(TenantConfigurationTabs.BartsToolbox)}
        />
      </Tabs>

      <TabPanel value={tabIndex} index={TenantConfigurationTabs.Features}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <TenantFeaturesForm />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={TenantConfigurationTabs.Admins}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6} pb={4}>
            <TenantAdminsTable />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={TenantConfigurationTabs.Import}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6} pb={4}>
            <TenantImportSettings />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={tabIndex} index={TenantConfigurationTabs.BartsToolbox}>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6} pb={4}>
            <BartsToolbox />
          </Grid>
        </Grid>
      </TabPanel>
    </>
  )
}

export default TenantConfigurationPage
