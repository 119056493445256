import {
  useRequiredParams,
  useSuspenseGetEmployersForOverviewQuery,
  useSuspenseGetIcpByIdQuery
} from '@epix-web-apps/core'
import { Configuration, DetailPageBaseQueryParams, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Paper, Switch } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AddEmployer } from '../../components/employer-components/add-edit-employer/add-edit-employer'
import EmployerOverview from '../../components/employer-components/employer-overview'
import FilterButton from '../../components/filter-button'
import OnboardingEmployerLayout from '../../components/onboarding-components/onboarding-employer-layout'
import { SuspensePaper } from '../../components/suspense-paper'

/* eslint-disable-next-line */
export interface EmployersPageProps {}

export function EmployersPage(props: EmployersPageProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { openFlyIn } = useFlyIn()
  const { t } = useTranslation()
  const [filters, setFilters] = useState({
    showInactive: false
  })

  const { data: employersData, refetch: refetchAllEmployers } = useSuspenseGetEmployersForOverviewQuery({
    icpId: params.id,
    showInactive: filters.showInactive
  })

  const { data: getIcpById } = useSuspenseGetIcpByIdQuery({
    icpId: params.id
  })

  const handleLinkClick = () => {
    openFlyIn({
      content: <AddEmployer />,
      callbackAfterClose: () => refetchAllEmployers
    })
  }

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        p: [0, 1, 1, 1],
        mb: 3,
        border: 'none'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: [2]
        }}
      >
        <HeaderTitleNavigation backToLink={Configuration.ICP_PROVIDERS()} title={getIcpById?.icpById.payrollProvider} />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <FilterButton filters={filters}>
            <p>
              <Switch
                checked={filters.showInactive}
                onChange={e => setFilters({ ...filters, showInactive: e.target.checked })}
                aria-label={t('peoplepage.filters.showinactivepeople')}
              />
              {t('employerpage.filters.showinactiveemployers')}
            </p>
          </FilterButton>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEmployer />,
                callbackAfterClose: () => refetchAllEmployers
              })
            }
          >
            <AddIcon />
            {t('employerpage.button.addemployer')}
          </Button>
        </Box>
      </Box>
      <SuspensePaper sx={{ border: 'none', padding: 0 }}>
        {employersData?.employersForOverview && employersData.employersForOverview.length > 0 ? (
          <Box>
            {employersData?.employersForOverview.map(employer => (
              <EmployerOverview key={employer.id} employer={employer} refetchAllEmployers={refetchAllEmployers} />
            ))}
          </Box>
        ) : (
          <OnboardingEmployerLayout onLinkClick={handleLinkClick} />
        )}
      </SuspensePaper>
    </Paper>
  )
}

export default EmployersPage
