import {
  useGetRequestPolicyPayrollCodesQuery,
  useUpdateRequestPolicyPayrollCodeOrderMutation
} from '@epix-web-apps/core'
import { useFlyIn } from '@epix-web-apps/ui'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, MenuItem } from '@mui/material'
import { GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from '../../data-table'
import DropdownMenu from '../../dropdown-menu/dropdown-menu'
import { AddEditAutomatedPayrollCodes } from '../add-edit-automated-payrollcodes'
import { EditRequestPolicyPayrollcode } from '../add-edit-request-policy-payrollcode'
import RemoveRequestPolicyPayrollcode from '../remove-request-policy-payrollcode'

/* eslint-disable-next-line */
export interface RequestPolicyPayrollcodesProps {
  requestPolicyId: string
  employerId: string
}

export function RequestPolicyPayrollcodes({ requestPolicyId, employerId }: RequestPolicyPayrollcodesProps) {
  const { t } = useTranslation()
  const [rowCount, setRowCount] = useState(0)
  const [clickedPayrollCode, setClickedPayrollCode] = useState<string | null>(null)
  const [tableData, setTableData] = useState<GridRowsProp>()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { openFlyIn } = useFlyIn()

  const { data, isLoading, refetch } = useGetRequestPolicyPayrollCodesQuery({
    requestPolicyId: requestPolicyId
  })

  const rowActions = (rowId: string) => (
    <IconButton
      aria-label="row actions"
      aria-controls="menu-row"
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        setClickedPayrollCode(rowId)
        setAnchorEl(e.currentTarget)
      }}
    >
      <MoreHorizIcon />
    </IconButton>
  )

  const columns: GridColDef[] = [
    {
      field: 'description',
      headerName: t('requestpolicypayrollcode.datatable.column.description'),
      flex: 1,
      sortable: false
    },
    {
      field: 'orderNumber',
      headerName: t('requestpolicypayrollcode.datatable.column.ordernumber'),
      flex: 1,
      sortable: false
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowParams => rowActions(rowParams.row.id)
    }
  ]

  useEffect(() => {
    if (data && data?.allRequestPolicyPayrollCodes) {
      const payrollcodes = data.allRequestPolicyPayrollCodes
      setTableData(
        payrollcodes.map(row => {
          return {
            id: row.id,
            description: row.description,
            orderNumber: row.orderNumber
          }
        })
      )
      setRowCount(payrollcodes.length)
    }
  }, [data])

  const updateRequestPolicyPayrollCodeOrder = useUpdateRequestPolicyPayrollCodeOrderMutation()

  const handleOrderUp = async () => {
    await updateRequestPolicyPayrollCodeOrder
      .mutateAsync({
        updateRequestPolicyPayrollCodeOrderCommand: {
          requestPolicyId: requestPolicyId,
          requestPolicyPayrollCodeId: clickedPayrollCode?.toString() || '',
          increment: true
        }
      })
      .then(() => refetch())
  }

  const handleOrderDown = async () => {
    await updateRequestPolicyPayrollCodeOrder
      .mutateAsync({
        updateRequestPolicyPayrollCodeOrderCommand: {
          requestPolicyId: requestPolicyId,
          requestPolicyPayrollCodeId: clickedPayrollCode?.toString() || '',
          increment: false
        }
      })
      .then(() => refetch())
  }

  return (
    <>
      <DataTable hideFooter data={tableData} columns={columns} totalRowCount={rowCount} isLoading={isLoading} />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <EditRequestPolicyPayrollcode
                  requestPolicyId={requestPolicyId}
                  employerId={employerId}
                  requestPolicyPayrollCodeId={clickedPayrollCode?.toString() || ''}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('requestpolicypayrollcodepage.list.row.menu.update')}
        </MenuItem>

        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveRequestPolicyPayrollcode
                  requestPolicyId={requestPolicyId}
                  requestPolicyPayrollCodeId={clickedPayrollCode?.toString() || ''}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('requestpolicypayrollcodepage.list.row.menu.remove')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            if (clickedPayrollCode) {
              openFlyIn({
                content: (
                  <AddEditAutomatedPayrollCodes
                    requestPolicyId={requestPolicyId}
                    requestPolicyPayrollCodeId={clickedPayrollCode}
                  />
                )
              })
            }
          }}
        >
          {t('requestpolicypayrollcodepage.list.row.menu.manageautomatedpayrollcodes')}
        </MenuItem>

        <MenuItem onClick={() => handleOrderUp()}>{t('requestpolicypayrollcodepage.list.row.menu.up')}</MenuItem>

        <MenuItem onClick={() => handleOrderDown()}>{t('requestpolicypayrollcodepage.list.row.menu.down')}</MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RequestPolicyPayrollcodes
