import {
  GetFirstDayOfYear,
  GetLastDayOfYear,
  OrderDirection,
  ROLE,
  ShowCarsType,
  ToBackendFormatedDate,
  byteArrayToFile,
  useExportCompanyCarsQuery,
  useGetCompanyCarsQuery,
  useSuspenseGetMeQuery
} from '@epix-web-apps/core'
import { CanView, DownloadButton, LoadingOverlay, TabPanel, a11yProps, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddCompanyCar from '../../components/company-cars-components/company-car-overview/add-company-car'
import CompanyCarStatisticsFilters from '../../components/company-cars-components/company-car-overview/company-car-statistics-filters'
import CompanyCarsStatistics from '../../components/company-cars-components/company-car-overview/company-cars-statistics'
import CompanyCarsTabOverview from '../../components/company-cars-components/company-car-overview/company-cars-tab-overview'
import OnboardingCompanyCars from '../../components/onboarding-components/onboarding-company-cars'
import SettingsButton from '../../components/settings-button'
import { useCompanyCarsStatisticsPersistedStore } from '../../stores/company-cars-statistics-store'

export interface CompanyCarFilters {
  startDate: Date
  endDate: Date
  carCategory: string | null
  country: string | null
  motorType: string | null
  showZeroScale: boolean
  endsInPeriod: boolean
  showCars: ShowCarsType
}

/* eslint-disable-next-line */
export interface CompanyCarsPageProps {}

export function CompanyCarsPage(props: CompanyCarsPageProps) {
  const { data: me } = useSuspenseGetMeQuery()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const queryClient = useQueryClient()

  const [tabIndex, setTabIndex] = useState(0)

  const [triggerRefetch, setTriggerRefetch] = useState(false)
  const [shouldFetch, setShouldFetch] = useState(false)

  const store = useCompanyCarsStatisticsPersistedStore()

  const filters: CompanyCarFilters = {
    startDate: store.startDateFilter,
    endDate: store.endDateFilter,
    carCategory: store.carCategoryFilter,
    country: store.countryFilter,
    motorType: store.motorTypeFilter,
    showZeroScale: store.showZeroScale,
    endsInPeriod: store.endsInPeriodFilter,
    showCars: store.showCarsFilter
  }

  function updateStore(filters: CompanyCarFilters) {
    store.setStartDateFilter(filters.startDate ?? GetFirstDayOfYear(new Date()))
    store.setEndDateFilter(filters.endDate ?? GetLastDayOfYear(new Date()))
    store.setCarCategoryFilter(filters.carCategory)
    store.setCountryFilter(filters.country)
    store.setMotorTypeFilter(filters.motorType)
    store.setShowZeroScale(filters.showZeroScale)
    store.setEndsInPeriodFilter(filters.endsInPeriod)
    store.setShowCarsFilter(filters.showCars)
  }

  const exportQueryVariables = {
    startDate: store.startDateFilter,
    endDate: store.endDateFilter,
    companyCarFilterModel: {
      carCategoryId: store.carCategoryFilter || null,
      country: store.countryFilter || null,
      motorTypeKey: store.motorTypeFilter || null,
      endsInPeriod: store.endsInPeriodFilter,
      showCars: store.showCarsFilter
    }
  }

  const { data: exportCompanyCarsQuery, isFetching } = useExportCompanyCarsQuery(exportQueryVariables, {
    enabled: shouldFetch
  })

  useEffect(() => {
    if (exportCompanyCarsQuery && exportCompanyCarsQuery.exportCompanyCars) {
      byteArrayToFile(
        exportCompanyCarsQuery.exportCompanyCars as unknown as Uint8Array,
        `company-cars_${ToBackendFormatedDate(new Date())}.xlsx`
      )
      queryClient.removeQueries({ queryKey: useExportCompanyCarsQuery.getKey(exportQueryVariables) })
    }
    setShouldFetch(false)
  }, [shouldFetch, exportCompanyCarsQuery?.exportCompanyCars])

  const { data: companyCarsData, refetch: refetchAllCompanyCars } = useGetCompanyCarsQuery({
    offset: 0,
    limit: 1,
    orderDirection: OrderDirection.Asc
  })

  const checkData = companyCarsData?.companyCars.totalCount === 0

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 3
        }}
      >
        <Typography m={0} variant="h2">
          {t('companycarspage.title')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {tabIndex === 0 && (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <CanView roles={[ROLE.ADMIN]}>
                <SettingsButton
                  menuItems={[
                    {
                      label: t('companycarspage.settings.carcategories'),
                      to: '/configuration/car-categories'
                    }
                  ]}
                />
              </CanView>
            </Box>
          )}
          <Button
            variant="outlined"
            onClick={() =>
              openFlyIn({
                content: <CompanyCarStatisticsFilters filters={filters} updateStore={updateStore} tabIndex={tabIndex} />
              })
            }
          >
            {t('companycar-statistics.filters')}
          </Button>
          {tabIndex === 0 && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() =>
                openFlyIn({
                  content: <AddCompanyCar />,
                  callbackAfterClose: () => () => {
                    refetchAllCompanyCars()
                    setTriggerRefetch(true)
                  }
                })
              }
            >
              {t('companycarspage.button.addcompanycars')}
            </Button>
          )}
        </Box>
      </Box>

      {checkData ? (
        <OnboardingCompanyCars />
      ) : (
        <>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
              <Tab label={t('companycars.tab.overview')} {...a11yProps(0)} />
              <Tab label={t('companycars.tab.statistics')} {...a11yProps(1)} />
            </Tabs>
            <DownloadButton isFetching={isFetching} onClick={() => setShouldFetch(true)} />
          </Box>
          <TabPanel value={tabIndex} index={0}>
            <Suspense fallback={<LoadingOverlay />}>
              <CompanyCarsTabOverview triggerRefetch={triggerRefetch} setTriggerRefetch={setTriggerRefetch} />
            </Suspense>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <Suspense fallback={<LoadingOverlay />}>
              <CompanyCarsStatistics />
            </Suspense>
          </TabPanel>
        </>
      )}
    </Box>
  )
}

export default CompanyCarsPage
