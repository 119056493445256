import {
  GetInjuryByIdQuery,
  GraphqlError,
  useCreateInjuryCommandMutation,
  useSuspenseGetInjuryByIdQuery,
  useUpdateInjuryCommandMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { boolean, date, object, TypeOf } from 'zod'
import { ContractDetailPageParams } from '../../../pages/contracts-detail-page'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddEditInjuryProps {
  injuryId?: string
}

export interface EditInjuryProps {
  injuryId: string
}

export function AddInjury() {
  return AddEditInjury()
}

export function EditInjury({ injuryId }: EditInjuryProps) {
  const injuryQuery = useSuspenseGetInjuryByIdQuery({
    injuryId: injuryId
  })

  return AddEditInjury(injuryQuery)
}

export function AddEditInjury(injuryQuery?: UseSuspenseQueryResult<GetInjuryByIdQuery, unknown>) {
  const { t } = useTranslation()

  const addEditInjurySchema = object({
    injuryDate: date({
      required_error: t('form.validation.injurydaterequired'),
      invalid_type_error: t('form.validation.injurydaterequired')
    }),
    fatal: boolean()
  })

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  type AddEditInjuryForm = TypeOf<typeof addEditInjurySchema>
  const params = useParams<ContractDetailPageParams>()
  const { closeFlyIn } = useFlyIn()
  const personId = params.id || ''

  const injury = injuryQuery?.data

  const form = useForm<AddEditInjuryForm>({
    resolver: zodResolver(addEditInjurySchema),
    defaultValues: {
      injuryDate: parseISO(injury?.injuryById?.injuryDate),
      fatal: injury?.injuryById?.fatal ?? false
    }
  })

  const createMutation = useCreateInjuryCommandMutation()
  const updateMutation = useUpdateInjuryCommandMutation()

  const handleOnSubmit = async (newInjury: AddEditInjuryForm) => {
    if (!injury) {
      await createMutation
        .mutateAsync({
          createInjuryCommand: {
            injuryDate: newInjury.injuryDate,
            fatal: newInjury.fatal,
            personId: personId
          }
        })
        .then(() => {
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateInjuryCommand: {
            injuryId: injury.injuryById.id,
            injuryDate: newInjury.injuryDate,
            fatal: newInjury.fatal
          }
        })
        .then(() => {
          closeFlyIn()
          injuryQuery.refetch()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{injury ? t('flyin.editinjury.title') : t('flyin.addinjury.title')}</Typography>

      <FormGridLayout>
        <FormDatepicker sx={12} name="injuryDate" label={t('form.field.injury.injurydate')} />

        <FormSwitch sx={12} name="fatal" label={t('form.field.injury.fatal')} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditInjury
