import { useTranslation } from 'react-i18next'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'
import PiechartComponent from '../piechart-component'
import { PieData } from '../piechart-component/piechart-component'
import { useSuspenseGetFunctionLevelDistributionQuery } from '@epix-web-apps/core'

/* eslint-disable-next-line */
export interface FunctionLevelComponentProps {}

export function FunctionLevelComponent(props: FunctionLevelComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter } = useAnalyticsPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()

  const { data: functionLevelDistribution } = useSuspenseGetFunctionLevelDistributionQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })

  return (
    <PiechartComponent
      data={functionLevelDistribution?.functionLevelDistribution.entries.map(
        x => new PieData(x.functionLevelTypeValue, x.amount.toString(), x.functionLevelTypeValue + ': ' + x.amount)
      )}
      total={functionLevelDistribution?.functionLevelDistribution.total}
      name={t('analytics.pie.genderTotal')}
    />
  )
}

export default FunctionLevelComponent
