import {
  FormSelectOption,
  GetSmartCalculationRuleByIdQuery,
  numericString,
  OrderDirection,
  SmartCalculationRuleOperator,
  SmartCalculationRulePartFunction,
  useCreateCalendarDaysByPayrollCodesCalculationRuleMutation,
  useCreateCalendarHoursByPayrollCodesCalculationRuleMutation,
  useCreateFixedValueCalculationRuleMutation,
  useCreateInputCodeCalculationRuleMutation,
  useCreateIntermediateResultCalculationRuleMutation,
  useGetSmartInputCodesByHistoryIdQuery,
  useGetSmartOutputCodesByHistoryIdQuery,
  useSuspenseGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetSmartCalculationRuleByIdQuery,
  useUpdateCalendarDaysByPayrollCodesCalculationRuleMutation,
  useUpdateCalendarHoursByPayrollCodesCalculationRuleMutation,
  useUpdateFixedValueCalculationRuleMutation,
  useUpdateInputCodeCalculationRuleMutation,
  useUpdateIntermediateResultCalculationRuleMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { DeleteOutline } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { nativeEnum, number, object, string, TypeOf } from 'zod'
import { smartPayComponentTypeSelectOptions } from '../form-select-options'

interface AddCalculationRuleProps {
  smartComponentHistoryId: string
  smartOutputCodeId: string
  employerId: string
}

interface EditCalculationRuleProps {
  smartComponentHistoryId: string
  smartOutputCodeId: string
  smartCalculationRuleId: string
  employerId: string
}

export function AddCalculationRule({
  smartComponentHistoryId,
  smartOutputCodeId,
  employerId
}: AddCalculationRuleProps) {
  return (
    <AddEditCalculationRule
      smartComponentHistoryId={smartComponentHistoryId}
      smartOutputCodeId={smartOutputCodeId}
      employerId={employerId}
    />
  )
}

export function EditCalculationRule({
  smartComponentHistoryId,
  smartOutputCodeId,
  smartCalculationRuleId,
  employerId
}: EditCalculationRuleProps) {
  const getSmartCalculationRuleByIdQuery = useSuspenseGetSmartCalculationRuleByIdQuery({
    smartPayComponentHistoryId: smartComponentHistoryId,
    smartOutputCodeId: smartOutputCodeId,
    smartRuleId: smartCalculationRuleId
  })

  return (
    <AddEditCalculationRule
      smartComponentHistoryId={smartComponentHistoryId}
      smartOutputCodeId={smartOutputCodeId}
      employerId={employerId}
      getSmartCalculationRuleByIdQuery={getSmartCalculationRuleByIdQuery}
    />
  )
}

function AddEditCalculationRule({
  smartComponentHistoryId,
  smartOutputCodeId,
  employerId,
  getSmartCalculationRuleByIdQuery
}: {
  smartComponentHistoryId: string
  smartOutputCodeId: string
  employerId: string
  getSmartCalculationRuleByIdQuery?: UseSuspenseQueryResult<GetSmartCalculationRuleByIdQuery, unknown>
}) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { calculationRuleOperators, calculationRulePartFunctions } = smartPayComponentTypeSelectOptions

  const getSmartCalculationRuleById = getSmartCalculationRuleByIdQuery?.data

  const { data: inputCodes } = useGetSmartInputCodesByHistoryIdQuery({
    smartPayComponentHistoryId: smartComponentHistoryId
  })

  const { data: outputCodes } = useGetSmartOutputCodesByHistoryIdQuery({
    smartPayComponentHistoryId: smartComponentHistoryId,
    isIntermediateResult: true
  })

  const { data: payrollCodes } = useSuspenseGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId,
    payrollCodeFilterModel: { showCalendarCodes: true },
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc
  })

  const addEditPayrollCodeCalculationRule = object({
    calendarPayrollCodeId: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    })
  })

  const addEditCalculationRule = object({
    decimalValue: numericString(number().optional().nullable()),
    operator: nativeEnum(SmartCalculationRuleOperator),
    function: nativeEnum(SmartCalculationRulePartFunction),
    inputCodeId: string().optional().nullable(),
    outputCodeId: string().optional().nullable(),
    payrollCodes: addEditPayrollCodeCalculationRule.array()
  }).superRefine((data, ctx) => {
    switch (data.function) {
      case SmartCalculationRulePartFunction.FixedValue:
        if (!data.decimalValue) {
          ctx.addIssue({
            code: 'custom',
            message: t('form.validation.valuerequired'),
            path: ['decimalValue']
          })
        }
        break
      case SmartCalculationRulePartFunction.InputCode:
        if (!data.inputCodeId) {
          ctx.addIssue({
            code: 'custom',
            message: t('form.validation.valuerequired'),
            path: ['inputCodeId']
          })
        }
        break
      case SmartCalculationRulePartFunction.IntermediateResult:
        if (!data.outputCodeId) {
          ctx.addIssue({
            code: 'custom',
            message: t('form.validation.valuerequired'),
            path: ['outputCodeId']
          })
        }
        break
      case SmartCalculationRulePartFunction.CalendarDaysByPayrollCodes:
      case SmartCalculationRulePartFunction.CalendarHoursByPayrollCodes:
        if (data.payrollCodes.length === 0) {
          ctx.addIssue({
            code: 'custom',
            message: t('form.validation.payrollcoderequired'),
            path: ['payrollCodes']
          })
        }
        break
    }
  })

  type CreateEditCalculationRuleForm = TypeOf<typeof addEditCalculationRule>

  const form = useForm<CreateEditCalculationRuleForm>({
    resolver: zodResolver(addEditCalculationRule),
    defaultValues: {
      operator: getSmartCalculationRuleById?.smartCalculationRuleById?.ruleOperator ?? SmartCalculationRuleOperator.Sum,
      decimalValue: getSmartCalculationRuleById?.smartCalculationRuleById?.fixedValue,
      function:
        getSmartCalculationRuleById?.smartCalculationRuleById?.function ?? SmartCalculationRulePartFunction.FixedValue,
      inputCodeId: getSmartCalculationRuleById?.smartCalculationRuleById?.inputCode?.id,
      outputCodeId: getSmartCalculationRuleById?.smartCalculationRuleById?.outputCode?.id,
      payrollCodes: getSmartCalculationRuleById?.smartCalculationRuleById?.payrollCodes.map(p => ({
        calendarPayrollCodeId: p.id
      }))
    }
  })

  const createFixedValueRuleMutation = useCreateFixedValueCalculationRuleMutation()
  const createInputCodeRuleMutation = useCreateInputCodeCalculationRuleMutation()
  const createIntermediateResultRuleMutation = useCreateIntermediateResultCalculationRuleMutation()
  const createCalendarDaysByPayrollCodesResultRuleMutation =
    useCreateCalendarDaysByPayrollCodesCalculationRuleMutation()
  const createCalendarHoursByPayrollCodesResultRuleMutation =
    useCreateCalendarHoursByPayrollCodesCalculationRuleMutation()
  const updateFixedValueRuleMutation = useUpdateFixedValueCalculationRuleMutation()
  const updateInputCodeRuleMutation = useUpdateInputCodeCalculationRuleMutation()
  const updateIntermediateResultRuleMutation = useUpdateIntermediateResultCalculationRuleMutation()
  const updateCalendarDaysByPayrollCodesResultRuleMutation =
    useUpdateCalendarDaysByPayrollCodesCalculationRuleMutation()
  const updateCalendarHoursByPayrollCodesResultRuleMutation =
    useUpdateCalendarHoursByPayrollCodesCalculationRuleMutation()

  const handleOnSubmit = (newSmartOutputCode: CreateEditCalculationRuleForm) => {
    if (getSmartCalculationRuleById) {
      handleUpdate(newSmartOutputCode, getSmartCalculationRuleById.smartCalculationRuleById.id)
    } else {
      handleCreate(newSmartOutputCode)
    }
  }

  const handleUpdate = (newSmartOutputCode: CreateEditCalculationRuleForm, ruleId: string) => {
    if (getSmartCalculationRuleById) {
      switch (getSmartCalculationRuleById.smartCalculationRuleById.function) {
        case SmartCalculationRulePartFunction.FixedValue:
          updateFixedValueRuleMutation
            .mutateAsync({
              updateFixedValueCalculationRuleCommand: {
                smartPayComponentHistoryId: smartComponentHistoryId,
                smartOutputCodeId: smartOutputCodeId,
                smartRuleId: ruleId,
                fixedValue: newSmartOutputCode.decimalValue,
                operator: newSmartOutputCode.operator
              }
            })
            .then(() => {
              getSmartCalculationRuleByIdQuery.refetch()
              closeFlyIn()
            })
          break
        case SmartCalculationRulePartFunction.InputCode:
          updateInputCodeRuleMutation
            .mutateAsync({
              updateInputCodeCalculationRuleCommand: {
                smartPayComponentHistoryId: smartComponentHistoryId,
                smartOutputCodeId: smartOutputCodeId,
                smartRuleId: ruleId,
                operator: newSmartOutputCode.operator,
                inputCodeId: newSmartOutputCode.inputCodeId ?? ''
              }
            })
            .then(() => {
              getSmartCalculationRuleByIdQuery.refetch()
              closeFlyIn()
            })
          break
        case SmartCalculationRulePartFunction.IntermediateResult:
          updateIntermediateResultRuleMutation
            .mutateAsync({
              updateIntermediateResultCalculationRuleCommand: {
                smartPayComponentHistoryId: smartComponentHistoryId,
                smartOutputCodeId: smartOutputCodeId,
                smartRuleId: ruleId,
                operator: newSmartOutputCode.operator,
                outputCodeId: newSmartOutputCode.outputCodeId ?? ''
              }
            })
            .then(() => {
              getSmartCalculationRuleByIdQuery.refetch()
              closeFlyIn()
            })
          break
        case SmartCalculationRulePartFunction.CalendarDaysByPayrollCodes:
          updateCalendarDaysByPayrollCodesResultRuleMutation
            .mutateAsync({
              updateCalendarDaysByPayrollCodesCalculationRule: {
                smartPayComponentHistoryId: smartComponentHistoryId,
                smartOutputCodeId: smartOutputCodeId,
                smartRuleId: ruleId,
                operator: newSmartOutputCode.operator,
                payrollCodeIds: newSmartOutputCode.payrollCodes.map(p => p.calendarPayrollCodeId)
              }
            })
            .then(() => {
              getSmartCalculationRuleByIdQuery.refetch()
              closeFlyIn()
            })
          break
        case SmartCalculationRulePartFunction.CalendarHoursByPayrollCodes:
          updateCalendarHoursByPayrollCodesResultRuleMutation
            .mutateAsync({
              updateCalendarHoursByPayrollCodesCalculationRule: {
                smartPayComponentHistoryId: smartComponentHistoryId,
                smartOutputCodeId: smartOutputCodeId,
                smartRuleId: ruleId,
                operator: newSmartOutputCode.operator,
                payrollCodeIds: newSmartOutputCode.payrollCodes.map(p => p.calendarPayrollCodeId)
              }
            })
            .then(() => {
              getSmartCalculationRuleByIdQuery.refetch()
              closeFlyIn()
            })
          break
      }
    }
  }

  const handleCreate = (newSmartOutputCode: CreateEditCalculationRuleForm) => {
    switch (newSmartOutputCode.function) {
      case SmartCalculationRulePartFunction.FixedValue:
        createFixedValueRuleMutation
          .mutateAsync({
            createFixedValueCalculationRuleCommand: {
              smartPayComponentHistoryId: smartComponentHistoryId,
              smartOutputCodeId: smartOutputCodeId,
              decimalValue: newSmartOutputCode.decimalValue,
              operator: newSmartOutputCode.operator
            }
          })
          .then(() => {
            closeFlyIn()
          })
        break
      case SmartCalculationRulePartFunction.InputCode:
        createInputCodeRuleMutation
          .mutateAsync({
            createInputCodeCalculationRuleCommand: {
              smartPayComponentHistoryId: smartComponentHistoryId,
              smartOutputCodeId: smartOutputCodeId,
              operator: newSmartOutputCode.operator,
              inputCodeId: newSmartOutputCode.inputCodeId ?? ''
            }
          })
          .then(() => {
            closeFlyIn()
          })
        break
      case SmartCalculationRulePartFunction.IntermediateResult:
        createIntermediateResultRuleMutation
          .mutateAsync({
            createIntermediateResultCalculationRuleCommand: {
              smartPayComponentHistoryId: smartComponentHistoryId,
              smartOutputCodeId: smartOutputCodeId,
              operator: newSmartOutputCode.operator,
              outputCodeId: newSmartOutputCode.outputCodeId ?? ''
            }
          })
          .then(() => {
            closeFlyIn()
          })
        break
      case SmartCalculationRulePartFunction.CalendarDaysByPayrollCodes:
        createCalendarDaysByPayrollCodesResultRuleMutation
          .mutateAsync({
            createCalendarDaysByPayrollCodesCalculationRuleCommand: {
              smartPayComponentHistoryId: smartComponentHistoryId,
              smartOutputCodeId: smartOutputCodeId,
              operator: newSmartOutputCode.operator,
              payrollCodeIds: newSmartOutputCode.payrollCodes.map(p => p.calendarPayrollCodeId)
            }
          })
          .then(() => {
            closeFlyIn()
          })
        break
      case SmartCalculationRulePartFunction.CalendarHoursByPayrollCodes:
        createCalendarHoursByPayrollCodesResultRuleMutation
          .mutateAsync({
            createCalendarHoursByPayrollCodesCalculationRuleCommand: {
              smartPayComponentHistoryId: smartComponentHistoryId,
              smartOutputCodeId: smartOutputCodeId,
              operator: newSmartOutputCode.operator,
              payrollCodeIds: newSmartOutputCode.payrollCodes.map(p => p.calendarPayrollCodeId)
            }
          })
          .then(() => {
            closeFlyIn()
          })
        break
    }
  }

  const watchRuleFunction = form.watch('function')

  const payrollCodesArray = useFieldArray({ control: form.control, name: 'payrollCodes' })

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      {getSmartCalculationRuleByIdQuery ? (
        <Typography variant="h4">{t('flyin.addeditcalculationrule.addtitle')}</Typography>
      ) : (
        <Typography variant="h4">{t('flyin.addeditcalculationrule.edittitle')}</Typography>
      )}

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="operator"
          label={`${t('form.field.operator')} *`}
          options={calculationRuleOperators}
        />

        <FormSelect
          sx={12}
          name="function"
          label={`${t('form.field.rulefunction')} *`}
          disabled={!!getSmartCalculationRuleById}
          options={calculationRulePartFunctions}
        />

        {watchRuleFunction === SmartCalculationRulePartFunction.FixedValue && (
          <FormNumericInput sx={12} name="decimalValue" label={`${t('form.field.value')} *`} />
        )}
        {watchRuleFunction === SmartCalculationRulePartFunction.InputCode && (
          <FormSelect
            sx={12}
            name="inputCodeId"
            label={`${t('form.field.inputcode')} *`}
            options={inputCodes?.smartInputCodesByHistoryId.map(
              i => new FormSelectOption(i.id, `${i.payrollCode} - ${i.userFriendlyDescription}`)
            )}
          />
        )}
        {watchRuleFunction === SmartCalculationRulePartFunction.IntermediateResult && (
          <FormSelect
            sx={12}
            name="outputCodeId"
            label={`${t('form.field.outputcode')} *`}
            options={outputCodes?.smartOutputCodesByHistoryId.map(
              o => new FormSelectOption(o.id, `${o.code} - ${o.description}`)
            )}
          />
        )}

        {(watchRuleFunction === SmartCalculationRulePartFunction.CalendarDaysByPayrollCodes ||
          watchRuleFunction === SmartCalculationRulePartFunction.CalendarHoursByPayrollCodes) && (
          <>
            {payrollCodesArray.fields.map((payrollCodeEntry, index) => (
              <>
                <FormSelect
                  sx={10}
                  key={`payrollCode-${payrollCodeEntry.id}`}
                  name={`payrollCodes.${index}.calendarPayrollCodeId`}
                  label={`${t('form.field.payrollcode')} *`}
                  options={payrollCodes.allPayrollCodesByEmployerId.data.map(
                    p => new FormSelectOption(p.id, `${p.code} - ${p.userFriendlyDescription}`)
                  )}
                />
                {index !== 0 && (
                  <Grid item xs={2}>
                    <IconButton
                      size="small"
                      key={`remove-payrollcode-${payrollCodeEntry.id}`}
                      onClick={() => payrollCodesArray.remove(index)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                )}
              </>
            ))}
            <Grid item xs={12}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => payrollCodesArray.append({ calendarPayrollCodeId: undefined })}
              >
                {t('flyin.requestpolicy.automatedpayrollcodes.form.add')}
              </Button>
            </Grid>
          </>
        )}
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={
          createFixedValueRuleMutation.isPending ||
          createInputCodeRuleMutation.isPending ||
          createIntermediateResultRuleMutation.isPending ||
          createCalendarDaysByPayrollCodesResultRuleMutation.isPending ||
          createCalendarHoursByPayrollCodesResultRuleMutation.isPending ||
          updateFixedValueRuleMutation.isPending ||
          updateInputCodeRuleMutation.isPending ||
          updateIntermediateResultRuleMutation.isPending ||
          updateCalendarDaysByPayrollCodesResultRuleMutation.isPending ||
          updateCalendarDaysByPayrollCodesResultRuleMutation.isPending 
        }
        onCancel={closeFlyIn}
      />
    </FormContainer>
  )
}
export default AddEditCalculationRule
