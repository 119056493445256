import { typeToFormSelectOption } from '../models'
import {
  OrderDirection,
  useSuspenseGetAbsenceTypesQuery,
  useSuspenseGetAffectedContractsParameterTypesQuery,
  useSuspenseGetAllAsbGroupAlteredWorkingAbilityTypesQuery,
  useSuspenseGetAllAsbGroupContractClassificationTypesQuery,
  useSuspenseGetAllAsbGroupDisabilityTypesQuery,
  useSuspenseGetAllAsbGroupEducationTypesQuery,
  useSuspenseGetAllAsbGroupHealthInsuranceCompanyTypesQuery,
  useSuspenseGetAllAsbGroupPpContractTypesQuery,
  useSuspenseGetAllAsbGroupSocialSecurityContractTypesQuery,
  useSuspenseGetAllCarTypesQuery,
  useSuspenseGetAllChurchDenominationTypesQuery,
  useSuspenseGetAllCivilStateTypesQuery,
  useSuspenseGetAllContractDurationTypesQuery,
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetAllCurrenciesQuery,
  useSuspenseGetAllDocumentAccessLevelTypesQuery,
  useSuspenseGetAllEducationLevelTypesQuery,
  useSuspenseGetAllEmployeeTypesQuery,
  useSuspenseGetAllEmployersByIcpIdsQuery,
  useSuspenseGetAllEmploymentRelationshipsTypesQuery,
  useSuspenseGetAllEndReasonTypesQuery,
  useSuspenseGetAllFamilyRelationTypesQuery,
  useSuspenseGetAllFunctionAreaTypesQuery,
  useSuspenseGetAllFunctionLevelTypesQuery,
  useSuspenseGetAllFunctionsQuery,
  useSuspenseGetAllGenderTypesQuery,
  useSuspenseGetAllGeneralEducationLevelsTypesQuery,
  useSuspenseGetAllIcpsQuery,
  useSuspenseGetAllInsuranceStatusTypesQuery,
  useSuspenseGetAllLastNameTypesQuery,
  useSuspenseGetAllLocalesQuery,
  useSuspenseGetAllNationalitiesQuery,
  useSuspenseGetAllowedValueTypesQuery,
  useSuspenseGetAllParentStatusTypesQuery,
  useSuspenseGetAllPopulationTypesQuery,
  useSuspenseGetAllProductivityViewPeriodTypesQuery,
  useSuspenseGetAllProfessionalEducationLevelsTypesQuery,
  useSuspenseGetAllReasonSalaryChangeTypesQuery,
  useSuspenseGetAllTaxClassesTypesQuery,
  useSuspenseGetAllTitleTypesQuery,
  useSuspenseGetAutomatedPayrollCodeValidityTypesQuery,
  useSuspenseGetCancellationAllowedTypesQuery,
  useSuspenseGetCarBrandsQuery,
  useSuspenseGetCarCategoriesQuery,
  useSuspenseGetCarContractTypesQuery,
  useSuspenseGetCarUsageTypesQuery,
  useSuspenseGetEventTypesQuery,
  useSuspenseGetGroupTypesQuery,
  useSuspenseGetMotorTypesQuery,
  useSuspenseGetObfuscatedTypesQuery,
  useSuspenseGetPagedExternalCodesQuery,
  useSuspenseGetPayPeriodTypesQuery,
  useSuspenseGetRequestMinimumTypesQuery,
  useSuspenseGetRequestPolicyTimeTypesQuery,
  useSuspenseGetSalaryEntryTypesQuery,
  useSuspenseGetSubGroupTypesQuery,
  useSuspenseGetTimeFrameTypesQuery,
  useSuspenseGetTimeTypesQuery,
  useSuspenseGetUpdateOperationTypesQuery,
  useSuspenseGetValueTypesQuery,
  useSuspenseGetWorkPermitTypesQuery
} from '../services'
import { getFormSelectOptions } from '../utils'

/* prettier-ignore */
export const formTypeSelectOptions = {
  get absenceOptions() {
    return getFormSelectOptions(useSuspenseGetAbsenceTypesQuery, result => typeToFormSelectOption(result.absenceTypes))
  },
  get affectedContractsParameterTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAffectedContractsParameterTypesQuery, result => typeToFormSelectOption(result.affectedContractsParameterTypes))
  },
  get allowedValueOptions() {
    return getFormSelectOptions(useSuspenseGetAllowedValueTypesQuery, result => typeToFormSelectOption(result.allowedValueTypes))
  },
  get allValueOptions() {
    return getFormSelectOptions(useSuspenseGetValueTypesQuery, result => typeToFormSelectOption(result.valueTypes))
  },
  get asbGroupAlteredWorkingAbilityTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupAlteredWorkingAbilityTypesQuery, result => typeToFormSelectOption(result.allASBGroupAlteredWorkingAbilityTypes))
  },
  get asbContractClassificationTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupContractClassificationTypesQuery, result => typeToFormSelectOption(result.allASBGroupContractClassificationTypes))
  },
  get asbDisabilityTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupDisabilityTypesQuery, result => typeToFormSelectOption(result.allASBGroupDisabilityTypes))
  },
  get asbEducationTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupEducationTypesQuery, result => typeToFormSelectOption(result.allASBGroupEducationTypes))
  },
  get asbHealthInsuranceCompanyTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupHealthInsuranceCompanyTypesQuery, result => typeToFormSelectOption(result.allASBGroupHealthInsuranceCompanyTypes))
  },
  get asbPpContractTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupPpContractTypesQuery, result => typeToFormSelectOption(result.allASBGroupPPContractTypes))
  },
  get asbSocialSecurityContractTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllAsbGroupSocialSecurityContractTypesQuery, result => typeToFormSelectOption(result.allASBGroupSocialSecurityContractTypes))
  },
  get cancellationAllowedTypeOptions() {
    return getFormSelectOptions(useSuspenseGetCancellationAllowedTypesQuery, result => typeToFormSelectOption(result.cancellationAllowedTypes))
  },
  get carBrandOptions() {
    return getFormSelectOptions(useSuspenseGetCarBrandsQuery, result => typeToFormSelectOption(result.carBrands))
  },
  get carCategoryOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useSuspenseGetCarCategoriesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Value',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }), 
        result => typeToFormSelectOption(result.carCategories.data.map(r => ({ key: r.id, value: r.description, active: r.active }))))
  },
  get carContractTypeOptions() {
    return getFormSelectOptions(useSuspenseGetCarContractTypesQuery, result => typeToFormSelectOption(result.carContractTypes))
  },
  get carTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllCarTypesQuery, result => typeToFormSelectOption(result.carTypes))
  },
  get carUsageTypeOptions() {
    return getFormSelectOptions(useSuspenseGetCarUsageTypesQuery, result => typeToFormSelectOption(result.carUsageTypes))
  },
  get churchDenominationTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllChurchDenominationTypesQuery, result => typeToFormSelectOption(result.churchDenominationTypes))
  },
  get civilStateOptions() {
    return getFormSelectOptions(useSuspenseGetAllCivilStateTypesQuery, result => typeToFormSelectOption(result.civilStateTypes))
  },
  get contractDurationOptions() {
    return getFormSelectOptions(useSuspenseGetAllContractDurationTypesQuery, result => typeToFormSelectOption(result.contractDurationTypes))
  },
  get contractEndReasonOptions() {
    return getFormSelectOptions(useSuspenseGetAllEndReasonTypesQuery, result => typeToFormSelectOption(result.contractEndReasonTypes))
  },
  get countryOptions() {
    return getFormSelectOptions(useSuspenseGetAllCountriesQuery, result => typeToFormSelectOption(result.countries.map(r => ({ key: r.code, value: r.name }))))
  },
  get currencyOptions() {
    return getFormSelectOptions(useSuspenseGetAllCurrenciesQuery, result => typeToFormSelectOption(result.currencies.map(r => ({ key: r.currency, value: r.name }))))
  },
  get deductionGroupOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => useSuspenseGetGroupTypesQuery({ isDeduction: true, ...variables }, { ...options }),
      result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel))
    )
  },
  get documentAccessLevelTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllDocumentAccessLevelTypesQuery, result => typeToFormSelectOption(result.allDocumentAccessLevelTypes))
  },
  get earningGroupOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => useSuspenseGetGroupTypesQuery({ isDeduction: false, ...variables }, { ...options }),
      result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel))
    )
  },
  get educationLevelOptions() {
    return getFormSelectOptions(useSuspenseGetAllEducationLevelTypesQuery, result => typeToFormSelectOption(result.educationLevelTypes))
  },
  get employeeOptions() {
    return getFormSelectOptions(useSuspenseGetAllEmployeeTypesQuery, result => typeToFormSelectOption(result.employeeTypes))
  },
  get employerOptions() {
    return getFormSelectOptions(useSuspenseGetAllEmployersByIcpIdsQuery, result => typeToFormSelectOption(result.employersByIcpIds.map(r => ({ key: r.id, value: r.companyName }))))
  },
  get employmentRelationshipOptions() {
    return getFormSelectOptions(useSuspenseGetAllEmploymentRelationshipsTypesQuery, result => typeToFormSelectOption(result.employmentRelationshipsTypes))
  },
  get eventTypeOptions() {
    return getFormSelectOptions(useSuspenseGetEventTypesQuery, result => typeToFormSelectOption(result.eventTypes))
  },
  get externalCodeTypeOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useSuspenseGetPagedExternalCodesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Value',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }), 
        result => typeToFormSelectOption(result.pagedExternalCodes.data.map(r => ({ key: r.key, value: r.value }))))
  },
  get familyRelationOptions() {
    return getFormSelectOptions(useSuspenseGetAllFamilyRelationTypesQuery, result => typeToFormSelectOption(result.familyRelationTypes))
  },
  get functionAreaOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useSuspenseGetAllFunctionAreaTypesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'key',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.functionAreaTypes.data.map(r => ({ key: r.key, value: r.value, active: r.active })))
    )
  },
  get functionLevelOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useSuspenseGetAllFunctionLevelTypesQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'key',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.functionLevelTypes.data.map(r => ({ key: r.key, value: r.value, active: r.active })))
    )
  },
  get functionOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) => 
        useSuspenseGetAllFunctionsQuery({
          offset: 0,
          limit: -1,
          sortByProperty: 'Name',
          orderDirection: OrderDirection.Asc,
          ...variables
        },
        {...options}
    ), result => typeToFormSelectOption(result.allFunctions.data.map(r => ({ key: r.id, value: `${r.description} - ${r.code}` }))))
  },
  get genderOptions() {
    return getFormSelectOptions(useSuspenseGetAllGenderTypesQuery, result => typeToFormSelectOption(result.genderTypes))
  },
  get generalEducationLevelOptions() {
    return getFormSelectOptions(useSuspenseGetAllGeneralEducationLevelsTypesQuery, result => typeToFormSelectOption(result.generalEducationLevelTypes))
  },
  get groupOptions() {
    return getFormSelectOptions(useSuspenseGetGroupTypesQuery, result => typeToFormSelectOption(result.groupTypes.map(r => r.typeModel)))
  },
  get icpOptions() {
    return getFormSelectOptions(
      (variables?: any, options?: any) =>
        useSuspenseGetAllIcpsQuery(
          {
            offset: 0,
            limit: -1,
            sortByProperty: 'Code',
            orderDirection: OrderDirection.Asc,
            ...variables
          },
          { ...options }
        ),
      result => typeToFormSelectOption(result.icps.data.map(r => ({ key: r.id, value: `${r.payrollProvider} (${r.code})` })))
    )
  },
  get insuranceStatusOptions() {
    return getFormSelectOptions(useSuspenseGetAllInsuranceStatusTypesQuery, result => typeToFormSelectOption(result.insuranceStatusTypes))
  },
  get lastNameOptions() {
    return getFormSelectOptions(useSuspenseGetAllLastNameTypesQuery, result => typeToFormSelectOption(result.lastNameTypes))
  },
  get localeOptions() {
    return getFormSelectOptions(useSuspenseGetAllLocalesQuery, result => typeToFormSelectOption(result.locales.map(r => ({ key: r.locale, value: r.language }))))
  },
  get motorTypeOptions() {
    return getFormSelectOptions(useSuspenseGetMotorTypesQuery, result => typeToFormSelectOption(result.motorTypes))
  },
  get nationalityOptions() {
    return getFormSelectOptions(useSuspenseGetAllNationalitiesQuery, result => typeToFormSelectOption(result.nationalities.map(r => ({ key: r.code, value: r.name }))))
  },
  get obfuscatedOptions() {
    return getFormSelectOptions(useSuspenseGetObfuscatedTypesQuery, result => typeToFormSelectOption(result.obfuscatedTypes.map(r => ({ key: r.key, value: r.value }))))
  },
  get parentStatusOptions() {
    return getFormSelectOptions(useSuspenseGetAllParentStatusTypesQuery, result => typeToFormSelectOption(result.parentStatusTypes))
  },
  get populationOptions() {
    return getFormSelectOptions(useSuspenseGetAllPopulationTypesQuery, result => typeToFormSelectOption(result.populationTypes))
  },
  get productivityViewPeriodTypeOptions() {
    return getFormSelectOptions(useSuspenseGetAllProductivityViewPeriodTypesQuery, result => typeToFormSelectOption(result.allProductivityViewPeriodTypes))
  },
  get professionalEducationLevelOptions() {
    return getFormSelectOptions(useSuspenseGetAllProfessionalEducationLevelsTypesQuery, result => typeToFormSelectOption(result.professionalEducationLevelTypes))
  },
  get requestMinimumOptions() {
    return getFormSelectOptions(useSuspenseGetRequestMinimumTypesQuery, result => typeToFormSelectOption(result.requestMinimumTypes))
  },
  get requestPolicyTimeOptions() {
    return getFormSelectOptions(useSuspenseGetRequestPolicyTimeTypesQuery, result => typeToFormSelectOption(result.requestPolicyTimeTypes))
  },
  get requestPolicyAutomatedPayrollcodeOptions() {
    return getFormSelectOptions(useSuspenseGetAutomatedPayrollCodeValidityTypesQuery, result => typeToFormSelectOption(result.automatedPayrollCodeValidityTypes))
  },
  get salaryEntryTypeOptions() {
    return getFormSelectOptions(useSuspenseGetSalaryEntryTypesQuery, result => typeToFormSelectOption(result.allSalaryEntryTypes))
  },
  get salaryChangedReasonOptions() {
    return getFormSelectOptions(useSuspenseGetAllReasonSalaryChangeTypesQuery, result => typeToFormSelectOption(result.reasonSalaryChangeTypes))
  },
  get subGroupOptions() {
    return getFormSelectOptions(useSuspenseGetSubGroupTypesQuery, result => typeToFormSelectOption(result.subGroupTypes.map(r => ({ key: r.key, value: r.value }))))
  },
  get taxClassesOptions() {
    return getFormSelectOptions(useSuspenseGetAllTaxClassesTypesQuery, result => typeToFormSelectOption(result.taxClassTypes))
  },
  get timeFrameOptions() {
    return getFormSelectOptions(useSuspenseGetTimeFrameTypesQuery, result => typeToFormSelectOption(result.timeFrameTypes))
  },
  get timeOptions() {
    return getFormSelectOptions(useSuspenseGetTimeTypesQuery, result => typeToFormSelectOption(result.timeTypes))
  },
  get titleOptions() {
    return getFormSelectOptions(useSuspenseGetAllTitleTypesQuery, result => typeToFormSelectOption(result.titleTypes))
  },
  get updateOperationTypeOptions() {
    return getFormSelectOptions(useSuspenseGetUpdateOperationTypesQuery, result => typeToFormSelectOption(result.updateOperationTypes))
  },
  get workPermitOptions() {
    return getFormSelectOptions(useSuspenseGetWorkPermitTypesQuery, result => typeToFormSelectOption(result.workPermitTypes))
  },
  get payPeriodOptions() {
    return getFormSelectOptions(useSuspenseGetPayPeriodTypesQuery, result => typeToFormSelectOption(result.allPayPeriodTypes))
  }
}
