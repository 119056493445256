import {
  ImportProcessModel,
  ImportProcessStatus,
  useRequiredParams,
  useSuspenseGetImportProcessByIdQuery
} from '@epix-web-apps/core'
import { DetailPageBaseQueryParams, HeaderTitleNavigation, Import } from '@epix-web-apps/ui'
import { Box, Button, Grid, LinearProgress, Step, StepLabel, Stepper } from '@mui/material'
import { MouseEventHandler, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'
import {
  ConvertAndValidateStep,
  ImportFinishStep,
  ImportMultiStepFormRef,
  SelectDataTypeAndFileStep,
  SelectImportFormatStep
} from '../../components/import-components'
import { Steps, useImportDataStore } from '../../stores/import-data-store'

export function ImportDataPageWithDetail() {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const resetStore = useImportDataStore(state => state.reset)
  const setImportProcessToStore = useImportDataStore(state => state.setImportProcess)

  const { data: importProcessQueryData } = useSuspenseGetImportProcessByIdQuery({ importProcessId: params.id })

  useEffect(() => {
    setImportProcessToStore(importProcessQueryData.importProcessById)
    return () => resetStore()
  }, [importProcessQueryData.importProcessById])

  return <ImportDataPage importProcess={importProcessQueryData.importProcessById} />
}

export interface ImportDataPageProps {
  importProcess?: ImportProcessModel
}

function RenderStepContent({
  currentStep,
  multiStepFormRef
}: {
  currentStep: Steps
  multiStepFormRef: React.RefObject<ImportMultiStepFormRef>
}) {
  switch (currentStep) {
    case Steps.SelectDataTypeAndFile:
      return <SelectDataTypeAndFileStep ref={multiStepFormRef} />
    case Steps.SelectImportFormat:
      return <SelectImportFormatStep ref={multiStepFormRef} />
    case Steps.ConvertAndValidate:
      return <ConvertAndValidateStep ref={multiStepFormRef} />
    case Steps.Finish:
      return <ImportFinishStep />
    default:
      return <Navigate to={Import.IMPORT} replace />
  }
}

export function ImportDataPage({ importProcess }: ImportDataPageProps) {
  const { t } = useTranslation()

  const currentStep = useImportDataStore(state => state.currentStep)
  const isProcessing = useImportDataStore(state => state.isProcessingForm)
  const activeSteps = useImportDataStore(state => state.activeSteps)

  const steps = [
    {
      id: Steps.SelectDataTypeAndFile,
      label: t('configurationimport.steps.select-data-type-step.label')
    },
    {
      id: Steps.SelectImportFormat,
      label: t('configurationimport.steps.select-converter.label')
    },
    {
      id: Steps.ConvertAndValidate,
      label: t('configurationimport.steps.convert-and-validate.label')
    },
    {
      id: Steps.Finish,
      label: t('configurationimport.steps.finish.label')
    }
  ].filter(step => activeSteps.includes(step.id))

  const multiStepFormRef = useRef<ImportMultiStepFormRef>(null)

  const onNextClick: MouseEventHandler<HTMLButtonElement> = async () => {
    await multiStepFormRef.current?.handleNext()
  }

  const disableNext =
    importProcess != null &&
    importProcess.status !== ImportProcessStatus.Created &&
    importProcess.status !== ImportProcessStatus.ConverterNeeded

  return (
    <Box
      sx={{
        p: [3]
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: [2]
        }}
      >
        <HeaderTitleNavigation backToLink={Import.IMPORT} showDivider={false} title={t('configurationimport.title')} />

        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button type="button" onClick={onNextClick} disabled={disableNext || isProcessing} variant="contained">
            {t('common.next')}
            {isProcessing && (
              <LinearProgress
                color="primary"
                sx={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0.6
                }}
              />
            )}
          </Button>
        </Box>
      </Box>

      <Grid container sx={{ mt: 1, mb: 4 }}>
        <Grid item xs={12}>
          <Stepper activeStep={currentStep} orientation="horizontal" alternativeLabel>
            {steps.map(step => (
              <Step key={step.id}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>

      <Box sx={{ px: 6, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <RenderStepContent currentStep={currentStep} multiStepFormRef={multiStepFormRef} />
      </Box>
    </Box>
  )
}

export default ImportDataPage
