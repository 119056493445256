import {
  FormSelectOption,
  GraphqlError,
  formTypeSelectOptions,
  useSuspenseGetAllCountriesQuery,
  useSuspenseGetCompanyCarByIdQuery,
  useUpdateCompanyCarGeneralInformationMutation,
  useRequiredParams
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormSelect,
  useFlyIn,
  
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, date, object, string } from 'zod'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'

/* eslint-disable-next-line */
export interface EditCompanyCarGeneralInformationProps {}

export function EditCompanyCarGeneralInformation(props: EditCompanyCarGeneralInformationProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<CompanyCarDetailPageParams>()
  const { closeFlyIn } = useFlyIn()

  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const editCompanyCarGeneralInformationSchema = object({
    countryKey: string({
      required_error: t('form.validation.countryrequired'),
      invalid_type_error: t('form.validation.countryrequired')
    }),
    currency: string().optional().nullable(),
    licensePlate: string({
      required_error: t('form.validation.licenseplaterequired'),
      invalid_type_error: t('form.validation.licenseplaterequired')
    }).min(1, t('form.validation.licenseplaterequired')),
    registrationDate: date().optional().nullable(),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    carBrandKey: string({
      required_error: t('form.validation.carbrandrequired'),
      invalid_type_error: t('form.validation.carbrandrequired')
    }),
    model: string().optional().nullable(),
    carCategoryId: string().optional().nullable()
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type EditCompanyCarGeneralInformationForm = TypeOf<typeof editCompanyCarGeneralInformationSchema>

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useSuspenseGetCompanyCarByIdQuery({
    companyCarId: params.id
  })
  const car = getCompanyCarById?.companyCarById

  const { carBrandOptions, carCategoryOptions, currencyOptions } = formTypeSelectOptions

  const { data: countries } = useSuspenseGetAllCountriesQuery({
    hasCurrency: true
  })
  const countryOptions = countries?.countries.map(x => new FormSelectOption(x.code, x.name)) || []

  const mutation = useUpdateCompanyCarGeneralInformationMutation()

  const form = useForm<EditCompanyCarGeneralInformationForm>({
    resolver: zodResolver(editCompanyCarGeneralInformationSchema),
    defaultValues: {
      countryKey: car?.country?.code,
      currency: currencyOptions.find(cu => cu.id === car?.country?.currencyKey)?.label || '',
      licensePlate: car?.licensePlate,
      registrationDate: car?.registrationDate ? parseISO(car.registrationDate) : null,
      startDate: car?.startDate ? parseISO(car.startDate) : undefined,
      endDate: car?.endDate ? parseISO(car.endDate) : null,
      carBrandKey: car?.carBrandType.key,
      model: car?.model,
      carCategoryId: car?.carCategory?.id
    }
  })

  const handleOnSubmit = async (carGeneralInfo: EditCompanyCarGeneralInformationForm) => {
    await mutation
      .mutateAsync({
        updateCompanyCarGeneralInformationCommand: {
          id: params.id || '',
          countryKey: carGeneralInfo.countryKey && carGeneralInfo.countryKey,
          licensePlate: carGeneralInfo.licensePlate,
          registrationDate: carGeneralInfo.registrationDate,
          startDate: carGeneralInfo.startDate,
          endDate: carGeneralInfo.endDate,
          carBrandTypeKey: carGeneralInfo.carBrandKey && carGeneralInfo.carBrandKey,
          model: carGeneralInfo.model,
          carCategoryId: carGeneralInfo.carCategoryId && carGeneralInfo.carCategoryId
        }
      })
      .then(() => {
        refetchCompanyCarById()
        closeFlyIn()
      })
      .catch(e => setBackendErrors([e]))
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanycargeneralinformation.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={6}
          name="countryKey"
          label={`${t('form.field.country')} *`}
          options={countryOptions}
          onChange={(e, selectedOption) =>
            form.resetField('currency', {
              defaultValue:
                currencyOptions.find(
                  cu => cu.id === countries?.countries.find(co => co.code === selectedOption?.id)?.currencyKey
                )?.label || ''
            })
          }
        />

        <FormInput sx={6} name="currency" label={t('form.field.currency')} disabled={true} />

        <FormDatepicker sx={6} name="registrationDate" label={t('form.field.registrationdate')} />

        <FormInput sx={6} name="licensePlate" label={`${t('form.field.licenseplate')} *`} />

        <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')} *`} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} />

        <FormSelect sx={6} name="carBrandKey" label={`${t('form.field.carbrand')} *`} options={carBrandOptions} />

        <FormInput sx={6} name="model" label={t('form.field.model')} />

        <FormSelect sx={6} name="carCategoryId" label={t('form.field.carcategory')} options={carCategoryOptions} />
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditCompanyCarGeneralInformation
