import { Module } from '@epix-web-apps/core'
import { IsAuthorized, SelfService, useRouteDefinitions } from '@epix-web-apps/ui'
import { Navigate, Route } from 'react-router-dom'
import { AbsenceRequestsDetail } from './selfservice/components/absence-components/absence-requests-detail'
import { MyAbsenceRequests } from './selfservice/components/absence-components/my-absence-requests'
import { MyAbsenceRequestsDetail } from './selfservice/components/absence-components/my-absence-requests-detail/my-absence-requests-detail'
import { RequestAbsence } from './selfservice/components/absence-components/request-absence'
import { BalanceCalculationRequestPolicy } from './selfservice/components/balance-components/balance-calculation-request-policy'
import { BalanceOverview } from './selfservice/components/balance-components/balance-overview'
import { DayDetailCalendar } from './selfservice/components/calendar-components/day-detail-calendar'
import {
  ContractDocumentDetail,
  EmployerDocumentDetail
} from './selfservice/components/document-components/document-detail'
import { Documents } from './selfservice/components/document-components/documents'
import { TeamCalendarFilter } from './selfservice/components/team-calendar-components/team-calendar-filter'
import HomePage from './selfservice/pages/home-page/home-page'
import { PendingRequests } from './selfservice/pages/pending-requests'
import { PendingTasks } from './selfservice/pages/pending-tasks'
import ProfileSettingsPage from './selfservice/pages/profile-settings-page/profile-settings-page'
import { SpecificPersonCalendar } from './selfservice/pages/specific-person-calendar'
import { TeamCalendar } from './selfservice/pages/team-calendar/team-calendar'

export const useSelfServiceRouting = () => {
  const routes = useRouteDefinitions()
  return (
    <>
      <Route path="/" element={<Navigate to={SelfService.ROOT()} replace />} />
      <Route path={SelfService.ROOT()}>
        <Route index element={<HomePage />} />
        <Route path={SelfService.BALANCE_OVERVIEW()} index element={<BalanceOverview />} />
        <Route path={SelfService.BALANCE_CALCULATION_ID(':id')} element={<BalanceCalculationRequestPolicy />} />
        <Route path={SelfService.PROFILE_SETTINGS()} element={<ProfileSettingsPage />} />
        <Route path={SelfService.REQUEST_ABSENCE_PERSONID(':id')} element={<RequestAbsence />} />
        <Route path={SelfService.MY_REQUESTS()} element={<MyAbsenceRequests />} />
        <Route path={SelfService.MY_REQUESTS_ID(':id')} element={<MyAbsenceRequestsDetail />} />
        <Route path={routes.SelfService.PEOPLE_ID_CALENDAR(':personId')} element={<SpecificPersonCalendar />} />
        <Route path={routes.SelfService.PEOPLE_ID_CALENDAR_DATE(':personId', ':date')} element={<DayDetailCalendar />} />
        <Route path={routes.SelfService.TEAMS_ID_CALENDAR(':teamId')} element={<TeamCalendar />} />
        <Route path={routes.SelfService.TEAMS_ID_CALENDAR_FILTER(':teamId')} element={<TeamCalendarFilter />} />
        <Route path={SelfService.PENDING_REQUESTS()} element={<PendingRequests />} />
        <Route path={SelfService.ABSENCE_REQUEST_ID(':id')} element={<AbsenceRequestsDetail />} />
        <Route
          path={SelfService.DOCUMENTS()}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <Documents />
            </IsAuthorized>
          }
        />
        <Route
          path={SelfService.DOCUMENTS_CONTRACTID_DOCUMENTTYPEID(':contractid', ':documenttypeid')}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <ContractDocumentDetail />
            </IsAuthorized>
          }
        />
        <Route
          path={SelfService.DOCUMENTS_EMPLOYERID_DOCUMENTTYPEID(':employerid', ':documenttypeid')}
          element={
            <IsAuthorized module={Module.Selfservicedocuments}>
              <EmployerDocumentDetail />
            </IsAuthorized>
          }
        />
        <Route path={SelfService.PENDING_TASKS()} element={<PendingTasks />} />
      </Route>
    </>
  )
}
