import {
  GraphqlError,
  ImportConverterModelForConfiguration,
  useDisableImportConverterMutation,
  useEnableImportConverterMutation,
  useGetImportConvertersQuery
} from '@epix-web-apps/core'
import ErrorIcon from '@mui/icons-material/Error'
import { Box, Switch, Tooltip, Typography, useTheme } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable, DataTableCell } from '../../data-table'

function IsEnabledCell({ row }: { row: ImportConverterModelForConfiguration }) {
  const theme = useTheme()
  const [checkedValue, setCheckedValue] = useState(row.isEnabled)
  const [backendError, setBackendError] = useState<GraphqlError | undefined>(undefined)
  const enableMutation = useEnableImportConverterMutation()
  const disableMutation = useDisableImportConverterMutation()

  const onChange = (_: React.ChangeEvent<HTMLInputElement>, newCheckedState: boolean) => {
    setBackendError(undefined)

    if (newCheckedState) {
      enableMutation
        .mutateAsync({ enableImportConverterCommand: { importConverterKey: row.key } })
        .then(() => setCheckedValue(newCheckedState))
        .catch(e => setBackendError(e))
    } else {
      disableMutation
        .mutateAsync({ disableImportConverterCommand: { importConverterKey: row.key } })
        .then(() => setCheckedValue(newCheckedState))
        .catch(e => setBackendError(e))
    }
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <Switch
        checked={checkedValue}
        disabled={enableMutation.isPending || disableMutation.isPending}
        onChange={onChange}
      />
      {backendError && (
        <Tooltip title={backendError.message}>
          <Box sx={{ color: theme.palette.error.main }}>
            <ErrorIcon fontSize="small" />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

export function TenantImportSettings() {
  const { t } = useTranslation()

  const { isLoading, data } = useGetImportConvertersQuery()

  const columns: GridColDef<ImportConverterModelForConfiguration>[] = [
    {
      field: 'isEnabled',
      headerName: t('tenantconfigurationpage.importsettings.convertertable.isenabled'),
      sortable: false,
      renderCell: params => (
        <DataTableCell>
          <IsEnabledCell row={params.row} />
        </DataTableCell>
      )
    },
    {
      field: 'importJobTypeKey',
      headerName: t('tenantconfigurationpage.importsettings.convertertable.type'),
      flex: 1,
      sortable: false
    },
    {
      field: 'name',
      headerName: t('tenantconfigurationpage.importsettings.convertertable.name'),
      flex: 3,
      sortable: false
    }
  ]

  const importConverterData = data?.importConverters.map(converter => ({
    ...converter,
    id: converter.key
  }))

  return (
    <>
      <Typography variant="h3" color="textSecondary">
        {t('tenantconfigurationpage.importsettings.title')}
      </Typography>
      <DataTable data={importConverterData} columns={columns} isLoading={isLoading} hideFooter={true} />
    </>
  )
}
