import { useSuspenseGetContractDistributionQuery } from '@epix-web-apps/core'
import { useTranslation } from 'react-i18next'
import PiechartComponent from '../piechart-component'
import { PieData } from '../piechart-component/piechart-component'
import { useAnalyticsPersistedStore } from '../../../stores/analytics-store'
import { useGlobalPersistedStore } from '@epix-web-apps/ui'

/* eslint-disable-next-line */
export interface ContractCountryComponentProps {}

export function ContractCountryComponent(props: ContractCountryComponentProps) {
  const { t } = useTranslation()
  const { icpFilters } = useGlobalPersistedStore()
  const { dateFilter } = useAnalyticsPersistedStore()
  const { genderFilter } = useAnalyticsPersistedStore()
  const { functionAreaFilter } = useAnalyticsPersistedStore()
  const { functionLevelFilter } = useAnalyticsPersistedStore()
  const { employeeTypeFilter } = useAnalyticsPersistedStore()

  const { data: contractDistribution } = useSuspenseGetContractDistributionQuery({
    icps: icpFilters,
    selectedDate: dateFilter,
    filterModel: {
      genderType: genderFilter,
      functionAreaType: functionAreaFilter,
      functionLevelType: functionLevelFilter,
      employeeType: employeeTypeFilter
    }
  })
  return (
    <PiechartComponent
      data={contractDistribution?.contractsDistribution.entries.map(
        x => new PieData(x.countryCode, x.amount.toString(), x.countryName + ': ' + x.amount)
      )}
      total={contractDistribution?.contractsDistribution.total}
      name={t('analytics.pie.contractTotal')}
    />
  )
}

export default ContractCountryComponent
