import {
  GetCollectiveScheduleByIdQuery,
  useCreateCollectiveScheduleMutation,
  useSuspenseGetCollectiveScheduleByIdQuery,
  useSuspenseGetEmployerByIdQuery,
  useUpdateCollectiveScheduleMutation
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormErrorList, FormGridLayout, FormInput, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export interface AddCollectiveScheduleProps {
  employerId: string
}

export interface EditCollectiveScheduleProps extends AddCollectiveScheduleProps {
  employerId: string
  collectiveScheduleId: string
}

export function AddCollectiveSchedule({ employerId }: AddCollectiveScheduleProps) {
  return AddEditCollectiveSchedule(employerId)
}

export function EditCollectiveSchedule({ employerId, collectiveScheduleId }: EditCollectiveScheduleProps) {
  const getCollectiveScheduleByIdQuery = useSuspenseGetCollectiveScheduleByIdQuery({
    collectiveScheduleId: collectiveScheduleId
  })

  return AddEditCollectiveSchedule(employerId, getCollectiveScheduleByIdQuery)
}

function AddEditCollectiveSchedule(
  employerId: string,
  getCollectiveScheduleByIdQuery?: UseSuspenseQueryResult<GetCollectiveScheduleByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditCollectiveScheduleSchema = object({
    code: string({
      required_error: t('form.validation.collectiveschedulecoderequired'),
      invalid_type_error: t('form.validation.collectiveschedulecoderequired')
    }).min(1, t('form.validation.collectiveschedulecoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired'))
  })

  type CreateCollectiveScheduleForm = TypeOf<typeof AddEditCollectiveScheduleSchema>

  const getCollectiveScheduleById = getCollectiveScheduleByIdQuery?.data

  const form = useForm<CreateCollectiveScheduleForm>({
    resolver: zodResolver(AddEditCollectiveScheduleSchema),
    defaultValues: {
      code: getCollectiveScheduleById?.collectiveSchedule.code,
      description: getCollectiveScheduleById?.collectiveSchedule.description,
      employerId: employerId
    }
  })

  const createMutation = useCreateCollectiveScheduleMutation()
  const updateMutation = useUpdateCollectiveScheduleMutation()

  const handleOnSubmit = async (newCollectiveSchedule: CreateCollectiveScheduleForm) => {
    if (!getCollectiveScheduleById) {
      await createMutation
        .mutateAsync({
          createCollectiveScheduleCommand: {
            code: newCollectiveSchedule.code,
            description: newCollectiveSchedule.description,
            employerId: employerId || ''
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateCollectiveScheduleCommand: {
            collectiveScheduleId: getCollectiveScheduleById.collectiveSchedule.id,
            code: newCollectiveSchedule.code,
            description: newCollectiveSchedule.description
          }
        })
        .then(() => {
          getCollectiveScheduleByIdQuery.refetch()
          closeFlyIn()
        })
    }
  }

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const company = getEmployerById?.employerById.companyName

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {getCollectiveScheduleById ? t('flyin.collectiveschedule.edittitle') : t('flyin.collectiveschedule.addtitle')}
      </Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.collectiveschedule.subtitle')}: {company}
      </Typography>
      <FormGridLayout>
        <FormInput sx={12} name="code" label={`${t('form.field.collectiveschedulecode')}`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')}`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditCollectiveSchedule
