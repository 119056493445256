import { DOCUMENT_CONTENT_TYPES, useGetPersonAvatarByIdQuery, useUpdatePersonAvatarMutation } from '@epix-web-apps/core'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import PersonIcon from '@mui/icons-material/Person'
import { Avatar, Box, CircularProgress, IconButton, SxProps, styled, useTheme } from '@mui/material'
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'

const EditAvatarButton = styled(IconButton)(() => {
  const theme = useTheme()
  return {
    position: 'absolute',
    zIndex: '100',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    ':hover': {
      backgroundColor: theme.palette.grey[50]
    }
  }
})

/* eslint-disable-next-line */
export interface EpixAvatarProps {
  personId: string
  editable?: boolean
  size?: number
  sx?: SxProps
  children?: React.ReactNode
}

export function EpixAvatar({ personId, editable = false, size = 40, sx = {}, children }: EpixAvatarProps) {
  const [editAvatar, setEditAvatar] = useState<string>('')
  const avatarEditor = useRef<AvatarEditor>(null)
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDropAccepted: (e: any) => setEditAvatar(e[0]),
    accept: {
      [DOCUMENT_CONTENT_TYPES.JPEG]: [],
      [DOCUMENT_CONTENT_TYPES.PNG]: []
    }
  })

  const {
    data: getPersonAvatarById,
    isLoading: personIsLoading,
    refetch: refetchPerson
  } = useGetPersonAvatarByIdQuery(
    { personId: personId },
    {
      refetchOnMount: false,
      staleTime: Infinity
    }
  )

  const updatePersonAvatarMutation = useUpdatePersonAvatarMutation()

  const personInitials = useMemo<ReactNode>(() => {
    if (!personIsLoading) {
      if (getPersonAvatarById?.personAvatarById?.firstName && getPersonAvatarById?.personAvatarById?.lastName) {
        return `${getPersonAvatarById?.personAvatarById?.firstName
          .charAt(0)
          .toUpperCase()}${getPersonAvatarById?.personAvatarById?.lastName.charAt(0).toUpperCase()}`
      }
      return <PersonIcon />
    }
    return ''
  }, [
    getPersonAvatarById?.personAvatarById?.firstName,
    getPersonAvatarById?.personAvatarById?.lastName,
    personIsLoading
  ])

  const avatar = useMemo(() => {
    if (getPersonAvatarById?.personAvatarById.avatarFile) {
      const content = new Uint8Array(getPersonAvatarById.personAvatarById.avatarFile)
      return URL.createObjectURL(new Blob([content.buffer], { type: DOCUMENT_CONTENT_TYPES.PNG }))
    }
    return ''
  }, [getPersonAvatarById?.personAvatarById.avatarFile])

  const saveAvatar = useCallback(async () => {
    if (avatarEditor.current) {
      avatarEditor.current?.getImageScaledToCanvas().toBlob(blob => {
        const file = blob ? new File([blob], `${personId}`, { type: DOCUMENT_CONTENT_TYPES.PNG }) : null
        updatePersonAvatarMutation.mutateAsync({ personId: personId, file: file }).then(_ => {
          setEditAvatar('')
          refetchPerson()
        })
      }, 'image/png')
    }
  }, [avatarEditor, personId, refetchPerson, updatePersonAvatarMutation])

  const cancelAvatar = () => {
    setEditAvatar('')
  }

  return (
    <Box sx={sx}>
      {editAvatar && editable && (
        <Box sx={{ position: 'relative', cursor: 'pointer' }}>
          <AvatarEditor
            ref={avatarEditor}
            width={180}
            height={180}
            css={{
              borderRadius: '50%'
            }}
            image={editAvatar}
            borderRadius={100}
          />
          <EditAvatarButton sx={{ right: '5%', bottom: '10%' }} onClick={saveAvatar}>
            <CheckIcon fontSize="small" />
          </EditAvatarButton>
          <EditAvatarButton sx={{ left: '5%', bottom: '10%' }} onClick={cancelAvatar}>
            <ClearIcon fontSize="small" />
          </EditAvatarButton>
        </Box>
      )}
      {!editAvatar && editable && (
        <Box {...getRootProps({ refKey: 'ref' })} sx={{ position: 'relative', cursor: 'pointer' }}>
          <Avatar sx={editable ? { margin: '0 auto', width: '10rem', height: '10rem' } : {}} src={avatar}>
            {children ? children : personInitials}
          </Avatar>
          <input {...getInputProps()} />
          <EditAvatarButton sx={{ right: 0, bottom: '5%' }}>
            <EditIcon fontSize="small" />
          </EditAvatarButton>
        </Box>
      )}
      {!editable && (
        <Avatar
          sx={
            editable
              ? { margin: '0 auto', width: '10rem', height: '10rem' }
              : { fontSize: '0.75rem', width: size, height: size }
          }
          src={avatar}
        >
          {personIsLoading && <CircularProgress sx={{ position: 'absolute' }} color="inherit" />}
          {personInitials && children ? children : personInitials}
        </Avatar>
      )}
    </Box>
  )
}

export default EpixAvatar
