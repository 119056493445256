import {
  FormSelectOption,
  OrderDirection,
  useCreateCountryPolicyMutation,
  useGetAllCountryPoliciesQuery,
  useGetAllIcpsQuery
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface AddCountryPolicyProps {}

export function AddCountryPolicy(props: AddCountryPolicyProps) {
  const { t } = useTranslation()
  const params = useParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()

  const AddCountryPolicySchema = object({
    userId: string({
      required_error: t('form.validation.userrequired'),
      invalid_type_error: t('form.validation.userrequired')
    }).min(1, t('form.validation.userrequired')),
    icpId: string({
      required_error: t('form.validation.icprequired'),
      invalid_type_error: t('form.validation.icprequired')
    }).min(1, t('form.validation.icprequired'))
  })

  type CreateCountryPolicyForm = TypeOf<typeof AddCountryPolicySchema>

  const { data: icpsData } = useGetAllIcpsQuery({
    activeOnly: true,
    offset: 0,
    limit: -1,
    sortByProperty: 'Payrollprovider',
    orderDirection: OrderDirection.Asc
  })
  const { data: countryPoliciesData } = useGetAllCountryPoliciesQuery({
    userId: params.id || '',
    offset: 0,
    limit: -1,
    sortByProperty: 'id',
    orderDirection: OrderDirection.Asc
  })

  const form = useForm<CreateCountryPolicyForm>({
    defaultValues: {
      userId: params.id || '',
      icpId: undefined
    }
  })

  const createMutation = useCreateCountryPolicyMutation()

  const handleOnSubmit = async (newCountryPolicy: CreateCountryPolicyForm) => {
    if (
      newCountryPolicy.icpId !==
      countryPoliciesData?.allCountryPolicies.data.find(c => c.icpId.includes(newCountryPolicy.icpId))?.icpId
    ) {
      await createMutation
        .mutateAsync({
          createCountryPolicyCommand: {
            userId: params.id || '',
            icpId: newCountryPolicy.icpId
          }
        })
        .then(closeFlyIn)
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addcountrypolicy.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="icpId"
          label={`${t('form.field.icp')}`}
          options={icpsData?.icps.data.map(x => new FormSelectOption(x.id, x.payrollProvider + ' (' + x.country + ')'))}
        />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons isMutating={createMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default AddCountryPolicy
