import {
  FormSelectOption,
  GetRequestPolicyContractByIdQuery,
  GraphqlError,
  useCreateRequestPolicyContractMutation,
  useGetRequestPolicyContractsByContractIdQuery,
  useSuspenseGetAllRequestPoliciesByEmployerIdQuery,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetRequestPolicyContractByIdQuery,
  useUpdateRequestPolicyContractMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, date, object, string } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddRequestPolicyContractProps {
  contractId: string
}

export interface EditRequestPolicyContractProps extends AddRequestPolicyContractProps {
  requestPolicyContractId: string
}

export function AddRequestPolicyContract({ contractId }: AddRequestPolicyContractProps) {
  return AddEditRequestPolicyContract(contractId)
}

export function EditRequestPolicyContract({ contractId, requestPolicyContractId }: EditRequestPolicyContractProps) {
  const getRequestPolicyContractByIdQuery = useSuspenseGetRequestPolicyContractByIdQuery({
    id: requestPolicyContractId
  })

  return AddEditRequestPolicyContract(contractId, getRequestPolicyContractByIdQuery)
}

function AddEditRequestPolicyContract(
  contractId: string,
  getRequestPolicyContractByIdQuery?: UseSuspenseQueryResult<GetRequestPolicyContractByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  const { data: getAllRequestPoliciesByEmployerId } = useSuspenseGetAllRequestPoliciesByEmployerIdQuery({
    offset: 0,
    limit: -1,
    employerId: getContractById.contractById.employerId ?? '' // bad backend model. a contract always has an employer
  })

  const { refetch: refetchRequestPolicies } = useGetRequestPolicyContractsByContractIdQuery({
    contractId: contractId
  })

  const getRequestPolicyContractById = getRequestPolicyContractByIdQuery?.data

  const addEditRequestPolicyContractSchema = object({
    requestPolicyId: string({
      required_error: t('form.validation.requestpolicyrequired'),
      invalid_type_error: t('form.validation.requestpolicyrequired')
    }),
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    balanceShown: boolean(),
    endDate: date().optional().nullable()
  }).refine(data => (data.endDate ? data.endDate >= data.startDate : !data.endDate), {
    message: t('form.validation.enddateafterstartdate'),
    path: ['endDate']
  })

  type AddEditRequestPolicyContractForm = TypeOf<typeof addEditRequestPolicyContractSchema>

  const form = useForm<AddEditRequestPolicyContractForm>({
    resolver: zodResolver(addEditRequestPolicyContractSchema),
    defaultValues: {
      requestPolicyId: getRequestPolicyContractById?.requestPolicyContractById.requestPolicyId,
      startDate: parseISO(getRequestPolicyContractById?.requestPolicyContractById.startDate),
      endDate: getRequestPolicyContractById?.requestPolicyContractById.endDate
        ? parseISO(getRequestPolicyContractById?.requestPolicyContractById.endDate)
        : null,
      balanceShown: getRequestPolicyContractById?.requestPolicyContractById.balanceShown
    }
  })

  const createMutation = useCreateRequestPolicyContractMutation()
  const updateMutation = useUpdateRequestPolicyContractMutation()

  const handleOnSubmit = async (newRequestPolicyContract: AddEditRequestPolicyContractForm) => {
    if (!getRequestPolicyContractById) {
      await createMutation
        .mutateAsync({
          createRequestPolicyContractCommand: {
            contractId: contractId,
            requestPolicyId: newRequestPolicyContract.requestPolicyId,
            startDate: newRequestPolicyContract.startDate,
            endDate: newRequestPolicyContract.endDate ? newRequestPolicyContract.endDate : null,
            balanceShown: newRequestPolicyContract.balanceShown
          }
        })
        .then(() => {
          closeFlyIn()
          refetchRequestPolicies()
        })
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateRequestPolicyContractCommand: {
            requestPolicyContractId: getRequestPolicyContractById.requestPolicyContractById.id,
            requestPolicyId: newRequestPolicyContract.requestPolicyId,
            startDate: newRequestPolicyContract.startDate,
            endDate: newRequestPolicyContract.endDate ? newRequestPolicyContract.endDate : null,
            balanceShown: newRequestPolicyContract.balanceShown
          }
        })
        .then(() => {
          closeFlyIn()
          refetchRequestPolicies()
          getRequestPolicyContractByIdQuery.refetch()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.requestpolicycontract.title')}</Typography>
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="requestPolicyId"
          options={getAllRequestPoliciesByEmployerId?.allRequestPoliciesByEmployer.data.map(
            x => new FormSelectOption(x.id, x.internalName)
          )}
          label={`${t('form.field.requestpolicy')} *`}
        />

        <FormDatepicker sx={6} name="startDate" label={`${t('form.field.startdate')} *`} />

        <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} />

        <FormSwitch sx={12} name="balanceShown" label={`${t('form.field.balanceselfservice')}`} />
      </FormGridLayout>
      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditRequestPolicyContract
