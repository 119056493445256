import { useGetTopValidationsPerPersonQuery, useGetValidationErrorsAndWarningsCountQuery } from '@epix-web-apps/core'
import { People, ValidationErrors, useGlobalPersistedStore } from '@epix-web-apps/ui'
import ErrorIcon from '@mui/icons-material/Error'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import MenuIcon from '@mui/icons-material/Menu'
import WarningIcon from '@mui/icons-material/Warning'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Button, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IcpCodeBadge } from '../../icp-components'
import { LoadingPaper } from '../../suspense-paper'

/* eslint-disable-next-line */
export interface ValidationsToSolveProps {}

export function ValidationsToSolve(props: ValidationsToSolveProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { icpFilters } = useGlobalPersistedStore()

  const { data: getValidations, isLoading: isLoadingValidations } = useGetTopValidationsPerPersonQuery({
    icps: icpFilters,
    limit: 3,
    showHasSeen: false
  })

  const { data: errorsAndWarnings, isLoading: isLoadingCount } = useGetValidationErrorsAndWarningsCountQuery({
    icps: icpFilters
  })

  const errors = errorsAndWarnings?.validationErrorsAndWarningsCount.errors
  const warnings = errorsAndWarnings?.validationErrorsAndWarningsCount.warnings

  function goToPersonDetailsPage(personId: string) {
    navigate(People.PEOPLE_ID_DETAILS(personId))
  }

  if (getValidations && getValidations.topValidationsPerPerson.length === 0) {
    return null
  }

  return (
    <LoadingPaper isLoading={isLoadingValidations || isLoadingCount}>
      <Box
        sx={{
          display: 'flex',
          mx: 1,
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography sx={{ justifyContent: 'center' }} variant="h4">
          {t('homepage.validationstosolve')}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {warnings !== undefined && errors !== undefined && (
            <>
              {warnings !== 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    color: theme.palette.warning.main,
                    alignItems: 'center',
                    height: '1.4rem'
                  }}
                >
                  <WarningIcon fontSize="small" />
                  <Typography sx={{ fontSize: '0.8rem', color: theme.palette.warning.main }}>{warnings}</Typography>
                </Box>
              )}
              {errors !== 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 0.5,
                    color: theme.palette.error.main,
                    alignItems: 'center',
                    height: '1.4rem'
                  }}
                >
                  <ErrorIcon fontSize="small" />
                  <Typography sx={{ fontSize: '0.8rem', color: theme.palette.error.main }}>{errors}</Typography>
                </Box>
              )}
            </>
          )}

          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              navigate(ValidationErrors.VALIDATION_ERROR())
            }}
          >
            <MenuIcon />
            {t('homepage.validations.viewdetails')}
          </Button>
        </Box>
      </Box>
      <Box>
        <List>
          {getValidations?.topValidationsPerPerson.map((value, index) => (
            <ListItem
              key={value.personId}
              sx={{
                m: 0,
                p: 0,
                color: theme.palette.text.primary,
                borderBottom: '1px solid',
                borderTop: index === 0 ? '1px solid' : '',
                borderColor: theme.palette.primary.light
              }}
            >
              <ListItemButton
                sx={{ p: 0.5 }}
                onClick={() => {
                  goToPersonDetailsPage(value.personId)
                }}
              >
                <ListItemText sx={{ flex: 1 }} primary={` ${value.displayName}`} />
                <ListItemText sx={{ flex: 1 }}>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {value.errorCount > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          color: theme.palette.error.light
                        }}
                      >
                        <ErrorOutlineOutlinedIcon fontSize="small" />
                        <Typography>{value.errorCount}</Typography>
                      </Box>
                    )}
                    {value.warningCount > 0 && (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          color: theme.palette.warning.light
                        }}
                      >
                        <WarningAmberOutlinedIcon fontSize="small" />
                        <Typography>{value.warningCount}</Typography>{' '}
                      </Box>
                    )}
                  </Box>
                </ListItemText>
                <ListItemText sx={{ flex: 0.25 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'end', gap: 1 }}>
                    {value.countryCodeKeys &&
                      value.countryCodeKeys.map((c, index) => c !== null && <IcpCodeBadge key={index} icpCode={c} />)}
                  </Box>
                </ListItemText>
                <KeyboardArrowRightIcon sx={{ flex: 0.1, color: theme.palette.text.secondary }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </LoadingPaper>
  )
}

export default ValidationsToSolve
