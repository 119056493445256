import {
  CollectiveScheduleDayModel,
  FormSelectOption,
  formTypeSelectOptions,
  OrderDirection,
  TIME_FRAME_TYPE_KEYS,
  useCreateCollectiveScheduleDayMutation,
  useDeleteCollectiveScheduleDayMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetCollectiveCalendarDaysQuery,
  useUpdateCollectiveScheduleDayMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

/* eslint-disable-next-line */
export interface AddEditCollectiveCalendarEntryProps {
  start: Date
  end: Date
  employerId: string
  collectiveScheduleId: string | undefined
}
export function AddEditCollectiveCalendarEntry({
  start,
  end,
  employerId,
  collectiveScheduleId
}: AddEditCollectiveCalendarEntryProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [addNewCollectiveDay, setAddNewCollectiveDay] = useState(false)
  const { timeFrameOptions } = formTypeSelectOptions

  const { data: payrollCodesData } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    sortByProperty: 'Code',
    orderDirection: OrderDirection.Asc
  })

  const { data: collectiveDays, refetch: refetchDays } = useSuspenseGetCollectiveCalendarDaysQuery({
    collectiveScheduleId: collectiveScheduleId || '',
    start: start,
    end: end
  })

  const [selectedCollectiveSchedule, setSelectedCollectiveSchedule] = useState<CollectiveScheduleDayModel | undefined>(
    collectiveDays.collectiveScheduleDays[0]
  )

  const AddEditCollectiveCalendarEntrySchema = object({
    payrollCodeId: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    timeFrameType: string({
      required_error: t('form.validation.timeframetyperequired'),
      invalid_type_error: t('form.validation.timeframetyperequired')
    }).min(1, t('form.validation.timeframetyperequired'))
  })

  type AddEditCollectiveCalendarEntryForm = TypeOf<typeof AddEditCollectiveCalendarEntrySchema>

  const form = useForm<AddEditCollectiveCalendarEntryForm>({
    resolver: zodResolver(AddEditCollectiveCalendarEntrySchema),
    defaultValues: {
      payrollCodeId: selectedCollectiveSchedule?.payrollCodeId || '',
      timeFrameType: selectedCollectiveSchedule?.timeFrameType.key || TIME_FRAME_TYPE_KEYS.DEFAULT
    }
  })

  const createMutation = useCreateCollectiveScheduleDayMutation()
  const updateMutation = useUpdateCollectiveScheduleDayMutation()
  const deleteMutation = useDeleteCollectiveScheduleDayMutation()

  const handleOnSubmit = async (newCollectiveCalendarEntry: AddEditCollectiveCalendarEntryForm) => {
    if (!selectedCollectiveSchedule) {
      for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
        await createMutation
          .mutateAsync({
            createCollectiveScheduleDayCommand: {
              payrollCodeId: newCollectiveCalendarEntry.payrollCodeId,
              timeFrameTypeKey: newCollectiveCalendarEntry.timeFrameType,
              dateTime: date,
              collectiveScheduleId: collectiveScheduleId || ''
            }
          })
          .then(() => {
            refetchDays()
            closeFlyIn()
          })
      }
    } else {
      await updateMutation
        .mutateAsync({
          updateCollectiveScheduleDayCommand: {
            collectiveScheduleId: collectiveScheduleId || '',
            collectiveScheduleDayId: selectedCollectiveSchedule?.id || '',
            dateTime: start,
            payrollCodeId: newCollectiveCalendarEntry.payrollCodeId,
            timeFrameTypeKey: newCollectiveCalendarEntry.timeFrameType
          }
        })
        .then(() => {
          refetchDays()
          closeFlyIn()
        })
    }
  }

  const handleDelete = async () => {
    await deleteMutation
      .mutateAsync({
        deleteCollectiveScheduleDayCommand: {
          collectiveScheduleDayId: selectedCollectiveSchedule?.id || '',
          collectiveScheduleId: collectiveScheduleId || ''
        }
      })
      .then(() => {
        refetchDays()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.collectiveschedule-entry.title')}</Typography>
      <Typography variant="h6" color={'gray'}>
        {t('flyin.collectiveschedule-entry.date')}:{' '}
        {start?.toLocaleDateString() === end?.toLocaleDateString()
          ? start?.toLocaleDateString()
          : start?.toLocaleDateString() + ' - ' + end?.toLocaleDateString()}
      </Typography>
      <Grid className="bold" container>
        <Grid xs={3.5} item>
          <p>{t('form.field.date')}</p>
        </Grid>
        <Grid xs={5.5} item>
          <p>{t('form.field.payrollcode')}</p>
        </Grid>
        <Grid xs={3} item>
          <p>{t('form.field.timeframetypekey')}</p>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {collectiveDays?.collectiveScheduleDays && (
            <RadioGroup
              aria-labelledby="collective-schedule-entries"
              value={selectedCollectiveSchedule?.id}
              onChange={(e, collectiveScheduleId) => {
                const selectCollectiveSchedule = collectiveDays.collectiveScheduleDays.find(
                  x => x.id === collectiveScheduleId
                )
                setSelectedCollectiveSchedule(selectCollectiveSchedule)
                form.reset({
                  payrollCodeId: selectCollectiveSchedule?.payrollCodeId,
                  timeFrameType: selectCollectiveSchedule?.timeFrameType.key
                })
              }}
            >
              {collectiveDays?.collectiveScheduleDays.map((collectiveEntry, index) => (
                <label
                  key={collectiveEntry.id}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={3.5} item>
                    <p>
                      <Radio
                        sx={{
                          padding: '0 0.25rem 0 0',
                          marginTop: '-0.2rem'
                        }}
                        size="small"
                        value={collectiveEntry.id}
                      />
                      <span>{parseISO(collectiveEntry?.dateTime).toLocaleDateString()}</span>
                    </p>
                  </Grid>
                  <Grid xs={5.5} item>
                    <p>
                      {collectiveEntry.description.length > 20
                        ? collectiveEntry.description.substring(0, 20) + '...'
                        : collectiveEntry.description}
                    </p>
                  </Grid>
                  <Grid xs={3} item>
                    <p>{collectiveEntry.timeFrameType.value}</p>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} item container justifyContent="space-between">
          <Box>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                form.reset({
                  payrollCodeId: '',
                  timeFrameType: TIME_FRAME_TYPE_KEYS.DEFAULT
                })
                setAddNewCollectiveDay(true)
                setSelectedCollectiveSchedule(undefined)
              }}
            >
              {t('flyin.collectiveschedule.add')}
            </Button>
          </Box>
          {collectiveDays?.collectiveScheduleDays && (
            <Box>
              <Button variant="outlined" size="small" color="error" onClick={handleDelete}>
                {t('flyin.collectiveschedule.delete')}
              </Button>
            </Box>
          )}
        </Grid>
      </FormGridLayout>

      {(selectedCollectiveSchedule || addNewCollectiveDay) && (
        <>
          <FormGridLayout hasPaddingTop>
            <FormSelect
              sx={12}
              name="payrollCodeId"
              label={`${t('form.field.payrollcode')}`}
              options={payrollCodesData?.allPayrollCodesByEmployerId.data
                .filter(x => x.isCalendarEntry)
                .map(x => new FormSelectOption(x.id, x.code + ' - ' + x.userFriendlyDescription))}
            />
            <FormSelect
              sx={12}
              name="timeFrameType"
              label={`${t('form.field.timeframetype')}`}
              options={timeFrameOptions}
            />
          </FormGridLayout>
          <FormErrorList />
          <FormActionButtons
            isMutating={createMutation.isPending || updateMutation.isPending || deleteMutation.isPending}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default AddEditCollectiveCalendarEntry
