import {
  DEFAULT_SALARY_TYPE,
  formTypeSelectOptions,
  ROLE,
  useCanAccess,
  useSuspenseGetAllUsedValueTypesByPayrollCodeIdQuery,
  useSuspenseGetAllValueTypePayComponentsQuery,
  useSuspenseGetPayrollCodeByIdQuery,
  useUpdatePayrollCodeMutation
} from '@epix-web-apps/core'
import {
  CanView,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormRadioGroup,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormLabel, Grid, Typography } from '@mui/material'
import { useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { array, boolean, object, string, TypeOf } from 'zod'
import FormCheckboxGroup from '../../form-components/form-checkbox-group/form-checkbox-group'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface AddEditSalarySettingsProps {
  employerId: string
  payrollCodeId: string
}

export function AddEditSalarySettings({ employerId, payrollCodeId }: AddEditSalarySettingsProps) {
  const { t } = useTranslation()

  const addEditPayrollCodeSchema = object({
    employerId: string({
      required_error: t('form.validation.employerrequired'),
      invalid_type_error: t('form.validation.employerrequired')
    }).min(1, t('form.validation.employerrequired')),
    reverseSignImport: boolean(),
    reverseSignExport: boolean(),
    isSalaryEntry: boolean(),
    salaryEntryConverterMapping: string().optional().nullable(),
    salaryEntryKey: string().optional().nullable(),
    valueTypePayComponents: array(string()).optional().nullable()
  })

  type CreateEditPayrollCodeForm = TypeOf<typeof addEditPayrollCodeSchema>
  const { salaryEntryTypeOptions } = formTypeSelectOptions

  const { data: getPayrollCodeById, refetch: refetchPayrollCodeById } = useSuspenseGetPayrollCodeByIdQuery({
    payrollCodeId: payrollCodeId
  })

  const { data: allValueTypePayComponents } = useSuspenseGetAllValueTypePayComponentsQuery({})

  const { data: allActiveValueTypePayComponents } = useSuspenseGetAllUsedValueTypesByPayrollCodeIdQuery({
    id: payrollCodeId
  })

  const canAccessPayrollCodes = useCanAccess([ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES])

  const { closeFlyIn } = useFlyIn()
  const form = useForm<CreateEditPayrollCodeForm>({
    resolver: zodResolver(addEditPayrollCodeSchema),
    defaultValues: {
      employerId: employerId,
      reverseSignImport: getPayrollCodeById?.payrollCodeById.reverseSignImport ?? false,
      reverseSignExport: getPayrollCodeById?.payrollCodeById.reverseSignExport ?? false,
      isSalaryEntry: getPayrollCodeById?.payrollCodeById.isSalaryEntry ?? false,
      salaryEntryKey: getPayrollCodeById?.payrollCodeById.salaryEntryType?.key || DEFAULT_SALARY_TYPE,
      valueTypePayComponents: getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.map(x => x.key),
      salaryEntryConverterMapping: getPayrollCodeById?.payrollCodeById.salaryEntryConverterMapping
    }
  })
  const { control } = form
  const watchedSalaryentry = useWatch({ control, name: `isSalaryEntry` })

  const updateMutation = useUpdatePayrollCodeMutation()

  const handleOnSubmit = async (newPayrollCode: CreateEditPayrollCodeForm) => {
    if (!canAccessPayrollCodes) return
    await updateMutation
      .mutateAsync({
        updatePayrollCodeCommand: {
          id: payrollCodeId || '',
          code: getPayrollCodeById?.payrollCodeById.code || '',
          providerCode: getPayrollCodeById?.payrollCodeById.providerCode,
          colourHexCode: getPayrollCodeById?.payrollCodeById.colourCodeHex || '',
          description: getPayrollCodeById?.payrollCodeById.description || '',
          groupTypeKey: getPayrollCodeById?.payrollCodeById.group.key || '',
          subGroupTypeKey: getPayrollCodeById?.payrollCodeById.subGroup?.key || '',
          employerId: employerId || '',
          reverseSignImport: newPayrollCode.reverseSignImport,
          reverseSignExport: newPayrollCode.reverseSignExport,
          isCalendarEntry: getPayrollCodeById?.payrollCodeById.isCalendarEntry ?? false,
          isSalaryEntry: newPayrollCode.isSalaryEntry,
          salaryEntryConverterMapping: newPayrollCode.salaryEntryConverterMapping,
          isUsableInSelfService: getPayrollCodeById?.payrollCodeById.isUsableInSelfService ?? false,
          needsDocument: getPayrollCodeById?.payrollCodeById.needsDocument ?? false,
          useForBradfordCalculation: getPayrollCodeById?.payrollCodeById.useForBradfordCalculation ?? false,
          userFriendlyDescription: getPayrollCodeById?.payrollCodeById.userFriendlyDescription,
          valueTypePayComponents: newPayrollCode.valueTypePayComponents || [],
          exportToProvider: getPayrollCodeById?.payrollCodeById.exportToProvider ?? true,
          salaryEntryTypeKey: newPayrollCode.salaryEntryKey
        }
      })
      .then(() => {
        refetchPayrollCodeById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditsalarysettings.title')}</Typography>
      <Typography variant="h5">
        {getPayrollCodeById?.payrollCodeById.code} {getPayrollCodeById?.payrollCodeById.userFriendlyDescription}
      </Typography>

      <FormGridLayout>
        <Grid xs={12} item>
          <FormLabel>{t('form.field.salarysubtitle')}</FormLabel>
        </Grid>
        <FormSwitch
          disabled={!canAccessPayrollCodes}
          sx={6}
          name="isSalaryEntry"
          label={t('form.field.issalaryentry')}
        />

        {watchedSalaryentry && (
          <FormCheckboxGroup
            sx={6}
            name="valueTypePayComponents"
            label={t('form.field.valuetypepaycomponents')}
            options={allValueTypePayComponents?.allValueTypePayComponents}
            defaultValue={
              getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.length !== 0
                ? getPayrollCodeById?.payrollCodeById.valueTypePayComponents?.map(x => x.key)
                : allValueTypePayComponents?.allValueTypePayComponents.map(x => x.key)
            }
            disabledValues={allActiveValueTypePayComponents?.allUsedValueTypePayComponentsByPayrollCodeId.map(
              x => x.key
            )}
          />
        )}

        {watchedSalaryentry && (
          <FormRadioGroup
            disabled={!canAccessPayrollCodes}
            sx={12}
            name="salaryEntryKey"
            label={`${t('form.field.salaryentry')}`}
            options={salaryEntryTypeOptions}
          />
        )}

        <FormSwitch
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="reverseSignImport"
          label={t('form.field.reversesignimport')}
        />
        <FormSwitch
          disabled={!canAccessPayrollCodes}
          sx={12}
          name="reverseSignExport"
          label={t('form.field.reversesignexport')}
        />

        <FormInput
          sx={12}
          name="salaryEntryConverterMapping"
          label={t('flyin.addeditcalculateddata.salaryentryconvertermapping')}
        />
      </FormGridLayout>
      <FormErrorList />
      <CanView roles={[ROLE.ADMIN, ROLE.MANAGEPAYROLLCODES]}>
        <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
      </CanView>
    </FormContainer>
  )
}

export default AddEditSalarySettings
