import {
  FormSelectOption,
  formTypeSelectOptions,
  GetDayDefinitionEntryByIdQuery,
  numericString,
  OrderDirection,
  useCreateDayDefinitionEntryMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useSuspenseGetDayDefinitionEntryByIdQuery,
  useUpdateDayDefinitionEntryMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'

export interface AddDayDefinitionEntryProps {
  employerId: string
  dayDefinitionId: string
}

export interface EditDayDefinitionEntryProps extends AddDayDefinitionEntryProps {
  dayDefinitionEntryId: string
}

export function AddDayDefinitionEntry({ employerId, dayDefinitionId }: AddDayDefinitionEntryProps) {
  return AddEditDayDefinitionEntry(employerId, dayDefinitionId)
}

export function EditDayDefinitionEntry({
  employerId,
  dayDefinitionId,
  dayDefinitionEntryId
}: EditDayDefinitionEntryProps) {
  const getDayDefinitionEntryByIdQuery = useSuspenseGetDayDefinitionEntryByIdQuery({
    dayDefinitionId: dayDefinitionId,
    dayDefinitionEntryId: dayDefinitionEntryId
  })

  return AddEditDayDefinitionEntry(employerId, dayDefinitionId, getDayDefinitionEntryByIdQuery)
}

function AddEditDayDefinitionEntry(
  employerId: string,
  dayDefinitionId: string,
  getDayDefinitionEntryByIdQuery?: UseSuspenseQueryResult<GetDayDefinitionEntryByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()

  const AddEditDayDefinitionEntrySchema = object({
    payrollCodeId: string({
      required_error: t('form.validation.payrollcoderequired'),
      invalid_type_error: t('form.validation.payrollcoderequired')
    }).min(1, t('form.validation.payrollcoderequired')),
    timeFrameType: string({
      required_error: t('form.validation.timeframetyperequired'),
      invalid_type_error: t('form.validation.timeframetyperequired')
    }).min(1, t('form.validation.timeframetyperequired')),
    hours: numericString(
      number({
        required_error: t('form.validation.hoursrequired'),
        invalid_type_error: t('form.validation.hoursmustbenumeric')
      }).positive({ message: t('form.validation.hoursgreaterthen0') })
    ),
    dayDefinitionId: string({
      required_error: t('form.validation.daydefinitionrequired'),
      invalid_type_error: t('form.validation.daydefinitionrequired')
    }).min(1, t('form.validation.daydefinitionrequired'))
  })

  type CreateEditDayDefinitionEntryForm = TypeOf<typeof AddEditDayDefinitionEntrySchema>

  const { timeFrameOptions } = formTypeSelectOptions
  const { data: payrollCodesData } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: employerId || '',
    offset: 0,
    limit: -1,
    payrollCodeFilterModel: {
      showCalendarCodes: true
    },
    sortByProperty: 'Description',
    orderDirection: OrderDirection.Asc
  })

  const getDayDefinitionEntryById = getDayDefinitionEntryByIdQuery?.data

  const form = useForm<CreateEditDayDefinitionEntryForm>({
    resolver: zodResolver(AddEditDayDefinitionEntrySchema),
    defaultValues: {
      timeFrameType: getDayDefinitionEntryById?.dayDefinitionEntryById?.timeFrameType.key,
      hours: getDayDefinitionEntryById?.dayDefinitionEntryById?.hours,
      dayDefinitionId: dayDefinitionId,
      payrollCodeId: getDayDefinitionEntryById?.dayDefinitionEntryById?.payrollCodeId
    }
  })

  const createMutation = useCreateDayDefinitionEntryMutation()
  const updateMutation = useUpdateDayDefinitionEntryMutation()

  const handleOnSubmit = async (newDayDefinitionEntry: CreateEditDayDefinitionEntryForm) => {
    if (!getDayDefinitionEntryById?.dayDefinitionEntryById?.id) {
      await createMutation
        .mutateAsync({
          createDayDefinitionEntryCommand: {
            payrollCodeId: newDayDefinitionEntry.payrollCodeId,
            timeFrameTypeKey: newDayDefinitionEntry.timeFrameType,
            dayDefinitionId: dayDefinitionId,
            hours: newDayDefinitionEntry.hours
          }
        })
        .then(closeFlyIn)
    } else {
      await updateMutation
        .mutateAsync({
          updateDayDefinitionEntryCommand: {
            dayDefinitionEntryId: getDayDefinitionEntryById.dayDefinitionEntryById?.id,
            dayDefinitionId: dayDefinitionId,
            payrollCodeId: newDayDefinitionEntry.payrollCodeId,
            timeFrameTypeKey: newDayDefinitionEntry.timeFrameType,
            hours: newDayDefinitionEntry.hours
          }
        })
        .then(() => {
          getDayDefinitionEntryByIdQuery?.refetch()
          closeFlyIn()
        })
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.addeditdaydefinitionentries.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="payrollCodeId"
          label={`${t('form.field.payrollcode')}`}
          options={payrollCodesData?.allPayrollCodesByEmployerId.data.map(
            x => new FormSelectOption(x.id, x.code + ' - ' + x.userFriendlyDescription)
          )}
        />

        <FormSelect
          sx={12}
          name="timeFrameType"
          label={`${t('form.field.timeframetype')}`}
          options={timeFrameOptions}
        />

        <FormNumericInput sx={12} name="hours" label={`${t('form.field.hours')} *`} />
      </FormGridLayout>
      <FormErrorList />
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditDayDefinitionEntry
