import { ROLE, useSuspenseGetIsAdminLimitReachedQuery } from '@epix-web-apps/core'
import { Box, Divider, Grid, IconButton, Tooltip, Typography, useTheme } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FeatureForPurchaseIndicator, FormGridLayout } from '@epix-web-apps/ui'
import { FormSwitch } from '../../form-components/form-switch'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { UserCountryPolicies } from './user-country-policies'
import { FormRadioGroup, FormRadioOption } from '@epix-web-apps/ui'
import { useEffect } from 'react'

/* eslint-disable-next-line */
export interface UserConfigurationProps {
  currentRole: string
  selectedRole: string
}

export function UserConfiguration({ currentRole, selectedRole }: UserConfigurationProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { control } = useFormContext()
  const { data: isLimitReachedQuery, refetch: refetchIsLimitReached } = useSuspenseGetIsAdminLimitReachedQuery()

  useEffect(() => {
    refetchIsLimitReached()
  }, [currentRole])

  const isLimitReached =
    isLimitReachedQuery?.isAdminLimitReached && currentRole !== ROLE.CUSTOM && currentRole !== ROLE.ADMIN

  const userRoleOptions = [
    new FormRadioOption('', t('user.role.noaccess')),
    new FormRadioOption(ROLE.CUSTOM, t('user.role.custom'), isLimitReached),
    new FormRadioOption(ROLE.ADMIN, t('user.role.admin'), isLimitReached)
  ]

  const watchManageAllCountryPolicies = useWatch({ control, name: 'manageAllCountryPolicies' })

  return (
    <>
      <Typography variant="h3">{t('userconfiguration.title.epixaccess')}</Typography>
      <FormGridLayout>
        <FormRadioGroup sx={12} row name="role" options={userRoleOptions} />
        {isLimitReached && (
          <Box sx={{ paddingLeft: 2, fontStyle: 'italic' }}>
            {t('limits.adminlimitreached')} <FeatureForPurchaseIndicator />
          </Box>
        )}
        <Grid item xs={6}>
          {selectedRole === ROLE.CUSTOM && (
            <>
              <Typography variant="h5">{t('userconfiguration.title.generalsettings')}</Typography>
              <Divider />
              <FormSwitch
                sx={12}
                name="manageAllCountryPolicies"
                label={t('userconfiguration.form.field.manageallcountrypolicies')}
              />
              <FormSwitch
                sx={12}
                name="manageTeamsAndRoles"
                label={t('userconfiguration.form.field.manageteamsandroles')}
              />

              <FormSwitch sx={12} name="importData" label={t('userconfiguration.form.field.importdata')} />

              <FormSwitch
                sx={12}
                name="manageCompanyCars"
                label={t('userconfiguration.form.field.managecompanycars')}
              />
              <Divider />
              <Box
                sx={{
                  gap: '0.5em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  textAlign: 'left'
                }}
              >
                <Box>
                  <FormSwitch
                    color={theme.palette.warning.light}
                    name="manageLists"
                    label={t('userconfiguration.form.field.managelists')}
                  />
                </Box>
                <Tooltip title={t('userconfiguration.form.field.tooltipmanagelists')}>
                  <IconButton sx={{ m: 0, p: 0 }}>
                    <InfoOutlinedIcon sx={{ color: theme.palette.warning.light }} />
                  </IconButton>
                </Tooltip>
              </Box>

              <FormSwitch
                sx={12}
                color={theme.palette.warning.light}
                name="manageUsersAndPolicies"
                label={t('userconfiguration.form.field.manageusersandpolicies')}
              />
            </>
          )}
        </Grid>

        {selectedRole === ROLE.CUSTOM && !watchManageAllCountryPolicies && (
          <Grid item xs={6}>
            <UserCountryPolicies />
          </Grid>
        )}
      </FormGridLayout>
    </>
  )
}

export default UserConfiguration
