import {
  ExportJobModel,
  GetExportJobsByJobIdsQuery,
  GetProcessByIdQuery,
  isProcessOfType,
  PROCESS_TYPE,
  ProcessingStatusType,
  ROLE,
  useCanAccess,
  useCompleteProcessMutation,
  useGetAvailablePayrollClosureProcessContractsQuery,
  useGetExportJobsByJobIdsQuery,
  useGetProcessByIdQuery,
  useRequiredParams
} from '@epix-web-apps/core'
import { } from '@epix-web-apps/ui'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Button, CircularProgress, Grid, LinearProgress, Tooltip, Typography, useTheme } from '@mui/material'
import { Query, QueryKey } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ExportJobList } from '../finish-step-components/export-job-list'
import FinishPaycomponentUpdateOverview from '../paycomponent-update/finish-paycomponent-update-overview/finish-paycomponent-update-overview'
import { FinishPayrollClosureOverview } from '../payroll-closure/finish-payroll-closure-overview'
import { StepParams } from './step-props'

export type ExportJobModelWithoutProcessingJobType = Omit<ExportJobModel, 'processingJobType'>

const allExportJobsFinished = (exportJobs: Array<ExportJobModelWithoutProcessingJobType>) => {
  return exportJobs.every(job => job.statusType === ProcessingStatusType.Finished)
}

const someFinishedExportJobsHaveErrors = (exportJobs: ExportJobModelWithoutProcessingJobType[] | undefined) => {
  if (!exportJobs) return false
  return allExportJobsFinished(exportJobs) && exportJobs.some(job => !job.success && job.errorMessages.length > 0)
}

const someFinishedExportJobsHaveWarnings = (exportJobs: Array<ExportJobModelWithoutProcessingJobType>) => {
  return allExportJobsFinished(exportJobs) && exportJobs.some(job => !job.success && job.warningMessages.length > 0)
}

export interface FinishProps {
  processId: string
  processType: PROCESS_TYPE
}

export function Finish({ processId, processType }: FinishProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const params = useRequiredParams<StepParams>()
  const [exportJobIds, setExportJobIds] = useState<Array<string>>([])

  const {
    data: getProcess,
    refetch: refetchProcess,
    isFetching: isFetchingProcess
  } = useGetProcessByIdQuery(
    {
      id: params.id
    },
    {
      refetchInterval: processPolling
    }
  )

  function processPolling(query: Query<GetProcessByIdQuery, Query, GetProcessByIdQuery, QueryKey>) {
    const data = query.state.data
    if (data && data.processById) {
      if (data.processById.completed && !data.processById.areExportJobsInitialized) {
        return 1000 * 2
      }

      if (data.processById.exportJobIds) {
        setExportJobIds(data.processById.exportJobIds)
      }
    }
    return false
  }

  const [contractsWithErrorsCount, setContractsWithErrorsCount] = useState(0)
  const { getValues } = useFormContext()
  const { data: getAvailableProcessContracts, isFetching: isFetchingContracts } =
    useGetAvailablePayrollClosureProcessContractsQuery(
      {
        id: processId,
        offset: 0,
        limit: -1
      },
      {
        enabled: isProcessOfType(PROCESS_TYPE.PAYROLL_CLOSURE, getProcess?.processById?.type.key)
      }
    )

  useEffect(() => {
    if (getAvailableProcessContracts && getAvailableProcessContracts.availablePayrollClosureProcessContracts) {
      const selectedContractIds = getValues('contractIds')
      const selectedContracts = getAvailableProcessContracts.availablePayrollClosureProcessContracts.data.filter(c =>
        selectedContractIds.includes(c.contractId)
      )
      const contractsWithErrors = selectedContracts.filter(c => c.validationErrorCount > 0)
      setContractsWithErrorsCount(contractsWithErrors.length)
    }
  }, [getAvailableProcessContracts])

  function exportJobPolling(query: Query<GetExportJobsByJobIdsQuery, Query, GetExportJobsByJobIdsQuery, QueryKey>) {
    const data = query.state.data
    if (data && data.allExportJobsByJobIds) {
      if (allExportJobsFinished(data.allExportJobsByJobIds)) {
        return false
      }
    }
    return 1000 * 5
  }

  const { data: getExportJobsByIds } = useGetExportJobsByJobIdsQuery(
    {
      jobIds: exportJobIds
    },
    {
      enabled: !!exportJobIds.length,
      refetchIntervalInBackground: true,
      refetchInterval: exportJobPolling
    }
  )

  const mutation = useCompleteProcessMutation()

  function handleCompleteProcess() {
    mutation
      .mutateAsync({
        completeProcessCommand: {
          processId: params?.id || ''
        }
      })
      .then(response => setExportJobIds(response.completeProcess))
      .finally(() => refetchProcess())
  }

  const canCompletePayrollClosure = useCanAccess([ROLE.ADMIN, ROLE.FINISHPAYROLLCLOSURE])

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} lg={6}>
        {processType === PROCESS_TYPE.PAYROLL_CLOSURE && (
          <FinishPayrollClosureOverview contractsWithErrors={contractsWithErrorsCount} />
        )}
        {processType === PROCESS_TYPE.PAYCOMPONENT_UPDATE && <FinishPaycomponentUpdateOverview />}
        {!getProcess?.processById.completed && (
          <Box>
            <Tooltip
              title={!canCompletePayrollClosure ? t('processpage.finishoverview.cancompletepayrollclosure') : ''}
              placement="top"
              arrow
              disableHoverListener={canCompletePayrollClosure}
            >
              <span>
                <Button
                  sx={{ mb: 2 }}
                  variant="contained"
                  onClick={handleCompleteProcess}
                  disabled={
                    mutation.isPending ||
                    isFetchingProcess ||
                    isFetchingContracts ||
                    contractsWithErrorsCount > 0 ||
                    !canCompletePayrollClosure
                  }
                >
                  {t('common.finish')}
                  {mutation.isPending && (
                    <LinearProgress
                      color="primary"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.6
                      }}
                    />
                  )}
                </Button>
              </span>
            </Tooltip>
            <Typography color={theme.palette.text.secondary} sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
              <InfoOutlinedIcon /> {t('processpage.finishoverview.completeprocesswarning')}
            </Typography>
            <Typography color={theme.palette.text.secondary} sx={{ display: 'flex', gap: 1, mb: 0.5 }}>
              <InfoOutlinedIcon /> {t('processpage.finishoverview.downloadinfo')}
            </Typography>
          </Box>
        )}
        {getProcess?.processById.completed &&
          getProcess?.processById.areExportJobsInitialized &&
          someFinishedExportJobsHaveErrors(getExportJobsByIds?.allExportJobsByJobIds) && (
            <>
              <Typography color={theme.palette.error.light} variant="h6">
                {t('processpage.finishoverview.notalljobsfinishedsuccessfully')}
              </Typography>
              <Button
                sx={{ mb: 2 }}
                variant="contained"
                onClick={handleCompleteProcess}
                disabled={
                  mutation.isPending ||
                  (getProcess?.processById.completed && !getProcess?.processById.areExportJobsInitialized)
                }
              >
                {t('common.retry')}
                {mutation.isPending ||
                  (getProcess?.processById.completed && !getProcess?.processById.areExportJobsInitialized && (
                    <LinearProgress
                      color="primary"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0.6,
                        borderRadius: 1
                      }}
                    />
                  ))}
              </Button>
            </>
          )}
        {getExportJobsByIds && someFinishedExportJobsHaveWarnings(getExportJobsByIds.allExportJobsByJobIds) && (
          <Typography color={theme.palette.warning.light} variant="h6">
            {t('processpage.finishoverview.somejobsfinishedwithwarnings')}
          </Typography>
        )}
      </Grid>
      <Grid item sm={12} lg={6}>
        {getProcess?.processById.completed && (
          <>
            <Typography variant="h3">{t('processpage.finishoverview.jobstates.title')}</Typography>
            {!getProcess?.processById.areExportJobsInitialized && (
              <Box sx={{ display: 'flex', gap: 1 }}>
                <CircularProgress color="inherit" size={30} />
                <Typography>{t('processpage.finishoverview.jobstates.preparing')}</Typography>
              </Box>
            )}

            {getProcess?.processById.areExportJobsInitialized && exportJobIds.length > 0 && (
                <ExportJobList
                  exportJobs={getExportJobsByIds?.allExportJobsByJobIds ?? []}
                  payrollProviderCode={getProcess.processById.payrollProviderCode}
                  payrollProviderName={getProcess.processById.payrollProviderName}
                />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default Finish
