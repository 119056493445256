import {
  useGetAllRequestPoliciesByEmployerIdQuery,
  useRequiredParams,
  useSuspenseGetEmployerByIdQuery
} from '@epix-web-apps/core'
import { Configuration, HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import AddIcon from '@mui/icons-material/Add'
import DoneIcon from '@mui/icons-material/Done'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Box, Button, IconButton, MenuItem, Typography } from '@mui/material'
import { GridColDef, GridRenderCellParams, GridRowId, GridRowsProp } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { DataTableCell, PaginationModel, ScrollableDataTable } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu/dropdown-menu'
import { AddRequestPolicy } from '../../components/request-policy-components'
import RemoveRequestPolicy from '../../components/request-policy-components/remove-request-policy'

/* eslint-disable-next-line */
export interface RequestPolicyPageProps {}

export type RequestPolicyDetailPageParams = {
  id: string
  employerid: string
}

export function RequestPolicyPage(props: RequestPolicyPageProps) {
  const params = useRequiredParams<RequestPolicyDetailPageParams>()
  const { t } = useTranslation()
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [rowCount, setRowCount] = useState(0)
  const { openFlyIn, closeFlyIn } = useFlyIn()
  const [requestPolicyId, setRequestPolicyId] = useState('')
  const [internalName, setInternalName] = useState('')
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const employerId = params.employerid

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: params.employerid
  })

  function activeActions(rowParams: GridRenderCellParams) {
    return <DataTableCell>{rowParams.value ? <DoneIcon /> : <HighlightOffOutlinedIcon />}</DataTableCell>
  }

  const rowActions = (rowParams: GridRenderCellParams) => (
    <>
      <IconButton
        aria-label="row actions"
        aria-controls="menu-row"
        aria-haspopup="true"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
          setRequestPolicyId(rowParams.row.id)
          setInternalName(rowParams.row.internalName)
        }}
      >
        <MoreHorizIcon />
      </IconButton>
      <IconButton aria-label="row details" aria-controls="details-row"></IconButton>
    </>
  )

  const columns: GridColDef[] = [
    {
      field: 'internalName',
      headerName: t('requestpolicypage.datatable.column.internalname'),
      flex: 1,
      sortable: false
    },
    {
      field: 'absenceType',
      headerName: t('requestpolicypage.datatable.column.absencetype'),
      flex: 1,
      sortable: false
    },
    {
      field: 'canBeRequested',
      headerName: t('requestpolicypage.datatable.column.canberequest'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'needsApproval',
      headerName: t('requestpolicypage.datatable.column.needsapproval'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'unlimited',
      headerName: t('requestpolicypage.datatable.column.unlimited'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'obfuscated',
      headerName: t('requestpolicypage.datatable.column.obfuscated'),
      flex: 1,
      sortable: false,
      renderCell: activeActions
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      renderCell: rowActions
    }
  ]

  const [tableData, setTableData] = useState<GridRowsProp>()

  const { data, isLoading, refetch } = useGetAllRequestPoliciesByEmployerIdQuery({
    employerId: employerId || '',
    offset: paginationModel.offset,
    limit: paginationModel.pageSize
  })

  const filterBar = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: [2],
          paddingLeft: [2],
          paddingRight: [2]
        }}
      >
        <HeaderTitleNavigation
          backToLink={Configuration.ICP_PROVIDERS_ID_EMPLOYERS(`${params.id}`)}
          title={t('requestpolicypage.title')}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddRequestPolicy employerId={employerId || ''} />,
                callbackAfterClose: () => refetch
              })
            }
          >
            <AddIcon />
            {t('requestpolicypage.button.addrequestpolicy')}
          </Button>
        </Box>
      </Box>
      <Typography color={'gray'} variant="h3" paddingLeft={2}>
        {getEmployerById?.employerById.companyName}
      </Typography>
    </Box>
  )

  useEffect(() => {
    if (data && data?.allRequestPoliciesByEmployer) {
      const requestPolicyData = data.allRequestPoliciesByEmployer
      setTableData(
        requestPolicyData.data.map(row => {
          return {
            id: row.id,
            internalName: row.internalName,
            userFriendlyName: row.userFriendlyName,
            absenceType: row.absenceType.value,
            canBeRequested: row.canBeRequested,
            needsApproval: row.needsApproval,
            unlimited: row.unlimited,
            obfuscated: row.obfuscated
          }
        })
      )
      setRowCount(requestPolicyData.totalCount)
    }
  }, [data])

  const goToRequestPolicySettingsPage = (clickedRow: GridRowId | undefined) => {
    if (clickedRow) {
      navigate(
        Configuration.ICP_PROVIDERS_ID_EMPLOYERID_REQUESTPOLICIES_ID(params.id, params.employerid, `${clickedRow}`)
      )
    }
  }

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        totalRowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoading}
        filterBarElement={filterBar}
      />
      <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => goToRequestPolicySettingsPage(requestPolicyId)}>
          {t('requestpolicies.list.row.menu.managerequestpolicy')}
        </MenuItem>
        <MenuItem
          onClick={() =>
            openFlyIn({
              content: (
                <RemoveRequestPolicy
                  requestPolicyId={requestPolicyId ?? ''}
                  internalName={internalName ?? ''}
                  closeFlyIn={closeFlyIn}
                />
              ),
              callbackAfterClose: () => refetch
            })
          }
        >
          {t('common.delete')}
        </MenuItem>
      </DropdownMenu>
    </>
  )
}

export default RequestPolicyPage
