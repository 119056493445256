import { useSuspenseGetMeQuery, useSuspenseGetSecurexContractDetailByContractIdQuery } from '@epix-web-apps/core'
import { DetailBox, DetailBoxRow, useFlyIn } from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { EditSecurexContractType } from './edit-securex-contract-type'
import { EditSecurexSocialSecurity } from './edit-securex-social-security'
import { EditSecurexTaxes } from './edit-securex-taxes'

/* eslint-disable-next-line */
export interface LuSecurexProps {
  contractId: string
}

export function LuSecurex({ contractId }: LuSecurexProps) {
  const { t } = useTranslation()
  const { data: me } = useSuspenseGetMeQuery()
  const { openFlyIn } = useFlyIn()

  const { data: partnerContractDetailData } = useSuspenseGetSecurexContractDetailByContractIdQuery({
    contractId: contractId
  })

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2rem'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('securexdetailpage.socialsecurity.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditSecurexSocialSecurity contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              {partnerContractDetailData?.securexContractDetailByContractId?.isSubjectedToSocialSecurity === true ? (
                <Typography>
                  {t('securexdetailpage.socialsecurity.issubjected')}{' '}
                  {t('securexdetailpage.socialsecurity.tosocialsecurity')}
                </Typography>
              ) : (
                <Typography>
                  {t('securexdetailpage.socialsecurity.isnotsubjected')}{' '}
                  {t('securexdetailpage.socialsecurity.tosocialsecurity')}
                </Typography>
              )}
            </Typography>
          </DetailBoxRow>
          {partnerContractDetailData?.securexContractDetailByContractId?.contributionsCodeType && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.socialsecurity.contributionscode')}:
                <strong>
                  {' '}
                  {partnerContractDetailData?.securexContractDetailByContractId.contributionsCodeType?.value}
                </strong>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
        <DetailBox
          title={t('securexdetailpage.contracttype.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditSecurexContractType contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          {partnerContractDetailData?.securexContractDetailByContractId?.iscoCodeType && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.contracttype.iscocode')}:
                <strong> {partnerContractDetailData?.securexContractDetailByContractId.iscoCodeType.value}</strong>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.securexContractDetailByContractId?.absenceReasonType && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.contracttype.absencereason')}:
                <strong>
                  {' '}
                  {partnerContractDetailData?.securexContractDetailByContractId.absenceReasonType?.value}
                </strong>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.securexContractDetailByContractId?.absenceStartDate && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.contracttype.absencestartdate')}:
                <strong>
                  {' '}
                  {parseISO(
                    partnerContractDetailData?.securexContractDetailByContractId.absenceStartDate
                  ).toLocaleDateString(me?.me.locale.locale)}
                </strong>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.securexContractDetailByContractId?.resumptionDate && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.contracttype.resumptiondate')}:
                <strong>
                  {' '}
                  {parseISO(
                    partnerContractDetailData?.securexContractDetailByContractId.resumptionDate
                  ).toLocaleDateString(me?.me.locale.locale)}
                </strong>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
      <Box sx={{ flex: 1 }}>
        <DetailBox
          title={t('securexdetailpage.taxes.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({
                  content: <EditSecurexTaxes contractId={contractId} />
                })
              }
            >
              <EditIcon />
            </IconButton>
          }
        >
          <DetailBoxRow>
            {partnerContractDetailData?.securexContractDetailByContractId?.isSubjectedToTaxes === true ? (
              <Typography>
                {t('securexdetailpage.socialsecurity.issubjected')} {t('securexdetailpage.socialsecurity.totax')}
              </Typography>
            ) : (
              <Typography>
                {t('securexdetailpage.socialsecurity.isnotsubjected')} {t('securexdetailpage.socialsecurity.totax')}
              </Typography>
            )}
          </DetailBoxRow>
          {partnerContractDetailData?.securexContractDetailByContractId?.taxType && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.taxes.taxtype')}:
                <strong> {partnerContractDetailData?.securexContractDetailByContractId.taxType?.value}</strong>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.securexContractDetailByContractId?.taxGroupType && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.taxes.taxgrouptype')}:
                <strong> {partnerContractDetailData?.securexContractDetailByContractId.taxGroupType?.value}</strong>
              </Typography>
            </DetailBoxRow>
          )}
          {partnerContractDetailData?.securexContractDetailByContractId?.taxCardValidFrom && (
            <DetailBoxRow>
              <Typography>
                {t('securexdetailpage.taxes.taxcardvalidfrom')}:
                <strong>
                  {' '}
                  {parseISO(
                    partnerContractDetailData?.securexContractDetailByContractId.taxCardValidFrom
                  ).toLocaleDateString(me?.me.locale.locale)}
                </strong>
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Box>
    </Box>
  )
}

export default LuSecurex
