import {
    addDays,
    FormatDateToDDMM,
    ToBackendFormatedDate,
    UpcomingAnniversariesBirthdaysModel,
    useGetPagedAnniversariesQuery
} from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingPaper } from '../../suspense-paper'
import HomepageEventWidget from '../homepage-event-widget'
import { OverviewEventAnniversaries } from '../overview-event-anniversaries'

/* eslint-disable-next-line */
export interface UpcomingAnniversariesProps {}

export function UpcomingAnniversaries(props: UpcomingAnniversariesProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: pagedAnniversaries, isLoading } = useGetPagedAnniversariesQuery({
    icps: icpFilters,
    startDate: ToBackendFormatedDate(new Date()),
    endDate: ToBackendFormatedDate(addDays(new Date(), 30)),
    offset: 0,
    limit: 4
  })

  if (pagedAnniversaries && pagedAnniversaries.pagedAnniversaries.totalCount === 0) return null

  return (
    <Grid item sm={12} lg={6}>
      <LoadingPaper isLoading={isLoading} title={t('homepage.event.anniversaries')}>
        <HomepageEventWidget
          data={pagedAnniversaries?.pagedAnniversaries.data ?? []}
          length={pagedAnniversaries?.pagedAnniversaries.totalCount ?? 0}
          subtext={(item: UpcomingAnniversariesBirthdaysModel) =>
            `${item.years} (${FormatDateToDDMM(parseISO(item.upcomingDate))})`
          }
          onItemClick={item => navigate(People.PEOPLE_ID_DETAILS(item.personId))}
          onMoreClick={() => {
            openFlyIn({ content: <OverviewEventAnniversaries /> })
          }}
        />
      </LoadingPaper>
    </Grid>
  )
}

export default UpcomingAnniversaries
