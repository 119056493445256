import {
  formTypeSelectOptions,
  useSuspenseGetPersonByIdQuery,
  useRequiredParams,
  useUpdatePersonPersonalInformationMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormGridLayout,
  FormSelect,
  useFlyIn,
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { object, string, TypeOf } from 'zod'

export function EditPersonPersonalInformation() {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const { educationLevelOptions } = formTypeSelectOptions
  const editPersonPersonalInfoSchema = object({
    educationLevelTypeKey: string()
  })
  type EditPersonPersonalInfoForm = TypeOf<typeof editPersonPersonalInfoSchema>

  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: getPersonById, refetch: refetchPersonById } = useSuspenseGetPersonByIdQuery({
    personId: params.id
  })

  const mutation = useUpdatePersonPersonalInformationMutation()
  const form = useForm<EditPersonPersonalInfoForm>({
    resolver: zodResolver(editPersonPersonalInfoSchema),
    defaultValues: {
      educationLevelTypeKey: getPersonById?.personById?.educationLevelType?.key
    }
  })

  const { handleSubmit } = form

  const handleOnSubmit = async (personPersonalInfo: EditPersonPersonalInfoForm) => {
    await mutation
      .mutateAsync({
        updatePersonPersonalInformation: {
          personId: params.id || '',
          educationLevelTypeKey: personPersonalInfo.educationLevelTypeKey
        }
      })
      .then(() => {
        refetchPersonById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.personalinformation.title')}</Typography>

      <FormGridLayout>
        <FormSelect
          sx={12}
          name="educationLevelTypeKey"
          label={t('form.field.educationlevel')}
          options={educationLevelOptions}
        />
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditPersonPersonalInformation
