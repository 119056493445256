import { useSuspenseGetTeamByIdQuery, useRequiredParams } from '@epix-web-apps/core'
import {
  Configuration,
  DetailPageBaseQueryParams,
  HeaderTitleNavigation,
  LoadingOverlay,
  TabPanel,
  a11yProps,
  useFlyIn,
} from '@epix-web-apps/ui'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import RemoveTeam from '../../components/team-components/delete-team/remove-team'
import AddEditTeam from '../../components/team-components/edit-team/add-edit-team'
import TeamIdentification from '../../components/team-components/team-identification/team-identification'
import TeamMembers from '../../components/team-components/team-members/team-members'
import TeamRoles from '../../components/team-components/team-roles/team-roles'

/* eslint-disable-next-line */
export interface TeamsDetailPageProps {}

export function TeamsDetailPage(props: TeamsDetailPageProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { t } = useTranslation()
  const theme = useTheme()
  const { openFlyIn } = useFlyIn()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabIndex = parseInt(searchParams.get('tabIndex') ?? '0')

  const { data: getTeamById, refetch: refetchTeam } = useSuspenseGetTeamByIdQuery({
    id: params.id
  })

  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <HeaderTitleNavigation
          mobileSizeTitle
          backToLink={Configuration.TEAMS()}
          title={t('teamsdetailpage.title') + ': ' + getTeamById?.teamById.name}
        />
        <Box>
          <Button
            sx={{ mr: 4 }}
            variant="contained"
            onClick={() =>
              openFlyIn({
                content: <AddEditTeam teamId={params.id} />,
                callbackAfterClose: () => refetchTeam
              })
            }
          >
            <EditIcon />
            {t('teamsdetailpage.button.editteam')}
          </Button>

          {getTeamById?.teamById.children &&
            getTeamById?.teamById.children.length === 0 &&
            getTeamById?.teamById.parentTeam !== null && (
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  openFlyIn({
                    content: <RemoveTeam teamId={params.id} />,
                    callbackAfterClose: () => refetchTeam
                  })
                }
              >
                <EditIcon />
                {t('teamsdetailpage.button.removeteam')}
              </Button>
            )}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'divider',
          maxWidth: '100%'
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={(e, tabIndex) => {
            searchParams.set('tabIndex', `${tabIndex}`)
            setSearchParams(searchParams, { replace: true })
          }}
        >
          <Tab label={t('teamsdetailspage.tab.identification')} {...a11yProps(0)} />
          <Tab label={t('teamsdetailspage.tab.roles')} {...a11yProps(1)} />
        </Tabs>
        <Typography sx={{ color: theme.palette.text.secondary }}>{t('teamsdetailspage.tab.infotext')}</Typography>
      </Box>

      <TabPanel value={tabIndex} index={0}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Suspense fallback={<LoadingOverlay />}>
              <TeamIdentification />
            </Suspense>
          </Grid>
          <Grid item xs={6}>
            <Suspense fallback={<LoadingOverlay />}>
              <TeamMembers teamId={params.id} />
            </Suspense>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Suspense fallback={<LoadingOverlay />}>
          <TeamRoles />
        </Suspense>
      </TabPanel>
    </Box>
  )
}

export default TeamsDetailPage
