import {
  isValidPayPeriodEndDate,
  useSuspenseGetContractByIdQuery,
  useSuspenseGetContractSmartHistoryByIdQuery,
  useUpdateContractSmartPayComponentHistoryMutation,
  WithoutTime
} from '@epix-web-apps/core'
import { FormActionButtons, FormContainer, FormDatepicker, FormGridLayout, useFlyIn } from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { date, object, TypeOf } from 'zod'

interface EditContractSmartPayComponentHistoryProps {
  contractId: string
  contractSmartPayComponentHistoryId: string
}

function EditContractSmartPayComponentHistory({
  contractId,
  contractSmartPayComponentHistoryId
}: EditContractSmartPayComponentHistoryProps) {
  const { t } = useTranslation()

  const { data: contractSmartPayComponentHistory } = useSuspenseGetContractSmartHistoryByIdQuery({
    contractId: contractId,
    contractSmartPayComponentHistoryId: contractSmartPayComponentHistoryId
  })

  const editContractSmartPayComponentHistory = object({
    startDate: date(),
    endDate: date().optional().nullable()
  }).refine(
    data => {
      return data.endDate ? WithoutTime(data.endDate) >= WithoutTime(data.startDate) : true
    },
    {
      message: t('form.validation.enddateafterstartdate'),
      path: ['endDate']
    }
  )

  type EditContractSmartPayComponentHistoryForm = TypeOf<typeof editContractSmartPayComponentHistory>

  const { data: getContractById } = useSuspenseGetContractByIdQuery({
    contractId: contractId
  })

  const { closeFlyIn } = useFlyIn()
  const form = useForm<EditContractSmartPayComponentHistoryForm>({
    resolver: zodResolver(editContractSmartPayComponentHistory),
    defaultValues: {
      startDate: parseISO(contractSmartPayComponentHistory.contractSmartHistoryById.startDate),
      endDate: contractSmartPayComponentHistory.contractSmartHistoryById.endDate
        ? parseISO(contractSmartPayComponentHistory.contractSmartHistoryById.endDate)
        : null
    }
  })

  const updateMutation = useUpdateContractSmartPayComponentHistoryMutation()

  const handleOnSubmit = (newContractSmartPayComponentHistoryForm: EditContractSmartPayComponentHistoryForm) => {
    updateMutation
      .mutateAsync({
        command: {
          contractId: contractId,
          contractSmartPayComponentHistoryId: contractSmartPayComponentHistoryId,
          endDate: newContractSmartPayComponentHistoryForm.endDate
        }
      })
      .then(closeFlyIn)
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcontractsmartpaycomponenthistory.title')}</Typography>
      <Typography variant="h6">{`${contractSmartPayComponentHistory?.contractSmartHistoryById.smartPayComponent.description} {${contractSmartPayComponentHistory?.contractSmartHistoryById.smartPayComponent.code}}`}</Typography>
      <FormGridLayout>
        <FormDatepicker
          sx={12}
          name="startDate"
          label={`${t('form.field.startdate')} *`}
          disabled
        />
        <FormDatepicker
          sx={12}
          name="endDate"
          label={`${t('form.field.enddate')}`}
          openTo={'day'}
          views={['year', 'month', 'day']}
          shouldDisableDate={e => isValidPayPeriodEndDate(getContractById.contractById.payGroup ?? null, e)}
        />
      </FormGridLayout>
      <FormActionButtons isMutating={updateMutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditContractSmartPayComponentHistory
