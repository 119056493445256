import {
  DEFAULT_CIVILSTATETYPE_KEY,
  DEFAULT_UNDEFINED_KEY,
  FormSelectOption,
  formTypeSelectOptions,
  isLastEntryById,
  numericString,
  useCreateCivilStateMutation,
  useDeleteCivilStateMutation,
  useGetSubCivilStatesQuery,
  useRequiredParams,
  useSuspenseGetAllContractsQuery,
  useSuspenseGetPersonCivilStateHistoryQuery,
  useUpdateCivilStateMutation
} from '@epix-web-apps/core'
import {
  DetailPageBaseQueryParams,
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Divider, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { FieldValues, useForm, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, literal, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditPersonCivilStateProps {}

export interface SubCivilStateDropdownProps<T extends FieldValues> {
  icp:
    | {
        __typename?: 'IcpModel' | undefined
        id: string
        code: string
        configurationKey: string
      }
    | null
    | undefined
  selectedSubCivilStateIds: Array<string> | undefined
  index: number
}

const SubCivilStateDropdown = ({ icp, selectedSubCivilStateIds, index }: SubCivilStateDropdownProps<any>) => {
  const { t } = useTranslation()
  const { control, setValue, getFieldState } = useFormContext()
  const watchCivilStateDropdown = useWatch({ control, name: 'civilState' })
  const { data: getSubCivilStates, refetch } = useGetSubCivilStatesQuery(
    {
      configurationKey: icp?.configurationKey || '',
      civilStateTypeKey: watchCivilStateDropdown
    },
    {
      enabled: !!watchCivilStateDropdown
    }
  )
  const subCivilstates = getSubCivilStates?.subCivilStates
  const error = getFieldState(`subCivilStates.${index}.id`).error?.message

  useEffect(() => {
    if (!error) {
      refetch()
      const selectedSubCivilStateId = subCivilstates?.find(x => selectedSubCivilStateIds?.includes(x.id))?.id
      if (subCivilstates && !selectedSubCivilStateId) {
        setValue(`subCivilStates.${index}.id`, subCivilstates?.find(x => x.default)?.id)
      } else {
        setValue(`subCivilStates.${index}.id`, selectedSubCivilStateId)
        if (subCivilstates?.length === 0) setValue(`subCivilStates.${index}.id`, undefined)
      }
    }
  }, [subCivilstates, selectedSubCivilStateIds, index, error])

  // Only show the sublist if it has more than one option. This will still send the one on one options to the update command.
  if (subCivilstates && subCivilstates.length > 1) {
    return (
      <FormSelect
        sx={12}
        name={`subCivilStates.${index}.id`}
        label={`${t('form.field.subcivilstate', { icpcode: icp?.code })} *`}
        options={subCivilstates.map(x => new FormSelectOption(x.id, x.name))}
      />
    )
  }

  return null
}

export function EditPersonCivilState(props: EditPersonCivilStateProps) {
  const { t } = useTranslation()
  const editPersonSubCivilStateSchema = object({
    id: string({
      required_error: t('form.validation.subcivilstaterequired'),
      invalid_type_error: t('form.validation.subcivilstaterequired')
    })
      .min(1, t('form.validation.subcivilstaterequired'))
      .optional()
  })

  const editPersonCivilStateSchema = object({
    startDate: date({
      required_error: t('form.validation.startdaterequired'),
      invalid_type_error: t('form.validation.startdaterequired')
    }),
    endDate: date().optional().nullable(),
    civilState: string({
      required_error: t('form.validation.civilstaterequired'),
      invalid_type_error: t('form.validation.civilstaterequired')
    }).min(1, t('form.validation.civilstaterequired')),
    subCivilStates: editPersonSubCivilStateSchema.array(),
    titleType: string().nullable(),
    showPartnerInfo: boolean(),
    birthDate: date().optional().nullable(),
    countryOfBirthKey: string().optional().nullable(),
    cityOfBirth: string().optional().nullable(),
    nationalityKey: string().optional().nullable(),
    nationalIdNumber: string().optional().nullable(),
    firstName: string().optional().nullable(),
    lastName: string().optional().nullable(),
    lastNamePrefix: string().optional().nullable(),
    otherNames: string().optional().nullable(),
    disabled: boolean().optional(),
    genderTypeKey: string().optional().nullable(),
    disabledPercentage: numericString(
      number({
        required_error: t('form.validation.percentagerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      })
        .gte(0, { message: t('form.validation.percentagegreaterthan0') })
        .lte(100, { message: t('form.validation.precentagelessthan100') })
        .optional()
        .nullable()
    ),
    dependent: boolean().optional(),
    personalPhone: string().optional().nullable(),
    personalMobile: string().optional().nullable(),
    personalEmail: string().email(t('form.validation.invalidemail')).or(literal('')).optional().nullable()
  })

  type EditPersonCivilStateForm = TypeOf<typeof editPersonCivilStateSchema>
  const params = useRequiredParams<DetailPageBaseQueryParams>()
  const { closeFlyIn } = useFlyIn()

  const { data: getPersonCivilStates, refetch: refetchPersonCivilStateHistory } =
    useSuspenseGetPersonCivilStateHistoryQuery({
      personId: params.id
    })

  const { data: getAllContracts } = useSuspenseGetAllContractsQuery({
    personId: params.id
  })

  const contracts = getAllContracts?.allContracts
  const { genderOptions } = formTypeSelectOptions
  const { titleOptions, civilStateOptions, countryOptions, nationalityOptions } = formTypeSelectOptions
  const civilStates = getPersonCivilStates?.personCivilStateHistory
  const [selectedCivilState, setSelectedCivilState] = useState(civilStates?.current)
  const [addNewCivilState, setAddNewCivilState] = useState(false)
  const createMutation = useCreateCivilStateMutation()
  const updateMutation = useUpdateCivilStateMutation()
  const deleteMutation = useDeleteCivilStateMutation()

  const form = useForm<EditPersonCivilStateForm>({
    resolver: zodResolver(editPersonCivilStateSchema),
    defaultValues: {
      firstName: selectedCivilState?.firstName,
      otherNames: selectedCivilState?.otherNames,
      lastNamePrefix: selectedCivilState?.lastNamePrefix,
      lastName: selectedCivilState?.lastName,
      startDate: selectedCivilState?.startDate ? parseISO(selectedCivilState.startDate) : undefined,
      endDate: selectedCivilState?.endDate ? parseISO(selectedCivilState.endDate) : null,
      birthDate: selectedCivilState?.dateOfBirth ? parseISO(selectedCivilState.dateOfBirth) : null,
      countryOfBirthKey: selectedCivilState?.countryOfBirth?.code,
      cityOfBirth: selectedCivilState?.cityOfBirth,
      nationalityKey: selectedCivilState?.nationality?.code,
      nationalIdNumber: selectedCivilState?.nationalIdNumber,
      civilState: selectedCivilState?.civilState?.key || DEFAULT_CIVILSTATETYPE_KEY,
      subCivilStates: selectedCivilState?.icpSubCivilStates || [],
      showPartnerInfo: selectedCivilState?.showPartnerInfo ?? false,
      titleType: selectedCivilState?.titleType?.key || DEFAULT_UNDEFINED_KEY,
      personalEmail: selectedCivilState?.emailAddress,
      personalMobile: selectedCivilState?.mobilePhoneNumber,
      personalPhone: selectedCivilState?.phoneNumber,
      genderTypeKey: selectedCivilState?.genderType?.key,
      disabled: selectedCivilState?.disabled ?? false,
      disabledPercentage: selectedCivilState?.disabledPercentage,
      dependent: selectedCivilState?.dependent ?? false
    }
  })
  const { control } = form
  const watchDisabled = useWatch({ control, name: `disabled` })
  const watchShowPartnerInfo = useWatch({ control, name: 'showPartnerInfo' })

  const handleOnSubmit = async (personCivilState: EditPersonCivilStateForm) => {
    const civilStateModel = {
      personId: params.id || '',
      civilStateTypeKey: personCivilState.civilState || DEFAULT_CIVILSTATETYPE_KEY,
      icpSubCivilStateIds: personCivilState.subCivilStates?.filter(x => x.id !== undefined).map(x => x.id || '') ?? [],
      showPartnerInfo: personCivilState.showPartnerInfo,
      titleTypeKey: personCivilState.titleType || DEFAULT_UNDEFINED_KEY,
      dateOfBirth: personCivilState.birthDate && personCivilState.birthDate,
      cityOfBirth: personCivilState.cityOfBirth,
      countryOfBirthKey: personCivilState.countryOfBirthKey,
      nationalityKey: personCivilState.nationalityKey,
      nationalIdNumber: personCivilState.nationalIdNumber,
      firstName: personCivilState.firstName,
      lastName: personCivilState.lastName,
      lastNamePrefix: personCivilState.lastNamePrefix,
      otherNames: personCivilState.otherNames,
      genderTypeKey: personCivilState.genderTypeKey,
      disabled: personCivilState.disabled,
      disabledPercentage: personCivilState.disabledPercentage,
      dependent: personCivilState.dependent,
      phoneNumber: personCivilState.personalPhone,
      mobilePhoneNumber: personCivilState.personalMobile,
      emailAddress: personCivilState.personalEmail
    }
    if (!selectedCivilState) {
      await createMutation
        .mutateAsync({
          createCivilStateCommand: {
            startDate: personCivilState.startDate,
            ...civilStateModel
          }
        })
        .then(() => {
          refetchPersonCivilStateHistory()
          closeFlyIn()
        })
    } else {
      await updateMutation
        .mutateAsync({
          updateCivilStateCommand: {
            civilStateId: selectedCivilState?.id || '',
            ...civilStateModel
          }
        })
        .then(() => {
          refetchPersonCivilStateHistory()
          closeFlyIn()
        })
    }
  }

  async function handleDelete() {
    await deleteMutation
      .mutateAsync({
        deletePersonCivilStateCommand: {
          civilStateId: selectedCivilState?.id || '',
          personId: params.id || ''
        }
      })
      .then(() => {
        refetchPersonCivilStateHistory()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editperson.civilstate.title')}</Typography>
      <Grid className="bold" container>
        <Grid xs={4} item>
          <p>{t('form.field.startdate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.enddate')}</p>
        </Grid>
        <Grid xs={4} item>
          <p>{t('form.field.civilstate')}</p>
        </Grid>
      </Grid>
      <Divider />

      <FormGridLayout hasBorderBottom>
        <Grid xs={12} sx={{ fontSize: '0.95em' }} item>
          {civilStates?.history && (
            <RadioGroup
              aria-labelledby="civilstate-history"
              value={selectedCivilState?.id}
              onChange={(e, civilStateId) => {
                const selectCivilState = civilStates?.history.find(x => x.id === civilStateId)
                setSelectedCivilState(selectCivilState)
                setAddNewCivilState(false)
                form.reset({
                  startDate: parseISO(selectCivilState?.startDate),
                  endDate: selectCivilState?.endDate ? parseISO(selectCivilState.endDate) : null,
                  birthDate: selectCivilState?.dateOfBirth ? parseISO(selectCivilState?.dateOfBirth) : null,
                  civilState: selectCivilState?.civilState?.key || DEFAULT_CIVILSTATETYPE_KEY,
                  titleType: selectCivilState?.titleType?.key || DEFAULT_UNDEFINED_KEY,
                  showPartnerInfo: selectCivilState?.showPartnerInfo ?? false,
                  cityOfBirth: selectCivilState?.cityOfBirth,
                  countryOfBirthKey: selectCivilState?.countryOfBirth?.code,
                  nationalityKey: selectCivilState?.nationality?.code,
                  nationalIdNumber: selectCivilState?.nationalIdNumber,
                  firstName: selectCivilState?.firstName,
                  otherNames: selectCivilState?.otherNames,
                  lastNamePrefix: selectCivilState?.lastNamePrefix,
                  lastName: selectCivilState?.lastName,
                  genderTypeKey: selectCivilState?.genderType?.key,
                  disabled: selectCivilState?.disabled ?? false,
                  disabledPercentage: selectCivilState?.disabledPercentage,
                  dependent: selectCivilState?.dependent ?? false,
                  personalMobile: selectCivilState?.mobilePhoneNumber,
                  personalPhone: selectCivilState?.phoneNumber,
                  personalEmail: selectCivilState?.emailAddress
                })
              }}
            >
              {civilStates?.history.map((civilState, index) => (
                <label
                  key={index}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    height: '2rem'
                  }}
                >
                  <Grid xs={4} item>
                    <p>
                      <Radio
                        sx={{ padding: '0 0.25rem 0 0', marginTop: '-0.2rem' }}
                        size="small"
                        value={civilState.id}
                      />
                      <span>{parseISO(civilState.startDate).toLocaleDateString()}</span>
                    </p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{civilState.endDate && parseISO(civilState.endDate).toLocaleDateString()}</p>
                  </Grid>
                  <Grid xs={4} item>
                    <p>{civilState.civilState?.value}</p>
                  </Grid>
                </label>
              ))}
            </RadioGroup>
          )}
        </Grid>
        <Grid xs={12} item container gap={1}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              form.reset({
                startDate: undefined,
                endDate: null,
                birthDate: null,
                civilState: DEFAULT_CIVILSTATETYPE_KEY,
                titleType: DEFAULT_UNDEFINED_KEY
              })
              setAddNewCivilState(true)
              setSelectedCivilState(null)
            }}
          >
            {t('flyin.editperson.civilstate.addcivilstate')}
          </Button>
          {isLastEntryById(civilStates?.history, selectedCivilState?.id) && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={handleDelete}
              disabled={deleteMutation.isPending}
            >
              {t('flyin.editperson.civilstate.removecivilstate')}
            </Button>
          )}
        </Grid>
      </FormGridLayout>

      {(selectedCivilState || addNewCivilState) && (
        <>
          <FormGridLayout hasPaddingTop hasBorderBottom>
            <FormDatepicker
              sx={6}
              name="startDate"
              label={`${t('form.field.startdate')} *`}
              disabled={!addNewCivilState}
            />

            <FormDatepicker sx={6} name="endDate" label={t('form.field.enddate')} disabled={true} />

            <FormSelect
              sx={12}
              name="civilState"
              label={`${t('form.field.civilstate')} *`}
              options={civilStateOptions}
              onChange={() => {
                form.resetField('subCivilStates')
              }}
            />

            {[...new Map(contracts?.map(item => [item.icp?.id, item])).values()].map((contract, index) => (
              <SubCivilStateDropdown
                key={index}
                icp={contract.icp}
                selectedSubCivilStateIds={selectedCivilState?.icpSubCivilStates?.map(x => x.id)}
                index={index}
              />
            ))}

            <FormSwitch sx={12} name="showPartnerInfo" label={t('form.field.showpartnerinfo')} />
          </FormGridLayout>

          {watchShowPartnerInfo && (
            <>
              <Typography variant="h4">{t('flyin.editperson.civilstate.partnerinfotitle')}</Typography>
              <FormGridLayout hasPaddingTop>
                <FormSelect sx={6} name="titleType" label={t('form.field.title')} options={titleOptions} />

                <FormDatepicker sx={6} name="birthDate" label={t('form.field.birthdate')} />

                <FormInput sx={6} name="cityOfBirth" label={'City of Birth'} />

                <FormSelect sx={6} name="countryOfBirthKey" label={'Country of Birth'} options={countryOptions} />

                <FormSelect sx={6} name="nationalityKey" label={'Nationality'} options={nationalityOptions} />

                <FormInput sx={6} name="nationalIdNumber" label={'National ID number'} />

                <FormInput sx={6} name="firstName" label={t('form.field.firstname')} />

                <FormInput sx={6} name="otherNames" label={t('form.field.othernames')} />

                <FormInput sx={6} name="lastNamePrefix" label={t('form.field.lastnameprefix')} />

                <FormInput sx={6} name="lastName" label={t('form.field.lastname')} />

                <FormSelect sx={6} name="genderTypeKey" label={'Gender'} options={genderOptions} />

                <FormSwitch
                  sx={12}
                  name="disabled"
                  onChange={() => {
                    form.resetField('disabledPercentage', {
                      defaultValue: null
                    })
                  }}
                  label={t('form.field.disabled')}
                />

                {watchDisabled && (
                  <FormNumericInput sx={12} name="disabledPercentage" label={t('form.field.disabledPercentage')} />
                )}

                <FormSwitch sx={12} name="dependent" label={t('form.field.dependent')} />

                <FormInput sx={6} name="personalPhone" label={t('form.field.personalphone')} />

                <FormInput sx={6} name="personalMobile" label={t('form.field.personalmobilephone')} />

                <FormInput sx={12} name="personalEmail" label={t('form.field.personalemail')} />
              </FormGridLayout>
            </>
          )}
          <FormErrorList />
          <FormActionButtons
            isMutating={createMutation.isPending || updateMutation.isPending}
            onCancel={() => closeFlyIn()}
          />
        </>
      )}
    </FormContainer>
  )
}

export default EditPersonCivilState
