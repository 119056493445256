import {
    FormatDateToDDMM,
    UpcomingEndProbationPeriodModel,
    useGetUpcomingEndProbationPeriodQuery
} from '@epix-web-apps/core'
import { People, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Grid } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LoadingPaper } from '../../suspense-paper'
import HomepageEventWidget from '../homepage-event-widget'
import { OverviewEventProbation } from '../overview-event-probation'

/* eslint-disable-next-line */
export interface UpcomingEndProbationPeriodProps {}

export function UpcomingEndProbationPeriod(props: UpcomingEndProbationPeriodProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { icpFilters } = useGlobalPersistedStore()
  const { data: dataUpcomingEndProbationPeriod, isLoading } = useGetUpcomingEndProbationPeriodQuery({
    icps: icpFilters
  })
  const limitedData = dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod.slice(0, 4)

  if (
    dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod &&
    dataUpcomingEndProbationPeriod.upcomingEndProbationPeriod.length === 0
  )
    return null

  return (
    <Grid item sm={12} lg={6}>
      <LoadingPaper isLoading={isLoading} title={t('homepage.event.endprobationperiod')}>
        <HomepageEventWidget
          data={limitedData ?? []}
          length={dataUpcomingEndProbationPeriod?.upcomingEndProbationPeriod.length || 0}
          subtext={(item: UpcomingEndProbationPeriodModel) =>
            `${item.icpCode} (${FormatDateToDDMM(parseISO(item.upcomingDate))})`
          }
          onItemClick={item => navigate(People.PEOPLE_ID_DETAILS(`${item.personId}`))}
          onMoreClick={() => openFlyIn({ content: <OverviewEventProbation /> })}
        />
      </LoadingPaper>
    </Grid>
  )
}

export default UpcomingEndProbationPeriod
