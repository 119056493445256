import {
  numericString,
  useRequiredParams,
  useSuspenseGetCompanyCarByIdQuery,
  useUpdateCompanyCarCarFinancialInformationMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormGridLayout,
  FormNumericInput,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TypeOf, boolean, number, object } from 'zod'
import { CompanyCarDetailPageParams } from '../../../../pages/company-car-detail-page/company-car-detail-page'
import { FormSwitch } from '../../../form-components/form-switch'

/* eslint-disable-next-line */
export interface EditCompanyCarCarFinancialInformationProps {}

export function EditCompanyCarCarFinancialInformation(props: EditCompanyCarCarFinancialInformationProps) {
  const { t } = useTranslation()
  const params = useRequiredParams<CompanyCarDetailPageParams>()
  const { closeFlyIn } = useFlyIn()

  const editCompanyCarCarFinancialInformationSchema = object({
    totalCarCost: numericString(number().optional().nullable()),
    vat: numericString(number().optional().nullable()),
    catalogueValue: numericString(number().optional().nullable()),
    options: numericString(number().optional().nullable()),
    hasTaxCalculation: boolean()
  })

  type EditCompanyCarCarFinancialInformationForm = TypeOf<typeof editCompanyCarCarFinancialInformationSchema>

  const { data: getCompanyCarById, refetch: refetchCompanyCarById } = useSuspenseGetCompanyCarByIdQuery({
    companyCarId: params.id
  })
  const car = getCompanyCarById?.companyCarById

  const mutation = useUpdateCompanyCarCarFinancialInformationMutation()

  const form = useForm<EditCompanyCarCarFinancialInformationForm>({
    resolver: zodResolver(editCompanyCarCarFinancialInformationSchema),
    defaultValues: {
      totalCarCost: car?.totalCarCost,
      vat: car?.vat,
      catalogueValue: car?.catalogueValue,
      options: car?.options,
      hasTaxCalculation: car?.hasTaxCalculation ?? false
    }
  })

  const { handleSubmit } = form

  const handleOnSubmit = async (carFinancialInformation: EditCompanyCarCarFinancialInformationForm) => {
    await mutation
      .mutateAsync({
        updateCompanyCarCarFinancialInformationCommand: {
          id: params.id || '',
          totalCarCost: carFinancialInformation.totalCarCost,
          vat: carFinancialInformation.vat,
          catalogueValue: carFinancialInformation.catalogueValue,
          options: carFinancialInformation.options,
          hasTaxCalculation: carFinancialInformation.hasTaxCalculation ?? false
        }
      })
      .then(() => {
        refetchCompanyCarById()
        closeFlyIn()
      })
  }

  return (
    <FormContainer form={form} onSubmit={handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">{t('flyin.editcompanycarcarfinancialinformation.title')}</Typography>

      <FormGridLayout>
        <FormNumericInput sx={6} name="totalCarCost" label={t('form.field.totalcarcost')} />

        <FormNumericInput sx={6} name="vat" label={t('form.field.vat')} />

        <FormNumericInput sx={6} name="catalogueValue" label={t('form.field.cataloguevalue')} />

        <FormNumericInput sx={6} name="options" label={t('form.field.options')} />

        <FormSwitch sx={12} name="hasTaxCalculation" label={t('form.field.hastaxcalculation')} />
      </FormGridLayout>

      <FormActionButtons isMutating={mutation.isPending} onCancel={() => closeFlyIn()} />
    </FormContainer>
  )
}

export default EditCompanyCarCarFinancialInformation
