import { UseSuspenseQueryResult } from '@tanstack/react-query'

export function getFormSelectOptions<TQuery, TResult>(
  query: (variables?: any, options?: any) => UseSuspenseQueryResult<TQuery>,
  mapFn: (data: TQuery) => TResult
): TResult {
  const { data } = query({}, { cacheTime: Infinity, staleTime: Infinity })

  if (!data) return [] as TResult

  return mapFn(data)
}
