import {
  OrderDirection,
  useRequiredParams,
  useSuspenseGetInheritedTeamAuthorizationsByTeamIdQuery
} from '@epix-web-apps/core'
import { DetailPageBaseQueryParams } from '@epix-web-apps/ui'
import { Box } from '@mui/material'
import AssignedRoles from '../assigned-roles/assigned-roles'
import InheritedRoles from '../inherited-roles/inherited-roles'

/* eslint-disable-next-line */
export interface TeamRolesProps {}

export function TeamRoles(props: TeamRolesProps) {
  const params = useRequiredParams<DetailPageBaseQueryParams>()

  const { data: getInheritedTeamAuthorization } = useSuspenseGetInheritedTeamAuthorizationsByTeamIdQuery({
    teamId: params.id || '',
    offset: 0,
    limit: -1,
    sortByProperty: 'Function',
    orderDirection: OrderDirection.Asc
  })

  return (
    <Box>
      <AssignedRoles teamId={params.id} />
      {getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId &&
        getInheritedTeamAuthorization?.allInheritedTeamAuthorizationsByTeamId.totalCount > 0 && (
          <InheritedRoles teamId={params.id} />
        )}
    </Box>
  )
}

export default TeamRoles
