import {
  GetReportDefinitionByIdQuery,
  ReportDefinitionDateSelection,
  ReportDefinitionDetailModel,
  ReportDefinitionPeriodSelection,
  ReportViewDefinitionColumnModel,
  ReportViewModel,
  useGetColumnsByReportViewKeyQuery,
  useGetReportDefinitionFiltersQuery,
  useGetReportDefinitionSelectedFieldsQuery,
  useRequiredParams,
  useSuspenseGetReportDefinitionByIdQuery,
  useSuspenseGetReportViewByStartPointQuery,
  useUpdateReportDefinitionSelectedFieldsMutation
} from '@epix-web-apps/core'
import {
  a11yProps,
  BorderedTableCell,
  DetailBox,
  DetailBoxRow,
  HeaderTitleNavigationSubtitle,
  routes,
  TabPanel,
  useFlyIn,
  useGlobalStore
} from '@epix-web-apps/ui'
import {
  Add,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  MoreHoriz
} from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme
} from '@mui/material'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import DropdownMenu from '../../components/dropdown-menu'
import { EditReportFlyin } from '../../components/reporting-assistant-components/add-edit-report-flyin'
import { AddReportFilterFlyin } from '../../components/reporting-assistant-components/add-report-filter-flyin'
import { DeleteReportFilterFlyin } from '../../components/reporting-assistant-components/delete-report-filter-flyin'

type ReportingAssistantDetailsPageParams = {
  id: string
}

enum ReportingAssistantDetailsTabs {
  GENERAL,
  FIELDS
}

export function ReportingAssistantDetailsPage() {
  const { t } = useTranslation()
  const params = useRequiredParams<ReportingAssistantDetailsPageParams>()

  const [tabIndex, setTabIndex] = useState(ReportingAssistantDetailsTabs.GENERAL)

  const { data, refetch } = useSuspenseGetReportDefinitionByIdQuery({
    id: params.id
  })

  return (
    <Box sx={{ p: 1, height: '100%', overflow: 'hidden' }}>
      <HeaderTitleNavigationSubtitle
        title={t('reportinganalyticsdetailpage.title')}
        subTitle={data.reportDefinitionById.name}
        backToLink={routes.analytics.REPORTING_DEFINITIONS}
      />
      <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
        <Tab
          label={t('reportinganalyticsdetailpage.general.title')}
          {...a11yProps(ReportingAssistantDetailsTabs.GENERAL)}
        />
        <Tab
          label={t('reportinganalyticsdetailpage.fields.title')}
          {...a11yProps(ReportingAssistantDetailsTabs.FIELDS)}
        />
      </Tabs>
      <TabPanel index={ReportingAssistantDetailsTabs.GENERAL} value={tabIndex}>
        <GeneralTab report={data.reportDefinitionById} refetch={refetch} />
      </TabPanel>
      <TabPanel index={ReportingAssistantDetailsTabs.FIELDS} value={tabIndex}>
        <FieldsTab report={data.reportDefinitionById} />
      </TabPanel>
    </Box>
  )
}

function GeneralTab({
  report,
  refetch
}: {
  report: ReportDefinitionDetailModel
  refetch: (options?: RefetchOptions) => Promise<QueryObserverResult<GetReportDefinitionByIdQuery, unknown>>
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { me } = useGlobalStore()

  const { data: reportDefinitionFilters, refetch: refetchIcpFilters } = useGetReportDefinitionFiltersQuery({
    reportDefinitionId: report.id
  })

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <DetailBox
          title={t('reportinganalyticsdetailpage.general.properties.title')}
          actionIcon={
            <IconButton
              size="small"
              onClick={() =>
                openFlyIn({ content: <EditReportFlyin report={report} />, callbackAfterClose: () => refetch })
              }
            >
              <Edit />
            </IconButton>
          }
        >
          <DetailBoxRow>
            <Typography>
              <strong>{report.name}</strong>
            </Typography>
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography>
              {t('reportinganalyticsdetailpage.general.properties.reportaccess')}: <strong>{report.access}</strong>
            </Typography>
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography>
              {t('reportinganalyticsdetailpage.general.properties.reportowner')}:{' '}
              <strong>
                {report.reportOwnerName} ({report.reportOwnerEmail})
              </strong>
            </Typography>
          </DetailBoxRow>

          <Divider sx={{ my: 1 }} />

          <DetailBoxRow>
            <Typography>
              {t('reportinganalyticsdetailpage.general.properties.populationselection.label')}:{' '}
              {report.selectedPopulation === ReportDefinitionDateSelection.None && (
                <strong>{t('reportinganalyticsdetailpage.general.properties.populationselection.today')}</strong>
              )}
              {report.selectedPopulation === ReportDefinitionDateSelection.Date && (
                <Trans
                  i18nKey={'reportinganalyticsdetailpage.general.properties.populationselection.date'}
                  values={{
                    date: parseISO(report.populationViewDate).toLocaleDateString(me.locale.locale)
                  }}
                />
              )}
              {report.selectedPopulation === ReportDefinitionDateSelection.All && (
                <strong>{t('reportinganalyticsdetailpage.general.properties.populationselection.all')}</strong>
              )}
            </Typography>
          </DetailBoxRow>

          <DetailBoxRow>
            <Typography>
              {t('reportinganalyticsdetailpage.general.properties.historydateselection.label')}:{' '}
              {report.selectedHistory === ReportDefinitionDateSelection.None && (
                <strong>{t('reportinganalyticsdetailpage.general.properties.historydateselection.today')}</strong>
              )}
              {report.selectedHistory === ReportDefinitionDateSelection.Date && (
                <Trans
                  i18nKey={'reportinganalyticsdetailpage.general.properties.historydateselection.date'}
                  values={{
                    date: parseISO(report.historyViewDate).toLocaleDateString(me.locale.locale)
                  }}
                />
              )}
              {report.selectedHistory === ReportDefinitionDateSelection.All && (
                <strong>{t('reportinganalyticsdetailpage.general.properties.historydateselection.all')}</strong>
              )}
            </Typography>
          </DetailBoxRow>

          {report.reportStartPoint.periodSelection === ReportDefinitionPeriodSelection.Period && (
            <DetailBoxRow>
              <Typography>
                {t('reportinganalyticsdetailpage.general.properties.periodselection.label')}:{' '}
                {report.selectedPeriod === ReportDefinitionPeriodSelection.CurrentMonth && (
                  <strong>{t('reportinganalyticsdetailpage.general.properties.periodselection.currentmonth')}</strong>
                )}
                {report.selectedPeriod === ReportDefinitionPeriodSelection.Period && (
                  <strong>
                    {parseISO(report.periodStartDate).toLocaleDateString(me.locale.locale)}
                    {' - '}
                    {parseISO(report.periodEndDate).toLocaleDateString(me.locale.locale)}
                  </strong>
                )}
              </Typography>
            </DetailBoxRow>
          )}
        </DetailBox>
      </Grid>

      <Grid item xs={12} lg={6}>
        <DetailBox title={t('reportinganalyticsdetailpage.general.providers.title')}>
          <DetailBoxRow sx={{ mt: 1 }}>
            <FormControlLabel
              control={<Switch checked={report.selectAllProviders} disabled />}
              label={t('reportinganalyticsdetailpage.general.providers.allproviders')}
            />
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('reportinganalyticsdetailpage.general.providers.note')}
            </Typography>
          </DetailBoxRow>
        </DetailBox>

        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ color: theme.palette.text.secondary }}>
              {t('reportinganalyticsdetailpage.general.providers.table.attention')}
            </Typography>
            <Button
              size="small"
              disabled={report.selectAllProviders}
              startIcon={<Add />}
              onClick={() =>
                openFlyIn({
                  content: <AddReportFilterFlyin reportDefinitionId={report.id} />,
                  callbackAfterClose: () => () => refetchIcpFilters()
                })
              }
            >
              {t('reportinganalyticsdetailpage.general.providers.table.add')}
            </Button>
          </Box>
          <Box>
            <Table size="small" sx={{ border: `1px solid ${theme.palette.divider}` }}>
              <TableHead sx={{ backgroundColor: theme.palette.grey[100] }}>
                <TableRow>
                  <BorderedTableCell>
                    {t('reportinganalyticsdetailpage.general.providers.table.provider')}
                  </BorderedTableCell>
                  <BorderedTableCell>
                    {t('reportinganalyticsdetailpage.general.providers.table.employer')}
                  </BorderedTableCell>
                  <BorderedTableCell>
                    {t('reportinganalyticsdetailpage.general.providers.table.paygroup')}
                  </BorderedTableCell>
                  <BorderedTableCell></BorderedTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportDefinitionFilters?.reportDefinitionFilters.map(filter => (
                  <TableRow
                    key={filter.id}
                    sx={{ backgroundColor: report.selectAllProviders ? theme.palette.grey[100] : '' }}
                  >
                    <BorderedTableCell>
                      {filter.icpCountryKey} - {filter.icpName}
                    </BorderedTableCell>
                    <BorderedTableCell>
                      {filter.employerName ?? t('reportinganalyticsdetailpage.general.providers.flyin.all')}
                    </BorderedTableCell>
                    <BorderedTableCell>
                      {filter.payGroupName ?? t('reportinganalyticsdetailpage.general.providers.flyin.all')}
                    </BorderedTableCell>
                    <BorderedTableCell sx={{ p: 0 }} align="center">
                      <IconButton
                        data-filter-id={filter.id}
                        size="small"
                        disabled={report.selectAllProviders}
                        onClick={e => {
                          setAnchorEl(e.currentTarget)
                        }}
                      >
                        <MoreHoriz />
                      </IconButton>
                    </BorderedTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
              <MenuItem
                onClick={() => {
                  const filterId = anchorEl?.getAttribute('data-filter-id')
                  const filter = reportDefinitionFilters?.reportDefinitionFilters.find(rdf => rdf.id === filterId)
                  if (filter) {
                    openFlyIn({
                      content: <DeleteReportFilterFlyin filter={filter} />,
                      callbackAfterClose: () => () => refetchIcpFilters()
                    })
                  }
                }}
              >
                {t('common.delete')}
              </MenuItem>
            </DropdownMenu>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

function FieldsTab({ report }: { report: ReportDefinitionDetailModel }) {
  const { t } = useTranslation()

  const [unsavedChanges, setUnsavedChanges] = useState(false)

  // Tree view items
  const { data: reportViews } = useSuspenseGetReportViewByStartPointQuery({
    startPointKey: report.reportStartPoint.key
  })

  const [selectedView, setSelectedView] = useState<ReportViewModel | null>(
    reportViews.reportViewByStartPoint[0] ?? null
  )

  // right column
  const {
    data: selectedFieldsQuery,
    isLoading: isLoadingSelectedFields,
    refetch: refetchSelectedFields
  } = useGetReportDefinitionSelectedFieldsQuery({
    reportDefinitionId: report.id
  })
  const [selectedFields, setSelectedFields] = useState<ReportViewDefinitionColumnModel[]>([])

  useEffect(() => {
    if (selectedFieldsQuery) {
      setSelectedFields(selectedFieldsQuery?.reportDefinitionSelectedFields ?? [])
    }
  }, [selectedFieldsQuery])

  // left column
  const { data: columnsQuery, isLoading: isLoadingColumns } = useGetColumnsByReportViewKeyQuery(
    { reportViewKey: selectedView!.key },
    { enabled: selectedView !== null }
  )

  const availableFields =
    columnsQuery?.columnsByReportViewKey?.filter(c => !selectedFields.some(sf => sf.key === c.key)) ?? []

  function toRight(column: ReportViewDefinitionColumnModel) {
    if (selectedFields.some(sf => sf.key === column.key)) {
      return
    }
    setSelectedFields(prev => [...prev, column])
    setUnsavedChanges(true)
  }

  function toLeft(column: ReportViewDefinitionColumnModel) {
    setSelectedFields(prev => prev.filter(rc => rc.key !== column.key))
    setUnsavedChanges(true)
  }

  const updateSelectedFieldsMutation = useUpdateReportDefinitionSelectedFieldsMutation()

  async function handleSave() {
    await updateSelectedFieldsMutation.mutateAsync({
      updateReportDefinitionSelectedFieldsCommand: {
        reportDefinitionId: report.id,
        reportViewDefinitionColumnKeys: selectedFields.map(sf => sf.key)
      }
    })
    setUnsavedChanges(false)
    await refetchSelectedFields()
  }

  const rootReportView = reportViews.reportViewByStartPoint.find(v => v.parentKey === null)
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={3} sx={{ pr: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {rootReportView && (
            <FieldsTabTree
              view={rootReportView}
              allViews={reportViews.reportViewByStartPoint}
              activeTreeItem={selectedView?.key}
              onViewClick={v => setSelectedView(v)}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={handleSave}
              variant="contained"
              disabled={updateSelectedFieldsMutation.isPending || !unsavedChanges}
            >
              {t('common.save')}
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={9}>
        <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, overflowY: 'auto', height: 700 }}>
          {/* LEFT */}
          <Table size="small">
            <TableHead sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
              <TableRow>
                <BorderedTableCell>{t('reportinganalyticsdetailpage.fields.table.field')}</BorderedTableCell>
                <BorderedTableCell width={48} padding="none" align="center">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedFields(prev => [...prev, ...availableFields])
                      setUnsavedChanges(true)
                    }}
                  >
                    <KeyboardDoubleArrowRight />
                  </IconButton>
                </BorderedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingColumns && (
                <TableRow>
                  <BorderedTableCell align="center" colSpan={2}>
                    <CircularProgress />
                  </BorderedTableCell>
                </TableRow>
              )}

              {!isLoadingColumns &&
                availableFields.map(c => (
                  <TableRow key={c.key}>
                    <BorderedTableCell>
                      <Typography>{c.value}</Typography>
                    </BorderedTableCell>
                    <BorderedTableCell align="center" padding="none">
                      <IconButton size="small" onClick={() => toRight(c)}>
                        <KeyboardArrowRight />
                      </IconButton>
                    </BorderedTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>

          {/* RIGHT */}
          <Table size="small">
            <TableHead sx={{ backgroundColor: theme => theme.palette.grey[100] }}>
              <TableRow>
                <BorderedTableCell width={48} padding="none" align="center">
                  <IconButton
                    size="small"
                    onClick={() => {
                      setSelectedFields([])
                      setUnsavedChanges(true)
                    }}
                  >
                    <KeyboardDoubleArrowLeft />
                  </IconButton>
                </BorderedTableCell>
                <BorderedTableCell>{t('reportinganalyticsdetailpage.fields.table.table')}</BorderedTableCell>
                <BorderedTableCell>{t('reportinganalyticsdetailpage.fields.table.field')}</BorderedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoadingSelectedFields && (
                <TableRow>
                  <BorderedTableCell align="center" colSpan={3}>
                    <CircularProgress />
                  </BorderedTableCell>
                </TableRow>
              )}
              {!isLoadingSelectedFields &&
                selectedFields.map(c => (
                  <TableRow key={c.key}>
                    <BorderedTableCell align="center" padding="none">
                      <IconButton size="small" onClick={() => toLeft(c)}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    </BorderedTableCell>
                    <BorderedTableCell>
                      <Typography>{c.viewName}</Typography>
                    </BorderedTableCell>
                    <BorderedTableCell>
                      <Typography>{c.value}</Typography>
                    </BorderedTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Grid>
    </Grid>
  )
}

function FieldsTabTree({
  view,
  allViews,
  onViewClick,
  activeTreeItem,
  sx
}: {
  view: ReportViewModel
  allViews: ReportViewModel[]
  onViewClick: (value: ReportViewModel) => void
  activeTreeItem?: string
  sx?: SxProps
}) {
  const children = allViews.filter(v => v.parentKey === view.key)
  return (
    <Box sx={sx}>
      <Button
        variant={activeTreeItem === view.key ? 'contained' : 'text'}
        onClick={() => onViewClick(view)}
        sx={{ width: '100%', justifyContent: 'start' }}
      >
        {view.reportViewDefinitionValue}
      </Button>
      <Divider sx={{ my: 0.5 }} />
      {children.map(child => (
        <FieldsTabTree
          key={child.key}
          view={child}
          allViews={allViews}
          activeTreeItem={activeTreeItem}
          onViewClick={onViewClick}
          sx={{ pl: 2 }}
        />
      ))}
    </Box>
  )
}
