import {
  GetWorkScheduleByIdQuery,
  GraphqlError,
  numericString,
  useCreateWorkScheduleMutation,
  useSuspenseGetEmployerByIdQuery,
  useSuspenseGetWorkScheduleByIdQuery,
  useUpdateWorkScheduleMutation
} from '@epix-web-apps/core'
import {
  FormActionButtons,
  FormContainer,
  FormDatepicker,
  FormErrorList,
  FormGridLayout,
  FormInput,
  FormNumericInput,
  FormRadioGroup,
  FormRadioOption,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Grid, Typography, useTheme } from '@mui/material'
import { UseSuspenseQueryResult } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { boolean, date, number, object, string, TypeOf } from 'zod'
import { FormSwitch } from '../../form-components/form-switch'

export interface AddWorkScheduleProps {
  employerId: string
}

export interface EditWorkScheduleProps extends AddWorkScheduleProps {
  workScheduleId: string
}

export function AddWorkSchedule({ employerId }: AddWorkScheduleProps) {
  return AddEditWorkSchedule(employerId)
}

export function EditWorkSchedule({ employerId, workScheduleId }: EditWorkScheduleProps) {
  const query = useSuspenseGetWorkScheduleByIdQuery({
    workScheduleId: workScheduleId
  })

  return AddEditWorkSchedule(employerId, query)
}

function AddEditWorkSchedule(
  employerId: string,
  getWorkScheduleByIdQuery?: UseSuspenseQueryResult<GetWorkScheduleByIdQuery, unknown>
) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { closeFlyIn } = useFlyIn()
  const [backendErrors, setBackendErrors] = useState<Array<GraphqlError>>([])

  const getWorkScheduleById = getWorkScheduleByIdQuery?.data

  const { data: getEmployerById } = useSuspenseGetEmployerByIdQuery({
    employerId: employerId || ''
  })

  const company = getEmployerById?.employerById.companyName

  const AddEditWorkScheduleSchema = object({
    code: string({
      required_error: t('form.validation.workschedulecoderequired'),
      invalid_type_error: t('form.validation.workschedulecoderequired')
    }).min(1, t('form.validation.workschedulecoderequired')),
    description: string({
      required_error: t('form.validation.descriptionrequired'),
      invalid_type_error: t('form.validation.descriptionrequired')
    }).min(1, t('form.validation.descriptionrequired')),
    labelOnCalendar: string({
      required_error: t('form.validation.labeloncalendarrequired'),
      invalid_type_error: t('form.validation.labeloncalendarrequired')
    }).min(1, t('form.validation.labeloncalendarrequired')),
    referenceStartDate: date({
      required_error: t('form.validation.referencestartdaterequired'),
      invalid_type_error: t('form.validation.referencestartdaterequired')
    }),
    fullTime: string({
      required_error: t('form.validation.fulltime'),
      invalid_type_error: t('form.validation.fulltime')
    }),
    numberOfWeeks: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    ftePercentage: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    averageNumberOfHours: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    effectiveNumberOfHours: numericString(
      number({
        required_error: t('form.validation.valuerequired'),
        invalid_type_error: t('form.validation.valuemustbenumeric')
      }).positive({ message: t('form.validation.valuegreaterthen0') })
    ),
    isActive: boolean()
  })

  type AddEditWorkScheduleForm = TypeOf<typeof AddEditWorkScheduleSchema>

  const form = useForm<AddEditWorkScheduleForm>({
    resolver: zodResolver(AddEditWorkScheduleSchema),
    defaultValues: {
      code: getWorkScheduleById?.workScheduleById?.code,
      labelOnCalendar: getWorkScheduleById?.workScheduleById?.labelOnCalendar,
      description: getWorkScheduleById?.workScheduleById?.description,
      referenceStartDate: getWorkScheduleById?.workScheduleById?.referenceStartDate
        ? parseISO(getWorkScheduleById?.workScheduleById?.referenceStartDate)
        : undefined,
      numberOfWeeks: getWorkScheduleById?.workScheduleById?.numberOfWeeks || 1,
      averageNumberOfHours: getWorkScheduleById?.workScheduleById?.averageNumberOfHoursPerWeek || 40,
      effectiveNumberOfHours: getWorkScheduleById?.workScheduleById?.effectiveNumberOfHoursPerWeek || 40,
      ftePercentage: getWorkScheduleById?.workScheduleById?.ftePercentage || 100,
      fullTime: String(getWorkScheduleById?.workScheduleById?.isFullTime),
      isActive: getWorkScheduleById?.workScheduleById?.isActive ?? true
    }
  })

  const createMutation = useCreateWorkScheduleMutation()
  const updateMutation = useUpdateWorkScheduleMutation()

  const handleOnSubmit = async (newWorkSchedule: AddEditWorkScheduleForm) => {
    if (!getWorkScheduleById) {
      await createMutation
        .mutateAsync({
          createWorkScheduleCommand: {
            employerId: employerId || '',
            code: newWorkSchedule.code,
            description: newWorkSchedule.description,
            labelOnCalendar: newWorkSchedule.labelOnCalendar,
            referenceStartDate: newWorkSchedule.referenceStartDate,
            numberOfWeeks: newWorkSchedule.numberOfWeeks,
            ftePercentage: newWorkSchedule.ftePercentage,
            isFullTime: newWorkSchedule.fullTime === 'true',
            averageNumberOfHours: newWorkSchedule.averageNumberOfHours,
            effectiveNumberOfHours: newWorkSchedule.effectiveNumberOfHours,
            isActive: newWorkSchedule.isActive
          }
        })
        .then(closeFlyIn)
        .catch(e => setBackendErrors([e]))
    } else {
      await updateMutation
        .mutateAsync({
          updateWorkScheduleCommand: {
            workScheduleId: getWorkScheduleById.workScheduleById.id,
            code: newWorkSchedule.code,
            description: newWorkSchedule.description,
            labelOnCalendar: newWorkSchedule.labelOnCalendar,
            referenceStartDate: newWorkSchedule.referenceStartDate,
            numberOfWeeks: newWorkSchedule.numberOfWeeks,
            ftePercentage: newWorkSchedule.ftePercentage,
            isFullTime: newWorkSchedule.fullTime === 'true',
            averageNumberOfHoursPerWeek: newWorkSchedule.averageNumberOfHours,
            effectiveNumberOfHoursPerWeek: newWorkSchedule.effectiveNumberOfHours,
            isActive: newWorkSchedule.isActive
          }
        })
        .then(() => {
          getWorkScheduleByIdQuery.refetch()
          closeFlyIn()
        })
        .catch(e => setBackendErrors([e]))
    }
  }

  return (
    <FormContainer form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {getWorkScheduleById ? t('flyin.workschedule.edittitle') : t('flyin.workschedule.addtitle')}
      </Typography>
      <Typography variant="h5" color={'gray'}>
        {t('flyin.workschedule.subtitle')}: {company}
      </Typography>
      <FormGridLayout>
        <FormInput sx={6} name="code" label={`${t('form.field.workschedulecode')} *`} />

        <FormInput sx={6} name="labelOnCalendar" label={`${t('form.field.labeloncalendar')} *`} />

        <FormInput sx={12} name="description" label={`${t('form.field.description')} *`} />

        <FormDatepicker
          sx={6}
          name="referenceStartDate"
          label={`${t('form.field.referencestartdate')} *`}
          disabled={getWorkScheduleById?.workScheduleById.isInUse}
        />

        <FormNumericInput
          sx={6}
          name="numberOfWeeks"
          onBlur={e => !e.target?.value && form.resetField('numberOfWeeks', { defaultValue: 1 })}
          placeholder={'0'}
          label={`${t('form.field.numberOfWeeks')} *`}
          disabled={getWorkScheduleById?.workScheduleById.isInUse}
        />
      </FormGridLayout>

      <Box sx={{ paddingTop: '2rem', borderTop: '1px solid lightgrey' }}>
        <FormGridLayout>
          <FormNumericInput
            sx={6}
            name="averageNumberOfHours"
            onBlur={e => !e.target?.value && form.resetField('averageNumberOfHours', { defaultValue: 40 })}
            placeholder={'0'}
            label={`${t('form.field.averageNumberOfHours')} *`}
            disabled={getWorkScheduleById?.workScheduleById.isInUse}
          />

          <FormNumericInput
            sx={6}
            name="effectiveNumberOfHours"
            onBlur={e => !e.target?.value && form.resetField('effectiveNumberOfHours', { defaultValue: 40 })}
            placeholder={'0'}
            label={`${t('form.field.effectiveNumberOfHours')} *`}
            disabled={getWorkScheduleById?.workScheduleById.isInUse}
          />

          <FormNumericInput
            sx={6}
            name="ftePercentage"
            onBlur={e => !e.target?.value && form.resetField('ftePercentage', { defaultValue: 100 })}
            placeholder={'0'}
            label={`${t('form.field.ftepercentage')} *`}
            disabled={getWorkScheduleById?.workScheduleById.isInUse}
          />

          <Grid padding="1rem" xs={12}>
            <FormRadioGroup
              name="fullTime"
              label={`${t('form.field.fulltime')} *`}
              options={[
                new FormRadioOption('true', t('form.option.fulltime')),
                new FormRadioOption('false', t('form.option.parttime'))
              ]}
              disabled={getWorkScheduleById?.workScheduleById.isInUse}
            />
          </Grid>

          <FormSwitch sx={12} name="isActive" label={t('form.field.isactive')} />
        </FormGridLayout>
      </Box>
      <FormErrorList customErrors={backendErrors} />
      {!getWorkScheduleById && (
        <Typography sx={{ color: theme.palette.text.secondary }}>{t('flyin.workschedule.infotext')}</Typography>
      )}
      <FormActionButtons
        isMutating={createMutation.isPending || updateMutation.isPending}
        onCancel={() => closeFlyIn()}
      />
    </FormContainer>
  )
}

export default AddEditWorkSchedule
