import {
  ReportRunDetailModel,
  ReportRunStatus,
  useRequiredParams,
  useSuspenseGetAiCompanionCreditsUsedQuery,
  useSuspenseGetReportRunByIdQuery
} from '@epix-web-apps/core'
import {
  a11yProps,
  DetailBox,
  DetailBoxRow,
  HeaderTitleNavigationSubtitle,
  routes,
  TabPanel,
  useGlobalStore
} from '@epix-web-apps/ui'
import { CancelOutlined, CheckCircleOutlined, ScheduleOutlined, Send, StarOutline, Sync } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, InputBase, Paper, Tab, Tabs, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

type ReportRunDetailPageParams = {
  id: string
}

enum ReportRunDetailTabs {
  DETAILS,
  EPIXCOPILOT
}

export function ReportingAssistantRunsPage() {
  const params = useRequiredParams<ReportRunDetailPageParams>()
  const { t } = useTranslation()
  const { me } = useGlobalStore()

  const [tabIndex, setTabIndex] = useState(ReportRunDetailTabs.DETAILS)

  const { data: reportRunQuery } = useSuspenseGetReportRunByIdQuery({ id: params.id })

  return (
    <Box sx={{ p: 1 }}>
      <HeaderTitleNavigationSubtitle
        title={reportRunQuery.reportRunById.reportRunName}
        subTitle={parseISO(reportRunQuery.reportRunById.runDate).toLocaleString(me.locale.locale)}
        backToLink={routes.analytics.REPORTING_RUNS}
      />
      <Tabs value={tabIndex} onChange={(_, tabIndex) => setTabIndex(tabIndex)}>
        <Tab label={t('reportrunsdetilpage.details.title')} {...a11yProps(ReportRunDetailTabs.DETAILS)} />
        <Tab
          label={t('reportrunsdetilpage.epixcopilot.title')}
          disabled={reportRunQuery.reportRunById.status !== ReportRunStatus.Success}
          {...a11yProps(ReportRunDetailTabs.EPIXCOPILOT)}
        />
      </Tabs>
      <TabPanel index={ReportRunDetailTabs.DETAILS} value={tabIndex}>
        <DetailsTab reportRun={reportRunQuery.reportRunById} />
      </TabPanel>
      <TabPanel index={ReportRunDetailTabs.EPIXCOPILOT} value={tabIndex}>
        <EpixCopilotTab />
      </TabPanel>
    </Box>
  )
}

function DetailsTab({ reportRun }: { reportRun: ReportRunDetailModel }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { me } = useGlobalStore()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <DetailBox title={t('reportrunsdetilpage.details.properties.title')}>
          <DetailBoxRow>
            <Typography>
              <strong>{reportRun.reportRunName}</strong>
            </Typography>
            <>
              {reportRun.status === ReportRunStatus.Queued && (
                <ScheduleOutlined sx={{ color: theme.palette.primary.main }} />
              )}
              {reportRun.status === ReportRunStatus.Running && <Sync sx={{ color: theme.palette.primary.main }} />}
              {reportRun.status === ReportRunStatus.Success && (
                <CheckCircleOutlined sx={{ color: theme.palette.success.main }} />
              )}
              {reportRun.status === ReportRunStatus.Failed && (
                <CancelOutlined sx={{ color: theme.palette.error.main }} />
              )}
            </>
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography>
              {t('reportrunsdetilpage.details.properties.populationselection')}:{' '}
              <strong>{parseISO(reportRun.populationViewDate).toLocaleDateString(me.locale.locale)}</strong>
            </Typography>
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography>
              {t('reportrunsdetilpage.details.properties.historyselection')}:{' '}
              <strong>{parseISO(reportRun.historyViewDate).toLocaleDateString(me.locale.locale)}</strong>
            </Typography>
          </DetailBoxRow>
          <DetailBoxRow>
            <Typography>
              {t('reportrunsdetilpage.details.properties.periodselection')}:{' '}
              <strong>{parseISO(reportRun.periodStartDate).toLocaleDateString(me.locale.locale)}</strong>
              {' - '}
              <strong>{parseISO(reportRun.periodEndDate).toLocaleDateString(me.locale.locale)}</strong>
            </Typography>
          </DetailBoxRow>
        </DetailBox>
      </Grid>
    </Grid>
  )
}

function EpixCopilotTab() {
  const { t } = useTranslation()
  const [question, setQuestion] = useState('')

  const { data: getAiCredits } = useSuspenseGetAiCompanionCreditsUsedQuery()

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Box sx={{ display: 'flex', gap: 1, color: theme => theme.palette.secondary.main }}>
          <StarOutline fontSize="small" fontWeight="bold" />
          <Typography>
            <Trans
              i18nKey="aicompanionpage.creditsremaining"
              values={{
                credits: getAiCredits.aiCompanionCreditsUsed.creditsRemaining
              }}
            />
          </Typography>
        </Box>
      </Box>

      <Paper component="form" sx={{ display: 'flex', alignItems: 'center', width: '100%', borderRadius: 1 }}>
        <InputBase
          id="companion-ai-search"
          placeholder={t('reportrunsdetilpage.epixcopilot.placeholder')}
          multiline
          minRows={2}
          value={question}
          onChange={event => setQuestion(event.target.value)}
          fullWidth
          aria-label={'search companion ai'}
          sx={{ padding: 2 }}
          disabled={false}
        />
        <Divider sx={{ height: 50, m: 0.5 }} orientation="vertical" />
        <IconButton color="primary" sx={{ p: '20px' }} disabled={question === ''}>
          <Send />
        </IconButton>
      </Paper>
    </>
  )
}
