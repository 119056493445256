import {
  OrderDirection,
  PARTNER_LIST_IDENTIFIERS,
  PartnerContractDetailTypeModel,
  useGetAllPartnerContractDetailTypesByListDefinitionKeyQuery,
  useGetPartnerContractDetailTypeListDefinitionByKeyQuery,
  useNameof,
  useRequiredParams
} from '@epix-web-apps/core'
import { HeaderTitleNavigation, useFlyIn } from '@epix-web-apps/ui'
import { MoreHoriz } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, IconButton, MenuItem } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddPartnerContractDetailType,
  EditPartnerContractDetailType
} from '../../components/contract-details-components/provider-specific-details/generic-contract-detail-types'
import { PaginationModel, ScrollableDataTable, SortModel } from '../../components/data-table'
import DropdownMenu from '../../components/dropdown-menu'

export type PartnerContractDetailTypesPageParams = {
  listdefinitionkey: string
}

type PartnerContractDetailTypeRow = {
  id: string
  key: string
  value: string
}

export function PartnerContractDetailTypesPage() {
  const { t } = useTranslation()
  const { openFlyIn } = useFlyIn()
  const { nameof } = useNameof<PartnerContractDetailTypeRow>()

  const [tableData, setTableData] = useState<PartnerContractDetailTypeRow[]>([])
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [paginationModel, setPaginationModel] = useState(new PaginationModel())
  const [clickedRow, setClickedRow] = useState<PartnerContractDetailTypeModel | null>(null)

  const params = useRequiredParams<PartnerContractDetailTypesPageParams>()

  const { data, isLoading, refetch } = useGetAllPartnerContractDetailTypesByListDefinitionKeyQuery({
    listDefinitionKey: params.listdefinitionkey,
    limit: paginationModel.pageSize,
    offset: paginationModel.offset
  })

  const { data: listDefinition } = useGetPartnerContractDetailTypeListDefinitionByKeyQuery({
    key: params.listdefinitionkey
  })

  useEffect(() => {
    if (data) {
      setTableData(
        data.allPartnerContractDetailTypesByListDefinitionKey.data.map(p => {
          return {
            id: p.id,
            key: p.key,
            value: p.value
          }
        })
      )
    }
  }, [data])

  const filterBar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: [2]
      }}
    >
      <HeaderTitleNavigation
        title={PARTNER_LIST_IDENTIFIERS[params.listdefinitionkey as keyof typeof PARTNER_LIST_IDENTIFIERS]?.value}
      />

      {listDefinition?.partnerContractDetailTypeListDefinitionByKey.isEditable && (
        <Button
          variant="contained"
          onClick={() =>
            openFlyIn({
              content: <AddPartnerContractDetailType listDefinitionKey={params.listdefinitionkey || ''} />,
              callbackAfterClose: () => refetch
            })
          }
        >
          <AddIcon />
          {t('partnercontractdetailtypes.button.addpartnercontractdetailtype')}
        </Button>
      )}
    </Box>
  )

  const rowActions = useCallback(
    (row: PartnerContractDetailTypeRow) => {
      return (
        <IconButton
          aria-label="row actions"
          aria-controls="menu-row"
          aria-haspopup="true"
          onClick={e => {
            setAnchorEl(e.currentTarget)
            if (row.id) {
              setClickedRow(
                data?.allPartnerContractDetailTypesByListDefinitionKey.data.find(p => p.id === row.id) ?? null
              )
            }
          }}
        >
          <MoreHoriz />
        </IconButton>
      )
    },
    [tableData]
  )
  const columns: GridColDef[] = [
    {
      field: nameof('key'),
      headerName: t('partnercontractdetailtypes.datatable.column.code'),
      flex: 1
    },
    {
      field: nameof('value'),
      headerName: t('partnercontractdetailtypes.datatable.column.description'),
      flex: 1
    },
    {
      field: 'rowactions',
      headerName: '',
      sortable: false,
      editable: false,
      hideable: !listDefinition?.partnerContractDetailTypeListDefinitionByKey.isEditable,
      renderCell: rowParams => rowActions(rowParams.row)
    }
  ]

  return (
    <>
      <ScrollableDataTable
        data={tableData}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        isLoading={isLoading}
        sortModel={new SortModel('', OrderDirection.Asc)}
        filterBarElement={filterBar}
      />
      {listDefinition?.partnerContractDetailTypeListDefinitionByKey.isEditable && clickedRow && (
        <DropdownMenu anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem
            onClick={() =>
              openFlyIn({
                content: (
                  <EditPartnerContractDetailType id={clickedRow.id} listDefinitionKey={params.listdefinitionkey} />
                ),
                callbackAfterClose: () => refetch
              })
            }
          >
            {t('partnercontractdetailtypes.list.row.menu.edit-partner-contract-detail-type')}
          </MenuItem>
        </DropdownMenu>
      )}
    </>
  )
}

export default PartnerContractDetailTypesPage
