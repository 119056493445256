import {
  DocumentModel,
  OrderByAddedOnFolders,
  OrderDirection,
  useRequiredParams,
  useSuspenseGetContractDocumentTypeByContractIdAndDocumentTypeIdQuery,
  useSuspenseGetContractDocumentTypeByIdQuery,
  useSuspenseGetEmployerDocumentTypeByEmployerIdIdAndDocumentTypeIdQuery
} from '@epix-web-apps/core'
import { HeaderTitleNavigation, SelfService, useGlobalStore } from '@epix-web-apps/ui'
import { Box, Typography, useTheme } from '@mui/material'
import { parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { DocumentComponent } from '../document-component'
import { FolderInfoComponent } from '../folder-component'

export type DocumentDetailsQueryParams = {
  contractid: string
  employerid: string
  documenttypeid: string
}

export function ContractDocumentDetail() {
  const params = useRequiredParams<DocumentDetailsQueryParams>()
  const { me } = useGlobalStore()

  const { data: documentType } = useSuspenseGetContractDocumentTypeByIdQuery({
    id: params.documenttypeid
  })

  const { data: contractDocumentType } = useSuspenseGetContractDocumentTypeByContractIdAndDocumentTypeIdQuery({
    contractId: params.contractid,
    documentTypeId: params.documenttypeid,
    sortByProperty: OrderByAddedOnFolders.includes(documentType?.contractDocumentTypeById.key || '')
      ? 'AddedOn'
      : 'Name',
    orderDirection: OrderByAddedOnFolders.includes(documentType?.contractDocumentTypeById.key || '')
      ? OrderDirection.Desc
      : OrderDirection.Asc
  })

  let endDateTitle = '...)'

  if (contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.endDate) {
    endDateTitle =
      parseISO(contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.endDate).toLocaleDateString(
        me?.locale.locale
      ) + ')'
  }

  const title = `${contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.icpCode} (${parseISO(
    contractDocumentType?.contractDocumentTypeByContractIdAndDocumentTypeId?.startDate
  ).toLocaleDateString(me?.locale.locale)} - ${endDateTitle}`

  return DocumentDetail(title, contractDocumentType.contractDocumentTypeByContractIdAndDocumentTypeId)
}

export function EmployerDocumentDetail() {
  const params = useRequiredParams<DocumentDetailsQueryParams>()

  // TODO 2993
  // const { data: documentType } = useSuspenseGetEmployerDocumentTypeByIdQuery({
  //   id: params.documenttypeid
  // })

  const { data: employerDocumentType } = useSuspenseGetEmployerDocumentTypeByEmployerIdIdAndDocumentTypeIdQuery({
    employerId: params.employerid,
    documentTypeId: params.documenttypeid,
    // TODO 2993
    // sortByProperty: OrderByAddedOnFolders.includes(documentType.employerDocumentTypeById.key || '')
    //   ? 'AddedOn'
    //   : 'Name',
    // orderDirection: OrderByAddedOnFolders.includes(documentType.employerDocumentTypeById.key || '')
    //   ? OrderDirection.Desc
    //   : OrderDirection.Asc
  })

  const title = employerDocumentType.employerDocumentTypeByEmployerIdAndDocumentTypeId.companyName

  return DocumentDetail(title, employerDocumentType.employerDocumentTypeByEmployerIdAndDocumentTypeId)
}

interface Document {
  name: string
  documents: DocumentModel[]
}

function DocumentDetail(title: string, data: Document) {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <HeaderTitleNavigation mobileSizeTitle title={title} backToLink={SelfService.DOCUMENTS()} />
      <Box sx={{ m: 1 }}>
        <FolderInfoComponent name={data?.name || ''} underline={false} />
        {data && data.documents && data?.documents.length > 0 ? (
          data?.documents.map(x => (
            <DocumentComponent key={x.id} addedOnDate={parseISO(x.addedOn)} documentName={x.name} document={x} />
          ))
        ) : (
          <Typography sx={{ mb: 2, color: theme.palette.text.secondary }}>
            {t('selfservice.emptystate.documents')}
          </Typography>
        )}
      </Box>
    </>
  )
}

export default DocumentDetail
