import {
  FormSelectOption,
  groupBy,
  GroupTypeModel,
  OrderDirection,
  PRODUCTIVITYANALYTICS_VIEWPERIODS,
  RequestPolicyOverviewModel,
  SubTypeModel,
  typeToFormSelectOption,
  useGetAllEmployersByIcpIdsQuery,
  useGetAllPayGroupsByEmployerIdQuery,
  useGetAllProductivityViewPeriodTypesQuery,
  useGetAllRequestPoliciesOverviewQuery,
  useGetGroupTypesQuery,
  useGetSubGroupTypesQuery
} from '@epix-web-apps/core'
import { FilterSelectBox, FLYIN_WIDTH, useFlyIn, useGlobalPersistedStore } from '@epix-web-apps/ui'
import { Box, Button, CircularProgress, Divider, Switch, Typography, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ProductivityAnalyticsTabs,
  ProductivityFilters
} from '../../../pages/productivity-analytics-page/productivity-analytics-page'
import { DEFAULT_SUBGROUP_TYPE_SELECTED } from '../../../stores/productivity-store'
import { EpixDatePicker } from '../../epix-date-picker'
import CheckBoxTree, { CheckboxTreeNode } from '../../filter-components/check-box-tree/check-box-tree'
import ListComponent from '../../labour-cost-analytics-components/list-component'

export interface FilterProductivityFlyinProps {
  filters: ProductivityFilters
  updateStore: (filters: ProductivityFilters) => void
  currentTab: ProductivityAnalyticsTabs
}

function buildSubgroupTree(
  groupTypes: GroupTypeModel[],
  subgroupTypes: SubTypeModel[],
  checkedSubgroupTypes: string[]
) {
  const subGroupTypeTree = groupTypes.map(g => {
    const children = subgroupTypes
      .filter(s => s.parentKey === g.typeModel.key)
      .map(s => {
        return {
          id: s.key,
          key: s.key,
          value: s.value,
          checked: checkedSubgroupTypes ? checkedSubgroupTypes.includes(s.key) : false,
          children: []
        } as CheckboxTreeNode
      })

    return {
      id: g.typeModel.key,
      key: null,
      value: g.typeModel.value,
      checked: false,
      children: children ?? []
    } as CheckboxTreeNode
  })

  return subGroupTypeTree ?? []
}

function buildRequestPolicyTree(
  requestPolicyRecords: RequestPolicyOverviewModel[],
  checkedPolicies: string[]
): CheckboxTreeNode[] {
  const groupedByIcp = groupBy(requestPolicyRecords, r => r.icpId)
  const icpTree = Object.entries(groupedByIcp).map(([icpId, requestPoliciesForIcp]) => {
    const groupedByEmployer = groupBy(requestPoliciesForIcp, r => r.employerId)
    const employerTree = Object.entries(groupedByEmployer).map(([employerId, requestPoliciesForEmployer]) => {
      return {
        id: employerId,
        key: null,
        value: requestPoliciesForEmployer[0].employerName,
        checked: requestPoliciesForEmployer.every(r => checkedPolicies.includes(r.id)),
        children: requestPoliciesForEmployer.map(r => ({
          id: r.id,
          key: r.id,
          value: r.internalName,
          checked: checkedPolicies.includes(r.id),
          children: []
        }))
      } as CheckboxTreeNode
    })

    return {
      id: icpId,
      key: null,
      value: requestPoliciesForIcp[0].icpName,
      checked: false,
      children: employerTree
    } as CheckboxTreeNode
  })

  return icpTree
}

function FilterProductivityFlyin({ filters: initialFilters, updateStore, currentTab }: FilterProductivityFlyinProps) {
  const theme = useTheme()
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const [filters, setFilters] = useState(initialFilters)

  const { icpFilters } = useGlobalPersistedStore()

  const filterVisibility = {
    viewPeriod: [ProductivityAnalyticsTabs.PERSON, ProductivityAnalyticsTabs.OVERVIEW],
    mainBalance: [ProductivityAnalyticsTabs.BALANCES],
    subgroups: [
      ProductivityAnalyticsTabs.EVOLUTION,
      ProductivityAnalyticsTabs.OVERVIEW,
      ProductivityAnalyticsTabs.PERSON
    ],
    requestPolicies: [ProductivityAnalyticsTabs.BALANCES]
  }

  // view period filter
  const { data: viewPeriodTypes } = useGetAllProductivityViewPeriodTypesQuery(
    {},
    { enabled: showOnTabs(filterVisibility.viewPeriod) }
  )
  const viewPeriodTypeOptions = typeToFormSelectOption(viewPeriodTypes?.allProductivityViewPeriodTypes ?? [])

  // employer select filter
  const { data: employerData } = useGetAllEmployersByIcpIdsQuery({ icps: icpFilters })
  const employerOptions = employerData?.employersByIcpIds.map(e => new FormSelectOption(e.id, e.companyName))

  // paygroup select filter
  const { data: paygroupData } = useGetAllPayGroupsByEmployerIdQuery(
    { employerId: filters.employer ?? '', orderDirection: OrderDirection.Asc },
    { enabled: !!filters.employer }
  )
  const paygroupOptions = paygroupData?.allPayGroupsByEmployerId.map(p => new FormSelectOption(p.id, p.name))

  // payroll checkbox tree filter
  const { data: getAllSubGroups } = useGetSubGroupTypesQuery({}, { enabled: showOnTabs(filterVisibility.subgroups) })
  const { data: getAllGroupTypes } = useGetGroupTypesQuery({}, { enabled: showOnTabs(filterVisibility.subgroups) })

  const subgroupTree = buildSubgroupTree(
    getAllGroupTypes?.groupTypes ?? [],
    getAllSubGroups?.subGroupTypes ?? [],
    filters.subgroups
  )

  // request policy checkbox tree filter
  const { data: requestPolicies, isLoading: isLoadingRequestPolicies } = useGetAllRequestPoliciesOverviewQuery(
    {},
    { enabled: showOnTabs([ProductivityAnalyticsTabs.BALANCES]) }
  )

  const requestPolicyTree = buildRequestPolicyTree(
    requestPolicies?.allRequestPoliciesOverview
      .filter(r => icpFilters.length === 0 || icpFilters.includes(r.icpId))
      .filter(r => (filters.employer ? r.employerId === filters.employer : true)) ?? [],
    filters.policies
  )

  function handleFilter() {
    updateStore(filters)
    closeFlyIn()
  }

  function handleReset() {
    updateStore({
      ...filters,
      date: null,
      viewPeriod: PRODUCTIVITYANALYTICS_VIEWPERIODS.MONTH,
      orderDirection: OrderDirection.Desc,
      employer: null,
      paygroup: null,
      subgroups: DEFAULT_SUBGROUP_TYPE_SELECTED,
      mainBalance: true,
      policies: []
    })
    closeFlyIn()
  }

  function showOnTabs(tabs: ProductivityAnalyticsTabs[]) {
    return tabs.includes(currentTab)
  }

  return (
    <>
      <Box sx={{ width: FLYIN_WIDTH.DEFAULT }}>
        <Typography color={theme.palette.primary.main} variant="h4">
          {t('analytics.filters.title')}
        </Typography>
        <Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <EpixDatePicker
              onChange={e => (e ? setFilters({ ...filters, date: e }) : null)}
              label={t('productivity.filters.viewpoint')}
              value={filters.date ?? new Date()}
              actionBarActions={['today']}
            />

            {showOnTabs(filterVisibility.viewPeriod) && (
              <FilterSelectBox
                id={'viewPeriod'}
                label={t('productivity.filters.viewperiod')}
                keys={viewPeriodTypeOptions}
                value={filters.viewPeriod}
                handleChange={(e: string) => {
                  setFilters({ ...filters, viewPeriod: e as PRODUCTIVITYANALYTICS_VIEWPERIODS })
                }}
              />
            )}

            <FilterSelectBox
              id={'employeeId'}
              label={t('form.field.employer')}
              keys={employerOptions ?? []}
              value={filters.employer}
              handleChange={(e: string) => {
                setFilters({ ...filters, employer: e, paygroup: null, policies: [] })
              }}
            />

            {!!filters.employer && (
              <FilterSelectBox
                id="paygroup"
                label={t('form.field.paygroup')}
                keys={paygroupOptions ?? []}
                value={filters.paygroup}
                handleChange={(e: string) => {
                  setFilters({ ...filters, paygroup: e })
                }}
              />
            )}
          </Box>

          {showOnTabs(filterVisibility.mainBalance) && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  checked={filters.mainBalance}
                  onChange={(_e, checked) => setFilters({ ...filters, mainBalance: checked })}
                />
                <Typography>Main balance</Typography>
              </Box>
              {!filters.mainBalance && (
                <>
                  <Divider sx={{ borderColor: theme.palette.divider, mt: 1 }} />
                  <ListComponent listItemText="request policies">
                    {isLoadingRequestPolicies && (
                      <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                      </Box>
                    )}
                    {requestPolicyTree.map(t => (
                      <CheckBoxTree
                        key={t.id}
                        treeNode={t}
                        filters={filters.policies}
                        handleChange={e => setFilters({ ...filters, policies: e })}
                      />
                    ))}
                  </ListComponent>
                </>
              )}
            </>
          )}

          {showOnTabs(filterVisibility.subgroups) && (
            <>
              <Divider sx={{ borderColor: theme.palette.divider }} />
              <ListComponent listItemText={t('analytics.sidebar.payroll')}>
                {subgroupTree.map(t => (
                  <CheckBoxTree
                    key={t.id}
                    treeNode={t}
                    filters={filters.subgroups}
                    handleChange={e =>
                      setFilters({
                        ...filters,
                        subgroups: e
                      })
                    }
                  />
                ))}
              </ListComponent>
            </>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
          py: 2,
          zIndex: 10
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button variant="contained" onClick={handleFilter}>
              {t('analytics.flyin.filter')}
            </Button>
            <Button variant="outlined" onClick={closeFlyIn}>
              {t('analytics.flyin.cancel')}
            </Button>
          </Box>
          <Button variant="text" onClick={handleReset}>
            {t('analytics.flyin.reset')}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default FilterProductivityFlyin
