import {
  FormSelectOption,
  formTypeSelectOptions,
  GraphqlError,
  numericString,
  OrderDirection,
  RequestPolicyAutomatedPayrollCodeModel,
  useCreateRequestPolicyAutomatedPayrollCodeMutation,
  useGetAllPayrollCodesByEmployerIdQuery,
  useRequiredParams,
  useSuspenseGetAllRequestPolicyAutomatedPayrollCodesByRequestPolicyIdQuery,
  useSuspenseGetRequestPolicyPayrollCodeByIdQuery,
  useUpdateRequestPolicyAutomatedPayrollCodeMutation
} from '@epix-web-apps/core'
import {
  FLYIN_WIDTH,
  FormActionButtons,
  FormContainer,
  FormErrorList,
  FormGridLayout,
  FormNumericInput,
  FormRadioGroup,
  FormSelect,
  useFlyIn
} from '@epix-web-apps/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { number, object, string, TypeOf } from 'zod'
import { RequestPolicySettingsPageParams } from '../request-policy-settings'
import { AutomatedCounterRow } from './automated-counter-row/automated-counter-row'

interface AddEditAutomatedPayrollCodesProps {
  requestPolicyId: string
  requestPolicyPayrollCodeId: string
}

export enum ValidityTypes {
  END_OF_YEAR = 'END_OF_YEAR',
  AMOUNT_IN_MONTHS = 'AMOUNT_IN_MONTHS'
}

export function AddEditAutomatedPayrollCodes({
  requestPolicyId,
  requestPolicyPayrollCodeId
}: AddEditAutomatedPayrollCodesProps) {
  const { t } = useTranslation()
  const { closeFlyIn } = useFlyIn()
  const params = useRequiredParams<RequestPolicySettingsPageParams>()

  const [backendErrors, setBackendErrors] = useState<GraphqlError[]>([])

  const { requestPolicyAutomatedPayrollcodeOptions } = formTypeSelectOptions

  const { data: automatedPayrollCodes, refetch: refetchCounters } =
    useSuspenseGetAllRequestPolicyAutomatedPayrollCodesByRequestPolicyIdQuery({
      requestPolicyId: requestPolicyId,
      requestPolicyPayrollCodeId: requestPolicyPayrollCodeId
    })

  const { data: requestPolicyPayrollCode } = useSuspenseGetRequestPolicyPayrollCodeByIdQuery({
    requestPolicyId: requestPolicyId,
    id: requestPolicyPayrollCodeId
  })

  const { data: calendarPayrollCodes } = useGetAllPayrollCodesByEmployerIdQuery({
    employerId: params.employerid,
    offset: 0,
    limit: -1,
    orderDirection: OrderDirection.Asc,
    payrollCodeFilterModel: { showCalendarCodes: true }
  })

  const [selectedAutomatedCode, setSelectedAutomatedCode] = useState<RequestPolicyAutomatedPayrollCodeModel | null>(
    automatedPayrollCodes.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId[0] ?? null
  )

  const payrollCodeOptions =
    calendarPayrollCodes?.allPayrollCodesByEmployerId.data
      .filter(p => p.id !== requestPolicyPayrollCode.requestPolicyPayrollCodeById.payrollCodeId)
      .map(
        p =>
          new FormSelectOption(
            p.id,
            p.userFriendlyDescription ?? p.description,
            automatedPayrollCodes.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId.every(
              c => p.id !== c.calendarPayrollCodeId
            )
          )
      ) ?? []

  const editAutomatedPayrollCodeSchema = object({
    calendarPayrollCode: string(),
    factor: numericString(number()),
    validityType: string(),
    validityInMonths: numericString(number().optional().nullable())
  })

  type AddEditRequestPolicyAutomatedPayrollCodeForm = TypeOf<typeof editAutomatedPayrollCodeSchema>

  const form = useForm<AddEditRequestPolicyAutomatedPayrollCodeForm>({
    resolver: zodResolver(editAutomatedPayrollCodeSchema)
  })

  const createMutation = useCreateRequestPolicyAutomatedPayrollCodeMutation()
  const updateMutation = useUpdateRequestPolicyAutomatedPayrollCodeMutation()

  async function handleOnSubmit(form: AddEditRequestPolicyAutomatedPayrollCodeForm) {
    if (selectedAutomatedCode == null) {
      try {
        const validity = form.validityInMonths ?? 12
        const { createRequestPolicyAutomatedPayrollCode } = await createMutation.mutateAsync({
          command: {
            requestPolicyId: requestPolicyId,
            requestPolicyPayrollCodeId: requestPolicyPayrollCodeId,
            calendarPayrollCodeId: form.calendarPayrollCode,
            factor: form.factor,
            validityTypeKey: form.validityType,
            validityInMonths: form.validityType === ValidityTypes.AMOUNT_IN_MONTHS ? validity : 12
          }
        })

        const result = await refetchCounters()
        const automatedPayrollCode =
          result.data?.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId.find(
            a => a.id === createRequestPolicyAutomatedPayrollCode
          ) ?? null

        setSelectedAutomatedCode(automatedPayrollCode)
      } catch (e: any) {
        setBackendErrors([e])
      }
    } else {
      const validity = form.validityInMonths ?? 12
      updateMutation
        .mutateAsync({
          command: {
            requestPolicyId: requestPolicyId,
            requestPolicyPayrollCodeId: requestPolicyPayrollCodeId,
            requestPolicyAutomatedPayrollCodeId: selectedAutomatedCode.id,
            factor: form.factor,
            validityTypeKey: form.validityType,
            validityInMonths: form.validityType === ValidityTypes.AMOUNT_IN_MONTHS ? validity : 12
          }
        })
        .then(_ => {
          refetchCounters()
        })
        .catch(e => {
          setBackendErrors([e])
        })
    }
  }

  useEffect(() => {
    form.reset({
      calendarPayrollCode: selectedAutomatedCode?.calendarPayrollCodeId,
      factor: selectedAutomatedCode?.factor ?? 1,
      validityType: selectedAutomatedCode?.validityType.key ?? ValidityTypes.AMOUNT_IN_MONTHS,
      validityInMonths: selectedAutomatedCode?.validityInMonths ?? ''
    })
  }, [selectedAutomatedCode])

  const shouldDisableSelect = automatedPayrollCodes.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId.some(
    c => c.id === selectedAutomatedCode?.id
  )

  return (
    <FormContainer sx={{ width: FLYIN_WIDTH.LARGE }} form={form} onSubmit={form.handleSubmit(handleOnSubmit)}>
      <Typography variant="h4">
        {`${t('flyin.requestpolicy.automatedpayrollcodes.title')} - ${
          requestPolicyPayrollCode.requestPolicyPayrollCodeById.description
        }`}
      </Typography>

      {automatedPayrollCodes.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId.length !== 0 && (
        <Box>
          <Table size="small" sx={{ mb: 2 }}>
            <TableHead>
              <TableRow sx={{ fontWeight: 'bold' }}>
                <TableCell></TableCell>
                <TableCell>{t('flyin.requestpolicy.automatedpayrollcodes.calendarcode')}</TableCell>
                <TableCell>{t('flyin.requestpolicy.automatedpayrollcodes.validitytype')}</TableCell>
                <TableCell>{t('flyin.requestpolicy.automatedpayrollcodes.validityamount')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {automatedPayrollCodes.allRequestPolicyAutomatedPayrollCodesByRequestPolicyId.map(c => (
                <AutomatedCounterRow
                  key={c.id}
                  automatedPayrollCode={c}
                  selectedPayrollCode={selectedAutomatedCode}
                  requestPolicyId={requestPolicyId}
                  onSelect={() => setSelectedAutomatedCode(c)}
                  onDelete={deletedId => {
                    if (deletedId === selectedAutomatedCode?.id) {
                      setSelectedAutomatedCode(null)
                    }
                    refetchCounters()
                  }}
                />
              ))}
            </TableBody>
          </Table>
          <Button size="small" variant="outlined" onClick={() => setSelectedAutomatedCode(null)}>
            {t('flyin.requestpolicy.automatedpayrollcodes.form.add')}
          </Button>
        </Box>
      )}
      <Divider sx={{ my: 2 }} />
      <FormGridLayout>
        <FormSelect
          sx={12}
          name="calendarPayrollCode"
          disabled={shouldDisableSelect}
          label={t('flyin.requestpolicy.automatedpayrollcodes.form.calendarpayrollcode')}
          options={payrollCodeOptions}
        />

        <FormNumericInput sx={12} name="factor" label={t('flyin.requestpolicy.automatedpayrollcodes.form.factor')} />

        <FormRadioGroup
          sx={12}
          defaultSelected={ValidityTypes.END_OF_YEAR}
          name="validityType"
          label={t('flyin.requestpolicy.automatedpayrollcodes.form.validitytype')}
          options={requestPolicyAutomatedPayrollcodeOptions}
        />
        {form.watch('validityType') === ValidityTypes.AMOUNT_IN_MONTHS && (
          <FormNumericInput
            sx={12}
            name="validityInMonths"
            label={t('flyin.requestpolicy.automatedpayrollcodes.form.validityinmonths')}
          />
        )}
      </FormGridLayout>

      <FormErrorList customErrors={backendErrors} />
      <FormActionButtons isMutating={createMutation.isPending || updateMutation.isPending} onCancel={closeFlyIn} />
    </FormContainer>
  )
}
